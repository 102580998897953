import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Button, Divider, Grid, Stack, Container, Typography, Pagination, Select, Menu, MenuItem, Tooltip } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';


import { useQuery, useMutation } from "@apollo/client";
import Footer from '../layouts/main/Footer';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// utils
import { useAuthContext } from '../auth/useAuthContext';


import axios from '../utils/axios';
import { GET_BRIEF_BY_ID, GET_PITCHES_BY_BRIEF_ID } from '../graphql/queries'
import AudioPlayerBar from '../components-custom/AudioPlayerBar';

import { useDispatch, useSelector } from '../redux/store';
import { addToCart } from '../redux/slices/product';


// components
import Iconify from '../components-default/iconify';
import Markdown from '../components-default/markdown';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
import { useSettingsContext } from '../components-default/settings';
import { SkeletonPostDetails } from '../components-default/skeleton';
// sections
import {
  BriefPostHero,
  BlogPostTags,
  BlogPostCard,
  BlogPostCommentList,
  BlogPostCommentForm,
} from '../components-custom/sections/@dashboard/blog';
import { DELETE_BRIEF_BY_ID, GET_ORG_INFO_M, PITCH_SONG_TO_BRIEF } from '../graphql/mutations';
import LoadingScreen from '../components-default/loading-screen';
import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient';
import CustomToast from '../components-custom/CustomToast';
import { fCurrencyCents } from '../utils/formatNumber';
import AudioFileCardDemo from '../sections/@dashboard/general/file/AudioFileCardDemo';


// ----------------------------------------------------------------------

export default function BriefDetailDemoPage() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const dispatch = useDispatch();


  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const querySuccess = queryParams.get('updatesuccess');  

  const theme = useTheme();
  const { briefId } = useParams();

  const [recentPosts, setRecentPosts] = useState([]);

  const [post, setPost] = useState(null);

  const [loadingPost, setLoadingPost] = useState(true);

  const [errorMsg, setErrorMsg] = useState(null);

  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [controlMessage, setControlMessage] = useState('');

  const [isPlaying, setIsPlaying] = useState(false);

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedId, setSelectedId] = useState(null);

  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [spotifyPlaylistId, setSpotifyPlaylistId] = useState('')

  const [userSongs, setUserSongs] = useState([])

  const [songPitches, setSongPitches] = useState([])

  const [songSubmission, setSongSubmission] = useState('')

  const [keyGate, setKeyGate] = useState(true)

  const [showReference, setShowReference] = useState(false)
  const [showBriefDetail, setShowBriefDetail] = useState(false)

  const [userOrg, setUserOrg] = useState({});


  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [pitchSongToBrief] = useMutation(PITCH_SONG_TO_BRIEF);

  const [deleteBrief] = useMutation(DELETE_BRIEF_BY_ID);

  const [getOrgInfo] = useMutation(GET_ORG_INFO_M);


  const { loading, error, data = {getBriefById: {}}, refetch } = useQuery(GET_BRIEF_BY_ID, {
    variables: { briefId: briefId }, // eslint-disable-line
    fetchPolicy: 'network-only',
  });

  const { loading: pitchesLoading, error: pitchesError, data: pitchesData = {getPitchesByBriefId: []}, refetch: pitchesRefetch } = useQuery(GET_PITCHES_BY_BRIEF_ID, {
    variables: { briefId: briefId }, // eslint-disable-line
    fetchPolicy: 'network-only'
  });


  async function getUserOrg(orgId){
    const org = await getOrgInfo({ variables: { orgId: orgId } }); // eslint-disable-line      
    setUserOrg(org.data?.getOrgInfo)
  }

  async function createReceipt(){
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/generalreceipts`, {  // eslint-disable-line
      userId: user._id,
      elementKey: 'briefs',
      elementValue: briefId
    })
  }


  function scrollToFile() {
    if (selectedId !== null) {
      const pane = document.getElementById(selectedId)
      setTimeout(()=> {pane.scrollIntoView({ behavior: 'smooth', block: 'center' })}, 20)
    }
  }

  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== songPitches.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, songPitches.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };



  
  useEffect(()=>{
    if (data.getBriefById !== {} && data?.getBriefById?.post !== post){

      console.log('render')

      if (data?.getBriefById?.post?.spotifyLink !== '') {
        try {
          const spotifyTemp1 = data?.getBriefById.post?.spotifyLink.split('/playlist/') || '123?123'
          const spotifyTemp2 = spotifyTemp1[1].split('?')
          setSpotifyPlaylistId(spotifyTemp2[0])
          // console.log('Spotify: ', spotifyTemp2)
        } catch (err) {
          console.log(err)
        }
      }

      setPost(data?.getBriefById?.post)
      getUserOrg(data?.getBriefById?.post?.organization)

      if (querySuccess === 'true') {
        setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${data?.getBriefById?.post?.title} successfully updated`, duration: 4000})
      }

    }
  }, [data, userOrg, post]) // eslint-disable-line

    
  useEffect(()=>{
    if (pitchesData?.getPitchesByBriefId !== undefined && pitchesData?.getPitchesByBriefId !== songPitches){
      setSongPitches(pitchesData?.getPitchesByBriefId)
    }
  }, [pitchesData, songPitches]) // eslint-disable-line

  
  useEffect(()=>{
    createReceipt()

  },[]) // eslint-disable-line




  if (loading) {
    return <LoadingScreen/>
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME} || Client Brief </title>
      </Helmet>

      <Container 
      >
        <CustomBreadcrumbs
          // heading="Post Details"
          links={post?.active ? 
            [
              {
                name: 'Briefs',
                href: '/demo',
              },
              {
                name: user?.role !== 'admin' ? post?.title : `${post?.title} - CLIENT VIEW`,
              },
            ] :
            [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              {
                name: 'Briefs',
                href: '/briefs',
              },
              {
                name: 'Inactive',
                href: '/briefs/inactive',
              },
              {
                name: user?.role !== 'admin' ? post?.title : `${post?.title} - CLIENT VIEW`,
              },
            ]
          }
        />
        {post && (
          <Box sx={{display: 'flex', flexDirection: 'column', gap: 0, pb: 10}}>
          <Stack
            sx={{
              borderRadius: 2,
              mt: 2,
              boxShadow: {
                md: theme.customShadows.card,
              },
              overflowX: 'hidden',

            }}
          >
            
            <BriefPostHero post={post} orgInfo={userOrg} user={user} briefId={briefId} />
            <Grid container spacing={3}>
              <Grid item 
                xs={12} sm={12} md={6} 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                }}
              >
              <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start'}}>
                <Typography
                  variant="h5"
                  sx={{
                    pt: 2,
                    pb: 5,
                    px: { md: 5 },
                    cursor: 'default',
                  }}
                >
                  {post.description}
                </Typography>
                <Divider sx={{m: 5, mt: 0}}/>
                <Markdown 
                  children={post.content} 
                  sx={{
                    px: { md: 5 },
                    cursor: 'default',

                  }}
                />
                <Divider sx={{m: 5, mb: 3}}/>
                <Stack gap={2} paddingTop={2} paddingBottom={5}>

                  <Typography
                    variant="subtitle2"
                    sx={{
                      // pt: 2,
                      // pb: 5,
                      px: { md: 3 },
                      cursor: 'default',
                    }}
                  >
                    {`Rate: $${fCurrencyCents(post?.customRate ? post?.customRate : post?.rate)}`}
                  </Typography>
                  {(post?.terms || post?.customTerms) &&
                  <Typography
                    variant="subtitle2"
                    sx={{
                      // pt: 2,
                      // pb: 5,
                      px: { md: 3 },
                      cursor: 'default',
                    }}
                  >
                    {`Terms: ${post?.customTerms ? post?.customTerms : post?.terms}`}
                  </Typography>
                  }
                </Stack>
              </Box>
              </Grid>
              <Grid item 
                xs={12} sm={12} md={6} 
                sx={{ 
                  display: 'flex', 
                  flexDirection: 'column', 
                  mb: 7,
                  pr: {
                    sm: 0,
                    md: 4,
                  },
                }}
              >
                <Box 
                  sx={{
                    // border: 'red solid 1px',
                    width: '100%',
                    p: 4,
                    mt: {
                      sm: 0,
                      md: 3,
                    },
                    // mb: 10,
                    borderRadius: 2,
                    // backgroundColor: theme.palette.grey[800],
                    backgroundColor: 'rgb(40, 40, 40)',
                    display: post.spotifyLink || post.appleMusicLink ? 'block' : 'none',
                  }}
                >
                <Typography
                  variant="h3"
                  sx={{
                    pb: 3,
                    pt: 2,
                    color: theme.palette.common.white,
                    // py: 5,
                    // px: { md: 5 },
                  }}
                  >
                  Reference Tracks
                </Typography>
                  <iframe style={{borderRadius: "12px",}} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator&theme=0`} width="100%" height="452" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist'/>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              mx: 3,
            }}>
              <Typography variant='h3'
                sx={{pb: 3}}
              >
                Submissions
              </Typography>
            </Box>
            <Box 
              onKeyDown={handleKeyDown} 
              sx={{                  
                mx: {
                  sm: 0,
                  md: 4,
                },

              }}
              >
              {songPitches.map((doc, index) => <AudioFileCardDemo 
                      key={doc._id}
                      id={doc._id}
                      userId={userr} 
                      user={user}
                      file={doc}
                      data={data}
                      dispatch={dispatch}
                      addToCart={addToCart}
                      refetch={pitchesRefetch}
                      loadedSong={loadedSong} 
                      setLoadedSong={setLoadedSong}
                      setSelectedIndex={setSelectedIndex}
                      selectedIndex={selectedIndex}
                      setSelectedId={setSelectedId}
                      index={index}
                      selected={selectedIndex === index}
                      sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                      setControlMessage={setControlMessage}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      audioBarFavorite={audioBarFavorite}
                      setAudioBarFavorite={setAudioBarFavorite}
                      favorites
                      passable
                      briefId={briefId}
                      // editable
                    />
              )}
            </Box>


          </Stack>
        </Box>
        )
        }

        {errorMsg && !loadingPost && <Typography variant="h6">404 {errorMsg}</Typography>}

      </Container>

      <Footer/>
      <CustomToast toast={toast} setToast={setToast} />

      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        audioBarFavorite={audioBarFavorite}
        setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        // refetch={refetch}          
      />
    </>
  );
}
