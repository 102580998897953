import { gql } from 'graphql-tag';

export const GET_ALL_USERS = gql`
    query GetAllUsers {
        getAllUsers {
            email
            name
            _id
            joinDate
            plan
            planStart
            publisher
            pro
            role
            tier
            organization 
        }
    }
`

export const GET_ALL_DOCS = gql`
    query GetDocuments {
    getDocuments {
        _id
        title
        bpm
        key
        scale
        audioUrl
        multitrackUrl
        legalDocs
        created_at
        updated_at
        userId
        claimed
        playlist
        artist {
            name
            artwork
            smallArtwork
            bio
        }
        writer
        publisher
        album
        tags {
            genre
            mood
            instrument
            theme
        }
        favorited
        pass
        pitches {
            briefId
            briefTitle
            briefCategory
            status
            created_at
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }  
        }
        inCart {
            _id
            orgName
            price
        }
        price
        cleared
        legalDocs
        feedback {
            date
            message
            sender {
                _id
                name
                avatarUrl
            }
        }    
        }
    }
`

export const GET_USER_DOCS_BY_ID = gql`
    query GetDocumentsByOwnerId(
        $getDocumentsByOwnerIdId: String
    ) {
        getDocumentsByOwnerId(
            id: $getDocumentsByOwnerIdId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                briefCategory
                status
                created_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`

export const GET_USER_SOLD_DOCS_BY_ID = gql`
    query GetSoldDocumentsByOwnerId(
        $userId: String
    ) {
        getSoldDocumentsByOwnerId(
            id: $userId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                briefCategory
                status
                created_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`

export const GET_USER_AVAILABLE_DOCS_BY_ID = gql`
    query GetAvailableDocumentsByOwnerId(
        $userId: String
    ) {
        getAvailableDocumentsByOwnerId(
            id: $userId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                briefCategory
                status
                created_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`

export const GET_SUBMITTED_DOCS = gql`
    query getSubmittedDocs {
        getSubmittedDocs{
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                briefCategory
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`

export const GET_ARTIST_SONGS_BY_USER_ID = gql`
    query GetArtistSongsByUserId(
        $userId: String
        $artistId: String
        ) {
            getArtistSongsByUserId(
                id: $userId
                artist: $artistId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            legalDocs
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                briefCategory
                status
            }
            inCart {
                _id
                orgName
                price
            }
            price
        }
    }
`
export const GET_DOCS_BY_ORG_PITCH = gql`
    query getDocumentsByPitchedToOrg(
        $orgId: String
    ) {
        getDocumentsByPitchedToOrg(
            orgId: $orgId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                organization
                briefId
                briefTitle
                briefCategory
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`

export const GET_DOCS_BY_ORG_PASSED = gql`
    query getDocumentsByPitchedToOrgPassed(
        $orgId: String
    ) {
        getDocumentsByPitchedToOrgPassed(
            orgId: $orgId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                organization
                briefId
                briefTitle
                briefCategory
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`


export const GET_USER_PROFILE_STATS = gql`
    query GetUserProfileStats(
        $id: ID
    ) {
        getUserProfileStats(
            id: $id
        ) {
            totalPitchedSongs
            
            claimedSongs

        }
    }
`

export const GET_ALL_CLIENT_USERS = gql`
    query GetAllClients {
        getAllClients {
            email
            name
            _id
            role
            organization 
        }
    }
`


export const GET_USER_CART = gql`
    query getUserCart(
        $orgId: String
    ) {
        getUserCart(
            orgId: $orgId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            legalDocs
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                _id
                name
                bio
                artwork
                smallArtwork
                created_at
            }
            pitches {
                organization
                briefId
                briefTitle
                briefCategory
                rate
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            writer
            publisher
            album
            tags {
                genre
                mood
                instrument
            }            
            favorited
            pass
            inCart {
                _id
                orgName
                price
            }
            price
        }
    }
`

export const GET_USER_DOCS_BY_EMAIL = gql`
    query GetDocumentsByOwnerEmail($email: String) {
    getDocumentsByOwnerEmail(email: $email) {
        _id
        title
        updated_at
        created_at
        owner {
            _id
                name
                email
                added
                percentage
                publisher
                pro
                status
        }
    }
}`



export const GET_USER_BY_ID = gql`
    query GetUserById(
        $id: ID
    ) {
        getUserById(
            id: $id
        ) {
            _id
            name
            email
            theme
            role
            publisher
            pro
            collaborators {
                _id
                name
                email
                lastDate
            }
            ai_usage {
                month
                total
            }
            avatar
            alias {
                _id
                name
                bio
                artwork
                smallArtwork
                largeArtwork
                created_at
            }        
        }
    }
`

export const GET_DOCUMENT_BY_ID = gql`
    query GetDocumentById(
        $documentId: ID
    ) {
        getDocumentById(
            id: $documentId
        ) {
            _id
            title
            originalFileName
            lyrics
            artist {
                name
                artwork
                smallArtwork
                largeArtwork
                bio
            }
            writer
            publisher
            album
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            inCart {
                _id
                orgName
                price
            }
            pitches {
                briefId
                briefTitle
                briefCategory
                status
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }
        }
}   
`


export const GET_ALL_BRIEFS = gql`
    query GetAllBriefs {
    getAllBriefs {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            rate
            terms
            customTerms
            description
            content
            cover
            tags
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }
    }
}
`
export const GET_ACTIVE_BRIEFS = gql`
    query GetActiveBriefs (
        $userTier: Int
    ){
    getActiveBriefs (
        userTier: $userTier
    ){
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            customRate
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }
    }
}
`
export const GET_ACTIVE_BRIEFS_BY_ORG = gql`
query GetActiveBriefsByOrg(
    $orgId: String
    ) {
        getActiveBriefsByOrg(
            orgId: $orgId
            ) {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }

    }
}   
`
export const GET_INACTIVE_BRIEFS_BY_ORG = gql`
query GetInactiveBriefsByOrg(
    $orgId: String
    ) {
        getInactiveBriefsByOrg(
            orgId: $orgId
            ) {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }

    }
}   
`


export const GET_INACTIVE_BRIEFS = gql`
    query GetInactiveBriefs {
    getInactiveBriefs {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }
    }
}
`

export const GET_BRIEF_BY_ID = gql`
query GetBriefById(
    $briefId: ID
    ) {
        getBriefById(
            id: $briefId
            ) {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            customRate
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            youtubeLink
            organization
            quantity
            fulfilled
            priceId {
                _id
                description
                amount
            }
        }

    }
}   
`
export const GET_PITCHES_BY_BRIEF_ID = gql`
query GetPitchesByBriefId(
    $briefId: ID
    ) {
        getPitchesByBriefId(
            id: $briefId
            ) {
                _id
                title
                bpm
                key
                scale
                lyrics
                audioUrl
                multitrackUrl
                legalDocs
                created_at
                updated_at
                userId
                claimed
                playlist
                artist {
                    name
                    artwork
                    smallArtwork
                    bio
                }
                album
                tags {
                    genre
                    mood
                    instrument
                    theme
                }
                favorited
                pass
                pitches {
                    briefId
                    briefTitle
                    briefCategory
                    status
                    passQueue
                }
                inCart {
                    _id
                    orgName
                    price
                }
                price
    }
}   
`
export const GET_ALL_OPEN_PITCHES = gql`
query getAllOpenPitches {
        getAllOpenPitches {
                _id
                title
                bpm
                key
                scale
                audioUrl
                multitrackUrl
                legalDocs
                created_at
                updated_at
                userId
                claimed
                playlist
                artist {
                    name
                    artwork
                    smallArtwork
                    bio
                }
                album
                tags {
                    genre
                    mood
                    instrument
                    theme
                }
                favorited
                pass
                pitches {
                    briefId
                    briefTitle
                    briefCategory
                    status
                }
                inCart {
                    _id
                    orgName
                    price
                }
                price
    }
}   
`


export const GET_ALL_INVOICES = gql`
    query GetAllInvoices {
    getAllInvoices {
        _id
        status
        items {
            _id
            title
            artist {
                _id
                name
                artwork
                smallArtwork
                largeArtwork
            }
            tags {
                genre
                instrument
                mood
                theme
            }
            price
            cleared
            legalDocs
            multitrackUrl
        }
        dueDate
        created_at
        invoiceTo
        organization {
            _id
            name
            address
            city
            state
            zip
            phone
            paymentType
            lastFour
            rate
        }
        subTotal
        salesTax
        totalPrice
        discount
        updated_at
    }
}
`
export const GET_INVOICES_BY_ORG_ID = gql`
query GetInvoicesByOrgId(
    $orgId: String
    ) {
        getInvoicesByOrgId(
            orgId: $orgId
            ) {
        _id
        status
        items {
            _id
            title
            artist {
                _id
                name
                artwork
                smallArtwork
            }
            tags {
                genre
                instrument
                mood
                theme
            }
            price
            cleared
            legalDocs
            multitrackUrl
        }
        dueDate
        created_at
        invoiceTo
        organization {
            _id
            name
            address
            city
            state
            zip
            phone
            paymentType
            lastFour
            rate
        }
        subTotal
        salesTax
        totalPrice
        discount
        updated_at
    }
}
`
export const GET_INVOICE_BY_ID = gql`
query GetInvoiceById(
    $id: String
    ) {
        getInvoiceById(
            id: $id
            ) {
        _id
        status
        items {
            _id
            title
            artist {
                _id
                name
                artwork
                smallArtwork
                email
            }
            bpm
            key
            scale
            tags {
                genre
                instrument
                mood
                theme
            }
            price
            cleared
            legalDocs
            multitrackUrl
            audioUrl
        }
        dueDate
        created_at
        invoiceTo
        organization {
            _id
            name
            address
            city
            state
            zip
            phone
            paymentType
            lastFour
            rate
        }
        subTotal
        salesTax
        totalPrice
        discount
        updated_at
    }
}
`


export const GET_ALL_ORGANIZATIONS = gql`
    query GetAllOrgs {
        getAllOrgs {
            _id
            name
        }
    }
`

export const GET_ORG_INFO = gql`
    query getOrgInfo(
        $orgId: String
    ) {
        getOrgInfo(
            orgId: $orgId
        ) {
            _id
            name
            address
            city
            state
            zip
            phone
            paymentType
            lastFour
            rate
        }
    }
`
export const GET_ORG_PLAYLISTS = gql`
query GetOrgPlaylists(
    $orgId: String
    ) {
        getOrgPlaylists(
            orgId: $orgId
            ) {
        _id
        name
        createdAt
        songs
    }
}
`
