// routes

// components
import isAA from '../../../components-custom/isAA';
import Iconify from '../../../components-default/iconify';

// ----------------------------------------------------------------------



export const navConfig = [

  {
    title: 'Inventory',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/inventory',
  },
  {
    title: 'Briefs',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/briefs',
  },  
  {
    title: 'Upload',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/upload',
  },  
  {
    title: 'Dashboard',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/dash',
  },  {
    title: 'Invoices',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/invoice',
  },
];

export const navConfigAdmin = [

  {
    title: 'Dashboard',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/dash',
  },  
  {
    title: 'Inventory',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/inventory',
  },
  {
    title: 'Briefs',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/briefs',
  },  
  {
    title: 'Invoices',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/invoice',
  },
  {
    title: 'Users',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/users',
  },
  {
    title: 'Utility',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/adminutility',
  },
];

export const navConfigAdminTB = [

  {
    title: 'Dashboard',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/dash',
  },  
  {
    title: 'Inventory',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/inventory',
  },
  {
    title: 'Briefs',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/briefs',
  },  
  {
    title: 'Invoices',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/invoice',
  },
  {
    title: 'Users',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/users',
  },
  {
    title: 'Utility',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/adminutility',
  },
  {
    title: 'Cart',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/cart',
  },
];

export const navConfigClient = [

  {
    title: 'Dashboard',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/dash',
  },  
  {
    title: 'Briefs',
    icon: <Iconify icon="pajamas:doc-chart" />,
    path: '/briefs',
  },  
  {
    title: 'Inventory',
    icon: <Iconify icon="solar:playlist-bold" />,
    path: '/inventory',
  },  
  {
    title: 'Orders',
    icon: <Iconify icon="tabler:file-invoice" />,
    path: '/orders',
  },
  {
    title: 'Categories',
    icon: <Iconify icon="tabler:file-invoice" />,
    path: '/categories',
  },
  {
    title: 'Cart',
    icon: <Iconify icon="bi:cart" />,
    path: '/cart',
  },
];

export const navConfigProducer = [

  {
    title: 'Dashboard',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/dash',
  },  
  {
    title: 'Inventory',
    icon: <Iconify icon="eva:music-fill" />,
    path: '/inventory',
  },  
  {
    title: 'Briefs',
    icon: <Iconify icon="pajamas:doc-chart" />,
    path: '/briefs',
  },  
  {
    title: 'Upload',
    icon: <Iconify icon="material-symbols:upload" />,
    path: '/upload',
  },  
];

export const navConfigNoUser = [

  {
    title: 'Register',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/auth/register',
  },  
  {
    title: 'Login',
    icon: <Iconify icon="ic:round-grain" />,
    path: '/auth/login',
  },  
];

