import PropTypes from 'prop-types';
import { useState, useEffect } from'react';
import { useNavigate } from 'react-router';


// @mui
import {
  Box,
  Card,
  Stack,
  Button,
  Divider,
  CardHeader,
  Typography,
  CardContent,
} from '@mui/material';
// utils
// import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from '../utils/axios';

import { fCurrencyCents } from '../utils/formatNumber';

// components
import Iconify from '../components-default/iconify';
import CheckoutPayment from './sections/@dashboard/e-commerce/checkout/payment/CheckoutPayment';
import isAA from './isAA';

// ----------------------------------------------------------------------

CheckoutSummary.propTypes = {
  onEdit: PropTypes.func,
  total: PropTypes.number,
  totalItems: PropTypes.number,
  discount: PropTypes.number,
  subtotal: PropTypes.number,
  cartTotal: PropTypes.number,
  enableEdit: PropTypes.bool,
  enableDiscount: PropTypes.bool,
  onApplyDiscount: PropTypes.func,
  user: PropTypes.object,
  userr: PropTypes.string,
  cartItemsDetail: PropTypes.array,
  orgData: PropTypes.object,
};

export default function CheckoutSummary({
  total,
  totalItems,
  onEdit,
  discount,
  subtotal,
  cartTotal,
  onApplyDiscount,
  enableEdit = false,
  enableDiscount = false,
  user,
  userr,
  cartItemsDetail,
  orgData
}) {

  const [totalSongs, setTotalSongs] = useState()
  const [rates, setRates] = useState()
  const [subTotal, setSubTotal] = useState()

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();



  // console.log('CART ITEMS DETAIL: ', cartItemsDetail)


// WORKING 
  const handleCreateInvoiceAndPay = async () => { // eslint-disable-line
    setLoading(true);
    try {


      const res = await axios.post(`${process.env.REACT_APP_SERVER}/createinvoice`, {  // eslint-disable-line
          headers: {
              'Content-Type':'',
          },
            userId: userr,
            name: user.name,
            email: user.email,
            invoiceTo: user.name,
            organization: user.organization,
            cartItemsDetail: cartItemsDetail, // eslint-disable-line

      }).then(
        async (response) => {
          
          setLoading(false)
          // console.log(response)

          const data = response.data;

          function openPaymentWindow(){
            window.open(`${response.data?.paymentUrl}?client_reference_id=${response.data?.invoiceId}`)
          }

          openPaymentWindow()

          console.log('RESPONSE: ', data)

      });
    } catch (err) {
      console.log(err)
    }
  }
// WORKING 
  const handleCreateMultiInvoice = async () => { // eslint-disable-line
    setLoading(true);
    try {


      const res = await axios.post(`${process.env.REACT_APP_SERVER}/testmultiinvoice`, {  // eslint-disable-line
          headers: {
              'Content-Type':'',
          },
            userId: userr,
            name: user.name,
            email: user.email,
            invoiceTo: user.name,
            organization: user.organization,
            cartItemsDetail: cartItemsDetail, // eslint-disable-line

      }).then(
        async (response) => {
          
          setLoading(false)
          // console.log(response)

          const data = response.data;

          function openPaymentWindow(){
            window.open(`${response.data?.paymentUrl}?client_reference_id=${response.data?.invoiceId}`)
          }

          openPaymentWindow()

          console.log('RESPONSE: ', data)

      });
    } catch (err) {
      console.log(err)
    }
  }

// WORKING 
  const handleTrackbaseBuy = async () => { // eslint-disable-line
    setLoading(true);
    try {


      const res = await axios.post(`${process.env.REACT_APP_SERVER}/trackbasebuy`, {  // eslint-disable-line
          headers: {
              'Content-Type':'',
          },
            userId: userr,
            name: user.name,
            email: user.email,
            invoiceTo: user.name,
            organization: user.organization,
            cartItemsDetail: cartItemsDetail, // eslint-disable-line

      }).then(
        async (response) => {
          
          setLoading(false)
          // console.log(response)

          const {data} = response;

          // console.log(`/orders/${data?.invoiceId}`)
          navigate(`/orders/${data?.invoiceId}`)
          // console.log('RESPONSE: ', data)

      });
    } catch (err) {
      console.log(err)
    }
  }


  const handleTestPayment = async () => { // eslint-disable-line
    setLoading(true);
    try {

      const res = await axios.post(`${process.env.REACT_APP_SERVER}/testpayment`, {  // eslint-disable-line
          headers: {
              'Content-Type':'',
          },
            userId: userr,
            name: user.name,
            email: user.email,
            invoiceTo: user.name,
            organization: user.organization,
            // paymentMethod: paymentMethod.paymentMethod.id,
            // cartItemsDetail: cartItemsDetail, // eslint-disable-line

      }).then(
        async (response) => {
          
          setLoading(false)
          // console.log(response)

          const data = response.data;

          function openPaymentWindow(){
            window.open(`${response.data?.checkoutPage}`)
          }

          openPaymentWindow()

          console.log('RESPONSE: ', data)

      });
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    const ratesArray = []

    cartItemsDetail.map(item => { // eslint-disable-line

      if (!ratesArray.includes(item.price)){
        return ratesArray.push(item.price)
      }

    })
    console.log('RATES ARRAY: ', ratesArray)

    // console.log('RATES ARRAY: ', ratesArray)
    setRates(ratesArray)
    setTotalSongs(totalItems)
    setSubTotal(totalItems*user.rate)
  }, [totalItems]) // eslint-disable-line

  return (
    <>

    <Card sx={{ mb: 3 }}>
      <CardHeader
        title="Order Summary"
        action={
          enableEdit && (
            <Button size="small" onClick={onEdit} startIcon={<Iconify icon="eva:edit-fill" />}>
              Edit
            </Button>
          )
        }
      />

      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.primary' }}>
              Songs
            </Typography>
            <Typography variant="subtitle2">{totalSongs}</Typography>
          </Stack>
          {rates?.length === 1 &&
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2" sx={{ color: 'text.primary' }}>
                Rate
              </Typography>
              <Typography variant="subtitle2">${fCurrencyCents(rates[0])}</Typography>
            </Stack>
          }
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Sub Total
            </Typography>
            <Typography variant="subtitle2"  sx={{ color: 'text.secondary' }}>${fCurrencyCents(cartTotal)}</Typography>
          </Stack>

          <Divider />

          <Stack direction="row" justifyContent="space-between">
            <Typography variant="subtitle1">Total</Typography>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                ${fCurrencyCents(cartTotal)}
              </Typography>

            </Box>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
    <Button 
      fullWidth 
      sx={{
        display: isAA() ? 'inherit' : 'none',
      }}
      variant='contained' 
      color='primary'
      onClick={()=> {
        // handleCreateInvoiceAndPay();
        handleCreateMultiInvoice();
      }}
    >
      Pay
    </Button>
    {(user?.role === 'admin' || user?.email === 'client@audioarsenal.io') &&
      <Button 
        fullWidth 
        sx={{
          display: 'none',
        }}
        variant='contained' 
        color='primary'
        onClick={()=> {
          handleTestPayment();
        }}
      >
        Test Payment
      </Button>
    }

    {(user?.role === 'admin' && !isAA()) &&
      <Button 
        fullWidth 
        disabled={loading}
        variant='contained' 
        color='primary'
        onClick={()=> {
          handleTrackbaseBuy();
        }}
      >
        TRACKBASE BUY
      </Button>
    }

    </>
  );
}
