import orderBy from 'lodash/orderBy';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { Box, Grid, Button, Container, Typography, Stack } from '@mui/material';
// utils
import { useQuery } from "@apollo/client";
import { GET_ALL_BRIEFS, GET_ALL_OPEN_PITCHES } from '../graphql/queries'
import axios from '../utils/axios';
import Footer from '../layouts/main/Footer';
// routes
// components
import Iconify from '../components-default/iconify';
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
import useCopyToClipboard from '../hooks/useCopyToClipboard';

// sections
import BriefPostCardV2Admin from '../components-custom/sections/@dashboard/blog/BriefPostCardV2Admin';
// import { BriefPostCard, BlogPostsSort, BriefPostCardV2, } from '../components-custom/sections/@dashboard/blog';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';

// graphql

// ----------------------------------------------------------------------



// ----------------------------------------------------------------------

export default function BriefsAdmin() {

  const { themeStretch } = useSettingsContext();

  const [allCurrentPitches, setAllCurrentPitches] = useState([]);

  const [sortBy, setSortBy] = useState('latest');

  const navigate = useNavigate()
  const { copy } = useCopyToClipboard();



  const organizationsList = [{_id: '63779527-dc9f-4d96-91bb-52fe8117c3ff', name: 'WavMaker'}, {_id: '7ad1f430-59e0-4439-9211-fef4fe7438c8', name: 'Music Assets'}]


  const { loading: pitchesLoading, error: pitchesError, data: pitchesData = {getAllOpenPitches: []}, refetch: pitchesRefetch } = useQuery(GET_ALL_OPEN_PITCHES, { // eslint-disable-line
    fetchPolicy: 'network-only'
  });

  const { loading, error, data = {getAllBriefs: []}, refetch } = useQuery(GET_ALL_BRIEFS, { // eslint-disable-line
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data) {
      console.log(data);
    }
  }, [data]); 

  useEffect(() => {
    if (pitchesData.getAllOpenPitches.length > 0 && pitchesData.getAllOpenPitches !== allCurrentPitches) {
      // setAllCurrentPitches(pitchesData.getAllOpenPitches)
      console.log('PITCHES DATA: ', pitchesData);
    }
  }, [pitchesData, allCurrentPitches]); 

  const handleChangeSortBy = (event) => {
    setSortBy(event.target.value);
  };

  function getOrgName(orgId){
    let returnValue = ''
    organizationsList.map(item => { // eslint-disable-line
      // console.log('ORG ID: ', orgId)
      // console.log('ITEM: ', item._id)
      if (orgId === item._id) {
        returnValue = item.name
        // return item.name
      }
    })
    return (returnValue)
  }

  // function getBriefPitchesTotal(briefId){

  //   const pitchedSongList = []

  //   if (!pitchesLoading || !loading){
  //     allCurrentPitches.map(item => { // eslint-disable-line
  //       if (briefId === item?.pitches[item.pitches.length - 1].briefId){
  //         pitchedSongList.push(item)
  //       }
  //     })
  //   }
  //   return pitchedSongList.length

  // }

  // console.log('ORG DATA: ', orgData)

  if (loading || pitchesLoading){
    return <LoadingScreen/>
  }

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | All Briefs </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Briefs (Admin View)"
          links={[
            {
              name: 'Dashboard',
              href: 'admindashboard',
            },
          ]}
          action={
            <Button
              component={RouterLink}
              to='create'
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              New Post
            </Button>
          }
          sx={{cursor: 'default', }}
        />

{data.getAllBriefs.length !== 0 ? 
        <Grid container spacing={3} sx={{mb: 10}}>
          {data.getAllBriefs.map((post, index) =>
           (
             <Grid key={post._id} item xs={12} sm={6} md={4}>
                <Stack spacing={1}>
                  <BriefPostCardV2Admin
                    post={post.post} 
                    id={post._id} 
                    cover={post.post.cover !== '' ? post.post.cover : '/logo/pikes.svg'} 
                    index={index} 
                    orgName={getOrgName(post?.post?.organization)}
                    // pitchedSongs={pitchesData.getAllOpenPitches.length}
                  />
                  <Stack direction='row' spacing={2}>
                    <Button variant='text'
                      onClick={()=>{
                        navigate(`/briefs/edit/${post._id}`)
                      }}
                    >
                      Edit
                    </Button>
                    <Button variant='text'
                      onClick={()=>{
                        copy(post._id)
                      }}
                    >
                      Copy Id
                    </Button>
                  </Stack>
                </Stack>
              </Grid>

          )) }
        </Grid> :
        <Box sx={{
          p: 5,
          pb: 10,
        }}>
          <Typography variant='body'>No briefs currently.</Typography>

        </Box>
        }
        {/* <iframe style={{borderRadius: "12px"}} src="https://open.spotify.com/embed/playlist/70sZcwHf23UBt7d44j2sqb?utm_source=generator" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist'/> */}
        {/* <iframe allow="autoplay *; encrypted-media *; clipboard-write" frameBorder="0" height="450" style={{width: '100%', maxWidth: '100%', overflow: 'hidden', borderRadius: '10px', background: 'red !important'}} sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="https://embed.music.apple.com/us/playlist/numa-runs/pl.u-GgA5kaztkKPEx" title='apple-embed'/> */}
      </Container>
      <Footer/>

    </>
  );
}

// ----------------------------------------------------------------------

const applySortBy = (posts, sortBy) => {
  if (sortBy === 'latest') {
    return orderBy(posts, ['createdAt'], ['desc']);
  }

  if (sortBy === 'oldest') {
    return orderBy(posts, ['createdAt'], ['asc']);
  }

  if (sortBy === 'popular') {
    return orderBy(posts, ['view'], ['desc']);
  }
  return posts;
};
