import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// @mui
import { useTheme } from '@mui/material/styles';

import { Button, Container, Typography, DialogActions, Dialog, Divider, Stack, Grid, TextField, Select, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LoadingIcons from 'react-loading-icons'
import axios from '../utils/axios';

// components
import Scrollbar from '../components-default/scrollbar';

import isAA from './isAA';
import Image from './image';

// ----------------------------------------------------------------------

OriginalBriefsDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  setOriginalBriefsDialogOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  songInfo: PropTypes.object,
  isSubmitting: PropTypes.bool,
  transferDoc: PropTypes.object,
  setTransferDoc: PropTypes.func,
  setToast: PropTypes.func,
};

export default function OriginalBriefsDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  setOriginalBriefsDialogOpen,
  songInfo,
  onSubmit,
  transferDoc,
  setTransferDoc,
  setToast
}) {

  const theme = useTheme()

  const [displayBriefs, setDisplayBriefs]=useState([])
  const [briefsLoading, setBriefsLoading]=useState(false)
  const [pitchBrief, setPitchBrief]=useState(null)
  const [artistList, setArtistList]=useState([])
  const [newArtist, setNewArtist]=useState('')
  const [newTitle, setNewTitle]=useState('')

  async function getAABriefs(){
    try {
      setBriefsLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getAAbriefs`, {  // eslint-disable-line
      }).then(response => {
        console.log(response?.data)
        setDisplayBriefs(response?.data?.briefData?.briefs)
        setBriefsLoading(false)
      });
    } catch (err) {
      console.log(err)
    }   
  }

  async function getArtistList(){
    try {
      setBriefsLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getAAuser`, {  // eslint-disable-line
        userEmail: 'mthomas82flicks@gmail.com',
      }).then(response => {
        setArtistList(response?.data?.userArtists)
      });
    } catch (err) {
      console.log(err)
    }   
  }

  async function makeAAPitch(){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/pitchtoAA`, {  // eslint-disable-line
        originalDoc: transferDoc,
        newArtist,
        newTitle,
        brief: pitchBrief,
        userId: '64c27c7a063f1cd4634cfb24',
        // test: true,

      }).then(response => {
        if (response.status === 200){
          setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${newTitle} successfully pitched to ${pitchBrief?.post?.title}`, duration: 4000})
          setPitchBrief(null)
          setNewArtist('')
          setNewTitle('')
          onClose()
      }
      });
    } catch (err) {
      console.log(err)
    }   
  }

  useEffect(()=>{
    if (open){
      // console.log('OPEN')
      getAABriefs()
      getArtistList()
    }

  }, [open])

  return (
    <Dialog fullScreen open={open} onClose={onClose} 
      sx={{
        overflowY: 'scroll',
      }}
      >
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Audio Arsenal Briefs
        </Typography>

        <Button variant="outlined" color="inherit" 
          onClick={()=>{
            setPitchBrief(null)
            setNewArtist('')
            setNewTitle('')
            onClose()
          }}
        >
          Close
        </Button>
      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 5, 
              mb: 10, 
              // ml: 10, 
              // mr: 20, 
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Stack direction='row' spacing={2} sx={{
                width: '100%',
                justifyContent: 'flex-start',
                p: 2,
                borderRadius: 2,
                border: 'white solid 1px',
                mb: 2,
              }}>
                <Stack spacing={1}>
                  <Typography variant='subtitle1'>
                    {transferDoc?.title}
                  </Typography>
                  <Typography variant='caption'>
                    {transferDoc?.artist?.name}
                  </Typography>
                </Stack>
                {pitchBrief !== null ?
                <Stack direction='row' spacing={1}a
                  sx={{
                    height: '100px',
                    width: '300px',
                    p: 2,
                    justifyContent: 'center',
                    borderRadius: 2,
                    border: 'white solid 1px',
                    backgroundColor: theme.palette.grey[900],
                  }}
                >
                  <Stack spacing={1}
                    sx={{
                    }}
                  >
                    <Typography variant='subtitle1' width='100px'>
                      {pitchBrief?.post?.title}
                    </Typography>
                    <Typography variant='caption'>
                      {pitchBrief?.post?.category}
                    </Typography>
                  </Stack>
                  <Image src={pitchBrief?.post?.cover} 
                      sx={{
                        borderRadius: 1,
                        height: '100%',
                      }}
                  />
                </Stack> :
                <Stack direction='row' spacing={1}a
                  sx={{
                    height: '100px',
                    width: '300px',
                    px: 2,
                    borderRadius: 2,
                    border: 'white solid 1px',
                    backgroundColor: theme.palette.grey[900],
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                <Typography variant='h5'>
                  Select Brief
                </Typography>
                </Stack>
                }
                <Stack spacing={1}
                  sx={{
                  }}
                >
                  <Typography variant='subtitle1' width='100px'>
                    New Title
                  </Typography>
                  <TextField 
                    value={newTitle}
                    onChange={(e)=>{
                      setNewTitle(e.target.value)
                    }}
                  />
                </Stack>
                <Stack spacing={1}
                  sx={{
                    width: '300px',
                  }}
                >
                  <Typography variant='subtitle1' width='100px'>
                    New Artist
                  </Typography>
                  <Select
                    id='artist-select'
                    value={newArtist}
                    defaultValue={newArtist}
                    onChange={(e)=>{
                      setNewArtist(e.target.value)
                    }}
                    inputProps={{ 'aria-label': 'Plan' }}
                    fullWidth
                    sx={{
                      // mr: 2,
                    }}
                  >
                    {artistList.map(item => { // eslint-disable-line
                      return <MenuItem value={item} selected={newArtist === item} key={item?._id}
                      >
                        {item?.name}
                      </MenuItem>

                    })}

                  </Select> 
                </Stack>
                <Button variant='text'
                  onClick={()=>{
                    makeAAPitch()
                  }}
                >
                  <Image src={newArtist?.smallArtwork || newArtist?.artwork}
                    sx={{
                      height: '100px',
                      borderRadius: 2,
                      border: 'white solid 1px',
                    }}
                    />
                  <Typography variant='h5' sx={{
                    position: 'absolute', 
                    color: 'white',
                    '&:hover':{
                      color: theme.palette.primary.main,
                    }

                }}>
                    PITCH
                  </Typography>
                </Button>

              </Stack>
              <Grid container spacing={3} 
                sx={{
                  pb: '50px',
                  
                }}
              >

              {!briefsLoading ? displayBriefs.map((item, index) => { // eslint-disable-line
                return <Grid item key={item._id}
                xs={4}
                >
                  <Stack sx={{
                      height: '100px',
                      borderRadius: 2,
                      border: pitchBrief?._id === item?._id ? `${theme.palette.primary.lighter} solid 3px` : 'white solid 1px',
                      backgroundColor: pitchBrief?._id === item?._id ? theme.palette.primary.dark : 'inherit',
                      p: 1,
                      cursor: 'pointer',
                    }}
                    onClick={()=>{
                      setPitchBrief(item)
                    }}
                  >
                    <Image src={item.post.cover} 
                      sx={{
                        borderRadius: 1,
                      }}
                    />
                    <Stack direction='row'
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                        pt: 1,
                      }}
                    >
                      <Typography variant='subtitle1'>
                        {item?.post?.title}
                      </Typography>
                      <Typography variant='body'>
                        {item?.post?.category}
                      </Typography>
                      <Typography variant='caption'>
                        {item?.post?.subGenres || item?.post?.multiGenres[0]}
                      </Typography>

                    </Stack>
                  </Stack>
                </Grid>
              }) :
              <LoadingIcons.TailSpin stroke='white' width='100%'/>
            }

              </Grid>
          </Container>
        </Scrollbar>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

