import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';
import { CardElement, Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';


import MailRoundedIcon from '@mui/icons-material/MailRounded';
import axios from '../utils/axios';
import { UploadAvatar, Upload, UploadBox } from './upload';
import Image from './image';
import TextfieldDisplay from './TextfieldDisplay';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


PaymentDialog.propTypes = {
    paymentDialogOpen: PropTypes.bool,
    setPaymentDialogOpen: PropTypes.func,
    user: PropTypes.object,
    userr: PropTypes.string,
    refetch: PropTypes.func,
    // toast: PropTypes.object,
    // setToast: PropTypes.func,
  };



export default function PaymentDialog({paymentDialogOpen, setPaymentDialogOpen, user, userr, refetch}) {

  const theme = useTheme();
  
  const [newArtistInfo, setNewArtistInfo] = useState({name: '', email: '', bio: ''})
  const [fileHolder, setFileHolder] = useState('')
  const [loading, setLoading] = useState(false)
  
  const stripe = useStripe()
  const elements = useElements()

  const handleClose = () => {
    setPaymentDialogOpen(false);
  };


  const handleSubmit = async (e) => { // eslint-disable-line
    e.preventDefault();
    setLoading(true);
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });

      const res = await axios.post(`${process.env.REACT_APP_SERVER}/payments`, {  // eslint-disable-line
          headers: {
              'Content-Type':'',
          },
            name: newArtistInfo.name,
            email: newArtistInfo.email,
            paymentMethod: paymentMethod.paymentMethod.id,

      }).then(
        async (response) => {
          
          setLoading(false)
          // console.log(response)

          const data = response.data;
          
          // const confirm = await stripe.confirmCardPayment(data.clientSecret);

          console.log('RESPONSE: ', data)

      });

      // if (!res.ok) return alert("Payment unsuccessful!");

      // const data = await res.json();

      // console.log(data.clientSecret)

      // if (confirm.error) return alert("Payment unsuccessful!");
      
      // return alert("Payment Successful! Subscription active.");
    ;
    } catch (err) {
      console.log(err.response.data)
    }              
    
    // setToast({open: true, message: `Email sent to ${email}`, icon: <MailRoundedIcon sx={{color: 'white'}}/>});
    handleClose();
  }
  


  return (
    <div>
      <Dialog
        open={paymentDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll='body'
        onClose={()=>{setPaymentDialogOpen(false)}}
        sx={{
            height: '100%',
            paper: {
            }
        }}
      >
        <Box 
          sx={{
            width: {
              sm: '300px',
              md: '500px',
            }, 
          }}
        >

        <DialogTitle 
          id="add-artist-title"
          sx={{
            pb: 0,
          }}
        >
          Payment
        </DialogTitle>

        <DialogContent scroll='body' >
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              minWidth: '100px',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
          >
              <TextfieldDisplay
                width='100%' 
                height='50px'
                message={user?.name} 
                // deleteButton 
                // onDelete={()=>{setArtistSelector('')}}
                sx={{
                  // border: 'red solid 1px !important',
                }}
              />
              <TextfieldDisplay
                width='100%' 
                height='50px'
                message={user?.email} 
                // deleteButton 
                // onDelete={()=>{setArtistSelector('')}}
                sx={{
                  mt: 2,
                  // border: 'red solid 1px !important',
                }}
              />

              <Box 
                sx={{
                  mt: 2,
                  p: 2,
                  border: `${theme.palette.divider} solid 1px`,
                  borderRadius: 1,
                }}
              >
                <CardElement />

              </Box>
          </Box>


        </DialogContent>

        <DialogActions>
          <Button 
            size='medium' 
            color='inherit' 
            variant="outlined" 
            onClick={handleClose} 
            sx={{px: "16px", py: "7px"}}
          >
            Cancel
          </Button>

          <LoadingButton 
            size='medium' 
            variant='contained'
            onClick={handleSubmit} 
            sx={{px: "16px", py: "7px"}}
            loading={loading}
          >
            Subscribe
          </LoadingButton>


        </DialogActions>
        </Box>

      </Dialog>
    </div>
  );
}
