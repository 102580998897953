import { Helmet } from 'react-helmet-async';

// @mui
import { Box, Container, Typography } from '@mui/material';
// sections
import { FaqsForm, FaqsListAA, FaqsListTB} from '../sections/faqs';
import Footer from '../layouts/main/Footer';
import isAA from '../components-custom/isAA';

// ----------------------------------------------------------------------

export default function AAFaqsPage() {
  return (
    <>
      <Helmet>
        <title> Faqs | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <Container sx={{ pt: 8, pb: 10, position: 'relative' }}>

        <Typography variant="h3" sx={{ mb: 5 }}>
          Frequently Asked Questions
        </Typography>

        <Box
          gap={10}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(1, 1fr)',
          }}
        >
          {isAA() ?
          <FaqsListAA /> :
          <FaqsListTB /> 
        }
          <FaqsForm />
        </Box>
      </Container>
      <Footer />
    </>
  );
}
