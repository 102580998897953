import { useState } from 'react';

import { useTheme } from '@mui/material/styles';

import { m } from 'framer-motion';

// @mui
import { Typography, TextField, Stack, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from '../../utils/axios';
//
import { varFade, MotionViewport } from '../../components-default/animate';
import Iconify from '../../components-default/iconify/Iconify';
import isAA from '../../components-custom/isAA';

// ----------------------------------------------------------------------

export default function FaqsForm() {

const theme = useTheme();

const [info, setInfo] = useState(
  {
    name: '',
    email: '',
    subject: '',
    message: ''
  }
)

const [loading, setLoading] = useState(false)
const [pageState, setPageState] = useState('form')



  async function handleSubmit(){
    setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/generalcontact`, {  // eslint-disable-line
        info
      }).then(response => {
        setTimeout(()=>{
          setLoading(false)
          setInfo({
            name: '',
            email: '',
            subject: '',
            message: ''
          })
          console.log(response)
          setPageState('sent')

        }, 1000)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  return (
    <Box sx={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
    <Stack component={MotionViewport} spacing={3}>
      <m.div variants={varFade().inUp}>
        <Typography variant="h4">Still have questions? {isAA() && 'Hit us up.'}</Typography>
      </m.div>
      <m.div variants={varFade().inUp}>
        <TextField fullWidth autoComplete='off' label="Name" value={info.name} onChange={(e)=>{setInfo({...info, name: e.target.value})}}/>
      </m.div>

      <m.div variants={varFade().inUp}>
        <TextField fullWidth autoComplete='off' label="Email" value={info.email} onChange={(e)=>{setInfo({...info, email: e.target.value})}}/>
      </m.div>

      <m.div variants={varFade().inUp}>
        <TextField fullWidth autoComplete='off' label="Subject" value={info.subject} onChange={(e)=>{setInfo({...info, subject: e.target.value})}}/>
      </m.div>

      <m.div variants={varFade().inUp}>
        <TextField fullWidth autoComplete='off' label="Enter your message here." multiline rows={4} value={info.message} onChange={(e)=>{setInfo({...info, message: e.target.value})}}/>
      </m.div>

      <m.div variants={varFade().inUp}>
        <LoadingButton loading={loading} size="large" variant="contained" onClick={()=>{handleSubmit()}}>
          Submit Now
        </LoadingButton>
      </m.div>
    </Stack>
    <Stack spacing={3} alignItems='center' justifyContent='center'>
      <Iconify icon='cil:check' 
        width='250px'
        sx={{
          display: pageState === 'sent' ? 'flex' : 'none',
          width: '250px', 
          color: theme.palette.primary.main,
      }}/>
      <Typography variant='h3' color='primary' sx={{display: pageState === 'sent' ? 'flex' : 'none',}}
      >
        Message sent
      </Typography>

    </Stack>

    </Box>

  );
}
