import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';


import { useMutation, useQuery } from "@apollo/client";

// routes
import { GET_USER_PROFILE_STATS } from '../../../../../../graphql/queries'
import { GET_BRIEF_BY_ID_M, GET_USER_PITCHES_BY_ID_M } from '../../../../../../graphql/mutations'

import { fData } from '../../../../../../utils/formatNumber';

import { useAuthContext } from '../../../../../../auth/useAuthContext';
import axios from '../../../../../../utils/axios';

//
import ProfileAbout from './ProfileAbout';
import ProfilePostCard from './ProfilePostCard';
import ProfilePostInput from './ProfilePostInput';
import ProfileFollowInfo from './ProfileFollowInfo';
import ProfileSocialInfo from './ProfileSocialInfo';
import FormProvider, {
  RHFSwitch,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../../../components-default/hook-form';

import { UploadAvatar, Upload, UploadBox } from '../../../../../upload';
import AddArtistDialog from '../../../../../AddArtistDialog';
import CreateOrganizationDialog from '../../../../../CreateOrganizationDialog';
import AccountDialog from '../../../../../AccountDialog';
import EditArtistDialog from '../../../../../EditArtistDialog';
import PaymentDialog from '../../../../../PaymentDialog';

import ArtistProfileCard  from '../../../blog/ArtistProfileCard'
import AAWidgetCard from '../../../../../AAWidgetCard';
import pikes from '../../../../../../assets/icons/pikes-white.svg'
import SongPitchedCard from '../../../../../SongPitchedCard';
import SongNoPitchesCard from '../../../../../SongNoPitchesCard';
import CustomToast from '../../../../../CustomToast';


// ----------------------------------------------------------------------

Profile.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
  data: PropTypes.array,
  refetch: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  controlMessage: PropTypes.string,
  setControlMessage: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  accountDialogOpen: PropTypes.bool,
  setAccountDialogOpen: PropTypes.func,
};


export default function Profile({ info, posts, data, refetch, loadedSong, setLoadedSong, controlMessage, setControlMessage, isPlaying, setIsPlaying, accountDialogOpen, setAccountDialogOpen }) {

  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  // console.log('PROFILE USER: ', user)

  const theme = useTheme();
  const navigate = useNavigate();

  const [getUserPitchesById] = useMutation(GET_USER_PITCHES_BY_ID_M);
  

  const [avatarHolder, setAvatarHolder] = useState(null)
  const [artistState, setArtistState] = useState([{alias: {name: '', bio: '', artwork: '', _id: '123'}}])

  const [artistDialogOpen, setArtistDialogOpen] = useState(false)
  const [organizationDialogOpen, setOrganizationDialogOpen] = useState(false)
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [allPitchesState, setAllPitchesState] = useState()

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
  );

  const { loading: statsLoading, error: statsError, data: statsData = {getUserProfileStats: []}, refetch: statsRefetch } = useQuery(GET_USER_PROFILE_STATS, {
    variables: { id: userr },
    fetchPolicy: 'network-only'
  });

  const [getBriefById] = useMutation(GET_BRIEF_BY_ID_M);


  async function getAllPitches(){
    const allPitches = await getUserPitchesById({ variables: { userId: userr } }); // eslint-disable-line
    setAllPitchesState(allPitches?.data?.getUserPitchesById)
  }

  useEffect(()=>{
    getAllPitches();
  },[]) // eslint-disable-line


  useEffect(()=>{
    if (data) {
      setArtistState(data)
    }
  }, [data])

  const handleDrop = (e) => {
    // console.log(e)
    setAvatarHolder(e[0])
  }

  const handleUpload = async () => {

    const coverBlob = avatarHolder !== null ? new Blob([avatarHolder, { type: avatarHolder.type }]) : '';
    const formData = new FormData();
    
    formData.append('fileType', avatarHolder !== null ? avatarHolder.type : '' );
    formData.append('userId', user._id);

    if (avatarHolder !== null) {
      formData.append('file', coverBlob, avatarHolder !== null ? avatarHolder.name : '');
    }
    
    // console.log('FORMDATA: ',formData)
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/uploadAvatar`, formData, {  // eslint-disable-line
            headers: {
                'Content-Type':'multipart/form-data',
            },
        }).then(response => {
          // successCallback();
          console.log(response)
        });
      } catch (err) {
          console.log(err.response.data)
      
    }
  }

  useEffect(()=>{

    // console.log(statsData)

  }, [statsData])


  return (
    <>
    <Grid container  
     // page grid, left cards, right artist icons
      sx={{
        pb: 10,
        ml: 0,
        mr: 2,
        // border: 'red solid 1px'
      }}
      >
      <Grid item xs={12} md={12}
      // left side cards
        sx={{
          pl: 0,

        }}
      
      >
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Songs Pitched' 
              total={statsData.getUserProfileStats[0]?.totalPitchedSongs || '-'} 
              statsLoading
              color='primary' 
              textColor={theme.palette.text.primary}
              cardColor={theme.palette.primary.light}
              icon="ant-design:send-outlined"
              />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Songs Sold' 
              total={statsData.getUserProfileStats[0]?.claimedSongs || '-'}
              statsLoading
              color='primary' 
              textColor={theme.palette.text.primary}
              cardColor={theme.palette.primary.main}
              icon="ant-design:dollar-outlined"
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Balance' 
              total= {100}
              totalPrefix='$'
              color='primary' 
              textColor={theme.palette.common.white}
              cardColor={theme.palette.success.dark}
              icon="ant-design:dollar-outlined"
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            {user.role === 'admin' ? 
              <AAWidgetCard 
                title='Add Org' 
                onClick={()=>{setOrganizationDialogOpen(true)}}
                // total={24} 
                color='info' 
                textColor={theme.palette.common.white}
                cardColor={theme.palette.text.primary}
                icon="ant-design:cloud-upload-outlined"
              /> :
              <AAWidgetCard 
                title='Upload Song' 
                onClick={()=>{navigate('/upload')}}
                // total={24} 
                color='info' 
                textColor={theme.palette.common.white}
                cardColor={theme.palette.text.primary}
                icon="ant-design:cloud-upload-outlined"
              />
            }
          </Grid>  
          <Divider sx={{width: '98%', m: 3, }}/>
          <Typography variant='h4' 
            sx={{
              p: 3,
              pb: 1, 
              pt: 1, 
            }}
          >
            {artistState.length === 0 ?
              'Artist' :
              'Artists'
            }
          </Typography>
          <Grid item xs={12}>
            <Grid container spacing={0} 
              sx={{
                // border: 'blue solid 1px',
                pl: 0,
                pb: 4,
              }}
            >
              {artistState.map((artist, index) => <Grid 
                    key={`artist${index}`} 
                    item 
                    xs={6} sm={6} md={2} 
                    sx={{
                      // border: 'red solid 1px',
                      p: {
                        xs: 2,
                        sm: 2,
                      },
                      pb: {
                        xs: 4,
                        sm: 2,
                        md: 2,
                        lg: 2,
                      },
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                    }}
                  >
                  <ArtistProfileCard 
                    artist={artist} 
                    refetch={refetch}
                    onCardClick={()=>{navigate(`/inventory/${artist._id}?name=${artist.name}`)}}
                    sx={{opacity: .5}}
                    setToast={setToast}
                  />
                </Grid>
              )}
              <Grid 
                    item 
                    xs={6} sm={6} md={2} 
                    sx={{
                      p: {
                        xs: 2,
                        sm: 2,
                      },
                      pb: {
                        xs: 4,
                        sm: 2,
                        md: 2,
                        lg: 2,
                      },
                      display: 'flex', 
                      justifyContent: 'center', 
                    }}
                  >

                    <Box 
                      sx={{
                        display: 'flex', 
                        justifyContent: 'center', 
                        height: '100%',
                        width: '100%',
                        aspectRatio: '1/1',
                        borderRadius: '50%',
                        maxHeight: '200px',
                        maxWidth: '200px',
                      }}
                    >
                      <Button 
                        variant='text' 
                        // fullWidth 
                        onClick={()=>{setArtistDialogOpen(true)}}
                        sx={{
                          p: 0,
                          m: 0,
                          height: '100%',
                          width: '100%',
                          borderRadius: '50%',
                          // border: 'red solid 1px',

                        }}
                      >
                        <Box 
                          sx={{
                            p: 0,
                            m: 0,
                            // position: 'absolute',
                            height: '100%',
                            width: '100%',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.background.paper,
                          }}
                        >
                          <Box 
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 0,
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              width: '100%',
                              opacity: .5,
                              transition: 'all .2s ease-in-out',
                              borderRadius: '50%',
                              '&:hover': {
                                opacity: 1,
                                border: `${theme.palette.text.primary} solid 2px`,
                                
                              }
                            }}
                          >
                            <AddRoundedIcon 
                              sx={{
                                color: 'text.primary',
                                width: '35px',
                                height: '35px',
                              }}
                            />
                            <Typography 
                              variant='subtitle2' 
                              color='text.primary'
                            >
                              Add Artist
                            </Typography>

                          </Box>
                        </Box>
                      </Button>
                    </Box>
                </Grid>
            </Grid>
          </Grid>
          <Divider sx={{width: '98%', m: 3, }}/>

          <Typography variant='h4' 
            sx={{
              p: 3,
              pb: 6, 
              pt: 1, 
            }}
          >
            Recent Pitches
          </Typography>
          <Grid container 
            spacing={3} 
            sx={{
              pl: 3,
            }} 
          >
          {allPitchesState?.map(song => {  // eslint-disable-line
            if (song.pitches.length > 0) {
              return <Grid key={`card${song._id}`} item xs= {12} sm={6} md={4} lg={3} sx={{display: 'flex', justifyContent:'center', mb: 3}}>
                  <SongPitchedCard 
                    item={song} 
                    loadedSong={loadedSong}
                    setLoadedSong={setLoadedSong}
                    controlMessage={controlMessage} 
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    getAllPitches={()=>{getAllPitches()}}
                    setToast={setToast}
                  />
                </Grid>

              return song.pitches.map(x => { // eslint-disable-line
                // hey
              })
              } 
            }
            )}
            {allPitchesState?.length === 0 &&
              <Grid item xs= {12} sm={6} md={4} lg={3} sx={{display: 'flex', justifyContent:'center', mb: 3}}>
                    <SongNoPitchesCard />
              </Grid>
            
            }
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <ProfileAbout
              user={user}
              quote={info.quote}
              country={info.country}
              email={info.email}
              role={info.role}
              company={info.company}
              school={info.school}
              setPaymentDialogOpen={setPaymentDialogOpen}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
    
    <AddArtistDialog 
        artistDialogOpen={artistDialogOpen}
        setArtistDialogOpen={setArtistDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />    
    <CreateOrganizationDialog
        organizationDialogOpen={organizationDialogOpen}
        setOrganizationDialogOpen={setOrganizationDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />
    <AccountDialog 
        accountDialogOpen={accountDialogOpen}
        setAccountDialogOpen={setAccountDialogOpen}
        user={user}
        userr={userr}
        setToast={setToast}
        refetch={refetch}
    />
    <Elements stripe={stripePromise}>
      <PaymentDialog 
        paymentDialogOpen={paymentDialogOpen}  
        setPaymentDialogOpen={setPaymentDialogOpen}  
        user={user}
        userr={userr}
        refetch={refetch}
      />

    </Elements>
    <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
