import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled } from '@mui/material/styles';
import { Button, Container, Typography, DialogActions, Dialog, Divider, Stack, Link } from '@mui/material';
// components
import Scrollbar from '../components-default/scrollbar';

import isAA from './isAA';
import AASubscriptionLink from './AASubscriptionLink';
import TBSubscriptionLink from './TBSubscriptionLink';

// ----------------------------------------------------------------------


SignUpDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  setSignUpOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  songInfo: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default function SignUpDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  setSignUpOpen,
  songInfo,
  onSubmit,
}) {
  // const { title = '', content = '', description = '' } = values;

  // console.log('SONG INFO: ', songInfo)

  return (
    <Dialog fullScreen open={open} onClose={onClose} >
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Subscript to Audio Arsenal
        </Typography>

        <Button variant="outlined" color="inherit" onClick={()=>{onClose()}}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          // disabled={!isValid}
          loading={isSubmitting}
          onClick={onClose}
        >
          Done
        </LoadingButton>
      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 5, 
              mb: 10, 
              // ml: 10, 
              // mr: 20, 
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
          <Stack 
            justifyContent='center' 
            alignItems='center' 
            width='80%' 
            textAlign='center'
            gap={6}
            marginBottom={6}
          >
            <Typography variant='h4'>
              This is where you do it.
            </Typography>
            <Link href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink(user?._id, user?.email)} target='_blank'>
              Subscribe
            </Link>
          </Stack>
          </Container>
        </Scrollbar>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

