import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Container, Typography, Select, MenuItem, Stack, IconButton } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'

// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import OrgDialog from '../components-custom/OrgDialog';


// ----------------------------------------------------------------------

export default function AdminOrgsPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;

  // console.log('User: ', user)
  const [openOrgDrawer, setOpenOrgDrawer] = useState(false)
  const [orgId, setOrgId] = useState('')


  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]); // eslint-disable-line

  const [sortMethod, setSortMethod] = useState('date-descending')

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(30)
  const [pageLinks, setPageLinks] = useState('')
  const [pageLinksAbbrev, setPageLinksAbbrev] = useState('')

  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [selectedId, setSelectedId] = useState(null); // eslint-disable-line

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  function noResultsMessage(){
    return 'No orgs found'
  }

  async function getInventory(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getorg`, {  // eslint-disable-line
        type: 'all',
      }).then(response => {
        console.log(response)
        setAxiosResults(response.data)
      });
    } catch (err) {
      console.log(err)
    }              
  }



  useEffect(() => {
    getInventory()

  },[]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.orgs)

      const totalPages = axiosResults.totalDocs/perPage
      setPageCount(Math.ceil(totalPages))

      const buttons = [];

      for (let i = 1; i <= Math.ceil(totalPages);) {
        // buttons.push(<button type='button' key={i}>Page {i}</button>);
        buttons.push(
          <Button key={`pageButton${i}`} variant={(page === i) ? 'outlined' : 'text'} size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled={page === i}
            onClick={()=>{
              setPage(i-1)
              navigate(`${window.location.pathname}?page=${i-1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
          <Typography variant='subtitle1' color={page === i ? 'primary' : 'primary'}>
            {i}
          </Typography>
        </Button>

        );
        i += 1
      }

      setPageLinks(buttons)
      const buttonsAbbrev = buttons.splice(0,3)
      if (page !== 4 && page !== 5){
        buttonsAbbrev.push(
          <Typography key='ellipsis' variant='button' color='primary'
            sx={{pt: .5, cursor: 'default'}}
          >
            ...
          </Typography>
        )
      }
      if (page > 3 && page < pageCount-2){
        buttonsAbbrev.push(
          <Button key={`pageButtonPrev${page}`} variant='text' size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
              display: page === 4 ? 'none' : 'inherit'
            }}
            onClick={()=>{
              setPage(page-1)
              navigate(`${window.location.pathname}?page=${page-1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {page-1}
            </Typography>
          </Button>
        )        
        buttonsAbbrev.push(
          <Button key={`pageButtonHere${page}`} variant='outlined' size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled
            onClick={()=>{
              setPage(page)
              navigate(`${window.location.pathname}?page=${page}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {page}
            </Typography>
          </Button>
        )
        if (page <= pageCount - 4){
          buttonsAbbrev.push(
            <Button key={`pageButtonNext${page}`} variant='text' size='small' width='fit-content'
              sx={{
                px: '10px',
                minWidth: 0,
              }}
              onClick={()=>{
                setPage(page+1)
                navigate(`${window.location.pathname}?page=${page+1}`)
                // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
              }}
            >
              <Typography variant='subtitle1' color='primary'>
                {page+1}
              </Typography>
            </Button>
          )
        }   
        buttonsAbbrev.push(
          <Button key={`pageButtonNextNext${page}`} variant='text' size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
              display: page === 4 ? 'inherit' : 'none', 
            }}
            onClick={()=>{
              setPage(page+1)
              navigate(`${window.location.pathname}?page=${page+1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {page+2}
            </Typography>
          </Button>
        )        
       if (page < pageCount-4){
         buttonsAbbrev.push(
           <Typography key='post-ellipses' variant='button' color='primary'
             sx={{pt: .5, cursor: 'default'}}
           >
             ...
           </Typography>
         )
       }
      }
      buttonsAbbrev.push(buttons[buttons.length -3])
      buttonsAbbrev.push(buttons[buttons.length -2])
      buttonsAbbrev.push(buttons[buttons.length -1])

      setPageLinksAbbrev(buttonsAbbrev)
      setAxiosLoading(false)

    }
  },[axiosResults]) // eslint-disable-line


  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Organizations</title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              { name: 'Organizations' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
            All Orgs
            </Typography>
          </Stack>
          <Stack direction='row' gap={2}>
          <Select  
            // defaultValue={sortMethod}
            value={sortMethod}
            sx={{
              display: 'flex',
              height: '36px',
              width: '200px',
            }}
            onChange={(e)=>{setSortMethod(e.target.value)}}
          >
            <MenuItem value='date-descending'>
              Sort by: Date <Iconify icon='bx:down-arrow' sx={{height: '12px'}}/>
            </MenuItem>
            <MenuItem value='date-ascending'>
              Sort by: Date <Iconify icon='bx:up-arrow' sx={{height: '12px'}}/>
            </MenuItem>
          </Select>
          <Select  
              // defaultValue={sortMethod}
              value={perPage}
              sx={{
                display: 'flex',
                height: '36px',
                width: '140px',
              }}
              onChange={(e)=>{
                setPage(0)
                setPerPage(e.target.value)
                navigate('/users')
              }}
            >
              <MenuItem value={10}>
                10 per page
              </MenuItem>
              <MenuItem value={20}>
                20 per page
              </MenuItem>
              <MenuItem value={30}>
                30 per page
              </MenuItem>
            </Select>
        </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
            >
            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%'/> : 
            <Box onKeyDown={handleKeyDown}>
            {displayFiles?.length > 0 ? 
                displayFiles.map((item, index) => (
                  <Button
                    sx={{
                      //
                    }}
                    onClick={()=>{
                      setOrgId(item._id)
                      setOpenOrgDrawer(true)
                    }}
                  >
                    <Typography variant='subtitle1'>
                      {item?.name}
                    </Typography>

                  </Button>
                  ))  :
                <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                 {noResultsMessage()}
                </Typography>
            }
            </Box>
           }
           {pageCount > 1 &&
           <Stack direction='row' height='50px' width='100%' gap={1}
            sx={{
              justifyContent: 'flex-end'
            }}
           >
            {page > 1 &&
            <Button variant='contained' size='small'
            onClick={()=>{
              setPage(page -1)
              navigate(`${window.location.pathname}?page=${page-1}`)
            }}
            >
              Prev
            </Button>
            }
            {pageLinks.length <= 5 ? pageLinks : pageLinksAbbrev}
            <Button variant='contained' size='small'
              onClick={()=>{
                setPage(page +1)
                navigate(`${window.location.pathname}?page=${page+1}`)
              }}
              disabled={page === pageCount}
            >
              Next
            </Button>
           </Stack>
           }
          </Grid>

        </Grid>
      </Container>
      <OrgDialog 
        open={openOrgDrawer} 
        setOpenOrgDrawer={setOpenOrgDrawer} 
        onClose={()=>{setOpenOrgDrawer(false)}} 
        orgId={orgId}
      />
      <Footer />
      <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
