import { alpha, useTheme, styled } from '@mui/material/styles';


// ----------------------------------------------------------------------

export function fPillStatus(status) {
  
  // const theme = useTheme()

  let pill = null

  switch (status) {
    case 'submitted':
      pill = {status: 'Pitched', color: (theme) => theme.palette.warning.main};
      break;
    case 'revise':
      pill = {status: 'Revise', color: (theme) => theme.palette.error.light};
      break;
    case 'resubmitted':
      pill = {status: 'Pitched', color: (theme) => theme.palette.warning.dark};
      break;
    case 'pitched':
      pill = {status: 'Pitched', color: (theme) => theme.palette.warning.main};
      break;
    case 'sold':
      pill = {status: 'Sold', color: (theme) => '#4ec777'};
      break;
    case 'pass':
      pill = {status: 'Pass', color: (theme) => theme.palette.error.main};
      break;
      
    default:
    pill = {status: 'none', color: (theme) => theme.palette.info.main};
      break;
  }
  return pill
}

