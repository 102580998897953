import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink} from 'react-router-dom';

// @mui
import {
  Box,
  Stack,
  Divider,
  Checkbox,
  Link,
  MenuItem,
  Typography,
  Grid,
  Button,
  Chip,
  Tooltip,
} from '@mui/material';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import EditIcon from '@mui/icons-material/Edit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import axios from 'axios';

import { ReactComponent as LogoIcon } from '../../../../assets/icons/pikes.svg'
// import { ReactComponent as LogoIconTB} from '../../../../assets/icons/TB_Record_Small.png'
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import { TOGGLE_DOC_FAVORITE, TOGGLE_DOC_PASS, ADD_DOC_TO_CART } from '../../../../graphql/mutations'

// components
import Iconify from '../../../../components-default/iconify';
import MenuPopover from '../../../../components-default/menu-popover';
import Markdown from '../../../../components-default/markdown/Markdown';
import isAA from '../../../../components-custom/isAA';

//

// ----------------------------------------------------------------------

AudioFileCardProducer.propTypes = {
  sx: PropTypes.object,
  userId: PropTypes.string,
  user: PropTypes.object,
  file: PropTypes.object,
  data: PropTypes.object,
  refetch: PropTypes.func,
  dispatch: PropTypes.func,
  addToCart: PropTypes.func,
  onDelete: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setControlMessage: PropTypes.func,
  audioBarFavorite: PropTypes.bool,
  setAudioBarFavorite: PropTypes.func,
  favorites: PropTypes.bool,
  passable: PropTypes.bool,
  editable: PropTypes.bool,
  inventoryPage: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  setSelectedId: PropTypes.func,
  selected: PropTypes.bool,
  plain: PropTypes.bool,
  index: PropTypes.number,
  briefId: PropTypes.string,
  openMultisUploadDrawer: PropTypes.bool,
  setOpenMultisUploadDrawer: PropTypes.func,
  multisInfo: PropTypes.object,
  setMultisInfo: PropTypes.func,
  multisUploadType: PropTypes.string,
};


export default function AudioFileCardProducer({ userId, user, file, data, refetch, dispatch, addToCart, onDelete, sx, favorites, passable, editable, inventoryPage, loadedSong, setLoadedSong, isPlaying, setIsPlaying, setControlMessage, audioBarFavorite, setAudioBarFavorite, index, setSelectedIndex, selectedIndex, selected, plain, setSelectedId, briefId, multisUploadType, openMultisUploadDrawer, setOpenMultisUploadDrawer, multisInfo, setMultisInfo, ...other }) {

  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'sm');

  const [openMultisPopover, setOpenMultisPopover] = useState(null);


  const [favorited, setFavorited] = useState(Boolean(file?.favorited?.includes(userId)));
  const [playButton, setPlayButton] = useState('play');
  const [pitchIndex, setPitchIndex] = useState(null);
  const [pitchPill, setPitchPill] = useState({ status: '', color: '' });


  const [openShare, setOpenShare] = useState(false); // eslint-disable-line

  const [openDetails, setOpenDetails] = useState(false); // eslint-disable-line
  const [showLyrics, setShowLyrics] = useState(false); // eslint-disable-line

  const theme = useTheme();

  const lastPitch = file.pitches.length <= 1 ? 0 : file.pitches.length - 1;
  const updatedDate = new Date(file?.pitches[lastPitch]?.updated_at)

  const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);
  const [addDocToCart] = useMutation(ADD_DOC_TO_CART);

  const cardColor = isAA() ? `${theme.palette.background.paper}0` : `${theme.palette.background.paper}80`
  const tagPillColor = isAA() ? theme.palette.background.paper : theme.palette.grey[500]
  const tagPillText = isAA() ? theme.palette.info.dark : theme.palette.background.default

  const handleFavorite = async () => {
    setAudioBarFavorite(!audioBarFavorite);
    await toggleFavorite({ variables: { docId: file._id, userId } });
    setFavorited(!favorited)
    refetch()
  };


  const handleOpenMultisPopover = (event) => {
    setOpenMultisPopover(event.currentTarget);
  };

  const handleCloseMultisPopover = () => {
    setOpenMultisPopover(null);
  };
  // console.log(file)

  useEffect(() => {
    setFavorited(Boolean(file.favorited.includes(userId)));
  }, [data]) // eslint-disable-line

  useEffect(() => {

    const lastPitchIndex = file.pitches.length - 1;
    setPitchIndex(lastPitchIndex);
    let pill = null;

    switch (file?.pitches[lastPitchIndex]?.status) {
      case 'submitted':
        pill = { status: 'Pitched', color: theme.palette.primary.light };
        break;
      case 'revise':
        pill = { status: 'Revise', color: theme.palette.error.main };
        break;
      case 'resubmitted':
        pill = { status: 'Repitched', color: theme.palette.primary.dark };
        break;
      case 'pitched':
        pill = { status: 'Pitched', color: theme.palette.primary.light };
        break;
      case 'sold':
        pill = { status: 'Sold', color: isAA() ? theme.palette.success.main : theme.palette.success.light };
        break;
      case 'pass':
        pill = { status: 'Pass', color: theme.palette.error.dark };
        break;
      default:
        pill = { status: '', color: '' };
        break;
    }
    setPitchPill(pill)
    // console.log('FILE PITCH: ', file)
  }, [file]) // eslint-disable-line


  useEffect(() => {
    setPlayButton(isPlaying && file.audioUrl === loadedSong.audioUrl ? 'pause' : 'play');
  }, [isPlaying, loadedSong, favorited]) // eslint-disable-line  

  // useEffect(() => {
  //   if (selectedIndex && selectedIndex === index) {
  //     setLoadedSong(file);
  //     // setIsPlaying(true);
  //     setSelectedId(file._id)
  //   }
  // },[selectedIndex, isPlaying]) // eslint-disable-line
  // console.log("FILE: ", file)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        position: 'relative',
        transition: 'all 0.2s ease-in-out',
        ml: (loadedSong?.audioUrl === file?.audioUrl) ? 1 : 0,
        mr: (loadedSong?.audioUrl === file?.audioUrl) ? -1 : 0,
        minHeight: '96px',
        backgroundColor: cardColor,
        boxShadow: (loadedSong?.audioUrl === file?.audioUrl) ? `-8px 8px 2px rgba(0, 0, 0, 0.1)` : theme.customShadows.z8,
        border: (loadedSong?.audioUrl === file?.audioUrl) ? `${theme.palette.primary.main} solid 2px` : `solid 1px ${theme.palette.info.lighter}40`,
        '&:hover': {
          // bgcolor: `inherit`,
          boxShadow: loadedSong?.audioUrl === file?.audioUrl ? '-8px 8px 2px rgba(0, 0, 0, 0.1)' : theme.customShadows.z8,
        },
        ...(isDesktop && {
          // p: 1.5,
          borderRadius: 1.5,
        }),
        ...sx,
      }}
    >
      {!plain &&
        <Box
          sx={{
            position: 'absolute',
            height: '34px',
            width: '34px',
            // zIndex: 10,
            transition: 'all .5s ease-in-out',
            ml: (loadedSong?.audioUrl === file?.audioUrl && isPlaying) ? -9.5 : -20,
            opacity: (loadedSong?.audioUrl === file?.audioUrl && isPlaying) ? 1 : 0,
            mt: '45px',
            transform: 'rotateZ(43deg)',
            borderRadius: '50%',
          }}
        >
          {process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ?
            <LogoIcon height='100%' /> :
            <LogoIcon height='100%' />
          }
        </Box>
      }
      <Stack
        spacing={isDesktop ? 1.5 : 2}
        direction={isDesktop ? 'row' : 'column'}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        sx={{
          p: 2.5,
          borderRadius: 2,
          position: 'relative',
          marginBottom: '0px !important',
          // border: 'red solid 1px',
          width: '100%',
          ...(isDesktop && {
            marginBottom: '0px!important',
            p: 1.5,
            pb: 0,
            borderRadius: 1.5,
          }),
          ...sx,
        }}
        {...other}
      >
        <Grid container>

          <Grid item xs={10} sm={10} md={10} lg={10}
            order={{
              xs: 1,
              sm: 1,
            }}
            sx={{
              // PLAY BUTTON / ARTIST INFO
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              pr: 3,
              // border: 'green solid 1px',
            }}
          >
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                if (setSelectedIndex) {
                  setSelectedIndex(index)
                }
                if (setSelectedId) {
                  setSelectedId(file._id)
                }
                if (loadedSong !== file) {
                  setLoadedSong(file);
                  setIsPlaying(true);
                } else {
                  setControlMessage('play-pause')
                  setIsPlaying(true)
                }

              }}
              sx={{
                flexGrow: 1,
                alignItems: 'flex-start',
                justifyContent: 'center',
                borderRadius: '25%',
                p: 0,
                m: 0,
              }}
            >
              {playButton === 'pause' ?
                <PauseCircleIcon sx={{
                  width: '52px',
                  height: '52px',
                  m: 0,
                  opacity: .8,
                  color: isAA() ? 'inherit' : theme.palette.primary.main,
                }} /> :
                <PlayCircleFilledRoundedIcon sx={{
                  width: '52px',
                  height: '52px',
                  opacity: .8,
                  m: 0,
                  color: isAA() ? 'inherit' : theme.palette.common.white,
                }} />
              }
            </Button>

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              width: '100%',
              // border: 'pink solid 1px',
            }}>
              <Box
                // song/artist name box
                sx={{
                  width: '100%',
                  maxWidth: {
                    xs: '95%',
                    sm: '95%',
                    md: '95%',
                  },
                  pr: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  opacity: 1,
                  marginBottom: '0px !important',
                  justifyContent: 'center',
                  // border: 'green solid 1px',

                }}
              >
                <Typography noWrap variant="subtitle2"
                  sx={{
                    textOverflow: 'ellipsis',
                    cursor: 'default',
                  }}
                >
                  {file.title}
                </Typography>
                <Typography variant="caption" noWrap sx={{ cursor: 'default' }}
                >
                  {file.artist.name}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={2} sm={2} md={2} lg={2}
            order={{
              xs: 2,
              sm: 3,
            }}
          >
            <Box
              sx={{
                // LIKE EDIT SECTION
                display: 'flex',
                justifyContent: {
                  xs: 'flex-end',
                  sm: 'flex-end',
                  md: 'flex-end',
                },
                alignItems: 'center',
                height: '100%',
                width: '100%',
                mt: {
                  // xs: 2,
                  sm: 'inherit',
                },
                pr: 1,
                // border: 'blue solid 1px',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                {(favorites && file.claimed === 'unclaimed') &&
                  <Checkbox
                    color="error"
                    icon={<Iconify icon="eva:heart-outline" />}
                    checkedIcon={<Iconify icon="eva:heart-fill" />}
                    checked={favorited}
                    onChange={handleFavorite}
                    sx={{
                      p: 0.75,
                      display: 'inline-flex',
                    }}
                  />
                }
                {(user.role === 'admin' || editable) &&
                  <Tooltip title='Edit song' >
                    <Button
                      href={file?.pitches[pitchIndex]?.status === 'revise' ? `/editsong/${file._id}?type=revisepitch` : `/editsong/${file._id}`}
                    >
                      <EditIcon fontSize="small" sx={{ color: theme.palette.text.disabled }} />
                    </Button>
                  </Tooltip>
                }

                <Box sx={{ display: 'flex' }}>
                  {(file.claimed !== 'unclaimed') &&
                    <Typography variant='subtitle2' sx={{ color: isAA() ? theme.palette.success.main : theme.palette.success.lighter }}>
                      Sold on {updatedDate?.toLocaleDateString('en-us')}
                    </Typography>
                  }
                </Box>
              </Box>

            </Box>
          </Grid>
        </Grid>
      </Stack>
      <Box
        sx={{
          pl: 3,
          pb: 3,
          my: 0,
          display: 'flex',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isDesktop && {
            position: 'unset',
          }),
          overflowX: 'hidden',
          // border: 'purple solid 1px',
        }}
      >
        {(inventoryPage && file?.pitches?.length > 0) ?
          <Stack direction='row' gap={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // border: `${theme.palette.text.disabled} solid 1px`,
              borderRadius: 5,
              height: 'fit-content',
              width: '100%',
              mb: 1,
              // mr: 1,
            }}
          >
            <Link
              href={`/briefs/${file?.pitches[pitchIndex]?.briefId}`}
              sx={{
                '&:hover': {
                  textDecoration: 'none',
                }
              }}
            >
              <Typography variant="subtitle2" noWrap
                sx={{
                  color: isAA() ? theme.palette.info.dark : theme.palette.text.primary,
                  cursor: 'pointer',
                  // py: 0, 
                  px: 1,
                }}
              >
                Brief: {file?.pitches[pitchIndex]?.briefTitle}<span style={{ color: isAA() ? theme.palette.info.light : theme.palette.text.primary }}> ({file?.pitches[pitchIndex]?.briefCategory})</span>
              </Typography>
            </Link>
            <Typography variant="caption" noWrap
              // onClick={()=>{console.log(pitchPill.status)}}
              sx={{
                color: pitchPill.color,
                cursor: 'default',
                borderRadius: 2,
                border: `${pitchPill.color} solid 1px`,
                py: 0,
                px: 1,
                // lineHeight: '16px',
              }}
            >
              {pitchPill.status}
            </Typography>
          </Stack> :
          <Stack direction='row' gap={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // border: `${theme.palette.text.disabled} solid 1px`,
              borderRadius: 5,
              height: 'fit-content',
              width: '100%',
              mb: 1,
              // mr: 1,
            }}
          >
            <Typography variant="subtitle2" noWrap
              sx={{
                color: isAA() ? theme.palette.text.disabled : theme.palette.text.disabled,
                cursor: 'pointer',
                // py: 0, 
                px: 1,
              }}
            >
              Not currently pitched
            </Typography>
          </Stack>
        }
        <Stack direction='row' width='100%'>
          <Tooltip title={file?.multitrackUrl === '' ? 'Upload multitracks' : ''} >
            <Stack direction='row' gap={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: file?.multitrackUrl !== '' ? (isAA() ? `${theme.palette.success.main} solid 1px` : `${theme.palette.success.light} solid 1px`) : (pitchPill.status === 'Sold' ? `${theme.palette.error.dark} solid 1px` : `${theme.palette.divider} solid 1px`), // eslint-disable-line
                borderRadius: 5,
                height: '20px',
                mr: 1,
              }}
            >
              <Typography variant="caption" noWrap
                sx={{
                  color: file?.multitrackUrl !== '' ? (isAA() ? theme.palette.success.main : theme.palette.success.light) : (pitchPill.status === 'Sold' ? theme.palette.error.dark : theme.palette.text.disabled), // eslint-disable-line
                  cursor: 'pointer',
                  py: 0,
                  px: 1
                }}
                onClick={async (e) => {
                  if (file?.multitrackUrl !== '') {
                    // window.open(file?.multitrackUrl, '_blank')
                    setOpenMultisPopover(e.target)
                  } else {

                    if (multisUploadType === 'server') { // eslint-disable-line
                      setMultisInfo({
                        title: file?.title,
                        _id: file?._id,
                        multitrackUrl: file?.multitrackUrl,
                      })
                      setOpenMultisUploadDrawer(true)
                    } else {
                      window.open(isAA() ? `https://audioarsenal.wetransfer.com/?title=${file?._id}&msg=${file?.title}` : `https://trackbase.wetransfer.com/?title=${file?._id}&msg=${file?.title}`, '_blank')
                    }
                    // setOpenMultisPopover(e.target)
                  }

                }}
              >
                {file?.multitrackUrl !== '' ? 'Multitracks' : 'Upload Multitracks'}
              </Typography>
            </Stack>
          </Tooltip>
        </Stack>
        <Divider sx={{ mt: 1.5, mb: 1, width: '97%', pr: 3, }} variant='fullWidth' />

        <Stack direction='row' gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: isAA() ? `${theme.palette.text.disabled} solid 1px` : `${tagPillColor} solid 1px`,
            color: isAA() ? theme.palette.info.dark : tagPillColor,
            borderRadius: 5,
            // height: '20px',
            // mb: 1,
            mr: 1,
          }}
        >
          <Typography variant="caption" noWrap sx={{ cursor: 'default', py: 0, pl: 1 }}>
            {file?.bpm} bpm
          </Typography>
          <Typography variant="caption" noWrap sx={{ cursor: 'default', py: 0, }}>
            |
          </Typography>
          <Typography variant="caption" noWrap sx={{ cursor: 'default', py: 0, pr: 1 }}>
            {file?.key} {file?.scale}
          </Typography>
        </Stack>

        {file.tags.genre.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: tagPillColor,
            color: tagPillText,
          }}
          size="small"
        />
        )}
        {file.tags.mood.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: tagPillColor,
            color: tagPillText,
          }}
          size="small"
        />
        )}
        {file.tags.instrument.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: tagPillColor,
            color: tagPillText,
          }}
          size="small"
        />
        )}
        {isAA() && file.tags.theme.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: tagPillColor,
            color: tagPillText,
          }}
          size="small"
        />
        )}
      </Box>
      <Stack direction='row' width='100%' alignItems='center'
        display={file?.lyrics ? 'flex' : 'none'} marginBottom={2} marginLeft={2}
      >
        <Iconify icon={!showLyrics ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'}
          sx={{
            // mx:2,
            width: '34px',
            cursor: 'pointer',
            color: theme.palette.info.light,
          }}
          onClick={() => { setShowLyrics(!showLyrics) }}
        />
        <Typography variant='subtitle1'
          sx={{ width: '195px', minWidth: '195px', cursor: 'pointer', color: theme.palette.info.light }}
          onClick={() => {
            setShowLyrics(!showLyrics)
          }}
        >
          Lyrics
        </Typography>
      </Stack>
      {(file?.lyrics && showLyrics) &&
        <>
          <Divider sx={{ width: '100%', pt: 1, }} />
          <Stack
            direction='row'
            padding={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              ml: 2,
              transition: 'height .2s ease-in-out',
            }}
          >
            <Typography variant='caption'
              sx={{
                color: theme.palette.info.main,
                // opacity: .5,
              }}
            >
              <Markdown>
                {file?.lyrics.replace(/\r\n/g, '<br>')}
              </Markdown>
            </Typography>
          </Stack>
        </>
      }
      <MenuPopover
        open={openMultisPopover}
        onClose={handleCloseMultisPopover}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        <MenuItem
          // LinkComponent={Link}
          // component={RouterLink}
          // to={file?.multitrackUrl}
          onClick={()=>{
            window.open(`${file?.multitrackUrl}`, '_blank')
          }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={() => {
            if (multisUploadType === 'server'){
              setMultisInfo({
                title: file?.title,
                _id: file?._id,
                multitrackUrl: file?.multitrackUrl,
              })
              setOpenMultisUploadDrawer(true)
              handleCloseMultisPopover()
            } else {
              window.open(isAA() ? `https://audioarsenal.wetransfer.com/?title=${file?._id}&msg=${file?.title}` : `https://trackbase.wetransfer.com/?title=${file?._id}&msg=${file?.title}`, '_blank')
            }
          }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Replace Multis
        </MenuItem>
      </MenuPopover>

    </Box>

  );
}