import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';  // eslint-disable-line
import { m } from 'framer-motion';

import { useParams, useNavigate } from 'react-router-dom';


import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Grid, Box, Button, Container, ToggleButtonGroup, ToggleButton, Typography, TextField, Chip, IconButton, Divider, Stack, Select, MenuItem } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useMutation, useQuery } from "@apollo/client";


// import { Dropbox } from 'dropbox';
// graphql
import axios from 'axios';
import { GET_ALL_CLIENT_USERS, GET_ALL_ORGANIZATIONS } from '../graphql/queries'
import { UPDATE_CLIENT_ORGANIZATION } from '../graphql/mutations';


// components
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import { varFade, MotionViewport } from '../components-default/animate';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import { UploadBox } from '../components-default/upload';
import { genreList, moodList, instrumentList, themeList } from '../components-custom/TagList';
import AddArtistDialog from '../components-custom/AddArtistDialog';


import Footer from '../layouts/main/Footer';
import { ArtistSearch } from '../components-custom/sections/@dashboard/e-commerce/shop';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';
import TextfieldDisplay from '../components-custom/TextfieldDisplay';
import CustomToast from '../components-custom/CustomToast';

// ----------------------------------------------------------------------



export default function ClientAdminPage() {


  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const [uploadPercentage, setUploadPercentage] = useState(0);  // eslint-disable-line
  const [loading, setLoading] = useState(false);

  const [orgIdState, setOrgIdState] = useState('Choose Organization')
  const [selectedIndex, setSelectedIndex] = useState();
  const [selectedClientId, setSelectedClientId] = useState();

  const { loading: clientLoading, error: clientError, data: clientData = {getAllClients: []}, refetch: clientRefetch } = useQuery(GET_ALL_CLIENT_USERS, { // eslint-disable-line
    fetchPolicy: 'network-only'
  });

  const { loading: orgLoading, error: orgError, data: orgData = {getAllClients: []}, refetch: orgRefetch } = useQuery(GET_ALL_ORGANIZATIONS, { // eslint-disable-line
    fetchPolicy: 'network-only'
  });

  const [updateClientOrg] = useMutation(UPDATE_CLIENT_ORGANIZATION);

  const handleUpdate = async () => {
      await updateClientOrg({ variables: { clientId: selectedClientId, orgId: orgIdState} });
      clientRefetch()
  };


  if (user.role !== 'admin') {
    return <h1>YOU AINT BELONG HERE</h1>
  }

  if (clientData.length === 0 || 
    orgData.length === 0 ||
    clientData.getAllClients === undefined ||
    orgData.getAllOrgs === undefined
    ) {
    return <h1>LOADING</h1>
  }

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Client-Admin </title>
      </Helmet>
      <Container>
      <Box
        sx={{
          pt: 6,
          pb: 1,
        }}
      >
        <Box sx={{display: 'flex', gap: 2}}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            ADMIN CLIENT PAGE
          </Typography>
          <Button 
            onClick={()=>{
              console.log('CLIENT ID: ', selectedClientId)
              console.log('ORG ID: ', orgIdState)
            }}>LOG</Button>
        </Box>
        <Grid container spacing={3}>
          {clientData?.getAllClients?.map((item, index) => {return <Grid  // eslint-disable-line
          key={item?._id} item
          xs={12} sm={12} md={12}>
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                border: `${theme.palette.primary.main} solid 1px`,
                borderRadius: 2,
                p:3,
                m: 2,
              }}
            >
              <Stack direction='column' spacing={2}>
                <Typography variant='subtitle1'>
                  {item?.name}
                </Typography>
                <Typography variant='body'>
                  {item?.email}
                </Typography>
              </Stack>
              <Stack direction='row' spacing={2}>
                <Button variant='contained'
                  disabled={index !== selectedIndex}
                  onClick={()=>{
                    console.log(orgIdState)
                    handleUpdate()
                  }}
                >
                  Update Client
                </Button>
                <Select sx={{width: '250px'}} placeholder='Organization' value={orgIdState}
                  onChange={(e)=>{
                    setOrgIdState(e.target.value)
                    setSelectedClientId(item?._id)
                    setSelectedIndex(index)
                  }}
                >
                  {orgData?.getAllOrgs.map(org => {return <MenuItem // eslint-disable-line
                    key={org?._id} value={org?._id}>{org?.name}</MenuItem>
                })}
                </Select>

              </Stack>

            </Box>
            
          </Grid>}
            )}

          <Grid item xs={12} sm={12} md={12} sx={{mb: 2, paddingTop: '0px',}}>
            <Box sx={{display: 'flex', gap: 2, alignContent: 'center'}}>
                <Divider />
            </Box>
          </Grid>
        </Grid>
      </Box>
      </Container>
      <Footer />
 
      </>
  );
}
