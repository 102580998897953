import { useState } from 'react';

import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme} from '@mui/material/styles';

import { Container, Box, Typography, Grid} from '@mui/material';
// _mock
// sections
import { ContactFormGeneral } from '../components-custom/sections/contact';
import Footer from '../layouts/main/Footer';
import isAA from '../components-custom/isAA';

// ----------------------------------------------------------------------

export default function AAGeneralContactPage() {

  const [pageState, setPageState] = useState('form')

  const theme = useTheme();

  return (
    <>
      <Helmet>
        <title> Contact | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <Container sx={{ py: 6, display: 'flex', justifyContent: 'center' }}>
        <Box
          marginBottom={15}
          sx={{
            // display: 'flex',
            width: 'fit-content',
            minWidth: '400px',
            maxWidth: '800px',
            borderRadius: 2,
            border: `${theme.palette.divider} solid 1px`,
            boxShadow: theme.shadows[15],
          }}
        >
          {pageState === 'form' ?
          <Grid container width='100%'
          >
            <Grid item 
              xs={12} sm={12} md={6} lg={6} xl={6}
              sx={{
                p: 6,
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                cursor: 'default',
                // border: 'red solid 1px'
              }}
            >
              <Typography variant='h4'>
                Got questions?
              </Typography>
              <Typography variant='body'>
                If you&apos;re a producer wanting to find out how to get on our curated list of content providers, {isAA() ? 'hit us up' : 'click'} <a href='/signuplist' alt='Producer contact' style={{color: 'inherit'}}>here.</a>
              </Typography>
              <Typography variant='body'>
                If you&apos;re a buyer and would like to get information how to list briefs with us,
                we&apos;d love to hear from you. Send us a message and we&apos;ll get back to you promptly.
              </Typography>
              {isAA() &&
              <Typography variant='body'>
                None of the above? Contact us and let&apos;s talk about whatever!
              </Typography>
              
              }
            </Grid>
            <Grid item 
              xs={12} sm={12} md={6} lg={6} xl={6}
              sx={{
                borderLeft: {
                  sm: 'none',
                  md: `${theme.palette.divider} solid 1px`,
                },
                p: 6,
                width: 'fit-content',
                // display: 'flex',
                // border: 'blue solid 1px'
              }}
            >
              <ContactFormGeneral />
                          
            </Grid>
          </Grid> : 
          <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
          >
            <Typography variant='h2'>
              Message sent. We&apos;ll get back to you asap!
            </Typography>
          </Box>
          }
        </Box>
      </Container>
      <Footer />
    </>
  );
}
