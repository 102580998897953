import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Container, Typography, Stack, Link } from '@mui/material';

// graphql
import { useMutation, useQuery } from "@apollo/client";
import { GET_USER_CART, GET_INVOICES_BY_ORG_ID } from '../graphql/queries'


// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import { fDate2 } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';
import Image from '../components-default/image';



// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';


// ----------------------------------------------------------------------

export default function OrdersPageClient() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const [displayFiles, setDisplayFiles] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  const [ cartItems, setCartItems] = useState();


  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});



  const { loading: invoicesLoading, error: invoicesError, data: invoicesData = {getInvoicesByOrgId: []}, refetch: invoicesRefetch } = useQuery(GET_INVOICES_BY_ORG_ID, { // eslint-disable-line
    variables: {orgId: user?.organization},
    fetchPolicy: 'network-only'
  });

  const { loading: cartLoading, error: cartError, data: cartData = {getUserCart: []}, refetch: cartRefetch } = useQuery(GET_USER_CART, {
    variables: { orgId: user.organization },
    fetchPolicy: 'network-only'
  });


  useEffect(()=>{
  
    if (cartData !== undefined) {
      setCartItems(cartData?.getUserCart.length || 0)
    }
  },[cartData]) // eslint-disable-line

  useEffect(()=>{
  
    if (invoicesData?.getInvoicesByOrgId.length > 0) {
      const sortedFiles = invoicesData?.getInvoicesByOrgId.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))
      setDisplayFiles(sortedFiles)
    }
  },[invoicesData]) // eslint-disable-line

  
  if (invoicesLoading){
    return <LoadingScreen />
  }


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Orders </title>
      </Helmet>
      <Box
        sx={{ 
          pt: 6, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dash',
                },
                { name: 'Orders' },
              ]
              }
            heading='Orders'
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >

        <Grid container spacing={2} className='test-opaque'
          sx={{
            // borderTop: `${theme.palette.divider} solid 1px`,
            borderRadius: 2,
            overflow: 'hidden',
            // boxShadow: theme.shadows[7],
            mb: 6,
            // display: 'flex',

          }}
        >
          {displayFiles.map(invoice => {return <Grid item key={invoice._id} // eslint-disable-line
          xs={12} sm={6} md={4} sx={{
            // m:2, 
          }}>
            <Link href={`/orders/${invoice?._id}`} 
              sx={{
                '&:hover': {
                  textDecoration: 'none'
                },
              }}
            >
`          <Box 
            sx={{
              height: {
                xs: '150px',
                sm: '250px',
              },
              width: '100%',
              borderRadius: 2,
              backgroundColor: theme.palette.divider,
              border: `${theme.palette.info.light} solid 1px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              overflow: 'hidden',
            }}
            >
            <Stack direction='row' alignItems='center' justifyContent='center' overflow='hidden'>
              <Image src='/logo/pikes.svg' sx={{
                height: {
                  xs: '150px',
                  sm: '250px',
                },
                width: {
                  xs: '150px',
                  sm: '250px',
                },
                right: {
                  xs: '25px',
                  sm: 'unset',
                },
                opacity: {
                  xs: .1,
                  sm: .035,
                },
                ml: 10,
                position: 'absolute',
                zIndex: 0,
                overflow: 'hidden',
                // borderRadius: "50%",
                // border: `${theme.palette.text.primary} solid 2px`,
              }}/>  
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  p: 2,
                  cursor: 'pointer',
                }}
              >
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Order Date:</Typography>
                  <Typography variant='subtitle1'>{fDate2(invoice?.created_at)}</Typography>
                </Stack>
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Invoice Id:</Typography>
                  <Typography variant='subtitle1'>{invoice?._id}</Typography>

                </Stack>
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Amount:</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='subtitle1'>${fCurrencyCents(invoice?.totalPrice)}</Typography>
                    <Typography variant='caption' 
                      sx={{
                        color: invoice?.status === 'paid' ? theme.palette.success.main : theme.palette.info.main,
                        border: invoice?.status === 'paid' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.info.main} solid 1px`,
                        px: 1,
                        borderRadius: 2,
                      }}
                    >{invoice?.status === 'paid' ? 'PAID' : 'OPEN'}</Typography>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Songs:</Typography>
                    <Typography variant='subtitle1'>{invoice?.items?.length}</Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
          </Link>
          </Grid>
}
          )}
        </Grid>
      </Container>
      <Footer />

      <CustomToast toast={toast} setToast={setToast}/>

      <CartWidgetCustom totalItems={cartItems} />
    </>
  );
}
