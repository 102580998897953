import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';  // eslint-disable-line
import { m } from 'framer-motion';

import { useParams, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Grid, Box, Button, Container, ToggleButtonGroup, ToggleButton, Typography, TextField, Chip, IconButton, Divider, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useMutation, useQuery } from "@apollo/client";


// import { Dropbox } from 'dropbox';
// graphql
import axios from 'axios';
import { GET_DOCUMENT_BY_ID, GET_USER_BY_ID } from '../graphql/queries'



// components
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import { varFade, MotionViewport } from '../components-default/animate';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import { UploadBox } from '../components-default/upload';
import { genreList, moodList, instrumentList, themeList } from '../components-custom/TagList';
import AddArtistDialog from '../components-custom/AddArtistDialog';


import Footer from '../layouts/main/Footer';
import { ArtistSearch } from '../components-custom/sections/@dashboard/e-commerce/shop';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';
import TextfieldDisplay from '../components-custom/TextfieldDisplay';
import CustomToast from '../components-custom/CustomToast';

// ----------------------------------------------------------------------



export default function UploadMultitracksPage() {


  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  const navigate = useNavigate();

//   const windowParams = new URLSearchParams(window.location.search);
  // const briefId = windowParams.get('brief');

  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const { docId } = useParams();

  // console.log('DOC ID: ', docId)
  const { loading: docLoading, error: docError, data: docData = {getDocumentById: []}, refetch: docRefetch } = useQuery(GET_DOCUMENT_BY_ID, {
    variables: { documentId: docId  },
    fetchPolicy: 'network-only'
  });

  const pillsGap = 1.5;


  const dropboxAccessToken = process.env.REACT_APP_DROPBOX_TOKEN;
  // const dropbox = new Dropbox({ dropboxAccessToken });



  // console.log('User: ', user)
  const [artistDialogOpen, setArtistDialogOpen] = useState(false)
  

  const [uploadPercentage, setUploadPercentage] = useState(0);  // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []}); // eslint-disable-line
  const [ fileHolder, setFileHolder] = useState(null);
  const [ uploadFileType, setUploadFileType] = useState('ok');
  const [ title, setTitle] = useState('');
  const [ artistName, setArtistName] = useState('');
  const [errorState, setErrorState] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [validated, setValidated] = useState(false)

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});


  const { loading: dataLoading, error, data, refetch } = useQuery(GET_USER_BY_ID, {
    variables: { id: userr },
    fetchPolicy: 'network-only'
  });

  // ////////////////////////
  // ////////////////////////

  async function makeFileRequest() {
    console.log(process.env.REACT_APP_DROPBOX_TOKEN);
  }

  // ////////////////////////
  // ////////////////////////

  function ValidateEntry() {

    if (fileHolder === null){
      setErrorState('file')
      setErrorMessage('Add a file')
      return false
    } 
    return true
  }

  function successCallback(songId) {
      console.log('Upload Success!')
      if (docId) {
        // setTimeout(()=>{navigate(`/briefs/${docId}?message=uploadsuccess&songId=${songId}`)}, 500)
        setLoading(false);
      } else {
        navigate('/uploadsuccess');
      }
  }

  const handleUpload = async () => {
    if (ValidateEntry()){
      setLoading(true)
      console.log('File: ', fileHolder);
      const formData = new FormData();
      
      formData.append('userId', userr);
      formData.append('docId', docId);
      formData.append('file', fileHolder[0], fileHolder[0].name);
      
      console.log('FORMDATA: ',formData)
      try {
          const res = await axios.post(`${process.env.REACT_APP_SERVER}/multitrackupload`, formData, {  // eslint-disable-line
              headers: {
                  'Content-Type':'multipart/form-data',
              },
              onUploadProgress: progressEvent => {
                  setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) / 
                  progressEvent.total),10))
              }
          }).then(response => {
            console.log(response.data)
            successCallback(response.data.id);
  
          });
        } catch (err) {
          if (err.response.status) {
            console.log('No directory baby!')
          } else {
            console.log(err.response.data)
          }
      }
  } else {
    // 
  }
}

useEffect(()=>{
  if (docData !== undefined){
    // console.log('DATA: ',data)
    // console.log('DATA: ',data?.getDocumentById?.artist.name || '')
    setTitle(docData?.getDocumentById?.title || '')
    setArtistName(docData?.getDocumentById?.artist?.name || '')
  }
}, [docData])

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Uploaddd </title>
      </Helmet>
      <Box
        sx={{
          pt: 6,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                // name: 'Dashboard',
                // href: null,
              },
              // { name: 'Dashboard' },
            ]
            }
            activeLast
            // moreLink={['https://www.framer.com/api/motion']}
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          // backgroundColor: 'red',
        }}
      >
        <Box sx={{display: 'flex', gap: 2}}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Upload Multitracks
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 7, width: '100%', }}>
            {/* <Stack direction='column' spacing={0}
              sx={{
                borderRadius: 2,
                border: `${theme.palette.divider} solid 1px`,
                width: 'min-content',
                py: 2,
                pl: 2,
                pr: 3,
              }}
            >
              <Typography variant="h5" sx={{color: theme.palette.text.primary}}>
                {title}
              </Typography>
              <Typography variant="caption" sx={{color: theme.palette.text.primary}}>
                {artistName}
              </Typography>
            </Stack> */}
          <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3,  }}>
              <Box 
                sx={{ 
                  display: 'grid', 
                  gridTemplateRows: fileHolder === null ? (errorState === 'file' ? 'auto 10px' : '100%') : 'auto 40px', // eslint-disable-line
                  gap: 1, 
                  pt: 4
                }}
              > 
                  <UploadBox
                    onDrop={(e)=>{
                      if (e[0].type === 'application/zip' ) {
                        setUploadFileType('ok')
                        setFileHolder(e);
                        console.log(e);
                      } else {
                        setUploadFileType('error')
                      }
                    }}
                    accept=".zip"
                    sx={{
                      mt: 0,
                      mb: 0,
                      py: 1,
                      ml: 0,
                      width: '100%',
                      height: '100%',
                      boxSizing: 'border-box',
                      borderRadius: 1,
                      backgroundColor: uploadFileType !== 'error' ? `${theme.palette.text.primary}80` : theme.palette.error.dark, // eslint-disable-line 
                      border: (errorState === 'file' && !fileHolder) ? 'red solid 2px' : 'inherit',
                    }}

                    placeholder=
                    {
                      fileHolder === null && uploadFileType !== 'error' ? // eslint-disable-line
                      <Box sx={{ color: 'text.disabled', height: '100%', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1 }}>
                          <Stack direction='column' spacing={0}
                            sx={{
                              borderRadius: 2,
                              border: `${theme.palette.divider} solid 1px`,
                              width: 'min-content',
                              py: 2,
                              pl: 2,
                              pr: 3,
                            }}
                          >
                            <Typography variant="h5" sx={{color: theme.palette.text.primary}}>
                              {title}
                            </Typography>
                            <Typography variant="caption" sx={{color: theme.palette.text.primary}}>
                              {artistName}
                            </Typography>
                          </Stack>
                        <Stack direction='row' spacing={1} display='flex' justifyContent='center' alignItems='center' width='100%' height='100%'
                          sx={{
                            borderRadius: 1,
                            // border: `${theme.palette.info.dark} solid 1px`,
                            mx: 1,
                            backgroundColor: `${theme.palette.grey[100]}40`,
                          }}
                        >
                          <Typography variant="subtitle1" color='common.white'>Add/drop zip file</Typography>
                          <Iconify icon="eva:cloud-upload-fill" width={40} sx={{color: theme.palette.common.white,}}/>
                        </Stack>
                      </Box> : 
                      (uploadFileType !== 'error' ? <Box sx={{ color: theme.palette.text.primary, height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>  
                        <Typography variant="subtitle1" color='common.white'>{fileHolder[0].name || 'File added'}</Typography>
                        <Iconify icon="eva:checkmark-circle-2-fill" width={40} sx={{color: theme.palette.common.white,}}/>
                      </Box> : <Box sx={{ color: theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" color='common.white'>Must be a ZIP file</Typography>
                        <Iconify icon="eva:slash-fill" width={20} sx={{color: theme.palette.common.white,}}/>
                      </Box>
                      )
                    }
                  />
                  {fileHolder !== null ? 
                    <Button 
                      variant='text' 
                      onClick={()=>{
                        setFileHolder(null)
                        setTitle('')
                      }} 
                      sx={{ mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}
                    >
                      clear selection
                    </Button> :
                    <Box sx={{ height: '36px'}}/>
                  }
                  {(fileHolder === null && errorState === 'file') &&
                  <>
                    <Typography variant='caption' sx={{color: 'red'}}>{errorMessage}</Typography>
                  </>
                  }

              </Box>
            </Box>

          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{mb: 2, paddingTop: '0px',}}>
            <Box sx={{display: 'flex', gap: 2, alignContent: 'center'}}>
              <Button 
                variant='contained' 
                disabled={loading}
                onClick={()=>{
                  // console.log(title)
                  handleUpload(fileHolder)
                }} 
                sx={{ mt: 0, mb: 3, height: '36px', width: '80px'}}
                >
                {!loading? 'Upload' : '...'}
              </Button>
              <Box
                sx={{display: 'flex', alignItems: 'center', height: '34px'}}
              >

              <Typography variant='caption' sx={{color: theme.palette.error.main,}}>
                {errorMessage !== '' ? 'Check form for missing fields' : null}
              </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomToast toast={toast} setToast={setToast}/>
      </Container>
      <Footer />
 
      </>
  );
}
