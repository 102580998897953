export const plusSideList = [
    'sea otters hold hands when they sleep, which is kind of adorable.', 
    'Norway knighted a penguin. His name is Sir Nils Olav III, and he lives in Edinburgh Zoo. Three other members of his were family also made honorary members of the elite guard.',
    'the voices of Mickey Mouse and Minnie Mouse got married in real life. Their names were Wayne Allwine and Russi Taylor. They tied the knot in 1991 and remained together until Wayne passed away in 2009.',
    'Dolphins give each other specific names. These names are not like human names, they make certain sounds to each other to represent each dolphin.',
    "an astronaut wrote his daughter's initials on the moon. At least that's the official story, and we have no reason to doubt any official story.",
    "Vikings gave the cutest wedding presents. They might be famous for their battle skills, but Nordic tradition also meant that they gave kittens as wedding gifts.",
    "there's a basketball court on top of the U.S. Supreme Court building. It's actually called “The Highest Court In All The Land. Not a joke.",
    "goats have accents. What a goat sounds like heavily depends on the region they grow up in, just like humans.",
    "there's an island in Japan called Okunoshima, which is filled with friendly rabbits.",
    "a town in Alaska had a cat as mayor. His name was Mr. Stubbs, and he was honorary mayor for 17 years.",
    "in the last 20 years the proportion of people living in extreme poverty worldwide has almost halved.",
    "there's something called a lyrebird that can mimic almost any sounds it hears — including chainsaws.",
    "the largest sand castle in the world measured 54 feet high. It took 168 trucks over a week to deliver enough sand for it. Wow.",
    "fingernails grow faster than toenails. This is because your fingernails get more sun.",
    "the human body contains enough fat to make seven bars of soap.",
    "the average person walks the equivalent of five times around the world during their lifetime.",
    "the leg bones of a bat are so thin that no bat can walk, which I find comforting in a way.",
    "William Shakespeare invented 420 words, including 'bedroom', 'bump', 'dawn', 'excitement', and 'zany', which is pretty cool.",
    "in one especially fascinating study, researchers examined hundreds of baseball players' rookie baseball cards to measure the width of the players' smiles. Those with the biggest grins went on to live an average of seven years longer than the players who either didn't smile, or who smiled only a little. Wow.",
    "in 2021, a Croatian named Budimir Šobat voluntarily held his breath for astonishing 24 minutes and 37.36 seconds, which is the current world record.",
    "in 2001, a Spanish man named Ismael Rivas Falcon pulled a train weighing 2,753.1 kg (6,069 lbs) over a distance of 10 m (32.8 ft) with his beard.",
    "in 1995, a Canadian named Sean Shannon recited Hamlet's “To be or not to be” soliloquy (260 words) in just 23.8 seconds. That's pretty fast.",
    "a man named Les Stewart in Australia typed from one to one million (in words). It took him 16 years. A truly astonishing feat.",
    "the world record for the fastest time to drink two liters of soda is held by Eric Booker, who did it in 18.45 seconds. Something for all of us to aspire to.",
    "in 1992, London's Paul Lynch performed 124 consecutive one finger push-ups. Such strength!",
    "in 2007, Ashrita Furman managed to balance 81 beer glasses on his chin for 12.10 seconds. These were 20 oz glasses, not some miniature novelty glasses. Incredible.",
];

