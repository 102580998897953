import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Stack, TextField, InputAdornment, IconButton } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
// import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient';
import AudioFileCardOrderDetail from '../sections/@dashboard/general/file/AudioFileCardOrderDetail';

import { fDate2 } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';
import Image from '../components-default/image';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import { AudioContext } from '../components-custom/audio/audio-context';
import FilterPane from '../components-custom/FilterPane';
import InventoryShowSearchBox from '../components-custom/InventoryShowSearchBox';
import ScrollButton from '../components-custom/ScrollButton';


// ----------------------------------------------------------------------

export default function OrderDetailPageClientV2() {
  const theme = useTheme();
  const { id } = useParams();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id


  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;
  const paymentSuccessQuery = queryParams.paymentsuccess || null;

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  const [displayFiles, setDisplayFiles] = useState([]);
  const [invoiceInfo, setInvoiceInfo] = useState([]);

  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchTerm, setShowSearchTerm] = useState('');
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [songStatus, setSongStatus] = useState('pitched');
  const [sortMethod, setSortMethod] = useState('date-descending')

  const [axiosLoading, setAxiosLoading] = useState(true)
  const [inventoryCount, setInventoryCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(10)
  const [pageLinks, setPageLinks] = useState('')


  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });

  async function getInventory() {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/orderdetail`, {  // eslint-disable-line
        type: 'orderdetail',
        perPage,
        // modifier: songStatus,
        // sortMethod,
        orgId: user?.organization,
        invoiceId: id,
        searchTerm,
      }).then(response => {
        // console.log(response)
        setAxiosResults(response.data.invoiceInfo)
        setInventoryCount(response.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }
  }

  async function updateInventory() {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'auto', // Use smooth scrolling behavior
      });

      try {
        setAxiosLoading(true)
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/orderdetail`, {  // eslint-disable-line
          type: 'orderdetail',
          perPage,
          // modifier: songStatus,
          // sortMethod,
          orgId: user?.organization,
          invoiceId: id,
          searchTerm,
        }).then(response => {
          console.log(response)
          setAxiosResults(response.data.invoiceInfo)
          setInventoryCount(response.data?.totalDocs)
        });
      } catch (error) {
        console.error('Error fetching search results:', error);
      }

      setUpdateTrigger(false)

    } catch (err) {
      console.log(err)
    }
  }

  function handleClearSearch() {
    if (filter.length === 0) {
      setDisplayFiles(axiosResults?.items)
    }
  }

  useEffect(() => {
    getInventory()
  }, []) // eslint-disable-line


  useEffect(() => {
    if (paymentSuccessQuery && invoiceInfo?.status === 'paid') {
      navigate(`/orders/${invoiceInfo._id}`)
      // alert('Payment successfully paymented!')
      setToast({ open: true, icon: <AttachMoneyIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Payment successful, these songs are officially yours!`, duration: 6000, closeButton: true })
    }
    // getInventory()
  }, [invoiceInfo]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== '') {
      // console.log('AXIOS RESULTS: ', axiosResults)
      if (axiosResults?.organization?._id !== user?.organization) {
        navigate('/403')
      }
      setDisplayFiles(axiosResults?.items)
      setAxiosLoading(false)
      setInvoiceInfo(axiosResults)

      const tempFilterList = [];
      axiosResults?.items.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
          doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.mood.length > 0) {
          doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.instrument.length > 0) {
          doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
      })
      setFilterList(tempFilterList)


      const totalPages = axiosResults.totalDocs / perPage
      setPageCount(Math.ceil(totalPages))

      const buttons = [];

      for (let i = 1; i <= Math.ceil(totalPages);) {
        // buttons.push(<button type='button' key={i}>Page {i}</button>);
        buttons.push(
          <Button key={`pageButton${i}`} variant={(page === i) ? 'outlined' : 'text'} size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled={page === i}
            onClick={() => {
              setPage(i - 1)
              navigate(`${window.location.pathname}?page=${i - 1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color={page === i ? 'primary' : 'primary'}>
              {i}
            </Typography>
          </Button>

        );
        i += 1
      }

      setPageLinks(buttons)

      setAxiosLoading(false)

    }
  }, [axiosResults]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== '') {
      updateInventory()
    }
  }, [page, sortMethod, perPage]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== '') {
      updateInventory()
    }
  }, [songStatus]) // eslint-disable-line


  useEffect(() => { // eslint-disable-line

    // console.log('Filter: ', filter)

    const filteredFiles = [];
    const searchDisplay = [];

    if (axiosResults === '') {
      return
    }
    // ////// IF NO FILTERS
    if (filter.length === 0) {

      if (showSearchTerm === '') { // NO FILTERS, NO SEARCH
        // console.log('NO FILTERS, NO SEARCH')
        setDisplayFiles(axiosResults?.items)
      }

      if (showSearchTerm !== '') { // NO FILTERS, SEARCH TRUE
        axiosResults?.items.map(item => { // eslint-disable-line
          if (item.title.toLowerCase().includes(showSearchTerm.toLowerCase()) || item.artist.name.toLowerCase().includes(showSearchTerm.toLowerCase())) {
            searchDisplay.push(item)
          }
        })
        // console.log('NO FILTERS, SEARCH')
        setDisplayFiles(searchDisplay)
      }
    }

    // ////// IF SOME FILTERS
    if (filter.length) {
      // console.log('IF FILTERS')
      if (searchTerm === '') { // if filters but no search
        axiosResults?.items.map(doc => { // eslint-disable-line
          if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
            return filteredFiles.push(doc)
          }
        })
        // console.log('FILTERS, NO SEARCH')
        setDisplayFiles(filteredFiles)
        return
      }
      if (searchTerm !== '') { // if filters and search term
        axiosResults?.items.map(item => { // eslint-disable-line
          if (item.title.toLowerCase().includes(showSearchTerm.toLowerCase()) || item.artist.name.toLowerCase().includes(showSearchTerm.toLowerCase())) {
            searchDisplay.push(item)
          }
        })
        searchDisplay.map(doc => { // eslint-disable-line
          if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
            return filteredFiles.push(doc)
          }
        })
        // console.log('FILTERS AND SEARCH')
        setDisplayFiles(filteredFiles)
      }

    }

  }, [filter, showSearchTerm]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }

    if (updateTrigger === false) {
      return
    }

    if (filter.length > 0) {
      console.log('FILTER: ', filter)
    }

  }, [updateTrigger]); // eslint-disable-line 

  if (axiosLoading) {
    return <LoadingScreen />
  }

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dash',
                },
                {
                  name: 'Orders',
                  href: '/orders',
                },
                { name: `Invoice ${id}` },
              ]
            }
            activeLast
            heading='Order Details'
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Box
          sx={{
            // height: '150px',
            cursor: 'default',
            width: '100%',
            borderRadius: 1,
            backgroundColor: theme.palette.divider,
            border: `${theme.palette.info.light} solid 1px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mb: 6,
            px: 3,
            py: 1,
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Order Date:</Typography>
              <Typography variant='subtitle1'>{fDate2(invoiceInfo?.created_at)}</Typography>
            </Stack>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Invoice Id:</Typography>
              <Typography variant='subtitle1'>{invoiceInfo?._id}</Typography>
            </Stack>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Songs:</Typography>
              <Typography variant='subtitle1'>{invoiceInfo?.items?.length}</Typography>
            </Stack>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Amount:</Typography>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='subtitle1'>${fCurrencyCents(invoiceInfo?.totalPrice)}</Typography>
                <Typography variant='caption'
                  sx={{
                    color: invoiceInfo?.status === 'paid' ? theme.palette.success.main : theme.palette.info.main,
                    border: invoiceInfo?.status === 'paid' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.info.main} solid 1px`,
                    px: 1,
                    borderRadius: 2,
                  }}
                >{invoiceInfo?.status === 'paid' ? 'PAID' : 'OPEN'}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
              Songs
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.info.light }}>
              {songStatus === 'pitched' &&
                `(${inventoryCount})`
              }
            </Typography>
          </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}
            sx={{
              mb: 7,
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              },
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
          >
            {showSearchTerm !== '' &&
              <Box
                sx={{
                  // height: '100px',
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: theme.palette.divider,
                  border: `${theme.palette.info.main} solid 1px`,
                  p: 3,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant='subtitle1'>
                  Showing results for &apos;{showSearchTerm}&apos;
                </Typography>
                <IconButton onClick={() => {
                  setSearchTerm('')
                  setShowSearchTerm('')
                  handleClearSearch()
                  // setUpdateTrigger(true)
                }}
                >
                  <CancelIcon />

                </IconButton>
              </Box>
            }
            <InventoryShowSearchBox
              showSearchTerm={showSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setSearchTerm={setSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              filter={filter}
              setFilter={setFilter}
            />

            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%' /> :
              <Box >
                {(displayFiles?.length > 0) ?
                  displayFiles.map((doc, index) => (

                    <AudioFileCardOrderDetail
                      key={doc._id}
                      id={doc._id}
                      userId={userr}
                      user={user}
                      file={doc}
                      // data={invoiceData?.getInvoiceById?.items}
                      filter={filter}
                      // dispatch={dispatch}
                      // addToCart={addToCart}
                      // refetch={()=> doRefetch()}
                      loadedSong={src}
                      setLoadedSong={setSrc}
                      index={index}
                      sx={{ mb: 2, mt: 0, display: 'flex' }} // eslint-disable-line
                      setControlMessage={setControlMessage}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      toast={toast}
                      setToast={setToast}
                      favorites
                      passable
                      inventoryPage
                    />
                  )) :
                  <Typography variant="h5" sx={{ mb: 0 }}>
                    No results
                  </Typography>
                }
              </Box>
            }
            {pageCount > 1 &&
              <Stack direction='row' height='50px' width='100%' gap={1}
                sx={{
                  justifyContent: 'flex-end'
                }}
              >
                {page > 1 &&
                  <Button variant='contained' size='small'
                    onClick={() => {
                      setPage(page - 1)
                      navigate(`${window.location.pathname}?page=${page - 1}`)
                    }}
                  >
                    Prev
                  </Button>
                }
                {pageLinks}
                <Button variant='contained' size='small'
                  onClick={() => {
                    setPage(page + 1)
                    navigate(`${window.location.pathname}?page=${page + 1}`)
                  }}
                  disabled={page === pageCount}
                >
                  Next
                </Button>
              </Stack>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: 7,
              mt: 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              height: 'fit-content',
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            <FilterPane
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              setControlMessage={setControlMessage}
              showFilter
              // setShowFilter={setShowFilter}
              setFilteredDisplayFiles={setFilteredDisplayFiles}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              // fullPath={fullPath}
              filter={filter}
              setFilter={setFilter}
            />
          </Grid>


        </Grid>
      </Container >
      <Footer />
      <ScrollButton playerLoaded={src?.audioUrl}/>
      <CustomToast toast={toast} setToast={setToast} />

    </>
  );
}
