import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui

import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Card, Typography, CardContent, Stack, Link } from '@mui/material';
// import { useQuery } from '@apollo/client';
import axios from '../../../../utils/axios';
import { useAuthContext } from '../../../../auth/useAuthContext';

// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrencyCents } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components-default/image';
import Iconify from '../../../../components-default/iconify';
import TextMaxLine from '../../../../components-default/text-max-line';
import isAA from '../../../isAA';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha('#85795a', .3),
  transition: 'all .15s ease-in-out',
  '&:hover': {
    backgroundColor: alpha('#85795a', 0.14),
    // backgroundColor: alpha(theme.palette.grey[700], 0.11),


  }
}));

// ----------------------------------------------------------------------

BriefPostCardV2Admin.propTypes = {
    id: PropTypes.string,
    tier: PropTypes.any,
    post: PropTypes.object,
    cover: PropTypes.string,
    cardHeight: PropTypes.string,
    currentPitches: PropTypes.number,
    orgName: PropTypes.string,
    pitchedSongList: PropTypes.number,
    briefStats: PropTypes.array,
  };
  
  export default function BriefPostCardV2Admin({ post, tier, cover, cardHeight, id, currentPitches, orgName, pitchedSongList, briefStats }) {

    const theme = useTheme();
    const navigate = useNavigate();
    // console.log('ORGNAME CARD: ', orgName)
  
    const { user } = useAuthContext();
    const userr = user?._id || user?._id

    const [subsTotal, setSubsTotal] = useState(0)

    function filterPitches(postId){
      let displayResult = 0

      briefStats?.map(item => { // eslint-disable-line
        // console.log('ID: ', postId)
        // console.log('ITEM ID: ', item?._id)

        if (item?._id === postId){
          displayResult = item?.total
          return displayResult
        }
      })
      return displayResult
    }

    function filterSubs(postId){
      let displayResult = 0

      briefStats?.map(item => { // eslint-disable-line
        // console.log('ID: ', postId)
        // console.log('ITEM ID: ', item?._id)

        if (item?._id === postId){
          displayResult = item?.x || 0
          return displayResult
        }
      })
      return displayResult
    }

    async function getSubsCount(){
        try {
          const res = await axios.post(`${process.env.REACT_APP_SERVER}/getsubmissionscount`, {  // eslint-disable-line
            briefId: id,
          }).then(response => {
            // console.log( `${post?.title} Sub Count: `, response?.data?.briefSubs)
            setSubsTotal(response?.data?.briefSubs)
              // setLoadingPost(false)
          }
          );
        } catch (err) {
          console.log(err)
        }              
      }

    useEffect(()=>{
      getSubsCount()
    }, []) // eslint-disable-line

    // console.log('PITCHED SONG LIST: ', pitchedSongList)

    return(
        <Link href={user?.role === 'admin' ? `/briefs/admin/${id}` : `/briefs/${id}`}>
        
        <Card
          sx={{
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            opacity: post?.active ? 1 : .4,
            height: cardHeight || 'inherit',
            '&:hover': {
              // boxShadow: '-8px 8px 2px rgba(0, 0, 0, 0.1)',
            },
          }}
  
        >
          {user?.role !== 'client' &&
          <Box // wrapper label
          >
            <Typography variant='h5' color='common.white' fontWeight='bold'
              sx= {{ // wrapper text
                display: 'none',
                position: 'absolute',
                top: 16,
                right: 4,
                zIndex: 20,
                // border: `${theme.palette.common.white}40 solid 1px`,
                borderRadius: 5,
                textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
                px: 2,
              }}>
                {post?.customRate ?
                 `$${fCurrencyCents(post?.customRate)}` :
                  `$${fCurrencyCents(post?.rate)}`
                }
            </Typography>
            <Box // wrapper background
              sx={{
                // display: 'none',
                position: 'absolute',
                top: -30,
                right: -70,
                zIndex: 10,
                height: '100px',
                width: '200px',
                // boxShadow: theme.shadows[12],
                backgroundColor: `${theme.palette.grey[900]}80`,
                transform: 'rotate(37deg)'
              }}
              />
  
          </Box>
          }
          <Typography 
              sx={{
                cursor: 'pointer',
                top: 24,
                left: 24,
                zIndex: 9,
                position: 'absolute',
                color: theme.palette.common.white,
                fontFamily: isAA() ? "peckham-press, sans-serif !important" : "gravesend-sans, sans-serif !important",
                fontSize: isAA() ? '18px' : '26px',
                fontWeight: isAA() ? 'inherit' : 'bold',
                lineHeight: isAA() ? '26px' : '26px',
                border: `${theme.palette.common.white} 2px solid`,
                borderRadius: '32px',
                padding: 2,
                paddingTop: isAA() ? 2.5 : 2,
                backgroundColor: `${theme.palette.grey[900]}80`,
              }}
              onClick={()=>{
                navigate(`/briefs/${id}`)
              }
              }
          >
            {post?.category || ''}
          </Typography>
          <Typography 
              sx={{
                cursor: 'pointer',
                top: 6,
                left: 6,
                zIndex: 9,
                position: 'absolute',
                color: theme.palette.common.white,
                fontFamily: "gravesend-sans, sans-serif !important",
                fontSize: '15px',
                // fontWeight: isAA() ? 'inherit' : 'bold',
                // lineHeight: isAA() ? '26px' : '26px',
                border: `${theme.palette.common.white} 1px solid`,
                borderRadius: 2,
                px: 2,
                // padding: 2,
                backgroundColor: `${theme.palette.info.darker}`,
              }}
          >
            {`Tier ${tier} - $${fCurrencyCents(post?.rate)}` || ''}
          </Typography>
          <Box // current pitches wrapper
           sx={{
            display: ((user?.role === 'client' || user?.role === 'admin') && post?.active === true) ? 'flex' : 'none',
            position: 'absolute',
            // top: 2,
            // right: 2,
            height: '100%',
            width: '100%',
            justifyContent: 'flex-end',
            zIndex: 10,
           }}
          >
            <Stack direction='column'
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                // color: theme.palette.success.main,
                zIndex: 200,
                mx: 1.5,
                my: 1,
                // px: 3.3,
                // py: 2,
                borderRadius: '50%',
                // border: 'white solid 1px',
                
              }}
              >

              <Typography variant='h5'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center'
                  
                }}
                >
                {filterPitches(id)}
              </Typography>
              <Typography variant='caption'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                Pitches
              </Typography>
            </Stack>
            <Box // current pitches background
              sx={{
                position: 'absolute',
                top: -200,
                right: -200,
                height: '300px',
                width: '300px',
                // borderRadius: '50%',
                backgroundColor: alpha(theme.palette.success.dark, 1),
                display: 'flex',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                transform: 'rotate(45deg)',
                zIndex: 4,
              }}/>
          </Box>
          <Box // current submissions wrapper
           sx={{
            display: user?.role === 'admin' ? 'flex' : 'none',
            position: 'absolute',
            bottom: 6,
            right: 6,
            height: '50px',
            width: '50px',
            opacity: subsTotal > 0 ? 1 : .2,
            justifyContent: 'flex-end',
            zIndex: 10,
            borderRadius: '50%',
            backgroundColor: theme.palette.common.black,
           }}
          >
            <Stack direction='column'
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                zIndex: 200,
                // mx: 1.5,
                // my: 1,
                // borderRadius: '50%',
              }}
              >
              <Typography variant='body'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center'
                  
                }}
                >
                {subsTotal}
              </Typography>
            </Stack>
          </Box>
          {(user?.role === 'admin' && orgName) &&
            <Typography variant='h3' textTransform='uppercase'
              sx={{
                position: 'absolute',
                bottom: '90px',
                textAlign: 'center',
                width: '100%',
                // left: '50%',
                color: 'white',
                opacity: .5,
                zIndex: 100,
              }}
            >
              {orgName}
            </Typography>
          }

          <PostContent
            orgName
            user={user}
            title={post?.title || ''}
            createdAt={post?.createdAt || null}
          />
  
          <StyledOverlay />
  
          <Image 
            alt="cover" 
            src={post?.cover} 
            sx={{ height: 360 }} 
              
          />
        </Card>
        </Link>
    )
}

    export function PostContent(post, orgName, user) {
        // const isDesktop = useResponsive('up', 'md');
      
        const theme = useTheme();
        // console.log(orgName)
      
        return (
          <CardContent
            sx={{
              // pt: 4.5,
              width: 1,
              pt: 0,
              zIndex: 3,
              bottom: 0,
              position: 'absolute',
              color: 'common.white',
              '&:hover': {
                background: 'none',
              }
            }}
            >
            <TextMaxLine
              variant='h5'
              line={1}
              persistent
              sx={{
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
            }}
              >
              {post?.title || ''}
            </TextMaxLine>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{
                opacity: 0.8,
                color: 'common.white',
                textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              {fDate(post?.createdAt || null)}
            </Typography>
      
          </CardContent>
        );
      }
      