// import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui

import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Card, Typography, CardContent, Stack, Link } from '@mui/material';
// import axios from 'axios';
import { useAuthContext } from '../../../../auth/useAuthContext';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fCurrencyCents } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components-default/image';
import Iconify from '../../../../components-default/iconify';
import TextMaxLine from '../../../../components-default/text-max-line';
import isAA from '../../../isAA';
// import BadgeStatus from '../../../../components-default/badge-status/BadgeStatus';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], .4),
  transition: 'all .15s ease-in-out',
  '&:hover': {
    backgroundColor: alpha('#85795a', 0.14),
    // backgroundColor: alpha(theme.palette.grey[700], 0.11),
  }
}));

// ----------------------------------------------------------------------

BriefPostCardV2.propTypes = {
    id: PropTypes.string,
    post: PropTypes.object,
    cover: PropTypes.string,
    currentPitches: PropTypes.number,
    orgName: PropTypes.string,
    briefStats: PropTypes.array,
  };
  
  export default function BriefPostCardV2({ post, cover, id, briefStats, currentPitches, orgName }) {

    const theme = useTheme();
    const navigate = useNavigate();
  
    const { user } = useAuthContext();

    return(
        <Link href={user?.role === 'client' ? `/briefs/client/${id}` : user?.role === 'admin' ? `/briefs/admin/${id}` : `/briefs/${id}`} // eslint-disable-line
        >
        
        <Card
          sx={{
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            opacity: post?.active ? 1 : .4,
            '&:hover': {
              boxShadow: '-6px 8px 2px rgba(0, 0, 0, 0.1)',
              transform: 'translateX(2px) translateY(-6px)'
            },
            height: {
              sm: '240px',
              md: '260px',
            }
          }}
        >
          {(user && user?.role !== 'client') &&
          <Box // wrapper label
          >
            <Typography variant='h5' color='common.white' fontWeight='bold'
              sx= {{ // wrapper text
                position: 'absolute',
                top: 6,
                right: 12,
                zIndex: 20,
                borderRadius: 5,
                textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
                // px: 2,
              }}>
                {post?.customRate ?
                 `$${fCurrencyCents(post?.customRate)}` :
                  `$${fCurrencyCents(post?.rate)}`
                }
            </Typography>
            <Box // wrapper background
              sx={{
                position: 'absolute',
                top: -30,
                right: -90,
                zIndex: 10,
                height: '100px',
                width: '200px',
                // backgroundColor: `${theme.palette.grey[900]}80`,
                // backgroundColor: alpha(theme.palette.grey[900], .5),
                backgroundColor: theme.palette.success.dark,
                transform: 'rotate(37deg)'
              }}
              />
  
          </Box>
          }
          <Typography 
              variant={isAA() ? 'body' : 'h5'}
              sx={{
                cursor: 'pointer',
                top: 12,
                left: 12,
                zIndex: 11,
                position: 'absolute',
                color: theme.palette.common.white,
                fontFamily: isAA() ? "peckham-press, sans-serif !important" : "gravesend-sans, sans-serif !important",
                fontSize: isAA() ? '12px' : '22px',
                // fontWeight: isAA() ? 'inherit' : 'bold',
                lineHeight: isAA() ? '22px' : '26px',
                border: `${theme.palette.common.white} 2px solid`,
                borderRadius: '32px',
                padding: 1,
                px:1.5,
                paddingTop: isAA() ? 1.5 : 1,
                backgroundColor: `${theme.palette.grey[900]}80`,
              }}
              onClick={(user?.role === 'client') ? ()=>{navigate(`/briefs/client/${id}`)} : ()=>{navigate(`/briefs/${id}`)}
              }
          >
            {post?.category || ''}
            {!user?.receipts?.briefs.includes(id) &&
              <Box // wrapper label
              >
                <Box // wrapper status
                  sx={{
                    position: 'absolute',
                    top: -8,
                    left: -10,
                    zIndex: 12,
                    height: '18px',
                    width: 'fit-content',
                    borderRadius: 2,
                    backgroundColor: theme.palette.success.dark,
                    px: .75,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  >
                    <Typography variant='caption' sx={{color: 'white'}}>
                      New
                    </Typography>
                  </Box>
              </Box>
            }
          </Typography>

          {(post?.subGenres?.length > 0 && 
          !post?.subGenres.includes('Main') &&
          !post?.subGenres.includes('Other') &&
          post?.subGenres[0] !== ''
          ) &&
              <Typography
                variant='caption'  
                sx={{
                  // py: 1,
                  position: 'absolute',
                  top: 58,
                  left: 10,
                  zIndex: 10,
                  // left: 2,
                  width: 'fit-content',
                  px: 1,
                  ml: 1,
                  cursor: 'default',
                  color: 'common.white',
                  textShadow: theme.shadows[7],
                  borderRadius: 2,
                  border: 'white solid 1px',
                  backgroundColor: `${theme.palette.grey[900]}90`,
                }}
              >
                {post?.subGenres}
              </Typography>
              }
          <Box // current pitches wrapper
           sx={{
            display: ((user?.role === 'client' || user?.role === 'admin' || !user) && post?.active === true) ? 'flex' : 'none',
            position: 'absolute',
            height: '100%',
            width: '100%',
            justifyContent: 'flex-end',
            zIndex: 10,
           }}
          >
            <Stack direction='column'
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                zIndex: 200,
                mx: 1.5,
                my: 1,
                borderRadius: '50%',
                
              }}
              >
              <Typography variant='h5'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center',
                }}
                >
                {user?.role === 'client' && (briefStats[0]?.total || 0)}
              </Typography>
              <Typography variant='caption'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center',
                }}
              >
                {user?.role === 'client' && briefStats[0]?.total === 1 ? `Pitch\u00A0` : `Pitches` }
              </Typography>
            </Stack>
            <Box // wrapper background
              sx={{
                position: 'absolute',
                top: -200,
                right: -200,
                height: '300px',
                width: '300px',
                backgroundColor: alpha(theme.palette.success.dark, 1),
                display: 'flex',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                transform: 'rotate(45deg)',
                zIndex: 4,
              }}/>

          </Box>
          <Box // inCart wrapper
           sx={{
            display: ((user?.role === 'client' || user?.role === 'admin' || !user) && post?.active === true) && briefStats[0]?.inCart > 0 ? 'flex' : 'none',
            position: 'absolute',
            bottom: 9,
            right: 9,
            height: 'fit-content',
            width: 'fit-content',
            zIndex: 10,
           }}
          >
            <Stack direction='column'
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                zIndex: 200,
                mb: 1.5,
                pr: 2.6,
                borderRadius: '50%',
                
              }}
              >
              <Typography variant='h6'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center',
                  pb: 0,
                  pl: .2,
                  mb: '-12px',
                  zIndex: 5,
                }}
                >
                {user?.role === 'client' && briefStats[0]?.inCart}
              </Typography>
              <Iconify icon='grommet-icons:cart' width='22px'
                sx={{
                  color: theme.palette.common.white,
                  opacity: .5,
                  zIndex: 10,
                  mb: .4,
                }}
              />
            </Stack>
            <Box // wrapper background
              sx={{
                position: 'absolute',
                bottom: 6,
                right: 6,
                height: '50px',
                width: '50px',
                borderRadius: '50%',
                border: `${theme.palette.common.white} solid 1px`,
                opacity: .8,
                backgroundColor: alpha(theme.palette.grey[900], 1),
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                transform: 'rotate(45deg)',
                zIndex: 4,
              }}/>
          </Box>
          {(user?.role === 'admin' && orgName) &&
            <Typography variant='h1' textTransform='uppercase'
              sx={{
                position: 'absolute',
                bottom: '90px',
                color: 'white',
                opacity: .5,
                zIndex: 100,
              }}
            >
              {orgName}
            </Typography>
          }

          <PostContent
            orgName
            user={user}
            title={post?.title || ''}
            createdAt={post?.createdAt || null}
            subGenres={post?.subGenres}
          />
  
          <StyledOverlay />
  
          <Image 
            alt="cover" 
            src={post?.cover} 
            sx={{ height: 360 }} 
              
          />
        </Card>
        </Link>
    )
}

    export function PostContent(post, orgName, user, subGenres) {
        // const isDesktop = useResponsive('up', 'md');
      
        const theme = useTheme();
        // console.log(orgName)
      
        return (
          <CardContent
            sx={{
              // pt: 4.5,
              width: 1,
              pt: 0,
              zIndex: 3,
              bottom: 0,
              position: 'absolute',
              color: 'common.white',
              fontFamily: isAA() ? "peckham-press, sans-serif !important" : "gravesend-sans, sans-serif !important",
              '&:hover': {
                background: 'none',
              }
            }}
            >
            <TextMaxLine
              variant='h5'
              line={1}
              persistent
              sx={{
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
              }}
              >
              {post?.title || ''}
            </TextMaxLine>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{
                opacity: 0.8,
                color: 'common.white',
                textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              {fDate(post?.createdAt || null)}
            </Typography>
      
          </CardContent>
        );
      }
      