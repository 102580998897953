import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, TextField, InputAdornment, IconButton } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

import { addToCart } from '../redux/slices/product';

// components
import useResponsive from '../hooks/useResponsive';
// import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import {genreList, instrumentList, moodList } from '../components-custom/TagList';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';


// ----------------------------------------------------------------------

export default function ClientInventoryPageShare() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;
  const searchQuery = queryParams.search || null;
  const orgQuery = queryParams.org || null;

  // console.log('User: ', user)

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(isDesktop);
  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [showSearchTerm, setShowSearchTerm] = useState(searchTerm);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [songStatus, setSongStatus] = useState('pitched');
  const [sortMethod, setSortMethod] = useState('date-descending')

  // const [pageLoaded, setPageLoaded] = useState(false)

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [inventoryCount, setInventoryCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pageLinks, setPageLinks] = useState('')
  // const [pageLinksAbbrev, setPageLinksAbbrev] = useState('')

  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});
  const [ cartItems, setCartItems] = useState(null);

  const [selectedId, setSelectedId] = useState(null);

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  function noResultsMessage(){
    if (songStatus === 'all'){
      return 'No songs currently available'
    }
    if (songStatus === 'all'){
      return 'No songs currently available'
    }
    if (songStatus === 'pitched'){
      return 'No songs currently pitched'
    }
    if (songStatus === 'pass'){
      return 'No song pitches currently passed on'
    }
    if (songStatus === 'revise'){
      return 'No song pitches currently requested to revise'
    }
    if (songStatus === 'unpitched'){
      return 'No songs currently unpitched'
    }
    if (songStatus === 'sold'){
      return 'No songs sold yet'
    }
    return null
  }

  async function getInventory(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'clientinventory',
        perPage,
        // modifier: songStatus,
        modifier: 'sold',
        sortMethod,
        orgId: orgQuery,
        searchTerm,
      }).then(response => {
        // console.log(response)
        setAxiosResults(response.data)
        setInventoryCount(response.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  async function getCartCount(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getcartcount`, {  // eslint-disable-line
        orgId: user?.organization,
      }).then(response => {
        // console.log(response)
        // setAxiosResults(response.data)
        setCartItems(response.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  async function updateInventory(){
    try {
      window.scrollTo({
        top: 0,
        behavior: 'auto', // Use smooth scrolling behavior
      });

      const normalPull = async () => {
        try {
          setAxiosLoading(true)
          const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
            type: 'clientinventory',
            page,
            perPage,
            modifier: 'sold',
            sortMethod,
            filter,
            searchTerm,
            orgId: orgQuery,
          }).then(response => {
            // console.log(response)
            setAxiosResults(response.data)
            
          });
            } catch (error) {
              console.error('Error fetching search results:', error);
            }
          }

          normalPull()
          setUpdateTrigger(false)
      
    } catch (err) {
      console.log(err)
    }              
  }

  async function getInventoryCount(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventoryCount',
        userId: user?._id,
      }).then(response => setInventoryCount(
        <Typography variant="subtitle1" fontWeight={500} sx={{color: theme.palette.info.light }}>
        {`(${response.data} Total)`}
        </Typography>
        )
      );
    } catch (err) {
      console.log(err)
    }              
  }

  useEffect(() => {
    getInventoryCount()
    getCartCount()
    getInventory()
  },[]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.docs)

      const tempFilterList = [];
      axiosResults?.docs.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
              doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.mood.length > 0) {
              doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.instrument.length > 0) {
              doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
      })
      setFilterList(tempFilterList)


      const totalPages = axiosResults.totalDocs/perPage
      setPageCount(Math.ceil(totalPages))

      const buttons = [];

      for (let i = 1; i <= Math.ceil(totalPages);) {
        // buttons.push(<button type='button' key={i}>Page {i}</button>);
        buttons.push(
          <Button key={`pageButton${i}`} variant={(page === i) ? 'outlined' : 'text'} size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled={page === i}
            onClick={()=>{
              setPage(i-1)
              navigate(`${window.location.pathname}?org=${orgQuery}&page=${i-1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
          <Typography variant='subtitle1' color={page === i ? 'primary' : 'primary'}>
            {i}
          </Typography>
        </Button>

        );
        i += 1
      }

      setPageLinks(buttons)

      setAxiosLoading(false)

    }
  },[axiosResults]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== ''){
      updateInventory()
    }
  },[page, sortMethod, perPage]) // eslint-disable-line

  // useEffect(() => {
  //   if (axiosResults !== '' && pageQuery !== null){
  //     setPage(pageQuery)
  //   }
  // },[pageQuery]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      updateInventory()
    }
  },[songStatus]) // eslint-disable-line




  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };


  useEffect(() => { // eslint-disable-line

    // console.log('Filter: ', filter)

    const filteredFiles = [];

    if (axiosResults === '') {
      return
    }

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('pause')
        setIsPlaying(false)
      }
      // setShowSearchTerm(searchTerm)
      navigate(`/share?org=${orgQuery}&page=1`)
      setPage(1)
      updateInventory()
      
    } else {      
      setPage(1)
      navigate(`/share?org=${orgQuery}&page=1`)
      updateInventory()
    }

  }, [filter]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }
    
    if (updateTrigger === false) {
      return
    }
    setPage(1)
    updateInventory()

  }, [updateTrigger]); // eslint-disable-line 


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              { name: 'Inventory' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
              Current Inventory
            </Typography>
            <Typography variant="h5" sx={{color: theme.palette.info.light}}>
            {songStatus === 'pitched' &&
            `(${inventoryCount})`
            }
            </Typography>
          </Stack>
          <Stack direction='row' gap={2}>
            <Select  
              // defaultValue={sortMethod}
              value={sortMethod}
              sx={{
                display: 'flex',
                height: '36px',
                width: '200px',
              }}
              onChange={(e)=>{setSortMethod(e.target.value)}}
            >
              <MenuItem value='date-descending'>
                Sort by: Date <Iconify icon='bx:down-arrow' sx={{height: '12px'}}/>
              </MenuItem>
              <MenuItem value='date-ascending'>
                Sort by: Date <Iconify icon='bx:up-arrow' sx={{height: '12px'}}/>
              </MenuItem>
            </Select>
            <Select  
              // defaultValue={sortMethod}
              value={perPage}
              sx={{
                display: 'flex',
                height: '36px',
                width: '140px',
              }}
              onChange={(e)=>{
                setPage(0)
                setPerPage(e.target.value)
                navigate(`/share?org=${orgQuery}`)
              }}
            >
              <MenuItem value={10}>
                10 per page
              </MenuItem>
              <MenuItem value={20}>
                20 per page
              </MenuItem>
              <MenuItem value={30}>
                30 per page
              </MenuItem>
            </Select>
          </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid  item xs={12} sm={12} md={9} lg={9} xl={9} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
            >
            <Stack direction='row' gap={3} 
              display='none'
              sx={{
                width: '100%',
                gridTemplateColumns: {
                  xs: '1fr 1fr 1fr',
                  sm: '1fr 1fr 1fr',
                  md: '1fr 1fr 1fr',
                },
                // mt: 2,
                mb: 2,
              }}
            >
              <Button 
                variant={songStatus === 'pitched' ? 'outlined': 'outlined'} 
                color={songStatus === 'pitched' ? 'primary' : 'info'}
                sx={{
                  opacity: songStatus === 'pitched' ? 1 : .3,
                  width: '100%',
                }}
                onClick={()=>{
                  setSongStatus('pitched')
                  setPage(1)
                  navigate(`${window.location.pathname}?org=${orgQuery}&page=1`)
                }}
              >
                Pitched {songStatus === 'pitched' && `(${axiosResults?.totalDocs})`}
              </Button>
              <Button 
                variant={songStatus === 'sold' ? 'contained': 'outlined'} 
                color={songStatus === 'sold' ? 'success' : 'info'}
                sx={{
                  opacity: songStatus === 'sold' ? 1 : .3,
                  width: '100%',
                  backgroundColor: songStatus === 'sold' ? theme.palette.success.main : 'none',
                }}
                onClick={()=>{
                  setSongStatus('sold')
                  setPage(1)
                  navigate(`${window.location.pathname}?org=${orgQuery}&page=1`)
                }}
              >
                Bought {songStatus === 'sold' && `(${axiosResults?.totalDocs})`}
              </Button>
            </Stack>
            {showSearchTerm !== '' &&
              <Box
                sx={{
                  // height: '100px',
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: theme.palette.divider,
                  border: `${theme.palette.info.main} solid 1px`,
                  p: 3,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant='subtitle1'>
                  Showing results for &apos;{showSearchTerm}&apos;
                </Typography>
                <IconButton onClick={()=>{
                  setSearchTerm('')
                  setShowSearchTerm('')
                  setUpdateTrigger(true)
                  navigate(`/share?org=${orgQuery}`)
                }}
                >
                  <CancelIcon />

                </IconButton>
              </Box>
          }
            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%'/> : 
            <Box onKeyDown={handleKeyDown}>
            {displayFiles?.length > 0 ? 
                displayFiles.map((doc, index) => (
                  <AudioFileCardClient
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                ))  :
                <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                 {noResultsMessage()}
                </Typography>
            }
            </Box>
           }
           {pageCount > 1 &&
           <Stack direction='row' height='50px' width='100%' gap={1}
            sx={{
              justifyContent: 'flex-end'
            }}
           >
            {page > 1 &&
            <Button variant='contained' size='small'
            onClick={()=>{
              setPage(page -1)
              navigate(`${window.location.pathname}?org=${orgQuery}&page=${page-1}`)
            }}
            >
              Prev
            </Button>
            }
            {pageLinks}
            <Button variant='contained' size='small'
              onClick={()=>{
                setPage(page +1)
                navigate(`${window.location.pathname}?org=${orgQuery}&page=${page+1}`)
              }}
              disabled={page === pageCount}
            >
              Next
            </Button>
           </Stack>
           }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} 
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: {
                sm: 3,
                md: 7,
              },
              mt: 3,
              pb: showFilter ? 'inherit' : 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mr: 4, mt: 0, gap: 3 }}>
            <Stack direction='row' width='100%' alignItems='center'
                display='flex' 
              >
                <Iconify icon={!showFilter ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'} 
                  sx={{
                    // mx:2,
                    pl: 0,
                    // width: '34px',
                    cursor: 'pointer',
                    color: theme.palette.info.light,
                    display: {
                      sm: 'inherit',
                      md: 'none',
                    },
                  }}
                  onClick={()=>{setShowFilter(!showFilter)}}
                />

                  <Typography variant="h5" sx={{ mb: 0 }}>
                    Filters
                  </Typography>
              </Stack>
              <Button 
                variant="text" 
                onClick={() => {
                  setFilter([])
                  setFilteredDisplayFiles([])
                }}
                sx={{ 
                  display: showFilter ? 'inherit' : 'none',
                  m: 0, 
                  p: 0, 
                  textTransform: 'none', 
                  fontSize: '12px', 
                  fontWeight: '200', 
                  color: theme.palette.primary.darker
                }}
                >
                clear filters
              </Button>
            </Box>
            <Box sx={{
              display: showFilter ? 'flex' : 'none',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'flex-start',
                sm: 'flex-start',
              },
              m: 0,
              p: 0,
              pt: 2,
              pr: 1,
              pb: 3,
            }}>
            <TextField fullWidth
              size='small'
              placeholder='Search artist or song'
              autoComplete='off'
              color='info'
              sx={{
                pt: 1,
                pb: 2,
                mr: 2,
              }}
              value={searchTerm}
              onChange={(e)=> {
                // console.log(e.target.value)
                setSearchTerm(e.target.value)
              }}
              onKeyDown={(e)=>{
                if (e.key === 'Enter'){
                  updateInventory()
                  setShowSearchTerm(searchTerm)
                  navigate(`/share?org=${orgQuery}&search=${searchTerm}`)
                }
              }}
              onKeyUp={(e)=>{
                if (searchTerm === '' && e.key !=='Shift'){
                  setUpdateTrigger(true)
                  setShowSearchTerm('')
                  navigate(`/share?org=${orgQuery}`)
                }
              }}
              InputProps={{
                // startAdornment: (
                // ),
                endAdornment: (
                  <>
                  {searchTerm && 
                  <InputAdornment position="end" 
                    onClick={()=>{
                      setSearchTerm('')
                      setShowSearchTerm('')
                      setUpdateTrigger(true)
                      navigate(`/share?org=${orgQuery}`)
                    }}
                    sx={{cursor: 'pointer'}}
                  >
                    <CancelIcon />
                  </InputAdornment>
                  }
                  <InputAdornment position="end"
                      sx={{cursor: 'pointer'}}
                      onClick={()=>{
                        if (searchTerm !== ''){
                          setUpdateTrigger(true)
                          setShowSearchTerm(searchTerm)
                          navigate(`/share?org=${orgQuery}&search=${searchTerm}`)
                        }
                      }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                  </>
                ),
              }}
            />

              <Typography variant='caption' width='100%' fontWeight='bold'>
                Genre
              </Typography>

              {filterList.filter(value => genreList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      if (isPlaying){
                        setControlMessage('play-pause')
                        setIsPlaying(!isPlaying)
                        setSelectedIndex(null)
                      }

                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }

              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Instrument
              </Typography>

              {filterList.filter(value => instrumentList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      if (isPlaying){
                        setControlMessage('play-pause')
                        setIsPlaying(!isPlaying)
                        setSelectedIndex(null)
                      }

                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Mood
              </Typography>

              {filterList.filter(value => moodList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      if (isPlaying){
                        setControlMessage('play-pause')
                        setIsPlaying(!isPlaying)
                        setSelectedIndex(null)
                      }

                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
            </Box>
          </Grid>

        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        // audioBarFavorite={audioBarFavorite}
        // setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        // refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast}/>

    </>
  );
}
