import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDate2(date, newFormat) {

  // Parse the date string into a Date object
  const dateRaw = new Date(date);

  // Get the day, month, and year from the Date object
  let day = dateRaw.getUTCDate();
  let month = dateRaw.getUTCMonth() + 1; // Note: Months are zero-based, so add 1
  const year = dateRaw.getUTCFullYear();

  // Pad the day and month with leading zeros if needed
  if (day < 10) {
    day = `0${day}`;
  }
  if (month < 10) {
    month = `0${month}`;
  }

  // Create the formatted date string in dd/mm/year format
  const formattedDate = `${month}/${day}/${year}`;

  return date ? formattedDate : '';
}

export function fDateTime(date, newFormat) {
  const fm = newFormat || 'dd MMM yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        // addPrefix: false,
        addSuffix: true,
      })
    : '';
}
