import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

// @mui
import {
  Button,
  Stack,
  Drawer,
  Divider,
  Tooltip,
  Typography,
  Grid,
  Link,
  MenuItem,
} from '@mui/material';

// utils
import axios from '../utils/axios';
import { fToNow } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';

// components
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import Iconify from '../components-default/iconify';
import MenuPopover from '../components-default/menu-popover/MenuPopover';

// ----------------------------------------------------------------------

UserInfoDrawer.propTypes = {
  events: PropTypes.array,
  picker: PropTypes.object,
  openUserDrawer: PropTypes.bool,
  setOpenUserDrawer: PropTypes.func,
  userDrawerInfo: PropTypes.object,
  setUserDrawerInfo: PropTypes.func,
  onCloseFilter: PropTypes.func,
  onResetFilter: PropTypes.func,
  onSelectEvent: PropTypes.func,
  onFilterEventColor: PropTypes.func,
  colorOptions: PropTypes.arrayOf(PropTypes.string),
  filterEventColor: PropTypes.arrayOf(PropTypes.string),
  setSearchTerm: PropTypes.func,
  setShowSearchTerm: PropTypes.func,
  setUpdateTrigger: PropTypes.func,
  adminUsersPage: PropTypes.bool,
  file: PropTypes.object,
  setLoadedSong: PropTypes.func,
};

export default function UserInfoDrawer({
  events,
  picker,
  openUserDrawer,
  setOpenUserDrawer,
  userDrawerInfo,
  setUserDrawerInfo,
  colorOptions,
  onCloseFilter,
  onResetFilter,
  onSelectEvent,
  filterEventColor,
  onFilterEventColor,
  setSearchTerm,
  setShowSearchTerm,
  setUpdateTrigger,
  adminUsersPage,
  file,
  setLoadedSong,
}) {

  const [userStats, setUserStats] = useState({})
  const [pitchList, setPitchList] = useState([])
  const [lastSale, setLastSale] = useState('')

  const [openPopover, setOpenPopover] = useState(null)


  const theme = useTheme();
  const navigate = useNavigate();
  const { copy } = useCopyToClipboard();

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };  

  const handleClosePopover = () => {
    setOpenPopover(null);
  };


  async function getUserStats(){
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/getuserdrawerinfo`, {
      userId: userDrawerInfo?._id,
    });
  
    setUserStats(response.data);

    const sortedPitches = response.data?.info?.pitches?.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))

    const orderedList = sortedPitches.filter(item => item.status !== 'sold' && item.status !== 'pass')
    sortedPitches.map(item => { // eslint-disable-line
      if (item.status === 'pass'){
        return orderedList.push(item)
      }
    })
    
    sortedPitches.map(item => { // eslint-disable-line
      if (item.status === 'sold'){
        return orderedList.push(item)
      }
    })

    setPitchList(orderedList)

    const sortedTransactions = userDrawerInfo?.transactions?.sort((a, b) => new Date (b.createdAt) - new Date(a.createdAt))
    
    if (sortedTransactions !== undefined){
      setLastSale(sortedTransactions[0]?.createdAt)
    }

  }

  function countCurrentPitches(allPitches){
    const activePitches = []

    if (allPitches && allPitches.length > 0){

      allPitches.map((item, index) => { // eslint-disable-line
        if (item.status === 'pitched'){
          return activePitches.push(item)
        }
      })
    }
    return activePitches.length
  }
  function countSubmissions(allPitches){
    const submissions = []

    if (allPitches && allPitches.length > 0){

      allPitches.map((item, index) => { // eslint-disable-line
        if (item.status === 'submitted'){
          return submissions.push(item)
        }
      })
    }
    return submissions.length
  }

  function getUserEarnings(trans){

    let total = 0

    if (trans && trans.length > 0){

      trans.map((item, index) => { // eslint-disable-line
        if (item?.status === 'credit' && item?.amount){
          // console.log('Adding transaction')
          total += item.amount
        }
      })
    }
    // console.log('TOTAL: ', total)
    return `$${fCurrencyCents(total)}`
  }

  useEffect(()=>{
    getUserStats()
  }, [userDrawerInfo]) // eslint-disable-line


  return (
    <Drawer
      anchor="right"
      open={openUserDrawer}
      onClose={onCloseFilter}
      variant='persistent'
      sx={{
        // zIndex: '100 !important',
      }}
      BackdropProps={{
        invisible: true,
        
      }}
      PaperProps={{
        sx: { 
          // mt: '100px',
          width: 420,
          // height: '100% - 100px',

        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, pr: 1, py: 2 }}
      >
        <Iconify 
          icon='iconamoon:close-bold' 
          onClick={()=>{setOpenUserDrawer(false)}}
          sx={{cursor: 'pointer'}}
        />
        <Typography variant="subtitle1">User Info</Typography>

      </Stack>

      <Divider />
      <Stack direction='row' justifyContent='space-between' alignItems='center' paddingLeft={2}>
        <Typography
          variant="subtitle2"
          sx={{
            color: userDrawerInfo?.noted ? 'success.main' : 'text.secondary',
            fontWeight: 'fontWeightMedium',
            cursor: 'pointer',
          }}
          onClick={()=>{
            navigate(`/transactions/${userDrawerInfo?._id}`)
          }}
        >
          {userDrawerInfo?.name}
        </Typography>
        <Stack direction='row' gap={1} justifyContent='flex-end' alignItems='center' paddingRight={2}>
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
              fontWeight: 'fontWeightMedium',
              // p: theme.spacing(2, 2, 1, 2),
              width: 'fit-content',
              display: 'flex',
              justifyContent: 'flex-end',
              px: 0,
            }}
          >
            {userDrawerInfo?.email}
          </Typography>
          <Button variant='text' sx={{minWidth: 0}}
            onClick={
              (e)=>{handleOpenPopover(e)
              // copy(userDrawerInfo?.email);
            }}>
            <Iconify icon='solar:copy-line-duotone' />
          </Button>
        </Stack>

      </Stack>
      <Stack direction='row' justifyContent='space-between'>
        <Stack spacing={1}
          sx={{
            p: theme.spacing(2, 2, 1, 2),
          }}
        >
          <Stack direction='row' 
            justifyContent='flex-start' width='100%'
          >
            <Typography
              variant="subtitle2"
              sx={{
                width: '120px',
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
              }}
            >
              Current Pitches:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                textAlign: 'right',
                width: '30px',
              }}
            >
              {countCurrentPitches(userStats?.info?.pitches)}
            </Typography>
          </Stack>
          <Stack direction='row' 
            justifyContent='flex-start' width='100%'
          >
            <Typography
              variant="subtitle2"
              sx={{
                width: '120px',
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
              }}
            >
              Submissions:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                textAlign: 'right',
                width: '30px',

              }}
            >
              {countSubmissions(userStats?.info?.pitches)}
            </Typography>
          </Stack>
        </Stack>
        <Stack >
          <Stack direction='row' spacing={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                p: theme.spacing(2, 2, 1, 2),
              }}
            >
              Earnings:
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'success.main',
                fontWeight: 'fontWeightMedium',
                p: theme.spacing(2, 2, 1, 2),
              }}
            >
              {getUserEarnings(userDrawerInfo?.transactions)}
            </Typography>
          </Stack>  
          <Stack direction='row' spacing={1}>
            <Typography
              variant="subtitle2"
              sx={{
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                p: theme.spacing(2, 2, 1, 2),
              }}
            >
              Last Sale: 
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                // color: 'success.main',
                fontWeight: 'fontWeightMedium',
                p: theme.spacing(2, 2, 1, 2),
              }}
            >
              {fToNow(lastSale)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>

      <Divider sx={{mb: 1}}/>
      <Grid container >

      {pitchList.map((item, index) => { // eslint-disable-line
      return <Grid item xs={5} key={`${item?.briefTitle}-${index}`}
        sx={{
          borderRadius: 2,
          border: item === file ? `${theme.palette.primary.main} solid 1px` : `${theme.palette.divider} solid 1px`,
          backgroundColor: theme.palette.background.paper,
          m: 1,
          p: 1,
          cursor: 'default',
          transition: 'all .2s ease-in-out',
        }}
      >
        <Stack spacing={0} sx={{px: 1}}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography
              variant="subtitle2"
              sx={{
                // p: 2,
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                minWidth: '30px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {item?.briefCategory}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                // pt: 1,
                px: 1,
                color: item?.status === 'pitched' ? theme.palette.warning.main : (item?.status === 'sold' ? theme.palette.success.main : (item?.status === 'submitted') ? theme.palette.info.main : theme.palette.error.main), // eslint-disable-line
                backgroundColor:  item?.passQueue === true ? `${theme.palette.error.light}80` : 'inherit',
                borderRadius: 2,
                width: 'fit-content',
                border: item?.passQueue ? `${theme.palette.error.dark} solid 1px` : `${theme.palette.info.light} solid 1px`,
                fontWeight: 'fontWeightMedium',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                maxWidth: '66px',
                minWidth: 'fit-content',
              }}
          >
            {item?.status}
          </Typography>

          </Stack>
          <Link href={`/briefs/${item?.briefId}`} target='_blank'>
            <Typography
              variant="caption"
              sx={{
                // p: 2,
                color: 'text.secondary',
                fontWeight: 'fontWeightLight',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  cursor: 'pointer',
                  color: theme.palette.primary.main,
                  textDecoration: 'none',
                }
              }}
            >
              {item?.briefTitle}
            </Typography>

          </Link>
          <Stack direction='column' justifyContent='flex-start' >
            <Tooltip title={item?.songTitle}>
            <Typography
              variant="caption"
              sx={{
                pt: 1,
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                '&:hover':{
                  color: theme.palette.primary.main,
                  cursor: 'pointer',
                }
              }}
              onClick={()=>{
                if (adminUsersPage) {
                  setLoadedSong(item)
                  // navigate(`/inventory?search=${item?.songTitle}`)
                } else {
                  setSearchTerm(item?.songTitle)
                  setShowSearchTerm(item?.songTitle)
                  setUpdateTrigger(true)
                }
              }}
            >
              {item?.songTitle}
            </Typography>
            </Tooltip>
            <Typography
              variant="caption"
              sx={{
                pt: 1,
                color: 'text.secondary',
                fontWeight: 'fontWeightMedium',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {fToNow(item?.updated_at)}
            </Typography>

          </Stack>

        </Stack>
      </Grid>
      }
      )}
      </Grid>


      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={()=>{
            copy(userDrawerInfo?._id)
          }}
          sx={{
            "&:hover":{
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Copy ID
        </MenuItem>
        <MenuItem 
          onClick={()=>{
            copy(userDrawerInfo?.email)
          }}
          sx={{
            "&:hover":{
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Copy Email
        </MenuItem>
      </MenuPopover>

    </Drawer>
  );
}
