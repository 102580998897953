import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';

// @mui
import {
  Box,
  Stack,
  Divider,
  Checkbox,
  MenuItem,
  Typography,
  Grid,
  Button,
  Chip,
  TextField,
  Link,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from '@mui/material';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
// import EditIcon from '@mui/icons-material/Edit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CheckIcon from '@mui/icons-material/Check';

import { useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { ReactComponent as LogoIcon } from '../../../../assets/icons/pikes.svg'
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import { TOGGLE_DOC_FAVORITE, GET_USER_BY_ID_M, PITCH_APPROVE, ADD_DOC_TO_CART } from '../../../../graphql/mutations'

// components
import { fPillStatus } from '../../../../utils/formatPitchPill';
import Iconify from '../../../../components-default/iconify';
import MenuPopover from '../../../../components-default/menu-popover';
import TextfieldDisplay from '../../../../components-custom/TextfieldDisplay';
import { plusSideList } from '../../../../components-custom/plusSideList';
import Markdown from '../../../../components-default/markdown/Markdown';
import isAA from '../../../../components-custom/isAA';
import Image from '../../../../components-default/image';
import LoadingScreen from '../../../../components-custom/loading-screen/LoadingScreen';

//

// ----------------------------------------------------------------------

AudioFileCardAdminTB.propTypes = {
  sx: PropTypes.object,
  userId: PropTypes.string,
  user: PropTypes.object,
  file: PropTypes.object,
  data: PropTypes.object,
  filter: PropTypes.array,
  refetch: PropTypes.func,
  updateInventory: PropTypes.func,
  dispatch: PropTypes.func,
  addToCart: PropTypes.func,
  onDelete: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setControlMessage: PropTypes.func,
  audioBarFavorite: PropTypes.bool,
  setAudioBarFavorite: PropTypes.func,
  favorites: PropTypes.bool,
  passable: PropTypes.bool,
  editable: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  setSelectedId: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
  briefId: PropTypes.string,
  toast: PropTypes.object,
  setToast: PropTypes.func,
  openUserDrawer: PropTypes.bool,
  setOpenUserDrawer: PropTypes.func,
  userDrawerInfo: PropTypes.object,
  setUserDrawerInfo: PropTypes.func,
  inventoryPage: PropTypes.bool,
  orderPage: PropTypes.bool,
  dimPreppedFiles: PropTypes.bool,
  invoiceId: PropTypes.string,
  preppedArray: PropTypes.array,
  setPreppedArray: PropTypes.func,
  hideList: PropTypes.array,
  setHideList: PropTypes.func,
  setOriginalBriefsDialogOpen: PropTypes.func,
  setChangePitchDialogOpen: PropTypes.func,
  transferDoc: PropTypes.object,
  setTransferDoc: PropTypes.func,
  getCartCount: PropTypes.func,
  cartRefetch: PropTypes.func,
};


export default function AudioFileCardAdminTB({ userId, user, file, data, filter, refetch, updateInventory, dispatch, addToCart, onDelete, sx, favorites, passable, editable, briefId, loadedSong, setLoadedSong, isPlaying, setIsPlaying, setControlMessage, audioBarFavorite, setAudioBarFavorite, index, setSelectedIndex, selectedIndex, selected, setSelectedId, toast, setToast, inventoryPage, orderPage, dimPreppedFiles, preppedArray, setPreppedArray, invoiceId, openUserDrawer, setOpenUserDrawer, hideList, setHideList, setOriginalBriefsDialogOpen, setChangePitchDialogOpen, transferDoc, setTransferDoc, userDrawerInfo, setUserDrawerInfo, cartRefetch, getCartCount, ...other }) {

  // console.log('FILE: ', file)
  const isDesktop = useResponsive('up', 'sm');
  const navigate = useNavigate();

  const [openPopover, setOpenPopover] = useState(null);

  // const [passWarning, setPassWarning] = useState(false);

  const [favorited, setFavorited] = useState(Boolean(file?.favorited?.includes(user?.organization)));
  const [inCart, setInCart] = useState(file.inCart?._id !== '' && file.inCart?._id === user?.organization);

  const [prepped, setPrepped] = useState(Boolean(file?.prepped));
  const [multitrackUrl, setMultitrackurl] = useState(file?.multitrackUrl);
  const [playButton, setPlayButton] = useState('play');
  const [pitchIndex, setPitchIndex] = useState(0);

  const [aaDoc, setAADoc] = useState(null);

  const [openShare, setOpenShare] = useState(false); // eslint-disable-line
  const [passRefetch, setPassRefetch] = useState(false); // eslint-disable-line

  const [pitchStatus, setPitchStatus] = useState('')

  const [pitchFlag, setPitchFlag] = useState(false)
  const [passFlag, setPassFlag] = useState(false)
  const [reviseFlag, setReviseFlag] = useState(false)

  const [openDetails, setOpenDetails] = useState(false); // eslint-disable-line
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('');
  const [feedbackPrint, setFeedbackPrint] = useState('');
  const [feedbackAuthor, setFeedbackAuthor] = useState('');
  const [submitterInfo, setSubmitterInfo] = useState({ name: '' });
  const [showLyrics, setShowLyrics] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [enableBuyAlert, setEnableBuyAlert] = useState(false);
  const [fixMultisMessage, setFixMultisMessage] = useState({
    open: false,
    message: '',
  });

  const theme = useTheme();

  const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);
  // const [pitchPass] = useMutation(PITCH_PASS);
  const [pitchApprove] = useMutation(PITCH_APPROVE);
  const [getUserByIdM] = useMutation(GET_USER_BY_ID_M);
  const [addDocToCart] = useMutation(ADD_DOC_TO_CART);


  let lastPitch = 0
  let updatedDate = null

  const pillStatusColor = aaDoc?.pitches[aaDoc?.pitches.length === 0 ? 0 : aaDoc.pitches.length - 1].status === 'pitched' ? theme.palette.warning.main : (aaDoc?.pitches[aaDoc?.pitches.length === 0 ? 0 : aaDoc.pitches.length - 1].status === 'sold' ? theme.palette.success.main : theme.palette.error.main) // eslint-disable-line

  if (file.pitches) {
    lastPitch = file.pitches.length <= 1 ? 0 : file.pitches.length - 1;
    updatedDate = new Date(file?.pitches[lastPitch]?.updated_at)
  }

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleFavorite = async () => {
    setAudioBarFavorite(!audioBarFavorite);
    await toggleFavorite({ variables: { docId: file._id, userId: user?.organization } });
    setFavorited(!favorited)

    if (refetch) {
      refetch()
    }
  };

  const handleApprove = async () => {
    // await toggleDocPass({ variables: { docId: file._id, userId} });
    await pitchApprove({ variables: { docId: file._id, briefId: file.pitches[pitchIndex]?.briefId, userId } });
    // setPassRefetch(true)
    setLoadedSong({ album: '', artist: { name: '', artwork: '', bio: '' }, title: '', favorited: [], tags: [] });
    // setPassWarning(false);
    setPitchFlag(true);
    // setTimeout(()=>{refetch()}, 500)
    // refetch();
    setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `You approved ${file.title}`, duration: 4000 })
  };

  const handleAddDocToCart = async () => {
    await addDocToCart({
      variables: {
        docId: file._id,
        orgId: user?.organization
      }
    });
    if (setInCart) {
      setInCart(!inCart)
    }

    if (cartRefetch) {
      cartRefetch()
    }
  };


  async function getUserInfo() {

    const submitterId = file.userId
    // console.log('USER ID: ', submitterId)

    const submitter = await getUserByIdM({ variables: { userId: submitterId } });
    setSubmitterInfo(submitter?.data?.getUserByIdM)
    // console.log('USER INFO: ', submitter?.data?.getUserByIdM)
  }

  async function queuePass() {
    // console.log('Feedback')
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/addtopassqueue`, {  // eslint-disable-line
        docId: file?._id,
        userId: file?.userId,
        userName: file?.writer,
        briefId: briefId ? briefId : file?.pitches[pitchIndex]?.briefId, // eslint-disable-line
        briefTitle: file?.pitches[pitchIndex]?.briefTitle, // eslint-disable-line
        message: feedback,
        sender: {
          _id: userId,
          name: user.name,
          avatarUrl: user?.avatar
        },
        sendStatus: 'ready',
      }).then(response => {
        console.log(response.data)
        if (feedbackType === 'pass') {
          setPassFlag(true)
        }
        if (feedbackType === 'revise') {
          setReviseFlag(true)
        }
        setTimeout(() => {
          setFeedback('')
          // refetch();
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${file.title} queued for passing`, duration: 4000 })
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }

  }



  async function sendFeedback() {
    // console.log('Feedback')
    const randomNumber = Math.floor(Math.random() * plusSideList.length) + 1;
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/addfeedback`, {  // eslint-disable-line
        docId: file._id,
        briefId: briefId ? briefId : file?.pitches[pitchIndex]?.briefId, // eslint-disable-line
        briefTitle: file?.pitches[pitchIndex]?.briefTitle, // eslint-disable-line
        message: feedback,
        feedbackType,
        plusSide: plusSideList[randomNumber],
        sender: {
          _id: userId,
          name: user.name,
          avatarUrl: user?.avatar
        }
      }).then(response => {
        console.log(response.data)
        if (feedbackType === 'pass') {
          setPassFlag(true)
        }
        if (feedbackType === 'revise') {
          setReviseFlag(true)
        }
        setTimeout(() => {
          setFeedback('')
          // refetch();
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Feedback sent - ${file.title}`, duration: 4000 })
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }

  }

  async function buySongTB() {
    // console.log('Feedback')
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/buytrack`, {  // eslint-disable-line
        docId: file._id,
        briefId: briefId || file?.pitches[pitchIndex]?.briefId,
        songTitle: file?.title
      }).then(response => {
        console.log(response.data)
        setTimeout(() => {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Song bought - ${file.title}`, duration: 4000 })
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }

  async function checkAA(fileId) {
    // console.log('Feedback')
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/checkAAdoc`, {  // eslint-disable-line
        docId: fileId,
        // originalFileName: file.originalFileName,
        audioUrl: file.audioUrl,
      }).then(response => {
        console.log('RESPONSE: ', response)
        if (response?.data?.message === 'No doc found') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.error.dark, message: response?.data?.message, duration: 3000 })
        }
        if (response?.data?.message === 'Doc updated') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: response?.data?.message, duration: 3000 })

        }
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }

  async function getAAPitch() {
    // console.log('Feedback')
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getTBorderinfo`, {  // eslint-disable-line
        docId: file?._id,
      }).then(response => {
        if (response?.data?.aaDoc) {
          setAADoc(response?.data?.aaDoc)
          // console.log('AA DOC: ', response?.data?.aaDoc)
          // console.log('AA PITCH: ', response?.data?.aaDoc.pitches[0]?.status)

        }
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }


  async function toggleInvoiceItemPrepped() {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/markinvoiceitem`, {  // eslint-disable-line
        type: 'prepped',
        docId: file._id,
        invoiceId
      }).then(response => {
        // console.log(response)
        if (response.status === 200) {
          setPrepped(!prepped)
        }
      });
    } catch (err) {
      if (err.response.status) {
        console.log('aint work')
      } else {
        console.log(err.response.data)
      }
    }
  }


  function checkNum(lastIndexx) {
    if (lastIndexx === 0) {
      return 0
    }
    if (lastIndexx > 0) {
      return lastIndexx - 1
    }
    return null
  }

  async function requestMultis() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/requestmultis`, {  // eslint-disable-line
      // email: user.email,
      userId: file?.userId,
      // name: user?.name,
      songTitle: file?.title,
      artist: file?.artist?.name,
      docId: file?._id
    }).then(
      async (response) => {
        if (response?.data?.status === 'ok') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: response?.data?.message, duration: 3000 })
        } else {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.error.dark, message: response?.data?.message, duration: 3000 })
        }
        console.log('RESPONSE: ', response)
      });
  }

  async function expiredMultisRequest() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/expiredmultis`, {  // eslint-disable-line
      userId: file?.userId,
      songTitle: file?.title,
      artist: file?.artist?.name,
      docId: file?._id
    }).then(
      async (response) => {
        if (response?.data?.status === 'ok') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: response?.data?.message, duration: 3000 })
        } else {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.error.dark, message: response?.data?.message, duration: 3000 })
        }
        console.log('RESPONSE: ', response)
      });
  }

  async function sendBuyAlert() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/sendmanualbuyalert`, {  // eslint-disable-line
      // email: 'support@trackbase.cc',
      docId: file?._id,
      // name: user?.name,
      songTitle: file?.title,
      artist: file?.artist?.name,
      rate: file?.price,
      payoutType: user?.payoutType || '',
    }).then(
      async (response) => {
        if (response?.data?.status === 'ok') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: response?.data?.message, duration: 3000 })
        } else {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.error.dark, message: response?.data?.message, duration: 3000 })
        }
        console.log('RESPONSE: ', response)
      });
  }

  async function fixMultisRequest() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/fixmultis`, {  // eslint-disable-line
      userId: file?.userId,
      songTitle: file?.title,
      artist: file?.artist?.name,
      docId: file?._id,
      message: fixMultisMessage.message,
    }).then(
      async (response) => {
        if (response?.data?.status === 'ok') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: response?.data?.message, duration: 3000 })
        } else {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.error.dark, message: response?.data?.message, duration: 3000 })
        }
        console.log('RESPONSE: ', response)
        setFixMultisMessage({ open: false, message: '' })
      });
  }


  async function checkForMultis() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
      docId: file?._id,
      type: 'inventory'
    }).then(
      async (response) => {
        if (response?.data.docs[0].multitrackUrl !== '') {
          setMultitrackurl(response?.data.docs[0].multitrackUrl)
        }
      });
  }

  async function handleDownloadSave() {
    try {
      // Fetch the MP3 file from the URL
      const response = await fetch(file?.audioUrl);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Failed to fetch the MP3 file.');
      }

      // Get the response data as a Blob
      const mp3Blob = await response.blob();

      // Use file-saver to trigger the download with a specified filename
      saveAs(mp3Blob, `${file?.title} ROUGHMIX.mp3`);
    } catch (error) {
      console.error('Error downloading the MP3:', error);
    }
  }


  useEffect(() => {
    if (file.prepped) {
      const newPreppedArray = preppedArray
      newPreppedArray.push(file?._id)
      setPreppedArray(newPreppedArray)
    }
    // console.log('USER: ', user)
  }, []) // eslint-disable-line


  useEffect(() => {
    if (passRefetch === true) {
      setTimeout(() => { refetch() }, 1500)
    }
  }, [passRefetch]) // eslint-disable-line


  useEffect(() => {
    setFavorited(Boolean(file?.favorited?.includes(user?.organization)));
  }, [data]) // eslint-disable-line


  useEffect(() => {
    if (file.pitches) {
      file.pitches.map((pitch, indexx) => { // eslint-disable-line
        // console.log('PITCH: ', pitch)
        // console.log('BRIEF ID: ', pitch.briefId)
        if (pitch.briefId === briefId || inventoryPage) {
          // console.log('INDEX IS: ', indexx)
          return setPitchIndex(indexx)
        }
      })
    }
    if (file?.multitrackUrl === '') {
      checkForMultis()
    }
    getUserInfo();

    if (!isAA()) {
      getAAPitch()
    }
  }, [file]) // eslint-disable-line

  useEffect(() => {
    // if (pitchIndex !== null && aaDoc) {
    //   const lastIndex = file?.pitches[pitchIndex]
    //   // console.log('LAST INDEX: ', lastIndex)
    //   // console.log('FB PRINT: ', lastIndex?.feedback[checkNum(lastIndex.feedback.length)])
    //   const theFeedback = lastIndex?.feedback[checkNum(lastIndex.feedback.length)]
    //   if (theFeedback !== undefined) {
    //     setFeedbackPrint(theFeedback.message)
    //     setFeedbackAuthor(theFeedback.sender?.name)
    //   }
    // }
    // if (file.pitches) {
    //   setPitchStatus(fPillStatus(file?.pitches[pitchIndex]?.status))
    // }

  }, [pitchIndex]) // eslint-disable-line

  useEffect(() => {
    setPlayButton(isPlaying && file.audioUrl === loadedSong.audioUrl ? 'pause' : 'play');
  }, [isPlaying, loadedSong, favorited]) // eslint-disable-line  

  useEffect(() => {
    if (selectedIndex === index) {
      setLoadedSong(file);
      // setIsPlaying(true);
      // setSelectedId(file._id)
    }
  }, [selectedIndex, isPlaying]) // eslint-disable-line

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        position: 'relative',
        transition: 'opacity 0.5s ease-in-out, all 0.2s ease-in-out',
        opacity: (passRefetch || (prepped && dimPreppedFiles)) ? .3 : 1,
        ml: selectedIndex === index ? 1 : 0,
        mr: selectedIndex === index ? -1 : 0,
        // minHeight: (file?.pitches && file?.pitches[pitchIndex]?.passQueue) ? `85px` : '96px',
        // height: (file?.pitches && file?.pitches[pitchIndex]?.passQueue) ? `85px` : `inherit`,
        overflow: 'hidden',
        // backgroundColor: (file?.pitches && file?.pitches[pitchIndex]?.passQueue) ? `${theme.palette.error.main}80` : (file?.inCart?._id === user?.organization ? `${theme.palette.success.light}40` : `${theme.palette.background.paper}0`), // eslint-disable-line
        boxShadow: selectedIndex === index ? `-8px 8px 2px rgba(0, 0, 0, 0.1)` : theme.customShadows.z8,
        // border: selectedIndex === index ? `${!file?.pitches[pitchIndex]?.passQueue ? theme.palette.primary.main : theme.palette.error.main} solid 2px` : `solid 1px ${(file?.pitches && file?.pitches[pitchIndex]?.passQueue) ? theme.palette.error.main : theme.palette.info.lighter}40`, // eslint-disable-line
        '&:hover': {
          boxShadow: selectedIndex === index ? '-8px 8px 2px rgba(0, 0, 0, 0.1)' : theme.customShadows.z8,
        },
        ...(isDesktop && {
          // p: 1.5,
          borderRadius: 1.5,
        }),
        ...sx,
      }}
    >
      <Box
        // MARKER FOR ADMIN PITCH APPROVAL/PASS
        sx={{
          position: 'absolute',
          height: '100%',
          width: '24px',
          right: 0,
          zIndex: 0,
          backgroundColor: passFlag ? theme.palette.error.main : (reviseFlag ? theme.palette.warning.main : theme.palette.success.main), // eslint-disable-line
          borderRadius: '0px 8px 8px 0px',
          display: (passFlag || reviseFlag || pitchFlag) ? 'flex' : 'none',
        }}
      />
      <Box // order page prepped indicator
        sx={{
          display: orderPage ? 'flex' : 'none',
          position: 'absolute',
          top: 5,
          right: 5,
          zIndex: 5,
        }}
      >
        <Stack direction='row' gap={0} alignItems='center'>
          <Typography variant='caption'>Delivery Prep </Typography>
          <Checkbox color={prepped ? 'success' : 'error'} checked={prepped}
            onChange={() => {
              toggleInvoiceItemPrepped()
            }
            }
            sx={{
              color: theme.palette.error.main,
            }}
          />
        </Stack>
      </Box>
      <Stack
        spacing={isDesktop ? 1.5 : 2}
        direction={isDesktop ? 'row' : 'column'}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        sx={{
          zIndex: 2,
          p: 2.5,
          borderRadius: 2,
          position: 'relative',
          marginBottom: '0px !important',
          // border: 'red solid 1px',
          width: '100%',
          ...(isDesktop && {
            marginBottom: '0px!important',
            p: 1.5,
            pb: 0,
            borderRadius: 1.5,
          }),
          ...sx,
        }}
        {...other}
      >
        <Grid container
        // MAIN CARD BOX
        >

          <Grid item xs={12} sm={8} md={7} lg={6}
            order={{
              xs: 1,
              sm: 1,
            }}
            sx={{
              // PLAY BUTTON / ARTIST INFO
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              pr: 3,
              // border: 'green solid 1px',
            }}
          >
            <Button
              variant="text"
              color={pitchStatus?.status !== 'Pass' ? "primary" : 'error'}
              onClick={() => {
                setSelectedIndex(index)
                // setSelectedId(file._id)
                if (loadedSong !== file) {
                  setLoadedSong(file);
                  setIsPlaying(true);
                } else {
                  setControlMessage('play-pause')
                  setIsPlaying(true)
                }

              }}
              sx={{
                flexGrow: 1,
                alignItems: 'flex-start',
                justifyContent: 'center',
                borderRadius: '25%',
                p: 0,
                m: 0,
              }}
            >
              {playButton === 'pause' ?
                <PauseCircleIcon sx={{
                  width: '52px',
                  height: '52px',
                  m: 0,
                  opacity: .8,
                }} /> :
                <PlayCircleFilledRoundedIcon sx={{
                  width: '52px',
                  height: '52px',
                  opacity: .8,
                  m: 0,
                }} />
              }
            </Button>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              width: '100%',
            }}>
              <Box
                // song/artist name box
                sx={{
                  width: '100%',
                  maxWidth: {
                    xs: '95%',
                    sm: '265px',
                    md: '265px',
                  },
                  pr: 3,
                  display: 'flex',
                  flexDirection: 'column',
                  // opacity: passWarning ? 0.2 : 1,
                  marginBottom: '0px !important',
                  justifyContent: 'center',
                }}
              >
                <Link
                  onClick={() => {
                    navigate(`/editsong/${file?._id}`)
                  }}
                  sx={{
                    cursor: 'pointer',
                  }}
                >
                  <Typography noWrap variant="subtitle2"
                    sx={{
                      color: theme.palette.text.primary,
                      textOverflow: 'ellipsis',
                      // cursor: 'default',
                    }}
                  >
                    {file.title}
                  </Typography>
                </Link>
                <Typography variant="caption" noWrap sx={{ cursor: 'default' }}
                >
                  {file.artist.name}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} md={3} lg={3}
            order={{
              xs: 3,
              sm: 3,
            }}
            sx={{
              // ADD TO CART BUTTON SECTION
              width: '100%',
              height: '60px',
              display: (file?.pitches && !file?.pitches[pitchIndex]?.passQueue) ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: {
                sm: 'flex-end',
                md: 'center',
                lg: 'center',
              },
              // pr: 3,
              // border: 'green solid 1px',
            }}
          >
            {file?.pitches?.length > 0 &&
              (((file?.pitches[pitchIndex]?.status === 'submitted' || file?.pitches[pitchIndex]?.status === 'resubmitted') && !file?.pitches[pitchIndex]?.passQueue) ?
                <Button
                  variant={file.inCart?._id === user?.organization ? "outlined" : "contained"}
                  // fullWidth
                  color={file.inCart?._id === user?.organization ? "info" : "primary"}
                  onClick={handleApprove}
                  sx={{
                    // mr: 4,
                    p: 1,
                    width: {
                      xs: '100%',
                      sm: '170px',
                    },
                    border: file?.pitches[pitchIndex]?.status === 'resubmitted' ? `${theme.palette.error.light} solid 2px` : 'inherite',
                    display: 'inline-flex',
                    '&:hover': {
                      // backgroundColor: file.inCart?._id === user?.organization ? theme.palette.error.main : theme.palette.text.primary, 
                      // color: theme.palette.common.white,
                    },
                  }}
                >
                  <Typography variant="subtitle2" noWrap>
                    {file?.pitches[pitchIndex]?.status === 'resubmitted' ?
                      'Approve Re-Pitch' :
                      'Approve Pitch'}
                  </Typography>
                </Button> :
                <Button
                  variant="contained"
                  // fullWidth
                  color={file.pitches[pitchIndex]?.status === 'pitched' ? 'success' : 'error'}
                  onClick={() => {
                    console.log('restore')
                  }}
                  disabled
                  sx={{
                    // mr: 4,
                    p: 1,
                    width: {
                      xs: '100%',
                      sm: '170px',
                    },
                    display: 'inline-flex',
                    '&:hover': {
                      backgroundColor: theme.palette.text.primary,
                      color: theme.palette.common.white,
                    },
                    '&:disabled': {
                      backgroundColor: pitchStatus.color,
                      color: theme.palette.common.white,
                    }
                  }}
                >
                  <Typography variant="subtitle2" noWrap>
                    {pitchStatus.status}
                  </Typography>
                </Button>
              )

            }

          </Grid>


          <Grid item xs={12} sm={12} md={2} lg={3}
            order={{
              xs: 2,
              sm: 3,
            }}
            sx={{
              display: pitchStatus.status !== 'pass' ? 'inherit' : 'none',
              // border: 'red solid 1px',
            }}
          >
            <Box
              sx={{
                // LIKE PASS EDIT SECTION
                display: (file?.pitches && !file?.pitches[pitchIndex]?.passQueue) ? 'flex' : 'none',
                justifyContent: {
                  xs: 'flex-end',
                  sm: 'flex-end',
                  md: 'flex-end',
                },
                alignItems: 'center',
                height: '100%',
                width: '100%',
                mt: {
                  // xs: 2,
                  sm: 'inherit',
                },
                pr: {
                  xs: 0,
                  sm: 1,
                },
                // ml: 2,
                // border: 'pink solid 1px',

              }}
            >
              <Box sx={{ display: 'flex' }}>
                {favorites &&
                  <Checkbox
                    color="error"
                    icon={<Iconify icon="eva:heart-outline" />}
                    checkedIcon={<Iconify icon="eva:heart-fill" />}
                    checked={favorited}
                    onChange={handleFavorite}
                    sx={{
                      p: 0.75,
                      display: 'inline-flex',

                    }}
                  />
                }
                {!isAA() &&
                  <Tooltip
                    title='Pitch to AA'
                  >
                    <Button
                      size='small'
                      sx={{
                        width: 'fit-content',
                        px: 0,
                      }}
                      variant='text'
                      onClick={() => {
                        setTransferDoc(file)
                        setOriginalBriefsDialogOpen(true)
                      }}
                    >
                      <Iconify icon='game-icons:thrown-daggers'
                        sx={{
                          transform: 'rotate(-90deg)'
                        }}
                      />
                    </Button>
                  </Tooltip>
                }
                {!isAA() &&
                  <Tooltip
                    title='Connect AA Pitch'
                  >
                    <Button
                      size='small'
                      variant='text'
                      sx={{
                        width: 'fit-content',
                        px: 0,
                      }}
                      onClick={() => {
                        checkAA(file._id)
                      }}
                    >
                      <Iconify icon='iconamoon:arrow-top-right-5-circle'
                        sx={{
                          // transform: 'rotate(-90deg)'
                        }}
                      />
                    </Button>
                  </Tooltip>
                }
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12}
            order={{
              xs: 5,
              sm: 5,
            }}
          >
            <Box
              sx={{
                pl: 2,
                py: 0,
                mb: 1,
                display: 'flex',
                // display: 'none',
                alignItems: 'center',
                flexWrap: 'wrap',
                ...(isDesktop && {
                  position: 'unset',
                }),
                overflow: 'hidden',
              }}
            >
              {(inventoryPage && file.pitches) &&
                <Stack direction='row' gap={0}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    // border: `${theme.palette.text.disabled} solid 1px`,
                    borderRadius: 5,
                    height: '20px',
                    // mb: 1,
                    // mr: 1,
                  }}
                >
                  <Link
                    href={`/briefs/client/${file?.pitches[pitchIndex]?.briefId}`}
                  >
                    <Typography variant="subtitle2" noWrap sx={{ color: theme.palette.info.dark, cursor: 'pointer', py: 0, px: 1 }}>
                      Brief: {file?.pitches[pitchIndex]?.briefTitle} <span style={{ color: theme.palette.info.light }}>({file?.pitches[pitchIndex]?.briefCategory})</span>
                    </Typography>
                  </Link>
                  <Iconify icon='ic:baseline-edit' width='16px'
                    sx={{
                      cursor: 'pointer',
                      opacity: 0,
                      transition: 'opacity .2s ease-in-out',
                      '&:hover': {
                        opacity: 1
                      }
                    }}
                    onClick={() => {
                      setChangePitchDialogOpen(true)
                      setTransferDoc(file)
                    }}
                  />
                </Stack>
              }
            </Box>
          </Grid>

          <Grid item xs={12} sm={12}
            order={{
              xs: 5,
              sm: 5,
            }}
          >
            <Box
              sx={{
                pl: {
                  sm: 0,
                  md: 2,
                },
                py: 0,
                my: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                // border: 'blue solid 1px',
                flexWrap: 'wrap',
                ...(isDesktop && {
                  position: 'unset',
                }),
                overflowX: 'hidden',
              }}
            >
              <Stack direction='row'  // status, user row
                sx={{
                  overflowY: 'hidden',
                  zIndex: 3,
                  py: 1,
                }}
              >
                <Stack direction='row' gap={1} // Status pills
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: `${theme.palette.text.disabled} solid 1px`,
                    borderRadius: 5,
                    height: '20px',
                    mr: 1,
                    overflowY: 'hidden',
                    minWidth: 'fit-content',
                  }}
                >
                  <Typography variant="caption" noWrap sx={{ color: theme.palette.info.main, cursor: 'default', py: 0, pl: 1 }}>
                    {file?.bpm} bpm
                  </Typography>
                  <Typography variant="caption" noWrap sx={{ color: theme.palette.info.main, cursor: 'default', py: 0, }}>
                    |
                  </Typography>
                  <Typography variant="caption" noWrap sx={{ color: theme.palette.info.main, cursor: 'default', py: 0, pr: 1 }}>
                    {file?.key} {file?.scale}
                  </Typography>
                </Stack>

                <Stack direction='row' gap={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    borderRadius: 5,
                    height: '20px',
                    mr: 1,
                  }}
                >
                  <Typography variant="subtitle2" noWrap
                    sx={{ color: theme.palette.info.dark, py: 0, px: 1, cursor: 'pointer' }}
                    onClick={() => {
                      setUserDrawerInfo(submitterInfo)
                      if (!openUserDrawer) {
                        setOpenUserDrawer(!openUserDrawer)
                      }
                    }}
                  >
                    {submitterInfo?.name}
                  </Typography>
                  <Typography variant="subtitle2" noWrap
                    sx={{ color: theme.palette.info.main, py: 0, px: 1, cursor: 'pointer' }}
                  >
                    {submitterInfo?.displayName}
                  </Typography>
                </Stack>
                {(file.pitches && file?.pitches[pitchIndex]?.status === 'resubmitted') &&
                  <Stack direction='row' gap={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: `${theme.palette.success.dark} solid 1px`,
                      borderRadius: 5,
                      height: '20px',
                      mr: 1,
                    }}
                  >
                    <Typography variant="caption" noWrap sx={{ color: theme.palette.success.dark, py: 0, px: 1 }}>
                      Resubmitted
                    </Typography>
                  </Stack>
                }

                <Stack direction='row' gap={1}
                  sx={{
                    display: updatedDate ? 'flex' : 'none',
                    alignItems: 'center',
                    border: file?.cleared === 'cleared' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
                    borderRadius: 5,
                    height: '20px',
                    mr: 1,
                  }}
                >
                  <Typography variant="caption" noWrap sx={{ color: file?.cleared === 'cleared' ? theme.palette.success.dark : theme.palette.text.disabled, cursor: 'default', py: 0, px: 1 }}>
                    {updatedDate?.toLocaleDateString('en-us')}
                  </Typography>
                </Stack>
              </Stack>
            </Box>


            <Grid item xs={12} sm={6}
              // MULTITRACKS / DOWNLOAD PILLS
              order={{
                xs: 5,
                sm: 5,
              }}
              sx={{
                pl: {
                  xs: 0,
                  md: 2,
                }
              }}
            >
              <Stack direction='row' spacing={1}
                sx={{
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  py: 1,
                }}
              >
                {multitrackUrl !== '' &&
                  <Stack direction='row' gap={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: multitrackUrl !== '' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
                      borderRadius: 5,
                      height: '20px',
                      mr: 1,
                    }}
                  >
                    <Typography variant="caption" noWrap
                      onClick={(e) => {
                        handleOpenPopover(e)
                        // onClick={()=>{window.open(`${multitrackUrl}`, '_blank')
                      }}
                      sx={{
                        color: multitrackUrl !== '' ? theme.palette.success.dark : theme.palette.text.disabled,
                        cursor: 'pointer',
                        py: 0,
                        px: 1,
                      }}>
                      Multitracks
                    </Typography>
                  </Stack>
                }
                {multitrackUrl === '' &&
                  <Stack direction='row' gap={1}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      border: `${theme.palette.error.main} solid 1px`,
                      borderRadius: 5,
                      height: '20px',
                      mr: 1,
                    }}
                  >
                    <Typography variant="caption" noWrap
                      sx={{ color: theme.palette.error.main, cursor: 'pointer', py: 0, px: 1 }}
                      onClick={() => {
                        requestMultis()
                      }}
                    >
                      Request Multis
                    </Typography>
                  </Stack>
                }
                <Stack direction='row' gap={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: `${theme.palette.success.main} solid 1px`,
                    borderRadius: 5,
                    height: '20px',
                    mr: 1,
                  }}
                >
                  <Typography variant="caption" noWrap
                    sx={{
                      color: theme.palette.success.dark,
                      py: 0,
                      px: 1,
                      cursor: 'pointer'
                    }}
                    onClick={(e) => { handleDownloadSave() }}
                  >
                    Download Roughmix
                  </Typography>
                </Stack>
                <Stack direction='row' gap={1}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    border: `${theme.palette.success.main} solid 1px`,
                    borderRadius: 5,
                    height: '20px',
                    mr: 1,
                    opacity: enableBuyAlert ? 1 : 0.5,
                  }}
                >
                  <Typography variant="caption" noWrap
                    sx={{
                      color: enableBuyAlert ? theme.palette.success.dark : theme.palette.text.disabled,
                      py: 0,
                      px: 1,
                      cursor: 'pointer'
                    }}
                    onClick={(e) => {
                      if (enableBuyAlert) {
                        sendBuyAlert()
                      }
                    }}
                  >
                    Send Buy Alert
                  </Typography>
                </Stack>
                <Switch
                  checked={enableBuyAlert}
                  onChange={() => { setEnableBuyAlert(!enableBuyAlert) }} 
                  sx={{
                    // py: 0,
                    // my: 0,
                  }}
                  />
              </Stack>
            </Grid>


          </Grid>
        </Grid>

      </Stack>
      <Stack direction='row' width='100%' alignItems='center'
        marginBottom={1} marginTop={2} marginLeft={2}
      >
        <Iconify icon={!showTags ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'}
          sx={{
            // mx:2,
            width: '34px',
            cursor: 'pointer',
            color: theme.palette.info.light,
          }}
          onClick={() => { setShowTags(!showTags) }}
        />
        <Typography variant='subtitle1'
          sx={{ width: '195px', minWidth: '195px', cursor: 'pointer', color: theme.palette.info.light }}
          onClick={() => {
            setShowTags(!showTags)
          }}
        >
          Tags
        </Typography>
      </Stack>
      <Box
        sx={{
          pl: 3,
          py: 0,
          my: 0,
          display: showTags ? 'flex' : 'none',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isDesktop && {
            position: 'unset',
          }),
          overflowX: 'hidden',
        }}
      >
        {file?.tags?.genre !== undefined && file?.tags?.genre.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
            color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
          }}
          size="small"
        />
        )}
        {file?.tags?.mood !== undefined && file?.tags?.mood.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
            color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
          }}
          size="small"
        />
        )}
        {file?.tags?.instrument !== undefined && file?.tags?.instrument.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
            color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
          }}
          size="small"
        />
        )}
        {file?.tags?.theme !== undefined && file?.tags?.theme.map((tag) => tag !== '' && <Chip
          key={tag}
          label={tag}
          variant="filled"
          sx={{
            border: 'none',
            mb: 1,
            mt: 1,
            mr: 1,
            backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
            color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
          }}
          size="small"
        />
        )}
      </Box>
      <Stack direction='row' width='100%' alignItems='center'
        marginBottom={1} marginTop={2} marginLeft={2}
        sx={{
          pb: showOptions ? 0 : 2,
        }}
      >
        <Iconify icon={!showOptions ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'}
          sx={{
            // mx:2,
            width: '34px',
            cursor: 'pointer',
            color: theme.palette.info.light,
          }}
          onClick={() => { setShowOptions(!showOptions) }}
        />
        <Typography variant='subtitle1'
          sx={{
            width: '195px',
            minWidth: '195px',
            cursor: 'pointer',
            color: theme.palette.info.light,
          }}
          onClick={() => {
            setShowOptions(!showOptions)
          }}
        >
          Options
        </Typography>
      </Stack>
      <Box
        sx={{
          display: showOptions ? 'flex' : 'none',
          flexDirection: 'column',

        }}
      >
        <Stack width='100%' p={3}
          justifyContent='space-between'
        >
          <RadioGroup row defaultValue="producer"
            onChange={(e) => setFeedbackType(e.target.value)}
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
              // border: 'red solid 1px',
            }}
          >
            <FormControlLabel value='pass' control={<Radio />} label="Pass" sx={{ width: 'fit-content' }} />
            <FormControlLabel value='revise' control={<Radio />} label="Revise" sx={{ width: 'fit-content' }} />
            <FormControlLabel value='buy' control={<Radio />} label="Buy" sx={{ width: 'fit-content' }} />
          </RadioGroup>
        </Stack>
        {file.pitches &&
          <Stack
            direction='row'
            padding={3}
            sx={{
              display: (
                feedbackType === 'buy' ||
                file?.pitches[pitchIndex]?.status === 'pass' ||
                // file?.pitches[pitchIndex]?.status === 'pitched' || 
                file?.pitches[pitchIndex]?.status === 'revise')
                ? 'none' : 'flex',
              pb: 6,
            }}
          >
            <TextField
              variant='outlined'
              placeholder={feedbackType === 'pass' ? 'Pass feedback' : 'Revise feedback'}
              sx={{ width: '100%' }}
              size='small'
              autoComplete='off'
              value={feedback}
              onChange={(e) => { setFeedback(e.target.value) }}
            />
            {feedbackType === 'pass' ?
              <Button
                variant='outlined'
                color='error'
                sx={{ width: '100px', ml: 2, }}
                onClick={() => { queuePass() }}
              >
                Pass
              </Button> :
              <Button
                variant='contained'
                color='primary'
                sx={{ width: '100px', ml: 2, }}
                onClick={() => { sendFeedback() }}
              >
                Revise
              </Button>
            }
          </Stack>
        }
        <Button
          variant='contained'
          size='small'
          color='success'
          sx={{
            mx: 3,
            my: 2,
            display: 'none',
            // display: (isAA() || feedbackType !== 'buy') ? 'none' : 'inherit',
          }}
          onClick={() => {
            buySongTB()
          }}
        >
          BUY
        </Button>
        <Button
          variant='contained'
          size='small'
          color='warning'
          sx={{
            mx: 3,
            my: 2,
            mb: 4,
            display: (isAA() || feedbackType !== 'buy') ? 'none' : 'inherit',
          }}
          onClick={() => {
            handleAddDocToCart()
          }}
        >
          ADD TO CART
        </Button>
        {feedbackPrint !== '' &&
          <>
            <Divider sx={{ width: '100%', pt: 1, }} />
            <Stack
              direction='row'
              padding={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
              }}
            >
              <Typography variant='subtitle1'>{feedbackAuthor} </Typography>
              <TextfieldDisplay message={`${feedbackPrint}`} color='info.lighter' width='100%' // eslint-disable-line
              />
            </Stack>
          </>
        }

      </Box>
      {aaDoc?.pitches && aaDoc?.pitches[pitchIndex]?.status &&
        <Stack
          direction='row'
          padding={3}
          sx={{
            display: 'flex',
            pt: 0,
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 2,
            ml: 2,
            mt: 3,
            transition: 'height .2s ease-in-out',
          }}
        >
          <Stack direction='row' gap={2}
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-start',

            }}
          >

            <Image
              src={aaDoc?.artist?.artwork}
              sx={{
                height: '50px',
                width: '85px',
                borderRadius: 2,
                border: `${theme.palette.text.primary} solid 1px`,
              }}
            />
            <Typography variant='subtitle1'
              sx={{
                color: theme.palette.info.main,
              }}
            >
              {aaDoc?.artist?.name}
            </Typography>
            <Typography variant='body'
              sx={{
                color: theme.palette.info.main,
              }}
            >
              {aaDoc?.title}
            </Typography>

          </Stack>
        </Stack>
      }
      <Stack direction='row' width='100%' alignItems='center'
        display={file?.lyrics ? 'flex' : 'none'} marginBottom={2} marginLeft={2}
      >
        <Iconify icon={!showLyrics ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'}
          sx={{
            // mx:2,
            width: '34px',
            cursor: 'pointer',
            color: theme.palette.info.light,
          }}
          onClick={() => { setShowLyrics(!showLyrics) }}
        />
        <Typography variant='subtitle1'
          sx={{ width: '195px', minWidth: '195px', cursor: 'pointer', color: theme.palette.info.light }}
          onClick={() => {
            setShowLyrics(!showLyrics)
          }}
        >
          Lyrics
        </Typography>
      </Stack>
      {(file?.lyrics && showLyrics) &&
        <>
          <Divider sx={{ width: '100%', pt: 1, }} />
          <Stack
            direction='row'
            padding={3}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              ml: 2,
              transition: 'height .2s ease-in-out',
            }}
          >
            <Typography variant='caption'
              sx={{
                color: theme.palette.info.main,
                // opacity: .5,
              }}
            >
              <Markdown>
                {file?.lyrics.replace(/\r\n/g, '<br>')}
              </Markdown>
            </Typography>
          </Stack>
        </>
      }
      {fixMultisMessage.open &&
        <Stack
          direction='row'
          padding={3}
          sx={{
            //
          }}
        >
          <TextField
            variant='outlined'
            placeholder='message'
            sx={{ width: '100%' }}
            size='small'
            autoComplete='off'
            value={fixMultisMessage.message}
            onChange={(e) => { setFixMultisMessage({ ...fixMultisMessage, message: e.target.value }) }}
          />
          <Button
            variant='outlined'
            color='error'
            sx={{ width: '100px', ml: 2, }}
            // onClick={()=>{sendFeedback()}}
            onClick={() => {
              setFixMultisMessage({ open: false, message: '' })
            }}
          >
            Cancel
          </Button>
          <Button
            variant='contained'
            sx={{ width: '100px', ml: 2, }}
            // onClick={()=>{sendFeedback()}}
            onClick={() => { fixMultisRequest() }}
          >
            Send
          </Button>


        </Stack>
      }

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        <MenuItem
          component={RouterLink}
          to={file?.multitrackUrl}
          target={multitrackUrl.includes('wetransfer') ? '_blank' : '_parent'}
          // onClick={() => {
          //   if (multitrackUrl.includes('wetransfer')) {
          //     window.open(`${multitrackUrl}`, '_blank')
          //   } else {

          //   }

          // }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Download
        </MenuItem>
        <MenuItem
          onClick={() => {
            expiredMultisRequest()
          }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Send Expired Email
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFixMultisMessage({ ...fixMultisMessage, open: true })
            handleClosePopover()
          }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Fix Multis Email
        </MenuItem>
      </MenuPopover>

    </Box>
  );
}
