// @mui
import { Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
// _mock_
import { _faqs } from '../../_mock/arrays';
// components
import Iconify from '../../components-default/iconify';

// ----------------------------------------------------------------------

export default function FaqsListTB() {

  const faqArray=[
    {
      value: `Selling`,
      heading: `I already sell my services on Fiverr, Beatport etc, why should I sign up on another platform?`,
      detail: <>
      <Typography variant="body" >Those platforms have thousands of producers competing with each other. Some are good, many aren&apos;t. </Typography> <br/> <br/>
      <Typography variant="body" >Our clients don&apos;t have time to search for hours looking for the right gig, then hoping they like the track that comes back. <br/><br/>
      We are very selective about the producers we accept; with {process.env.REACT_APP_SITE_NAME}, our clients know that we are only presenting the best music. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Contract`,
      heading: "That contract was a lot of words. What's that all about?",
      detail: <>
      <Typography variant="body" >Here&apos;s the simple version:</Typography> <br/> <br/>
      <Typography variant="body" >When a client buys your song, it&apos;s no longer yours, in any way. The contract is the lawyers way of saying that.</Typography> <br/> <br/>
      <Typography variant="body" >Specific details will vary from client to client, and any particular details will be indicated in the brief.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Briefs`,
      heading: `What's a brief?`,
      detail: <>
      <Typography variant="body" >It&apos;s just a description of the type of songs they&apos;re looking for.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Fees`,
      heading: `What kind of fees do you charge?`,
      detail: <>
      <Typography variant="body" >None. </Typography> <br/> <br/>
      <Typography variant="body" >We charge a subscription fee to sell on this platform, and you keep all the money from your sales. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Pay`,
      heading: `How long will it take to get paid?`,
      detail: <>
      <Typography variant="body" >We worked hard to set up a system that gets producers paid fast. When a client buys a song through us, their payment is immediate. Once the payment settles, payouts are made within 7 business days. </Typography> <br/> <br/>
      <Typography variant="body" >The life of a musician is full of uncertainty, we want to offer a little relief. With {process.env.REACT_APP_SITE_NAME} you can make a track, pitch it and get paid QUICKLY. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Pay how`,
      heading: `How will I get the money?`,
      detail: <>
      <Typography variant="body" >We use Payoneer and Wise since they&apos;re accepted worldwide, and are the simplest way to get you paid fast. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Rights`,
      heading: `Am I giving up all my rights to a song just to be able to pitch it?`,
      detail: <>
      <Typography variant="body" >No. Until a client finishes the checkout, the song is entirely yours. Only then does the contract become valid for that song.</Typography> <br/> <br/>
      <Typography variant="body" >The only restriction is that you can&apos;t simultaneously pitch a song here and somewhere else. If you decide you want to do something else with a song you&apos;ve pitched, just cancel the pitch and you&apos;re free to do whatever you like with it.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Song type`,
      heading: `My songs don't fit into any of the brief descriptions.`,
      detail: <>
      <Typography variant="body" >That&apos;s ok! If you have a track that fits the mood of a particular brief even though that genre isn&apos;t specifically listed, pitch it anyway!</Typography> <br/> <br/>
      <Typography variant="body" >You&apos;re membership includes unlimited pitches, so there&apos;s no reason to be shy. When in doubt, pitch it!</Typography> <br/> <br/>
      </>
    },
    {
      value: `Time`,
      heading: `It's been weeks and my song hasn't been rejected or bought.`,
      detail: <>
      <Typography variant="body" >In general if it has&apos;nt been passed on, that&apos;s a good sign. If a client just doesn&apos;t like or want the song, they&apos;ll just pass.</Typography> <br/> <br/>
      <Typography variant="body" >If it&apos;s lingering with the &apos;pitched&apos; status, it could mean one of several things:</Typography> <br/> <br/>
      <Typography variant="body" sx={{pl: 3}}>• They have so many songs pitched to that particular brief, they haven&apos;t got to yours yet.</Typography> <br/> <br/>
      <Typography variant="body" sx={{pl: 3}}>• They like it but are limited to the number of songs they buy per week/month, and are hoping it will still be there for the next round.</Typography> <br/> <br/>
      <Typography variant="body" >It&apos;s up to you how long you want to wait before deciding to do something else with your song.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Cancel`,
      heading: `If I want to cancel, is it going to be a lot of trouble?`,
      detail: <>
      <Typography variant="body" >No. We make it easy to come, easy to go. Just click &apos;Edit Profile&apos; from the dashboard, and select &apos;Cancel&apos; from the Plan section. </Typography> <br/> <br/>
      </>
    },
  ]


  return (
    <div>
      {faqArray.map((accordion, index) => (
        <Accordion key={index} sx={{mb: 3}}>
          <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
              <Typography variant="subtitle1">{accordion.heading}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            {accordion.detail}
            {/* <Typography>{accordion.detail}</Typography> */}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
