import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Stack, Divider, Link } from '@mui/material';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';


// graphql
import axios from 'axios';
import { useMutation, useQuery } from "@apollo/client";
import queryString from 'query-string';
import { GET_USER_DOCS_BY_ID, GET_ALL_DOCS, } from '../graphql/queries'
import { GET_USER_PITCHES_BY_ID_M } from '../graphql/mutations';

// _mock_

import { useDispatch, useSelector } from '../redux/store';
import { addToCart } from '../redux/slices/product';


// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardAdmin from '../sections/@dashboard/general/file/AudioFileCardAdmin'


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import CartWidget from '../sections/@dashboard/e-commerce/CartWidget';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import { genreList, instrumentList, moodList, themeList } from '../components-custom/TagList';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import SongPitchedCard from '../components-custom/SongPitchedCard';


// ----------------------------------------------------------------------

export default function BriefsOrgChoicePage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const dispatch = useDispatch();

  // console.log('User: ', user)

  const [allPitchesState, setAllPitchesState] = useState([]);
  const [loadedSong, setLoadedSong] = useState({ album: '', artist: '', title: '', favorited: [], tags: [] });

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });

  const [getUserPitchesById] = useMutation(GET_USER_PITCHES_BY_ID_M);



  async function getAllPitches() {
    const allPitches = await getUserPitchesById({ variables: { userId: userr } }); // eslint-disable-line
    setAllPitchesState(allPitches?.data?.getUserPitchesById)
  }

  useEffect(() => {
    getAllPitches();
  }, []) // eslint-disable-line

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Orgs </title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dash',
                },
                { name: 'Briefs' },
              ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
          Orgs
        </Typography>
        <Grid container spacing={3} className='test-opaque'
          sx={{
            pb: 10,
            // display: 'flex',
          }}
        >
          <Grid item xs={12} md={6} lg={4}>
            <Link href='/briefs/orgs/63779527-dc9f-4d96-91bb-52fe8117c3ff'>

              <Box
                sx={{
                  width: '100%',
                  height: '200px',
                  borderRadius: 2,
                  backgroundColor: theme.palette.background.paper,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography variant="h6"
                  sx={{
                    // 
                  }}
                >
                  WavMaker
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar
        src={loadedSong}
        controlMessage={controlMessage}
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        audioBarFavorite={audioBarFavorite}
        // setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
      // refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast} />
    </>
  );
}
