import { useState, useEffect, useMemo, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Container, Typography, Select, MenuItem, Stack, Pagination } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'

// _mock_

import { addToCart } from '../redux/slices/product';


// components
import useResponsive from '../hooks/useResponsive';
import noResultsMessage from '../utils/no-results-message';

// import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
// import AudioFileCardAdmin from '../sections/@dashboard/general/file/AudioFileCardAdmin'
import { AudioFileCardAdmin } from '../routes/elements'


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import UserInfoDrawer from '../components-custom/UserInfoDrawer';
import OriginalBriefsDialog from '../components-custom/OriginalBriefsDialog';
import ChangePitchDialog from '../components-custom/ChangePitchDialog';
import isAA from '../components-custom/isAA';
import ScrollButton from '../components-custom/ScrollButton';
import FilterPane from '../components-custom/FilterPane';
import InventoryShowSearchBox from '../components-custom/InventoryShowSearchBox';
import { AudioContext } from '../components-custom/audio/audio-context';


// ----------------------------------------------------------------------

export default function AdminSubmissionsPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const isDesktop = useResponsive('up', 'md');


  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = parseInt(queryParams.page, 10) || null;
  const perPageQuery = queryParams.perpage || 30;
  const searchQuery = queryParams.search || null;
  const modifierQuery = queryParams.modifier || 'all';
  const tagsQuery = queryParams.tags || null;

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  // console.log('User: ', user)
  const [openUserDrawer, setOpenUserDrawer] = useState(false)

  const [userDrawerInfo, setUserDrawerInfo] = useState({})
  const [originalBriefsDialogOpen, setOriginalBriefsDialogOpen] = useState(false)
  const [changePitchDialogOpen, setChangePitchDialogOpen] = useState(false)

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]); // eslint-disable-line
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState(tagsQuery?.split(',') || []);
  const [showFilter, setShowFilter] = useState(isDesktop);
  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [showSearchTerm, setShowSearchTerm] = useState(searchTerm);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [songStatus, setSongStatus] = useState(modifierQuery);
  const [sortMethod, setSortMethod] = useState('date-descending')

  // const [pageLoaded, setPageLoaded] = useState(false)

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [inventoryCount, setInventoryCount] = useState('')
  const [selectedCount, setSelectedCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(perPageQuery)


  const [hideList, setHideList] = useState([])
  const [transferDoc, setTransferDoc] = useState({})

  const [selectedId, setSelectedId] = useState(null); // eslint-disable-line

  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });

  const fullPath = useMemo(() => { // eslint-disable-line
    return `${window.location.pathname}?page=${page || 1}&perpage=${perPage}&modifier=${songStatus}&tags=${filter.join(',').replace('&', '%26')}&search=${searchTerm}`
  }, [page, perPage, songStatus, filter, searchTerm]);

  const memoizedDisplayFiles = useMemo(() => displayFiles, [displayFiles]);

  function closeDialog() {
    setOriginalBriefsDialogOpen(false)
    setChangePitchDialogOpen(false)
  }

  async function updateInventory() {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'auto', // Use smooth scrolling behavior
      });


      try {
        setAxiosLoading(true)
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
          type: 'inventory',
          perPage,
          modifier: songStatus,
          sortMethod,
          page,
          filter,
          searchTerm,
          isAdmin: user.role === 'admin',
          hideList,
          // userId: '64d3c9649d24018f494a997c',
        }).then(response => {
          // console.log(response)
          setAxiosResults(response.data)
        });
      } catch (error) {
        console.error('Error fetching search results:', error);
      }


      setUpdateTrigger(false)

    } catch (err) {
      console.log(err)
    }
  }

  async function getInventoryCount() {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventoryCount',
      }).then(response => setInventoryCount(
        <Typography variant="subtitle1" fontWeight={500} sx={{ color: theme.palette.info.light }}>
          {`(${response.data} Total)`}
        </Typography>
      )
      );
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {

    navigate(fullPath)

    getInventoryCount()
    updateInventory()
  }, []) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== '') {
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.docs)
      setSelectedCount(axiosResults?.totalDocs)

      const tempFilterList = [];
      axiosResults?.docs.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
          doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.mood.length > 0) {
          doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.instrument.length > 0) {
          doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
      })
      setFilterList(tempFilterList)


      const totalPages = axiosResults.totalDocs / perPage
      setPageCount(Math.ceil(totalPages))

      setAxiosLoading(false)

    }
  }, [axiosResults]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== '') {
      updateInventory()
      navigate(fullPath)

    }
  }, [page, sortMethod, perPage, hideList]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== '') {
      updateInventory()
      navigate(fullPath)

    }
  }, [songStatus]) // eslint-disable-line


  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }

    if (filter.length === 0) {
      // if (isPlaying) {
      //   setControlMessage('pause')
      //   setIsPlaying(false)
      // } 
      updateInventory()

    } else {
      updateInventory()
    }

  }, [filter]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    // console.log('PAGE: ', page)
    // console.log('PER PAGE: ', perPage)
    // console.log('MOD: ', songStatus)

    if (axiosResults === '') {
      return
    }

    if (updateTrigger === false) {
      return
    }
    updateInventory()

  }, [updateTrigger]); // eslint-disable-line 


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
                {
                  // name: 'Dashboard',
                  // href: null,
                },
                // { name: 'Dashboard' },
              ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
              All Inventory
            </Typography>
            {inventoryCount}
          </Stack>
          <Stack direction='row' gap={2}>
            <Select
              // defaultValue={sortMethod}
              value={sortMethod}
              sx={{
                display: 'flex',
                height: '36px',
                width: '200px',
              }}
              onChange={(e) => { setSortMethod(e.target.value) }}
            >
              <MenuItem value='date-descending'>
                Sort by: Date <Iconify icon='bx:down-arrow' sx={{ height: '12px' }} />
              </MenuItem>
              <MenuItem value='date-ascending'>
                Sort by: Date <Iconify icon='bx:up-arrow' sx={{ height: '12px' }} />
              </MenuItem>
            </Select>
            <Select
              // defaultValue={sortMethod}
              value={perPage}
              sx={{
                display: 'flex',
                height: '36px',
                width: '140px',
              }}
              onChange={(e) => {
                setPage(1)
                setPerPage(e.target.value)
                // navigate(fullPath)
                setUpdateTrigger(true)
              }}
            >
              <MenuItem value={10}>
                10 per page
              </MenuItem>
              <MenuItem value={20}>
                20 per page
              </MenuItem>
              <MenuItem value={30}>
                30 per page
              </MenuItem>
            </Select>
          </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}
            sx={{
              mb: 7,
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              },
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
          >
            <Stack direction='row' gap={2} display='grid'
              sx={{
                width: '100%',
                gridTemplateColumns: {
                  xs: '1fr 1fr 1fr',
                  sm: '1fr 1fr 1fr',
                  md: '1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                },
                // mt: 2,
                mb: 2,
              }}
            >
              <Button
                variant={songStatus === 'all' ? 'contained' : 'outlined'}
                color={songStatus === 'all' ? 'info' : 'info'}
                sx={{
                  opacity: songStatus === 'all' ? 1 : .5,
                  width: '100%',
                }}
                onClick={() => {
                  setSongStatus('all')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                All
              </Button>
              <Button
                variant={songStatus === 'pitched' ? 'contained' : 'outlined'}
                color={songStatus === 'pitched' ? 'primary' : 'info'}
                sx={{
                  opacity: songStatus === 'pitched' ? 1 : .5,
                  width: '100%',
                  px: 0,
                }}
                onClick={() => {
                  setSongStatus('pitched')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`Pitched ${(songStatus === 'pitched' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
              <Button
                variant={songStatus === 'submitted' ? 'contained' : 'outlined'}
                color={songStatus === 'submitted' ? 'warning' : 'info'}
                sx={{
                  opacity: songStatus === 'submitted' ? 1 : .5,
                  color: songStatus === 'submitted' ? theme.palette.common.white : 'inherit',
                  width: '100%',
                  px: 0,
                }}
                onClick={() => {
                  setSongStatus('submitted')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`Submitted ${(songStatus === 'submitted' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
              <Button
                variant={songStatus === 'pass' ? 'contained' : 'outlined'}
                color={songStatus === 'pass' ? 'error' : 'info'}
                sx={{
                  opacity: songStatus === 'pass' ? 1 : .5,
                  width: '100%',
                  backgroundColor: songStatus === 'pass' ? theme.palette.error.dark : 'none',
                  px: 0,
                }}
                onClick={() => {
                  setSongStatus('pass')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`Passed ${(songStatus === 'pass' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
              <Button
                variant={songStatus === 'revise' ? 'contained' : 'outlined'}
                color={songStatus === 'revise' ? 'warning' : 'info'}
                sx={{
                  opacity: songStatus === 'revise' ? 1 : .5,
                  width: '100%',
                  px: 0,
                }}

                onClick={() => {
                  setSongStatus('revise')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`Revise ${(songStatus === 'revise' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
              <Button
                variant={songStatus === 'sold' ? 'contained' : 'outlined'}
                color={songStatus === 'sold' ? 'success' : 'info'}
                sx={{
                  opacity: songStatus === 'sold' ? 1 : .5,
                  width: '100%',
                  px: 0,
                }}

                onClick={() => {
                  setSongStatus('sold')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`Sold ${(songStatus === 'sold' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
              <Button
                variant={songStatus === 'unpitched' ? 'contained' : 'outlined'}
                color={songStatus === 'unpitched' ? 'info' : 'info'}
                sx={{
                  opacity: songStatus === 'unpitched' ? 1 : .5,
                  width: '100%',
                  px: 0,
                }}
                onClick={() => {
                  setSongStatus('unpitched')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`Unpitched ${(songStatus === 'unpitched' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
              <Button
                variant={songStatus === 'passQueue' ? 'contained' : 'outlined'}
                color={songStatus === 'passQueue' ? 'info' : 'info'}
                sx={{
                  opacity: songStatus === 'passQueue' ? 1 : .5,
                  width: '100%',
                  px: 0,
                }}
                onClick={() => {
                  setSongStatus('passQueue')
                  setPage(1)
                  navigate(fullPath)
                }}
              >
                {`PassQueue ${(songStatus === 'passQueue' && !axiosLoading) ? selectedCount : ''}`}
              </Button>
            </Stack>
            <InventoryShowSearchBox
              showSearchTerm={showSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setSearchTerm={setSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              filter={filter}
              setFilter={setFilter}
              selectedCount={selectedCount}
            />

            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%' /> :
              <Box >
                {displayFiles?.length > 0 ?
                  memoizedDisplayFiles.map((doc, index) => (
                    (!doc.pass.includes(userr) || user.role === 'admin') &&
                    <AudioFileCardAdmin
                      key={doc._id}
                      id={doc._id}
                      userId={userr}
                      user={user}
                      file={doc}
                      filter={filter}
                      addToCart={addToCart}
                      updateInventory={() => { updateInventory() }}
                      // cartRefetch={()=>{updateInventory()}}
                      loadedSong={src}
                      setLoadedSong={setSrc}
                      setSelectedIndex={setSelectedIndex}
                      selectedIndex={selectedIndex}
                      setSelectedId={setSelectedId}
                      index={index}
                      selected={selectedIndex === index}
                      sx={{ mb: 2, mt: 0, display: 'flex' }} // eslint-disable-line
                      setControlMessage={setControlMessage}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      audioBarFavorite={audioBarFavorite}
                      setAudioBarFavorite={setAudioBarFavorite}
                      toast={toast}
                      setToast={setToast}
                      openUserDrawer={openUserDrawer}
                      setOpenUserDrawer={setOpenUserDrawer}
                      userDrawerInfo={userDrawerInfo}
                      setUserDrawerInfo={setUserDrawerInfo}
                      inventoryPage
                      favorites
                      editable
                      passable
                      hideList={hideList}
                      setHideList={setHideList}
                      setOriginalBriefsDialogOpen={setOriginalBriefsDialogOpen}
                      setChangePitchDialogOpen={setChangePitchDialogOpen}
                      setTransferDoc={setTransferDoc}
                    />
                  )) :
                  <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                    {noResultsMessage()}
                  </Typography>
                }
              </Box>
            }
            {pageCount > 1 &&
              <Box
                sx={{
                  display: pageCount > 1 ? 'flex' : 'none',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  mb: 2,
                  // display: 'none',
                }}
              >
                <Pagination count={pageCount} page={parseInt(page, 10) || 1}
                  // variant='soft'
                  shape='rounded'
                  color='primary'
                  size='large'
                  onChange={(e, value) => {
                    setPage(value)
                    setUpdateTrigger(true)
                  }} />
              </Box>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: {
                sm: 3,
                md: 7,
              },
              mt: 3,
              pb: showFilter ? 'inherit' : 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            <FilterPane
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              setControlMessage={setControlMessage}
              setSelectedIndex={setSelectedIndex}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setFilteredDisplayFiles={setFilteredDisplayFiles}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              fullPath={fullPath}
              filter={filter}
              setFilter={setFilter}
              isAdmin
            />
          </Grid>

        </Grid>
        <ScrollButton playerLoaded={src?.audioUrl} />
      </Container>
      <UserInfoDrawer
        openUserDrawer={openUserDrawer}
        setOpenUserDrawer={setOpenUserDrawer}
        userDrawerInfo={userDrawerInfo}
        setUserDrawerInfo={setUserDrawerInfo}
        setSearchTerm={setSearchTerm}
        setUpdateTrigger={setUpdateTrigger}
        setShowSearchTerm={setShowSearchTerm}
      />
      <Footer />
      {isAA() ?
        <ChangePitchDialog
          open={changePitchDialogOpen}
          onClose={() => { closeDialog() }}
          setChangePitchDialogOpen={setChangePitchDialogOpen}
          user={user}
          setTransferDoc={setTransferDoc}
          transferDoc={transferDoc}
          setToast={setToast}
          setUpdateTrigger={setUpdateTrigger}
        /> :
        <OriginalBriefsDialog
          open={originalBriefsDialogOpen}
          onClose={() => { closeDialog() }}
          setOriginalBriefsDialogOpen={setOriginalBriefsDialogOpen}
          user={user}
          setTransferDoc={setTransferDoc}
          transferDoc={transferDoc}
          setToast={setToast}
        />
      }
      <CustomToast toast={toast} setToast={setToast} />

    </>
  );
}
