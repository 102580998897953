/* eslint-disable jsx-a11y/alt-text */
import PropTypes from 'prop-types';
import { Page, View, Text, Image, Document } from '@react-pdf/renderer';
// utils
import { fDate } from '../../../../../utils/formatTime';
import { fCurrencyCents } from '../../../../../utils/formatNumber';
//
import styles from './InvoiceStyle';

// ----------------------------------------------------------------------

InvoicePDFAAWMV2.propTypes = {
  invoice: PropTypes.object,
};

export default function InvoicePDFAAWMV2({ invoice }) {
  const {
    items,
    // organization,
    totalPrice,
  } = invoice;

  function lowerCaseBullet(index) {
    if (index === 0){
      return 'a.'
    }
    if (index === 1){
      return 'b.'
    }
    if (index === 2){
      return 'c.'
    }
    if (index === 3){
      return 'd.'
    }
    if (index === 4){
      return 'e.'
    }
    return undefined
  }

  function upperCaseBullet(index) {
    if (index === 0){
      return 'A'
    }
    if (index === 1){
      return 'B'
    }
    if (index === 2){
      return 'C'
    }
    if (index === 3){
      return 'D'
    }
    if (index === 4){
      return 'E'
    }
    return undefined
  }

  function renderSchedule(schedule, funcItems){

    let subtotal = 0
    funcItems.map(x => { // eslint-disable-line
      subtotal += x.price
      return undefined
    })

    return <Page size="A4" style={styles.page} >
        <View style={[styles.alignCenter, styles.mb8]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>Schedule &quot;{schedule}&quot;</Text>
        </View>

        <View style={[styles.alignCenter, styles.mb8]}>
          <Text style={[styles.subtitle2, styles.mb4,] }>Schedule {schedule} Subtotal: ${fCurrencyCents(subtotal)}</Text>
        </View>
        <View style={[styles.alignCenter, styles.mb8]}>
          <Text style={[styles.subtitle2, styles.mb8,] }>Total Invoice Amount: ${fCurrencyCents(totalPrice)}</Text>
        </View>

        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_2}>
                <Text style={[styles.subtitle2, styles.pl16]}> Track Title</Text>
              </View>

              <View style={styles.tableCell_1} />
              
              <View style={styles.tableCell_2}>
                <Text style={[styles.subtitle2, styles.pl8]}>Artist Name</Text>
              </View>

              <View style={styles.tableCell_3} />

              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>Compensation</Text>
              </View>

            </View>
          </View>

          <View style={styles.tableBody}>
            {funcItems?.map((item, index) => (
            <View style={styles.tableRow} key={item._id}>
                  <View style={styles.tableCell_1}>
                    <Text>{index + 1}</Text>
                  </View>
                  
                  <View style={styles.tableCell_2}>
                    <Text>{item.title}</Text>
                  </View>

                  <View style={styles.tableCell_1} />

                  <View style={styles.tableCell_2} >
                    <Text>{(item?.artist?.name)}</Text>
                  </View>
                  
                  <View style={styles.tableCell_3} />
                  <View style={styles.tableCell_3}>
                    <Text>${fCurrencyCents(item?.price)}</Text>
                  </View>
                </View>
                ))}
              </View>
            </View>
      </Page>
    }

  const totalItemsLength = items?.length

  const priceList = []

  items?.map (item => { // eslint-disable-line
    if (!priceList.includes(item.price)){
      return priceList.push(item.price)
    }
  })

  const sortedPriceList = priceList.sort((a, b) => a - b)

  const sortedItems = []
  
  sortedPriceList.map((price, index) =>{ // eslint-disable-line

    sortedItems.push([])
    items.map(item =>{ // eslint-disable-line
      if (item.price === price){
        sortedItems[index].push(item)
      }
    })
  })

  // console.log('SORTED ITEMS LIST: ', sortedItems)

  return (
    <Document>
      <Page size="A4" style={styles.page} >

        <View style={[styles.alignCenter, styles.mb8]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>EXHIBIT A</Text>
        </View>

        <View style={[styles.alignCenter, styles.mb8]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>STATEMENT OF WORK</Text>
        </View>
        
        <View style={[styles.alignLeft, styles.mb8, styles.mx36]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>Date: ______________</Text>
        </View>

        <View style={[styles.alignCenter, styles.mb8]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>ASSIGNMENT OF COPYRIGHT</Text>
        </View>
        
        <View style={[styles.gridContainer, styles.mx48, styles.body, styles.block]}>
            <Text>
          FOR AND IN CONSIDERATION of the mutual covenants, promises, and undertakings set forth in the Master Services Agreement between the undersigned Company and Assignee as of the effective date thereof, including without limitation the Fees set forth herein, with respect to the Materials and the musical compositions and master recordings (the musical compositions and master recordings are individually referred to herein as the “Musical Works”) in which they are embodied (hereinafter collectively &quot;Materials&quot;) listed in the corresponding Schedule attached hereto, the undersigned (hereinafter &quot;Company&quot;) hereby agrees that such Materials are works for hire for Assignee, and if such Materials are not considered works for hire, Company hereby assigns, transfers, sets over and conveys to the assignee (hereafter &quot;Assignee&quot;) all right, title, and interest in and to the following Materials held by Company:
            </Text>
        </View>

        <View style={[styles.alignLeft, styles.mx36, styles.my12]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4, styles.underline] }>Deliverables</Text>
        </View>

        <View style={[styles.alignLeft, styles.mb8, styles.mx36, styles.mb8]}>
          <Text style={[styles.body, styles.mb8, styles.underline] }>Materials being granted/assigned to Assignee:</Text>
        </View>

        <View style={[styles.alignLeft, styles.mx36, styles.mb8, styles.gridContainer]}>
          <Text style={[styles.body, styles.mb8, styles.alignRight] }>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.
          </Text>
          <Text style={[styles.body, styles.mb8, styles.pl8, styles.mr36] }>
          Deliverables (Materials): Each Musical Work shall be subject to Assignee&apos;s approval in its sole and absolute discretion and shall not be considered a Musical Work hereunder until approved by Assignee.
          </Text>
        </View>

        <View style={[styles.alignLeft, styles.mx36, styles.mb8, styles.gridContainer]}>
          <Text style={[styles.body, styles.mb8, styles.alignRight] }>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.
          </Text>
        </View>
          {sortedPriceList.map((item, index) =>{ // eslint-disable-line
            return <View style={[styles.alignLeft, styles.mx58, styles.mb8, styles.gridContainer]}>
          <Text style={[styles.body, styles.mb8, styles.alignRight] }>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{lowerCaseBullet(index)}
          </Text>
            <Text style={[styles.body, styles.mb8, styles.pl8, styles.mr36] }>
            Fees: ${`${fCurrencyCents(item)}`} per approved work listed on Schedule {upperCaseBullet(index)}. Company shall invoice Assignee immediately upon the provision of Musical Works hereunder (i.e. when Musical Works are added to Schedule &apos;{upperCaseBullet(index)}&apos;)          
            </Text>
            </View>
          })}

    
        <View style={[styles.gridContainer, styles.mx48, styles.body, styles.block, styles.mb8]}>
            <Text>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The within assignment, transfer, and conveyance includes, without limitation, the lyrics, music, and title of said Materials, any and all works derived therefrom, the United States and worldwide copyright therein, and any renewals or extensions thereof, and any and all other rights that Company now has or to which Company may become entitled under existing or subsequently enacted federal, state or foreign laws, including, without limitation, the following rights: to prepare derivative works based upon the Materials, to distribute copies or phonorecords of the Materials, and to perform and display the Materials publicly. The within grant further includes any and all causes of action or infringement of the Materials, past, present, and future, and all the proceeds from the foregoing accrued and unpaid and hereafter accruing. Company warrants and represents that the Materials are original works that do not infringe upon the rights of any third parties and that no other compensations shall be due in connection therewith other than the Fees explicitly set forth herein.
            </Text>
        </View>
        <View style={[styles.gridContainer, styles.mx48, styles.body, styles.block, styles.mb32]}>
            <Text>
             Effective upon execution from the date first set forth herein:
            </Text>
        </View>

        <View style={[styles.alignLeft, styles.mb8, styles.mx36]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>Company</Text>
          <Text style={[styles.body,] }>By: _________________________________</Text>
          <Text style={[styles.body, styles.mb8,] }>An Authorized Signatory</Text>
        </View>

        <View style={[styles.alignLeft, styles.my12, styles.mx36]}>
          <Text style={[styles.tableHeader, styles.mb8, styles.h4] }>Assignee</Text>
          <Text style={[styles.body] }>By: _________________________________</Text>
          <Text style={[styles.body, styles.mb8,] }>An Authorized Signatory</Text>
        </View>

      </Page>
      
      {sortedItems.map((item, index) => { // eslint-disable-line
        return renderSchedule(upperCaseBullet(index), item)
      })
    }
      
    </Document>
  );
}
