import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';  // eslint-disable-line
import { m } from 'framer-motion';

import { useParams, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';

import { Autocomplete, Grid, Box, Button, Container, ToggleButtonGroup, ToggleButton, Typography, TextField, Chip, IconButton, Divider, Stack, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useMutation, useQuery } from "@apollo/client";


// import { Dropbox } from 'dropbox';
// graphql
import axios from 'axios';
import { GET_USER_BY_ID } from '../graphql/queries'
import { fDate, fToNow } from '../utils/formatTime';





// components
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import { varFade, MotionViewport } from '../components-default/animate';
import useCopyToClipboard from '../hooks/useCopyToClipboard';




// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import { UploadBox } from '../components-default/upload';
import { genreList, moodList, instrumentList, themeList } from '../components-custom/TagList';
import AddArtistDialog from '../components-custom/AddArtistDialog';


import Footer from '../layouts/main/Footer';
import { ArtistSearch } from '../components-custom/sections/@dashboard/e-commerce/shop';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';
import TextfieldDisplay from '../components-custom/TextfieldDisplay';
import CustomToast from '../components-custom/CustomToast';
import CounterSignDialog from '../components-custom/CounterSignDialog';

// ----------------------------------------------------------------------



export default function ContractTodoListPage() {


  const { themeStretch } = useSettingsContext();
  const theme = useTheme();
  const navigate = useNavigate();


//   const windowParams = new URLSearchParams(window.location.search);
  // const briefId = windowParams.get('brief');

  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  // console.log('User: ', user)
  
  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [loading, setLoading] = useState(false);
  const [contractsList, setContractsList] = useState([]);
  const [signLink, setSignLink] = useState('');
  const [contractListId, setContractListId] = useState('');
  const [ legalDialogOpen, setLegalDialogOpen ] = useState(false)


  function openDialog(link, itemId){
    setSignLink(link)
    // setContractListId(itemId)
    setLegalDialogOpen(true)
  }


  function closeDialog(){
    setLegalDialogOpen(false)
    setSignLink('')
    setContractListId('')
  }

  function displayInfo(info){
    const splitInfo = info?.split('has completed') || 'butt'
    return splitInfo[0]
  }

  async function getOpenContracts(){
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/getopencontracts`, {  // eslint-disable-line
      method: 'getOpenContracts',
      itemId: null
    })
    setContractsList(res.data)
  }

  useEffect(()=>{
    getOpenContracts()
    // removeContractListItem()
    },[]) // eslint-disable-line

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Contracts </title>
      </Helmet>
      <Container>
      <Box
        sx={{
          pt: 6,
          pb: 1,
        }}
      >
        <Box sx={{display: 'flex', gap: 2}}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Open Contracts
          </Typography>
        </Box>
        <Grid container spacing={3}>

          {contractsList.length > 0 && contractsList.map((item) => <Grid  // eslint-disable-line
            key={item?._id} item xs={12} sm={12} md={4} lg={3} sx={{mb: 2, paddingTop: '0px',}}>
            <Box 
              sx={{
                display: 'flex',
                flexDirection: 'column', 
                justifyContent: 'center',
                alignItems: 'center',
                gap: 2,
                padding: 3,
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px` 
                
              }}
            >
              <Button 
                variant='outlined' 
                color='info'
                fullWidth
                onClick={()=>{
                  setSignLink(item?.link)
                  setContractListId(item._id)
                  setLegalDialogOpen(true)
                }}
                >
                Sign Doc
              </Button>
              {item?.info &&
                <Typography variant='body'
                  sx={{
                    display: 'flex',
                    textAlign: 'center',
                  }}
                >
                  {displayInfo(item.info)}
                </Typography>
              }
              <Typography variant='body'>
                {fToNow(item?.createdAt)}
              </Typography>
            </Box>
          </Grid>
          
          )}

        </Grid>
      </Box>
      </Container>
      <CounterSignDialog 
        open={legalDialogOpen} 
        onClose={()=>{closeDialog()}} 
        signLink={signLink} 
        user={user} 
        contractListId={contractListId}
        setContractsList={setContractsList}
        getOpenContracts={()=>{getOpenContracts()}}
      />

      <CustomToast toast={toast} setToast={setToast} />
      <Footer />
      </>
  );
}
