import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import {
  Box,
  Stack,
  IconButton,
  Typography
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import Iconify from '../components-default/iconify';


export default function InventoryShowSearchBox(props) {

  const theme = useTheme();

  const {
    showSearchTerm,
    setShowSearchTerm,
    setSearchTerm,
    setUpdateTrigger,
    filter,
    setFilter,
    selectedCount,
  } = props;

  // console.log('SC: ', selectedCount)

  return (
    <Box
      sx={{
        display: (showSearchTerm !== '' || filter?.length > 0) ? 'flex' : 'none',
        width: '100%',
        minHeight: 'fit-content',
        borderRadius: 1,
        backgroundColor: theme.palette.divider,
        border: `${theme.palette.info.main} solid 1px`,
        p: 3,
        mb: 2,
        alignItems: 'center',
      }}
    >
      <Stack direction='column'
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <Box
          // SEARCH LINE
          sx={{
            display: showSearchTerm !== '' ? 'flex' : 'none',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <IconButton onClick={() => {
            setSearchTerm('')
            setShowSearchTerm('')
            setUpdateTrigger(true)
          }}>
            <CancelIcon />
          </IconButton>
          <Typography variant='subtitle1'>
            Showing results for &apos;<b>{showSearchTerm}&apos;</b>
          </Typography>
          {selectedCount !== '' &&
            <Typography variant='subtitle1'
              sx={{
                color: theme.palette.text.disabled,
              }}
            >
              {`(${selectedCount})`}
            </Typography>
          }
        </Box>
        <Box
          // FILTER LINE
          sx={{
            display: filter?.length > 0 ? 'flex' : 'none',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <IconButton onClick={() => {
            setFilter([])
          }}>
            <CancelIcon />
          </IconButton>
          <Box sx={{ display: 'flex', gap: 1, cursor: 'default' }}>
            <Typography variant='subtitle1' >
              Tags:
            </Typography>
            {filter?.map((item, index) => <Box key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                // gap: 1,
                borderRadius: 2,
                px: 1,
                mx: 1,
                border: `${theme.palette.primary.dark} solid 1px`,
              }}
            >
              <Typography key={index}
                variant='body2'
                sx={{
                  color: theme.palette.primary.dark,
                  // px: 1,
                }}
              >{item}
              </Typography>
              <IconButton
                sx={{
                  color: theme.palette.primary.dark,
                  height: '14px',
                  width: '14px',
                  p: 0,
                }}
                onClick={() => {
                  setFilter(filter.filter((tag) => tag !== item))
                }}>
                <Iconify icon='mdi:close' />
              </IconButton>

            </Box>
            )}
          </Box>
          {selectedCount !== '' &&
            <Typography variant='subtitle1'
              sx={{
                color: theme.palette.text.disabled,
              }}
            >
              {`(${selectedCount})`}
            </Typography>
          }
        </Box>

      </Stack>
    </Box>


  );
};

InventoryShowSearchBox.propTypes = {
  showSearchTerm: PropTypes.string,
  setShowSearchTerm: PropTypes.func,
  setSearchTerm: PropTypes.func,
  setUpdateTrigger: PropTypes.func,
  filter: PropTypes.array,
  setFilter: PropTypes.func,
  selectedCount: PropTypes.any,
};
