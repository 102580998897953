import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { useTheme } from '@mui/material/styles';

import { Box, Button, Divider, Grid, InputAdornment, MenuItem, Popover, Stack, TextField, Tooltip, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// utils
import axios from 'axios';

// components
import Iconify from '../components-default/iconify';
// import Image from './image/Image';
import MenuPopover from '../components-default/menu-popover/MenuPopover';
import useCopyToClipboard from '../hooks/useCopyToClipboard';


// ----------------------------------------------------------------------

UserInfoCard.propTypes = {
  sx: PropTypes.object,
  inputUser: PropTypes.object,
  hideItemOption: PropTypes.bool,
  userDrawerInfo: PropTypes.object,
  setUserDrawerInfo: PropTypes.func,
  openUserDrawer: PropTypes.bool,
  setOpenUserDrawer: PropTypes.func,
  openArtistDrawer: PropTypes.bool,
  setOpenArtistDrawer: PropTypes.func,
  transactionDialogOpen: PropTypes.bool,
  setTransactionDialogOpen: PropTypes.func,
  targetUserId: PropTypes.string,
  setTargetUserId: PropTypes.func,
  artistDetails: PropTypes.object,
  setArtistDetails: PropTypes.func,
  toast: PropTypes.object,
  setToast: PropTypes.func,
};

export default function UserInfoCard({
  inputUser,
  hideItemOption,
  userDrawerInfo,
  setUserDrawerInfo,
  openUserDrawer,
  setOpenUserDrawer,
  openArtistDrawer,
  setOpenArtistDrawer,
  artistDetails,
  setArtistDetails,
  transactionDialogOpen,
  setTransactionDialogOpen,
  targetUserId,
  setTargetUserId,
  toast,
  setToast,
  sx,
  ...other
}) {

  const theme = useTheme();
  const { copy } = useCopyToClipboard();


  const [userBalance, setUserBalance] = useState('')
  const [paymentAmount, setPaymentAmount] = useState('')
  const [artistList, setArtistList] = useState('')
  const [userDetails, setUserDetails] = useState(false)
  const [allPitchesState, setAllPitchesState] = useState()

  const [openPopover, setOpenPopover] = useState(null)
  const [openDetailsPopover, setOpenDetailsPopover] = useState(null)

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleOpenDetailsPopover = (event) => {
    setOpenDetailsPopover(event.currentTarget);
  };

  const handleCloseDetailsPopover = () => {
    setOpenDetailsPopover(null);
  };


  async function handlePayProducer() {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/payproducerstripe`, {  // eslint-disable-line
        stripeId: inputUser?.payoutId,
        amount: paymentAmount * 100,
      }).then(response => {
        console.log(response)
      });
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  }

  async function handleTrialOver(id) {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/sendtrialoveralert`, {  // eslint-disable-line
        userId: id,
      }).then(response => {
        console.log(response)
        if (response.status === 200) {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Email sent`, duration: 4000 })
        }
      });
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  }

  async function getProducerPitchCount(id) {
    try {
      console.log('USER ID: ', id)
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventoryCount',
        modifier: 'pitchedCount',
        userId: id,
      }).then(response => {
        // console.log(response)
        setAllPitchesState(response.data)
      });
    } catch (err) {
      console.log(err)
    }
  }





  useEffect(() => {

    let balance = 0;

    inputUser?.transactions.map(item => { // eslint-disable-line
      if (item.status === 'credit') {
        balance += item.amount
      }
    })

    inputUser?.transactions.map(item => { // eslint-disable-line
      if (item.status === 'paid') {
        balance -= item.amount
      }
    })
    setUserBalance(balance / 100)

    const artistArray = []
    inputUser.alias?.map(item => { // eslint-disable-line
      artistArray.push(item.name)
    })

    setArtistList(inputUser?.alias)

    getProducerPitchCount(inputUser?._id)

  }, [inputUser])

  useEffect(() => {
    if (allPitchesState) {
      console.log('PITCH STATE', allPitchesState)
    }
  }, [allPitchesState])


  return (
    <Box
      sx={{
        // height: '60px',
        width: '100%',
        display: (hideItemOption && userBalance <= 0) ? 'none' : 'flex',
        gap: 3,
        borderRadius: 1,
        border: userBalance > 0 ? `${theme.palette.success.main} solid 2px` : `${theme.palette.divider} solid 2px`,
        p: 2,
        mb: 2,
        cursor: 'default',
        boxShadow: userDrawerInfo === inputUser ? theme.shadows[10] : 'none',
        backgroundColor: userDrawerInfo === inputUser ? `${theme.palette.background.paper}80` : 'inherit',
        transform: userDrawerInfo === inputUser ? 'translateX(-15px)' : 'none',
        transition: 'all .3s'
      }}
    >
      <Stack width='100%' >
        <Stack direction='row' width='100%' alignItems='center'
          display='flex'
        >
          <Grid container >

            <Grid item xs={6} sm={3} md={3} lg={3} xl={3}
              sx={{
                // border: 'red solid 1px',
              }}
            >
              <Stack direction='row'
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: 1,
                }}
              >

                <Iconify icon={!userDetails ? 'bx:right-arrow' : 'bx:down-arrow'}
                  sx={{
                    // mx:2,
                    // width: '34px',
                    cursor: 'pointer',
                  }}
                  onClick={() => { setUserDetails(!userDetails) }}
                />
                <Typography variant='subtitle'
                  sx={{
                    // width: '195px',
                    // minWidth: '195px',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    setOpenUserDrawer(true)
                    setUserDrawerInfo(inputUser)
                  }}
                >
                  {inputUser?.name}
                </Typography>
              </Stack>

            </Grid>

            <Grid item xs={6} sm={6} md={2.5} lg={3} xl={3}
              sx={{
                // border: 'red solid 1px',
              }}
            >
              <Typography variant='caption' sx={{ width: '215px', minWidth: '215px' }}>
                {inputUser?.email}
              </Typography>

            </Grid>
            <Grid item xs={12}
              sx={{
                display: {
                  xs: 'inherit',
                  sm: 'none',
                },
              }}
            >
              <Divider width='100%' orientation='horizontal' sx={{ my: 1 }} />
            </Grid>

            <Grid item xs={4} sm={2.5} md={2} lg={1.5} xl={1.5}
              sx={{
                // border: 'red solid 1px',
              }}
            >
              <Stack direction='row' width='100%' gap={1}>
                <Typography variant='subtitle1'>
                  Plan:
                </Typography>
                {inputUser?.plan === 'Trial' ?
                  <Typography variant='body'
                    sx={{
                      cursor: 'pointer',
                      borderRadius: 1,
                      border: `${theme.palette.info.main} solid 1px`,
                      px: 2,
                    }}
                    onClick={(e) => {
                      handleOpenPopover(e)
                    }}
                  >
                    {inputUser?.plan}
                  </Typography> :
                  <Typography variant='body'>
                    {inputUser?.plan}
                  </Typography>
                }
              </Stack>
            </Grid>

            <Grid item xs={4} sm={2.5} md={1.5} lg={1.5} xl={1.5}
              sx={{
                // border: 'red solid 1px',
              }}
            >
              <Stack direction='row' width='100%'
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 1,
                }}
              >
                <Typography variant='subtitle1'>
                  Pitches:
                </Typography>
                <Typography variant='body' fontWeight='bold'>
                  {allPitchesState}
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={4} sm={3} md={2} lg={2} xl={2}
              sx={{
                // border: 'red solid 1px',
              }}
            >
              <Stack direction='row'
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  gap: 1,
                }}
              >
                <Typography variant='subtitle1' >
                  Balance:
                </Typography>
                <Typography variant='body' fontWeight='bold'>
                  ${userBalance}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={1}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                justifyContent: {
                  xs: 'flex-end',
                  sm: 'flex-end',
                },
                my: {
                  xs: 2,
                  sm: 'inherit',
                },
                width: '100%',
                // border: 'red solid 1px',
              }}
            >
              <Iconify icon='mingcute:transfer-3-line'
                sx={{
                  // mx:2,
                  width: '28px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setTargetUserId(inputUser?._id)
                  setTransactionDialogOpen(true)
                  setOpenDetailsPopover(null)
                }}
              />
              <Iconify icon='mdi:dots-horizontal'
                sx={{
                  // mx:2,
                  width: '28px',
                  cursor: 'pointer',
                }}
                onClick={handleOpenDetailsPopover}
              />

            </Grid>

          </Grid>
        </Stack>
        {userDetails &&
          <>
            <Divider sx={{ my: 2, }} ml={3} />
            <Stack direction='row' spacing={0} flexWrap='wrap' mt={1} pl={3} width="100%"
              sx={{
                justifyContent: 'flex-start',
              }}
            >
              {inputUser?.alias.map((artist, index) => { // eslint-disable-line
                return <Typography width='fit-content'
                  key={`artist-${index}`}
                  variant='caption'
                  sx={{
                    px: 1,
                    mx: 1,
                    mb: 1,
                    borderRadius: .5,
                    border: `${theme.palette.info.dark} solid 1px`,
                    width: 'max-content',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setArtistDetails({ ...artist, userId: inputUser._id },)
                    console.log(artist)
                    setOpenArtistDrawer(true)
                  }}
                >
                  {artist?.name}
                </Typography>
              })}
            </Stack>
          </>
        }
      </Stack>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            handleTrialOver(inputUser?._id)
          }}
          sx={{
            "&:hover": {
              backgroundColor: theme.palette.info.lighter,
              color: theme.palette.common.white,
            }
          }}
        >
          Send Trial Over Alert
        </MenuItem>
      </MenuPopover>
      <MenuPopover
        open={openDetailsPopover}
        onClose={handleCloseDetailsPopover}
        arrow="top-left"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            copy(inputUser?.email)
            setOpenDetailsPopover(null)
            setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', vertical: 'bottom', toastColor: theme.palette.success.dark, message: `User email copied to clipboard`, duration: 2000 })
          }}
          sx={{
            "&:hover": {
              // backgroundColor: theme.palette.info.lighter,
              // color: theme.palette.common.white,
            }
          }}
        >
          Copy Email
        </MenuItem>
        <Tooltip title={inputUser?._id} placement='left'>
          <MenuItem
            onClick={() => {
              copy(inputUser?._id)
              setOpenDetailsPopover(null)
              setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', vertical: 'bottom', toastColor: theme.palette.success.dark, message: `User ID copied to clipboard`, duration: 2000 })

            }}
            sx={{
              "&:hover": {
                // backgroundColor: theme.palette.info.lighter,
                // color: theme.palette.common.white,
              }
            }}
          >
            Copy ID
          </MenuItem>
        </Tooltip>
        <MenuItem
          onClick={() => {
            setTargetUserId(inputUser?._id)
            setTransactionDialogOpen(true)
            setOpenDetailsPopover(null)
          }}
          sx={{
            "&:hover": {
              // backgroundColor: theme.palette.info.lighter,
              // color: theme.palette.common.white,
            }
          }}
        >
          User Transactions
        </MenuItem>
      </MenuPopover>

    </Box>
  );
}
