import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Alert, Tooltip, Stack, Typography, Link, Box } from '@mui/material';
// auth
import { useAuthContext } from '../../auth/useAuthContext';
// routes
import { PATH_AUTH } from '../../routes/paths';
// layouts
import LoginLayout from '../../layouts/login';
//
import AuthLoginForm from './AuthLoginForm';
import isAA from '../../components-custom/isAA';

// ----------------------------------------------------------------------

export default function Login() {
  const { method } = useAuthContext();

  return (
    <LoginLayout
      sx={{
        // backgroundColor: 'red !important' 
      }}
    >
      <Stack spacing={2} sx={{ mb: 5, position: 'relative', }}>
        <Typography variant="h4">Sign in to {process.env.REACT_APP_SITE_NAME}</Typography>

        <Stack direction="row" spacing={1.5}>
          {/* <Typography variant="body2">New user?</Typography> */}
          <Typography variant="body2">Not a member?</Typography>

          <Link component={RouterLink} to='/signuplist' variant="subtitle2">
            {isAA() ? 'Get on the list' : 'Apply now'}
          </Link>
          {/* <Link component={RouterLink} to={PATH_AUTH.register} variant="subtitle2">
            Create an account
          </Link> */}
        </Stack>

      </Stack>

      <AuthLoginForm />

    </LoginLayout>
  );
}
