import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Divider, Grid, Stack, Container, Typography, Link, } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';


import { useQuery, useMutation } from "@apollo/client";
import Footer from '../layouts/main/Footer';
// routes
// utils

import { useAuthContext } from '../auth/useAuthContext';


import axios from '../utils/axios';
import { GET_USER_CART } from '../graphql/queries'
import AudioPlayerBar from '../components-custom/AudioPlayerBar';

import { useDispatch } from '../redux/store';
import { addToCart } from '../redux/slices/product';


// components
import Markdown from '../components-default/markdown';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
import Iconify from '../components-default/iconify';
// sections
import {
  BriefPostHero,

} from '../components-custom/sections/@dashboard/blog';
import { GET_ORG_INFO_M } from '../graphql/mutations';
import LoadingScreen from '../components-default/loading-screen';
import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import CustomToast from '../components-custom/CustomToast';
import { fCurrencyCents } from '../utils/formatNumber';
import isAA from '../components-custom/isAA';
import noResultsMessage from '../utils/no-results-message';
import { AudioContext } from '../components-custom/audio/audio-context';


// ----------------------------------------------------------------------

export default function BriefDetailClientPageV2() {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const dispatch = useDispatch();


  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const querySuccess = queryParams.get('updatesuccess');

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  const theme = useTheme();
  const { briefId } = useParams();

  const [post, setPost] = useState(null);

  const [quantity, setQuantity] = useState(null)
  const [fulfilled, setFulfilled] = useState(null)

  const [loadingPost, setLoadingPost] = useState(true);

  const [errorMsg, setErrorMsg] = useState(null);

  const [loadedSong, setLoadedSong] = useState({ album: '', artist: '', title: '', favorited: [], tags: [] });

  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [spotifyPlaylistId, setSpotifyPlaylistId] = useState('')
  const [youtubePlaylistId, setYoutubePlaylistId] = useState('')

  const [songStatus, setSongStatus] = useState('pitched')
  const [songPitches, setSongPitches] = useState([])

  const [updateTrigger, setUpdateTrigger] = useState(false)

  const [keyGate, setKeyGate] = useState(true)

  const [showReference, setShowReference] = useState(false)
  const [showBriefDetail, setShowBriefDetail] = useState(false)

  const [userOrg, setUserOrg] = useState({});


  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });


  const [getOrgInfo] = useMutation(GET_ORG_INFO_M);

  const { loading: cartLoading, error: cartError, data: cartData = { getUserCart: [] }, refetch: cartRefetch } = useQuery(GET_USER_CART, {
    variables: { orgId: user.organization },
    fetchPolicy: 'network-only'
  });

  async function getBrief() {
    setLoadingPost(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getsinglebrief`, {  // eslint-disable-line
        briefId,
        type: 'client'
      }).then(response => {
        console.log('render')

        if (response?.data?.brief?.post?.spotifyLink !== '') {
          try {
            const spotifyTemp1 = response?.data?.brief?.post?.spotifyLink.split('/playlist/') || '123?123'
            const spotifyTemp2 = spotifyTemp1[1].split('?')
            setSpotifyPlaylistId(spotifyTemp2[0])
          } catch (err) {
            console.log(err)
          }
        }
        if (response?.data?.brief?.post?.youtubeLink !== '') {
          try {
            const youtubeTemp1 = response?.data?.brief?.post?.youtubeLink.split('list=') || '123?123'
            const youtubeTemp2 = youtubeTemp1[1].split('&')
            setYoutubePlaylistId(youtubeTemp2[0])
          } catch (err) {
            console.log(err)
          }
        }
        setPost(response?.data?.brief?.post)
        getUserOrg(response?.data?.brief?.post?.organization)
        setQuantity(response?.data?.quantity)
        setFulfilled(response?.data?.fulfilled)
        if (querySuccess === 'true') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${response?.data?.brief?.post?.title} successfully updated`, duration: 4000 })
        }
        setLoadingPost(false)
      }
      );
    } catch (err) {
      console.log(err)
    }
  }


  async function getPitches() {
    // setLoadingPost(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getbriefpitches`, {  // eslint-disable-line
        briefId,
        type: 'pitches',
        pitchType: songStatus,
      }).then(response => {
        // console.log('render')
        // console.log('SONG PITCHES: ', response?.data)
        setSongPitches(response?.data.briefPitches)
        setUpdateTrigger(false)
        // setLoadingPost(false)
      }
      );
    } catch (err) {
      console.log(err)
    }
  }


  async function getUserOrg(orgId) {
    const org = await getOrgInfo({ variables: { orgId: orgId } }); // eslint-disable-line      
    if (org.data.getOrgInfo !== null) {
      setUserOrg(org.data?.getOrgInfo)
    }
    return undefined
  }

  async function createReceipt() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/generalreceipts`, {  // eslint-disable-line
      userId: user._id,
      elementKey: 'briefs',
      elementValue: briefId
    })
  }

  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer() {
    setTimeout(() => setKeyGate(true), 500)
  }


  // useEffect(()=>{
  //   if (pitchesData?.getPitchesByBriefId !== undefined && pitchesData?.getPitchesByBriefId !== songPitches){
  //     setSongPitches(pitchesData?.getPitchesByBriefId)
  //   }
  // }, [pitchesData, songPitches]) // eslint-disable-line


  useEffect(() => {
    createReceipt()
  }, []) // eslint-disable-line


  useEffect(() => {
    getBrief()
    getPitches()
  }, []) // eslint-disable-line


  useEffect(() => {
    if (updateTrigger === true) {
      getPitches()
    }
  }, [updateTrigger]) // eslint-disable-line


  if (loadingPost) {
    return <LoadingScreen />
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME} || Client Brief </title>
      </Helmet>

      <Container
      >
        <CustomBreadcrumbs
          // heading="Post Details"
          links={post?.active ?
            [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              {
                name: 'Briefs',
                href: '/briefs',
              },
              {
                name: user?.role !== 'admin' ? post?.title : `${post?.title} - CLIENT VIEW`,
              },
            ] :
            [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              {
                name: 'Briefs',
                href: '/briefs',
              },
              {
                name: 'Inactive',
                href: '/briefs/inactive',
              },
              {
                name: user?.role !== 'admin' ? post?.title : `${post?.title} - CLIENT VIEW`,
              },
            ]
          }
        />
        <Link onClick={() => { navigate(-1) }}
          sx={{
            pl: 0,
            mb: 1,
            '&a': {
              textDecoration: 'none',
            },
            color: theme.palette.text.primary,
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Iconify icon='mingcute:arrow-left-fill' width='36px'
            sx={{
              pr: 2,
              color: theme.palette.text.primary
            }}
          />
          <Typography variant='subtitle1'>
            Back
          </Typography>
        </Link>
        {post && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, pb: 10 }}>
            <Stack
              sx={{
                borderRadius: 2,
                mt: 0,
                boxShadow: {
                  md: theme.customShadows.card,
                },
                overflowX: 'hidden',

              }}
            >

              <BriefPostHero post={post} orgInfo={userOrg} user={user} briefId={briefId} editable />

              {showBriefDetail &&
                <Grid container spacing={3}>
                  <Grid item
                    xs={12} sm={12} md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', }}>
                      <Typography
                        variant="h5"
                        sx={{
                          pt: 2,
                          pb: 5,
                          px: { md: 5 },
                          cursor: 'default',
                        }}
                      >
                        {post.description}
                      </Typography>
                      <Divider sx={{ m: 5, mt: 0 }} />
                      <Markdown
                        children={post.content}
                        sx={{
                          px: { md: 5 },
                          cursor: 'default',

                        }}
                      />
                      <Divider sx={{ m: 5, mt: 0, mb: 3 }} />
                      <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='center' >
                        <Typography variant='subtitle1'
                          sx={{ pl: 5, width: '120px', pr: 0, }}
                        >
                          Rate:
                        </Typography>
                        <Typography variant='body2'
                        >
                          {`$${fCurrencyCents(post?.customRate ? post?.customRate : post?.rate)}`}
                        </Typography>

                      </Stack>
                      <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='flex-end' >
                        <Typography variant='subtitle1'
                          sx={{ pl: 5, mt: 2, minWidth: '120px', width: '120px' }}
                        >
                          Terms:
                        </Typography>
                        <Typography variant='body2' flexWrap='wrap'
                        >
                          {post?.terms}
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='flex-end' >
                        <Typography variant='subtitle1'
                          sx={{ pl: 5, mt: 2, width: '120px', height: 'min-content' }}
                        >
                          Quantity:
                        </Typography>
                        <Typography variant='body2'
                          sx={{
                            // color: theme.palette.error.main
                          }}
                        >
                          {quantity || 'Not specified'}
                        </Typography>
                      </Stack>
                      <Stack direction='row' spacing={1} justifyContent='flex-start' alignItems='flex-end' >
                        <Typography variant='subtitle1'
                          sx={{ pl: 5, mt: 2, width: '120px', height: 'min-content' }}
                        >
                          Fulfilled:
                        </Typography>
                        <Typography variant='body2'
                          sx={{
                            // color: theme.palette.error.main
                          }}
                        >
                          {fulfilled || 'Not specified'}
                        </Typography>
                      </Stack>
                    </Box>
                  </Grid>
                  <Grid item
                    xs={12} sm={12} md={6}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      mb: 7,
                      pr: {
                        sm: 0,
                        md: 4,
                      },
                    }}
                  >
                    <Box
                      sx={{
                        // border: 'red solid 1px',
                        width: '100%',
                        p: 4,
                        mt: {
                          sm: 0,
                          // md: 14,
                          md: 3,
                        },
                        // mb: 10,
                        borderRadius: 2,
                        // backgroundColor: theme.palette.grey[800],
                        backgroundColor: isAA() ? 'rgb(40, 40, 40)' : theme.palette.background.paper,
                        display: (post.spotifyLink || post.appleMusicLink || post.youtubeLink) ? 'block' : 'none',
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          pb: 3,
                          pt: 2,
                          color: theme.palette.common.white,
                          // py: 5,
                          // px: { md: 5 },
                        }}
                      >
                        Reference Playlist
                      </Typography>
                      <Box
                        sx={{
                          display: post.spotifyLink ? 'inherit' : 'none',
                        }}
                      >
                        <iframe
                          style={{ borderRadius: "12px" }}
                          src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator&theme=0`}
                          width="100%"
                          height="452"
                          frameBorder="0"
                          allowFullScreen=""
                          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                          loading="lazy"
                          title='trial-playlist'
                        />
                      </Box>
                      <Box
                        sx={{
                          display: post.youtubeLink ? 'inherit' : 'none',
                        }}
                      >
                        <iframe
                          width="100%"
                          height="315"
                          src={`https://www.youtube.com/embed/videoseries?si=Gld-8t4gFgvkVg1U&amp;list=${youtubePlaylistId}`}
                          title="YouTube video player"
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              }

              <Grid container spacing={0} >
                <Grid item
                  xs={12} sm={12} md={12}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2,
                    mb: 3,
                    pr: {
                      sm: 0,
                      md: 4,
                    },
                    ml: {
                      sm: 0,
                      md: 4,
                    },
                  }}
                >
                  <Button
                    fullWidth
                    variant={!showBriefDetail ? 'outlined' : 'contained'}
                    onClick={() => { setShowBriefDetail(!showBriefDetail) }}
                    sx={{ mb: 2 }}
                  >
                    {!showBriefDetail ? 'Show Brief Detail' : 'Hide Brief Detail'}
                  </Button>
                  {post.spotifyLink !== '' &&
                    <Button
                      fullWidth
                      variant={!showReference ? 'outlined' : 'contained'}
                      onClick={() => { setShowReference(!showReference) }}
                      sx={{ mb: 0 }}
                    >
                      {!showReference ? 'Show Reference Playlist' : 'Hide Reference Playlist'}
                    </Button>
                  }
                  {showReference &&
                    <Box
                      sx={{
                        // border: 'red solid 1px',
                        width: '100%',
                        height: '550px',
                        p: 2,
                        mt: {
                          sm: 0,
                          md: 4,
                        },
                        // mb: 10,
                        borderRadius: 2,
                        // backgroundColor: theme.palette.grey[800],
                        backgroundColor: 'rgb(40, 40, 40)',
                        display: post.spotifyLink || post.appleMusicLink ? 'block' : 'none',
                      }}
                    >
                      <Typography
                        variant="h3"
                        sx={{
                          pb: 3,
                          pt: 2,
                          color: theme.palette.common.white,
                          // py: 5,
                          // px: { md: 5 },
                        }}
                      >
                        Reference
                      </Typography>
                      <iframe style={{ borderRadius: "12px", }} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator&theme=0`} width="100%" height="400" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist' />
                    </Box>
                  }

                </Grid>
              </Grid>
              <Stack direction='row' spacing={1}
                sx={{
                  width: '100%',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  px: 4,
                  pb: 2,
                }}
              >
                <Button fullWidth
                  color={songStatus === 'pitched' ? 'primary' : 'primary'}
                  variant={songStatus === 'pitched' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSongStatus('pitched')
                    setUpdateTrigger(true)
                  }}
                >
                  Pitched
                </Button>
                <Button fullWidth
                  color={songStatus === 'sold' ? 'success' : 'success'}
                  variant={songStatus === 'sold' ? 'contained' : 'outlined'}
                  onClick={() => {
                    setSongStatus('sold')
                    setUpdateTrigger(true)
                  }}
                >
                  Bought
                </Button>
                <Button fullWidth
                  color={songStatus === 'pass' ? 'error' : 'error'}
                  variant={songStatus === 'pass' ? 'contained' : 'outlined'}
                  sx={{
                    display: 'none',
                    // backgroundColor: theme.palette.error.dark,
                  }}
                  onClick={() => {
                    setSongStatus('pass')
                    setUpdateTrigger(true)
                  }}
                >
                  Passed
                </Button>
              </Stack>
              <Box
                sx={{
                  mx: {
                    sm: 0,
                    md: 4,
                  },
                  pb: 10,

                }}
              >
                {songPitches.length > 0 ? songPitches.map((doc, index) => <AudioFileCardClient
                  key={doc._id}
                  id={doc._id}
                  userId={userr}
                  user={user}
                  file={doc}
                  // data={data}
                  dispatch={dispatch}
                  addToCart={addToCart}
                  refetch={() => { getPitches() }}
                  cartRefetch={cartRefetch}
                  loadedSong={src}
                  setLoadedSong={setSrc}
                  index={index}
                  sx={{ mb: 2, mt: 0, display: 'flex' }} // eslint-disable-line
                  setControlMessage={setControlMessage}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  audioBarFavorite={audioBarFavorite}
                  setAudioBarFavorite={setAudioBarFavorite}
                  favorites
                  briefId={briefId}
                  toast={toast}
                  setToast={setToast}
                  passable={songStatus !== 'sold'}
                  hideCartButton={songStatus === 'sold'}

                // editable
                />
                ) :
                  <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                    {noResultsMessage(songStatus)}
                  </Typography>

                }
              </Box>


            </Stack>
          </Box>
        )
        }

        {errorMsg && !loadingPost && <Typography variant="h6">404 {errorMsg}</Typography>}

      </Container>

      <Footer />
      <CartWidgetCustom totalItems={cartData?.getUserCart?.length} />

      <CustomToast toast={toast} setToast={setToast} />

    </>
  );
}
