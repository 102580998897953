import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import { Button, Box, Chip, Divider, Typography, Stack } from '@mui/material';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { useQuery, useMutation } from "@apollo/client";

import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { fDate, fToNow } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';
import { fPillStatus } from '../utils/formatPitchPill';


import { DELETE_PITCH } from '../graphql/mutations';
import { useSettingsContext } from '../components-default/settings';
import Label from '../components-default/label';
import Image from '../components-default/image';
import Iconify from '../components-default/iconify/Iconify';
import isAA from './isAA';


SongPitchedCard.propTypes = {
  item: PropTypes.object,
  // artistName: PropTypes.string,
  // artwork: PropTypes.string,
  // briefId: PropTypes.string,
  // pitchStatus: PropTypes.string,
  createdAt: PropTypes.string,
  // includeBriefTitle: PropTypes.bool,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  // controlMessage: PropTypes.string,
  setControlMessage: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  // userId: PropTypes.string,
  getAllPitches: PropTypes.func,
  setToast: PropTypes.func,
  inputBrief: PropTypes.object,
  multisUploadType: PropTypes.string,
  openMultisUploadDrawer: PropTypes.bool,
  setOpenMultisUploadDrawer: PropTypes.func,
  multisInfo: PropTypes.object,
  setMultisInfo: PropTypes.func,
  refetch: PropTypes.func,
};

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  mb: 3,
  position: 'absolute',
  borderRadius: 1.5,
  backgroundColor: alpha(theme.palette.grey[900], 0.44),
}));

export default function SongPitchedCard(props) {

  const { 
    item, 
    createdAt,
    loadedSong, 
    setLoadedSong,
    setControlMessage, 
    isPlaying, 
    setIsPlaying, 
    setToast,
    getAllPitches,
    inputBrief,
    multisUploadType,
    openMultisUploadDrawer,
    setOpenMultisUploadDrawer,
    multisInfo,
    setMultisInfo,
    refetch,
  } = props;

  const navigate = useNavigate()

  const [itemStatus, setItemStatus] = useState('')
  const [briefId, setBriefId] = useState('')
  const [briefTitle, setBriefTitle] = useState('')
  const [briefCategory, setBriefCategory] = useState('')
  const [briefArtwork, setBriefArtwork] = useState('')
  const [pitchPill, setPitchPill] = useState('')
  const [deletePitchWarning, setDeletePitchWarning] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)
  const [loading, setLoading] = useState(false)
  // const [pitchIndex, setPitchIndex] = useState()

  const theme = useTheme();

  const latestFeedback = (inputBrief?.feedback?.length) || 0;

  function convertDate(rawDate) {
    const timestamp = rawDate;

    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate

  }
  const [deleteSongPitch] = useMutation(DELETE_PITCH);

  async function handleDeletePitch(){
    setLoading(true)
    const removeSong = await deleteSongPitch({ variables: { docId: item._id, briefId: briefId} }); // eslint-disable-line
    // console.log('REMOVED: ', removeSong?.data?.deleteSongPitch?.title)
    // const songTitle = removeSong?.data?.deleteSongPitch?.title
    getAllPitches()
    setToast({
      open: true, 
      message: `Successfully cancelled "${removeSong?.data?.deleteSongPitch?.title}" pitch for ${briefTitle}`, 
      icon: <CheckRoundedIcon sx={{color: 'white'}}/>, 
      duration: 4000,
      toastColor: theme.palette.success.dark,
      closeButton: false,
      horizontal: 'right',
    })
    // getAllPitches();
    setLoading(false)
  }

  useEffect(()=>{

    if (inputBrief !== undefined){

        setItemStatus(inputBrief?.status || '')
        setBriefId(inputBrief?.briefId || '')
        setBriefTitle(inputBrief?.briefTitle || '')
        setBriefCategory(inputBrief?.briefCategory || '')
        setBriefArtwork(inputBrief?.briefArtwork || '')

        setPitchPill(fPillStatus(inputBrief.status))
    }

  }, [inputBrief]) // eslint-disable-line

  return (
    <Box 
      sx={{ 
        // CARD BOX
        position: 'relative',
        height: {
          xs: '385px',
          sm: '285px',
      }, 
        width: '100%', 
        borderRadius: 1.5,
        border: loadedSong?.audioUrl === item?.audioUrl ? `${theme.palette.primary.main} solid 2px` : 'none',
        overflow: 'hidden',
        p: 2,
        // border: 'red solid 1px',
        boxShadow: theme.shadows[12],
        background: theme.palette.background.paper,
    }}
    >
    {deletePitchWarning &&
      <Box
        sx={{
          zIndex: 10,
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '100%',
          backgroundColor: alpha(theme.palette.error.darker, .9),
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 6,
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100%',
            width: '100%',
          }}
        >
          <Typography variant='body2' sx={{color: theme.palette.common.white}}>
            Remove this song from
          </Typography>

          <Typography variant='body' fontWeight='bold'
            sx={{
              color: theme.palette.common.white,
            }}
            >
            {briefTitle}
          </Typography>

          <Typography variant='body2' sx={{color: theme.palette.common.white}}>
            pitches?
          </Typography>
          <Box 
            sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              gap: 2,
              pt: 3,
            }}
            >
              <Button variant='outlined' color='error'
                sx={{
                  color: theme.palette.common.white,
                  backgroundColor: 'transparent',
                  border: `${theme.palette.common.white} solid 1px`,
                  '&:hover': {
                    border: `${theme.palette.common.white} solid 1px`,
                  }
                }}
                onClick={()=>setDeletePitchWarning(false)}
                >
                Cancel
              </Button>
              <Button variant='contained' color='error'
                sx={{
                  color: theme.palette.common.white,
                  backgroundColor: theme.palette.error.main,
                  border: `${theme.palette.common.white} solid 1px`,
                  '&:hover': {
                    border: `${theme.palette.common.white} solid 1px`,
                    backgroundColor: theme.palette.error.dark,
                  }
                }}
                onClick={()=>handleDeletePitch()}
              >
                Remove
              </Button>
            </Box>

          </Box>
        </Box>
    }

      <Box 
        // HEADER BOX
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
          height: {
            xs: '250px',
            sm: '150px',
          },          
          width: '100%',
          borderRadius: 1.5,
          overflow: 'hidden',
          background: theme.palette.text.primary,
        }}
      >
        <Box 
          sx={{
            position: 'absolute',
            zIndex: 2,
            height: {
              xs: '250px',
              sm: '150px',
            },          
              width: 'calc(100% - 32px)',
            backgroundColor: alpha(theme.palette.grey[900], .44),
            borderRadius: 1.5,
          }}/>
        <Image 
          alt="cover" 
          src={briefArtwork} 
          ratio="16/9" 
          sx={{ 
            borderRadius: 1.5,
            backgroundColor: 'black',
            opacity: .7,
          }} 
        />
        <Box
          sx={{
            position: 'absolute',
            zIndex: 3,
            height: '50%',
            width: 'calc(100% - 24px)',
            display: 'flex',
            // border: 'red solid 1px',
            // m: 2,
          }}
        >
          <Button 
            variant='text'
            sx={{
              height: {
                xs: '80%',
                sm: '50%',
              },
              width: {
                xs: '70%',
                sm: '70%',
              },
              ml: '15%',
              mt: {
                xs: '13%',
                sm: '13%',
                md: '12%',
                lg: '15%',
              
              },
              borderRadius: '50%',
              '&:hover': {
                background: 'none',
              },
              '&:active': {
                background: 'none',
              },

            }}
            onClick={() => {
              if (loadedSong !== item) {
                setLoadedSong(item);
              } else {
                setControlMessage('play-pause')
              }
            
            }}
          >
          {isPlaying && item._id === loadedSong._id ?
            <PauseCircleOutlinedIcon 
              sx={{
                height: '100%',
                width: '100%',
                color: theme.palette.common.white,
              }}
            /> :
            <PlayCircleOutlinedIcon 
              sx={{
                height: '100%',
                width: '100%',
                color: theme.palette.common.white,
              }}
            /> 
          }
          </Button>
      </Box>

      </Box>
      <Label // brief title top left corner
        sx={{
          top: {
            xs: 34,
            sm: 24,
          },
          left: 24,
          zIndex: 3,
          position: 'absolute',
          backgroundColor: `${theme.palette.grey[700]}80`,
          // backgroundColor: 'transparent',
          border: 'white solid 1px',
          color: theme.palette.common.white,
          cursor: 'pointer',
        }}
        onClick={()=>{
          navigate(`/briefs/${briefId}`)
        }} 
      >
          <Typography 
            variant='subtitle1' 
            sx={{
              color: theme.palette.common.white,
              lineHeight: '8px',
              fontSize: {
                xs: '20px',
                sm: '16px',
                md: '16px',
                lg: '12px',
              },
            }}
          >
            {briefTitle}
          </Typography>
      </Label>
      <Label // rate top right corner
        sx={{
          top: {
            xs: 34,
            sm: 24,
          },
          right: 16,
          zIndex: 3,
          position: 'absolute',
          backgroundColor: 'transparent',
          // color: theme.palette.common.white,
        }}
      >
          <Typography 
            variant='caption' 
            sx={{
              color: theme.palette.common.white,
              lineHeight: '8px',
              fontSize: {
                xs: '20px',
                sm: '16px',
                md: '16px',
                lg: '12px',
              },
              textShadow: theme.shadows[4],
            }}
          >
            ${fCurrencyCents(inputBrief.rate)}
          </Typography>
      </Label>
      <Label
        sx={{
          top: {
            xs: 234,
            sm: 134,
            md: 134,
          },
          right: 24,
          zIndex: 2,
          position: 'absolute',
          // backgroundColor: `${theme.palette.grey[700]}80`,
          backgroundColor: 'transparent',
          // border: 'white solid 1px',
          color: theme.palette.common.white,
          textShadow: theme.shadows[4],
        }}
      >
          <Typography 
            variant='subtitle1'      
            sx={{
              color: theme.palette.common.white,
              lineHeight: '8px',
              fontSize: {
                xs: '20px',
                sm: '16px',
                md: '16px',
                lg: '12px',
              },
            }}
          >
            {briefCategory}
          </Typography>
      </Label>

      <Box 
        sx={{
          pt: 3,
          width: '100%',
          zIndex: 2,
        }}
      >
        <Stack direction='row' justifyContent='space-between' width= '100%' sx={{cursor: 'default'}}>
          <Stack direction='column' >

            <Typography 
              variant='subtitle2'          
              noWrap
              sx={{
                color: theme.palette.text.primary,
                maxWidth: '155px',
                // lineHeight: '12px',
                textOverflow: 'ellipsis',
                cursor: 'default',
              }}
            >
              {item?.title}
            </Typography>
            <Typography 
              variant='caption'
              sx={{
                maxWidth: '155px',
                color: theme.palette.text.primary,
                cursor: 'default',
              }}
            >
              {item?.artist.name}
            </Typography>
          </Stack>
          <Typography 
              variant='subtitle2'        
              sx={{ 
                display: 'flex', 
                alignItems: 'flex-end', 
                textAlign: 'end',
                // justifyContent: 'flex-end', 
                // whiteSpace: 'nowrap'
                // minWidth: 'fit-content',
              }}
          >
            {fToNow(createdAt).replace(/^about\s/, '')}
          </Typography>
          
        </Stack>


        <Divider  sx={{pt: 1}} variant='fullWidth'/>
        <Box 
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            py: 1,
          }}
        >
          {(inputBrief.status === 'sold' && item?.multitrackUrl === '')&&
            <Button variant='outlined'
              onClick={()=>{
                if (multisUploadType === 'server'){
                  setMultisInfo({
                    title: item?.title,
                    _id: item?._id,
                    multitrackUrl: item?.multitrackUrl,
                  })
                  setOpenMultisUploadDrawer(true)
                } else {
                  window.open(isAA() ? `https://audioarsenal.wetransfer.com/?title=${item?._id}&msg=${item?.title}` : `https://trackbase.wetransfer.com/?title=${item?._id}&msg=${item?.title}`, '_blank')
                }
        
              }}
              color='error'
              size='small'
              // fullWidth
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                px: 1,
                m: 0,
                // width: '100%',
              }}
            >
              <Typography variant='body' color='info'>
                Upload Multis ASAP
              </Typography>
            </Button>
          }
          {(inputBrief?.feedback.length !== 0 && (inputBrief?.status === 'pass' || inputBrief?.status === 'revise')) ?
            <Button variant='text'
              onClick={()=>{
                setShowFeedback(true)
              }}
              color='info'
              size='small'
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                p: 0,
                m: 0,
              }}
            >
              <Typography variant='body' color='info'>
                Show Feedback
              </Typography>
            </Button> :
            <Iconify
            icon="eva:close-fill"
            // onClick={()=>{setDeleteWarning(true)}}
            onClick={(inputBrief?.status !== 'pass' && inputBrief?.status !== 'sold') ? ()=>{setDeletePitchWarning(true)} : null}
            sx={{
              display: 'flex',
              opacity: (inputBrief?.status !== 'pass' && inputBrief?.status !== 'sold') ? 1 : 0,
              width: 24,
              height: 24,
              borderRadius: '50%',
              transition: 'all .2s ease-in-out',
              cursor: (inputBrief?.status !== 'pass' && inputBrief?.status !== 'sold') ? 'pointer' : 'default',
              color: `${theme.palette.text.primary}40`,
              '&:hover': {
                color: theme.palette.error.main,
              }
            }}
          /> 
          }
          <Typography 
            variant='subtitle2'          
            noWrap
            onClick={()=>{
              if (pitchPill.status === 'Revise'){
                navigate(`/editsong/${item?._id}?type=revisepitch`)
              }
            }}
            sx={{
              // color: theme.palette.text.primary,
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              color: pitchPill.status !== 'revise' ? theme.palette.common.white : pitchPill.color,
              cursor: pitchPill.status === 'Revise' ? 'pointer' : 'default',
              px: 2,
              textShadow: theme.shadows[15],
              borderRadius: 2,
              border: pitchPill.status !== 'revise' ? 'none' : `${pitchPill.color} solid 1px`,
              backgroundColor: pitchPill.status !== 'revise' ? pitchPill.color : 'none',
              // lineHeight: '22px',
              textOverflow: 'ellipsis',
            }}
          >
            {pitchPill?.status?.charAt(0).toUpperCase().concat(pitchPill?.status?.slice(1))}
          </Typography>
        </Box>
      </Box>
      {(inputBrief?.feedback.length > 0 && showFeedback) &&
      <Box 
        onClick={()=>{setShowFeedback(false)}}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: alpha(theme.palette.text.primary, .95),
          borderRadius: 2,
          zIndex: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 3,
          cursor: 'pointer',
        }}
      >
            <Stack spacing={2}>
              <Typography variant='caption2' sx={{color: 'white'}}>{inputBrief?.feedback[latestFeedback-1]?.message}</Typography>
              <Button variant='contained' color='error'
                sx={{display: pitchPill.status !== 'Pass' ? 'inherit' : 'none'}}
                onClick={()=>{
                  navigate(`/editsong/${item?._id}?type=revisepitch`)
                }}
              >Upload Revision</Button>
            </Stack>
            <Stack direction='row' justifyContent='flex-end'>
              <Typography variant='caption' sx={{color: 'white'}}>{convertDate(inputBrief?.feedback[latestFeedback-1]?.date)}</Typography>
            </Stack>
      </Box>
      }
    </Box>
  )

}
