import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from 'axios';

import { Box, Button, Container, Typography, DialogActions, Dialog, Divider, Stack, TextField } from '@mui/material';
// components
import { Image } from '@mui/icons-material';
import Iconify from '../components-default/iconify/Iconify';
import Scrollbar from '../components-default/scrollbar';

import isAA from './isAA';

// ----------------------------------------------------------------------

DisplayEditComponent.propTypes = {

  title: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,

};

export default function DisplayEditComponent({
  title,
  value,
  setValue,
}) {

  const theme = useTheme()

  const [displayType, setDisplayType] = useState('display')

  return (
      <Stack direction='column' spacing={1} width='100%'>
        <Stack direction='row' spacing={1} 
          width='100%' justifyContent='flex-start'
        >
          <Typography variant='subtitle2'>
            {title}
          </Typography>
        </Stack>
        <Stack direction='row' spacing={2}
          width='100%'
        >
          {displayType === 'display' ? 
          <>
            <Typography variant='body'
              sx={{
                borderRadius: 1,
                border: `${theme.palette.divider} solid 1px`
              }}
            >
              {value}
            </Typography>
            <Iconify icon='material-symbols:edit'
              sx={{
                cursor: 'pointer',
              }}              
              onClick={()=>{
                setDisplayType('edit')
              }}
            />
          </>
          :
          <>
            <TextField 
              size='small'
              value={value}
              onChange={(e)=>{
                setValue(e.target.value)
              }}
            />
            <Iconify icon='material-symbols:save'
              sx={{
                cursor: 'pointer',
              }}
              onClick={()=>{
                console.log('edit')
              }}
            />
          </>
          
          }
        </Stack>
      </Stack>

  );
}

// ----------------------------------------------------------------------

