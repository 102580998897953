import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  Stack,
} from '@mui/material';


import MailRoundedIcon from '@mui/icons-material/MailRounded';
import CheckIcon from '@mui/icons-material/Check';
import { useMutation, useQuery } from "@apollo/client";

import axios from '../utils/axios';
import { GET_USER_DOCS_BY_ID_M, GET_ARTIST_SONGS_BY_USER_ID, DELETE_ARTIST_BY_ID } from '../graphql/mutations'

import { UploadAvatar, Upload, UploadBox } from './upload';
import Image from './image';
import TextfieldDisplay from './TextfieldDisplay';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


EditArtistDialog.propTypes = {
    editArtistDialogOpen: PropTypes.bool,
    setEditArtistDialogOpen: PropTypes.func,
    userr: PropTypes.string,
    refetch: PropTypes.func,
    currentName: PropTypes.string,
    currentBio: PropTypes.string,
    currentArtwork: PropTypes.string,
    artistId: PropTypes.string,
    setToast: PropTypes.func,
    // editArtistInfo: PropTypes.object,
    // toast: PropTypes.object,
  };



export default function EditArtistDialog({editArtistDialogOpen, setEditArtistDialogOpen, userr, refetch, currentName, currentBio, currentArtwork, artistId, setToast }) {

  const theme = useTheme();
  
  const [artistInfo, setArtistInfo] = useState({name: currentName, artwork: '', bio: currentBio})
  const [fileHolder, setFileHolder] = useState('')

  const [loading, setLoading] = useState(false)
  const [deleteWarning, setDeleteWarning] = useState(false)

  const [artState, setArtState] = useState('current')

  const [deleteArtistById] = useMutation(DELETE_ARTIST_BY_ID);

  async function deleteArtist(){
    const deleteThatArtist = await deleteArtistById({ variables: { userId: userr, artistId: artistId } }); // eslint-disable-line
    refetch();
    handleClose();
    setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Artist "${currentName}" deleted`, duration: 4000})
    console.log(deleteThatArtist)
  }

  const handleClose = () => {
    setEditArtistDialogOpen(false);
    setArtistInfo({name: currentName, artwork: '', bio: currentBio})
    setFileHolder('');
    setDeleteWarning(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    submitArtist();
  }


  function successCallback() {
    console.log('Artist updated successfully')
    // setCardState('add')
    setTimeout(()=>{
      setLoading(false)
      console.log('refetching')
      refetch()
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Artist successfully updated`, duration: 4000})

      handleClose()
    }, 1000)
    // refetch()
      // navigate('/uploadsuccess');
  }


  async function submitArtist(){

    const coverBlob = fileHolder !== '' ? new Blob([fileHolder, { type: fileHolder?.type }]) : '';
    const formData = new FormData();
    
    formData.append('_id', artistId);
    formData.append('name', artistInfo.name);
    formData.append('bio', artistInfo.bio);
    formData.append('currentArtwork', currentArtwork);
    formData.append('userId', userr)
    if (fileHolder !== ''){
      formData.append('fileType', fileHolder?.type);
      formData.append('file', coverBlob, fileHolder.name);
    }

    try {

      const res = await axios.post(`${process.env.REACT_APP_SERVER}/editartist`, formData, {  // eslint-disable-line
          headers: {
              'Content-Type':'multipart/form-data',
          },
      }).then(response => {
        successCallback();
      });
    } catch (err) {
      console.log(err.response.data)
  }              


  }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setFileHolder(file);
        // setValue('cover', newFile, { shouldValidate: true });
      }
    },
    [setFileHolder]
  );



  return (
    <div>
      <Dialog
        open={editArtistDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll='body'
        onClose={()=>{handleClose()}}
        sx={{
            height: '100%',
            // minWidth: '100px',
            paper: {
              // width: '500px',
            }
        }}
      >
        <Box 
          sx={{
            width: {
              sm: '300px',
              md: '500px',
            }, 
          }}
        >

        <DialogTitle 
          id="edit-artist-title"
          sx={{
            pb: 0,
          }}
        >
          Edit Artist
        </DialogTitle>

        <DialogContent scroll='body' >
        <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              minWidth: '100px',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Artist Name</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              fullWidth
              sx={{ }} 
              value={artistInfo.name} 
              onChange={(e)=>{
                setArtistInfo({...artistInfo, name: e.target.value})
              }}
            />
  
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Artist Bio</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              fullWidth
              placeholder='Optional'
              // sx={{ }} 
              // multiline={3}
              value={artistInfo.bio} 
              onChange={(e)=>{
                setArtistInfo({...artistInfo, bio: e.target.value})
              }}
            />
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              px: 3,
              pb: 3,
              // border: 'red solid 1px',
            }}
            >
              <Box sx={{display: 'flex', gap: 3}}>
                <Typography variant='subtitle1' sx={{display:'flex', alignItems: 'center'}}>Artwork</Typography>
                <Button 
                  variant='text' 
                  size='small'
                  onClick={()=>{
                    if (artState === 'new'){
                      setFileHolder('')
                      setArtState('current')
                    } else {
                      setArtState('new')
                    }
                  }}
                  sx={{
                    height: '50%',
                    // textTransform: 'none',
                  }}
                  >
                    {artState === 'current' ?
                      "replace artwork" :
                      "clear selection"
                    }
                </Button>
              </Box>
            {((fileHolder === '' && !currentArtwork) || (artState === 'new' && fileHolder === '')) ?
              <Upload
                multiple
                accept={{ 'image/*': [] }}
                simple
                files={artistInfo.cover}
                onDrop={handleDrop}
                sx={{
                  // height: '50px',
                }}
                // onDelete={handleRemoveFile}
            /> : 
            <Box>
              <Image 
                alt="cover" 
                src={fileHolder?.preview || currentArtwork} 
                sx={{ 
                  // height: 360, 
                  borderRadius: 2, 
                }} 
                ratio='16/9'
              />
            </Box>
          }
          </Box>


        </DialogContent>

        <DialogActions sx={{justifyContent: 'space-between'}} >
          <Button 
            size='medium' 
            color='error' 
            variant="text" 
            onClick={()=>{setDeleteWarning(true)}} 
            sx={{px: "16px", py: "7px",}}
          >
            Delete Artist
          </Button>
          <Stack direction='row' spacing={2}>

            <Button 
              size='medium' 
              color='inherit' 
              variant="outlined" 
              onClick={handleClose} 
              sx={{px: "16px", py: "7px"}}
            >
              Cancel
            </Button>

            <Button 
              size='medium' 
              variant="contained" 
              onClick={handleSubmit} 
              sx={{px: "16px", py: "7px"}}
              disabled={loading}
            >
              Update
            </Button>
          </Stack>
        </DialogActions>
        </Box>
      {deleteWarning && 
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: 100,
            height: '100%',
            width: '100%',
            p:6,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: alpha(theme.palette.error.darker, .95),
          }}
        >
          <Stack direction='column' spacing={4}>
            <Typography variant='h5' sx={{color: 'white'}} textAlign='center'>
              This will delete &apos;<b>{currentName}</b>&apos; from your profile.
            </Typography>
            <Typography variant='body' sx={{color: 'white', pb: 5}} textAlign='left'>
              Any songs previously uploaded with this artist name will remain unchanged.
            </Typography>
            <Button variant='contained' color='error' 
              onClick={async ()=>{
                console.log('hey')
                deleteArtist()
              }}>
              Delete Artist
            </Button>
            <Button variant='outlined' color='error' 
              onClick={()=>{setDeleteWarning(false)}}
              sx={{
                color: 'white',
                border: 'white solid 1px',
                '&:hover': {
                  border: 'white solid 1px',

                }
              }}  
            >
              Cancel
            </Button>
          </Stack>
        </Box>
      }
      </Dialog>
    </div>
  );
}
