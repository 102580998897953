import PropTypes from 'prop-types';
import { useMemo, useState, useEffect } from 'react';

import { AudioContext } from './audio-context';



// ----------------------------------------------------------------------



export function AudioProvider({ children }) {

    const [src, setSrc] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [controlMessage, setControlMessage] = useState('');

    const value = useMemo(() => ({ 
      src, 
      setSrc, 
      isPlaying, 
      setIsPlaying,
      controlMessage,
      setControlMessage
    }), [src, isPlaying, controlMessage]);

    useEffect(() => {
      // console.log('IS PLAYING: ', isPlaying);
        // console.log('audioSrc: ', audioSrc);
    }, [isPlaying]);

  return <AudioContext.Provider value={value}>{children}</AudioContext.Provider>;
}

AudioProvider.propTypes = {
  children: PropTypes.node,
};
