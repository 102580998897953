import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, TextField, InputAdornment, IconButton, Divider } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';

// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';

// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import isAA from '../components-custom/isAA';

// ----------------------------------------------------------------------

export default function ClientPricesPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id


  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);


  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]); // eslint-disable-line

  const [axiosResults, setAxiosResults] = useState('')
  const [showAddSubGenre, setShowAddSubGenre] = useState(false)
  const [showAddGenre, setShowAddGenre] = useState(false)
  const [saveFlagTarget, setSaveFlagTarget] = useState(null)
  const [editFieldTarget, setEditFieldTarget] = useState(null)
  const [editFieldValue, setEditFieldValue] = useState('')

  const [priceList, setPriceList] = useState(
    ['hey']
  )  
  
  const [originalPriceList, setOriginalPriceList] = useState(
    ['hey']
  )


  const [selectedId, setSelectedId] = useState(null); // eslint-disable-line
  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});



  async function getPrices(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/stripeprices`, {  // eslint-disable-line
        orgId: user?.organization,
        method: 'get',
        platform: isAA() ? 'AA' : 'TB',
      }).then(response => {
        console.log(response)
        setPriceList(response.data.priceList.data)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  async function saveCategories(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/stripeprices`, {  // eslint-disable-line
        orgId: user?.organization,
        method: 'add',
        priceList,
        platform: isAA() ? 'AA' : 'TB',
      }).then(response => {
        // console.log(response)
        if (response.status===200){
          setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Price Added`, duration: 4000})
        }
      });
    } catch (err) {
      console.log(err)
    }              
  }


  useEffect(() => {
    getPrices()
  },[]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      // yes
    }
  },[axiosResults]) // eslint-disable-line


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Prices</title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              { name: 'Categories' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              pb: 8,
              // border: 'red solid 1px',
              
            }}
          >
          {priceList.map((item, itemIndex) => { // eslint-disable-line
            return <Stack direction='column' justifyContent='flex-start' key={`item-${itemIndex}`}
              sx={{
                // border: 'blue solid 1px',
                width: '800px',
                pb: 6,
                borderRadius: 1,
                // opacity: (editFieldTarget !== null && editFieldTarget !== item.id || saveFlagTarget === item.id) ? '1' : ((editFieldTarget === item.id) ? '1' : .2) // eslint-disable-line
              }}
              >
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' width='100%'
                  sx={{
                    pb: 2,
                  }}
                >
                  <Stack direction='row' spacing={1} alignItems='center' >
                    <Typography variant='caption' 
                      sx={{
                        display: editFieldTarget !== item.genre ? 'inherit' : 'none',
                        width: '250px',
                        justifyContent: 'flex-start'
                      }}
                    >
                      {item?.id}
                    </Typography>
                    <Typography variant='h5'
                      sx={{
                        display: editFieldTarget !== item.id ? 'inherit' : 'none',
                        width: '250px',
                      }}
                    >
                      {item?.nickname || 'Edit Nickname'}
                    </Typography>
                    <TextField value={editFieldValue} size='large' variant='standard' autoComplete='off'
                        sx={{
                          display: editFieldTarget === item.id ? 'inherit' : 'none',
                          width: '250px',
                        }}
                        onChange={(e)=>{
                          setEditFieldValue(e.target.value)
                        }}
                        onKeyDown={(e)=>{
                          if (e.key === 'Enter'){
                            // if (categories[itemIndex].genre === editFieldValue){
                            //   setEditFieldTarget(null)
                            //   setEditFieldValue('')
                            // } else {
                            // const newCategories = [...categories]
                            // newCategories[itemIndex].genre = editFieldValue
                            // setCategories(newCategories)
                            // }
                          }
                          if (e.key === 'Escape'){
                            setSaveFlagTarget(null)
                            setEditFieldTarget(null)
                            setEditFieldValue('')
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position="end" 
                                onClick={()=>{
                                  if (priceList[itemIndex].nickname === editFieldValue){
                                    setEditFieldTarget(null)
                                    setEditFieldValue('')
                                  } else {
                                    const newPriceList = [...priceList]
                                    newPriceList[itemIndex].nickname = editFieldValue
                                    setPriceList(newPriceList)
                                    setEditFieldTarget(null)
                                    setEditFieldValue('')
                                    setSaveFlagTarget(itemIndex)
                                  }
                                }}
                                sx={{cursor: 'pointer'}}
                              >
                                <Iconify icon='iconamoon:check-duotone'/>
                              </InputAdornment>

                              <InputAdornment position="end" 
                                onClick={()=>{
                                  setEditFieldTarget(null)
                                  setEditFieldValue('')
                              }}
                                sx={{cursor: 'pointer'}}
                              >
                                <Iconify icon='iconamoon:close'/>
                              </InputAdornment>
                            </>
                          ),
                        }}
                    />

                    <Iconify icon='material-symbols:edit-outline' 
                      sx={{
                        cursor: 'pointer',
                        transition: 'all .2s ease-in-out',
                        opacity: .1,
                        display: editFieldTarget !== item.id ? 'inherit' : 'none',
                        '&:hover':{
                          opacity: 1,
                        }
                      }}
                      onClick={()=>{
                        setEditFieldTarget(item.id)
                        setEditFieldValue(item.nickname)
                      }}
                    />
                    <Button variant='contained' size='small'
                      sx={{
                        display: saveFlagTarget !== itemIndex ? 'none' : 'inherit',
                      }}
                    >
                      Save
                    </Button>
                  </Stack>
                </Stack>
                <Divider sx={{width: '100%'}}/>
            </Stack>
          }
          )}
          </Box>

        </Grid>
      </Container>
      <Footer />

      <CustomToast toast={toast} setToast={setToast}/>

    </>
  );
}
