export const genreList = [
    'acoustic',
    'ambient',
    'ballad',
    'blues',
    'cinematic',
    'classical',
    'corporate',
    'country',
    'electronic',
    'experimental',
    'folk',
    'funk',
    'gospel',
    'hiphop',
    'holiday',
    'indie',
    'jazz',
    'kids',
    'metal',
    'new age',
    'pop',
    'r&b',
    'reggae',
    'rock',
    'soul',
    'soundtrack',
    'world',
];

export const subGenreList = {
    country: [
        'bluegrass',
        'country pop',
        'bro country',
        'western',
        'traditional country',
        'country rock',
    ],
    electronic: [
        'dubstep',
        'drum and bass',
        'electro',
        'house',
        'future bass',
        'reggaeton',
        'synthwave',
        'techno',
        'trance',
        'tropical house',
        '8 bit',
    ],
    hiphop: [
        'trap',
        'old school',
        'lofi',
    ],
    cinematic: [
        'epic',
        'underscore',
        'electronic',
        'dramatic',
        'emotional',
    ],
    rock: [
        'modern rock',
        'classic rock',
        'alternative',
        'surf',
        'southern rock',
    ],
}

export const moodList = [
    'angry',
    'aggressive',
    'bright',
    'calm',
    'chaotic',
    'chill',
    'dark',
    'dreamy',
    'epic',
    'fun',
    'gritty',
    'happy',
    'hopeful',
    'fantasy',
    'inspiring',
    'intense',
    'live',
    'novelty',
    'organic',
    'pleasant',
    'quirky',
    'reflective',
    'romantic',
    'rowdy',
    'sad',
    'scary',
    'suspenseful',
    'uncomfortable',
    'upbeat',
    'weird',
];

export const instrumentList = [
    'accordian',
    'banjo',
    'bass',
    'beats',
    'bells',
    'drums',
    'fx',
    'electric guitar',
    'acoustic guitar',
    'harmonica',
    'horns',
    'organ',
    'pad',
    'percussion',
    'piano',
    'rhodes',
    'strings',
    'synth',
    'ukulele',
    'wind',
    'whistle',
    'vocals',
];

export const themeList = [
    'action',
    'adventure',
    'danger',
    'game',
    'graduation',
    'information',
    'love',
    'meditation',
    'nostalgic',
    'retro',
    'sports',
    'spa',
    'war',
    'wedding',

];

