import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, Divider } from '@mui/material';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';


// graphql
import axios from 'axios';
import { useMutation, useQuery } from "@apollo/client";
import queryString from 'query-string';
import { GET_USER_DOCS_BY_ID, GET_ALL_DOCS, GET_SUBMITTED_DOCS } from '../graphql/queries'

// _mock_

import { useDispatch, useSelector } from '../redux/store';
import { addToCart } from '../redux/slices/product';


// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardAdmin from '../sections/@dashboard/general/file/AudioFileCardAdmin'


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import CartWidget from '../sections/@dashboard/e-commerce/CartWidget';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import {genreList, instrumentList, moodList, themeList} from '../components-custom/TagList';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import UserInfoDrawer from '../components-custom/UserInfoDrawer';


// ----------------------------------------------------------------------

export default function AdminInventoryPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const dispatch = useDispatch();

  const { checkout } = useSelector((state) => state.product);

  // console.log('User: ', user)
  const [openUserDrawer, setOpenUserDrawer] = useState(false)
  const [userDrawerInfo, setUserDrawerInfo] = useState({})

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);

  const [sortMethod, setSortMethod] = useState('date-descending')
  const [pageLoaded, setPageLoaded] = useState(false)

  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});
  const [ cartItems, setCartItems] = useState();

  const [selectedId, setSelectedId] = useState(null);

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});


  const { loading, error, data = {getSubmittedDocs: []}, refetch } = useQuery(GET_SUBMITTED_DOCS, {
    fetchPolicy: 'network-only'
  });

  // console.log('Docs: ', data?.getSubmittedDocsByOwnerId)
  // console.log('USER: ', user)

  function doRefetch() {
    refetch();
    console.log('refetched')
  }

  function scrollToFile() {
    if (selectedId !== null) {
      const pane = document.getElementById(selectedId)
      setTimeout(()=> {pane.scrollIntoView({ behavior: 'smooth', block: 'center' })}, 20)
    }
  }

  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };

  useEffect(() => {
    if (selectedId !== null && isPlaying) {
      scrollToFile()
    }
  }, [controlMessage]); // eslint-disable-line
  

  useEffect(() => { // eslint-disable-line
    if (data?.getSubmittedDocs.length > 0) {

      if (sortMethod === 'date-ascending'){
        console.log('ASCEND INTIAL')
        const sortedFiles = data?.getSubmittedDocs.sort((a, b) => new Date (a.created_at) - new Date(b.created_at))
        setDisplayFiles(sortedFiles || [])
        setPageLoaded(true)
      }
      
      if (sortMethod === 'date-descending'){
        console.log('DESCEND INITIAL')
        const sortedFiles = data?.getSubmittedDocs.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))
        setDisplayFiles(sortedFiles || [])
        setPageLoaded(true)
      }

      // setDisplayFiles(data?.getSubmittedDocs || []);

      const cart = data?.getSubmittedDocs.filter((doc) => doc.inCart?._id === userr)
      setCartItems(cart?.length || 0)
      const tempFilterList = [];
      data?.getSubmittedDocs.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
              doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.mood.length > 0) {
              doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.instrument.length > 0) {
              doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
      })
      setFilterList(tempFilterList)
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { //eslint-disable-line

    if (pageLoaded && sortMethod === 'date-ascending'){
      console.log('ASCEND SELECT')
      const sortedFiles = data?.getSubmittedDocs.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))
      return setDisplayFiles(sortedFiles)
    }

    if (pageLoaded && sortMethod === 'date-descending'){
      console.log('DESCEND SELECT')
      const sortedFiles = data?.getSubmittedDocs.sort((a, b) => new Date (a.created_at) - new Date(b.created_at))
      return setDisplayFiles(sortedFiles)
    }

  },[sortMethod]) // eslint-disable-line


  useEffect(() => {

    // console.log('Filter: ', filter)

    const filteredFiles = [];

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('play-pause')
      }
    
      // if (sortMethod === 'date-ascending'){
      //   console.log('ASCEND FILTER')
      //   const sortedFiles = data?.getSubmittedDocs.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))
      //   setDisplayFiles(sortedFiles)
      // }
  
      // if (sortMethod === 'date-descending'){
      //   console.log('DESCEND FILTER')
      //   const sortedFiles = data?.getSubmittedDocs.sort((a, b) => new Date (a.created_at) - new Date(b.created_at))
      //   setDisplayFiles(sortedFiles)
      // }

      // setDisplayFiles(data?.getSubmittedDocs)


    } else {
      data?.getSubmittedDocs.map(doc => { // eslint-disable-line
        if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
          return filteredFiles.push(doc)
        }
      })
      setDisplayFiles(filteredFiles)
    }
    // Object.values(doc.tags).some(x => filter.some(f => x.includes(f))))   

  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory </title>
      </Helmet>
      <Box
        sx={{ 
          pt: 6, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                // name: 'Dashboard',
                // href: null,
              },
              // { name: 'Dashboard' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between'>

          <Typography variant="h4" sx={{ mb: 5 }}>
          All Inventory
          </Typography>
          <Select  
            // defaultValue={sortMethod}
            value={sortMethod}
            sx={{
              display: 'flex',
              height: '36px',
              width: '200px',
            }}
            onChange={(e)=>{setSortMethod(e.target.value)}}
          >
            <MenuItem value='date-descending'>
              Sort by: Date <Iconify icon='bx:down-arrow' sx={{height: '12px'}}/>
            </MenuItem>
            <MenuItem value='date-ascending'>
              Sort by: Date <Iconify icon='bx:up-arrow' sx={{height: '12px'}}/>
            </MenuItem>
          </Select>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid  item xs={12} sm={12} md={9} lg={9} xl={9} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
            >
            {loading ? <LoadingScreen/> : 
            <Box onKeyDown={handleKeyDown}>
            {displayFiles.length > 0 ? 
                displayFiles.map((doc, index) => (
                  (!doc.pass.includes(userr) || user.role === 'admin') &&
                  <AudioFileCardAdmin
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    data={data}
                    filter={filter}
                    dispatch={dispatch}
                    addToCart={addToCart}
                    refetch={refetch}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    openUserDrawer={openUserDrawer}
                    setOpenUserDrawer={setOpenUserDrawer}
                    userDrawerInfo={userDrawerInfo}
                    setUserDrawerInfo={setUserDrawerInfo}
                    inventoryPage
                    favorites
                    passable
                  />
                ))  :
                <Typography variant="h5" sx={{ mb: 0 }}>
                  No songs currently available
                </Typography>
            }
            </Box>
              }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} 
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: 7,
              mt: 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            {loading ? <LoadingScreen/> : 
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mr: 4, mt: 0, gap: 3 }}>
              <Typography variant="h5" sx={{ mb: 0 }}>
                Filters
              </Typography>
              <Button 
                variant="text" 
                onClick={() => {
                  setFilter([])
                  setFilteredDisplayFiles([])
                }}
                sx={{ m: 0, p: 0, textTransform: 'none', fontSize: '12px', fontWeight: '200', color: theme.palette.primary.darker}}
                >
                clear filters
              </Button>
            </Box>
            }
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'space-between',
                lg: 'space-between',
                xl: 'space-between',
              },
              m: 0,
              p: 0,
              pt: 2,
              pr: 1,
              pb: 3,
            }}>
              {/* {filterList.map((filterItem) => <Chip */}
              <Typography variant='caption' width='100%' fontWeight='bold'>
                Genre
              </Typography>

              {filterList.filter(value => genreList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }

              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Instrument
              </Typography>

              {filterList.filter(value => instrumentList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Mood
              </Typography>

              {filterList.filter(value => moodList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
            </Box>
          </Grid>

        </Grid>
      </Container>
      <UserInfoDrawer 
        openUserDrawer={openUserDrawer} 
        setOpenUserDrawer={setOpenUserDrawer}
        userDrawerInfo={userDrawerInfo}
        setUserDrawerInfo={setUserDrawerInfo}
      />
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        audioBarFavorite={audioBarFavorite}
        setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
