import PropTypes from 'prop-types';

import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';


import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


// @mui
import {  useTheme } from '@mui/material/styles';
import { Box, Button, Divider, Grid, Link, Stack, Typography } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';


import { useMutation, useQuery } from "@apollo/client";

// routes
import { GET_USER_PROFILE_STATS } from '../../../../../../graphql/queries'
import { GET_BRIEF_BY_ID_M, GET_RECENT_USER_PITCHES_BY_ID_M } from '../../../../../../graphql/mutations'


import { useAuthContext } from '../../../../../../auth/useAuthContext';
import axios from '../../../../../../utils/axios';
import { fCurrencyCents } from '../../../../../../utils/formatNumber';

//
import ProfileAbout from './ProfileAbout';
import AddArtistDialog from '../../../../../AddArtistDialog';
import WalkthroughDialog from '../../../../../WalkthroughDialog';
import CreateOrganizationDialog from '../../../../../CreateOrganizationDialog';
import AccountDialog from '../../../../../AccountDialog';
import EditArtistDialog from '../../../../../EditArtistDialog';
import PaymentDialog from '../../../../../PaymentDialog';

import Iconify from '../../../../../../components-default/iconify/Iconify';
import ArtistProfileCard  from '../../../blog/ArtistProfileCard'
import AAWidgetCard from '../../../../../AAWidgetCard';
import SongPitchedCard from '../../../../../SongPitchedCard';
import SongNoPitchesCard from '../../../../../SongNoPitchesCard';
import CustomToast from '../../../../../CustomToast';
import isAA from '../../../../../isAA';

import AASubscriptionLink from '../../../../../AASubscriptionLink';
import TBSubscriptionLink from '../../../../../TBSubscriptionLink';
import { AudioContext } from '../../../../../audio/audio-context';
import MultisUploadDrawer from "../../../../../MultisUploadDrawer";

// ----------------------------------------------------------------------

ProducerProfile.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
  data: PropTypes.array,
  refetch: PropTypes.func,
  accountDialogOpen: PropTypes.bool,
  setAccountDialogOpen: PropTypes.func,
  walkthroughDialogOpen: PropTypes.bool,
  setWalkthroughDialogOpen: PropTypes.func
};


export default function ProducerProfile({ info, posts, data, refetch, accountDialogOpen, setAccountDialogOpen, walkthroughDialogOpen, setWalkthroughDialogOpen }) {

  const { user } = useAuthContext();
  // console.log('USER: ', user)


  const userr = user?._id || user?._id
  // console.log('PROFILE USER: ', user)

  const theme = useTheme();
  const navigate = useNavigate();

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);
  

  const [getRecentUserPitchesById] = useMutation(GET_RECENT_USER_PITCHES_BY_ID_M);
  

  const [avatarHolder, setAvatarHolder] = useState(null)
  const [artistState, setArtistState] = useState([{alias: {name: '', bio: '', artwork: '', _id: '123'}}])
  const [userBalance, setUserBalance] = useState()

  const [artistDialogOpen, setArtistDialogOpen] = useState(false)
  const [organizationDialogOpen, setOrganizationDialogOpen] = useState(false)
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});
  const [cancelWarning, setCancelWarning] = useState(false)

  const [accountMessage, setAccountMessage] = useState('')

  const [allPitchesState, setAllPitchesState] = useState()
  const [openMultisUploadDrawer, setOpenMultisUploadDrawer] = useState(false)
  const [multisInfo, setMultisInfo] = useState({
    title: '',
    _id: '',
    multitrackUrl: '',
  })


  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
  );

  const { loading: statsLoading, error: statsError, data: statsData = {getUserProfileStats: []}, refetch: statsRefetch } = useQuery(GET_USER_PROFILE_STATS, {
    variables: { id: userr },
    fetchPolicy: 'network-only'
  });

  const [getBriefById] = useMutation(GET_BRIEF_BY_ID_M);


  async function getRecentPitches(){
    const allPitches = await getRecentUserPitchesById({ variables: { userId: userr } }); // eslint-disable-line
    setAllPitchesState(allPitches?.data?.getRecentUserPitchesById)
  }

  async function getUserBalance(){
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/userbalance`, {  // eslint-disable-line
      userId: user?._id
    })
    setUserBalance(res?.data?.balance)
    // console.log('BALANCE: ', res.data.balance)
  }

  async function handleCancelSubscription(){
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/cancelsubscription`, {  // eslint-disable-line
      userId: user?._id,
      subscriptionId: user?.subscriptionId
    })
    setAccountMessage(res?.data)
    refetch()
    // console.log(res)
  }

  useEffect(()=>{
    getRecentPitches();
    getUserBalance();
  },[]) // eslint-disable-line


  useEffect(()=>{
    if (data) {
      setArtistState(data)
    }
  }, [data])

  const handleUpload = async () => {

    const coverBlob = avatarHolder !== null ? new Blob([avatarHolder, { type: avatarHolder.type }]) : '';
    const formData = new FormData();
    
    formData.append('fileType', avatarHolder !== null ? avatarHolder.type : '' );
    formData.append('userId', user._id);

    if (avatarHolder !== null) {
      formData.append('file', coverBlob, avatarHolder !== null ? avatarHolder.name : '');
    }
    
    // console.log('FORMDATA: ',formData)
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/uploadAvatar`, formData, {  // eslint-disable-line
            headers: {
                'Content-Type':'multipart/form-data',
            },
        }).then(response => {
          // successCallback();
          console.log(response)
        });
      } catch (err) {
          console.log(err.response.data)
      
    }
  }

  function handleCloseWalkthrough(){
    setWalkthroughDialogOpen(false)
  }

  useEffect(()=>{

    // console.log(statsData)

  }, [statsData])


  return (
    <>
    <Grid container  
     // page grid, left cards, right artist icons
      sx={{
        pb: 10,
        ml: 0,
        mr: 2,
        // border: 'red solid 1px'
      }}
      >
      <Grid item xs={12} md={12}
      // left side cards
        sx={{
          pl: 0,

        }}
      
      >
        <Grid container spacing={3}>
          {user?.plan === 'Free' &&
          <Grid item xs={6} sm={6} md={3}>
              <Button href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink(user?._id, user?.email)} color="success" variant='contained'
                sx={{
                  width: '100%',
                  height: '100%',
                  borderRadius: 2,
                  fontSize: '28px',
                  textAlign: 'center',
                }}
              >
                Subscribe
              </Button>
          </Grid>            
          }
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Songs Pitched' 
              total={statsData.getUserProfileStats[0]?.totalPitchedSongs || '-'} 
              statsLoading
              color='primary' 
              textColor={theme.palette.text.primary}
              cardColor={isAA() ? theme.palette.primary.light : theme.palette.primary.darker}
              darkColor={isAA() ? theme.palette.primary.dark : theme.palette.primary.dark}
              // icon="ant-design:send-outlined"
              />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Songs Sold' 
              total={statsData.getUserProfileStats[0]?.claimedSongs || '-'}
              statsLoading
              color='primary' 
              textColor={theme.palette.text.primary}
              cardColor={isAA() ? theme.palette.primary.main : theme.palette.primary.main}
              darkColor={isAA() ? theme.palette.primary.dark :theme.palette.primary.dark}
              // icon="ant-design:dollar-outlined"
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
              <AAWidgetCard 
                title='Balance' 
                total= {fCurrencyCents(userBalance)}
                money
                // totalPrefix='$'
                color='primary' 
                textColor={theme.palette.common.white}
                cardColor={user?.plan !== 'Free' ? theme.palette.success.dark : theme.palette.info.main}
                darkColor={user?.plan !== 'Free' ? theme.palette.success.darker : theme.palette.info.dark}
                // icon="ant-design:dollar-outlined"
              /> 
          </Grid>            
          {user?.plan !== 'Free' && 
          <Grid item xs={6} sm={6} md={3}>
              <AAWidgetCard 
                title='Upload Song' 
                onClick={()=>{navigate('/upload')}}
                // total={24} 
                color='info' 
                noTotal
                textColor={theme.palette.common.white}
                cardColor={isAA() ? theme.palette.text.primary : theme.palette.info.dark}
                darkColor={isAA() ? theme.palette.text.primary : theme.palette.info.main}
                icon="ant-design:cloud-upload-outlined"
              />
          </Grid>  
          }
          <Divider 
            sx={{
              width: '98%', 
              m: 3, 
              display: isAA() ? 'inherit' : 'none',
            }} 
          />
          <Typography variant='h4' display={isAA() ? 'inherit' : 'none'}
            sx={{
              p: 3,
              pb: 1, 
              pt: 1, 
            }}
          >
            {artistState.length === 0 ?
              'Artist' :
              'Artists'
            }
          </Typography>
          <Grid item xs={12} display={isAA() ? 'inherit' : 'none'}>
            <Grid container spacing={0} 
              sx={{
                // border: 'blue solid 1px',
                pl: 0,
                pb: 4,
              }}
            >
              {artistState.map((artist, index) => <Grid 
                    key={`artist${index}`} 
                    item 
                    xs={6} sm={6} md={2} 
                    sx={{
                      // border: 'red solid 1px',
                      p: {
                        xs: 2,
                        sm: 2,
                      },
                      pb: {
                        xs: 4,
                        sm: 2,
                        md: 2,
                        lg: 2,
                      },
                      display: 'flex', 
                      justifyContent: 'center', 
                      alignItems: 'center', 
                    }}
                  >
                  <ArtistProfileCard 
                    artist={artist} 
                    refetch={refetch}
                    onCardClick={()=>{navigate(`/inventory/${artist._id}?name=${artist.name}`)}}
                    sx={{opacity: .5}}
                    setToast={setToast}
                  />
                </Grid>
              )}
              <Grid 
                    item 
                    xs={6} sm={6} md={2} 
                    sx={{
                      p: {
                        xs: 2,
                        sm: 2,
                      },
                      pb: {
                        xs: 4,
                        sm: 2,
                        md: 2,
                        lg: 2,
                      },
                      display: 'flex', 
                      justifyContent: 'center', 
                    }}
                  >

                    <Box 
                      sx={{
                        display: 'flex', 
                        justifyContent: 'center', 
                        height: '100%',
                        width: '100%',
                        aspectRatio: '1/1',
                        borderRadius: '50%',
                        maxHeight: '200px',
                        maxWidth: '200px',
                      }}
                    >
                      <Button 
                        variant='text' 
                        // fullWidth 
                        onClick={()=>{setArtistDialogOpen(true)}}
                        sx={{
                          p: 0,
                          m: 0,
                          height: '100%',
                          width: '100%',
                          borderRadius: '50%',
                          // border: 'red solid 1px',

                        }}
                      >
                        <Box 
                          sx={{
                            p: 0,
                            m: 0,
                            // position: 'absolute',
                            height: '100%',
                            width: '100%',
                            borderRadius: '50%',
                            backgroundColor: theme.palette.background.paper,
                          }}
                        >
                          <Box 
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: 0,
                              justifyContent: 'center',
                              alignItems: 'center',
                              height: '100%',
                              width: '100%',
                              opacity: .5,
                              transition: 'all .2s ease-in-out',
                              borderRadius: '50%',
                              '&:hover': {
                                opacity: 1,
                                border: `${theme.palette.text.primary} solid 2px`,
                                
                              }
                            }}
                          >
                            <AddRoundedIcon 
                              sx={{
                                color: 'text.primary',
                                width: '35px',
                                height: '35px',
                              }}
                            />
                            <Typography 
                              variant='subtitle2' 
                              color='text.primary'
                            >
                              Add Artist
                            </Typography>

                          </Box>
                        </Box>
                      </Button>
                    </Box>
                </Grid>
            </Grid>
          </Grid>
          <Divider sx={{width: '98%', m: 3, mb: 0}}/>
          <Stack 
            direction='row' 
            spacing={2} 
            height='100%' 
            width='100%' 
            justifyContent='space-between' 
            alignItems='center'
            // border= 'pink solid 1px'
          >
              <Typography variant='h4' 
                sx={{
                  p: 3,
                  pr: 0,
                  width: 'fit-content'
                }}
              >
                Recent Pitches
              </Typography>
            <Box 
              sx={{
                // pt: 1.5, 
                display: 'flex', 
                alignItems: 'flex-start', 
                height: '100%', 
                // border: 'blue solid 1px',
              }}>
              <Link href={allPitchesState?.length !== 0 ? '/pitches' : '/briefs'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  // border: 'orange solid 1px',
                }}
              >
                <Typography variant='body' 
                  sx={{
                    height: '100%',
                  }}
                >
                  {allPitchesState?.length !== 0 ? 'All Pitches' : 'Go to Briefs'}
                </Typography>
                <Iconify 
                  icon='material-symbols:arrow-right' 
                  width='30px'
                  sx={{
                    height: '100%',
                    // border: 'red solid 1px',
                    p: 0,
                    m: 0,
                  }}
                />
              </Link>
            </Box>
          </Stack>


          <Grid container 
            spacing={3} 
            sx={{
              pl: 3,
            }} 
          >
          {allPitchesState?.map(song => {  // eslint-disable-line

            return song.pitches.map(pitch => { // eslint-disable-line

              return <Grid key={`${pitch.briefId}${song._id}`} item xs= {12} sm={6} md={4} lg={3} sx={{display: 'flex', justifyContent:'center', mb: 3}}>
                  <SongPitchedCard 
                    item={song} 
                    inputBrief={pitch}
                    loadedSong={src}
                    setLoadedSong={setSrc}
                    controlMessage={controlMessage} 
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    createdAt={pitch?.created_at || ''}
                    setToast={setToast}
                    getAllPitches={()=>{getRecentPitches()}}
                    openMultisUploadDrawer={openMultisUploadDrawer}
                    setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
                    multisInfo={multisInfo}
                    setMultisInfo={setMultisInfo}
                    refetch={()=>{getRecentPitches()}}
            
                  />
                </Grid>
              //
            })

            }
            )}
            {allPitchesState?.length === 0 &&
              <Grid item xs= {12} sm={6} md={4} lg={3} sx={{display: 'flex', justifyContent:'center', mb: 3}}>
                    <SongNoPitchesCard />
              </Grid>
            
            }
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <ProfileAbout
              user={user}
              setPaymentDialogOpen={setPaymentDialogOpen}
              handleCancelSubscription={()=>{handleCancelSubscription()}}
              accountMessage={accountMessage}
              setAccountMessage={setAccountMessage}
              cancelWarning={cancelWarning}
              setCancelWarning={setCancelWarning}
              accountDialogOpen={accountDialogOpen}
              setAccountDialogOpen={setAccountDialogOpen}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
    
    <AddArtistDialog 
        artistDialogOpen={artistDialogOpen}
        setArtistDialogOpen={setArtistDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />    
    <CreateOrganizationDialog
        organizationDialogOpen={organizationDialogOpen}
        setOrganizationDialogOpen={setOrganizationDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />
    <AccountDialog 
        accountDialogOpen={accountDialogOpen}
        setAccountDialogOpen={setAccountDialogOpen}
        user={user}
        userr={userr}
        setToast={setToast}
        handleCancelSubscription={()=>{handleCancelSubscription()}}
        accountMessage={accountMessage}
        setAccountMessage={setAccountMessage}
        cancelWarning={cancelWarning}
        setCancelWarning={setCancelWarning}
        refetch={refetch}
    />
    <WalkthroughDialog open={walkthroughDialogOpen} onClose={()=>{handleCloseWalkthrough()}}/>
    <MultisUploadDrawer
        openMultisUploadDrawer={openMultisUploadDrawer}
        setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
        multisInfo={multisInfo}
        setMultisInfo={setMultisInfo}
        refetch={()=>{getRecentPitches()}}
        loadedSong={src}
      />

    <Elements stripe={stripePromise}>
      <PaymentDialog 
        paymentDialogOpen={paymentDialogOpen}  
        setPaymentDialogOpen={setPaymentDialogOpen}  
        user={user}
        userr={userr}
        refetch={refetch}
      />

    </Elements>
    <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
