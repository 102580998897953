import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, TextField, InputAdornment, IconButton, Divider } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

import { addToCart } from '../redux/slices/product';

// components
import useResponsive from '../hooks/useResponsive';
// import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient';
import AudioFileCardDemo from '../sections/@dashboard/general/file/AudioFileCardDemo';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import {genreList, instrumentList, moodList } from '../components-custom/TagList';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import LoadingScreen from '../components-default/loading-screen/LoadingScreen';


// ----------------------------------------------------------------------

export default function ClientInventoryPageDemoTracks() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;
  const searchQuery = queryParams.search || null;
  const orgQuery = queryParams.org || null;

  // console.log('User: ', user)

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(isDesktop);
  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [showSearchTerm, setShowSearchTerm] = useState(searchTerm);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [songStatus, setSongStatus] = useState('pitched');

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [inventoryCount, setInventoryCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(20)
  const [pageLinks, setPageLinks] = useState('')

  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [selectedId, setSelectedId] = useState(null);

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const demoList = [
    // Turquoise - Tropical
    'f6589612-86ef-4a0d-9324-17fc532f9229',
    // Young and Free - Tropical
    'd9406ddc-6d27-4ae0-981b-9b60ac6287af',
    // The End - Dubstep
    '9c3385b2-b06c-42dc-98a8-4a5616f3d0a2',
    // Long Live the King - Dubstep
    'df78f112-37ab-481f-858a-5069b82b7945',
    // Berserker Arc - Drum and Bass
    '4bb92ebf-a69e-49d8-b003-375620b76b14',
    // Headrush - Trance
    '84064a1f-b34b-4589-a1a0-ba8cd905e144',
    // In the Game - Synthwave
    '062bd77d-d82b-452b-ba5b-448f380b18e0',
    // Sizzle Reel - K-POP
    '00b1b641-1c1d-484e-b0db-866a7a782af0',
    // Trust Me, I'm a Professional - Pop
    'd5e85cff-c4fe-49f1-ac6e-e5f13ca8409b',
    // Don't Leave - Pop
    '87785776-a2f6-4d8b-81fd-2caa192fb5eb',
    // High Life - Feel Good
    'c22844e3-a1a3-461d-b1fc-a8eed4d3fd7e',
    // Libertine - Feel Good
    'a773ab1f-c082-4e13-8d43-19c1b7b390d7',
    // Feels Alright - Retro
    'f723184e-fa2c-44bd-96b5-5c8437f2fedb',
    // Night on the Town - Retro
    '3aba0cf1-6185-41e8-8cb1-ed430f8648d4',
    // The District - Hip Hop
    '40deeb05-6b3f-4b85-844c-37846bb7a7c0',
    // Griddy Up - Hip Hop
    '7f721e56-635d-45c8-8b04-0ac4312eb142',
    // Stacks - Hip Hop
    '713de909-b0fd-4d30-be1d-5ea0cee0996f',
    // Infinitum - Metal
    '5d71c44a-eeac-43ad-9133-711d419118f5',
    // Radioactive - Metal
    '6503bcfb-7189-4e96-ad27-a845890fd0a5',
    // Face Rip - Rock
    'be8b16f9-641a-4b46-8684-38b63a09f11f',
    // Green Shade - Rock
    '15d3d5ec-6252-4335-8021-25fabd1f513f',
    // Trees in Autumn - Acoustic
    'ffd8b6e5-1790-4e90-bfc4-65ddc3984074',
    // Coven Fight
    '31828346-057e-4d80-8239-06ea653cc412',
    // The Nature of Man
    '2c56549d-6f3e-404e-82fe-f4c864a764df',
    // Psychosis
    '1de362ec-9a13-46bd-889e-642472be7b10',

  ]



  async function getDemoSong(docId){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getsingledoc`, {  // eslint-disable-line
        docId
      }).then(response => { // eslint-disable-line
        const {doc} = response.data
        return <AudioFileCardDemo
          key={doc._id}
          id={doc._id}
          userId={userr} 
          user={user}
          file={doc}
          filter={filter}
          addToCart={addToCart}
          getInventory={()=>{getDemoInventory()}}
          updateInventory={()=>{updateInventory()}}
          loadedSong={loadedSong} 
          setLoadedSong={setLoadedSong}
          setSelectedIndex={setSelectedIndex}
          selectedIndex={selectedIndex}
          setSelectedId={setSelectedId}
          // index={index}
          // selected={selectedIndex === index}
          sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
          setControlMessage={setControlMessage}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          audioBarFavorite={audioBarFavorite}
          setAudioBarFavorite={setAudioBarFavorite}
          toast={toast} 
          setToast={setToast}
          passable
          hideCartButton
          hidePlaylistButton
          hideTotalBuys
      />

      })
    } catch (err) {
      console.log(err)
    }              
  }

  async function getDemoInventory(){
    setAxiosLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdemodocs`, {  // eslint-disable-line
        demoList, 
        userId: user? user?._id : null
      }).then(response => {
        // console.log(response)
        setAxiosLoading(false)
        setAxiosResults(response.data)
        setToast({open: true, icon: null, closeButton: true, horizontal: 'right', vertical: 'bottom', toastColor: theme.palette.success.dark, message: `Please note these songs have already been sold and are not available`, duration: 8000})
      });
    } catch (err) {
      console.log(err)
    }              
  }

  // async function getCartCount(){
  //   // setLoading(true)
  //   try {
  //     const res = await axios.post(`${process.env.REACT_APP_SERVER}/getcartcount`, {  // eslint-disable-line
  //       orgId: user?.organization,
  //     }).then(response => {
  //       // console.log(response)
  //       // setAxiosResults(response.data)
  //       setCartItems(response.data?.totalDocs)
  //     });
  //   } catch (err) {
  //     console.log(err)
  //   }              
  // }

  async function updateInventory(){
    try {
      window.scrollTo({
        top: 0,
        behavior: 'auto', // Use smooth scrolling behavior
      });

      const normalPull = async () => {
        try {
          setAxiosLoading(true)
          const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdemodocs`, {  // eslint-disable-line
            demoList
          }).then(response => {
            // console.log(response)
            setAxiosResults(response.data)
            
          });
            } catch (error) {
              console.error('Error fetching search results:', error);
            }
          }

          normalPull()
          setUpdateTrigger(false)
      
    } catch (err) {
      console.log(err)
    }              
  }



  useEffect(() => {
    getDemoInventory()
  },[]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.docs)

      const tempFilterList = [];
      axiosResults?.docs.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
              doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.mood.length > 0) {
              doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.instrument.length > 0) {
              doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
      })
      setFilterList(tempFilterList)


      const totalPages = axiosResults.totalDocs/perPage
      setPageCount(Math.ceil(totalPages))

      const buttons = [];

      for (let i = 1; i <= Math.ceil(totalPages);) {
        // buttons.push(<button type='button' key={i}>Page {i}</button>);
        buttons.push(
          <Button key={`pageButton${i}`} variant={(page === i) ? 'outlined' : 'text'} size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled={page === i}
            onClick={()=>{
              setPage(i-1)
              navigate(`${window.location.pathname}?org=${orgQuery}&page=${i-1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
          <Typography variant='subtitle1' color={page === i ? 'primary' : 'primary'}>
            {i}
          </Typography>
        </Button>

        );
        i += 1
      }

      setPageLinks(buttons)

      setAxiosLoading(false)

    }
  },[axiosResults]) // eslint-disable-line

  // useEffect(() => {
  //   if (axiosResults !== ''){
  //     updateInventory()
  //   }
  // },[page, sortMethod, perPage]) // eslint-disable-line

  // useEffect(() => {
  //   if (axiosResults !== '' && pageQuery !== null){
  //     setPage(pageQuery)
  //   }
  // },[pageQuery]) // eslint-disable-line


  // useEffect(() => {
  //   if (axiosResults !== ''){
  //     updateInventory()
  //   }
  // },[songStatus]) // eslint-disable-line




  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };


  useEffect(() => { // eslint-disable-line

    // console.log('Filter: ', filter)

    const filteredFiles = [];

    if (axiosResults === '') {
      return
    }

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('pause')
        setIsPlaying(false)
      }
      // setShowSearchTerm(searchTerm)
      navigate(`/share?org=${orgQuery}&page=1`)
      setPage(1)
      updateInventory()
      
    } else {      
      setPage(1)
      navigate(`/share?org=${orgQuery}&page=1`)
      updateInventory()
    }

  }, [filter]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }
    
    if (updateTrigger === false) {
      return
    }
    setPage(1)
    updateInventory()

  }, [updateTrigger]); // eslint-disable-line 

  if (axiosLoading){
    return <LoadingScreen />
  }

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              { name: 'Selected Showcase' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
            <Typography variant="h3" textAlign='center'
              sx={{
                width: '100%',
                backgroundColor: theme.palette.primary.main,
                borderRadius: 1,
                color: theme.palette.common.white,
                p: 4,
                fontFamily: "peckham-press, sans-serif",
                mr: 3,
              }}
            >
              Audio Arsenal Track Showcase
            </Typography>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
            >
            <Stack direction='row' gap={3} 
              display='none'
              sx={{
                width: '100%',
                gridTemplateColumns: {
                  xs: '1fr 1fr 1fr',
                  sm: '1fr 1fr 1fr',
                  md: '1fr 1fr 1fr',
                },
                // mt: 2,
                mb: 2,
              }}
            >
              <Button 
                variant={songStatus === 'pitched' ? 'outlined': 'outlined'} 
                color={songStatus === 'pitched' ? 'primary' : 'info'}
                sx={{
                  opacity: songStatus === 'pitched' ? 1 : .3,
                  width: '100%',
                }}
                onClick={()=>{
                  setSongStatus('pitched')
                  setPage(1)
                  navigate(`${window.location.pathname}?org=${orgQuery}&page=1`)
                }}
              >
                Pitched {songStatus === 'pitched' && `(${axiosResults?.totalDocs})`}
              </Button>
              <Button 
                variant={songStatus === 'sold' ? 'contained': 'outlined'} 
                color={songStatus === 'sold' ? 'success' : 'info'}
                sx={{
                  opacity: songStatus === 'sold' ? 1 : .3,
                  width: '100%',
                  backgroundColor: songStatus === 'sold' ? theme.palette.success.main : 'none',
                }}
                onClick={()=>{
                  setSongStatus('sold')
                  setPage(1)
                  navigate(`${window.location.pathname}?org=${orgQuery}&page=1`)
                }}
              >
                Bought {songStatus === 'sold' && `(${axiosResults?.totalDocs})`}
              </Button>
            </Stack>
            {showSearchTerm !== '' &&
              <Box
                sx={{
                  // height: '100px',
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: theme.palette.divider,
                  border: `${theme.palette.info.main} solid 1px`,
                  p: 3,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant='subtitle1'>
                  Showing results for &apos;{showSearchTerm}&apos;
                </Typography>
                <IconButton onClick={()=>{
                  setSearchTerm('')
                  setShowSearchTerm('')
                  setUpdateTrigger(true)
                  navigate(`/share?org=${orgQuery}`)
                }}
                >
                  <CancelIcon />

                </IconButton>
              </Box>
          }
            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%'/> : 
            <>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Cinematic
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 22 && index < 25){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Feel Good
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 10 && index < 12){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Retro
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 12 && index < 14){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Hip Hop
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 14 && index < 17){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Pop
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 8 && index < 10){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Metal
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 17 && index < 19){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Rock
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 19 && index < 21){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                Acoustic
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 21 && index < 22){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                EDM - Tropical
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index < 2){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                EDM - Dubstep
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 2 && index < 4){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                EDM - DnB
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 4 && index < 5){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                EDM - Trance
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 5 && index < 6){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                EDM - Synthwave
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 6 && index < 7){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            <Box onKeyDown={handleKeyDown}>
              {/* {getDemoSong('f6589612-86ef-4a0d-9324-17fc532f9229')} */}
              <Typography variant='h4'
                sx={{
                  // backgroundColor: theme.palette.text.primary,
                  borderRadius: 2,
                  // color: theme.palette.common.white,
                  // p: 2,
                  // mb:2,
                  mt: 3,
                  width: '400px',
                }}
              >
                K-Pop
              </Typography>
              <Divider width='100%' 
                sx={{
                  mb: 2,
                }}
              />
            {displayFiles?.length > 0 &&
                displayFiles.map((doc, index) => { // eslint-disable-line
                if (index >= 7 && index < 8){
                  return <AudioFileCardDemo
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    filter={filter}
                    addToCart={addToCart}
                    // getCartCount={()=>{getCartCount()}}
                    getInventory={()=>{getDemoInventory()}}
                    updateInventory={()=>{updateInventory()}}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    // inventoryPage
                    // favorites
                    passable
                    // passable={songStatus !== 'sold'}
                    hideCartButton
                    hidePlaylistButton
                    hideTotalBuys
                  />
                }
              })
            }
            </Box>
            </>
           }
           {displayFiles?.length === 0 &&
               <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                Nothing to see here...
               </Typography>
           }
           {pageCount > 1 &&
           <Stack direction='row' height='50px' width='100%' gap={1}
            sx={{
              justifyContent: 'flex-end'
            }}
           >
            {page > 1 &&
            <Button variant='contained' size='small'
            onClick={()=>{
              setPage(page -1)
              navigate(`${window.location.pathname}?org=${orgQuery}&page=${page-1}`)
            }}
            >
              Prev
            </Button>
            }
            {pageLinks}
            <Button variant='contained' size='small'
              onClick={()=>{
                setPage(page +1)
                navigate(`${window.location.pathname}?org=${orgQuery}&page=${page+1}`)
              }}
              disabled={page === pageCount}
            >
              Next
            </Button>
           </Stack>
           }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} 
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: {
                sm: 3,
                md: 7,
                display: 'none',
              },
              mt: 3,
              pb: showFilter ? 'inherit' : 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mr: 4, mt: 0, gap: 3 }}>
            <Stack direction='row' width='100%' alignItems='center'
                display='flex' 
              >
                <Iconify icon={!showFilter ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'} 
                  sx={{
                    // mx:2,
                    pl: 0,
                    // width: '34px',
                    cursor: 'pointer',
                    color: theme.palette.info.light,
                    display: {
                      sm: 'inherit',
                      md: 'none',
                    },
                  }}
                  onClick={()=>{setShowFilter(!showFilter)}}
                />

                  <Typography variant="h5" sx={{ mb: 0 }}>
                    Filters
                  </Typography>
              </Stack>
              <Button 
                variant="text" 
                onClick={() => {
                  setFilter([])
                  setFilteredDisplayFiles([])
                }}
                sx={{ 
                  display: showFilter ? 'inherit' : 'none',
                  m: 0, 
                  p: 0, 
                  textTransform: 'none', 
                  fontSize: '12px', 
                  fontWeight: '200', 
                  color: theme.palette.primary.darker
                }}
                >
                clear filters
              </Button>
            </Box>
            <Box sx={{
              display: showFilter ? 'flex' : 'none',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'flex-start',
                sm: 'flex-start',
              },
              m: 0,
              p: 0,
              pt: 2,
              pr: 1,
              pb: 3,
            }}>
            <TextField fullWidth
              size='small'
              placeholder='Search artist or song'
              autoComplete='off'
              color='info'
              sx={{
                pt: 1,
                pb: 2,
                mr: 2,
              }}
              value={searchTerm}
              onChange={(e)=> {
                // console.log(e.target.value)
                setSearchTerm(e.target.value)
              }}
              onKeyDown={(e)=>{
                if (e.key === 'Enter'){
                  updateInventory()
                  setShowSearchTerm(searchTerm)
                  navigate(`/share?org=${orgQuery}&search=${searchTerm}`)
                }
              }}
              onKeyUp={(e)=>{
                if (searchTerm === '' && e.key !=='Shift'){
                  setUpdateTrigger(true)
                  setShowSearchTerm('')
                  navigate(`/share?org=${orgQuery}`)
                }
              }}
              InputProps={{
                // startAdornment: (
                // ),
                endAdornment: (
                  <>
                  {searchTerm && 
                  <InputAdornment position="end" 
                    onClick={()=>{
                      setSearchTerm('')
                      setShowSearchTerm('')
                      setUpdateTrigger(true)
                      navigate(`/share?org=${orgQuery}`)
                    }}
                    sx={{cursor: 'pointer'}}
                  >
                    <CancelIcon />
                  </InputAdornment>
                  }
                  <InputAdornment position="end"
                      sx={{cursor: 'pointer'}}
                      onClick={()=>{
                        if (searchTerm !== ''){
                          setUpdateTrigger(true)
                          setShowSearchTerm(searchTerm)
                          navigate(`/share?org=${orgQuery}&search=${searchTerm}`)
                        }
                      }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                  </>
                ),
              }}
            />

              <Typography variant='caption' width='100%' fontWeight='bold'>
                Genre
              </Typography>

              {filterList.filter(value => genreList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      if (isPlaying){
                        setControlMessage('play-pause')
                        setIsPlaying(!isPlaying)
                        setSelectedIndex(null)
                      }

                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }

              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Instrument
              </Typography>

              {filterList.filter(value => instrumentList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      if (isPlaying){
                        setControlMessage('play-pause')
                        setIsPlaying(!isPlaying)
                        setSelectedIndex(null)
                      }

                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Mood
              </Typography>

              {filterList.filter(value => moodList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      if (isPlaying){
                        setControlMessage('play-pause')
                        setIsPlaying(!isPlaying)
                        setSelectedIndex(null)
                      }

                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
            </Box>
          </Grid>

        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        // audioBarFavorite={audioBarFavorite}
        // setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        // refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast}/>

    </>
  );
}
