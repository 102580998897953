import { Helmet } from 'react-helmet-async';
// @mui
import { Container } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import { BlogNewPostForm } from '../sections/@dashboard/blog';
import { CreateBrief } from '../components-custom/sections/@dashboard/blog';

// ----------------------------------------------------------------------

export default function BlogNewPostPage() {
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Create Brief</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Create a new Brief"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Briefs',
              href: '/briefs',
            },
            {
              name: 'Create',
            },
          ]}
        />

        <CreateBrief />
      </Container>
    </>
  );
}
