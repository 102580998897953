// @mui
import { Accordion, Typography, AccordionSummary, AccordionDetails } from '@mui/material';
// _mock_
import { _faqs } from '../../_mock/arrays';
// components
import Iconify from '../../components-default/iconify';

// ----------------------------------------------------------------------

export default function FaqsListAA() {

  const faqArray=[
    {
      value: `Artist name`,
      heading: `Can the artist just be my name?`,
      detail: <>
      <Typography variant="body" >In theory, yes - but if you&apos;ve already sold music to other platforms with that name, you need to have a unique artist name for these pitches.</Typography> <br/> <br/>
      <Typography variant="body">Its also better for you if the songs you sell here don&apos;t clog up any playlists of your other songs on streaming platforms, since you&apos;re selling these outright.</Typography> 
      </>
    },
    {
      value: `Selling`,
      heading: `Why shouldn't I just contact platforms myself and sell directly?`,
      detail: <>
      <Typography variant="body" >You can certainly try - but these companies are inundated with pitches by hopeful producers. They trust companies like us to vet songs and only send the best. </Typography> <br/> <br/>
      <Typography variant="body" >{process.env.REACT_APP_SITE_NAME} is selective about the producers it accepts. When you pitch songs through us, you&apos;re going through the side door instead of standing in line outside with all the riff raff. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Contract`,
      heading: `That contract was a lot of words. I'm scared.`,
      detail: <>
      <Typography variant="body" >Yeah. Here&apos;s the layman&apos;s version:</Typography> <br/> <br/>
      <Typography variant="body" >When a client buys your song, it&apos;s no longer yours, in any way. The contract is the lawyers way of saying that. Since they&apos;re paid by the hour, they really like to drag it out.</Typography> <br/> <br/>
      <Typography variant="body" >Specific details will vary from client to client, and any details of note will be indicated in the brief.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Pay`,
      heading: `How long will it take to get paid?`,
      detail: <>
      <Typography variant="body" >We worked hard to set up a system that gets producers paid fast. When a client buys a song through us, payment is immediate. Once the payment settles, payouts are made within 7 business days. </Typography> <br/> <br/>
      <Typography variant="body" >The life of a producer is full of uncertainty, we want to offer a little relief. With {process.env.REACT_APP_SITE_NAME} you can make a track, pitch it and get paid QUICKLY. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Pay how`,
      heading: `How will I get the money?`,
      detail: <>
      <Typography variant="body" >When you sell your first song, we&apos;ll send you a link to setup with Stripe. Once that&apos;s done, we&apos;ll do the payout through Stripe, so whatever method you choose from them is how you you&apos;ll get it. </Typography> <br/> <br/>
      <Typography variant="body" >Once that&apos;s setup all future payouts will be automatic, within 7 business days of client payment. </Typography> <br/> <br/>
      </>
    },
    {
      value: `Rights`,
      heading: `Am I giving up all my rights to a song just to be able to pitch it?`,
      detail: <>
      <Typography variant="body" >Nope. Until a client finishes the checkout, the song is entirely yours. Only then does the contract become valid for that song.</Typography> <br/> <br/>
      <Typography variant="body" >The only restriction is that you can&apos;t simultaneously pitch a song here and somewhere else. If you decide you want to do something else with a song you&apos;ve pitched, just cancel the pitch and you&apos;re free as a bird.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Bigtime`,
      heading: `My friend said he got $10,000 for landing a song in a commercial.`,
      detail: <>
      <Typography variant="body" >Big opportunities still exist, and you should definitely go for those.</Typography> <br/> <br/>
      <Typography variant="body" >You can spend weeks obsessing over the details for that Superbowl ad that 2,000 other artists (and major labels) are pitching to. </Typography> <br/> <br/>
      <Typography variant="body" >While you&apos;re waiting (and waiting, and waiting) to hear back, you can crank out tracks and get paid now.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Big cut`,
      heading: `Post Malone might cut my song, why would I sell it full buyout here?`,
      detail: <>
      <Typography variant="body" >Oh my sweet summer child.</Typography> <br/> <br/>
      <Typography variant="body" >We must all go on our own journey to learn life&apos;s lessons.</Typography> <br/> <br/>
      <Typography variant="body" >When you&apos;re ready, we&apos;ll be here with open arms.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Briefs`,
      heading: `Brief? What's a brief?`,
      detail: <>
      <Typography variant="body" >It&apos;s just a description of the type of songs needed.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Song type`,
      heading: `My songs don't fit into rigid genre descriptions.`,
      detail: <>
      <Typography variant="body" >WoW yOu ArE sO uNiQuE aNd sPeCiAl</Typography> <br/> <br/>
      <Typography variant="body" >Just kidding. Creativity is encouraged! There&apos;s plenty of cookie-cutter music out there. If you&apos;re blurring the lines, maybe you&apos;re music will stand out from the pack.</Typography> <br/> <br/>
      <Typography variant="body" >You&apos;re membership includes unlimited pitches, so there&apos;s no reason to be shy. When in doubt, pitch it!</Typography> <br/> <br/>
      </>
    },
    {
      value: `Time`,
      heading: `It's been weeks and my song hasn't been rejected or bought.`,
      detail: <>
      <Typography variant="body" >In general if it has&apos;nt been passed on, that&apos;s a good sign. If a client just doesn&apos;t like or want the song, they&apos;ll just pass.</Typography> <br/> <br/>
      <Typography variant="body" >If it&apos;s lingering with the &apos;pitched&apos; status, it could mean one of several things:</Typography> <br/> <br/>
      <Typography variant="body" sx={{pl: 3}}>• They have so many songs pitched to that particular brief, they haven&apos;t got to yours yet.</Typography> <br/> <br/>
      <Typography variant="body" sx={{pl: 3}}>• They like it but are limited to the number of songs they buy per week/month, and are hoping it will still be there for the next round.</Typography> <br/> <br/>
      <Typography variant="body" >It&apos;s up to you how long you want to wait before deciding to do something else with your song.</Typography> <br/> <br/>
      </>
    },
    {
      value: `Cancel`,
      heading: `If I want to cancel, is it going to be a whole ordeal?`,
      detail: <>
      <Typography variant="body" >No. We make it easy to come, easy to go. Just click &apos;Edit Profile&apos; from the dashboard, and select &apos;Cancel&apos; from the Plan section. </Typography> <br/> <br/>
      <Typography variant="body" >Just a heads up, we might make a voodoo doll of you and poke it a few times. But you&apos;ll always be welcome back. </Typography> <br/> <br/>
      </>
    },
  ]


  return (
    <div>
      {faqArray.map((accordion, index) => (
        <Accordion key={index} sx={{mb: 3}}>
          <AccordionSummary expandIcon={<Iconify icon="eva:arrow-ios-downward-fill" />}>
              <Typography variant="subtitle1">{accordion.heading}</Typography>
          </AccordionSummary>

          <AccordionDetails>
            {accordion.detail}
            {/* <Typography>{accordion.detail}</Typography> */}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
