import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';  // eslint-disable-line

// import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';

import { Grid, Box, Button, Container, Typography, TextField, Stack, RadioGroup, FormControlLabel, Radio, Select, MenuItem, Divider } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

import axios from 'axios';
import { useQuery } from '@apollo/client';
import { GET_ALL_ORGANIZATIONS } from '../graphql/queries'

// components
// import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import {plusSideList} from '../components-custom/plusSideList';

// sections

import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import isAA from '../components-custom/isAA';

// ----------------------------------------------------------------------



export default function AdminUtilityPage() {


  const theme = useTheme();
  const { copy } = useCopyToClipboard();


//   const windowParams = new URLSearchParams(window.location.search);
  // const briefId = windowParams.get('brief');

  const { user } = useAuthContext();

  // console.log('User: ', user)
  
  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [uploadPercentage, setUploadPercentage] = useState(0);  // eslint-disable-line
  const [loading, setLoading] = useState(false); // eslint-disable-line
  const [utilityReq, setUtilityReq] = useState('');
  const [linkInfo, setLinkInfo] = useState({title: 'Untitled', platform: 'Fiverr', item: '', email: 'enter%20email', name: 'enter%20full%20name', composer: 'enter%20full%20name', price: ''})
  const [fullLink, setFullLink] = useState(`https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhB5sxhOBPU2w6z4mTtWxEV54jUp_KssrcMRpZls2zuGEyxpVl9_BrsF2qDKwY2pvOk*#title=${linkInfo.title}&platform=${linkInfo.platform}&item=${linkInfo.item}&composer=${linkInfo.composer}&price=$${linkInfo.price}%20USD`)
  const [newAccountInfo, setNewAccountInfo] = useState({name: '', email: '', password: '', tier: null, role: null, organization: ''})

  const [artistEmailInfo, setArtistEmailInfo] = useState({artistName: '', artistEmail: '', docId: ''})

  const [invoiceFinal, setInvoiceFinal] = useState('')
  const [paypalTestInfo, setPaypalTestInfo] = useState({userEmail: '', subscriptionId: ''})

  const [invoiceId, setInvoiceId] = useState('')
  const [briefInfo, setBriefInfo] = useState({briefId: '', tier: ''})
  const [cloneTierInfo, setCloneTierInfo] = useState({currentTier: '', newTier: ''})
  const [orgInfo, setOrgInfo] = useState({originalOrg: '', targetOrg: ''})

  const [stripeInviteInfo, setStripeInviteInfo] = useState({userEmail: '', stripeLink: ''})
  const [stripeAccount, setStripeAccount] = useState('')
  const [email, setEmail] = useState('')
  const [testInfo, setTestInfo] = useState({
    type: '',
    email: '',
    name: 'Nicolas Cage',
    userId: '64a83bd005185ee60c97eedf',
    link: 'http://www.yahoo.com',
    artist: 'Prince',
    songTitle: 'Purple Rain',
    amount: 10000,
    paymentMethod: 'Chickens',
    briefTitle: 'Butch Songs',
    message: 'It made me feel sick.',
    plusSide: plusSideList[Math.floor(Math.random() * plusSideList.length) + 1],
    docId: '22df8520-0728-40bb-b30d-fbd7d48d3af1',
    stripeLink: 'http://www.stripe.com',
    subject: 'Test this subject',
    temporaryPassword: '1234',
  })
  const [massEmailInfo, setMassEmailInfo] = useState({
    type: '',
    email: '',
    title: '',
    message: '',
    picLink: '',
  })

  const [invoiceUpdateDateInfo, setInvoiceUpdateDateInfo] = useState({invoiceId: '', updatedDate: ''})
  const [transactionInfo, setTransactionInfo] = useState({userId: '', songs: '', amount: '', status: '',})

  const { loading: orgLoading, error: orgError, data: orgData = {getAllClients: []}, refetch: orgRefetch } = useQuery(GET_ALL_ORGANIZATIONS, { // eslint-disable-line
    fetchPolicy: 'network-only'
  });

  function generateRandomPassword() {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let password = "";
    
    for (let i = 0; i < 6; i += 1) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters.charAt(randomIndex);
      
    }
    
    return password;
  }

  async function sendUserInvite(tempPass){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/senduserinvite`, { // eslint-disable-line
      name: newAccountInfo.name,
      email: newAccountInfo.email,
      temporaryPassword: tempPass,
    })
  }

  async function handleArtistEmailInfo(){ // eslint-disable-line
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/setartistemail`, { // eslint-disable-line
      artistName: artistEmailInfo.artistName,
      artistEmail: artistEmailInfo.artistEmail
    })
  }

  async function handleUpdateDocArtist(){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/updatedocartist`, { // eslint-disable-line
      docId: artistEmailInfo.docId
    })
  }

  async function handleCompleteInvoice(){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/completesale`, { // eslint-disable-line
      invoiceId: invoiceFinal,
    })
  }

  async function handleCopyAABrief(){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/copyAAbrief`, { // eslint-disable-line
      briefId: briefInfo.briefId,
      tier: briefInfo.tier,
    })

    if (invite.status === 200){
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Brief copied successfully`, duration: 4000})

    } else {
      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
    }

  }

  async function handleCloneBrief(){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/clonebriefs`, { // eslint-disable-line
      currentTier: cloneTierInfo.currentTier,
      newTier: cloneTierInfo.newTier,
    })

    if (invite.status === 200){
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Briefs cloned successfully`, duration: 4000})

    } else {
      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
    }

  }

  async function handlePaypalTest(){
    const test = await axios.post(`${process.env.REACT_APP_SERVER}/paypaltest`, { // eslint-disable-line
      userEmail: paypalTestInfo.userEmail,
      subscriptionId: paypalTestInfo.subscriptionId,
    })
  }

  async function handleSendStripeInvite(){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/sendstripeinvite`, { // eslint-disable-line
      userEmail: stripeInviteInfo.userEmail,
      stripeLink: stripeInviteInfo.stripeLink
    })
  }

  async function handleInvoiceUpdateDate(){
    const invite = await axios.post(`${process.env.REACT_APP_SERVER}/updateinvoicesales`, { // eslint-disable-line
      invoiceId: invoiceUpdateDateInfo.invoiceId,
      updatedDate: invoiceUpdateDateInfo.updatedDate,
    })
  }

  async function handleDeleteStripeAccount(){
    const result = await axios.post(`${process.env.REACT_APP_SERVER}/deletestripeaccount`, { // eslint-disable-line
      account: stripeAccount,
    })
  }

  async function handleTestEmail(){

    const result = await axios.post(`${process.env.REACT_APP_SERVER}/testemail`, { // eslint-disable-line
      testInfo,
    })
    if (result.status === 200){
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Email sent`, duration: 4000})
  
    } else {
      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
    }

  }

  async function handleMassEmail(){

    const result = await axios.post(`${process.env.REACT_APP_SERVER}/massemail`, { // eslint-disable-line
      massEmailInfo,
    })
    if (result.status === 200){
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Emails sent`, duration: 4000})
  
    } else {
      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
    }

  }

  async function handleMultisFixEmail(){

    const result = await axios.post(`${process.env.REACT_APP_SERVER}/multisfixemail`, { // eslint-disable-line
      massEmailInfo,
    })
    if (result.status === 200){
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Emails sent`, duration: 4000})
  
    } else {
      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
    }

  }


  async function handleAddTransaction(){
    const result = await axios.post(`${process.env.REACT_APP_SERVER}/addtransaction`, { // eslint-disable-line
      transactionInfo,
    })
    if (result.data.message !== 'error'){
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `$${result?.data?.amount} added to ${result?.data?.userName}'s transactions`, duration: 4000})

    } else {
      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
    }
  }

  async function createNewAccount(){
    const randomPassword = generateRandomPassword()

    console.log('RANDOM PASSWORD: ', randomPassword)

    const response = await axios.post(`${process.env.REACT_APP_SERVER}/register`, {
      name: newAccountInfo.name,
      email: newAccountInfo.email,
      tier: newAccountInfo.tier,
      password: randomPassword,
      role: newAccountInfo.role,
      organization: newAccountInfo.organization,
    })

    // console.log('RESPONSE STATUS: ',response.status)
    if (response.status === 200){
      sendUserInvite(randomPassword)
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${newAccountInfo.name} account successfully created`, duration: 4000})
      setNewAccountInfo({name: '', email: '', password: '', tier: null})

    } else {
      setToast({open: true, icon: <CheckIcon sx={{color: 'red'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Somethin ain't work`, duration: 4000})
    }

  }
  
  useEffect(()=>{
    setFullLink(`https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhB5sxhOBPU2w6z4mTtWxEV54jUp_KssrcMRpZls2zuGEyxpVl9_BrsF2qDKwY2pvOk*#title=${linkInfo.title}&platform=${linkInfo.platform}&item=${linkInfo.item}&composer=${linkInfo.composer}&email=${linkInfo.email}&price=$${linkInfo.price}%20USD`)
  },[linkInfo])

  if (user.role !== 'admin') {
    return <h1>YOU AINT BELONG HERE</h1>
  }

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Utility </title>
      </Helmet>
      <Container>
      <Box
        sx={{
          pt: 6,
          pb: 1,
        }}
      >
        <Box sx={{display: 'flex', gap: 2}}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            ADMIN UTILITY
          </Typography>
        </Box>
        <Grid container spacing={3}>

          <Grid item xs={12} sm={12} md={12} sx={{mb: 2, paddingTop: '0px',}}>
            <Box sx={{display: 'none', gap: 2, alignContent: 'center'}}>
              <Stack direction='row'>
                <Button 
                  variant='contained' 
                  disabled={loading}
                  onClick={async ()=>{
                    const res = await axios.post(`${process.env.REACT_APP_SERVER}/adminutility`, {  // eslint-disable-line
                      invoiceId: utilityReq
                    })} }
                  sx={{ mt: 0, mb: 3, height: '36px', width: '80px'}}
                  >
                  {!loading? 'Invoice Check' : '...'}
                </Button>
                <TextField  onChange={(e)=>{setUtilityReq(e.target.value)}}/>
              </Stack>
              <Button 
                variant='contained' 
                disabled={loading}
                onClick={async ()=>{
                  const res = await axios.get(`${process.env.REACT_APP_SERVER}/youtubeapi`, {  // eslint-disable-line
                  })} }
                sx={{ mt: 0, mb: 3, height: '36px', width: '80px'}}
                >
                {!loading? 'Youtube' : '...'}
              </Button>
            <Box sx={{display: 'flex', gap: 2, alignContent: 'center'}}>
              <Button 
                variant='contained' 
                disabled={loading}
                onClick={async ()=>{
                  const res = await axios.post(`${process.env.REACT_APP_SERVER}/contract-list-test`, {  // eslint-disable-line
                  })} }
                  sx={{ mt: 0, mb: 3, height: '36px', width: '80px'}}
                  >
                {!loading? 'Contract' : '...'}
              </Button>
            </Box>
            <Box sx={{display: 'flex', gap: 2, alignContent: 'center'}}>
              <Button 
                variant='contained' 
                // disabled={loading}
                onClick={async ()=>{
                  const res = await axios.post(`${process.env.REACT_APP_SERVER}/testemail`, {  // eslint-disable-line
                    email: 'rob.hawkins@mac.com',
                    name: 'Rob Hawkins',
                    songTitle: "Roofs Coming Off",
                    artist: 'North Station',
                    docId: 'b7cf4339-903f-41c4-83a6-670a92041088',
                  })} }
                  sx={{ mt: 0, mb: 3, height: '36px', width: '80px'}}
                  >
                Email Test
              </Button>
            </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
            <Box // LEGAL DOC FORM BOX
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='row' alignItems='center' mb={2}>
                <Typography variant='subtitle1' sx={{width: '150px'}}>
                  Song Title
                </Typography>
                <TextField size='small' autoComplete='off'
                  value={linkInfo.title}
                  onChange={(e)=>{
                    setLinkInfo({...linkInfo, title: e.target.value })
                  }}
                />
              </Stack>
              <Stack direction='row' alignItems='center' mb={2}>
                <Typography variant='subtitle1' sx={{width: '150px'}}>
                  Item No.
                </Typography>
                <TextField size='small' autoComplete='off'
                  value={linkInfo.item}
                  onChange={(e)=>{
                    setLinkInfo({...linkInfo, item: e.target.value })
                  }}
                />
              </Stack>
              {/* <Stack direction='row' alignItems='center' mb={2}>
                <Typography variant='subtitle1' sx={{width: '150px'}}>
                  Email
                </Typography>
                <TextField size='small' autoComplete='off'
                  value={linkInfo.email}
                  onChange={(e)=>{
                    setLinkInfo({...linkInfo, email: e.target.value })
                  }}
                />
              </Stack> */}
              <Stack direction='row' alignItems='center' mb={2}>
                <Typography variant='subtitle1' sx={{width: '150px'}}>
                  Price
                </Typography>
                <TextField size='small' autoComplete='off'
                  value={linkInfo.price}
                  onChange={(e)=>{
                    setLinkInfo({...linkInfo, price: e.target.value })
                  }}
                />
              </Stack>
              <Stack direction='row' alignItems='center' mb={2}>
                <Typography variant='subtitle1' sx={{width: '150px'}}>
                  Full Link:
                </Typography>
                <TextField size='small'
                  value={fullLink}
                  onChange={(e)=>{
                    setLinkInfo({...linkInfo, email: e.target.value })
                  }}
                />
                <Button variant='text'
                  onClick={()=>{
                    copy(fullLink);
                  }}>
                  <Iconify icon='solar:copy-line-duotone' />

                </Button>
              </Stack>
              <Typography variant='caption' sx={{lineBreak: 'anywhere'}}>{fullLink}</Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // CREATE ACCOUNT
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Create Account
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' >
                    Name
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={newAccountInfo.name}
                    onChange={(e)=>{
                      setNewAccountInfo({...newAccountInfo, name: e.target.value })
                    }}
                  />
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' >
                    Email
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={newAccountInfo.email}
                    onChange={(e)=>{
                      setNewAccountInfo({...newAccountInfo, email: e.target.value })
                    }}
                  />
                </Stack>
                <Stack  width='100%' p={3} justifyContent='space-between'>
                  <RadioGroup row defaultValue="producer" 
                    onChange={(e)=> setNewAccountInfo({...newAccountInfo, tier: e.target.value})}
                    sx={{
                      display: 'flex', 
                      width: '100%',
                      justifyContent: 'space-evenly',
                      // border: 'red solid 1px',
                  }}
                  >
                      <FormControlLabel value={1} control={<Radio />} label="Tier 1" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={2} control={<Radio />} label="Tier 2" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={3} control={<Radio />} label="Tier 3" sx={{width: 'fit-content'}}/>
                  </RadioGroup>
                </Stack>
                <Stack  width='100%' p={3} justifyContent='space-between'>
                  <RadioGroup row defaultValue=" " 
                    onChange={(e)=> setNewAccountInfo({...newAccountInfo, role: e.target.value})}
                    sx={{
                      display: 'flex', 
                      width: '100%',
                      justifyContent: 'space-evenly',
                      // border: 'red solid 1px',
                    }}
                    >
                      <FormControlLabel value='producer' control={<Radio />} label="Producer" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value='client' control={<Radio />} label="Client" sx={{width: 'fit-content'}}/>
                  </RadioGroup>
                </Stack>
                {newAccountInfo.role === 'client' &&
                  <Stack  width='100%' justifyContent='center' alignItems='center' paddingBottom={6}>
                    <Select sx={{width: '250px'}} placeholder='Organization' value={newAccountInfo.organization}
                      onChange={(e)=>{setNewAccountInfo({...newAccountInfo, organization: e.target.value})

                      }}
                    >
                      {orgData?.getAllOrgs?.map(org => {return <MenuItem // eslint-disable-line
                        key={org?._id} value={org?._id}>{org?.name}</MenuItem>
                    })}
                    </Select>
                  </Stack>
                }
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    createNewAccount()
                  }}>
                    Create Account
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // EDIT ARTIST EMAIL
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Update Doc Artist
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}}>
                    Doc Id
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={artistEmailInfo.docId}
                    onChange={(e)=>{
                      setArtistEmailInfo({...artistEmailInfo, docId: e.target.value })
                    }}
                  />
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleUpdateDocArtist()
                    // handleArtistEmailInfo()
                    // createNewAccount()
                  }}>
                    Set Email
                </Button>
              </Stack>

          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // FINALIZE INVOICE
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Complete Sale and Credit Producers
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Invoice Id
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={invoiceFinal}
                    onChange={(e)=>{
                      setInvoiceFinal(e.target.value )
                    }}
                  />
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleCompleteInvoice()
                    // handleUpdateDocArtist()
                    // handleArtistEmailInfo()
                    // createNewAccount()
                  }}>
                    FINALIZE
                </Button>
              </Stack>

          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // ADD AUDIO URL TO INVOICE ITEMS
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Paypal Test
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    User Email
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={paypalTestInfo.userEmail}
                    onChange={(e)=>{
                      setPaypalTestInfo({...paypalTestInfo, userEmail: e.target.value})
                    }}
                  />
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    SubscriptionId
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={paypalTestInfo.subscriptionId}
                    onChange={(e)=>{
                      setPaypalTestInfo({...paypalTestInfo, subscriptionId: e.target.value})
                    }}
                  />
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    // handleSetAudioUrls()
                    handlePaypalTest()

                  }}>
                    RUN
                </Button>
              </Stack>

          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // SEND STRIPE INVITE
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Send Stripe Invite
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    User Email
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={stripeInviteInfo.userEmail}
                    onChange={(e)=>{
                      setStripeInviteInfo({...stripeInviteInfo, userEmail: e.target.value})
                    }}
                  />
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Stripe Link
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={stripeInviteInfo.stripeLink}
                    onChange={(e)=>{
                      setStripeInviteInfo({...stripeInviteInfo, stripeLink: e.target.value})
                    }}
                  />
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleSendStripeInvite()
                  }}>
                    SEND STRIPE INVITE
                </Button>
              </Stack>

          </Box>  
          </Grid>

          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // SEND STRIPE INVITE
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Update Invoice Sales
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Invoice Id
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={invoiceUpdateDateInfo.invoiceId}
                    onChange={(e)=>{
                      setInvoiceUpdateDateInfo({...invoiceUpdateDateInfo, invoiceId: e.target.value})
                    }}
                  />
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Updated Date
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={invoiceUpdateDateInfo.updatedDate}
                    onChange={(e)=>{
                      setInvoiceUpdateDateInfo({...invoiceUpdateDateInfo, updatedDate: e.target.value})
                    }}
                  />
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleInvoiceUpdateDate()
                  }}>
                    Update Invoice Dates
                </Button>
              </Stack>

          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // DELETE STRIPE ACCOUNT
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Delete Stripe Account
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Account #
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={stripeAccount}
                    onChange={(e)=>{
                      setStripeAccount(e.target.value)
                    }}
                  />
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleDeleteStripeAccount()
                    // handleInvoiceUpdateDate()
                  }}>
                    Delete Account
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // MANUALLY ADD PRODUCER PAYMENT TO TRANSACTIONS
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Manually Add Producer Payment to Transactions
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    User ID
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={transactionInfo.userId}
                    onChange={(e)=>{
                      setTransactionInfo({...transactionInfo, userId: e.target.value})
                    }}
                  />
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Songs
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={transactionInfo.songs}
                    onChange={(e)=>{
                      setTransactionInfo({...transactionInfo, songs: e.target.value})
                    }}
                  />
                  </Stack>
                  <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Amount
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={transactionInfo.amount}
                    onChange={(e)=>{
                      setTransactionInfo({...transactionInfo, amount: e.target.value})
                    }}
                  />
                  </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Status
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={transactionInfo.status}
                    onChange={(e)=>{
                      setTransactionInfo({...transactionInfo, status: e.target.value})
                    }}
                  />
                  </Stack>

                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleAddTransaction()
                  }}>
                    Add Transaction
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // TEST EMAILS
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Test Email
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Email
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={testInfo.email}
                    onChange={(e)=>{
                      setTestInfo({...testInfo, email: e.target.value})
                    }}
                    sx={{
                      width: '100%',
                    }}
                  />
                </Stack>
                  <Select
                      id='type-select'
                      value={testInfo.type}
                      defaultValue={testInfo.type}
                      onChange={(e)=>{
                        setTestInfo({...testInfo, type: e.target.value})
                        // console.log('Select: ', e.target.value)
                      }}
                      inputProps={{ 'aria-label': 'Email Type' }}
                      fullWidth
                      size='small'
                      placeholder={testInfo.type}
                      sx={{
                        mb: 2,
                      }}
                >
                  <MenuItem value='all'
                  selected={testInfo.type === 'all'}
                  >
                    Send All Emails
                  </MenuItem>

                  <Divider />

                  <MenuItem value='sendInviteEmail'
                  selected={testInfo.type === 'sendInviteEmail'}
                  >
                    Invite Email
                  </MenuItem>

                  <MenuItem value='sendEmailPasswordReset'
                  selected={testInfo.type === 'sendEmailPasswordReset'}
                  >
                    Email Password Reset
                  </MenuItem>

                  <Divider />
                  <MenuItem value='sendTrialOverAlert'
                  selected={testInfo.type === 'sendTrialOverAlert'}
                  >
                    Trial Over Alert
                  </MenuItem>

                  <MenuItem value='sendSubcriptionSuccess'
                  selected={testInfo.type === 'sendSubcriptionSuccess'}
                  >
                    Subcription Success
                  </MenuItem>

                  <Divider />

                  <MenuItem value='sendSongReviseAlert'
                  selected={testInfo.type === 'sendSongReviseAlert'}
                  >
                    Song Revise Alert
                  </MenuItem>

                  <MenuItem value='sendSongPassAlert'
                  selected={testInfo.type === 'sendSongPassAlert'}
                  >
                    Song Pass Alert
                  </MenuItem>

                  <MenuItem value='sendSongInCartAlert'
                  selected={testInfo.type === 'sendSongInCartAlert'}
                  >
                    Song In Cart Alert
                  </MenuItem>

                  <MenuItem value='sendSongBuyAlert'
                  selected={testInfo.type === 'sendSongBuyAlert'}
                  >
                    Song Buy Alert
                  </MenuItem>

                  <MenuItem value='sendMultiPassAlert'
                  selected={testInfo.type === 'sendMultiPassAlert'}
                  >
                    Multi Song Pass Alert
                  </MenuItem>
                  <Divider />

                  <MenuItem value='sendUrgentMultisAlert'
                  selected={testInfo.type === 'sendUrgentMultisAlert'}
                  >
                    Urgent Multis Alert
                  </MenuItem>

                  <MenuItem value='sendExpiredMultisAlert'
                  selected={testInfo.type === 'sendExpiredMultisAlert'}
                  >
                    Expired Multis Alert
                  </MenuItem>
                  <Divider />

                  <MenuItem value='sendStripeInvite'
                  selected={testInfo.type === 'sendStripeInvite'}
                  >
                    Stripe Invite
                  </MenuItem>

                  <MenuItem value='sendPayoutAlert'
                  selected={testInfo.type === 'sendPayoutAlert'}
                  >
                    Payout Alert
                  </MenuItem>

                  <Divider />

                  <MenuItem value='sendGeneralContact'
                  selected={testInfo.type === 'sendGeneralContact'}
                  >
                    General Contact
                  </MenuItem>

                  <MenuItem value='sendSignUpContact'
                  selected={testInfo.type === 'sendSignUpContact'}
                  >
                    SignUp Contact
                  </MenuItem>

                  <MenuItem value='sendLoginAlert'
                  selected={testInfo.type === 'sendLoginAlert'}
                  >
                    Login Alert
                  </MenuItem>
                  
                </Select>

                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleTestEmail()
                  }}>
                    Check It
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // COPY AA BRIEF
              sx={{
                display: isAA() ? 'none' : 'inherit',
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Copy AA Brief
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2}>
                  <Typography variant='subtitle1' sx={{width: '150px'}} >
                    Brief Id
                  </Typography>
                  <TextField size='small' autoComplete='off'
                    value={briefInfo.briefId}
                    onChange={(e)=>{
                      setBriefInfo({...briefInfo, briefId: e.target.value })
                    }}
                  />
                </Stack>
                <Stack  width='100%' p={3} justifyContent='space-between'>
                  <RadioGroup row defaultValue="producer" 
                    onChange={(e)=> setBriefInfo({...briefInfo, tier: e.target.value})}
                    sx={{
                      display: 'flex', 
                      width: '100%',
                      justifyContent: 'space-evenly',
                      // border: 'red solid 1px',
                  }}
                  >
                      <FormControlLabel value={1} control={<Radio />} label="Tier 1" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={2} control={<Radio />} label="Tier 2" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={3} control={<Radio />} label="Tier 3" sx={{width: 'fit-content'}}/>
                  </RadioGroup>
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleCopyAABrief()
                  }}>
                    Create Brief
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // CLONE BRIEF TIER
              sx={{
                display: isAA() ? 'none' : 'inherit',
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    Clone Brief Tier
                  </Typography>
                </Stack>
                <Stack  width='100%' p={3} justifyContent='space-between'>
                  <Typography variant='subtitle'>
                    Current Tier Selection
                  </Typography>
                  <RadioGroup row defaultValue="producer" 
                    onChange={(e)=> setCloneTierInfo({...cloneTierInfo, currentTier: e.target.value})}
                    sx={{
                      display: 'flex', 
                      width: '100%',
                      justifyContent: 'space-evenly',
                  }}
                  >
                      <FormControlLabel value={1} control={<Radio />} label="Tier 1" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={2} control={<Radio />} label="Tier 2" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={3} control={<Radio />} label="Tier 3" sx={{width: 'fit-content'}}/>
                  </RadioGroup>
                </Stack>
                <Stack  width='100%' p={3} justifyContent='space-between'>
                  <Typography variant='subtitle'>
                    New Tier Selection
                  </Typography>
                  <RadioGroup row defaultValue="producer" 
                    onChange={(e)=> setCloneTierInfo({...cloneTierInfo, newTier: e.target.value})}
                    sx={{
                      display: 'flex', 
                      width: '100%',
                      justifyContent: 'space-evenly',
                  }}
                  >
                      <FormControlLabel value={1} control={<Radio />} label="Tier 1" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={2} control={<Radio />} label="Tier 2" sx={{width: 'fit-content'}}/>
                      <FormControlLabel value={3} control={<Radio />} label="Tier 3" sx={{width: 'fit-content'}}/>
                  </RadioGroup>
                </Stack>
                <Button variant='contained' size='medium'
                  onClick={()=>{
                    handleCloneBrief()
                  }}>
                    Create Brief
                </Button>
              </Stack>
          </Box>  
          </Grid>


      <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
      <Box // SEND GROUP EMAIL
            sx={{
              borderRadius: 2,
              backgroundColor: theme.palette.divider,
              border: `${theme.palette.info.main} solid 1px`,
              p: 3,
            }}
          >
            <Stack direction='column' alignItems='flex-start' mb={2}>
              <Stack direction='row' alignItems='flex-start' mb={2}>
                <Typography variant='subtitle1' >
                  Send Group Email
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Title
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={massEmailInfo.title}
                  onChange={(e)=>{
                    setMassEmailInfo({...massEmailInfo, title: e.target.value})
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>
              <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Message
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={massEmailInfo.message}
                  onChange={(e)=>{
                    setMassEmailInfo({...massEmailInfo, message: e.target.value})
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>
              <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Pic Link
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={massEmailInfo.picLink}
                  placeholder='Optional'
                  onChange={(e)=>{
                    setMassEmailInfo({...massEmailInfo, picLink: e.target.value})
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>
                <Select
                    id='type-select'
                    value={massEmailInfo.type}
                    defaultValue={massEmailInfo.type}
                    onChange={(e)=>{
                      setMassEmailInfo({...massEmailInfo, type: e.target.value})
                    }}
                    inputProps={{ 'aria-label': 'Email Type' }}
                    fullWidth
                    size='small'
                    placeholder={testInfo.type}
                    sx={{
                      mb: 2,
                    }}
              >
                <MenuItem value='test'
                  selected={massEmailInfo.type === 'test'}
                >
                  Test
                </MenuItem>
                <Divider />

                <MenuItem value='all producers'
                  selected={massEmailInfo.type === 'all producers'}
                >
                  All Producers
                </MenuItem>
                <Divider />

                <MenuItem value='premium producers'
                  selected={massEmailInfo.type === 'premium producers'}
                >
                  Premium Producers
                </MenuItem>
                <Divider />

                <MenuItem value='noted producers'
                  selected={massEmailInfo.type === 'noted producers'}
                >
                  Noted Producers
                </MenuItem>
                <Divider />

              </Select>

              <Button variant='contained' size='medium'
                onClick={()=>{
                  handleMassEmail()
                }}>
                  Send
              </Button>
            </Stack>
          </Box>  
          </Grid>
      <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
      <Box // SEND MULTIS FIX EMAIL
            sx={{
              borderRadius: 2,
              backgroundColor: theme.palette.divider,
              border: `${theme.palette.info.main} solid 1px`,
              p: 3,
            }}
          >
            <Stack direction='column' alignItems='flex-start' mb={2}>
              <Stack direction='row' alignItems='flex-start' mb={2}>
                <Typography variant='subtitle1' >
                  FIX MULTIS EMAIL
                </Typography>
              </Stack>
              <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  To:
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={massEmailInfo.email}
                  onChange={(e)=>{
                    setMassEmailInfo({...massEmailInfo, email: e.target.value})
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>
              <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Title
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={massEmailInfo.title}
                  onChange={(e)=>{
                    setMassEmailInfo({...massEmailInfo, title: e.target.value})
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>
              <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Message
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={massEmailInfo.message}
                  onChange={(e)=>{
                    setMassEmailInfo({...massEmailInfo, message: e.target.value})
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>

              <Button variant='contained' size='medium'
                onClick={()=>{
                  handleMultisFixEmail()
                }}>
                  Send
              </Button>
            </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // EXECUTE PASSLIST
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    EXECUTE PASSLIST
                  </Typography>
                </Stack>

                <Button variant='contained' size='medium'
                  onClick={async ()=>{
                    const result = await axios.post(`${process.env.REACT_APP_SERVER}/executepasslist`, { // eslint-disable-line
                      executePasses: true,
                    })
                    if (result.status === 200){
                      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Completed`, duration: 4000})
                  
                    } else {
                      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
                    }                  }}>
                    BULK PASS
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // EXECUTE USER PASSLIST
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    EXECUTE USER PASSLIST
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  USER ID
                </Typography>
                <TextField multiline={3} size='medium' autoComplete='off'
                  value={invoiceId}
                  onChange={(e)=>{
                    setInvoiceId(e.target.value)
                  }}
                  sx={{
                    width: '100%',
                  }}
                />
              </Stack>


                <Button variant='contained' size='medium'
                  onClick={async ()=>{
                    const result = await axios.post(`${process.env.REACT_APP_SERVER}/executeuserpasslist`, { // eslint-disable-line
                      userId: invoiceId,
                    })
                    if (result.status === 200){
                      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Completed`, duration: 4000})
                  
                    } else {
                      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
                    }                  }}>
                    SEND USER PASS ALERT
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // ADD PRICING TO BRIEFS
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    BRIEF PRICING
                  </Typography>
                </Stack>

                <Button variant='contained' size='medium'
                  onClick={async ()=>{
                    const result = await axios.post(`${process.env.REACT_APP_SERVER}/adminutility`, { // eslint-disable-line
                      addPrices: true,
                    })
                    if (result.status === 200){
                      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Completed`, duration: 4000})
                  
                    } else {
                      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
                    }                  }}>
                    ADD BRIEF PRICES
                </Button>
              </Stack>
          </Box>  
          </Grid>
          <Grid item xs={12} sm={12} md={6} sx={{mb: 2, paddingTop: '0px',}}>
          <Box // COPY ORG CATEGORIES
              sx={{
                borderRadius: 2,
                backgroundColor: theme.palette.divider,
                border: `${theme.palette.info.main} solid 1px`,
                p: 3,
              }}
            >
              <Stack direction='column' alignItems='flex-start' mb={2}>
                <Stack direction='row' alignItems='flex-start' mb={2}>
                  <Typography variant='subtitle1' >
                    COPY ORG CATEGORIES
                  </Typography>
                </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Origin Org
                </Typography>
                <Stack  width='100%' justifyContent='center' alignItems='center' paddingBottom={6}>
                  <Select sx={{width: '250px'}} placeholder='Organization' value={orgInfo.originalOrg}
                    onChange={(e)=>{setOrgInfo({...orgInfo, originalOrg: e.target.value})

                    }}
                  >
                    {orgData?.getAllOrgs?.map(org => {return <MenuItem // eslint-disable-line
                      key={org?._id} value={org?._id}>{org?.name}</MenuItem>
                  })}
                  </Select>
                </Stack>
              </Stack>
                <Stack direction='row' alignItems='flex-start' gap={3} mb={2} width='100%'>
                <Typography  variant='subtitle1' sx={{width: '150px'}} >
                  Target Org
                </Typography>
                <Stack  width='100%' justifyContent='center' alignItems='center' paddingBottom={6}>
                  <Select sx={{width: '250px'}} placeholder='Organization' value={orgInfo.targetOrg}
                    onChange={(e)=>{setOrgInfo({...orgInfo, targetOrg: e.target.value})

                    }}
                  >
                    {orgData?.getAllOrgs?.map(org => {return <MenuItem // eslint-disable-line
                      key={org?._id} value={org?._id}>{org?.name}</MenuItem>
                  })}
                  </Select>
                </Stack>
              </Stack>
                <Button variant='contained' size='medium'
                  onClick={async ()=>{
                    const result = await axios.post(`${process.env.REACT_APP_SERVER}/copyorgcategories`, { // eslint-disable-line
                      orgInfo,
                    })
                    if (result.status === 200){
                      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Completed`, duration: 4000})
                  
                    } else {
                      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
                    }                  }}>
                    DO IT
                </Button>
              </Stack>
              <Stack>
                <Button variant='contained' size='medium'
                  onClick={async ()=>{
                    const result = await axios.post(`${process.env.REACT_APP_SERVER}/checkbriefs`, { // eslint-disable-line
                      // orgInfo,
                      log: true,
                    })
                    if (result.status === 200){
                      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Completed`, duration: 4000})
                  
                    } else {
                      setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000})
                    }                  }}>
                    CHECK BRIEFS
                </Button>
              </Stack>
          </Box>  
          </Grid>
        </Grid>
      </Box>
      </Container>
      <CustomToast toast={toast} setToast={setToast} />
      <Footer />
      </>
  );
}
