import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const graphQlPort = process.env.REACT_APP_GRAPHQL_PORT;

const httpLink = createHttpLink({
    uri: graphQlPort
})

const authLink = setContext((_, { headers }) => { // eslint-disable-line
    return {
        headers: {
          ...headers,
            authorization: localStorage.getItem('accessToken') || ''
        }
    }
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
});

export default client;