import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';


import { Button, Box, Chip, Divider, Typography } from '@mui/material';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { useQuery, useMutation } from "@apollo/client";

import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


import { DELETE_PITCH } from '../graphql/mutations';
import { useSettingsContext } from '../components-default/settings';
import Label from '../components-default/label';
import Image from '../components-default/image';
import Iconify from '../components-default/iconify/Iconify';


const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  mb: 3,
  position: 'absolute',
  borderRadius: 1.5,
  backgroundColor: alpha(theme.palette.grey[900], 0.44),
}));

export default function SongNoPitchesCard() {

  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box 
      sx={{ 
        // CARD BOX
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
        position: 'relative',
        height: {
          xs: '285px',
          sm: '185px',
      }, 
        width: '100%', 
        borderRadius: 1.5,
        overflow: 'hidden',
        p: 3,
        // boxShadow: theme.shadows[1],
        background: theme.palette.background.paper,
    }}
    >
      <Typography variant='subtitle2' width='100%' textAlign='center'>
        You have no current pitches.
      </Typography>  
      <Typography 
        variant='body' 
        fontWeight='bold'
        width='100%' 
        textAlign='center'
        onClick={()=>{navigate('/briefs')}}
        sx={{
          cursor: 'pointer',
          '&:hover': {
            textDecoration: 'underline',
          }

        }}
      >
        Check out the Briefs
      </Typography>
  



    </Box>
  )

}
