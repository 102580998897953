import { Helmet } from 'react-helmet-async';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Button, Container, Typography, Select, MenuItem, Stack, Pagination } from '@mui/material';
// utils
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import axios from '../utils/axios';
import { useAuthContext } from '../auth/useAuthContext';

import Footer from '../layouts/main/Footer';
// routes
// components

import Iconify from '../components-default/iconify';
import { categoriesList } from '../components-custom/CategoriesList'
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import { BriefPostCardV2, } from '../components-custom/sections/@dashboard/blog';

// graphql

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function BriefsProducerPageV2() {

  const { themeStretch } = useSettingsContext();

  const theme = useTheme();

  const { user } = useAuthContext();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page;
  const categoryQuery = queryParams.category;

  const [axiosLoading, setAxiosLoading] = useState(false)

  const [displayBriefs, setDisplayBriefs] = useState([])
  const [briefCount, setBriefCount] = useState(null)
  const [category, setCategory] = useState(categoryQuery || 'All')
  const [page, setPage] = useState(parseInt(pageQuery, 10) || 1)
  const [perPage, setPerPage] = useState(12)
  const [pageCount, setPageCount] = useState(0)

  async function getTheBriefs() {
    setAxiosLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getbriefs`, {  // eslint-disable-line
        // orgId: user?.organization,
        status: 'active',
        queryType: 'producer',
        page,
        perPage,
        tier: user?.tier,
        userId: user?._id,
        category,
      }).then(response => {

        const themedBriefs = response?.data?.briefs.filter(x => x?.post?.themed === true)
        const unThemedBriefs = response?.data?.briefs.filter(x => !x?.post?.themed)
        // console.log('unthemed Briefs: ', unThemedBriefs.length)
        // console.log('themed Briefs: ', themedBriefs.length)

        // console.log('Themed Briefs: ', themedBriefs) 
        const sortedBriefs = unThemedBriefs.sort((a, b) => new Date(b.post.createdAt) - new Date(a.post.createdAt))
        const extraSortedBriefs = sortedBriefs.filter(x => (x?.post?.category !== 'Various' && x?.post?.category !== 'Vocal'))


        sortedBriefs.map(item => { // eslint-disable-line
          if (item?.post?.category === 'Various') {
            return extraSortedBriefs.push(item)
          }
        })

        sortedBriefs.map(item => { // eslint-disable-line
          if (item?.post?.category === 'Vocal') {
            return extraSortedBriefs.push(item)
          }
        })

        const consolidatedBriefs = themedBriefs.concat(extraSortedBriefs)
        // console.log('EXTRA: ', extraSortedBriefs)

        // console.log('BRIEFS: ',extraSortedBriefs)
        setDisplayBriefs(consolidatedBriefs)
        setBriefCount(response?.data?.briefCount)
        const totalPages = response.data.briefCount / perPage
        setPageCount(Math.ceil(totalPages))

        setAxiosLoading(false)
      }
      );
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    if (!pageQuery) {
      return navigate('/briefs?page=1')
    }
    getTheBriefs()
    return undefined
  }, []); // eslint-disable-line


  useEffect(() => { // eslint-disable-line
    if (category === '') {
      return undefined
    }
    navigate(`/briefs?category=${category}&page=${page}`)
    getTheBriefs()
    return undefined
  }, [category, perPage, page]); // eslint-disable-line


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Briefs</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          // heading='Briefs'

          links={[
            {
              name: 'Dashboard',
              href: '/dash',
            },
            {
              name: 'Briefs',
            },
          ]}
        />
        <Grid container spacing={3} sx={{
          mb: 6,
        }}
        >
          <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
            <Stack direction='row' gap={2} alignItems='center' marginLeft={3}>
              <Typography variant="h4">
                Briefs
              </Typography>
            </Stack>
            <Stack direction='row' gap={2}>
              <Select
                // defaultValue={sortMethod}
                value={perPage}
                sx={{
                  display: 'flex',
                  height: '36px',
                  width: '140px',
                }}
                onChange={(e) => {
                  setPage(1)
                  setPerPage(e.target.value)
                  // navigate('/inventory')
                }}
              >
                <MenuItem value={12}>
                  12 per page
                </MenuItem>
                <MenuItem value={24}>
                  24 per page
                </MenuItem>
                <MenuItem value={48}>
                  48 per page
                </MenuItem>
                <MenuItem value={64}>
                  64 per page
                </MenuItem>
              </Select>
            </Stack>
          </Stack>

          {categoriesList.map(item => { // eslint-disable-line
            return <Grid item xs={4} sm={2} key={`buttons-${item}`}
              sx={{
                // display: (item === 'All' || displayBriefs.some(entry => entry?.post?.category === item)) ? 'inherit' : 'none',
              }}
            >
              <Button fullWidth size='large'
                variant={item === category ? 'contained' : 'outlined'}
                color={item === category ? 'primary' : 'info'}
                sx={{
                  opacity: item === category ? 1 : .5,
                }}
                onClick={() => {
                  if (category === item) {
                    setPage(1)
                    setCategory('All')
                  } else {
                    setPage(1)
                    setCategory(item)
                  }
                }}
              >
                {item}
              </Button>
            </Grid>
          }
          )}
        </Grid>
        {axiosLoading ?
          <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%' /> :
          <>
            {displayBriefs.length !== 0 &&
              <Grid container spacing={3}
                sx={{
                  mb: 10,
                  // pt: 0,
                }}
              >
                <Grid item xs={12}
                  sx={{
                    pt: '0 !important',
                  }}
                >
                  {pageCount > 1 &&
                    <Box
                      sx={{
                        display: pageCount > 1 ? 'flex' : 'none',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mb: 2,
                        // display: 'none',
                      }}
                    >

                      <Pagination count={pageCount} page={parseInt(page, 10) || 1}
                        // variant='soft'
                        sx={{
                          // pt: 0,
                          // border: 'blue solid 1px',
                        }}
                        shape='rounded'
                        color='primary'
                        size='large'
                        onChange={(e, value) => {
                          setPage(value)
                          // setUpdateTrigger(true)
                        }} />
                    </Box>
                  }
                </Grid>
                {displayBriefs.map((post, index) =>
                (
                  <Grid key={post._id} item xs={12} sm={4} md={3}>
                    <BriefPostCardV2 post={post.post} id={post._id} index={index} />
                  </Grid>

                ))}
                <Grid item xs={12}
                >
                  {pageCount > 1 &&
                    <Box
                      sx={{
                        display: pageCount > 1 ? 'flex' : 'none',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        mb: 2,
                        // display: 'none',
                      }}
                    >

                      <Pagination count={pageCount} page={parseInt(page, 10) || 1}
                        // variant='soft'
                        sx={{
                          // border: 'blue solid 1px',
                        }}
                        shape='rounded'
                        color='primary'
                        size='large'
                        onChange={(e, value) => {
                          setPage(value)
                          // setUpdateTrigger(true)
                        }} />
                    </Box>
                  }
                </Grid>
              </Grid>
            }
            {displayBriefs.length === 0 &&
              <Box sx={{
                p: 5,
                pb: 10,
              }}>
                <Typography variant='body'>No briefs currently.</Typography>
              </Box>
            }
          </>
        }

      </Container>

      <Footer />

    </>
  );
}

// ----------------------------------------------------------------------

