import orderBy from 'lodash/orderBy';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
// @mui
import { Box, Grid, Button, Container, Typography, Select, MenuItem, Stack } from '@mui/material';
// utils
import { useQuery, useMutation } from "@apollo/client";
import { GET_ACTIVE_BRIEFS_BY_ORG, GET_DOCS_BY_ORG_PITCH } from '../graphql/queries'
import axios from '../utils/axios';

import { useAuthContext } from '../auth/useAuthContext';

import Footer from '../layouts/main/Footer';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import Iconify from '../components-default/iconify';
import { SkeletonPostItem } from '../components-default/skeleton';
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import { BriefPostCard, BriefPostCardV2, BlogPostsSort, } from '../components-custom/sections/@dashboard/blog';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';

// graphql

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function BriefsInactiveV2() {

  const { themeStretch } = useSettingsContext();


  const { user } = useAuthContext();

  const navigate = useNavigate();


  const [briefSet, setBriefSet] = useState('inactive')


  const [displayBriefs, setDisplayBriefs] = useState([])
  const [ briefStats, setBriefStats] = useState([]);
  const [category, setCategory] = useState('All')

  const allCategories = 
  [
    'All',
    'Ambient', 
    'Cinematic/Score',
    'Classical',
    'Country',
    'Electronic',
    'Folk/Acoustic',
    'Hip Hop',
    'Indie',
    'Jazz',
    'Kids',
    'Pop',
    'RnB',
    'Rock',
    'World',
    'Vocal',
    'Everything Else',
  ]

  async function getTheBriefs(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getbriefs`, {  // eslint-disable-line
        orgId: user?.organization,
        status: 'inactive',
      }).then(response => {

          const sortedBriefs = response?.data?.briefs.sort((a, b) => new Date (b.post.createdAt) - new Date(a.post.createdAt))
          const extraSortedBriefs = sortedBriefs.filter(x => (x?.post?.category !== 'Any Genre' && x?.post?.category !== 'Vocal'))

          sortedBriefs.map(item => { // eslint-disable-line
            if (item?.post?.category === 'Any Genre'){
              return extraSortedBriefs.push(item)
            }
          })

          sortedBriefs.map(item => { // eslint-disable-line
            if (item?.post?.category === 'Vocal'){
              return extraSortedBriefs.push(item)
            }
          })

          setDisplayBriefs(extraSortedBriefs)

          setBriefStats(response?.data?.briefStats)

      }
      );
    } catch (err) {
      console.log(err)
    }              
  }

  async function filterBriefs(genre){
    try {
      // console.log('GETTING GENRE: ', genre)
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getbriefs`, {  // eslint-disable-line
        orgId: user?.organization,
        status: 'inactive',
        queryType: 'client',
        tier: user?.tier,
      }).then(response => {

          const sortedBriefs = response?.data?.briefs.sort((a, b) => new Date (b.post.createdAt) - new Date(a.post.createdAt))
          const extraSortedBriefs = sortedBriefs.filter(x => (
            x?.post?.category?.includes(genre) || 
            x?.post?.multiGenres[0]?.includes(category)
          ))

          setDisplayBriefs(extraSortedBriefs)

      }
      );
    } catch (err) {
      console.log(err)
    }              

  }



  function getTotalCart(){
    let total = 0
    briefStats.map(item => { // eslint-disable-line   
        total += item.inCart
    })
    return total
  }
  
  useEffect(() => {
    getTheBriefs()
  }, []); // eslint-disable-line


  useEffect(() => { // eslint-disable-line
    // console.log('CATEGORY: ', category)
    if (category === ''){
      return undefined
    }
    if (category === 'All'){
      getTheBriefs()
    } else {
      filterBriefs(category)
    }
    return undefined
    // return filterBriefs(category)
  }, [category]); // eslint-disable-line


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Briefs</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Briefs'
          action={
            <Stack direction='row' spacing={3}>
            {user.role !== 'producer' ?
            <Button
              // component={RouterLink}
              // to='/create'
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              onClick={()=>{
                navigate('/briefs/create')
              }}
            >
              New Post
            </Button> : null
            }
            <Select  
              defaultValue={briefSet}
              sx={{
                display: user.role !== 'producer' ? 'flex' : 'none',
                height: '36px',
                width: {
                  sm: '140px',
                  md: '200px',
              },
              }}
              onChange={(e)=>{
                if (e.target.value === 'active'){
                  navigate('/briefs')
                }
              }}
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
            </Select>
          </Stack>
          }
          links={[
            {
              name: 'Dashboard',
              href: '/dash',
            },
            {
              name: 'Briefs',
              href: '/briefs',
            },
            {
              name: 'Inactive',
              // href: '/briefs',
            },
          ]}
        />
          <Grid container spacing={3} sx={{mt: 2, mb: 6}}>
          {allCategories.map(item => { // eslint-disable-line
            return <Grid item xs={3} sm={2} key={`buttons-${item}`}> 
            <Button fullWidth size='small'
              variant={item === category ? 'contained' : 'outlined'}
              color={item === category ? 'primary' : 'info'}
              sx={{
                opacity: item === category ? 1 : .5,
              }}
              onClick={()=>{
                if (category === item){
                  setCategory('All')
                } else {
                  setCategory(item)
                }
              }}
            >
              {item}
            </Button>
          </Grid>
          }
            )}
        </Grid>

        {displayBriefs.length !== 0 ? 
        <Grid container spacing={3} sx={{mb: 10}}>
          {displayBriefs.length > 0 && displayBriefs.map((post, index) =>
           (
             <Grid key={post._id} item xs={12} sm={4} md={3}>
                <BriefPostCardV2 
                  post={post.post} 
                  id={post._id }
                  index={index} 
                  briefStats={briefStats.filter(x=>x._id === post._id) }
                  // currentPitches={findBriefPitches(post._id)}
                  />
              </Grid>

          )) }
        </Grid> :
        <Box sx={{
          p: 5,
          pb: 10,
        }}>
          <Typography variant='body'>No briefs currently.</Typography>

        </Box>
        }
      </Container>
      {user?.role !== 'producer' &&
        <CartWidgetCustom totalItems={getTotalCart()} />
      }

      <Footer/>

    </>
  );
}

// ----------------------------------------------------------------------

