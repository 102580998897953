import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { Box, Grid, Button, Container, Typography, Select, MenuItem, Stack, Pagination, IconButton, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';


// utils
import queryString from 'query-string';
import { useQuery } from '@apollo/client';
import axios from 'axios';
import { GET_DOCUMENT_BY_ID } from '../graphql/queries';
import { useAuthContext } from '../auth/useAuthContext';

// components
import Footer from '../layouts/main/Footer';
import Iconify from '../components-default/iconify';
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import isAA from "../components-custom/isAA";


// sections
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';
import { UploadBox } from '../components-default/upload';

// graphql

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function MultisUploadPage() {

  const { themeStretch } = useSettingsContext();
  const theme = useTheme();

  const { user } = useAuthContext();

  const navigate = useNavigate();

  const hashValue = window.location.hash.substring(1);
  // console.log('HASH: ', hashValue)
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const [axiosLoading, setAxiosLoading] = useState(false)

  const { copy } = useCopyToClipboard();

  const { documentId } = useParams()

  const { loading: docLoading, error: docError, data: docData = { getDocumentById: [] }, refetch: docRefetch } = useQuery(GET_DOCUMENT_BY_ID, {
    variables: { documentId },
    fetchPolicy: 'network-only'
  });

  const [uploadPercentage, setUploadPercentage] = useState(0);  // eslint-disable-line
  const [uploadSuccess, setUploadSuccess] = useState(false);  // eslint-disable-line
  const [loading, setLoading] = useState(false);

  const [fileHolder, setFileHolder] = useState(null);

  const [uploadFileType, setUploadFileType] = useState('ok');
  const [errorState, setErrorState] = useState(null)

  const [errorMessage, setErrorMessage] = useState('')

  const { CancelToken } = axios;
  const source = CancelToken.source();

  function cancelUpload() {
    source.cancel('Upload canceled by the user.');
    setLoading(false);
  }

  function successCallback(songId) {
    console.log('Upload Success!')
    setUploadPercentage(0);
    setLoading(false);
    setUploadSuccess(true);
    // if (setUpdateTrigger) {
    //   setUpdateTrigger(true)
    // }
    // if (refetch) {
    //   refetch()
    // }
  }

  const handleUpload = async (zipFile) => {

    setLoading(true)
    console.log('File: ', fileHolder);
    const formData = new FormData();

    formData.append('userId', user?._id);
    formData.append('docId', docData?.getDocumentById?._id);
    formData.append('title', docData?.getDocumentById?.title);
    formData.append('file', zipFile);
    formData.append('alert', hashValue === 'revise');

    console.log('FORMDATA: ', formData)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/multisupload`, formData, {  // eslint-disable-line
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) /
            progressEvent.total), 10))
        },
        cancelToken: source.token
      }).then(response => {
        console.log(response.data)
        successCallback(response.data.id);
      }).catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }



  useEffect(() => {
    console.log('USER ID: ', user?._id)
    console.log('DOC USER: ', docData?.getDocumentById?.userId)
    if (!docLoading && docData?.getDocumentById?.userId && user?._id !== docData?.getDocumentById?.userId) {
      navigate('/403')
    }
  }, [docData]); // eslint-disable-line

  if (docLoading) return <LoadingScreen />

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | {hashValue === 'revise' ? 'Fix Multis' : 'Upload Multis'}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${docData?.getDocumentById?.title} - ${hashValue === 'revise' ? 'Revise Multis' : 'Upload Multis'}`}
          links={[
            {
              name: 'Dashboard',
              href: '/dash',
            },
            {
              name: hashValue === 'revise' ? 'Revise Multis' : 'Upload Multis',
            },
          ]}
        />
        <Grid container spacing={3} sx={{ mt: 2, mb: 6 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              // gap: 1,
              pt: 2,
              px: 4,
              // border: '1px solid red',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: 'auto',
              width: '100%',
              cursor: 'default',
            }}
          >
            <Stack direction='row' justifyContent='center' alignItems='center'
              sx={{
                mb: 2,
                gap: 2,
                display: !uploadSuccess ? 'inherit' : 'none',
              }}
            >
            <Iconify icon="fa:arrow-down" width={24} sx={{ color: theme.palette.text.primary }} />
            <Typography variant="h4" >
              {
                hashValue === 'revise' ? 'Drop revised multis here' : 'Drop multis here'
              }
            </Typography>
            <Iconify icon="fa:arrow-down" width={24} sx={{ color: theme.palette.text.primary }} />
            </Stack>

            {!uploadSuccess &&
              (!loading ?
                <UploadBox
                  onDrop={(e) => {
                    if (
                      e[0].type === 'application/zip'
                    ) {
                      setUploadFileType('ok')
                      setFileHolder(e[0]);
                      // handleUpload(e[0])
                      console.log(e);
                    } else {
                      setUploadFileType('error')
                    }
                  }}
                  accept=".zip"
                  sx={{
                    mt: 0,
                    mb: 0,
                    py: 1,
                    // px: 2,
                    width: '100%',
                    height: '250px',
                    boxSizing: 'border-box',
                    borderRadius: 1,
                    backgroundColor: uploadFileType !== 'error' ? `#e6e8e4` : theme.palette.error.dark, // eslint-disable-line 
                    border: (errorState === 'file' && !fileHolder) ? 'red solid 2px' : `${theme.palette.grey[700]} solid 1px`,
                  }}

                  placeholder=
                  {
                    fileHolder === null && uploadFileType !== 'error' ? // eslint-disable-line
                      <Box sx={{ color: isAA() ? 'text.disabled' : 'primary.light', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: isAA() ? theme.palette.text.main : theme.palette.background.default }}>
                          {docData?.getDocumentById?.multitrackUrl ? 'Add/drop replacement file' : 'Add/drop file'}
                        </Typography>
                        <Iconify icon="eva:cloud-upload-fill" width={40} sx={{ color: isAA() ? `${theme.palette.text.primary}80` : `${theme.palette.background.default}`, }} />
                      </Box> :
                      (uploadFileType !== 'error' ?
                        <Box sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                          <Typography variant="subtitle1" sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.background.default }}>{fileHolder?.name || 'File added'}</Typography>
                          <Iconify icon="octicon:file-zip-24" width={40} sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.background.default, }} />
                        </Box>
                        : <Box sx={{ color: theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                          <Typography variant="subtitle1" color='common.white'>File must be zipped</Typography>
                          <Iconify icon="eva:slash-fill" width={20} sx={{ color: theme.palette.common.white, }} />
                        </Box>
                      )
                  }
                />
                :
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '170px',
                    width: '100%',
                    position: 'relative',
                    // border: 'red solid 1px',
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={uploadPercentage}
                    size={120}
                    color='info'
                  />
                  {uploadPercentage < 100 ?
                    <Typography variant="subtitle2" component="div" color="text.secondary"
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      {`${Math.round(uploadPercentage)}%`}
                    </Typography> :
                    <Stack direction='column' justifyContent='center' alignItems='center' gap={.5}
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <Typography variant='subtitle1' color='text.secondary'>
                        Saving
                      </Typography>
                      <Typography variant='caption' color='text.secondary'>
                        please wait
                      </Typography>
                      <Iconify icon="eos-icons:three-dots-loading" width={32} />
                    </Stack>
                  }
                </Box>)
            }
            {uploadSuccess &&
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 2,
                  height: '150px',
                  width: '100%',
                  position: 'relative',
                  // border: 'red solid 1px',
                }}
              >
                <Typography variant="h3" component="div" color="text.secondary"
                >
                  {
                    hashValue === 'revise' ? 'Multis revision sent' : 'Multis uploaded'
                  }
                </Typography>
                <Iconify icon="eva:checkmark-circle-2-fill" width={62} sx={{ color: theme.palette.success.main }} />
              </Box>
            }
            <Stack direction='row' justifyContent='space-between' alignItems='center'
              sx={{
                // height: '40px',
                width: '100%',
                gap: 1,
                py: 4,
                display: (fileHolder === null || uploadSuccess) ? 'none' : 'inherit',
              }}
            >
              <Button fullWidth
                variant='contained'
                onClick={() => {
                  console.log('UPLOAD')
                  handleUpload(fileHolder)
                }}
                sx={{ mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}
              >
                Upload Multis
              </Button>
              <IconButton
                onClick={() => {
                  setFileHolder(null);
                  setUploadFileType('ok');
                }}>
                <Iconify icon="eva:close-fill" width={20} />
              </IconButton>
            </Stack>
            <Stack direction='row' justifyContent='space-between' alignItems='center'
              sx={{
                // height: '40px',
                width: '100%',
                gap: 1,
                py: 4,
              }}
            >
              <Button fullWidth
                variant='text'
                onClick={() => {
                  console.log('CANCEL')
                  cancelUpload()
                }}
                sx={{ display: loading ? 'inherit' : 'none', mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}
              >
                Cancel Upload
              </Button>
            </Stack>
            {(fileHolder === null && errorState === 'file') &&
              <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
            }

          </Box>

        </Grid>
      </Container>

      <Footer />

    </>
  );
}

// ----------------------------------------------------------------------

