import { useState } from 'react';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Grid,
  Stack,
  Paper,
  Button,
  Checkbox,
  Container,
  Typography,
  CardHeader,
  IconButton,
} from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// _mock
import _mock from '../../_mock';
// components
import Image from '../../components-default/image';
import Iconify from '../../components-default/iconify';
import BadgeStatus from '../../components-default/badge-status';
import { MotionViewport, varFade } from '../../components-default/animate';
import { CustomAvatar } from '../../components-default/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    // border: 'red solid 1px',
    
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  // border: 'red solid 1px',
  padding: theme.spacing(1.5, 0),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 0),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.5),
  },
}));

const StyledDescription = styled('div')(({ theme }) => ({
  textAlign: 'center',
  cursor: 'default',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(10),
  },
}));

const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(2.5),
    },
  },
}));

// ----------------------------------------------------------------------

export default function HomeHeroTB() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Grid direction={{ xs: 'column', md: 'row' }} container spacing={5}>

          <Grid item xs={12} md={5} 
            sx={{
              // border: 'red solid 1px'
            }}
          >
            <Content />
          </Grid>

          <Grid item xs={12} md={7} 
            sx={{
              // border: 'green solid 1px'
            }}
          >
            <Description2 />
          </Grid>

          {!isDesktop && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              {SignUpButton}
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

function Description() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledDescription>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ 
            my: 3,
            fontFamily: "peckham-press, sans-serif",
            fontWeight: 400,
            fontStyle: 'normal', 
          }}>
          Custom music <br />
          for your platform
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: 'text.secondary',
          }}
        >
          An exclusive selection of high-quality, pre-cleared buyout music, custom curated for you.
        </Typography>
      </m.div>

      {isDesktop && SignUpButton}
    </StyledDescription>
  );
}

function Description2() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledDescription>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ 
            my: 3,
            // fontFamily: "peckham-press, sans-serif",
            fontFamily: "gravesend-sans, sans-serif",
            fontWeight: 400,
            fontStyle: 'normal', 
          }}>
          Get paid for making music.
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: 'text.secondary',
          }}
        >
          Media companies are in constant need of new music for their platforms - <br/>
          You can be a provider.
        </Typography>
      </m.div>

      {isDesktop && SignUpButton}
    </StyledDescription>
  );
}

// ----------------------------------------------------------------------

function Content() {
  const isMd = useResponsive('up', 'md');

  const isLg = useResponsive('up', 'lg');


  return (
    <StyledContent>
      <img src='/logo/TB_Record_Small.png' alt={process.env.REACT_APP_SITE_NAME} height='400px'/>
    </StyledContent>
  );
}

// ----------------------------------------------------------------------

const SignUpButton = (
  <m.div variants={varFade().inUp}>
    <Button
      size="large"
      color="primary"
      variant="contained"
      // target="_blank"
      rel="noopener"
      href='/signuplist'
      endIcon={<Iconify icon="ic:round-arrow-right-alt" />}
    >
      Apply Now
    </Button>
  </m.div>
);

// ----------------------------------------------------------------------

