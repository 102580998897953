import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

// @mui
import { useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,

} from '@mui/material';


import CheckIcon from '@mui/icons-material/Check';
import axios from '../utils/axios';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


CopyOrganizationDialog.propTypes = {
    organizationDialogOpen: PropTypes.bool,
    setOrganizationDialogOpen: PropTypes.func,
    userr: PropTypes.string,
    refetch: PropTypes.func,
    setArtistSelector: PropTypes.func,
    setToast: PropTypes.func,
    uploadPage: PropTypes.bool,
  };



export default function CopyOrganizationDialog({organizationDialogOpen, setOrganizationDialogOpen, userr, refetch, setArtistSelector, setToast, uploadPage}) {

  const theme = useTheme();
  
  const [orgInfo, setOrgInfo] = useState({name: '', address: '', city: '', state: '', zip: '', paymentType: '', lastFour: '', tier: ''})
  const [loading, setLoading] = useState(false)
  


  const handleClose = () => {
    setOrganizationDialogOpen(false);
    setOrgInfo({name: '', address: '', city: '', state: '', zip: '', paymentType: '', lastFour: '', tier: ''})
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    submitNewOrg();

  }
  
  
  function successCallback() {
    console.log('Upload Success!')
    setLoading(false)
    setTimeout(()=>{
      handleClose();
      refetch()
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${orgInfo.name} successfully copied`, duration: 4000})
      console.log('refetching')
    }, 500)
    refetch()
      // navigate('/uploadsuccess');
  }


  async function submitNewOrg(){

    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/copyAAorg`, {  // eslint-disable-line
        name: orgInfo._id,
      }).then(response => {
        successCallback();
      });
    } catch (err) {
      console.log(err)
    }              
  }


  return (
    <div>
      <Dialog
        open={organizationDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll='body'
        onClose={()=>{setOrganizationDialogOpen(false)}}
        sx={{
            height: '100%',
            paper: {
            }
        }}
      >
        <Box 
          sx={{
            width: {
              sm: '300px',
              md: '500px',
            }, 
          }}
        >

        <DialogTitle 
          id="add-artist-title"
          sx={{
            pb: 0,
          }}
        >
          Copy Organization
        </DialogTitle>

        <DialogContent scroll='body' >
        <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              minWidth: '100px',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Org Id</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              fullWidth
              autoComplete='off'
              sx={{ }} 
              value={orgInfo.name} 
              onChange={(e)=>{
                setOrgInfo({...orgInfo, _id: e.target.value})
              }}
            />
  
        </Box>

        </DialogContent>

        <DialogActions>
          <Button 
            size='medium' 
            color='inherit' 
            variant="outlined" 
            onClick={handleClose} 
            sx={{px: "16px", py: "7px"}}
          >
            Cancel
          </Button>

          <Button 
            size='medium' 
            variant="contained" 
            onClick={handleSubmit} 
            sx={{px: "16px", py: "7px"}}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
        </Box>

      </Dialog>
    </div>
  );
}
