export const categoriesList = [
    'All',
    'Ambient', 
    'Cinematic/Score',
    'Classical',
    'Country',
    'Electronic',
    'Folk/Acoustic',
    'Hip Hop',
    'Indie',
    'Jazz',
    // 'Kids',
    'Pop',
    'RnB',
    'Rock',
    'World',
    'Vocal',
    'Everything Else',
];

