import { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';

import { m } from 'framer-motion';

// @mui
import { Button, Typography, TextField, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';

import axios from '../../../utils/axios';
// components
import { MotionViewport, varFade } from '../../../components-default/animate';
import Iconify from '../../../components-default/iconify/Iconify';

// ----------------------------------------------------------------------

export default function ContactFormGeneral() {
  const theme = useTheme();

  const [info, setInfo] = useState(
    {
      name: '',
      email: '',
      subject: '',
      message: ''
    }
  )
  const [loading, setLoading] = useState(false)
  const [pageState, setPageState] = useState('form')

  async function handleSubmit(){
    setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/generalcontact`, {  // eslint-disable-line
        info
      }).then(response => {
        setLoading(true)
        console.log(response)
        setPageState('sent')
      });
    } catch (err) {
      console.log(err)
    }              
  }

  return (
    <>
    {pageState === 'form' &&
    <Stack component={MotionViewport} spacing={5} width='100%' minWidth='300px'>
      <Stack spacing={3}>
        <m.div variants={varFade().inUp}>
          <TextField fullWidth label="Name" autoComplete='off'
          onChange={(e)=>{
            setInfo({...info, name: e.target.value})
          }}
          />
        </m.div>

        <m.div variants={varFade().inUp}>
          <TextField fullWidth label="Email" autoComplete='off'
          onChange={(e)=>{
            setInfo({...info, email: e.target.value})
          }}
          />
        </m.div>

        <m.div variants={varFade().inUp}>
          <TextField fullWidth label="Subject" autoComplete='off'
          onChange={(e)=>{
            setInfo({...info, subject: e.target.value})
          }}
          />
        </m.div>

        <m.div variants={varFade().inUp}>
          <TextField fullWidth autoComplete='off'
          label="Enter your message here." multiline rows={4} 
          onChange={(e)=>{
            setInfo({...info, message: e.target.value})
          }}
          />
        </m.div>
      </Stack>

      <m.div variants={varFade().inUp}>
        <LoadingButton size="large" variant="contained"
          loading={loading}
          onClick={()=>{
            handleSubmit()
          }}
        >
          {!loading ? 'Submit Now' : '...'}
        </LoadingButton>
      </m.div>
    </Stack>
    }
    {pageState === 'sent' &&
    <Iconify icon='cil:check' 
    width='250px'
    sx={{
      width: '250px', 
      color: theme.palette.primary.main,
    }}/>
  }
  </>
  );
}
