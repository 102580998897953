import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';


import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


// @mui
import { alpha,useTheme } from '@mui/material/styles';
import { Box, Card, Divider, Grid, Link, Stack, Typography } from '@mui/material';


import { useMutation, useQuery } from "@apollo/client";

// routes
import axios from 'axios';
import { GET_USER_CART } from '../../../../../../graphql/queries'
import { GET_ACTIVE_BRIEFS_BY_ORG_M, GET_DOCS_BY_ORG_PITCH_M, GET_USER_PITCHES_BY_ID_M } from '../../../../../../graphql/mutations'


import { useAuthContext } from '../../../../../../auth/useAuthContext';

//
import ProfileAbout from './ProfileAbout';

import CartWidgetCustom from '../../../../../CartWidgetCustom';
import AddArtistDialog from '../../../../../AddArtistDialog';
import CreateOrganizationDialog from '../../../../../CreateOrganizationDialog';
import AccountDialog from '../../../../../AccountDialog';
import PaymentDialog from '../../../../../PaymentDialog';

import AAWidgetCard from '../../../../../AAWidgetCard';
import AAWidgetCardSongsBought from '../../../../../AAWidgetCardSongsBought';

import CustomToast from '../../../../../CustomToast';

import Image from '../../../../../../components-default/image';

import { fDate } from '../../../../../../utils/formatTime';
import Iconify from '../../../../../../components-default/iconify/Iconify';
import BriefsEmptyCard from '../../../../../BriefsEmptyCard';

// ----------------------------------------------------------------------

ClientProfile.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
  data: PropTypes.array,
  refetch: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  controlMessage: PropTypes.string,
  setControlMessage: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  accountDialogOpen: PropTypes.bool,
  setAccountDialogOpen: PropTypes.func,
};


export default function ClientProfile({ info, posts, data, refetch, loadedSong, setLoadedSong, controlMessage, setControlMessage, isPlaying, setIsPlaying, accountDialogOpen, setAccountDialogOpen }) {

  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  // console.log('PROFILE USER: ', user)

  const theme = useTheme();
  const navigate = useNavigate();

  const [getActiveBriefsByOrgM] = useMutation(GET_ACTIVE_BRIEFS_BY_ORG_M);
  const [getDocsPitchedToOrgM] = useMutation(GET_DOCS_BY_ORG_PITCH_M);

  const [artistDialogOpen, setArtistDialogOpen] = useState(false)
  const [organizationDialogOpen, setOrganizationDialogOpen] = useState(false)
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)

  const [ cartItems, setCartItems] = useState();

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [briefsState, setBriefsState] = useState()
  const [pitchedSongs, setPitchedSongs] = useState()
  const [boughtSongsInfo, setBoughtSongsInfo] = useState({month: '', year: '', allTime: ''})

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
  );


  const { loading: cartLoading, error: cartError, data: cartData = {getUserCart: []}, refetch: cartRefetch } = useQuery(GET_USER_CART, {
    variables: { orgId: user.organization },
    fetchPolicy: 'network-only'
  });


  // let theBriefs = null;

  async function getAllOrgBriefs(){
    const actBriefs = await getActiveBriefsByOrgM({ variables: { orgId: user?.organization}}); // eslint-disable-line
    const pitchedDocs = await getDocsPitchedToOrgM({ variables: { orgId: user?.organization}}); // eslint-disable-line

    const sortedBriefs = actBriefs?.data?.getActiveBriefsByOrgM.sort((a, b) => new Date (b.post.createdAt) - new Date(a.post.createdAt))
    setBriefsState(sortedBriefs)
    setPitchedSongs(pitchedDocs?.data?.getDocumentsByPitchedToOrgM)

  }


  function findBriefPitches(briefTitle){
    let total = 0;
    pitchedSongs.map(item => { // eslint-disable-line
      item.pitches.map(pitch => { // eslint-disable-line
        if (pitch.briefTitle === briefTitle && pitch.status === 'pitched')
         total += 1
      })
    })
    return total
  }
  
  async function getBoughtSongsInfo(){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getboughtsongsinfo`, {  // eslint-disable-line
        orgId: user?.organization,
      }).then(response => {
        setBoughtSongsInfo(response?.data?.songTotals)
        // successCallback();
        // console.log(response)
      });
    } catch (err) {
        console.log(err.response.data)
    
  }
  }

  useEffect(()=>{
    getAllOrgBriefs();
    getBoughtSongsInfo()
  },[]) // eslint-disable-line
      

  useEffect(()=>{
  
    if (cartData !== undefined) {
      setCartItems(cartData?.getUserCart.length || 0)
    }
  },[cartData]) // eslint-disable-line



  return (
    <>
    <Grid container  
     // page grid, left cards, right artist icons
      sx={{
        pb: 10,
        ml: 0,
        mr: 2,
        // border: 'red solid 1px'
      }}
      >
      <Grid item xs={12} md={12}
      // left side cards
        sx={{
          pl: 0,

        }}
      
      >
        <Grid container spacing={3} >

          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Current Briefs' 
              total={briefsState?.length || '0'} 
              statsLoading
              color='primary' 
              textColor={theme.palette.text.primary}
              // cardColor={theme.palette.primary.light}
              cardColor='#B1AB99'
              />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCardSongsBought
              title='Songs Bought' 
              total='0'
              statsLoading
              color='primary' 
              textColor={theme.palette.text.primary}
              cardColor='#4C8077'
              boughtSongsInfo={boughtSongsInfo}
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Inventory' 
              // total= {100}
              // totalPrefix='$'
              onClick={()=>{navigate('/inventory')}}
              color='primary' 
              noTotal
              textColor={theme.palette.common.white}
              cardColor={theme.palette.primary.main}
              darkColor={theme.palette.primary.dark}
              icon="game-icons:sound-waves"
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
              <AAWidgetCard 
                title='Orders' 
                onClick={()=>{navigate('/orders')}}
                // total={24} 
                noTotal
                color='info' 
                textColor={theme.palette.common.white}
                cardColor={theme.palette.text.primary}
                darkColor={theme.palette.text.primary}
                icon="nimbus:invoice"
              />
          </Grid>  
          <Divider sx={{width: '98%', m: 3, }}/>
          <Stack 
            direction='row' 
            spacing={2} 
            height='100%' 
            width='100%' 
            justifyContent='space-between' 
            alignItems='center'
            // border= 'pink solid 1px'
          >
              <Typography variant='h4' 
                sx={{
                  p: 3,
                  pr: 0,
                  width: 'fit-content'
                }}
              >
                Recent Briefs
              </Typography>
            <Box 
              sx={{
                pt: 1.5, 
                display: 'flex', 
                alignItems: 'flex-start', 
                height: '100%', 
                // border: 'blue solid 1px',
              }}>
              <Link href='/briefs'
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  // border: 'orange solid 1px',
                }}
              >
                <Typography variant='body' 
                  sx={{
                    height: '100%',
                  }}
                >
                  All Briefs
                </Typography>
                <Iconify 
                  icon='material-symbols:arrow-right' 
                  width='30px'
                  sx={{
                    height: '100%',
                    // border: 'red solid 1px',
                    p: 0,
                    m: 0,
                  }}
                />
              </Link>
            </Box>
          </Stack>
          <Grid container 
            spacing={3} 
            sx={{
              pl: 3,
            }} 
          >
          {briefsState !== undefined && 
          briefsState.length > 0 ? briefsState?.map((item, indexx) => {  // eslint-disable-line
            if (indexx < 4){
              return <Grid item key={item._id}
              xs={6} sm={6} md={3} 
              >
          <Link 
            href={user?.role === 'client' ? `/briefs/client/${item._id}` : `/briefs/${item._id}`}
          >  
            <Card
              sx={{
                cursor: 'pointer',
                transition: 'all .2s ease-in-out',
                overflow: 'hidden',
                height: '250px',
                width: '100%',
                // backgroundColor: 'pink',
                // opacity: item.post?.active ? 1 : .4,
                '&:hover': {
                  // boxShadow: '-8px 8px 2px rgba(0, 0, 0, 0.1)',
                },
              }}
      
            >
              <Box // overlay
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  zIndex: 7,
                  backgroundColor: alpha(theme.palette.grey[900], .2),
                }}
              />
              <Box // overlay
                sx={{
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  zIndex: 8,
                  background: 'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0) 40%)'
                }}
              />
              <Typography variant='body'
                sx={{
                  cursor: 'pointer',
                  top: 14,
                  left: 14,
                  zIndex: 9,
                  position: 'absolute',
                  color: theme.palette.common.white,
                  fontFamily: "peckham-press, sans-serif",
                  fontSize: '12px',
                  lineHeight: '18px',
                  border: `${theme.palette.common.white} 2px solid`,
                  borderRadius: '32px',
                  padding: 1,
                  paddingTop: 1.5,
                  backgroundColor: `${theme.palette.grey[900]}80`,
                }}
              >
                {item.post.category}
              </Typography>
              
              <Box // current pitches wrapper
                sx={{
                  display: user?.role === 'client' ? 'flex' : 'none',
                  position: 'absolute',
                  // top: 2,
                  // right: 2,
                  height: '100%',
                  width: '100%',
                  justifyContent: 'flex-end',
                  zIndex: 10,
                }}
              >
                <Stack direction='column'
                  sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    display: 'flex',
                    justifyContent: 'center',
                    alignContent: 'center',
                    zIndex: 200,
                    mx: 1.5,
                    my: 1,
                    // px: 3.3,
                    // py: 2,
                    borderRadius: '50%',
                    // border: 'white solid 1px',
                    
                  }}
                >

                <Typography variant='h5'
                  sx={{
                    textShadow: theme.shadows[8],
                    color: 'white',
                    textAlign: 'center'
                    
                  }}
                >
                  {findBriefPitches(item?.post?.title).toString()}
                </Typography>
                <Typography variant='caption'
                  sx={{
                    textShadow: theme.shadows[8],
                    color: 'white',
                    textAlign: 'center'
                  }}
                >
                  Pitches
                </Typography>
              </Stack>
              <Box // current pitches number
                sx={{
                  position: 'absolute',
                  top: -200,
                  right: -200,
                  height: '300px',
                  width: '300px',
                  // borderRadius: '50%',
                  backgroundColor: alpha(theme.palette.success.dark, 1),
                  display: 'flex',
                  alignContent: 'flex-end',
                  justifyContent: 'flex-start',
                  transform: 'rotate(45deg)',
                  zIndex: 4,
                }}/>
              </Box>

              <Stack direction='column' alignItems='flex-start'
                sx={{
                  cursor: 'pointer',
                  bottom: 14,
                  left: 12,
                  zIndex: 9,
                  position: 'absolute',
                  borderRadius: '32px',

                }}
              >
                <Typography variant='subtitle1'
                  sx={{
                    color: theme.palette.common.white,
                    // fontSize: '12px',
                    // lineHeight: '18px',
                    // border: `${theme.palette.common.white} 2px solid`,
                    pr: 1,
                    // paddingTop: 1.5,
                    // backgroundColor: `${theme.palette.grey[900]}80`,
                  }}
                >
                  {item.post.title}
                </Typography>
                <Typography variant='caption2'
                  sx={{
                    color: theme.palette.common.white,
                    fontSize: '12px',
                    lineHeight: '18px',
                    // border: `${theme.palette.common.white} 2px solid`,
                    pr: 1,
                    // paddingTop: 1.5,
                    // backgroundColor: `${theme.palette.grey[900]}80`,
                  }}
                >
                  {fDate(item.post.createdAt || null)}
                </Typography>

              </Stack>
              <Box // corner wrapper
                sx={{
                  display: 'none',
                  position: 'absolute',
                  bottom: 0,
                  right: 0,
                  zIndex: 8,
                  width: '100%',
                  height: '100%',
                  rotate: '-40deg',
                  translate: '50% 70%',
                  backgroundColor: alpha(theme.palette.common.black, .7),
                }}
              />
              <Image 
                src={item.post.cover}
                sx={{ height: 360 }} 
              />
            </Card>
          </Link>
  
                {/* <BriefPostCard post={item.post} id={item._id }/> */}
            </Grid>
          }
          
          }) :
          <Grid item
          xs={6} sm={6} md={3} 
          >
            <BriefsEmptyCard />
          </Grid>
          }
          </Grid>


          <Grid item xs={12} sm={12} md={12}>
            <ProfileAbout
              user={user}
              quote={info.quote}
              country={info.country}
              email={info.email}
              role={info.role}
              company={info.company}
              school={info.school}
              setPaymentDialogOpen={setPaymentDialogOpen}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
    
    <AddArtistDialog 
        artistDialogOpen={artistDialogOpen}
        setArtistDialogOpen={setArtistDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />    
    <CreateOrganizationDialog
        organizationDialogOpen={organizationDialogOpen}
        setOrganizationDialogOpen={setOrganizationDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />
    <AccountDialog 
        accountDialogOpen={accountDialogOpen}
        setAccountDialogOpen={setAccountDialogOpen}
        user={user}
        userr={userr}
        setToast={setToast}
        refetch={refetch}
    />
    <CartWidgetCustom totalItems={cartItems} />

    <Elements stripe={stripePromise}>
      <PaymentDialog 
        paymentDialogOpen={paymentDialogOpen}  
        setPaymentDialogOpen={setPaymentDialogOpen}  
        user={user}
        userr={userr}
        refetch={refetch}
      />

    </Elements>
    <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
