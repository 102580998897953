import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Button, Container, Typography, DialogActions, Dialog, Divider, Stack } from '@mui/material';
import axios from 'axios';
// components
import Image from '../components-default/image';
import Markdown from '../components-default/markdown';
import Scrollbar from '../components-default/scrollbar';
import EmptyContent from '../components-default/empty-content';

import aaIcon from '../assets/icons/pikes-white.svg'

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

const StyledTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.h3,
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h2,
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const StyledFooter = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
  },
}));

CounterSignDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  setContractsList: PropTypes.func,
  getOpenContracts: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  signLink: PropTypes.string,
  contractListId: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default function CounterSignDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  signLink,
  onSubmit,
  contractListId,
  setContractsList,
  getOpenContracts,
}) {
  // const { title = '', content = '', description = '' } = values;

  console.log('CONTRACT LIST ID: ', contractListId)


  async function removeContractListItem(){
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/getopencontracts`, {  // eslint-disable-line
      method: 'deleteListItem',
      itemId: contractListId,
    })
    getOpenContracts()
    onClose()
    // setContractsList(res.data)
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose} >
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Approve Contract
        </Typography>

        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          // disabled={!isValid}
          loading={isSubmitting}
          onClick={()=>{
            removeContractListItem()
          }}
        >
          Mark Complete
        </LoadingButton>
      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 5, 
              mb: 10, 
              // px: 6,
              // ml: 10, 
              // mr: 20, 
              height: '500px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
          <iframe title='AudioArsenal Contract' 
            src={signLink}
            width="100%" height="100%" frameBorder="0" 
            style={{border: 0, overflow: 'hidden', minHeight: '500px', minWidth:' 400px', }}/>
          </Container>
        </Scrollbar>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

