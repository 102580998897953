import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Button, Typography } from '@mui/material';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// utils
import { fDate } from '../../../../utils/formatTime';

// components
import Image from '../../../../components-default/image';
import Iconify from '../../../../components-default/iconify';
import aaIcon from '../../../../assets/icons/pikes-white.svg';
import tbIcon from '../../../../assets/icons/TB_Record_Small.png'
import isAA from '../../../isAA';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.34),
}));

const StyledTitle = styled('h1')(({ theme }) => ({
  // ...theme.typography.h4,
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  textShadow: '3px 4px 0px rgba(0, 0, 0, 0.2)',
  fontFamily: isAA() ? "peckham-press, sans-serif !important" : "gravesend-sans, sans-serif !important",
  fontSize: {
    xs: '22px !important',
    sm: '28px !important',
    md: '32px !important',
    lg: '32px !important',
    xl: '32px !important',
  },
  '&h1':{
    fontSize: '56px',
  },
  [theme.breakpoints.up('xs')]: {
    ...theme.typography.h3,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h2,
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.h2,
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(5),
  },  
  // [theme.breakpoints.up('xs')]: {
  //   ...theme.typography.h3,
  //   padding: theme.spacing(3),
  // },
}));

const StyledRate = styled('h3')(({ theme }) => ({
  // ...theme.typography.h4,
  bottom: 0,
  right: 5,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  textShadow: '3px 4px 0px rgba(0, 0, 0, 0.2)',
  // fontFamily: "peckham-press, sans-serif !important",
  fontSize: 
    // '32px',
  {
    xs: '22px !important',
    sm: '28px !important',
    md: '32px !important',
    lg: '32px !important',
    xl: '32px !important',
  },

  [theme.breakpoints.up('xs')]: {
    ...theme.typography.h3,
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h2,
    padding: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    ...theme.typography.h2,
    padding: theme.spacing(0),
    paddingLeft: theme.spacing(5),
  },  
}));

const StyledFooter = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  // backgroundColor: theme.palette.text.primary,
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

BriefPostHero.propTypes = {
  post: PropTypes.object,
  orgInfo: PropTypes.object,
  user: PropTypes.object,
  briefId: PropTypes.string,
  editable: PropTypes.bool,
};

export default function BriefPostHero({ post, orgInfo, user, briefId, editable }) {
  const { cover, category, title, createdAt } = post;
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'sm');

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
        borderRadius: {
          xs: `16px 16px 16px 16px !important`,
          sm: `16px 16px 16px 16px !important`,
          md: `16px 16px 0 0 !important`,
          lg: `16px 16px 0 0 !important`,
          xl: `16px 16px 0 0 !important`,
        },
        height: {
          xs: '300px',
          md: '300px',
        },
        cursor: 'default'
      }}
    >
      <StyledTitle>
        <Typography variant='h1' fontFamily={isAA() ? "peckham-press, sans-serif !important" : "gravesend-sans, sans-serif !important"}
          sx={{
            fontSize: {
              xs: '2rem',
              sm: '3rem',
              md: '4rem',
            }
          }}
        >
          {title}
        </Typography>
        {post?.active === false &&
          <Typography variant='h4' color='error'
            sx={{
              borderRadius: 2,
              backgroundColor: 'black',
              width: 'fit-content',
              px: 2,
            }}
          >
            Inactive
          </Typography>
        }
        
      </StyledTitle>

      <StyledFooter>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%', }}>
      {editable ?
        <Box sx={{
          position: 'absolute',
          top: {
            md: 0,
            lg: 8,
          },
          right: 40,
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          mb: 1,
        }}
        >
          <Button
            component={RouterLink}
            to={`/briefs/edit/${briefId}`}
            variant="outlined"
            color='primary'
            size='small'
            startIcon={<Iconify icon="eva:edit-fill" />}
            sx={{color: 'white', py: 0, height: '28px',}}
          >
            Edit Brief
          </Button>
        </Box> : null
        }
          <Image 
              alt="icon" 
              src={isAA() ? aaIcon : tbIcon} 
              sx={{ 
                width: (post?.subGenres.length > 0 && post?.subGenres[0] !== '') ? 62 : 42,  // eslint-disable-line 
                height: (post?.subGenres.length > 0 && post?.subGenres[0] !== '') ? 62 : 42,  // eslint-disable-line 
                p: isAA() ? '5px' : 0,
                borderRadius: isAA() ? '50%' : '50%',
                border: isAA() ? 'white solid 1px' : `none`,
                // outline: isAA() ? 'none' : `${theme.palette.primary.main} solid 10px`,
                backgroundColor: isAA() ? `${theme.palette.grey[900]}80`: 'transparent',
                transform: isAA() ? 'inherit' : 'scale(1.1)',
                pb: isAA() ? 0 : '1px',
              }} 
              
          />
          <Box sx={{ ml: 2, cursor: 'default', width: '100%', display: 'flex', justifyContent: 'space-between', }}>
            <Box sx={{width: '100%', }}>

            <Typography 
              variant='subtitle2'
              sx={{ color: 'common.white', pb: 0 }}
            >
              {user?.role === 'client' ? 
                orgInfo?.name || process.env.REACT_APP_SITE_NAME :
                process.env.REACT_APP_SITE_NAME
              }
            </Typography>

            <Typography
              variant='body2'  
              sx={{
                // py: 1,
                // px: { md: 5 },
                // py: (post?.subGenres.length > 0 && post?.subGenres[0] !== '') ? 'inherit' : .5,
                cursor: 'default',
                color: 'common.white',
              }}
            >
              <b>Genre:</b> <i>{category !== 'Various' ? category : post?.multiGenres[0].replace(',', ', ')}</i>
            </Typography>
            {(post?.subGenres.length > 0 && post?.subGenres[0] !== '') &&
              <Typography
                variant='body2'  
                sx={{
                  // py: 1,
                  // px: { md: 5 },
                  cursor: 'default',
                  color: 'common.white',
                }}
              >
                <b>Sub Genre:</b> <i>{post?.subGenres.toString().replace(',', ', ')}</i>
              </Typography> 
            }
            </Box>
            <Typography 
              variant='caption'        
              sx={{ 
                color: 'common.white', 
                width: '100px', 
                // height: '100px',
                display: 'flex', 
                alignItems: 'flex-end' 
              }}
            >
              {fDate(createdAt)}
            </Typography>
          </Box>
        </Box>

      </StyledFooter>

      <StyledOverlay />

      <Image 
        alt="cover" 
        src={cover} 
        sx={{
          height: {
            xs: '300px',
            sm: 'inherit',
          }
          // mt: -6,
          // backgroundSize: '150%',
        }}
        // src='/assets/images/test/camouflage.jpg' 
        // ratio="16/9" 
        />
    </Box>
  );
}
