import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Card, Stack, Typography } from '@mui/material';
// utils
import { bgGradient } from '../utils/cssStyles';
// components
import Iconify from '../components-default/iconify';

// ----------------------------------------------------------------------

AAWidgetCardPitches.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.string,
  statsLoading: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
  cardColor: PropTypes.string,
  darkColor: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.any,
  money: PropTypes.bool,
  noTotal: PropTypes.bool,
  totalPrefix: PropTypes.string,
  onClick: PropTypes.func,
  allPitchesInfo: PropTypes.number,
  wavMakerPitchesInfo: PropTypes.number,
};

export default function AAWidgetCardPitches({
  title,
  total,
  money,
  noTotal,
  statsLoading,
  totalPrefix,
  icon,
  color = 'primary',
  textColor,
  cardColor,
  darkColor,
  onClick,
  allPitchesInfo,
  wavMakerPitchesInfo,
  sx,
  ...other
}) {
  const theme = useTheme();

  const [displayType, setDisplayType] = useState([{period: 'All', amount: 0}, {period: 'WavMaker', amount: 0}])
  const [displayIndex, setDisplayIndex] = useState(1)

  useEffect(()=>{ 
    const tempArray = displayType
    tempArray[0] = {period: 'All', amount: allPitchesInfo}
    setDisplayType(tempArray)
  },[allPitchesInfo, displayType]) // eslint-disable-line

  useEffect(()=>{ 
    const tempArray = displayType
    tempArray[1] = {period: 'WavMaker', amount: wavMakerPitchesInfo}
    setDisplayType(tempArray)
  },[wavMakerPitchesInfo, displayType]) // eslint-disable-line


  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        pt: {
          xs: 3,
          sm: 4,
        },
        pb: 3,
        boxShadow: 0,
        height: {
          xs: '150px',
          sm: '200px',
        },
        textAlign: 'center',
        color: textColor || '#bada55',
        bgcolor: cardColor || '#nnn',
        ...bgGradient({
          direction: '135deg',
          startColor: `${alpha(cardColor || '#nnn', 0.9)} 0%`,
          endColor: `${alpha(cardColor || '#nnn', .8)} 50%`,
        }),
        ...sx,
      }}
      {...other}
    >
      <Typography variant="h5" sx={{ color: theme.palette.common.white, opacity: 0.84 }}>
        {title}
      </Typography>
      <Stack direction='row' marginTop={3} justifyContent='center' alignItems='center'>
        <Iconify icon='bxs:left-arrow' 
          sx={{color: theme.palette.common.white, cursor: 'pointer'}}
          onClick={()=>{setDisplayIndex(displayIndex === 0 ? (displayType.length-1) : displayIndex -1)}}
          />
        <Typography variant="h3" 
          sx={{
            color: theme.palette.common.white,
            width: '90px',
            // mx: 5,
          }}
          > 
          {displayType[displayIndex].amount}
        </Typography>
        <Iconify icon='bxs:right-arrow' 
          sx={{color: theme.palette.common.white, cursor: 'pointer'}}
          onClick={()=>{setDisplayIndex(displayIndex === (displayType.length-1) ? 0 : displayIndex +1)}}
        />

      </Stack>
      <Typography variant="subtitle2" 
          sx={{
            color: theme.palette.common.white,
            mt: 1.5,
          }}
        > 
          {displayType[displayIndex].period}
        </Typography>


      {icon &&
        <Iconify
          icon={icon}
          sx={{
            mt: {
              xs: 1,
              sm: 2,
            },
            p: 2.5,
            width: title === 'Upload Song' ? 64 : 64,
            height: title === 'Upload Song' ? 64 : 64,
            borderRadius: '50%',
            border: `${darkColor}80 solid 1px`,
            color: `${textColor}`,
            ...bgGradient({
              direction: '135deg',
              startColor: `${alpha(theme.palette.divider, 0)} 0%`,
              endColor: `${alpha(darkColor, 0.24)} 100%`,
            }),
          }}
        />
      
      }

    </Card>
  );
}
