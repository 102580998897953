import PropTypes from 'prop-types';

import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Grow from '@mui/material/Grow';
import { Alert } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import DeleteIcon from '@mui/icons-material/Delete';

function SlideTransition(props) {
  return <Slide {...props} direction='up' />;
}

function GrowTransition(props) {
  return <Grow {...props} />;
}

CustomToast.propTypes = {
    toast: PropTypes.object,
    setToast: PropTypes.func,
  }


export default function CustomToast({toast, setToast}) {

    const theme = useTheme();

    const [state, setState] = React.useState({
        open: false,
        Transition: Slide,
      });
    
      const handleClick = (Transition) => () => {
        setState({
          open: true,
          Transition,
        });
      };
    
      const handleClose = () => {
        setToast({...toast, open: false});
      };
    
      return (
        <div>
          <Snackbar
            open={toast.open}
            onClose={handleClose}
            TransitionComponent={state.Transition}
            key={state.Transition.name}
            anchorOrigin={{horizontal: toast.horizontal || 'right',  vertical: toast.vertical || 'top'}}
            autoHideDuration={toast.duration || 3000}
            sx={{cursor: 'default'}}
          >  
            <Alert 
                severity='success' 
                icon={toast.icon} 
                sx={{
                  color: 'white', 
                  background: toast.toastColor || theme.palette.primary.main,
                }} 
                onClose={toast.closeButton ? handleClose : null}
            >
              {toast.message}
            </Alert>
        </Snackbar>
        </div>
      );
}