import PropTypes from 'prop-types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Chip,
  Stack,
  IconButton,
  Typography,
  InputAdornment,
  TextField,
  Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';
import { useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import { sub } from 'date-fns';
import Iconify from '../components-default/iconify';
import { genreList, subGenreList, instrumentList, moodList } from './TagList';



export default function FilterPane(props) {

  
  const theme = useTheme();
  const navigate = useNavigate();

  const {
    isPlaying,
    showFilter,
    setShowFilter,
    setFilteredDisplayFiles,
    searchTerm,
    setSearchTerm,
    setShowSearchTerm,
    setUpdateTrigger,
    fullPath,
    filter,
    setFilter,
    isAdmin
  } = props;

  const icon = useMemo(() => { // eslint-disable-line
    return !showFilter ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'

  }
  , [showFilter]);

  const toggleShowFilter = useCallback(() => {
    setShowFilter(prevShowFilter => !prevShowFilter);
  }, [setShowFilter]);

  const clearFilters = useCallback(() => {
    setFilter([]);
    setFilteredDisplayFiles([]);
  }, [setFilter, setFilteredDisplayFiles]);

  const handleInputChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []); // eslint-disable-line

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      setUpdateTrigger(true);
      setShowSearchTerm(searchTerm);
      navigate(fullPath);
    }
  }, [searchTerm, fullPath]); // eslint-disable-line

  const handleKeyUp = useCallback((e) => {
    // this is so that backspacing to clear search will trigger update
    if (searchTerm === '') {
      // ...
    }
  }, [searchTerm]);

  const subGenrePills = Object.keys(subGenreList).map(key => 
    subGenreList[key].map(filterItem => { // eslint-disable-line
      // console.log(key)
      if (filter.includes(key)){
        return <Chip
          key={filterItem}
          label={filterItem}
          variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
          color={filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : 'info')} // eslint-disable-line
          sx={{
            border: 'none',
            m: .5,
            color: filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : theme.palette.text.disabled) // eslint-disable-line
          }}
          size="small"
          onClick={() => {
            if (isPlaying) {
              // setControlMessage('play-pause')
            }
            const currentFilters = [...filter]
            if (currentFilters.includes(filterItem)) {
              const removedFilter = currentFilters.filter((x) => x !== filterItem)
              setFilter(removedFilter)
            } else {
              currentFilters.push(filterItem)
              setFilter(currentFilters)
            }
          }}
        />
      }
    }
    )
  );  


  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mr: 4, mt: 0, gap: 3 }}>
        <Stack direction='row' width='100%' alignItems='center'
          display='flex'
        >
          <Iconify icon={icon}
            sx={{
              // mx:2,
              pl: 0,
              // width: '34px',
              cursor: 'pointer',
              color: theme.palette.info.light,
              display: {
                sm: 'inherit',
                md: 'none',
              },
            }}
            onClick={toggleShowFilter}
          />

          <Typography variant="h5" sx={{ mb: 0 }}>
            Filters
          </Typography>
        </Stack>
        <Button
          variant="text"
          onClick={clearFilters}
          sx={{ m: 0, p: 0, textTransform: 'none', fontSize: '12px', fontWeight: '200', color: theme.palette.primary.darker }}
        >
          clear filters
        </Button>
      </Box>

      <Box sx={{
        display: showFilter ? 'flex' : 'none',
        flexWrap: 'wrap',
        justifyContent: {
          xs: 'flex-start',
          sm: 'flex-start',
        },
        m: 0,
        p: 0,
        pt: 2,
        pr: 1,
        pb: 3,
      }}>
        <TextField fullWidth
          size='small'
          placeholder={isAdmin ? 'Artist, Song or User' : 'Search artist or song'}
          autoComplete='off'
          color='info'
          type='text'
          sx={{
            pt: 1,
            pb: 2,
            mr: 2,
          }}
          value={searchTerm}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          InputProps={{
            // startAdornment: (
            // ),
            endAdornment: (
              <>
                {searchTerm &&
                  <InputAdornment position="end"
                    onClick={() => {
                      setSearchTerm('')
                      setShowSearchTerm('')
                      setUpdateTrigger(true)
                      // navigate('/inventory')
                    }}
                    sx={{ cursor: 'pointer' }}
                  >
                    <CancelIcon />
                  </InputAdornment>
                }
                <InputAdornment position="end"
                  sx={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (searchTerm !== '') {
                      setUpdateTrigger(true)
                      setShowSearchTerm(searchTerm)
                      // navigate(`/inventory?search=${searchTerm}`)
                    }
                  }}
                >
                  <SearchIcon />
                </InputAdornment>
              </>
            ),
          }}
        />

        <Typography variant='caption' width='100%' fontWeight='bold'>
          Genre
        </Typography>

        {genreList.map((filterItem) => <Chip
          key={filterItem}
          label={filterItem}
          variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
          color={filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : 'info')} // eslint-disable-line
          sx={{
            border: 'none',
            m: .5,
            color: filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : theme.palette.text.disabled) // eslint-disable-line
          }}
          size="small"
          onClick={() => {
            if (isPlaying) {
              // setControlMessage('play-pause')
            }
            const currentFilters = [...filter]
            if (currentFilters.includes(filterItem)) {
              // console.log('no')
              const removedFilter = currentFilters.filter((x) => x !== filterItem)
              console.log(removedFilter)
              setFilter(removedFilter)
            } else {
              console.log(filterItem)

              currentFilters.push(filterItem)
              // console.log('Current Filters: ', currentFilters)
              setFilter(currentFilters)
            }
          }}
        />)
        }
        {subGenrePills}
        <Typography variant='caption' width='100%' fontWeight='bold' sx={{ mt: 2 }}>
          Instrument
        </Typography>

        {instrumentList.map((filterItem) => <Chip
          key={filterItem}
          label={filterItem}
          variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
          color={filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : 'info')} // eslint-disable-line
          sx={{
            border: 'none',
            m: .5,
            color: filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : theme.palette.text.disabled) // eslint-disable-line
          }}
          size="small"
          onClick={() => {
            if (isPlaying) {
              // setControlMessage('play-pause')
            }
            const currentFilters = [...filter]
            if (currentFilters.includes(filterItem)) {
              // console.log('no')
              const removedFilter = currentFilters.filter((x) => x !== filterItem)
              console.log(removedFilter)
              setFilter(removedFilter)
            } else {
              console.log(filterItem)

              currentFilters.push(filterItem)
              console.log('Current Filters: ', currentFilters)
              setFilter(currentFilters)
            }
          }}
        />)
        }
        <Typography variant='caption' width='100%' fontWeight='bold' sx={{ mt: 2 }}>
          Mood
        </Typography>

        {moodList.map((filterItem) => <Chip
          key={filterItem}
          label={filterItem}
          variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
          color={filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : 'info')} // eslint-disable-line
          sx={{
            border: 'none',
            m: .5,
            color: filter.length === 0 ? 'primary' : (filter.includes(filterItem) ? 'primary' : theme.palette.text.disabled) // eslint-disable-line
          }}
          size="small"
          onClick={() => {
            if (isPlaying) {
              // setControlMessage('play-pause')
            }
            const currentFilters = [...filter]
            if (currentFilters.includes(filterItem)) {
              // console.log('no')
              const removedFilter = currentFilters.filter((x) => x !== filterItem)
              console.log(removedFilter)
              setFilter(removedFilter)
            } else {
              console.log(filterItem)

              currentFilters.push(filterItem)
              console.log('Current Filters: ', currentFilters)
              setFilter(currentFilters)
            }
          }}
        />)
        }
      </Box>
    </>


  );
};

FilterPane.propTypes = {
  filter: PropTypes.array,
  setFilter: PropTypes.func,
  showFilter: PropTypes.bool,
  setShowFilter: PropTypes.func,
  setFilteredDisplayFiles: PropTypes.func,
  searchTerm: PropTypes.string,
  setSearchTerm: PropTypes.func,
  setShowSearchTerm: PropTypes.func,
  setUpdateTrigger: PropTypes.func,
  isPlaying: PropTypes.bool,
  // setIsPlaying: PropTypes.func,
  // setControlMessage: PropTypes.func,
  // setSelectedIndex: PropTypes.func,
  fullPath: PropTypes.string,
  isAdmin: PropTypes.bool,
};
