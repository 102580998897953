import { Helmet } from 'react-helmet-async';
import { useContext, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Divider, Grid, Stack, Container, Typography, Select, MenuItem, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CheckIcon from '@mui/icons-material/Check';


import { useQuery, useMutation } from "@apollo/client";

import Footer from '../layouts/main/Footer';
// routes
// utils
import { useAuthContext } from '../auth/useAuthContext';
import { fCurrencyCents } from '../utils/formatNumber';


import axios from '../utils/axios';
import { GET_USER_AVAILABLE_DOCS_BY_ID } from '../graphql/queries'
import AudioPlayerBar from '../components-custom/AudioPlayerBar';



// components
import Markdown from '../components-default/markdown';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
import { useSettingsContext } from '../components-default/settings';
// sections
import {
  BriefPostHero,
} from '../components-custom/sections/@dashboard/blog';
import { DELETE_BRIEF_BY_ID, PITCH_SONG_TO_BRIEF } from '../graphql/mutations';
import LoadingScreen from '../components-default/loading-screen';
import SongPitchedSquare from '../components-custom/SongPitchedSquare';
import CustomToast from '../components-custom/CustomToast';
import AudioFileCardProducer from '../sections/@dashboard/general/file/AudioFileCardProducer';
import Iconify from '../components-default/iconify/Iconify';
import SignUpDialog from '../components-custom/SignUpDialog';
import TBSubscriptionLink from '../components-custom/TBSubscriptionLink';
import AASubscriptionLink from '../components-custom/AASubscriptionLink';
import isAA from '../components-custom/isAA';
import { parseReferenceLink } from '../utils/parse-ref-link';
import { AudioContext } from '../components-custom/audio/audio-context';
import MultisUploadDrawer from '../components-custom/MultisUploadDrawer';


// ----------------------------------------------------------------------

export default function BriefDetailUserPage() {
  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  // const queryMessage = queryParams.get('message');  
  // const querySongId = queryParams.get('songId');  

  const querySuccess = queryParams.get('updatesuccess');

  const multisUploadType = process.env.REACT_APP_MULTIS_UPLOAD_TYPE 


  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  const theme = useTheme();

  const { briefId } = useParams();


  const [post, setPost] = useState(null);

  const [quantity, setQuantity] = useState(null)
  const [fulfilled, setFulfilled] = useState(null)

  const [loadingPost, setLoadingPost] = useState(true);

  const [errorMsg, setErrorMsg] = useState(null);


  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const [spotifyPlaylistId, setSpotifyPlaylistId] = useState('')
  const [youtubePlaylistId, setYoutubePlaylistId] = useState('')


  const [songSubmission, setSongSubmission] = useState('')

  const [axiosResults, setAxiosResults] = useState([])
  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });
  const [signUpOpen, setSignUpOpen] = useState(false)
  const [openMultisUploadDrawer, setOpenMultisUploadDrawer] = useState(false)
  const [multisInfo, setMultisInfo] = useState({
    title: '',
    _id: '',
    multitrackUrl: '',
  })


  const [pitchSongToBrief] = useMutation(PITCH_SONG_TO_BRIEF);

  const [deleteBrief] = useMutation(DELETE_BRIEF_BY_ID);

  const { loading: songsLoading, error: songsError, data: songsData = { getAvailableDocumentsByOwnerId: [] }, songsRefetch } = useQuery(GET_USER_AVAILABLE_DOCS_BY_ID, {
    variables: { userId: userr },
    fetchPolicy: 'network-only'
  });

  async function getBrief() {
    setLoadingPost(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getsinglebrief`, {  // eslint-disable-line
        briefId,
        type: 'producer'
      }).then(response => {
        console.log('render')

        if (response?.data?.brief?.post?.spotifyLink !== '') {
          try {
            const spotifyTemp1 = response?.data?.brief?.post?.spotifyLink.split('/playlist/') || '123?123'
            const spotifyTemp2 = spotifyTemp1[1].split('?')
            setSpotifyPlaylistId(spotifyTemp2[0])
          } catch (err) {
            console.log(err)
          }
        }
        if (response?.data?.brief?.post?.youtubeLink !== '') {
          try {
            const youtubeTemp1 = response?.data?.brief?.post?.youtubeLink.split('list=') || '123?123'
            const youtubeTemp2 = youtubeTemp1[1].split('&')
            setYoutubePlaylistId(youtubeTemp2[0])
          } catch (err) {
            console.log(err)
          }
        }
        setPost(response?.data?.brief?.post)
        setQuantity(response?.data?.brief?.post?.quantity)
        setFulfilled(response?.data?.brief?.post?.fulfilled)
        if (querySuccess === 'true') {
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${response?.data?.brief?.post?.title} successfully updated`, duration: 4000 })
        }
        setLoadingPost(false)
      }
      );
    } catch (err) {
      console.log(err)
    }
  }



  async function getPitchedSongs() {
    try {
      // setAxiosLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventory',
        briefId,
        // modifier: 'sold',
        userId: user?._id,
      }).then(response => {
        // console.log(response.data)
        const sortedFiles = response?.data?.docs.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))

        setAxiosResults(sortedFiles)
      });
    } catch (err) {
      console.error('Error fetching search results:', err);
    }
  }

  function closeSignUp() {
    setSignUpOpen(false)
  }

  async function createReceipt() {
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/generalreceipts`, {  // eslint-disable-line
      userId: user._id,
      elementKey: 'briefs',
      elementValue: briefId
    })
  }

  useEffect(() => {
    createReceipt()
    getBrief()
    getPitchedSongs()
  }, []) // eslint-disable-line


  if (loadingPost) {
    return <LoadingScreen />
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME} || Brief </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          // heading="Post Details"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Briefs',
              href: '/briefs',
            },
            {
              name: post?.title,
            },
          ]}
        />

        {post && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, pb: 10 }}>
            {/* {user.role ==='admin' &&  */}
            {user.role === 'admin' &&
              <Box sx={{ mb: 0, display: 'flex', justifyContent: 'flex-end', gap: 3 }}>

                <Button variant='text' onClick={() => { navigate(`/briefs/client/${briefId}`) }}>
                  Client View
                </Button>
                <Button variant='text' onClick={() => { console.log(songSubmission) }}>
                  Song Submission
                </Button>
                <Button variant='text' onClick={() => { navigate(`/briefs/edit/${briefId}`) }}>
                  Edit Brief
                </Button>

                <Button variant='text' onClick={async () => {
                  setDeleteConfirm(true)
                }}
                >
                  Delete
                </Button>
                <Button
                  variant='contained'
                  onClick={async () => {
                    await deleteBrief({ variables: { id: briefId } });
                    setDeleteConfirm(false)
                    navigate('/briefs')
                    console.log("DELETED IT")
                  }}
                  sx={{
                    display: deleteConfirm ? 'block' : 'none',
                    backgroundColor: 'error.main',
                    color: 'white',
                    "&:hover": {
                      backgroundColor: 'error.dark',

                    }
                  }}
                >
                  Are you sure?
                </Button>

                <Button
                  variant='outlined'
                  onClick={async () => {
                    setDeleteConfirm(false)
                  }}
                  sx={{
                    display: deleteConfirm ? 'block' : 'none',
                    color: 'error.main',
                    backgroundColor: 'none',
                    border: 'red solid 1px !important',
                  }}
                >
                  Cancel
                </Button>
              </Box>
            }
            <Link onClick={() => { navigate(-1) }}
              sx={{
                pl: 0,
                mb: 1,
                '&a': {
                  textDecoration: 'none',
                },
                color: theme.palette.text.primary,
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Iconify icon='mingcute:arrow-left-fill' width='36px'
                sx={{
                  pr: 2,
                  color: theme.palette.text.primary
                }}
              />
              <Typography variant='subtitle1'>
                Back
              </Typography>
            </Link>
            <Stack
              sx={{
                borderRadius: 2,
                mt: 2,
                boxShadow: {
                  md: theme.customShadows.card,
                },
              }}
            >
              <BriefPostHero post={post} editable={user?.role === 'admin'} briefId={briefId} />
              <Grid container spacing={3}>
                <Grid item
                  xs={12} sm={12} md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
                    <Stack direction='column' >
                      <Typography
                        variant="h5"
                        sx={{
                          pt: 2,
                          pb: 5,
                          px: { md: 5 },
                          cursor: 'default',
                          mt: 3,
                        }}
                      >
                        {post.description}
                      </Typography>
                      <Stack direction={(quantity && fulfilled) ? 'row' : 'column'} spacing={1} display={user?.plan !== 'Free' ? 'inherit' : 'none'}
                        sx={{
                          display: isAA() ? 'inherit' : 'none',
                          borderRadius: 1,
                          backgroundColor: ((quantity) && (fulfilled / quantity <= .6)) ? theme.palette.success.dark : (fulfilled / quantity <= .8 ? theme.palette.warning.dark : fulfilled / quantity > .8 ? theme.palette.error.dark : theme.palette.success.dark), // eslint-disable-line
                          color: theme.palette.common.white,
                          mx: 5,
                          pt: 3,
                          pb: 1,
                          px: 3,
                        }}
                      >
                        <Grid container>
                          <Grid item xs={12} sm={12} md={12}>
                            <Stack direction='row' spacing={2} alignItems='center'
                              sx={{
                                pb: 2,
                                cursor: 'default',
                                width: '100%',
                                justifyContent: 'flex-start',
                              }}
                            >
                              <Typography variant='h6' noWrap
                                sx={{
                                  width: (quantity && fulfilled) ? '150px' : '150px',
                                }}
                              >
                                Songs needed:
                              </Typography>
                              <Typography variant='h6'
                                sx={{
                                  borderRadius: .5,
                                  border: `${theme.palette.common.white} solid 1px`,
                                  px: 1,
                                }}
                              >
                                {quantity || 'Flexible'}
                              </Typography>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12}>

                            <Stack direction='row' spacing={2} alignItems='center'
                              sx={{
                                // px: { md: 5 },
                                // border: 'red solid 1px',
                                pb: 2,
                                cursor: 'default',
                                width: '100%',
                                justifyContent: {
                                  xs: 'flex-start',
                                  // sm: (quantity && fulfilled) ? 'flex-end' : 'flex-start',
                                  // md: (quantity && fulfilled) ? 'flex-end' : 'flex-start',
                                }
                              }}
                            >
                              <Typography variant='h6' noWrap
                                sx={{
                                  width: (quantity && fulfilled) ? '150px' : '150px',
                                }}
                              >
                                Slots remaining:
                              </Typography>
                              <Typography variant='h6'
                                sx={{
                                  borderRadius: .5,
                                  border: `${theme.palette.common.white} solid 1px`,
                                  px: 1,
                                }}
                              >
                                {((quantity) ? // eslint-disable-line
                                (quantity - fulfilled >= 0 ? quantity - fulfilled : 0) 
                                : 'Flexible') // eslint-disable-line
                                }
                              </Typography>
                            </Stack>
                          </Grid>
                        </Grid>
                      </Stack>
                    </Stack>
                    <Divider sx={{ m: 5, mt: 0, mb: 5 }} />
                    <Markdown
                      children={post.content}
                      sx={{
                        px: { md: 5 },
                        cursor: 'default',

                      }}
                    />
                    <Divider sx={{ m: 5, mb: 3 }} />
                    <Stack gap={2} paddingTop={2} paddingBottom={5}
                      sx={{
                        px: 2,
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{
                          // pt: 2,
                          // pb: 5,
                          px: { md: 3 },
                          cursor: 'default',
                        }}
                      >
                        {`Rate: $${fCurrencyCents(post?.customRate ? post?.customRate : post?.rate)}`}
                      </Typography>
                      {(post?.terms || post?.customTerms) &&
                        <Typography
                          variant="subtitle2"
                          sx={{
                            // pt: 2,
                            // pb: 5,
                            px: { md: 3 },
                            cursor: 'default',
                          }}
                        >
                          {`Terms: ${post?.customTerms ? post?.customTerms : post?.terms}`}
                        </Typography>
                      }
                    </Stack>
                    <Box
                      sx={{
                        pt: 2,
                        pb: 4,
                        pl: { md: 3 },
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 2,
                      }}>
                      <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'flex-end',
                        px: 2,
                      }}
                      >
                        <Typography variant='subtitle2'>
                          Submit song
                        </Typography>
                        {songSubmission !== '' &&
                          <Button variant='text'
                            sx={{ pb: 0, mb: 0 }}
                            onClick={() => {
                              setSongSubmission('')
                            }}
                          >
                            clear selection
                          </Button>
                        }
                      </Box>
                      {(user?.plan === 'Beta' ||
                        user?.plan === 'Premium' ||
                        user?.plan === 'Trial'
                      ) ?
                        <Select
                          // variant='standard'
                          id='submit-song'
                          value={songSubmission}
                          onChange={(e) => {
                            setSongSubmission(e.target.value)
                            // console.log('Select: ', e.target.value)
                          }}
                          // displayEmpty
                          inputProps={{ 'aria-label': 'Submit Song' }}
                          // fullWidth
                          // size='small'
                          placeholder='Submit Song'
                          sx={{
                            mx: 2,
                            mb: 3,
                            // pr: 4,
                          }}
                        >
                          {songsData?.getAvailableDocumentsByOwnerId.map(song => { // eslint-disable-line

                            let pitched = false;

                            song.pitches.map(item => { // eslint-disable-line
                              if (
                                item?.status === 'submitted' ||
                                item?.status === 'revise' ||
                                item?.status === 'resubmitted' ||
                                item?.status === 'pitched'
                              ) {
                                pitched = true
                              }
                              if (item?.briefId === briefId && item?.status === 'pass') {
                                pitched = true
                              }
                            }
                            )

                            if (!pitched) {
                              // console.log(song?.pitches[0]?.briefId)
                              return <MenuItem
                                key={song._id}
                                value={song}
                                sx={{
                                  // display: song?.pitches.some(pitch => pitch?.briefId !== briefId) ? 'flex' : 'none',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  mr: 3
                                }}
                              >
                                <Box>{song.title}</Box>
                                <Box sx={{ color: theme.palette.grey[500] }}>{song.artist.name}</Box>
                              </MenuItem>
                            }
                          }
                          )}
                          <Divider sx={{ m: 2 }} />
                          <MenuItem
                            value='upload song'
                            onClick={() => {
                              navigate(`/upload?brief=${briefId}`)
                            }}

                          >Upload new song
                          </MenuItem>
                        </Select> :
                        <>
                          <Button color='primary'
                            variant='contained'
                            size='large'
                            href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink(user?._id, user?.email)}
                            sx={{
                              boxSizing: 'border-box',
                              ml: 2,
                              // mr: 16,
                              mb: 3,
                            }}
                          >
                            Subscribe to Premium to Pitch Your Songs!
                          </Button>
                          <Select sx={{ display: 'none', mb: 3, mx: 2 }}>
                            <MenuItem
                              value='subscribe'
                            // onClick={()=>{
                            //   setSignUpOpen(true)
                            // }}
                            >
                              <Link href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink(user?._id, user?.email)}
                                // color='info'
                                style={{ textDecoration: 'none' }}
                                sx={{
                                  color: theme.palette.text.primary,
                                  '&:visited': {
                                    color: theme.palette.text.primary,
                                  }
                                }}
                              >
                                Subscribe to Premium to Pitch Your Songs!
                              </Link>
                            </MenuItem>
                          </Select>
                        </>
                      }
                      {songSubmission !== '' &&
                        <>
                          <Box
                            sx={{
                              mx: 2,
                            }}
                          >
                            <AudioFileCardProducer
                              id={songSubmission._id}
                              userId={userr}
                              user={user}
                              file={songSubmission}
                              data={songsData}
                              loadedSong={src}
                              setLoadedSong={setSrc}
                              sx={{ mb: 2, mt: 0, display: 'flex' }} // eslint-disable-line
                              setControlMessage={setControlMessage}
                              isPlaying={isPlaying}
                              setIsPlaying={setIsPlaying}
                              plain
                            />

                          </Box>
                          <Stack direction='row' justifyContent='center' paddingX={6}>
                            <Iconify icon='ri:arrow-down-fill' sx={{ color: theme.palette.text.primary }} />
                          </Stack>

                          <Button
                            variant='contained'
                            sx={{
                              mb: 3,
                            }}
                            onClick={async () => {
                              // console.log(songSubmission)
                              await pitchSongToBrief({ variables: { docId: songSubmission._id, briefId: briefId, briefTitle: post.title, briefCategory: post.category } }); // eslint-disable-line
                              setTimeout(() => {
                                getPitchedSongs()
                                setToast({
                                  open: true,
                                  message: `Successfilly submitted pitch to ${post.title}`,
                                  icon: <CheckRoundedIcon sx={{ color: 'white' }} />,
                                  duration: 2500,
                                  toastColor: theme.palette.success.dark,
                                  closeButton: false,
                                  horizontal: 'right'
                                })
                              }, 1000)
                              setSongSubmission('')
                            }}
                          >
                            Submit
                          </Button>
                        </>
                      }
                    </Box>

                  </Box>
                </Grid>
                <Grid item
                  xs={12} sm={12} md={6}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 7,
                    pr: {
                      sm: 0,
                      md: 4,
                    },
                  }}
                >
                  <Box
                    sx={{
                      // border: 'red solid 1px',
                      width: '100%',
                      p: 4,
                      mt: {
                        sm: 0,
                        // md: 14,
                        md: 3,
                      },
                      // mb: 10,
                      borderRadius: 2,
                      // backgroundColor: theme.palette.grey[800],
                      backgroundColor: isAA() ? 'rgb(40, 40, 40)' : theme.palette.background.paper,
                      display: (post.spotifyLink || post.appleMusicLink || post.youtubeLink) ? 'block' : 'none',
                    }}
                  >
                    <Typography
                      variant="h3"
                      sx={{
                        pb: 3,
                        pt: 2,
                        color: theme.palette.common.white,
                        // py: 5,
                        // px: { md: 5 },
                      }}
                    >
                      Reference Playlist
                    </Typography>
                    <Box
                      sx={{
                        display: post.spotifyLink ? 'inherit' : 'none',
                      }}
                    >
                      <iframe
                        style={{ borderRadius: "12px" }}
                        src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator&theme=0`}
                        width="100%"
                        height="452"
                        frameBorder="0"
                        allowFullScreen=""
                        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
                        loading="lazy"
                        title='trial-playlist'
                      />
                    </Box>
                    <Box
                      sx={{
                        display: post.youtubeLink ? 'inherit' : 'none',
                      }}
                    >
                      <iframe
                        width="100%"
                        height="315"
                        // height={parseReferenceLink(post?.youtubeLink)?.height} 
                        // width={parseReferenceLink(post?.youtubeLink)?.width} 
                        src={parseReferenceLink(post?.youtubeLink)?.link}
                        // src={`https://www.youtube.com/embed/videoseries?si=Gld-8t4gFgvkVg1U&amp;list=${youtubePlaylistId}`}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                      />
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              {axiosResults.length > 0 &&
                // {PitchedArray().length > 0 &&
                <Box >
                  <Divider sx={{ ml: 3, }} />
                  <Typography variant='h3'
                    sx={{
                      color: theme.palette.text.primary,
                      pl: { xs: 0, sm: 0, md: 3 },
                      pb: 2,
                      pt: 2,
                    }}
                  >
                    Songs Pitched
                  </Typography>
                </Box>
              }
              <Grid container
                spacing={3}
                sx={{
                  pl: { xs: 0, sm: 0, md: 3 },
                }}
              >

                {axiosResults.map(song => { // eslint-disable-line
                  // console.log('Pitched Array Item: ', song)
                  return <Grid
                    key={`card${song._id}`} item xs={12} sm={6} md={3}
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      mb: 3,
                      // pl: 3,
                    }}
                  >
                    <SongPitchedSquare
                      key={song._id}
                      item={song}
                      artistName={song.artist.name}
                      artwork={song.artwork}
                      // includeBriefTitle
                      refetch={() => { getPitchedSongs() }}
                      briefId={briefId}
                      loadedSong={src}
                      setLoadedSong={setSrc}
                      controlMessage={controlMessage}
                      setControlMessage={setControlMessage}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      userId={userr}
                      setToast={setToast}
                      multisUploadType={multisUploadType}
                      openMultisUploadDrawer={openMultisUploadDrawer}
                      setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
                      multisInfo={multisInfo}
                      setMultisInfo={setMultisInfo}
              
                    />
                  </Grid>
                })

                }
              </Grid>


            </Stack>

          </Box>
        )
        }

        {errorMsg && !loadingPost && <Typography variant="h6">404 {errorMsg}</Typography>}

      </Container>
      <CustomToast toast={toast} setToast={setToast} />
      <SignUpDialog setSignUpOpen={setSignUpOpen} open={signUpOpen} onClose={() => { closeSignUp() }} />
      <Footer />
      <MultisUploadDrawer
        openMultisUploadDrawer={openMultisUploadDrawer}
        setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
        multisInfo={multisInfo}
        setMultisInfo={setMultisInfo}
        refetch={()=>{getPitchedSongs()}}
        loadedSong={src}
      />

    </>
  );
}
