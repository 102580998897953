import PropTypes from 'prop-types';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


// @mui
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Typography } from '@mui/material';

// routes

import { useAuthContext } from '../../../../../../auth/useAuthContext';
import axios from '../../../../../../utils/axios';

//
import ProfileAbout from './ProfileAbout';


import isAA from '../../../../../isAA';
import AddArtistDialog from '../../../../../AddArtistDialog';
import OriginalBriefsDialog from '../../../../../OriginalBriefsDialog';
import CreateOrganizationDialog from '../../../../../CreateOrganizationDialog';
import CopyOrganizationDialog from '../../../../../CopyOrganizationDialog';
import AccountDialog from '../../../../../AccountDialog';
import PaymentDialog from '../../../../../PaymentDialog';

import AAWidgetCard from '../../../../../AAWidgetCard';
import AAWidgetCardPitches from '../../../../../AAWidgetCardPitches';

import CustomToast from '../../../../../CustomToast';


// ----------------------------------------------------------------------

AdminProfile.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
  data: PropTypes.array,
  refetch: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  controlMessage: PropTypes.string,
  setControlMessage: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  accountDialogOpen: PropTypes.bool,
  setAccountDialogOpen: PropTypes.func,
};


export default function AdminProfile({ info, posts, data, refetch, loadedSong, setLoadedSong, controlMessage, setControlMessage, isPlaying, setIsPlaying, accountDialogOpen, setAccountDialogOpen }) {

  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  // console.log('PROFILE USER: ', user)

  const theme = useTheme();
  const navigate = useNavigate();


  const [artistDialogOpen, setArtistDialogOpen] = useState(false)
  const [organizationDialogOpen, setOrganizationDialogOpen] = useState(false)
  const [copyOrganizationDialogOpen, setCopyOrganizationDialogOpen] = useState(false)
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false)
  const [originalBriefsDialogOpen, setOriginalBriefsDialogOpen] = useState(false)

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [allPitchesState, setAllPitchesState] = useState()
  const [wavMakerPitchesState, setWavMakerPitchesState] = useState()

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
  );


  function closeDialog(){
    setOriginalBriefsDialogOpen(false)
  }


  async function getPitchCountAll(){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventoryCount',
        modifier: 'pitchedCount',
        admin: true,
        // userId: '64d3c9649d24018f494a997c',
      }).then(response => {
        console.log('All pitches: ', response?.data)
        setAllPitchesState(parseInt(response?.data, 10))
      });
    } catch (err) {
      console.log(err)
    }   
  }

  async function getPitchCountAllWavMaker(){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventoryCount',
        modifier: 'pitchedCount',
        admin: true,
        orgId: '63779527-dc9f-4d96-91bb-52fe8117c3ff'
        // userId: '64d3c9649d24018f494a997c',
      }).then(response => {
        console.log('WavMaker: ', response?.data)
        setWavMakerPitchesState(parseInt(response?.data, 10))
      });
    } catch (err) {
      console.log(err)
    }   
  }

  useEffect(()=>{
    getPitchCountAll();
    getPitchCountAllWavMaker();
  },[]) // eslint-disable-line

  return (
    <>
    <Grid container  
     // page grid, left cards, right artist icons
      sx={{
        pb: 10,
        ml: 0,
        mr: 2,
      }}
      >
      <Grid item xs={12} md={12}
      // left side cards
        sx={{
          pl: 0,

        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCardPitches
              title='Current Pitches' 
              total='0'
              statsLoading
              color='primary' 
              cardColor={theme.palette.primary.main}
              textColor={theme.palette.common.white}
              allPitchesInfo={allPitchesState}
              wavMakerPitchesInfo={wavMakerPitchesState}
              />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
              title='Orders' 
              onClick={()=>{navigate('/orders')}}
              color='success' 
              textColor={theme.palette.common.white}
              cardColor={theme.palette.success.dark}
              darkColor={theme.palette.success.darker}
              noTotal
              icon="ant-design:dollar-outlined"
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            <AAWidgetCard 
                title='Inventory' 
                onClick={()=>{navigate('/inventory')}}
                // total={24} 
                color='warning' 
                textColor={theme.palette.common.white}
                cardColor={theme.palette.warning.main}
                darkColor={theme.palette.warning.dark}
                noTotal
                icon="quill:sound"
            />
          </Grid>            
          <Grid item xs={6} sm={6} md={3}>
            {isAA() ?
              <AAWidgetCard 
                title='Add Org' 
                onClick={()=>{setOrganizationDialogOpen(true)}}
                // total={24} 
                color='info' 
                textColor={theme.palette.common.white}
                cardColor={theme.palette.text.primary}
                darkColor={theme.palette.text.primary}
                noTotal
                icon="ant-design:cloud-upload-outlined"
              /> :
              <AAWidgetCard 
                title='Add Org' 
                onClick={()=>{setOrganizationDialogOpen(true)}}
                // onClick={()=>{setCopyOrganizationDialogOpen(true)}}
                color='info' 
                textColor={theme.palette.common.white}
                cardColor={theme.palette.text.primary}
                darkColor={theme.palette.text.primary}
                noTotal
                icon="ant-design:cloud-upload-outlined"
              />
            }
          </Grid>  
          <Divider sx={{width: '98%', m: 3, }}/>
          <Typography variant='h4' 
            sx={{
              p: 3,
              pb: 1, 
              pt: 1, 
            }}
          >
            Section 1
          </Typography>
          <Divider sx={{width: '98%', m: 3, }}/>

          <Typography variant='h4' 
            sx={{
              p: 3,
              pb: 6, 
              pt: 1, 
            }}
          >
            Section 2
          </Typography>

          <Grid item xs={12} sm={12} md={12}>
            <ProfileAbout
              user={user}
              quote={info.quote}
              country={info.country}
              email={info.email}
              role={info.role}
              company={info.company}
              school={info.school}
              setPaymentDialogOpen={setPaymentDialogOpen}
            />
          </Grid>
        </Grid>
      </Grid>

    </Grid>
    
    <AddArtistDialog 
        artistDialogOpen={artistDialogOpen}
        setArtistDialogOpen={setArtistDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />    
    <CreateOrganizationDialog
        organizationDialogOpen={organizationDialogOpen}
        setOrganizationDialogOpen={setOrganizationDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />
    <CopyOrganizationDialog
        organizationDialogOpen={copyOrganizationDialogOpen}
        setOrganizationDialogOpen={setCopyOrganizationDialogOpen}
        setToast={setToast}
        userr={userr}
        refetch={refetch}
    />
    <AccountDialog 
        accountDialogOpen={accountDialogOpen}
        setAccountDialogOpen={setAccountDialogOpen}
        user={user}
        userr={userr}
        setToast={setToast}
        refetch={refetch}
    />
    <Elements stripe={stripePromise}>
      <PaymentDialog 
        paymentDialogOpen={paymentDialogOpen}  
        setPaymentDialogOpen={setPaymentDialogOpen}  
        user={user}
        userr={userr}
        refetch={refetch}
      />
      {!isAA() &&
      <OriginalBriefsDialog 
        open={originalBriefsDialogOpen} 
        onClose={()=>{closeDialog()}} 
        setLegalDialogOpen={setOriginalBriefsDialogOpen} 
        user={user}
      />
      }

    </Elements>
    <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
