import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

// @mui
import {
  Box,
  Button,
  Stack,
  Drawer,
  Divider,
  Link,
  Tooltip,
  TextField,
  Typography,
  IconButton,
  ListItemText,
  ListItemButton,
  Grid,
  Checkbox,
  Switch,
  CircularProgress,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import axios from 'axios';
import isAA from "./isAA";


// utils
// import axios from '../utils/axios';
// components
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import Iconify from '../components-default/iconify';
import { UploadBox } from './upload';
import { useAuthContext } from '../auth/useAuthContext';
import ProgressCircular from './sections/mui/progress/ProgressCircular';
import { AudioContext } from './audio/audio-context';

// ----------------------------------------------------------------------

MultisUploadDrawer.propTypes = {
  openMultisUploadDrawer: PropTypes.bool,
  setOpenMultisUploadDrawer: PropTypes.func,
  multisInfo: PropTypes.object,
  setMultisInfo: PropTypes.func,
  setUpdateTrigger: PropTypes.func,
  refetch: PropTypes.func,
  persistent: PropTypes.bool,
  loadedSong: PropTypes.object,
};

export default function MultisUploadDrawer({
  openMultisUploadDrawer,
  setOpenMultisUploadDrawer,
  multisInfo,
  setMultisInfo,
  setUpdateTrigger,
  refetch,
  persistent,
  loadedSong,
}) {

  const theme = useTheme();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);


  const [uploadPercentage, setUploadPercentage] = useState(0);  // eslint-disable-line
  const [uploadSuccess, setUploadSuccess] = useState(false);  // eslint-disable-line
  const [loading, setLoading] = useState(false);

  const [fileHolder, setFileHolder] = useState(null);

  const [uploadFileType, setUploadFileType] = useState('ok');
  const [errorState, setErrorState] = useState(null)

  const [errorMessage, setErrorMessage] = useState('')

  const [uploadLink, setUploadLink] = useState(null)

  const { CancelToken } = axios;
  const source = CancelToken.source();

  async function getUploadLink() {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getuploadlink`, {  // eslint-disable-line
        userId: userr,
        docId: multisInfo?._id,
        title: multisInfo?.title,
      }).then(response => {
        console.log(response.data)
        setUploadLink(response.data.uploadLink)
      })
    } catch (err) {
      console.log(err.response.data)
    }
  }

  async function uploadToGoogleStorage() {
    try {
      const formData = new FormData();
      formData.append('file', fileHolder);

      const res = await axios({
          method: 'put',
          url: uploadLink,
          data: formData,
          headers: {
              'Content-Type': 'application/zip',
          }
      }).then(response => {
        console.log(response.data)
      })
    } catch (err) {
      console.log(err.response.data)
    }
  }

  function cancelUpload() {
    source.cancel('Upload canceled by the user.');
    setLoading(false);
  }

  function successCallback(songId) {
    console.log('Upload Success!')
    setUploadPercentage(0);
    setLoading(false);
    setUploadSuccess(true);
    if (setUpdateTrigger) {
      setUpdateTrigger(true)
    }
    if (refetch) {
      refetch()
    }
  }

  const handleUpload = async (zipFile) => {

    setLoading(true)
    console.log('File: ', fileHolder);
    const formData = new FormData();

    formData.append('userId', userr);
    formData.append('docId', multisInfo?._id);
    formData.append('title', multisInfo?.title);
    formData.append('file', zipFile);

    console.log('FORMDATA: ', formData)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/multisupload`, formData, {  // eslint-disable-line
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) /
            progressEvent.total), 10))
        },
        cancelToken: source.token
      }).then(response => {
        console.log(response.data)
        successCallback(response.data.id);
      }).catch((thrown) => {
        if (axios.isCancel(thrown)) {
          console.log('Request canceled', thrown.message);
        } else {
          // handle error
        }
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }

  return (
    <Drawer
      anchor="right"
      open={openMultisUploadDrawer}
      variant={persistent ? 'persistent' : 'temporary'}
      onClose={() => {
        setOpenMultisUploadDrawer(false)
        setFileHolder(null)
        setMultisInfo({
          title: '',
          _id: '',
        })
        setUploadSuccess(false)

      }}
      // sx={{
      // }}
      BackdropProps={{
        invisible: true,
        sx: {
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
        },
      }}
      PaperProps={{
        sx: {
          // mt: '100px',
          width: {
            xs: 300,
            sm: 300,
            md: 300,
            lg: 420,
          },

          height: loadedSong?.title ? 'calc(100% - 80px)' : '100%',
          boxShadow: theme.shadows[10],
          pb: '200px',
          // height: '100% - 100px',

        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, pr: 1, py: 2, backgroundColor: 'inherit' }}
      >
        <Iconify
          icon='iconamoon:close-bold'
          onClick={() => { setOpenMultisUploadDrawer(false) }}
          sx={{
            cursor: 'pointer',
            display: persistent ? 'none' : 'inherit',
          }}
        />
        <Stack direction='row' width='100%' justifyContent='center'>
          <Typography variant="subtitle1">Upload Multis</Typography>
        </Stack>

      </Stack>

      <Divider />
      <Stack direction='row' spacing={2}
        sx={{
          p: 2,
          mx: 4,
          my: 2,
          border: `${theme.palette.primary.main} solid 2px`,
          borderRadius: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          cursor: 'default',
        }}
      >
        <Typography variant="subtitle1"
          sx={{
            color: isAA() ? theme.palette.grey[700] : theme.palette.text.secondary,
            width: '90px',
            textAlign: 'right',
          }}
        >
          Song Title:
        </Typography>
        <Typography variant="subtitle1">{multisInfo?.title}</Typography>

      </Stack>
      <Stack direction='row' spacing={2}
        sx={{
          p: 2,
          mx: 4,
          mb: 2,
          border: `${theme.palette.primary.main} solid 2px`,
          borderRadius: 1,
          justifyContent: 'flex-start',
          alignItems: 'center',
          cursor: 'default',

          // display: multisInfo?.multitrackUrl ? 'flex' : 'none',
        }}
      >
        <Typography variant="subtitle1"
          sx={{
            color: isAA() ? theme.palette.grey[700] : theme.palette.text.secondary,
            width: '90px',
            textAlign: 'right',
          }}
        >
          Multis:
        </Typography>
        {(multisInfo?.multitrackUrl || uploadSuccess) ?
          <>
            <Typography variant="subtitle1">Uploaded</Typography>
            <Iconify icon="eva:checkmark-circle-2-fill" width={24} sx={{ color: theme.palette.grey[700] }} />
          </>
          : <Typography variant="subtitle1"
            sx={{
              color: !loading ? theme.palette.error.dark : theme.palette.text.secondary,

            }}
          >Not uploaded</Typography>
        }
      </Stack>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // gap: 1,
          pt: 4,
          px: 4,
          // border: '1px solid red',
          justifyContent: 'flex-start',
          alignItems: 'center',
          height: 'auto',
        }}
      >
        {!uploadSuccess &&
          (!loading ?
            <UploadBox
              onDrop={(e) => {
                if (
                  e[0].type === 'application/zip'
                ) {
                  setUploadFileType('ok')
                  setFileHolder(e[0]);
                  getUploadLink()
                  console.log(e);
                } else {
                  setUploadFileType('error')
                }
              }}
              accept=".zip"
              sx={{
                mt: 0,
                mb: 0,
                py: 1,
                // px: 2,
                width: '100%',
                height: '150px',
                boxSizing: 'border-box',
                borderRadius: 1,
                backgroundColor: uploadFileType !== 'error' ? `#e6e8e4` : theme.palette.error.dark, // eslint-disable-line 
                border: (errorState === 'file' && !fileHolder) ? 'red solid 2px' : `${theme.palette.grey[700]} solid 1px`,
              }}

              placeholder=
              {
                fileHolder === null && uploadFileType !== 'error' ? // eslint-disable-line
                  <Box sx={{ color: isAA() ? 'text.disabled' : 'primary.light', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: isAA() ? theme.palette.text.main : theme.palette.background.default }}>
                      {multisInfo?.multitrackUrl ? 'Add/drop replacement file' : 'Add/drop file'}
                    </Typography>
                    <Iconify icon="eva:cloud-upload-fill" width={40} sx={{ color: isAA() ? `${theme.palette.text.primary}80` : `${theme.palette.background.default}`, }} />
                  </Box> :
                  (uploadFileType !== 'error' ?
                    <Box sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.background.default }}>{fileHolder?.name || 'File added'}</Typography>
                      <Iconify icon="octicon:file-zip-24" width={40} sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.background.default, }} />
                    </Box>
                    : <Box sx={{ color: theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" color='common.white'>File must be zipped</Typography>
                      <Iconify icon="eva:slash-fill" width={20} sx={{ color: theme.palette.common.white, }} />
                    </Box>
                  )
              }
            />
            :
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '150px',
                width: '100%',
                position: 'relative',
                // border: 'red solid 1px',
              }}
            >
              <CircularProgress
                variant="determinate"
                value={uploadPercentage}
                size={100}
                color='info'
              />
              {uploadPercentage < 100 ?
                <Typography variant="subtitle2" component="div" color="text.secondary"
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {`${Math.round(uploadPercentage)}%`}
                </Typography> :
                <Stack direction='column' justifyContent='center' alignItems='center' gap={.5}
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography variant='subtitle1' color='text.secondary'>
                    Saving
                  </Typography>
                  <Iconify icon="eos-icons:three-dots-loading" width={32} />
                </Stack>
              }
            </Box>)
        }
        {uploadSuccess &&
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 2,
              height: '150px',
              width: '100%',
              position: 'relative',
              // border: 'red solid 1px',
            }}
          >
            <Typography variant="h6" component="div" color="text.secondary"
            >
              Multis Uploaded
            </Typography>
            <Iconify icon="eva:checkmark-circle-2-fill" width={32} sx={{ color: theme.palette.success.main }} />
          </Box>
        }
        <Typography variant='caption' sx={{ color: 'red' }}>{uploadLink}</Typography>
        <Stack direction='row' justifyContent='space-between' alignItems='center'
          sx={{
            // height: '40px',
            width: '100%',
            gap: 1,
            py: 4,
            display: (fileHolder === null || uploadSuccess) ? 'none' : 'inherit',
          }}
        >
          <Button fullWidth
            variant='contained'
            onClick={() => {
              if (fileHolder !== null) {
                console.log('UPLOAD')
                uploadToGoogleStorage()
              } else {
                setErrorState('file')
                setErrorMessage('No file added')
              }
              // handleUpload(fileHolder)
            }}
            sx={{ mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}
          >
            Upload Multis
          </Button>
          <IconButton
            onClick={() => {
              setFileHolder(null);
              setUploadFileType('ok');
            }}>
            <Iconify icon="eva:close-fill" width={20} />
          </IconButton>
        </Stack>
        <Stack direction='row' justifyContent='space-between' alignItems='center'
          sx={{
            // height: '40px',
            width: '100%',
            gap: 1,
            py: 4,
          }}
        >
          <Button fullWidth
            variant='text'
            onClick={() => {
              console.log('CANCEL')
              cancelUpload()
            }}
            sx={{ display: loading ? 'inherit' : 'none', mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}
          >
            Cancel Upload
          </Button>
        </Stack>
        {(fileHolder === null && errorState === 'file') &&
          <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
        }

      </Box>
    </Drawer>
  );
}
