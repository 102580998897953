import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Button, Container, Typography, DialogActions, Dialog, Divider, Stack } from '@mui/material';
// components
import Image from '../components-default/image';
import Markdown from '../components-default/markdown';
import Scrollbar from '../components-default/scrollbar';
import EmptyContent from '../components-default/empty-content';

import aaIcon from '../assets/icons/pikes-white.svg'

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

const StyledTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.h3,
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h2,
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const StyledFooter = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
  },
}));

WalkthroughDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  songInfo: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default function WalkthroughDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  songInfo,
  onSubmit,
}) {
  // const { title = '', content = '', description = '' } = values;

  // console.log('SONG INFO: ', songInfo)

  return (
    <Dialog fullScreen open={open} onClose={onClose} >
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
        {process.env.REACT_APP_SITE_NAME} Quick Walkthrough
        </Typography>

        <LoadingButton
          type="submit"
          variant="contained"
          // disabled={!isValid}
          loading={isSubmitting}
          onClick={onClose}
        >
          Done
        </LoadingButton>
      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 2, 
              mb: 10, 
              // ml: 10, 
              // mr: 20, 
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}>
          <Stack 
            justifyContent='flex-start' 
            alignItems='center' 
            width='100%' 
            textAlign='center'
            gap={6}
            marginBottom={6}
            // border='red solid 1px'
            height='100%'
          >
            <iframe src="https://player.vimeo.com/video/849779755?h=f8c8fa84d3" 
            title='Audio Arsenal Walkthrough' 
            style={{border: 0, overflow: 'hidden', height: '100%', width: '100%', minWidth:'400px', maxHeight:'75%'}}
            frameBorder="0" allow="autoplay; fullscreen" allowFullScreen/>
          </Stack>
          </Container>
        </Scrollbar>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

