import { Font, StyleSheet } from '@react-pdf/renderer';

// ----------------------------------------------------------------------

Font.register({
  family: 'Roboto',
  fonts: [{ src: '/fonts/Roboto-Regular.ttf' }, { src: '/fonts/Roboto-Bold.ttf' }],
});

Font.register({
  family: 'Peckham Press Trial, sans-serif',
  fonts: [{ src: '/fonts/PeckhamPress-Trial.otf' }],
});

const styles = StyleSheet.create({
  col4: { width: '25%' },
  col8: { width: '75%' },
  col6: { width: '50%' },
  mb8: { marginBottom: 8 },
  mb16: { marginBottom: 16 },
  mb32: { marginBottom: 32 },
  mx16: { marginLeft: 16, marginRight: 16 },
  mx32: { marginLeft: 32, marginRight: 32 },
  mx36: { marginLeft: 36, marginRight: 36 },
  mr36: { marginRight: 36 },
  mx48: { marginLeft: 48, marginRight: 48 },
  mx58: { marginLeft: 58, marginRight: 58 },
  my12: { marginTop: 12, marginBottom: 12 },
  block: {  textAlign: 'justify', textAlignLast: 'justify', textJustify: 'inter-character'},
  indent: { textIndent: '32px'},
  pl8: { paddingLeft: 8 },
  pl16: { paddingLeft: 16 },
  mb40: { marginBottom: 40 },
  underline: { textDecoration: 'underline'},
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  h3: { fontSize: 16, fontWeight: 700 },
  h4: { fontSize: 13, fontWeight: 700 },
  logo: { fontFamily: 'Peckham Press Trial, sans-serif',fontSize: 16, fontWeight: 700 },
  body1: { fontSize: 10 },
  body: { fontSize: 12 },
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  alignCenter: { textAlign: 'center' },
  alignLeft: { textAlign: 'left', paddingLeft: '10px' },
  lowerCase: {textTransform: 'lowercase'},
  page: {
    padding: '40px 24px 20px 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#FFFFFF',
    // textTransform: 'capitalize',
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 20,
    margin: 'auto',
    borderTopWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    borderColor: '#DFE3E8',
  },
  gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  gridIndentLine: { display: 'grid', gridTemplateColumns: '40px 1fr', },
  table: { display: 'flex', width: 'auto' },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: '8px 0',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8',
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  tableCell_1: { width: '5%' },
  tableCell_2: { width: '50%', paddingRight: 16,  },
  tableCell_3: { width: '15%', },
});

export default styles;
