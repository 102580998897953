import { forwardRef, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  Stack,
  Checkbox,
  Grid,
  Paper,
  Divider,
} from '@mui/material';

import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';


import CheckIcon from '@mui/icons-material/Check';


import { useParams } from 'react-router';
import axios from '../utils/axios';
import { fCurrencyCents } from '../utils/formatNumber';
import Iconify from '../components-default/iconify';
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import Icon from '../components-default/color-utils/Icon';


// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


UserTransactionsAdminDialog.propTypes = {
  transactionDialogOpen: PropTypes.bool,
  setTransactionDialogOpen: PropTypes.func,
  targetUserId: PropTypes.string,
  setToast: PropTypes.func,
  // editArtistInfo: PropTypes.object,
  // toast: PropTypes.object,
};



export default function UserTransactionsAdminDialog({ transactionDialogOpen, setTransactionDialogOpen, targetUserId, setToast }) {

  const theme = useTheme();

  const { copy } = useCopyToClipboard();


  const [userStats, setUserStats] = useState('')
  const [chargeSubscription, setChargeSubscription] = useState(false)
  const [userTransactions, setUserTransactions] = useState([])
  const [transactionInfo, setTransactionInfo] = useState(
    {
      userId: targetUserId,
      songs: '',
      amount: '',
      status: '',
    })


  async function getUserStats() {
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/getuser`, {
      userId: { userId: targetUserId }
    });
    // console.log(response)
    setUserStats(response?.data?.user);

  }

  function convertDate(timestamp) {

    // Create a new Date object with the timestamp
    const date = new Date(timestamp);

    // Extract the various components of the date
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
    const day = date.getDate();

    // Create a formatted date string
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    return formattedDate
  }

  async function handleAddTransaction() {
    const result = await axios.post(`${process.env.REACT_APP_SERVER}/addtransaction`, { // eslint-disable-line
      transactionInfo,
      chargeSubscription
    })
    if (result.data.message !== 'error') {
      setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `$${result?.data?.amount} added to ${result?.data?.userName}'s transactions`, duration: 4000 })
      getUserStats()

    } else {
      setToast({ open: true, horizontal: 'right', toastColor: theme.palette.error.dark, message: `Somethin ain't work`, duration: 4000 })
    }
  }


  useEffect(() => {
    if (targetUserId !== '') {
      getUserStats()
    }
    setTransactionInfo({ ...transactionInfo, userId: targetUserId })
  }, [targetUserId]) // eslint-disable-line

  useEffect(() => {
    if (userStats !== '') {
      setUserTransactions(userStats?.transactions)
    }
  }, [userStats])



  const [loading, setLoading] = useState(false)



  const handleClose = () => {
    setTransactionDialogOpen(false);

  };


  return (
    <div>
      <Dialog
        open={transactionDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        fullScreen
        scroll='body'
        onClose={() => { handleClose() }}
        sx={{
          // height: '100%',
          // minWidth: '100px',
          paper: {
            m: 10,
            // width: '500px',
          }
        }}
      >
        <Box
          sx={{
            // width: {
            //   sm: '300px',
            //   md: '500px',
            // }, 
          }}
        >

          <DialogTitle
            id="edit-artist-title"
            sx={{
              pb: 0,
            }}
          >
            Transactions
          </DialogTitle>

          <DialogContent scroll='body' >
            <Grid container>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Timeline position="right" >
                  {userTransactions?.map((item) => (

                    <TimelineItem key={item?._id} >
                      {item?.status === 'credit' ?
                        <TimelineOppositeContent sx={{ mb: 4, px: 3 }} >
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {convertDate(item?.createdAt)}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'flex-end',
                              gap: 1,
                            }}
                          >
                            <Iconify icon='mingcute:copy-line'
                              sx={{
                                cursor: 'pointer',
                                color: theme.palette.info.main,
                                transition: 'all 0.2s ease-in-out',
                                '&:active': {
                                  transform: 'scale(0.9)',
                                }
                              }}
                              onClick={() => {
                                copy(item?.songTitle)
                              }}
                            />
                            <Iconify icon='typcn:plus'
                              sx={{
                                cursor: 'pointer',
                                color: theme.palette.info.main,
                                transition: 'all 0.2s ease-in-out',
                                '&:active': {
                                  transform: 'scale(0.9)',
                                }
                              }}
                              onClick={() => {
                                if (transactionInfo.songs === '') {
                                  setTransactionInfo({ ...transactionInfo, songs: item?.songTitle })
                                } else {
                                  setTransactionInfo({ ...transactionInfo, songs: `${transactionInfo.songs}, ${item?.songTitle}` })
                                }
                                // copy(item?.songTitle)
                              }}
                            />
                            <Typography variant="subtitle2">
                              {item?.songTitle}
                            </Typography>
                          </Box>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {item?.songArtist}
                          </Typography>
                        </TimelineOppositeContent > :
                        <TimelineOppositeContent sx={{ mb: 4, px: 3 }} >
                          <Typography variant="body2" sx={{ color: 'text.secondary' }} />
                        </TimelineOppositeContent>
                      }
                      <TimelineSeparator>
                        <TimelineDot color={item?.status === 'credit' ? 'primary' : (item?.status === 'paid' ? 'success' : 'info')} // eslint-disable-line
                        >
                          <Iconify icon={item?.status === 'credit' ? 'circum:receipt' : (item?.status === 'paid' ? 'tdesign:money' : 'eva:credit-card-outline')} // eslint-disable-line
                          />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      {item?.status === 'paid' || item?.status === 'subscription-charge' ?
                        <TimelineContent sx={{ mb: 4, px: 3 }} >
                          <Paper
                            sx={{
                              p: 3,
                              bgcolor: item?.status === 'paid' ? theme.palette.success.dark : theme.palette.info.dark,
                              maxWidth: '300px',

                            }}
                          >
                            <Typography variant="body2"
                              sx={{
                                color: theme.palette.common.white,
                                mb: 2.5,
                              }}
                            >
                              {convertDate(item?.createdAt)}
                            </Typography>
                            <Stack gap={0}
                              sx={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                mb: 1.5,
                              }}
                            >
                              <Typography variant={item?.status === 'paid' ? "h5" : "h6"}
                                sx={{
                                  color: theme.palette.common.white,
                                }}
                              >
                                {item?.status === 'paid' ?
                                `PAYOUT - $${fCurrencyCents(item.amount)}`
                                : `Subscription Charge - $${fCurrencyCents(item.amount)}`
                                }
                              </Typography>
                              {item.method &&
                                <Typography variant="caption"
                                  sx={{
                                    color: theme.palette.common.white,
                                    mb: 1.5,
                                  }}
                                >
                                  via {item?.method}
                                </Typography>
                              }


                            </Stack>
                            <Typography variant="caption" fontWeight='bold'
                              sx={{
                                display: item?.status === 'paid' ? 'inherit' : 'none', 
                                color: theme.palette.common.white,
                                mb: 2.5,
                              }}
                            >
                              Songs:
                            </Typography>
                            <br />
                            <Typography variant="caption"
                              sx={{
                                color: theme.palette.common.white,
                                mb: 2.5,
                              }}
                            >
                              {item?.songTitle}
                            </Typography>
                          </Paper>
                        </TimelineContent> :
                        <TimelineContent sx={{ mb: 4, px: 3 }} >
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Sale
                          </Typography>
                          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            ${fCurrencyCents(item.amount)}
                          </Typography>
                        </TimelineContent>
                      }
                    </TimelineItem>
                  ))}
                </Timeline>
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', }}>
                <Stack gap={2}
                  sx={{
                    width: '500px',
                    p: 2,
                    borderRadius: 2,
                    border: `${theme.palette.divider} solid 1px`,
                  }}
                >

                  <Stack direction='row' gap={2} alignItems='center'>
                    <Typography variant="body2" sx={{ width: '95px', textAlign: 'right' }}>
                      Song Titles
                    </Typography>
                    <TextField fullWidth size='small' autoComplete='off'
                      value={transactionInfo.songs}
                      onChange={(e) => {
                        setTransactionInfo({ ...transactionInfo, songs: e.target.value })
                      }}
                    />
                  </Stack>
                  <Stack direction='row' gap={2} alignItems='center'>
                    <Typography variant="body2" sx={{ width: '95px', textAlign: 'right' }}>
                      Amount
                    </Typography>
                    <TextField fullWidth size='small' autoComplete='off'
                      value={transactionInfo.amount}
                      onChange={(e) => {
                        setTransactionInfo({ ...transactionInfo, amount: e.target.value })
                      }}
                    />
                  </Stack>
                  <Stack direction='row' gap={2} alignItems='center'>
                    <Typography variant="body2" sx={{ width: '95px', textAlign: 'right' }}>
                      Type
                    </Typography>
                    <Select fullWidth size='small'
                      value={transactionInfo.status}
                      onChange={(e) => {
                        setTransactionInfo({ ...transactionInfo, status: e.target.value })
                      }}

                    >
                      <MenuItem value=''>Transaction Type</MenuItem>
                      <MenuItem value='credit'>Credit</MenuItem>
                      <MenuItem value='paid'>Paid</MenuItem>
                    </Select>
                  </Stack>
                  <Stack direction='row' gap={2} alignItems='center'>
                    <Typography variant="body2" sx={{ width: '95px', textAlign: 'right' }} />
                    <Button fullWidth variant='contained' size='large'
                      onClick={() => {
                        handleAddTransaction()
                        console.log('transactionInfo', transactionInfo)
                        console.log('targetUserId', targetUserId)
                      }}
                    >
                      Add Transaction
                    </Button>
                  </Stack>
                  <Divider sx={{ width: '100%' }} />
                  <Stack direction='row' gap={2} alignItems='center'>
                    <Typography variant="body2"
                      sx={{
                        width: 'fit-content',
                        textAlign: 'right',
                        color: chargeSubscription ? theme.palette.text.primary : theme.palette.text.disabled,
                      }}
                    >
                      Charge Subscription
                    </Typography>
                    <Checkbox
                      checked={chargeSubscription}
                      onChange={(e) => {
                        setChargeSubscription(!chargeSubscription)
                      }}
                    />
                  </Stack>
                </Stack>
              </Grid>

            </Grid>

          </DialogContent>

          <DialogActions sx={{ justifyContent: 'space-between' }} >
            <Stack direction='row' spacing={2} width='100%' justifyContent='flex-end'>

              <Button
                size='medium'
                variant="contained"
                onClick={() => {
                  setTransactionDialogOpen(false)
                  setTransactionInfo({ userId: '', songs: '', amount: '', status: '', })
                }}
                sx={{ px: "16px", py: "7px" }}
                disabled={loading}
              >
                Done
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}
