import { useState } from 'react';
import { m } from 'framer-motion';
// @mui
import { alpha, styled } from '@mui/material/styles';
import {
  Fab,
  Tab,
  Box,
  Grid,
  Tabs,
  Chip,
  Alert,
  Stack,
  Radio,
  Paper,
  Button,
  Rating,
  Slider,
  Switch,
  MenuItem,
  Checkbox,
  Container,
  TextField,
  Typography,
  AlertTitle,
  Pagination,
  CardHeader,
  IconButton,
  ToggleButton,
  CircularProgress,
  FormControlLabel,
  ToggleButtonGroup,
} from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// utils
import { bgGradient } from '../../utils/cssStyles';
// routes
import { PATH_PAGE } from '../../routes/paths';
// _mock
import _mock from '../../_mock';
// components
import Label from '../../components-default/label';
import Image from '../../components-default/image';
import Iconify from '../../components-default/iconify';
import Scrollbar from '../../components-default/scrollbar';
import MenuPopover from '../../components-default/menu-popover';
import BadgeStatus from '../../components-default/badge-status';
import { MotionViewport, varFade } from '../../components-default/animate';
import { CustomAvatar, CustomAvatarGroup } from '../../components-default/custom-avatar';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    // border: 'red solid 1px',
    
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'left',
  // border: 'red solid 1px',
  padding: theme.spacing(1.5, 0),
  borderRadius: Number(theme.shape.borderRadius) * 2,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(0, 0),
  },
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(2.5),
  },
}));

const StyledDescription = styled('div')(({ theme }) => ({
  textAlign: 'center',
  cursor: 'default',
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    paddingLeft: theme.spacing(0),
    paddingTop: theme.spacing(10),
  },
}));

const StyledRow = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(1.5),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(2),
    },
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(2.5),
    },
  },
}));

// ----------------------------------------------------------------------

export default function HomeHeroAA() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledRoot>
      <Container component={MotionViewport}>
        <Grid direction={{ xs: 'column', md: 'row' }} container spacing={5}>

          <Grid item xs={12} md={5} 
            sx={{
              // border: 'red solid 1px'
            }}
          >
            <Content />
          </Grid>

          <Grid item xs={12} md={7} 
            sx={{
              // border: 'green solid 1px'
            }}
          >
            <Description2 />
          </Grid>

          {!isDesktop && (
            <Grid item xs={12} sx={{ textAlign: 'center' }}>
              {SignUpButton}
            </Grid>
          )}
        </Grid>
      </Container>
    </StyledRoot>
  );
}

// ----------------------------------------------------------------------

function Description() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledDescription>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ 
            my: 3,
            fontFamily: "peckham-press, sans-serif",
            fontWeight: 400,
            fontStyle: 'normal', 
          }}>
          Custom music <br />
          for your platform
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: 'text.secondary',
          }}
        >
          An exclusive selection of high-quality, pre-cleared buyout music, custom curated for you.
        </Typography>
      </m.div>

      {isDesktop && SignUpButton}
    </StyledDescription>
  );
}

function Description2() {
  const isDesktop = useResponsive('up', 'md');

  return (
    <StyledDescription>

      <m.div variants={varFade().inUp}>
        <Typography variant="h2" sx={{ 
            my: 3,
            fontFamily: "peckham-press, sans-serif",
            fontWeight: 400,
            fontStyle: 'normal', 
          }}>
          Make tracks. <br />
          Get paid.
        </Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography
          sx={{
            mb: 5,
            color: 'text.secondary',
          }}
        >
          Music libaries are in constant need of new music for their platforms - <br/>
          That&apos;s where <b>you</b> come in.
        </Typography>
      </m.div>

      {isDesktop && SignUpButton}
    </StyledDescription>
  );
}

// ----------------------------------------------------------------------

function Content() {
  const isMd = useResponsive('up', 'md');

  const isLg = useResponsive('up', 'lg');

  const [slider, setSlider] = useState(24);

  const [select, setSelect] = useState('Option 1');

  const [alignment, setAlignment] = useState('left');

  const [rating, setRating] = useState(2);

  const [currentTab, setCurrentTab] = useState('Angular');

  const [openPopover, setOpenPopover] = useState(null);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <StyledContent>
      {/* Row 1 */}
      <img src='/logo/pikes.svg' alt={process.env.REACT_APP_SITE_NAME} height='400px'/>

    </StyledContent>
  );
}

// ----------------------------------------------------------------------

const SignUpButton = (
  <m.div variants={varFade().inUp}>
    <Button
      size="large"
      color="primary"
      variant="contained"
      // target="_blank"
      rel="noopener"
      href='/signuplist'
      endIcon={<Iconify icon="ic:round-arrow-right-alt" />}
    >
      Get on the list
    </Button>
  </m.div>
);

// ----------------------------------------------------------------------

const cardPost = (
  <Paper
    sx={{
      width: 320,
      borderRadius: 2,
      boxShadow: (theme) => theme.customShadows.z24,
    }}
  >
    <CardHeader
      title="Jayvion Simon"
      subheader="California, United States"
      avatar={
        <CustomAvatar
          src={_mock.image.avatar(0)}
          BadgeProps={{
            badgeContent: <BadgeStatus status="online" />,
          }}
          sx={{ width: 48, height: 48 }}
        />
      }
      titleTypographyProps={{ typography: 'subtitle2', sx: { mb: 0.25 } }}
      subheaderTypographyProps={{ typography: 'caption' }}
      sx={{ p: 2 }}
    />
    <Image alt="cover" src={_mock.image.cover(12)} ratio="16/9" />

    <Typography variant="body2" sx={{ color: 'text.secondary', pt: 2, px: 2 }}>
      Phasellus dolor. Fusce egestas elit eget lorem. Quisque id odio.
    </Typography>

    <Stack direction="row" sx={{ px: 2, py: 1 }}>
      <Checkbox
        defaultChecked
        color="error"
        size="small"
        icon={<Iconify icon="eva:heart-fill" />}
        checkedIcon={<Iconify icon="eva:heart-fill" />}
      />

      <Box sx={{ flexGrow: 1 }} />

      <IconButton>
        <Iconify icon="eva:share-outline" />
      </IconButton>

      <IconButton>
        <Iconify icon="eva:message-circle-fill" />
      </IconButton>
    </Stack>
  </Paper>
);
