import PropTypes from 'prop-types';
// @mui
import { Box, Link, Stack, Typography, Breadcrumbs } from '@mui/material';
//
import LinkItem from './LinkItem';
import { useAuthContext } from '../../auth/useAuthContext';


// ----------------------------------------------------------------------

CustomBreadcrumbs.propTypes = {
  sx: PropTypes.object,
  action: PropTypes.node,
  links: PropTypes.array,
  heading: PropTypes.any,
  moreLink: PropTypes.array,
  activeLast: PropTypes.bool,
};

export default function CustomBreadcrumbs({
  links,
  action,
  heading,
  moreLink,
  activeLast,
  sx,
  ...other
}) {
  const { user } = useAuthContext();

  const lastLink = links[links.length - 1].name;

  return (
    <Box sx={{ mb: 2, cursor: 'default', ...sx }}>
      <Stack direction="column" alignItems="flex-start">
        <Box sx={{ flexGrow: 1 }}>

          {/* BREADCRUMBS */}
          {links.length > 0 && links[0] !== '' ? (
            <Breadcrumbs separator={<Separator />} {...other}>
              {links.map((link) => (
                <LinkItem
                  key={link.name || ''}
                  link={link}
                  activeLast={activeLast}
                  disabled={link.name === lastLink}
                />
              ))}
            </Breadcrumbs>
          ) : <Box sx={{height: 4}}/>
        }
        </Box>
        <Stack direction='row' 
          width='100%' justifyContent='space-between'
          alignItems='center'
          mt={4}
          // border='red solid 1px'
        >
          {/* HEADING */}
          {heading && (
            <Box
              sx={{
                cursor: 'default',
                // display: 'flex',
                // alignItems: 'flex-end',
                // gap: 1,
              }}
            >
              <Typography variant="h4" gutterBottom>
                {heading}
              </Typography>
            </Box>
          )}

          {action && <Box sx={{ flexShrink: 0 }}> {action} </Box>}
        </Stack>

      </Stack>

      {/* MORE LINK */}
      {!!moreLink && (
        <Box sx={{ mt: 2 }}>
          {moreLink.map((href) => (
            <Link
              noWrap
              key={href}
              href={href}
              variant="body2"
              target="_blank"
              rel="noopener"
              sx={{ display: 'table' }}
            >
              {href}
            </Link>
          ))}
        </Box>
      )}
    </Box>
  );
}

// ----------------------------------------------------------------------

function Separator() {
  return (
    <Box
      component="span"
      sx={{ width: 4, height: 4, borderRadius: '50%', bgcolor: 'text.disabled' }}
    />
  );
}
