import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,

  Chip,
  Container,
} from '@mui/material';


import { genreList, subGenreList, moodList, instrumentList, themeList } from "./TagList";
import { varFade, MotionViewport } from '../components-default/animate';
import isAA from "./isAA";


// ----------------------------------------------------------------------



UploadTagsSection.propTypes = {
  tags: PropTypes.object,
  setTags: PropTypes.func,
  errorState: PropTypes.any,
  errorMessage: PropTypes.string,
  tagsLength: PropTypes.number,
};



export default function UploadTagsSection(props) {

  const theme = useTheme();

  const {
    tags,
    setTags,
    tagsLength,
    errorState,
    errorMessage,
  } = props;

  const pillsGap = 1.5;



  return (
    <Container component={MotionViewport}
      sx={{
        m: 0,
        p: 0,
        pl: '0px !important',
        pr: '0px !important',
        // border: '1px solid red',
      }}
    >
      <m.div variants={varFade().inLeft} sx={{ m: 0, p: 0, pl: '0px !important', pr: '0px !important' }}>

        <Box // TAGS SECTION
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            pr: 3,
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
            <Typography variant='subtitle1' >Tags</Typography>
            {/* <Typography variant='caption' sx={{opacity: .5}}>(3 minimum)</Typography> */}
            {(errorState === 'tags' && tagsLength < 3) &&
              <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
            }

          </Box>

          <Typography variant='subtitle2' sx={{ mt: 2, pl: 2 }}>Genre</Typography>
          <Box
            sx={{
              display: 'flex',
              gap: pillsGap,
              width: '100%',
              flexWrap: 'wrap',
              pl: 3,
            }}
          >
            {genreList.map(tag =>
              <Chip
                key={tag}
                variant={tags.genre.includes(tag) ? 'filled' : 'outlined'}
                // color={tags.includes(tag) ? 'primary' : 'inherit'}
                color={tags.genre.includes(tag) ? 'primary' : 'default'}
                label={tag}
                value={tag}
                onClick={() => {
                  if (tags.genre.includes(tag)) {
                    // console.log('SUBS: ', subGenreList[tag])
                    let newGenre = tags.genre.filter(currentTag => currentTag !== tag);
                    if (subGenreList[tag]) {
                      // console.log('SUBTAGS: ', subGenreList[tag])
                      subGenreList[tag].map(subTag => { // eslint-disable-line
                        const newGenre2 = newGenre.filter(currentTag => currentTag !== subTag);
                        // console.log('newGenre2: ', newGenre2)
                        newGenre = newGenre2
                      })
                      const newTags = { ...tags }
                      newTags.genre = newGenre
                      setTags(newTags)
                    } else {
                      const newTags = { ...tags }
                      newTags.genre = newGenre
                      setTags(newTags)
                    }
                    // const newTags = { ...tags }
                    // newTags.genre = newGenre
                    // setTags(newTags)
                  } else {
                    const newGenre = tags.genre;
                    newGenre.push(tag)
                    const newTags = { ...tags }
                    newTags.genre = newGenre
                    setTags(newTags)
                  }
                }}
                sx={{
                  border: '1px solid inherit'
                }}
              />
            )}
          </Box>
          {
            (tags?.genre?.includes('electronic')
              || tags?.genre?.includes('cinematic')
              || tags?.genre?.includes('hiphop')
              || tags?.genre?.includes('country')
              || tags?.genre?.includes('rock')) &&
            <>
              <Typography variant='subtitle2' sx={{ mt: 2, pl: 2 }}>Sub Genre</Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: pillsGap,
                  width: '100%',
                  flexWrap: 'wrap',
                  pl: 3,
                }}
              >
                {tags?.genre?.includes('electronic') &&
                  subGenreList.electronic.map(tag =>
                    <Chip
                      key={tag}
                      variant={tags.genre.includes(tag) ? 'filled' : 'outlined'}
                      // color={tags.includes(tag) ? 'primary' : 'inherit'}
                      color={tags.genre.includes(tag) ? 'primary' : 'default'}
                      label={tag}
                      value={tag}
                      onClick={() => {
                        if (tags.genre.includes(tag)) {
                          const newGenre = tags.genre.filter(currentTag => currentTag !== tag);
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                          // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                        } else {
                          const newGenre = tags.genre;
                          newGenre.push(tag)
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                        }
                      }}
                      sx={{
                        border: '1px solid inherit'
                      }}
                    />
                  )
                }
                {tags?.genre?.includes('country') &&
                  subGenreList.country.map(tag =>
                    <Chip
                      key={tag}
                      variant={tags.genre.includes(tag) ? 'filled' : 'outlined'}
                      // color={tags.includes(tag) ? 'primary' : 'inherit'}
                      color={tags.genre.includes(tag) ? 'primary' : 'default'}
                      label={tag}
                      value={tag}
                      onClick={() => {
                        if (tags.genre.includes(tag)) {
                          const newGenre = tags.genre.filter(currentTag => currentTag !== tag);
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                          // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                        } else {
                          const newGenre = tags.genre;
                          newGenre.push(tag)
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                        }
                      }}
                      sx={{
                        border: '1px solid inherit'
                      }}
                    />
                  )
                }
                {tags?.genre?.includes('hiphop') &&
                  subGenreList.hiphop.map(tag =>
                    <Chip
                      key={tag}
                      variant={tags.genre.includes(tag) ? 'filled' : 'outlined'}
                      // color={tags.includes(tag) ? 'primary' : 'inherit'}
                      color={tags.genre.includes(tag) ? 'primary' : 'default'}
                      label={tag}
                      value={tag}
                      onClick={() => {
                        if (tags.genre.includes(tag)) {
                          const newGenre = tags.genre.filter(currentTag => currentTag !== tag);
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                          // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                        } else {
                          const newGenre = tags.genre;
                          newGenre.push(tag)
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                        }
                      }}
                      sx={{
                        border: '1px solid inherit'
                      }}
                    />
                  )
                }
                {tags?.genre?.includes('rock') &&
                  subGenreList.rock.map(tag =>
                    <Chip
                      key={tag}
                      variant={tags.genre.includes(tag) ? 'filled' : 'outlined'}
                      // color={tags.includes(tag) ? 'primary' : 'inherit'}
                      color={tags.genre.includes(tag) ? 'primary' : 'default'}
                      label={tag}
                      value={tag}
                      onClick={() => {
                        if (tags.genre.includes(tag)) {
                          const newGenre = tags.genre.filter(currentTag => currentTag !== tag);
                          // const newGenre2 = newGenre.filter(currentTag => subGenreList[tag].includes(tag));
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                          // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                        } else {
                          const newGenre = tags.genre;
                          newGenre.push(tag)
                          const newTags = { ...tags }
                          newTags.genre = newGenre
                          setTags(newTags)
                        }
                      }}
                      sx={{
                        border: '1px solid inherit'
                      }}
                    />
                  )
                }
              </Box>
            </>
          }

          <Typography variant='subtitle2' sx={{ mt: 2, pl: 2 }}>Mood</Typography>
          <Box sx={{ display: 'flex', gap: pillsGap, width: '100%', flexWrap: 'wrap', pl: 3 }}>
            {moodList.map(tag =>
              <Chip
                key={tag}
                variant={tags.mood.includes(tag) ? 'filled' : 'outlined'}
                // color={tags.includes(tag) ? 'primary' : 'inherit'}
                color={tags.mood.includes(tag) ? 'primary' : 'default'}
                label={tag}
                value={tag}
                onClick={() => {
                  if (tags.mood.includes(tag)) {
                    const newMood = tags.mood.filter(currentTag => currentTag !== tag);
                    const newTags = { ...tags }
                    newTags.mood = newMood
                    setTags(newTags)
                    // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                  } else {
                    const newMood = tags.mood;
                    newMood.push(tag)
                    const newTags = { ...tags }
                    newTags.mood = newMood
                    setTags(newTags)
                  }
                }}
                sx={{ border: '1px solid inherit' }}
              // onDelete={()=> console.log('Delete')}
              />
            )}
          </Box>

          <Typography variant='subtitle2' sx={{ mt: 2, pl: 2 }}>Instrument</Typography>
          <Box sx={{ display: 'flex', gap: pillsGap, width: '100%', flexWrap: 'wrap', pl: 3 }}>
            {instrumentList.map(tag =>
              <Chip
                key={tag}
                variant={tags.instrument.includes(tag) ? 'filled' : 'outlined'}
                // color={tags.includes(tag) ? 'primary' : 'inherit'}
                color={tags.instrument.includes(tag) ? 'primary' : 'default'}
                label={tag}
                value={tag}
                onClick={() => {
                  if (tags.instrument.includes(tag)) {
                    const newInstrument = tags.instrument.filter(currentTag => currentTag !== tag);
                    const newTags = { ...tags }
                    newTags.instrument = newInstrument
                    setTags(newTags)
                    // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                  } else {
                    const newInstrument = tags.instrument;
                    newInstrument.push(tag)
                    const newTags = { ...tags }
                    newTags.instrument = newInstrument
                    setTags(newTags)
                  }
                }}
                sx={{ border: '1px solid inherit' }}
              // onDelete={()=> console.log('Delete')}
              />
            )}
          </Box>
          <Typography variant='subtitle2' sx={{ mt: 2, pl: 2 }}>Theme</Typography>
          <Box sx={{ display: isAA() ? 'flex' : 'none', gap: pillsGap, width: '100%', flexWrap: 'wrap', pl: 3 }}>
            {themeList.map(tag =>
              <Chip
                key={tag}
                variant={tags.theme.includes(tag) ? 'filled' : 'outlined'}
                // color={tags.includes(tag) ? 'primary' : 'inherit'}
                color={tags.theme.includes(tag) ? 'primary' : 'default'}
                label={tag}
                value={tag}
                onClick={() => {
                  if (tags.theme.includes(tag)) {
                    const newTheme = tags.theme.filter(currentTag => currentTag !== tag);
                    const newTags = { ...tags }
                    newTags.theme = newTheme
                    setTags(newTags)
                    // setTags(tags.genre.filter(currentTag => currentTag !== tag))
                  } else {
                    const newTheme = tags.theme;
                    newTheme.push(tag)
                    const newTags = { ...tags }
                    newTags.theme = newTheme
                    setTags(newTags)
                  }
                }}
                sx={{ border: '1px solid inherit' }}
              // onDelete={()=> console.log('Delete')}
              />
            )}
          </Box>
        </Box>
      </m.div>
    </Container>

  );
}
