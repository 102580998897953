import orderBy from 'lodash/orderBy';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useEffect, useCallback, useState } from 'react';
// @mui
import { Box, Grid, Button, Container, Typography, Select, MenuItem, Stack } from '@mui/material';
// utils
import { useQuery, useMutation } from "@apollo/client";
import { GET_ACTIVE_BRIEFS_BY_ORG, GET_DOCS_BY_ORG_PITCH } from '../graphql/queries'
import axios from '../utils/axios';

import { useAuthContext } from '../auth/useAuthContext';

import Footer from '../layouts/main/Footer';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
// components
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import Iconify from '../components-default/iconify';
import { SkeletonPostItem } from '../components-default/skeleton';
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import { BriefPostCard, BriefPostCardV2, BlogPostsSort, } from '../components-custom/sections/@dashboard/blog';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';
import BriefPostCardDemo from '../components-custom/sections/@dashboard/blog/BriefPostCardDemo';
import DemoWelcomeDialog from '../components-custom/DemoWelcomeDialog';

// graphql

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function BriefsDemo() {

  const { themeStretch } = useSettingsContext();

  const [posts, setPosts] = useState([]);

  const { user } = useAuthContext();
  
  const demoOrg = '66125731-e329-4883-a5e1-8826f89b5fc5'

  const navigate = useNavigate();

  const userr = user?._id || user?._id

  const [briefSet, setBriefSet] = useState('active')

  const [sortMethod, setSortMethod] = useState('date-ascending')

  const [displayBriefs, setDisplayBriefs] = useState([])
  const [pitchedSongs, setPitchedSongs] = useState([])
  const [demoDialogOpen, setDemoDialogOpen] = useState(true)
  const [ cartItems, setCartItems] = useState();


  function compareBriefData(obj1, obj2) {
    const json1 = JSON.stringify(obj1);
    const json2 = JSON.stringify(obj2);
  
    return json1 === json2;
  }


  // const { loading: cartLoading, error: cartError, data: cartData = {getDocumentsByPitchedToOrg: []}, refetch: cartRefetch } = useQuery(GET_DOCS_BY_ORG_PITCH, {
  //   variables: { orgId: user?.organization },
  //   fetchPolicy: 'network-only'
  // });

  const { loading: briefsLoading, error: briefsError, data: briefsData = {getActiveBriefsByOrg: []}, refetch: briefsRefetch } = useQuery(GET_ACTIVE_BRIEFS_BY_ORG, {
    variables: { orgId: demoOrg },
    fetchPolicy: 'network-only'
  });

  const { loading: pitchesLoading, error: pitchesError, data: pitchesData = {getDocumentsByPitchedToOrg: []}, refetch: pitchesRefetch } = useQuery(GET_DOCS_BY_ORG_PITCH, {
    variables: { orgId: demoOrg },
    fetchPolicy: 'network-only'
  });

  function findBriefPitches(briefId){
    let total = 0;
    pitchedSongs.map(item => { // eslint-disable-line
      item.pitches.map(pitch => { // eslint-disable-line
        if (pitch.briefId === briefId && pitch.status === 'pitched')
         total += 1
      })
    })
    return total
  }
  

  function closeDialog(){
    setDemoDialogOpen(false)
  }



  useEffect(() => {
    if (briefsData?.getActiveBriefsByOrg?.length > 0 && (!briefsLoading && !pitchesLoading)) {
      const sortedBriefs = briefsData?.getActiveBriefsByOrg.sort((a, b) => new Date (b.post.createdAt) - new Date(a.post.createdAt))
      const extraSortedBriefs = sortedBriefs.filter(x => x?.post?.category !== 'Any Genre')

      sortedBriefs.map(item => { // eslint-disable-line
        if (item?.post?.category === 'Any Genre'){
          return extraSortedBriefs.push(item)
        }
      })

      setDisplayBriefs(extraSortedBriefs)
      setPitchedSongs(pitchesData?.getDocumentsByPitchedToOrg)
      // console.log(briefsData?.getActiveBriefsByOrg)
    }
// 
  }, [briefsData, pitchesData]); // eslint-disable-line


  // useEffect(() => {
  //   if (cartData?.getDocumentsByPitchedToOrg.length > 0) {

  //     const cart = cartData?.getDocumentsByPitchedToOrg.filter((doc) => doc.inCart?._id === user?.organization)
  //     setCartItems(cart?.length || 0)

  //   }
  // }, [cartData]);  // eslint-disable-line


  if (briefsLoading || pitchesLoading){
    return <LoadingScreen />
  }

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Briefs</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Demo Briefs'
          // action={
          //   //
          // }
          links={[
            {
              name: 'Demo',
              href: '/demo',
            },
            {
              name: 'Briefs',
            },
          ]}
        />

        {displayBriefs.length !== 0 ? 
        <Grid container spacing={3} sx={{mb: 10}}>
          {displayBriefs.length > 0 && displayBriefs.map((post, index) =>
           (
             <Grid key={post._id} item xs={12} sm={6} md={4}>
                <BriefPostCardDemo post={post.post} id={post._id }index={index} currentPitches={findBriefPitches(post._id)}/>
              </Grid>

          )) }
        </Grid> :
        <Box sx={{
          p: 5,
          pb: 10,
        }}>
          <Typography variant='body'>No briefs currently.</Typography>

        </Box>
        }
      </Container>

      <Footer/>

    </>
  );
}

// ----------------------------------------------------------------------

