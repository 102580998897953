import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';
import CancelIcon from '@mui/icons-material/Cancel';

import axios from 'axios';

import { Box, Button, Container, Typography, DialogActions, Dialog, Divider, Stack, TextField } from '@mui/material';
// components
import { Image } from '@mui/icons-material';
import Iconify from '../components-default/iconify/Iconify';
import Scrollbar from '../components-default/scrollbar';

import isAA from './isAA';
import DisplayEditComponent from './DisplayEditComponent';

// ----------------------------------------------------------------------


OrgDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  setOpenOrgDrawer: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  orgId: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default function OrgDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  setOpenOrgDrawer,
  orgId,
  onSubmit,
}) {

  const [org, setOrg] = useState('')

  async function getOrg(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getorg`, {  // eslint-disable-line
        orgId,
      }).then(response => {
        console.log(response)
        setOrg(response.data.org)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  useEffect(() => {
    if (orgId !== ''){
      getOrg()
    }
  },[orgId]) //eslint-disable-line

  return (
    <Dialog open={open} onClose={onClose} 
      sx={{
        // width: '600px',
      }}
    >
      <Box 
        sx={{
          width: {
            sm: '300px',
            md: '500px',
          }, 
        }}
      >

      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction='row' spacing={2} width='100%' justifyContent='flex-start' alignItems='center'>
          <Typography variant="h6" 
            // sx={{ flexGrow: 1 }}
          >
            Organization
          </Typography>
        </Stack>

        <Button variant="text" color="inherit" onClick={()=>{setOpenOrgDrawer(false)}}>
        <Iconify icon='zondicons:close' />
        </Button>

      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 5, 
              mb: 10, 
              // ml: 10, 
              // mr: 20, 
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
          <Stack 
            justifyContent='center' 
            alignItems='center' 
            // width='80%' 
            textAlign='center'
            gap={6}
            marginBottom={6}
          >
            <Typography variant='h4'>
              {org.name}
            </Typography>
          </Stack>
          <DisplayEditComponent title='Dang' value={org?.state}/>
          <Stack direction='row' spacing={1} 
            width='100%' justifyContent='flex-start'
          >
            <Typography variant='subtitle2'>
              Price Options
            </Typography>
          </Stack>
          <Stack direction='row' spacing={2}
            width='100%'
          >
            <TextField 
              size='small'
            />
            <Iconify icon='material-symbols:edit'
              onClick={()=>{
                console.log('edit')
              }}
            />
          </Stack>
          </Container>
        </Scrollbar>
        </Box>

    </Dialog>
  );
}

// ----------------------------------------------------------------------

