import { forwardRef, useState } from 'react';
import PropTypes from 'prop-types';

import { LoadingButton } from '@mui/lab';

// @mui
import { useTheme } from '@mui/material/styles';


import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  TextField,
  Stack,
  Select,
  MenuItem,
} from '@mui/material';


import CheckIcon from '@mui/icons-material/Check';

import axios from '../utils/axios';
import { fData } from '../utils/formatNumber';

import { UploadAvatar} from './upload';
import Image from './image';
import isAA from './isAA';
import AASubscriptionLink from './AASubscriptionLink';
import TBSubscriptionLink from './TBSubscriptionLink';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="right" ref={ref} {...props} />);


AccountDialog.propTypes = {
    accountDialogOpen: PropTypes.bool,
    setAccountDialogOpen: PropTypes.func,
    user: PropTypes.object,
    userr: PropTypes.string,
    refetch: PropTypes.func,
    setToast: PropTypes.func,
    handleCancelSubscription: PropTypes.func,
    accountMessage: PropTypes.string,
    setAccountMessage: PropTypes.func,
    cancelWarning: PropTypes.bool,
    setCancelWarning: PropTypes.func,
  };



export default function AccountDialog({accountDialogOpen, setAccountDialogOpen, user, userr, refetch, setToast, handleCancelSubscription, accountMessage, setAccountMessage, cancelWarning, setCancelWarning}) {

  const theme = useTheme();
  
  const [accountInfo, setAccountInfo] = useState({name: user?.name || '', email: user?.email || '', plan: user?.plan})
  const [fileHolder, setFileHolder] = useState('')

  const [avatarHolder, setAvatarHolder] = useState(null)

  const [ avatarState, setAvatarState ] = useState('original')

  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  // const [cancelWarning, setCancelWarning] = useState(false)
  // const [accountMessage, setAccountMessage] = useState('')

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClose = () => {
    setAccountDialogOpen(false);
    // setAccountInfo({name: '', artwork: '', bio: ''})
    // setFileHolder('');
  };

  const handleDrop = (e) => {

    const file = e[0];

    const newFile = Object.assign(file, {
      preview: URL.createObjectURL(file),
    });

    setAvatarHolder(file)
  }

    
  function successCallback() {
    console.log('Upload Success!')
    setTimeout(()=>{
      setLoading(false)
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Profile successfully updated`, duration: 4000})
      handleClose();
      // refetch()
      // console.log('refetching from dialog')
      location.reload() // eslint-disable-line
    }, 1000)
  }


  const handleUpload = async () => {

    const coverBlob = avatarHolder !== null ? new Blob([avatarHolder, { type: avatarHolder.type }]) : '';
    const formData = new FormData();
    
    formData.append('fileType', avatarHolder !== null ? avatarHolder.type : '' );
    formData.append('userId', user._id);

    if (avatarHolder !== null) {
      formData.append('file', coverBlob, avatarHolder !== null ? avatarHolder.name : '');
    }
    
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/uploadAvatar`, formData, {  // eslint-disable-line
            headers: {
                'Content-Type':'multipart/form-data',
            },
        }).then(response => {
          successCallback();
          console.log(response)
        });
      } catch (err) {
          console.log(err.response.data)
      
    }
  }

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoading(true)

    const coverBlob = avatarHolder !== null ? new Blob([avatarHolder, { type: avatarHolder.type }]) : '';
    const formData = new FormData();
    
    formData.append('fileType', avatarHolder !== null ? avatarHolder.type : '' );
    formData.append('userId', user._id);
    formData.append('name', accountInfo.name);
    formData.append('email', accountInfo.email);

    if (avatarHolder !== null) {
      formData.append('file', coverBlob, avatarHolder !== null ? avatarHolder.name : '');
    }
    
    // console.log('FORMDATA: ',formData)
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/updateuser`, formData, {  // eslint-disable-line
            headers: {
                'Content-Type':'multipart/form-data',
            },
        }).then(response => {
          if (response.status === 401){
            setErrorMessage(response.data)
          }
          if (response.status === 200){
            successCallback();
            console.log(response)
          } else {
            console.log('ERROR: ',response)
          }
        });
      } catch (err) {
          setLoading(false)
          setErrorMessage(err)
          console.log('CATCH ERROR: ',err)
      
    }
  }



  return (
    <div>
      <Dialog
        open={accountDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll='body'
        onClose={()=>{setAccountDialogOpen(false)}}
        sx={{
            height: '100%',
            paper: {
            }
        }}
      >
        <Box 
          sx={{
            width: {
              sm: '300px',
              md: '500px',
            }, 
          }}
        >

        <DialogTitle 
          id="add-artist-title"
          sx={{
            pb: 0,
          }}
        >
          Account Details
        </DialogTitle>

        <DialogContent scroll='body' >
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 3,
              height: '100%',
              width: '100%',
              minWidth: '100px',
              pt: 3,
              px: 0,
              // border: 'red solid 1px',
            }}
            >
            {avatarState === 'original' &&
              <Box 
                sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Image src={user?.avatar} sx={{
                  height: '130px',
                  width: '130px',
                  borderRadius: "50%",
                  border: `${theme.palette.text.primary} solid 2px`,
                }}/>
                <Button variant='text' 
                  onClick={()=>{setAvatarState('edit')}}
                  sx={{
                    // opacity: isHovered ? 1 : .2, 
                    transition: 'all .2s ease-in-out',
                  }}
                  >
                  change avatar
                </Button>
              </Box>
            }
            {(avatarState === 'edit' && !avatarHolder )&&
            
              <Box 
              // upload avatar section, need to
              // make dialog box
                sx={{
                  display: 'block',
                }}
              >
                <Box sx={{display: 'flex', justifyContent: 'center',}}>
                  <UploadAvatar
                    accept={{
                      'image/*': [],
                    }}
                    // error={!!error}
                    file={avatarHolder}
                    sx={{
                      // backgroundColor: 'red',
                    }}
                    // {...other}
                    onDrop={handleDrop}
                  />
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    mt: 2,
                    mx: 'auto',
                    display: 'block',
                    textAlign: 'center',
                    color: 'text.secondary',
                  }}
                >
                  Allowed *.jpeg, *.jpg, *.png, *.gif
                  <br /> max size of {fData(3145728)}
                </Typography>
              </Box>
            }
            {avatarHolder && 
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Image src={avatarHolder?.preview} sx={{
                  height: '130px',
                  width: '130px',
                  borderRadius: "50%",
                  border: `${theme.palette.text.primary} solid 2px`,
                }}/>              
                <Button variant='text'
                  sx={{textTransform: 'none'}}
                    onClick={async ()=>{
                    setAvatarHolder(null);
                    setAvatarState('original')
                  }}
                >
                  clear selection
                </Button>            
                </Box>
            }
            <Stack spacing={1} direction='column'>   
              <Typography variant='body'>
                Name
              </Typography>
              <TextField 
                autoComplete='off'
                value={accountInfo.name}
                onChange={(e)=>{setAccountInfo({...accountInfo, name: e.target.value})}}
              />
            </Stack>
            <Stack spacing={1} direction='column'>   
              <Typography variant='body'>
                Email
              </Typography>
              <TextField 
                autoComplete='off'
                value={accountInfo.email}
                onChange={(e)=>{setAccountInfo({...accountInfo, email: e.target.value})}}
              />
            </Stack>

            <Stack spacing={1} direction='column'>   
              <Typography variant='body'>
                Plan
              </Typography>
              {user?.role === 'admin' ? 
              <Select
                    id='plan-select'
                    value={accountInfo.plan}
                    defaultValue={accountInfo.plan}
                    onChange={(e)=>{
                      setAccountInfo({...accountInfo, plan: e.target.value})
                      console.log('Select: ', e.target.value)
                    }}
                    // displayEmpty
                    inputProps={{ 'aria-label': 'Plan' }}
                    fullWidth
                    // size='small'
                    placeholder={accountInfo.plan}
                    sx={{
                      mr: 2,
                    }}
              >
                <MenuItem value='Beta' selected={accountInfo.plan === 'Beta'}
                >
                  Beta
                </MenuItem>
                <MenuItem value='Commission' selected={accountInfo.plan === 'Commission'}
                >
                  Commission
                </MenuItem>
                <MenuItem value='Premium' selected={accountInfo.plan === 'Premium'}
                >
                  Premium
                </MenuItem>
              </Select> : 
              <Stack direction='row' alignItems='center' justifyContent='space-between'
                sx={{
                  height: '56px',
                  borderRadius: 1,
                  border: `${theme.palette.divider} solid 1px`,
                  p:3,
                  display: !cancelWarning ? 'flex' : 'none',
                }}
              >
                <Typography variant='body'
                  sx={{
                    cursor: 'default',
                  }}
                >
                  {user?.plan}
                </Typography>
                {(user?.plan !== 'Premium' && user?.role !== 'client') ?
                  <Button variant='contained' color='primary'
                    href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink()}
                  >
                    Subscribe
                  </Button> :
                  <Button variant='text' color='error'
                    onClick={()=>{setCancelWarning(true)}}
                    sx={{
                      display: user?.role !== 'client' ? 'inherit' : 'none',
                    }}
                  >
                    Cancel Subscription
                  </Button>
                }
              </Stack>
              }
              {cancelWarning &&
                <Box sx={{
                  borderRadius: 1,
                  border: `${theme.palette.divider} solid 1px`,
                  backgroundColor: theme.palette.info.light,
                  p:3,
                  marginTop: '22px',
                }}
                >
                  {accountMessage === '' ?
                  <>
                  <Typography variant='subtitle1'
                    sx={{
                      color: theme.palette.common.white
                    }}
                  >
                    Are you sure you want to cancel your subscription? 
                  </Typography>
                  <br/>
                  <Typography variant='caption'
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  >
                    All current pitches will be removed, but your songs will remain in the database in case you ever re-subscribe.
                  </Typography>
                  <br/>
                  <Stack direction='row' justifyContent='center' marginTop={3}>
                    <Button variant='outlined' color='error'
                      sx={{
                        mr: 3,
                        width: '166px',
                        backgroundColor: theme.palette.common.white,
                        '&:hover': {
                          backgroundColor: theme.palette.error.main,
                          color: theme.palette.common.white,
                        }
                      }}
                      onClick={()=>{setCancelWarning(false)}}
                    >
                      Nevermind
                    </Button>
                    <Button variant='contained' color='error'
                      onClick={()=>{handleCancelSubscription()}}
                    >
                      Cancel Subscription
                    </Button>
                    </Stack> 
                    </> :
                    <>
                      <Typography variant='subtitle1'
                        sx={{
                          color: theme.palette.common.white
                        }}
                      >
                        {accountMessage}
                      </Typography>
                      <Typography variant='subtitle2'
                        sx={{
                          color: theme.palette.common.white
                        }}
                      >
                        Sorry to see you go!
                      </Typography>
                    </>
                  }

                </Box>
              }
            </Stack>


          </Box>


        </DialogContent>

        <Stack direction='row' width='100%' justifyContent='center'>
          <Typography variant='body' color='error'
            sx={{
              display: errorMessage === '' ? 'none' : 'inherit',
              mt: 3,
            }}
          >
            Error: {errorMessage}
          </Typography>
        </Stack>

        <DialogActions >
          {!cancelWarning &&
          <>
            <Button 
              size='medium' 
              color='inherit' 
              variant="outlined" 
              onClick={handleClose} 
              sx={{px: "16px", py: "7px"}}
            >
              Cancel
            </Button>

            <LoadingButton 
              size='medium' 
              variant='contained'
              onClick={handleUpdate} 
              sx={{px: "16px", py: "7px"}}
              loading={loading}
            >
              Update
            </LoadingButton>
          </>
          }
        </DialogActions>
        </Box>

      </Dialog>
    </div>
  );
}
