import orderBy from 'lodash/orderBy';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
// @mui
import { Box, Grid, Button, Container, Typography, Select, MenuItem, Stack, Pagination } from '@mui/material';
import { useTheme } from '@mui/material/styles';


// utils
import queryString from 'query-string';
import axios from '../utils/axios';
import { useAuthContext } from '../auth/useAuthContext';

// components
import Footer from '../layouts/main/Footer';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import Iconify from '../components-default/iconify';
import { categoriesList } from '../components-custom/CategoriesList'
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import { BriefPostCardV2, } from '../components-custom/sections/@dashboard/blog';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';

// graphql

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function BriefsActiveV2() {

  const { themeStretch } = useSettingsContext();
  const theme = useTheme();

  const { user } = useAuthContext();

  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page;
  const categoryQuery = queryParams.category;
  const [axiosLoading, setAxiosLoading] = useState(false)


  const [briefSet, setBriefSet] = useState('active')


  const [displayBriefs, setDisplayBriefs] = useState([])
  const [briefStats, setBriefStats] = useState([]);
  const [briefCount, setBriefCount] = useState(null)
  const [category, setCategory] = useState(categoryQuery || 'All')
  const [page, setPage] = useState(parseInt(pageQuery, 10) || 1)
  const [perPage, setPerPage] = useState(12)
  const [pageCount, setPageCount] = useState(0)

  async function getTheBriefs() {
    setAxiosLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getbriefs`, {  // eslint-disable-line
        orgId: user?.organization,
        status: briefSet,
        queryType: 'client',
        page,
        perPage,
        userId: user?._id,
        category,
      }).then(response => {

        const sortedBriefs = response?.data?.briefs.sort((a, b) => new Date(b.post.createdAt) - new Date(a.post.createdAt))
        const extraSortedBriefs = sortedBriefs.filter(x => (x?.post?.category !== 'Various' && x?.post?.category !== 'Vocal'))

        sortedBriefs.map(item => { // eslint-disable-line
          if (item?.post?.category === 'Various') {
            return extraSortedBriefs.push(item)
          }
        })

        sortedBriefs.map(item => { // eslint-disable-line
          if (item?.post?.category === 'Vocal') {
            return extraSortedBriefs.push(item)
          }
        })

        setDisplayBriefs(extraSortedBriefs)
        setBriefCount(response?.data?.briefCount)
        setBriefStats(response?.data?.briefStats)

        const totalPages = response.data.briefCount / perPage
        setPageCount(Math.ceil(totalPages))


        setAxiosLoading(false)
      }
      );
    } catch (err) {
      console.log(err)
    }
  }

  function getTotalCart() {
    let total = 0
    briefStats.map(item => { // eslint-disable-line   
      total += item.inCart
    })
    return total
  }

  function renderPaginationButtons() {
    const buttons = [];

    for (let i = 1; i <= Math.ceil(briefCount / perPage);) {
      // buttons.push(<button type='button' key={i}>Page {i}</button>);
      buttons.push(
        <Button key={`pageButton${i}`} variant={(page === i) ? 'outlined' : 'text'} size='small' width='fit-content'
          sx={{
            px: '10px',
            minWidth: 0,
          }}
          disabled={page === i}
          onClick={() => {
            setPage(i - 1)
            navigate(`${window.location.pathname}?page=${i - 1}`)
          }}
        >
          <Typography variant='subtitle1' color={page === i ? 'info' : 'primary'}>
            {i}
          </Typography>
        </Button>

      );
      i += 1
    }
    return buttons
  }


  useEffect(() => {
    if (!pageQuery) {
      return navigate('/briefs?page=1')
    }
    getTheBriefs()
    return undefined
  }, []); // eslint-disable-line


  useEffect(() => { // eslint-disable-line
    if (category === '') {
      return undefined
    }
    navigate(`/briefs?category=${category}&page=${page}`)
    getTheBriefs()
    return undefined
  }, [category, perPage, page]); // eslint-disable-line



  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Briefs</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading='Briefs'
          action={
            <Stack direction='row' spacing={3}>
              {user.role !== 'producer' ?
                <Button
                  component={RouterLink}
                  to='create'
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  Add Brief
                </Button> : null
              }
              <Select
                defaultValue={briefSet}
                sx={{
                  display: user.role !== 'producer' ? 'flex' : 'none',
                  height: '36px',
                  width: {
                    sm: '140px',
                    md: '200px',
                  },
                }}
                onChange={(e) => {
                  if (e.target.value === 'inactive') {
                    navigate('/briefs/inactive')
                  }
                }}
              >
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
              </Select>
              <Select
                // defaultValue={sortMethod}
                value={perPage}
                sx={{
                  display: 'flex',
                  height: '36px',
                  width: '140px',
                }}
                onChange={(e) => {
                  setPage(1)
                  setPerPage(e.target.value)
                }}
              >
                <MenuItem value={12}>
                  12 per page
                </MenuItem>
                <MenuItem value={24}>
                  24 per page
                </MenuItem>
                <MenuItem value={48}>
                  48 per page
                </MenuItem>
                <MenuItem value={64}>
                  64 per page
                </MenuItem>
              </Select>
            </Stack>
          }
          links={[
            {
              name: 'Dashboard',
              href: '/dash',
            },
            {
              name: 'Briefs',
            },
          ]}
        />
        <Grid container spacing={2} sx={{ mt: 2, mb: 6 }}>
          {categoriesList.map(item => { // eslint-disable-line
            return <Grid item xs={4} sm={2} key={`buttons-${item}`}>
              <Button fullWidth size='large'
                variant={item === category ? 'contained' : 'outlined'}
                color={item === category ? 'primary' : 'info'}
                sx={{
                  opacity: item === category ? 1 : .5,
                }}
                onClick={() => {
                  if (category === item) {
                    setPage(1)
                    setCategory('All')
                  } else {
                    setPage(1)
                    setCategory(item)
                  }
                }}
              >
                {item}
              </Button>
            </Grid>
          }
          )}
        </Grid>
        <Grid item xs={12}
        >
          {pageCount > 1 &&
            <Box
              sx={{
                display: pageCount > 1 ? 'flex' : 'none',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
                // display: 'none',
              }}
            >

              <Pagination count={pageCount} page={parseInt(page, 10) || 1}
                // variant='soft'
                sx={{
                  // border: 'blue solid 1px',
                }}
                shape='rounded'
                color='primary'
                size='large'
                onChange={(e, value) => {
                  setPage(value)
                  // setUpdateTrigger(true)
                }} />
            </Box>
          }
        </Grid>
        {displayBriefs.length !== 0 ?
          <Grid container spacing={3} sx={{ mt: 0, mb: 10 }}>
            {displayBriefs.length > 0 && displayBriefs.map((post, index) =>
            (
              <Grid key={post._id} item xs={12} sm={4} md={3}>
                <BriefPostCardV2
                  post={post.post}
                  id={post._id}
                  index={index}
                  briefStats={briefStats.filter(x => x._id === post._id)}
                // currentPitches={findBriefPitches(post._id)}
                />
              </Grid>
            ))}
            <Grid item xs={12}
            >
              {pageCount > 1 &&
                <Box
                  sx={{
                    display: pageCount > 1 ? 'flex' : 'none',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    mb: 2,
                    // display: 'none',
                  }}
                >

                  <Pagination count={pageCount} page={parseInt(page, 10) || 1}
                    // variant='soft'
                    sx={{
                      // border: 'blue solid 1px',
                    }}
                    shape='rounded'
                    color='primary'
                    size='large'
                    onChange={(e, value) => {
                      setPage(value)
                      // setUpdateTrigger(true)
                    }} />
                </Box>
              }
            </Grid>
          </Grid> :
          <Box sx={{
            p: 5,
            pb: 10,
          }}>
            <Typography variant='body'>No briefs currently.</Typography>

          </Box>
        }
      </Container>
      {user?.role !== 'producer' &&
        <CartWidgetCustom totalItems={getTotalCart()} />
      }

      <Footer />

    </>
  );
}

// ----------------------------------------------------------------------

