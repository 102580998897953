import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { useTheme } from '@mui/material/styles';

// @mui
import { DatePicker } from '@mui/x-date-pickers';
import {
  Box,
  Button,
  Stack,
  Drawer,
  Divider,
  Tooltip,
  TextField,
  Typography,
  IconButton,
  ListItemText,
  ListItemButton,
  Grid,
  Checkbox,
  Switch,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';


// utils
import axios from '../utils/axios';
import { fDateTime, fToNow } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';
// components
import useCopyToClipboard from '../hooks/useCopyToClipboard';
import Iconify from '../components-default/iconify';
import Scrollbar from '../components-default/scrollbar';
import { ColorMultiPicker } from '../components-default/color-utils';

// ----------------------------------------------------------------------

OrderPaymentsDrawer.propTypes = {
  openPaymentsDrawer: PropTypes.bool,
  setOpenPaymentsDrawer: PropTypes.func,
  invoiceId: PropTypes.string,
  items: PropTypes.array,
  toast: PropTypes.object,
  setToast: PropTypes.func,
  getInventory: PropTypes.func,
  receipts: PropTypes.array

};

export default function OrderPaymentsDrawer({
  openPaymentsDrawer,
  setOpenPaymentsDrawer,
  invoiceId,
  items,
  toast,
  setToast,
  getInventory,
  receipts,
}) {
  
  const [payoutsList, setPayoutsList] = useState([])
  const [payoutsReceiptsState, setPayoutsReceiptState] = useState('')
  const [totalPayoutsAmount, setTotalPayoutsAmount] = useState(0)
  const [paidOutState, setPaidOutState] = useState(false)
  const [payLock, setPayLock] = useState(false)

  const theme = useTheme();
  const { copy } = useCopyToClipboard();


  async function getInvoicePayouts(){
    let total = 0
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/invoicepayouts`, {
      invoiceId,
    });

    const fullProducerList = response?.data?.payoutUsers

    const consolidatedList = []

    fullProducerList.map(item => { // eslint-disable-line
      // check to see if producer is already in list
      if (consolidatedList.some(entry => entry._id === item._id)){
        // if so, find the correct index and update details
        consolidatedList.map(x => { // eslint-disable-line
          if (x._id === item._id){
            x.amount += item.amount
            x.songs?.push(`, ${item?.songs}`)
          }
        })
      } else {
        consolidatedList.push(item)
      }
      total += item.amount
    })

    function compareByLastName(a, b) {
      const lastNameA = a?.name.split(' ')[1]; // Extract last name from 'name' property
      const lastNameB = b?.name.split(' ')[1];

      if (lastNameA === 'Thomas' && lastNameB !== 'Thomas') {
        return 1;
      } else if (lastNameA !== 'Thomas' && lastNameB === 'Thomas') { // eslint-disable-line
        return -1;
      }
    
      return lastNameA?.localeCompare(lastNameB);
    }
    const sortedList = consolidatedList.sort(compareByLastName)

    setPayoutsList(sortedList)
    setTotalPayoutsAmount(total)

  }


  async function handlePayProducer(userId, stripeId, amount, songs){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/payproducerstripe`, {  // eslint-disable-line
        stripeId,
        amount,
        userId,
        songs,
        invoiceId,
        // userId: '64d3c9649d24018f494a997c',
      }).then(response => {
        console.log(response)
        if (response?.data?.status === 'success'){
          const responseName = response?.data?.name || ''
          const responseAmount = response?.data?.amount || ''
          setToast({
            open: true, 
            message: `$${responseAmount/100} sent to ${responseName}`, 
            icon: <CheckIcon sx={{color: 'white'}}/>, 
            duration: 3000,
            toastColor: theme.palette.success.dark,
            closeButton: false,
            horizontal: 'right',
          })
      
        }
        if (response?.data?.status === 'error'){
          setToast({open: true, icon: null, horizontal: 'right', toastColor: theme.palette.error.dark, message: `${response?.data?.message}`, duration: 4000})
        }
      });
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
  }

  async function toggleInvoiceItemPaidout(songs, name, amount){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/markinvoiceitem`, {  // eslint-disable-line
        // docId: file._id,
        type: 'paidout',
        invoiceId,
        songs,
        name,
        amount,
      }).then(response => {
        console.log(response)
        getInventory()
        // getInvoicePayouts()
        if (response.status === 200){
          // setPrepped(!prepped)
        }
      });
    } catch (err) {
      if (err.response.status) {
        console.log('aint work')
      } else {
        console.log(err.response.data)
      }
  }
  }


  useEffect(()=>{

    if (openPaymentsDrawer === true){
      getInvoicePayouts()
    }

  }, [openPaymentsDrawer, items]) // eslint-disable-line

  useEffect(()=>{

    // console.log('PAYOUT RECEIPTS: ', receipts)
    // receipts.map(receipt => { // eslint-disable-line
    //   if (receipt?.name === )
    // })

  }, [receipts]) // eslint-disable-line

  return (
    <Drawer
      anchor="right"
      open={openPaymentsDrawer}
      variant='persistent'
      // sx={{
      // }}
      BackdropProps={{
        invisible: true,
        
      }}
      PaperProps={{
        sx: { 
          // mt: '100px',
          width: 420,
          boxShadow: theme.shadows[10],
          pb: '200px',
          // height: '100% - 100px',

        },
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pl: 2, pr: 1, py: 2 }}
      >
        <Iconify 
          icon='iconamoon:close-bold' 
          onClick={()=>{setOpenPaymentsDrawer(false)}}
          sx={{cursor: 'pointer'}}
        />
        <Stack direction='row' width='100%' justifyContent='center'>
          <Typography variant="subtitle1">Producer Payments</Typography>
        </Stack>

      </Stack>

      <Divider />
      <Stack direction='row' justifyContent='space-between'>
        <Stack direction='row' spacing={1} alignItems='center'>
          <Typography
            variant="subtitle2"
            sx={{
              color: 'text.secondary',
              fontWeight: 'fontWeightMedium',
              p: theme.spacing(2, 2, 1, 2),
            }}
          >
            Enable Payments
          </Typography>
          <Switch 
            // value={briefValues.active}
            checked={payLock}
            onChange={()=>{
              setPayLock(!payLock)
            }}                  
              // defaultChecked 
          />

        </Stack>
        <Typography
          variant="subtitle2"
          sx={{
            color: 'text.secondary',
            fontWeight: 'fontWeightMedium',
            p: theme.spacing(2, 2, 1, 2),
          }}
        >
          Total: ${totalPayoutsAmount/100}
        </Typography>
      </Stack>

      <Divider sx={{mb: 1}}/>
      <Grid container >

      {payoutsList.map((item, index) => { // eslint-disable-line
      return <Grid item xs={12} key={`${item?.briefTitle}-${index}`}
        sx={{
          borderRadius: 1,
          border: `${theme.palette.divider} solid 1px`,
          backgroundColor: theme.palette.background.paper,
          m: 1,
          p: 1,
          cursor: 'default',
          opacity: item?.name === 'Matt Thomas' ? .5 : 1,
          // alignItems: 'center',
          // gap: 2,
        }}
      >
        <Stack direction='row' spacing={2} alignItems='center'>
          <Typography variant='subtitle1' width='180px' minWidth='180px'>
            {item?.name !== 'Matt Thomas' ? item?.name : 'Audio Arsenal'}
          </Typography>
          <Typography variant='body' width='50px' minWidth='50px'>
            ${item.amount? item.amount/100 : ''}
          </Typography>
          {(item?.payoutType === 'Stripe' && !receipts.some(y => y.name === item?.name))&&
            <Button variant='contained' sx={{width: '100%'}} disabled={!payLock}
              onClick={()=>{
                handlePayProducer(item?._id, item?.payoutId,item?.amount, item?.songs)
              }}
            >
              Payout Now
            </Button>
          }
          {(item?.payoutType === 'Stripe' && receipts.some(y => y.name === item?.name))&&
            <Stack direction='row' width='100%' justifyContent='center'>  
              <CheckIcon color='success' />
            </Stack>
          }
          {item?.payoutType === 'Wise' &&
            <Button variant='contained' sx={{width: '100%'}}
              disabled
            >
              Pay via Wise
            </Button>
          }
          {item?.paymentSetup === false &&
            <Typography variant='caption' sx={{width: '100%'}}>
              Payments not setup
            </Typography>
          }

        </Stack>
        <Divider sx={{my: 1}} />
        <Stack direction='row' alignItems='flex-start'>
          <Checkbox color={receipts.some(y => y.name === item?.name) ? 'success' : 'info'} 
              checked={receipts.some(y => y.name === item?.name)} // eslint-disable-line
              onChange={()=>{
                toggleInvoiceItemPaidout(item?.songs, item?.name, item?.amount)
              }
              }
              disabled={receipts.some(y => y.name === item?.name)}
              sx={{
                color: receipts.some(y => y.name === item?.name) ? theme.palette.success.dark : theme.palette.info.main,
                my: 0,
              }}
            />

          <Typography variant='caption' sx={{opacity: .5, pt: 1.3,}}>
            {item?.songs}
          </Typography>
        </Stack>
      </Grid>

      }
      )}
      </Grid>



    </Drawer>
  );
}
