import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui

import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Card, Typography, CardContent, Stack, Link } from '@mui/material';
import { useAuthContext } from '../../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber, fCurrencyCents } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components-default/image';
import Iconify from '../../../../components-default/iconify';
import TextMaxLine from '../../../../components-default/text-max-line';
import BadgeStatus from '../../../../components-default/badge-status/BadgeStatus';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha('#85795a', .3),
  transition: 'all .15s ease-in-out',
  '&:hover': {
    backgroundColor: alpha('#85795a', 0.14),
    // backgroundColor: alpha(theme.palette.grey[700], 0.11),


  }
}));

// ----------------------------------------------------------------------

BriefPostCardDemo.propTypes = {
    id: PropTypes.string,
    post: PropTypes.object,
    cover: PropTypes.string,
    currentPitches: PropTypes.number,
    orgName: PropTypes.string,
  };
  
  export default function BriefPostCardDemo({ post, cover, id, currentPitches, orgName }) {

    const theme = useTheme();
    const navigate = useNavigate();
    // console.log('ORGNAME CARD: ', orgName)
  
    const { user } = useAuthContext();
    const userr = user?._id || user?._id

    // console.log('IMPORTED ORGNAME: ', orgName)

    return(
        <Link href={`/demo/${id}`}>
        
        <Card
          sx={{
            cursor: 'pointer',
            transition: 'all .2s ease-in-out',
            overflow: 'hidden',
            opacity: post?.active ? 1 : .4,
            '&:hover': {
              // boxShadow: '-8px 8px 2px rgba(0, 0, 0, 0.1)',
            },
          }}
  
        >
          {(user && user?.role !== 'client') &&
          <Box // wrapper label
          >
            <Typography variant='h5' color='common.white' fontWeight='bold'
              sx= {{ // wrapper text
                // display: 'none',
                position: 'absolute',
                top: 16,
                right: 4,
                zIndex: 20,
                // border: `${theme.palette.common.white}40 solid 1px`,
                borderRadius: 5,
                textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
                px: 2,
              }}>
                {post?.customRate ?
                 `$${fCurrencyCents(post?.customRate)}` :
                  `$${fCurrencyCents(post?.rate)}`
                }
            </Typography>
            <Box // wrapper background
              sx={{
                // display: 'none',
                position: 'absolute',
                top: -30,
                right: -70,
                zIndex: 10,
                height: '100px',
                width: '200px',
                // boxShadow: theme.shadows[12],
                backgroundColor: `${theme.palette.grey[900]}80`,
                transform: 'rotate(37deg)'
              }}
              />
  
          </Box>
          }
          <Typography 
              sx={{
                cursor: 'pointer',
                top: 24,
                left: 24,
                zIndex: 9,
                position: 'absolute',
                color: theme.palette.common.white,
                fontFamily: "peckham-press, sans-serif",
                fontSize: '18px',
                lineHeight: '26px',
                border: `${theme.palette.common.white} 2px solid`,
                borderRadius: '32px',
                padding: 2,
                paddingTop: 2.5,
                backgroundColor: `${theme.palette.grey[900]}80`,
              }}
              onClick={navigate(`/demo/${id}`)
              }
          >
            {post?.category || ''}
          </Typography>
          {!user?.receipts?.briefs.includes(id) &&
          <Box // wrapper label
          >
            <Box // wrapper status
              sx={{
                // display: 'none',
                position: 'absolute',
                top: 30,
                left: 34,
                zIndex: 20,
                height: '20px',
                width: 'fit-content',
                borderRadius: 2,
                // border: `white solid 1px`,
                px: 1,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: `${theme.palette.success.dark}`,
                // transform: 'rotate(37deg)'
              }}
              >
                <Typography variant='caption' sx={{color: 'white'}}>
                  New
                </Typography>
              </Box>
  
          </Box>
          }

          <Box // current pitches wrapper
           sx={{
            display: ((user?.role === 'client' || user?.role === 'admin' || !user) && post?.active === true) ? 'flex' : 'none',
            position: 'absolute',
            // top: 2,
            // right: 2,
            height: '100%',
            width: '100%',
            justifyContent: 'flex-end',
            zIndex: 10,
           }}
          >
            <Stack direction='column'
              sx={{
                width: 'fit-content',
                height: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                // color: theme.palette.success.main,
                zIndex: 200,
                mx: 1.5,
                my: 1,
                // px: 3.3,
                // py: 2,
                borderRadius: '50%',
                // border: 'white solid 1px',
                
              }}
              >

              <Typography variant='h5'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center'
                  
                }}
                >
                {currentPitches?.toString()}
              </Typography>
              <Typography variant='caption'
                sx={{
                  textShadow: theme.shadows[8],
                  color: 'white',
                  textAlign: 'center'
                }}
              >
                Pitches
              </Typography>
            </Stack>
            <Box // wrapper background
              sx={{
                position: 'absolute',
                top: -200,
                right: -200,
                height: '300px',
                width: '300px',
                // borderRadius: '50%',
                backgroundColor: alpha(theme.palette.success.dark, 1),
                display: 'flex',
                alignContent: 'flex-end',
                justifyContent: 'flex-start',
                transform: 'rotate(45deg)',
                zIndex: 4,
              }}/>

          </Box>
          {(user?.role === 'admin' && orgName) &&
            <Typography variant='h1' textTransform='uppercase'
              sx={{
                position: 'absolute',
                bottom: '90px',
                color: 'white',
                opacity: .5,
                zIndex: 100,
              }}
            >
              {orgName}
            </Typography>
          }

          <PostContent
            orgName
            user={user}
            title={post?.title || ''}
            createdAt={post?.createdAt || null}
          />
  
          <StyledOverlay />
  
          <Image 
            alt="cover" 
            src={post?.cover} 
            sx={{ height: 360 }} 
              
          />
        </Card>
        </Link>
    )
}

    export function PostContent(post, orgName, user) {
        // const isDesktop = useResponsive('up', 'md');
      
        const theme = useTheme();
        // console.log(orgName)
      
        return (
          <CardContent
            sx={{
              // pt: 4.5,
              width: 1,
              pt: 0,
              zIndex: 3,
              bottom: 0,
              position: 'absolute',
              color: 'common.white',
              '&:hover': {
                background: 'none',
              }
            }}
            >
            <TextMaxLine
              variant='h5'
              line={1}
              persistent
              sx={{
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                
              }}
              >
              {post?.title || ''}
            </TextMaxLine>
            <Typography
              gutterBottom
              variant="body2"
              component="div"
              sx={{
                opacity: 0.8,
                color: 'common.white',
                textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
              }}
            >
              {fDate(post?.createdAt || null)}
            </Typography>
      
          </CardContent>
        );
      }
      