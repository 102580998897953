import { useContext, createContext } from 'react';

// ----------------------------------------------------------------------

export const AudioContext = createContext({});

export const useAudioContext = () => {
  const context = useContext(AudioContext);

  if (!context) throw new Error('useSettingsContext must be use inside SettingsProvider');

  return context;
};