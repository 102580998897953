import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  const logo = (
    theme.palette.mode === 'light' ?
    <Box
      component="img"
      src="/logo/pikes.svg"
      sx={{ width: 40, height: 40, cursor: 'pointer', padding: '5px', ...sx }}
      onClick={()=>{
        // navigate('/dash')
      }}
    /> :
    <Box
      component="img"
      src="/logo/TB_Record_Small.png"
      sx={{ width: 50, height: 50, cursor: 'pointer', padding: '5px', ...sx }}
      onClick={()=>{
        // navigate('/dash')
      }}
    />
    
  );



  if (disabledLink) {
    return logo;
  }

  return (
    <Link component={RouterLink} to="/" sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
