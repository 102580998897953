import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Container, Typography } from '@mui/material';

import { loadStripe } from "@stripe/stripe-js";

// graphql
import { useQuery } from "@apollo/client";
import { GET_ORG_INFO, GET_USER_CART } from '../graphql/queries'

// _mock_

import { useDispatch } from '../redux/store';
import { addToCart } from '../redux/slices/product';



// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import AudioFileCardCart from '../sections/@dashboard/general/file/AudioFileCardCart'
import CheckoutSummary from '../components-custom/CheckoutSummary2'


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';


import CustomToast from '../components-custom/CustomToast';
import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import Footer from '../layouts/main/Footer';

// ----------------------------------------------------------------------

export default function CustomCart() {
  const theme = useTheme(); // eslint-disable-line

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const dispatch = useDispatch();


  // console.log('User: ', user)

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});
  const [cartQuantity, setCartQuantity] = useState();
  const [cartItems, setCartItems] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);

  const [selectedId, setSelectedId] = useState(null); // eslint-disable-line

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);
  
  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const { loading, error, data = {getUserCart: []}, refetch } = useQuery(GET_USER_CART, {  // eslint-disable-line
    variables: { orgId: `${user.organization}` },
    fetchPolicy: 'network-only'
  });


  const { loading: orgLoading, error: orgError, data: orgData = {getOrgInfo: {}}, refetch: orgRefetch } = useQuery(GET_ORG_INFO, {
    variables: { orgId: user.organization },
    fetchPolicy: 'network-only'
  });
  // console.log('Docs: ', data)
  
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLIC_KEY
  );

  function doRefetch() {
    refetch();
    console.log('refetched')
  }

  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }
  
  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };

  useEffect(() => {
    if (data?.getUserCart.length >= 0) {
      setDisplayFiles(data?.getUserCart);
      setCartQuantity(data?.getUserCart.length);

      const tempItems = [];
      let total = 0 // eslint-disable-line

      data?.getUserCart.map( item => {
        // console.log('ITEM: ', item)

        let pitchIndex = null

        if (item.pitches.length === 0) {
          pitchIndex = 0
        } else {
          pitchIndex = item.pitches.length -1
        }

        const file = {
          _id: item?._id, 
          title: item?.title, 
          artist: item?.artist, 
          price: item?.pitches[pitchIndex]?.rate,
          tags: item?.tags,
        };
        const itemCost = item?.pitches[pitchIndex]?.rate
        console.log('ITEM COST IS: ', typeof itemCost)
        if (typeof itemCost === 'number') {
          total += itemCost
        }
        return tempItems.push(file)
      })
      setCartTotal(total)
      setCartItems(tempItems)

    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Cart </title>
      </Helmet>
      <Box
        sx={{
          pt: 6,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            heading='Cart'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dash',
              },
              { name: 'Cart' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          // backgroundColor: 'red',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8} lg={8} xl={8} sx={{mb: 7}}>
            {loading ? <LoadingScreen/> : // eslint-disable-line
              <Box onKeyDown={handleKeyDown}>
              {displayFiles.length > 0 ? 
              displayFiles.map((doc, index) => (
                (!doc.pass?.includes(userr) || doc.pass?.includes(userr)) &&
                <AudioFileCardCart 
                  key={doc._id} 
                  userId={userr} 
                  user={user}
                  file={doc}
                  data={data}
                  dispatch={dispatch}
                  addToCart={addToCart}
                  refetch={()=> doRefetch()}
                  loadedSong={loadedSong} 
                  setLoadedSong={setLoadedSong}
                  setSelectedIndex={setSelectedIndex}
                  selectedIndex={selectedIndex}
                  setSelectedId={setSelectedId}
                  index={index}
                  selected={selectedIndex === index}
                  sx={{ mb: 2 }}
                  setControlMessage={setControlMessage}
                  isPlaying={isPlaying}
                  setIsPlaying={setIsPlaying}
                  audioBarFavorite={audioBarFavorite}
                  setAudioBarFavorite={setAudioBarFavorite}
                  toast={toast}
                  setToast={setToast}
                  favorites
                  inventoryPage
                /> 
              )) :
              
                <Typography variant="h5" sx={{ mb: 2 }}>
                  Nothing in cart
                </Typography>
            }
            </Box>
          }
          </Grid>
          {/* <Elements stripe={stripePromise}> */}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{mb: 7}}>
              <CheckoutSummary 
                user={user} 
                userr={userr} 
                totalItems={cartQuantity} 
                cartItemsDetail={cartItems}
                cartTotal={cartTotal}
                orgData={orgData?.getOrgInfo}
              />
            </Grid>
          {/* </Elements> */}

        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        audioBarFavorite={audioBarFavorite}
        setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        refetch={refetch}          
      />    
      {/* for this to work, have to separate add to cart and 
          remove from cart mutations, other wise toast doesn't
          know which one happened
      <CustomToast toast={toast} setToast={setToast}/> */}
  
    </>
  );
}
