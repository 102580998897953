import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, Divider, TextField, InputAdornment, IconButton } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';

// components
import isAA from '../components-custom/isAA';
import InvoiceToolbar from '../components-custom/sections/@dashboard/invoice/details-WM/InvoiceToolbar';
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import AudioFileCardAdminTBOrder from '../sections/@dashboard/general/file/AudioFileCardAdminTBOrder';

import { fDate2 } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import { genreList, instrumentList, moodList } from '../components-custom/TagList';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import OrderPaymentsDrawer from '../components-custom/OrderPaymentsDrawer';
import { AudioContext } from '../components-custom/audio/audio-context';
import FilterPane from '../components-custom/FilterPane';
import InventoryShowSearchBox from '../components-custom/InventoryShowSearchBox';


// ----------------------------------------------------------------------

export default function OrderDetailPageAdminV2() {
  const theme = useTheme();
  const { id } = useParams();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  // console.log('User: ', user)
  const [openUserDrawer, setOpenUserDrawer] = useState(false)

  const [userDrawerInfo, setUserDrawerInfo] = useState({})

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [dimPreppedFiles, setDimPreppedFiles] = useState(false);
  const [sortPreppedFiles, setSortPreppedFiles] = useState(false);
  const [preppedArray, setPreppedArray] = useState([]);
  const [invoiceInfo, setInvoiceInfo] = useState([]);

  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchTerm, setShowSearchTerm] = useState('');
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [songStatus, setSongStatus] = useState('pitched');

  const [pageLoaded, setPageLoaded] = useState(false)

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [inventoryCount, setInventoryCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [receipts, setReceipts] = useState([])


  const [openPaymentsDrawer, setOpenPaymentsDrawer] = useState(false)

  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });


  async function getInventory() {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/orderdetail`, {  // eslint-disable-line
        type: 'orderdetail',
        // perPage,
        // modifier: songStatus,
        // sortMethod,
        orgId: user?.organization,
        invoiceId: id,
        searchTerm,
      }).then(response => {
        // console.log(response)
        setAxiosResults(response.data.invoiceInfo)
        setInventoryCount(response.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }
  }


  function handleClearSearch() {
    if (filter.length === 0) {
      setDisplayFiles(axiosResults?.items)
    }
  }

  useEffect(() => {
    getInventory()
  }, []) // eslint-disable-line


  const sortedFiles = useMemo(() => {
    if (sortPreppedFiles) {
      return [...displayFiles].sort((a, b) => { // eslint-disable-line
        if (a.prepped === b.prepped) {
          // Add your sorting logic here
        }
      });
    }
    return displayFiles;
  }, [displayFiles, sortPreppedFiles]); // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== '') {
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.items)
      setInvoiceInfo(axiosResults)
      setReceipts(axiosResults?.payoutReceipts)

      const tempFilterList = [];

      axiosResults?.items.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
          doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.mood.length > 0) {
          doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.instrument.length > 0) {
          doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
      })
      setFilterList(tempFilterList)


      setAxiosLoading(false)

    }
  }, [axiosResults]) // eslint-disable-line


  useEffect(() => { // eslint-disable-line

    // console.log('Filter: ', filter)

    const filteredFiles = [];
    const searchDisplay = [];

    if (axiosResults === '') {
      return
    }
    // ////// IF NO FILTERS
    if (filter.length === 0) {

      if (showSearchTerm === '') { // NO FILTERS, NO SEARCH
        // console.log('NO FILTERS, NO SEARCH')
        setDisplayFiles(axiosResults?.items)
      }

      if (showSearchTerm !== '') { // NO FILTERS, SEARCH TRUE
        axiosResults?.items.map(item => { // eslint-disable-line
          if (item.title.toLowerCase().includes(showSearchTerm.toLowerCase()) || item.artist.name.toLowerCase().includes(showSearchTerm.toLowerCase())) {
            searchDisplay.push(item)
          }
        })
        // console.log('NO FILTERS, SEARCH')
        setDisplayFiles(searchDisplay)
      }
    }

    // ////// IF SOME FILTERS
    if (filter.length) {
      // console.log('IF FILTERS')
      if (searchTerm === '') { // if filters but no search
        axiosResults?.items.map(doc => { // eslint-disable-line
          if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
            return filteredFiles.push(doc)
          }
        })
        // console.log('FILTERS, NO SEARCH')
        setDisplayFiles(filteredFiles)
        return
      }
      if (searchTerm !== '') { // if filters and search term
        axiosResults?.items.map(item => { // eslint-disable-line
          if (item.title.toLowerCase().includes(showSearchTerm.toLowerCase()) || item.artist.name.toLowerCase().includes(showSearchTerm.toLowerCase())) {
            searchDisplay.push(item)
          }
        })
        searchDisplay.map(doc => { // eslint-disable-line
          if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
            return filteredFiles.push(doc)
          }
        })
        // console.log('FILTERS AND SEARCH')
        setDisplayFiles(filteredFiles)
      }

    }

  }, [filter, showSearchTerm]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }

    if (updateTrigger === false) {
      return
    }

    if (filter.length > 0) {
      console.log('FILTER: ', filter)
    }

  }, [updateTrigger]); // eslint-disable-line 


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CustomBreadcrumbs
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dash',
                },
                {
                  name: 'Orders',
                  href: '/orders',
                },
                { name: `Invoice ${id}` },
              ]
            }
            activeLast
            heading='Order Details'
          />
          {axiosResults !== '' &&
            <InvoiceToolbar invoice={axiosResults}
              sx={{
                px: 3,
              }}
            />
          }
        </Container>

      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
      >

        <Box
          sx={{
            // height: '150px',
            cursor: 'default',
            width: '100%',
            borderRadius: 1,
            backgroundColor: theme.palette.divider,
            border: `${theme.palette.info.light} solid 1px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mb: 6,
            px: 3,
            py: 1,
          }}
        >
          <Stack direction='row' alignItems='center' justifyContent='space-between' width='100%'>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Order Date:</Typography>
              <Typography variant='subtitle1'>{fDate2(invoiceInfo?.created_at)}</Typography>
            </Stack>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Invoice Id:</Typography>
              <Typography variant='subtitle1'>{invoiceInfo?._id}</Typography>
            </Stack>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Songs:</Typography>
              <Typography variant='subtitle1'>{invoiceInfo?.items?.length}</Typography>
            </Stack>
            <Stack direction='row' spacing={3}
              alignItems='center'
              padding={0}
            // border='red solid 1px'
            >
              <Typography variant='h6' sx={{ width: '100px', p: 0, textAlign: 'right' }}>Amount:</Typography>
              <Stack direction='row' spacing={1} alignItems='center'>
                <Typography variant='subtitle1'>${fCurrencyCents(invoiceInfo?.totalPrice)}</Typography>
                <Typography variant='caption'
                  sx={{
                    color: invoiceInfo?.status === 'paid' ? theme.palette.success.main : theme.palette.info.main,
                    border: invoiceInfo?.status === 'paid' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.info.main} solid 1px`,
                    px: 1,
                    borderRadius: 2,
                  }}
                >{invoiceInfo?.status === 'paid' ? 'PAID' : 'OPEN'}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>

        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
              Songs
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.info.light }}>
              {songStatus === 'pitched' &&
                `(${inventoryCount})`
              }
            </Typography>
          </Stack>
          <Stack direction='row' spacing={2}>
            <Button variant='outlined' color='success'
              onClick={() => {
                setOpenPaymentsDrawer(!dimPreppedFiles)
              }}
            >
              Producer Payments
            </Button>
            <Button variant='contained' color='primary'
              sx={{
                width: '155px',
              }}
              onClick={() => {
                setDimPreppedFiles(!dimPreppedFiles)
              }}
            >
              {dimPreppedFiles ? 'Show All Files' : 'Dim Prepped Files'}
            </Button>
            <Button variant='contained' color='primary'
              sx={{
                width: '155px',
                display: 'none',
              }}
              onClick={() => {
                setSortPreppedFiles(!sortPreppedFiles)
              }}
            >
              {sortPreppedFiles ? 'Default Sort' : 'Sort by Prepped'}
            </Button>
          </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}
            sx={{
              mb: 7,
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              },
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
          >
            {showSearchTerm !== '' &&
              <Box
                sx={{
                  // height: '100px',
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: theme.palette.divider,
                  border: `${theme.palette.info.main} solid 1px`,
                  p: 3,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant='subtitle1'>
                  Showing results for &apos;{showSearchTerm}&apos;
                </Typography>
                <IconButton onClick={() => {
                  setSearchTerm('')
                  setShowSearchTerm('')
                  handleClearSearch()
                  // setUpdateTrigger(true)
                }}
                >
                  <CancelIcon />

                </IconButton>
              </Box>
            }
            <InventoryShowSearchBox
              showSearchTerm={showSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setSearchTerm={setSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              filter={filter}
              setFilter={setFilter}

            />
            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%' /> :
              <Box >
                {(displayFiles?.length > 0) ?
                  sortedFiles.map((doc, index) => (
                    <AudioFileCardAdminTBOrder
                      key={doc._id}
                      id={doc._id}
                      userId={userr}
                      user={user}
                      // file={{ ...doc, passQueue: false }}
                      file={doc}
                      // data={invoiceData?.getInvoiceById?.items}
                      filter={filter}
                      // addToCart={addToCart}
                      // refetch={()=> doRefetch()}
                      loadedSong={src}
                      setLoadedSong={setSrc}
                      setSelectedIndex={setSelectedIndex}
                      selectedIndex={selectedIndex}
                      // setSelectedId={setSelectedId}
                      index={index}
                      selected={selectedIndex === index}
                      sx={{ mb: 2, mt: 0, display: 'flex' }} // eslint-disable-line
                      setControlMessage={setControlMessage}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      // audioBarFavorite={audioBarFavorite}
                      // setAudioBarFavorite={setAudioBarFavorite}
                      toast={toast}
                      setToast={setToast}
                      // playlists={playlistData?.getOrgPlaylists}
                      // playlistRefetch={playlistRefetch}
                      // favorites
                      passable
                      inventoryPage
                      orderPage
                      preppedArray={preppedArray}
                      setPreppedArray={setPreppedArray}
                      dimPreppedFiles={dimPreppedFiles}
                      invoiceId={id}
                    /> 
                  )) :
                  <Typography variant="h5" sx={{ mb: 0 }}>
                    No results
                  </Typography>
                }
              </Box>
            }
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: 7,
              mt: 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              height: 'fit-content',
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            <FilterPane
              filter={filter}
              setFilter={setFilter}
              filterList={filterList}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              showSearchTerm={showSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              showFilter
              setFilteredDisplayFiles={setFilteredDisplayFiles}
              setUpdateTrigger={setUpdateTrigger}

            />
          </Grid>

        </Grid>
      </Container>
      <Footer />
      <OrderPaymentsDrawer
        openPaymentsDrawer={openPaymentsDrawer}
        setOpenPaymentsDrawer={setOpenPaymentsDrawer}
        invoiceId={id}
        toast={toast}
        setToast={setToast}
        getInventory={() => { getInventory() }}
        receipts={receipts}
      />
      <CustomToast toast={toast} setToast={setToast} />

    </>
  );
}
