import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Button, Container, Typography, Stack, Divider } from '@mui/material';


// graphql
import { useQuery } from "@apollo/client";
import isAA from '../components-custom/isAA';
import { GET_DOCUMENT_BY_ID } from '../graphql/queries'

// _mock_

import { useSelector } from '../redux/store';


// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import CartWidget from '../sections/@dashboard/e-commerce/CartWidget';
import Footer from '../layouts/main/Footer';
import LegalDialog from '../components-custom/LegalDialog';
import MultisUploadDrawer from '../components-custom/MultisUploadDrawer';

// ----------------------------------------------------------------------

export default function UploadSuccess() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const querySongId = queryParams.get('songId');

  const multisUploadType = process.env.REACT_APP_MULTIS_UPLOAD_TYPE 

  const { checkout } = useSelector((state) => state.product);

  // console.log('User: ', user)

  const [pageState, setPageState] = useState('upload');


  const [legalDialogOpen, setLegalDialogOpen] = useState(false)
  const [openMultisUploadDrawer, setOpenMultisUploadDrawer] = useState(false)
  const [multisInfo, setMultisInfo] = useState({
    title: '',
    _id: '',
    multitrackUrl: '',
  })


  const { loading: docLoading, error: docError, data: docData = { getDocumentById: {} }, refetch: docRefetch } = useQuery(GET_DOCUMENT_BY_ID, {
    variables: { documentId: querySongId },
    fetchPolicy: 'network-only'
  });


  useEffect(() => {
    // console.log('PageState: ', pageState)
  }, [pageState])

  function closeDialog() {
    setLegalDialogOpen(false)
  }


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Success! </title>
      </Helmet>
      <Box
        sx={{
          pt: 6,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
                {
                  // name: 'Dashboard',
                  // href: null,
                },
                // { name: 'Dashboard' },
              ]
            }
            activeLast
          // moreLink={['https://www.framer.com/api/motion']}
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          height: '60%'
          // backgroundColor: 'red',
        }}
      >

        <>
          <Typography variant="h4" sx={{ mb: 5 }}>
            Upload successfull!
          </Typography>
          <Stack justifyContent='center' width='450px' gap={3} textAlign='center' borderRadius={2} backgroundColor={theme.palette.divider} padding={3}>
            <Typography variant='subtitle1'>Todo List for &quot;{docData?.getDocumentById?.title}&quot;</Typography>
            <Divider />
            {docData?.getDocumentById?.multitrackUrl === '' ?
              <Stack gap={2}>
                <Stack direction='row' width='max-content'>
                  <Iconify icon='carbon:dot-mark' sx={{ color: theme.palette.error.main, mt: 1, mr: 3, }} />
                  <Button variant='text' color='info'
                    // href={isAA() ? `https://audioarsenal.wetransfer.com/?title=${docData?.getDocumentById?._id}&msg=${encodeURIComponent(docData?.getDocumentById?.title)}` : `https://trackbase.wetransfer.com/?title=${docData?.getDocumentById?._id}&msg=${encodeURIComponent(docData?.getDocumentById?.title)}`} 
                    // target='_blank' 
                    sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start' }}
                    onClick={() => {
                      console.log('MULTIS INFO: ', docData?.getDocumentById)
                      setMultisInfo({
                        title: docData?.getDocumentById?.title,
                        _id: docData?.getDocumentById?._id,
                        // multitrackUrl: file?.multitrackUrl,
                      })
                      setOpenMultisUploadDrawer(true)
                    }}
                  >
                    <Typography variant="subtitle1" sx={{ textTransform: 'none', color: isAA() ? 'inherit' : theme.palette.error.main }}>
                      Upload multitracks
                    </Typography>
                  </Button>
                </Stack>
                <Stack direction='row' width='max-content'>
                  <Iconify icon='carbon:dot-mark' sx={{ color: theme.palette.error.main, mt: 1, mr: 3, }} />
                  <Button variant='text' color='info' href='/briefs' target='_parent' sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="subtitle1" sx={{ textTransform: 'none', color: isAA() ? 'inherit' : theme.palette.error.main }}>
                      Pitch song to brief
                    </Typography>
                  </Button>
                </Stack>
              </Stack> :

              <Stack gap={2}>
                <Stack direction='row' width='max-content'>
                  <Iconify icon='ph:check-bold' sx={{ color: isAA() ? theme.palette.success.main : theme.palette.success.light, mr: 3, }} />
                  <Button variant='text' color='info'
                    sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start' }}
                    onClick={() => {
                      // console.log('MULTIS INFO: ', docData?.getDocumentById)
                      if (multisUploadType === 'server') {
                        setMultisInfo({
                          title: docData?.getDocumentById?.title,
                          _id: docData?.getDocumentById?._id,
                          multitrackUrl: docData?.getDocumentById?.multitrackUrl,
                        })
                        setOpenMultisUploadDrawer(true)
                      } else {
                        window.open(isAA() ? `https://audioarsenal.wetransfer.com/?title=${docData?.getDocumentById?._id}&msg=${docData?.getDocumentById?.title}` : `https://trackbase.wetransfer.com/?title=${docData?.getDocumentById?._id}&msg=${docData?.getDocumentById?.title}`, '_blank')
                      }
                    }}
                  >
                    <Typography variant="subtitle1"
                      sx={{
                        textTransform: 'none',
                        color: theme.palette.text.primary,
                      }}
                    >
                      Multitracks uploaded
                    </Typography>
                  </Button>
                </Stack>
                <Stack direction='row' width='max-content'>
                  <Iconify icon='carbon:dot-mark' sx={{ color: theme.palette.error.main, mt: 1, mr: 3, }} />
                  <Button variant='text' color='info' href='/briefs' target='_parent' sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start' }}>
                    <Typography variant="subtitle1" sx={{ textTransform: 'none', color: theme.palette.text.primary }}>
                      Pitch song to brief
                    </Typography>
                  </Button>
                </Stack>

              </Stack>
            }
            {!docData?.getDocumentById?.legalDocs || docData?.getDocumentById?.legalDocs === '' ?
              <Stack gap={2} display='none'>
                <Stack direction='row' width='max-content'>
                  <Iconify icon='carbon:dot-mark' sx={{ color: theme.palette.error.main, mt: 1, mr: 3, }} />
                  <Button variant='text' color='info'
                    sx={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start' }}
                    onClick={() => { setLegalDialogOpen(true) }}
                  >
                    <Typography variant="subtitle1" sx={{ textTransform: 'none', }}>
                      Pre-sign Transfer of Ownership
                    </Typography>
                  </Button>
                </Stack>
                <Typography variant="caption" fullWidth textAlign='left' sx={{ textTransform: 'none', }}>
                  * if you have already signed and the status hasn&apos;t updated yet, sometimes it takes awhile to update, you can proceed to pitch
                </Typography>

              </Stack> :
              <Stack gap={2}>
                <Stack direction='row' width='max-content'>
                  <Iconify icon='ph:check-bold' sx={{ color: theme.palette.success.main, mr: 3, }} />
                  <Typography variant='subtitle1' paddingLeft={1} >Docs signed</Typography>
                </Stack>
              </Stack>
            }
          </Stack>
          <br />
          <Button variant='text' href='/inventory' sx={{ cursor: 'pointer', }}>
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Go to Inventory
            </Typography>
          </Button>
          <br />
          <Button variant='text' href='/upload' sx={{ cursor: 'pointer' }}>
            <Typography variant="subtitle1" sx={{ textTransform: 'none' }}>
              Upload another song
            </Typography>
          </Button>
        </>
      </Container>
      <LegalDialog open={legalDialogOpen} onClose={() => { closeDialog() }} songInfo={docData?.getDocumentById} user={user} />
      <MultisUploadDrawer
        openMultisUploadDrawer={openMultisUploadDrawer}
        setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
        multisInfo={multisInfo}
        setMultisInfo={setMultisInfo}
        refetch={docRefetch}
      />

      <Footer />
      <CartWidget totalItems={checkout.totalItems} />
    </>
  );
}
