import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from '@mui/material';


import MailRoundedIcon from '@mui/icons-material/MailRounded';
import CheckIcon from '@mui/icons-material/Check';
import axios from '../utils/axios';
import { UploadAvatar, Upload, UploadBox } from './upload';
import Image from './image';
import TextfieldDisplay from './TextfieldDisplay';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


CreateOrganizationDialog.propTypes = {
    organizationDialogOpen: PropTypes.bool,
    setOrganizationDialogOpen: PropTypes.func,
    userr: PropTypes.string,
    refetch: PropTypes.func,
    setArtistSelector: PropTypes.func,
    setToast: PropTypes.func,
    uploadPage: PropTypes.bool,
  };



export default function CreateOrganizationDialog({organizationDialogOpen, setOrganizationDialogOpen, userr, refetch, setArtistSelector, setToast, uploadPage}) {

  const theme = useTheme();
  
  const [orgInfo, setOrgInfo] = useState({name: '', address: '', city: '', state: '', zip: '', paymentType: '', lastFour: '', tier: ''})
  const [fileHolder, setFileHolder] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  


  const handleClose = () => {
    setOrganizationDialogOpen(false);
    setOrgInfo({name: '', address: '', city: '', state: '', zip: '', paymentType: '', lastFour: '', tier: ''})
    setFileHolder('');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    submitNewOrg();

  }
  
  
  function successCallback() {
    console.log('Upload Success!')
    setLoading(false)
    setTimeout(()=>{
      handleClose();
      refetch()
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${orgInfo.name} successfully created`, duration: 4000})
      console.log('refetching')
    }, 500)
    refetch()
      // navigate('/uploadsuccess');
  }


  async function submitNewOrg(){

    try {

      const res = await axios.post(`${process.env.REACT_APP_SERVER}/createorg`, {  // eslint-disable-line
        name: orgInfo.name,
        address: orgInfo.address,
        city: orgInfo.city,
        state: orgInfo.state,
        zip: orgInfo.zip,
        paymentType: orgInfo.paymentType,
        lastFour: orgInfo.lastFour,
        tier: orgInfo.tier,

      }).then(response => {
        successCallback();
      });
    } catch (err) {
      console.log(err)
    }              
    }


  return (
    <div>
      <Dialog
        open={organizationDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll='body'
        onClose={()=>{setOrganizationDialogOpen(false)}}
        // aria-labelledby="alert-dialog-slide-title"
        // aria-describedby="alert-dialog-slide-description"
        sx={{
            height: '100%',
            // minWidth: '100px',
            paper: {
              // width: '500px',
            }
        }}
      >
        <Box 
          sx={{
            width: {
              sm: '300px',
              md: '500px',
            }, 
          }}
        >

        <DialogTitle 
          id="add-artist-title"
          sx={{
            pb: 0,
          }}
        >
          Add Organization
        </DialogTitle>

        <DialogContent scroll='body' >
        <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              minWidth: '100px',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Name</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              fullWidth
              autoComplete='off'
              sx={{ }} 
              value={orgInfo.name} 
              onChange={(e)=>{
                setOrgInfo({...orgInfo, name: e.target.value})
              }}
            />
  
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Address</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              autoComplete='off'
              fullWidth
              value={orgInfo.address} 
              onChange={(e)=>{
                setOrgInfo({...orgInfo, address: e.target.value})
              }}
            />
          </Box>
          <Box 
            sx={{
              display: 'flex',
              gap: 1,
            }}
          >
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              pl: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>City</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              autoComplete='off'
              fullWidth
              value={orgInfo.city} 
              onChange={(e)=>{
                setOrgInfo({...orgInfo, city: e.target.value})
              }}
            />
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '60%',
              pt: 3,
              px: 1,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>State</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              autoComplete='off'
              fullWidth
              value={orgInfo.state} 
              onChange={(e)=>{
                setOrgInfo({...orgInfo, state: e.target.value})
              }}
            />
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              pr: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Zip</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              autoComplete='off'
              fullWidth
              value={orgInfo.zip} 
              onChange={(e)=>{
                setOrgInfo({...orgInfo, zip: e.target.value})
              }}
            />
          </Box>
        </Box>
              <Stack width='100%' p={3} justifyContent='space-between'>
                <RadioGroup row defaultValue="producer" 
                  onChange={(e)=> setOrgInfo({...orgInfo, tier: e.target.value})}
                  sx={{
                    display: 'flex', 
                    width: '100%',
                    justifyContent: 'space-evenly',
                    // border: 'red solid 1px',
                }}
                >
                    <FormControlLabel value={1} control={<Radio />} label="Tier 1" sx={{width: 'fit-content'}}/>
                    <FormControlLabel value={2} control={<Radio />} label="Tier 2" sx={{width: 'fit-content'}}/>
                    <FormControlLabel value={3} control={<Radio />} label="Tier 3" sx={{width: 'fit-content'}}/>
                </RadioGroup>
              </Stack>


        </DialogContent>

        <DialogActions>
          <Button 
            size='medium' 
            color='inherit' 
            variant="outlined" 
            onClick={handleClose} 
            sx={{px: "16px", py: "7px"}}
          >
            Cancel
          </Button>

          <Button 
            size='medium' 
            variant="contained" 
            onClick={handleSubmit} 
            sx={{px: "16px", py: "7px"}}
            disabled={loading}
          >
            Add
          </Button>
        </DialogActions>
        </Box>

      </Dialog>
    </div>
  );
}
