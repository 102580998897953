import PropTypes from 'prop-types';
import { useEffect, useState, useCallback } from 'react';

import { paramCase } from 'change-case';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

// @mui

import { alpha, styled, useTheme } from '@mui/material/styles';
import { Button, Box, Card, Typography, CardContent, Stack, TextField, Link } from '@mui/material';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useAuthContext } from '../../../../auth/useAuthContext';
import { UploadAvatar, Upload, UploadBox } from '../../../upload';

//


// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
// import useResponsive from '../../../../hooks/useResponsive';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components-default/image';
import Iconify from '../../../../components-default/iconify';
import TextMaxLine from '../../../../components-default/text-max-line';
import EditArtistDialog from '../../../EditArtistDialog';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  // backgroundColor: alpha('#85795a', .3),
  backgroundColor: alpha(theme.palette.grey[900], 0.44),
  transition: 'all .15s ease-in-out',
  '&:hover': {
    backgroundColor: alpha('#85795a', 0.14),
    // backgroundColor: alpha(theme.palette.grey[700], 0.11),


  }
}));

// ----------------------------------------------------------------------

ArtistProfileCard.propTypes = {
  // index: PropTypes.number,
  artist: PropTypes.object,
  addNewArtist: PropTypes.bool,
  refetch: PropTypes.func,
  noTitle: PropTypes.bool,
  disabled: PropTypes.bool,
  onCardClick: PropTypes.func,
  setToast: PropTypes.func,
  selectedArtistFilter: PropTypes.string,
};

export default function ArtistProfileCard({ artist, addNewArtist, noTitle, disabled, refetch, onCardClick, selectedArtistFilter, setToast }) {

  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const queryArtistName = queryParams.get('name');

  const { _id, name, artwork, bio, onClick, created_at } = artist;

  const [editArtistDialogOpen, setEditArtistDialogOpen] = useState(false)

  return (
    <>
      <Card
        onClick={noTitle ? onCardClick : null}
        sx={{
          cursor: noTitle ? 'pointer' : 'default',
          transition: 'all .3s ease-in-out',
          // height: '100%',
          width: '100%',
          aspectRatio: '1/1',
          borderRadius: '50%',
          maxHeight: '100%',
          maxWidth: '100%',
          opacity: !selectedArtistFilter ? 1 : (selectedArtistFilter === '') ? 1 : (selectedArtistFilter === name ? 1 : .2), // eslint-disable-line
          '&:hover': {
            //
          },
        }}
      >
        <PostContent
          name={artist.name}
          bio={artist.bio}
          noTitle={noTitle}
          selectedArtistFilter={selectedArtistFilter}
          onCardClick={onCardClick}
          setEditArtistDialogOpen={setEditArtistDialogOpen}
        // setEditArtistInfo={setEditArtistInfo}
        />

        <StyledOverlay />

        <Image
          alt="cover"
          // src='/assets/images/test/shape-02.png' 
          src={artist.artwork}
          sx={{
            height: '125%',
            position: 'relative',
            objectFit: 'contain',
            opacity: !disabled ? 1 : 0,
          }}
        />
      </Card>
      <EditArtistDialog
        editArtistDialogOpen={editArtistDialogOpen}
        setEditArtistDialogOpen={setEditArtistDialogOpen}
        userr={userr}
        refetch={refetch}
        currentName={name}
        currentBio={bio}
        currentArtwork={artwork}
        artistId={artist._id}
        setToast={setToast}
      />
    </>
  );

}

// ----------------------------------------------------------------------

PostContent.propTypes = {
  name: PropTypes.string,
  bio: PropTypes.string,
  noTitle: PropTypes.bool,
  selectedArtistFilter: PropTypes.string,
  onCardClick: PropTypes.func,
  setEditArtistDialogOpen: PropTypes.func,
};

export function PostContent({ name, bio, noTitle, selectedArtistFilter, onCardClick, setEditArtistDialogOpen }) {

  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  function handleEditArtist() {
    setEditArtistDialogOpen(true)
  }


  return (
    <Box
      sx={{
        zIndex: 3,
        position: 'absolute',
        color: 'common.white',
        height: '100%',
        width: '100%',
        background: 'none',
        // border: 'red solid 1px',
        '&:hover': {
          background: 'none',
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Box
        sx={{
          display: noTitle ? 'none' : 'grid',
          // gridTemplateColumns: '30px auto 30px',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          '&:hover': {
            background: 'none',
          }
        }}
      >
        <Link
          // noWrap
          color="inherit"
          variant="subtitle2"
          href={`/inventory?page=1&perpage=10&modifier=all&tags=&search=${name}`}
          // href={`/inventory?artist=${name}`}
          // onClick={onCardClick}
          sx={{ cursor: 'pointer' }}
        >
          <Typography
            // flexWrap='wrap'
            variant='h6'
            sx={{
              textShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)',
              textAlign: 'center',
            }}

          >
            {name}
          </Typography>
        </Link>
        <Link
          noWrap
          color="inherit"
          variant="caption"
          onClick={() => { handleEditArtist() }}
          sx={{
            cursor: 'pointer',
            opacity: .7,
            position: 'absolute',
            bottom: 10,
            left: '38%',
            '&:hover': {
              textDecoration: 'none',
              opacity: 1,
            }
          }}
        >
          <Typography
            variant='body'
            sx={{
              transition: 'all .2s ease-in-out',
              textAlign: 'center',
              px: 1,
              opacity: isHovered ? 1 : 0,
              background: 'black',
              border: 'white solid 1px',
              borderRadius: 3,
            }}
          >
            Edit
          </Typography>
        </Link>

      </Box>
    </Box>

  );
}
