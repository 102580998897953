import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Stack,
  Divider,
  Checkbox,
  MenuItem,
  Typography,
  Grid,
  Button,
  Chip,
  TextField,
  Link,
} from '@mui/material';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import EditIcon from '@mui/icons-material/Edit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CheckIcon from '@mui/icons-material/Check';

import { alpha, useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import axios from 'axios';

import { ReactComponent as LogoIcon} from '../../../../assets/icons/pikes.svg'
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import { TOGGLE_DOC_FAVORITE, TOGGLE_DOC_PASS, ADD_DOC_TO_CART, PITCH_PASS } from '../../../../graphql/mutations'

// components
import Iconify from '../../../../components-default/iconify';
import MenuPopover from '../../../../components-default/menu-popover';
//

// ----------------------------------------------------------------------

AudioFileCardPlaylist.propTypes = {
  sx: PropTypes.object,
  userId: PropTypes.string,
  user: PropTypes.object,
  file: PropTypes.object,
  data: PropTypes.object,
  refetch: PropTypes.func,
  dispatch: PropTypes.func,
  addToCart: PropTypes.func,
  onDelete: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setControlMessage: PropTypes.func,
  audioBarFavorite: PropTypes.bool,
  setAudioBarFavorite: PropTypes.func,
  favorites: PropTypes.bool,
  passable: PropTypes.bool,
  editable: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  setSelectedId: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
  briefId: PropTypes.string,
  toast: PropTypes.object,
  setToast: PropTypes.func,
  inventoryPage: PropTypes.bool,
};


export default function AudioFileCardPlaylist({ userId, user, file, data, refetch, dispatch, addToCart, onDelete, sx, favorites, passable, editable, briefId, loadedSong, setLoadedSong, isPlaying, setIsPlaying, setControlMessage, audioBarFavorite, setAudioBarFavorite, index, setSelectedIndex, selectedIndex, selected, setSelectedId, toast, setToast, inventoryPage, ...other }) {


  const isDesktop = useResponsive('up', 'sm');


  const [openPassPopover, setOpenPassPopover] = useState(null);
  const [passWarning, setPassWarning] = useState(false);

  const [favorited, setFavorited] = useState(Boolean(file.favorited.includes(user?.organization)));
  const [playButton, setPlayButton] = useState('play');
  const [pitchIndex, setPitchIndex] = useState(null);

  const [openShare, setOpenShare] = useState(false); // eslint-disable-line
  const [passRefetch, setPassRefetch] = useState(false); // eslint-disable-line

  const [openDetails, setOpenDetails] = useState(false); // eslint-disable-line
  const [feedback, setFeedback] = useState(''); 

  const theme = useTheme();



  const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);
  const [toggleDocPass] = useMutation(TOGGLE_DOC_PASS);
  const [pitchPass] = useMutation(PITCH_PASS);
  const [addDocToCart] = useMutation(ADD_DOC_TO_CART);

  const handleFavorite = async () => {
    setAudioBarFavorite(!audioBarFavorite);
    await toggleFavorite({ variables: { docId: file._id, userId: user?.organization} });
    refetch()
  };

  const handlePass = async () => {
    // await toggleDocPass({ variables: { docId: file._id, userId} });
    await pitchPass({ variables: { docId: file._id, briefId, userId} });
    setPassRefetch(true)
    setLoadedSong({album: '', artist: {name: '', artwork: '', bio: ''}, title: '', favorited: [], tags: []});
    setPassWarning(false);
    // setTimeout(()=>{refetch()}, 1500)
    // refetch();
    setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `You passed on ${file.name}`, duration: 4000})
  };

  const handleAddDocToCart = async () => {
    await addDocToCart({ variables: { docId: file._id, userId: user?.organization} });
    refetch()
  };

  const handleOpenShare = () => {
    setOpenShare(true);
  };


  const handleClosePassPopover = () => {
    setOpenPassPopover(null);
  };


  async function sendFeedback(){
    console.log('Feedback')
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/addfeedback`, {  // eslint-disable-line
        docId: file._id,
        briefId: briefId ? briefId : file?.pitches[pitchIndex]?.briefId, // eslint-disable-line
        message: feedback,
        sender: {
          _id: userId, 
          name: user.name, 
          avatarUrl: user?.avatar
        }
      }).then(response => {
        console.log(response.data)
        setTimeout(()=>{
          setFeedback('')
          refetch();
          setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Feedback sent - ${file.title}`, duration: 4000})
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
  }

  }

  useEffect(() => {
    if (passRefetch === true){
      setTimeout(()=>{refetch()}, 1500)
    }
  },[passRefetch]) // eslint-disable-line
  

  useEffect(() => {
    setFavorited(Boolean(file.favorited.includes(user?.organization)));
  },[data]) // eslint-disable-line

  useEffect(() => {
    file.pitches.map((pitch, indexx )=> { // eslint-disable-line
      // console.log('PITCH: ', pitch)
      // console.log('BRIEF ID: ', pitch.briefId)
      if (pitch.briefId === briefId || inventoryPage) {
        // console.log('INDEX IS: ', indexx)
        return setPitchIndex(indexx)
      }
    })
  },[file]) // eslint-disable-line
  
  useEffect(() => {
    setPlayButton(isPlaying && file.audioUrl === loadedSong.audioUrl ? 'pause' : 'play');
  },[isPlaying, loadedSong, favorited]) // eslint-disable-line  

  useEffect(() => {
    if (selectedIndex === index) {
      setLoadedSong(file);
      // setIsPlaying(true);
      setSelectedId(file._id)
    }
  },[selectedIndex, isPlaying]) // eslint-disable-line

  // console.log("FILE: ", file)
  
  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        // borderRadius: 2,
        position: 'relative',
        transition: 'opacity 0.5s ease-in-out, all 0.2s ease-in-out',
        opacity: passRefetch ? .1 : 1,
        // ml: selectedIndex === index ? 1 : 0,
        // mr: selectedIndex === index ? -1 : 0,
        minHeight: '68px',
        backgroundColor: selectedIndex === index ? `${theme.palette.background.paper}` : `${theme.palette.background.default}`,
        // boxShadow: selectedIndex === index ? `-8px 8px 2px rgba(0, 0, 0, 0.1)` : theme.customShadows.z8,
        border: selectedIndex === index ? `inherit solid 1px` : (file?.pitches[pitchIndex]?.status === 'pass' ? `${theme.palette.error.dark} solid 1px`: `solid 1px ${theme.palette.info.lighter}10`), // eslint-disable-line
        borderLeft: `${theme.palette.divider} solid 1px`,
        borderRight: 'none',
        '&:hover': {
          // bgcolor: passWarning ? `${theme.palette.text.primary}` : `inherit` ,
          // boxShadow: selectedIndex === index ? '-8px 8px 2px rgba(0, 0, 0, 0.1)' : theme.customShadows.z8,
        },
        ...(isDesktop && {
          // p: 1.5,
          // borderRadius: 1.5,
        }),
        ...sx,
      }}
    >
      <Stack
        spacing={isDesktop ? 1.5 : 2}
        direction={isDesktop ? 'row' : 'column'}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        sx={{
          p: .5,
          borderRadius: 2,
          position: 'relative',
          marginBottom: '0px !important',
          // border: 'red solid 1px',
          width: '100%',
          ...(isDesktop && {
            marginBottom: '0px!important',
            pr: 2,
            pb: 0,
            // borderRadius: 1.5,
          }),
          ...sx,
        }}
        {...other}
      >
        <Grid container>

          <Grid item xs={7} sm={8} md={7} lg={7} 
            order={{
              xs: 1,
              sm: 1,
            }}
            sx={{
              // PLAY BUTTON / ARTIST INFO
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              pr: 3,
              pl: 2,
              // border: 'green solid 1px',
            }}
          >
          <Typography variant='body' color='info.lighter'>
            {index + 1}
          </Typography>
            <Button 
              variant="text" 
              color={file?.pitches[pitchIndex]?.status !== 'pass' ? "primary" : 'info' }
              onClick={() => {
                setSelectedIndex(index)
                setSelectedId(file._id)
                if (loadedSong !== file) {
                  setLoadedSong(file);
                  setIsPlaying(true);
                } else {
                  setControlMessage('play-pause')
                  setIsPlaying(true)
                }
              
              }}
              sx={{
                flexGrow: 1,
                alignItems: 'flex-start',
                justifyContent:'center',
                borderRadius: '25%',
                p: 0,
                m: 0,
                '&:hover': {
                  backgroundColor: 'none !important',
                }
              }}
            >
            {playButton === 'pause' ? 
              <PauseCircleIcon sx={{
                width: '32px',
                height: '32px',
                m: 0,
                opacity: .8,
              }}/> :
              <PlayCircleFilledRoundedIcon sx={{
                width: '32px',
                height: '32px',
                opacity: .8,
                m: 0,
              }}/> 
              }
            </Button>

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              width: '100%',
            }}>
            <Box
            // song/artist name box
              sx={{
                width: '100%',
                maxWidth: {
                  xs: '95%',
                  sm: '265px',
                  md: '265px',
                },
                pr:3,
                display: 'flex',
                flexDirection: 'column',
                opacity: passWarning ? 0.2 : 1,
                marginBottom: '0px !important',
                justifyContent: 'center',
              }}
            >
              <Typography noWrap variant="subtitle2" noWrap 
                sx={{
                  textOverflow: 'ellipsis',
                  cursor: 'default',
                }}
                >
                {file.title}
              </Typography>
              <Typography variant="caption" noWrap sx={{cursor: 'default'}}
              >
                {file.artist.name}
              </Typography>
              {file.multitrackUrl === 'producer' &&
                <Typography variant="caption" noWrap sx={{cursor: 'default'}}
                  onClick={async ()=>{
                    try {
                      const response = await axios.get(file.multitrackUrl, { responseType: 'stream' });
                      
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Download Multis
                </Typography>
              }
            </Box>
              </Box>
          </Grid>

          <Grid item xs={2} sm={5} md={5} lg={5} 
            order={{
              xs: 3,
              sm: 2,
            }}
            sx={{
              // ADD TO CART BUTTON SECTION
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: {
                sm: 'flex-end',
                md: 'flex-end',
                lg: 'flex-end',
              },
              // pr: 3,
              // border: 'green solid 1px',
            }}
          >
            {file?.pitches[pitchIndex]?.status !== 'pass' ?
            <Button 
              variant='text'
              // fullWidth
              color='inherit' 
              onClick={handleAddDocToCart} 
              sx={{
                // mr: 4,
                p: 1,
                width: {
                  xs: 'fit-content',
                  sm: 'fit-content',
              },
                display: !passWarning ? 'inline-flex' : 'none',
                backgroundColor: 'none', 
                '&:hover': {
                    // color: theme.palette.common.white,
                  },
              }}
            >
              <Iconify 
                icon={ file.inCart?._id === user?.organization ? 'ci:check' : 'bxs:cart'} 
                width={ file.inCart?._id === user?.organization ? '28px' : '22px'}

                sx={{
                  color: file.inCart?._id === user?.organization ? theme.palette.primary.main : theme.palette.info.main,
                }}
              />
            </Button> :            
            <Button 
              variant="contained"
              // fullWidth
              color="error"
              onClick={()=>{
                console.log('restore')
              }} 
              sx={{
                // mr: 4,
                p: 1,
                width: {
                  xs: '100%',
                  sm: '170px',
              },
                display: 'inline-flex',
                '&:hover': {
                    backgroundColor: theme.palette.text.primary, 
                    color: theme.palette.common.white,
                  },
              }}
            >
              <Typography variant="subtitle2" noWrap>
                Restore to Pitches
              </Typography>
            </Button> 
            }
            
          </Grid>

 
          <Grid item 
            xs={2} sm={12} md={2} lg={2}
            order={{
              xs: 2,
              sm: 3,
            }}
            sx={{
              display: file?.pitches[pitchIndex]?.status !== 'pass' ? 'inherit' : 'none',
            }}
          >
            <Box
              sx={{
                // LIKE PASS EDIT SECTION
                display: 'flex',
                justifyContent: {
                  xs: 'flex-end',
                  sm: 'flex-end',
                  md: 'flex-end',
                },
                alignItems: 'center',
                height: '100%',
                width: '100%',
                mt: {
                  // xs: 2,
                  sm: 'inherit',
                },
                pr: 1,
                // ml: 2,
                // border: 'pink solid 1px',
              }}
            >
              <Box sx={{display: 'flex'}}>
                {(passable && file.inCart?._id !== user?.organization) ?
                  <Checkbox
                    color="error"
                    icon={<Iconify icon={file?.pitches[pitchIndex]?.status !== 'pass' ? "eva:close-outline" : "eva:slash-outline"} />}
                    checkedIcon={<Iconify icon="eva:slash-outline" />}
                    checked={file.pass.includes(userId)}
                    onChange={()=> setPassWarning(true)}
                    sx={{ 
                      p: 0,
                      ml: 3,
                      // height: '60px',
                      display: !passWarning ? 'none' : 'none',
                      // opacity: .5,
                    }}
                  /> :
                  <Checkbox
                    color="primary"
                    icon={<LogoIcon/>}
                    disabled
                    sx={{ 
                      p: 0.75,
                      ml: 3,
                      display: !passWarning ? 'inline-flex' : 'none',
                      color: theme.palette.primary.main,
                    }}
                  />
                }
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Box>
    </>
  );
}
