import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
// @mui
import { Container } from '@mui/material';

import { useQuery, useMutation } from "@apollo/client";

import { GET_INVOICE_BY_ID } from '../graphql/queries'


// routes
import { PATH_DASHBOARD } from '../routes/paths';
// _mock_
import { _invoices } from '../_mock/arrays';
import axios from '../utils/axios';

// components
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import InvoiceDetailsAAWM from '../components-custom/sections/@dashboard/invoice/details-WM';

// ----------------------------------------------------------------------

export default function InvoiceDetailsPageWM(openPaymentsDrawer, setOpenPaymentsDrawer) {

  const { themeStretch } = useSettingsContext();
  const { id } = useParams();
  const hashValue = window.location.hash.substring(1);


  // console.log('PARAMS ID: ', id)

  // const currentInvoice = _invoices.find((invoice) => invoice.id === id);

  const [currentInvoice, setCurrentInvoice] = useState()

  const { loading, error, data, refetch } = useQuery(GET_INVOICE_BY_ID, {

    variables: { id: id }, // eslint-disable-line

    fetchPolicy: 'network-only',

  });

  async function checkPayment(){

    if (hashValue === 'paymentsuccessful'){
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/checkpayment`, {  // eslint-disable-line
        invoiceId: id
      })
    }

  }

    
  useEffect(()=>{
      checkPayment()

  },[]) // eslint-disable-line


  useEffect(()=>{
    if (data?.getInvoiceById !== undefined) {
      // console.log(data)
      setCurrentInvoice(data.getInvoiceById)
    }
  }, [data])
  

  useEffect(()=>{
    if (currentInvoice !== null) {
      // console.log(currentInvoice)
    }
  }, [currentInvoice])
  


  return (
    <>
      <Helmet>
        <title> WavMaker Invoices | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Invoice Details"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            {
              name: 'Invoices',
              href: '/invoice',
            },
            { name: `${currentInvoice?._id}` },
          ]}
        />

        <InvoiceDetailsAAWM invoice={currentInvoice} />
      </Container>
    </>
  );
}
