import { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Grid, Box, Button, Container, Divider, Typography, TextField, ToggleButtonGroup, ToggleButton, CircularProgress, Chip, Stack, RadioGroup, FormControlLabel, Radio, Switch } from '@mui/material';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';


// graphql
import axios from 'axios';
import { useMutation, useQuery } from "@apollo/client";
import queryString from 'query-string';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import UploadTagsSection from '../components-custom/UploadTagsSection';
import { GET_DOCUMENT_BY_ID, GET_USER_BY_ID } from '../graphql/queries'
import { DELETE_DOCUMENT } from '../graphql/mutations'

// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import TextfieldDisplay from '../components-custom/TextfieldDisplay';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import { UploadBox } from '../components-default/upload';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import isAA from '../components-custom/isAA';
import MultisUploadDrawer from '../components-custom/MultisUploadDrawer';
import { AudioContext } from '../components-custom/audio/audio-context';


// ----------------------------------------------------------------------

export default function EditDoc() {
  const theme = useTheme();
  const navigate = useNavigate();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id


  // console.log('User: ', user)

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [sendLoading, setSendLoading] = useState(false);
  const [songLoading, setSongLoading] = useState(false);
  const [songInfo, setSongInfo] = useState(null);
  // const [displayFiles, setDisplayFiles] = useState([]);
  // const [ loadedSong, setLoadedSong] = useState('');
  const [showLyrics, setShowLyrics] = useState(false);

  const [toast, setToast] = useState({ open: false, message: '', icon: '', horizontal: 'left', vertical: 'bottom' });

  const [deleteWarning, setDeleteWarning] = useState(false)

  const [openMultisUploadDrawer, setOpenMultisUploadDrawer] = useState(false)

  const multisUploadType = process.env.REACT_APP_MULTIS_UPLOAD_TYPE 

  // document id from the url
  const { documentId } = useParams()
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const revisePitch = queryParams.type || null;

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  const [deleteDocumentById] = useMutation(DELETE_DOCUMENT);



  // const { loading: songLoading, error: docError, data: docData = { getDocumentById: [] }, refetch: docRefetch } = useQuery(GET_DOCUMENT_BY_ID, {
  //   variables: { documentId },
  //   fetchPolicy: 'network-only'
  // });


  // const { loading: userLoading, error: userError, data: userData, refetch: userRefetch } = useQuery(GET_USER_BY_ID, {
  //   variables: { id: userr },
  //   fetchPolicy: 'network-only'
  // });


  const [artistDialogOpen, setArtistDialogOpen] = useState(false)

  const [fileHolder, setFileHolder] = useState(null);
  const [fileState, setFileState] = useState('original');
  const [uploadFileType, setUploadFileType] = useState(null);
  const [title, setTitle] = useState(songInfo?.title || '');
  const [artist, setArtist] = useState({ _id: '', name: '', artwork: '', bio: '' });
  const [artistChoices, setArtistChoices] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [album, setAlbum] = useState(songInfo?.album || '');
  const [bpm, setBpm] = useState('');
  const [key, setKey] = useState('');
  const [scale, setScale] = useState('');
  const [lyrics, setLyrics] = useState(null);
  const [pitchStatus, setPitchStatus] = useState('');
  const [tags, setTags] = useState(songInfo?.tags || { genre: [], mood: [], instrument: [], theme: [] });
  const [duration, setDuration] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null); // eslint-disable-line
  const [errorState, setErrorState] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [tagsLength, setTagsLength] = useState(0)
  const [validated, setValidated] = useState(false)


  const scaleList = ['A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'G#']

  const [artistSelector, setArtistSelector] = useState(''); // eslint-disable-line

  // console.log('DocumentId: ', documentId)
  // console.log('Docs: ', data)

  function ValidateEntry() {

    if (title === '') {
      setErrorState('title')
      setErrorMessage('Title required')
      return false
    }
    if (artist?._id === '' || artist?._id === undefined) {
      setErrorState('artist')
      setErrorMessage('Select artist from dropdown menu')
      return false
    }
    if (bpm === '') {
      setErrorState('bpm')
      setErrorMessage('Please enter bpm')
      return false
    }
    if (key === '') {
      setErrorState('key')
      setErrorMessage('Please enter key')
      return false
    }
    if (scale === '') {
      setErrorState('scale')
      setErrorMessage('Please enter scale')
      return false
    }
    if (fileHolder === null) {
      setErrorState('file')
      setErrorMessage('Add an audio file')
      return false
    }
    if (tagsLength < 3) {
      setErrorState('tags')
      setErrorMessage('Please choose at least 3 tags')
      return false
    }
    return true
  }

  async function DeleteSong() {
    const deleteSong = await deleteDocumentById({ variables: { id: documentId } }); // eslint-disable-line
    navigate(`/inventory?deleted=${title}`)
    console.log(deleteSong)
  }

  async function getSong(docId){
    setSongLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getsingledoc`, {  // eslint-disable-line
        docId,
        getArtists: true,
      }).then(response => { // eslint-disable-line
        const {doc, artists} = response.data
        setSongInfo(doc)
        setSongLoading(false)
        if (artists){
          setArtistChoices(artists)

          const names = [];
    
          artists?.map(item => names.push(item.name))
          names.push(<Divider sx={{ width: '100%' }} />)
          names.push('Add new artist')
          setArtistList(names)
    
        }
      })
    } catch (err) {
      console.log(err)
    }              
  }



  function successCallback() {
    console.log('Upload Success!')
    setSendLoading(false);
    setFileHolder(null)
    setTimeout(() => {
      // docRefetch()
      // setFileState('original')
      navigate(-1)
    }, 500)
    setToast({
      open: true,
      message: `Song updated`,
      icon: <CheckRoundedIcon sx={{ color: 'white' }} />,
      duration: 4000,
      toastColor: theme.palette.success.dark,
      closeButton: null,
      horizontal: 'right',
    })
  }

  const handleUpload = async () => {
    setSendLoading(true)
    // console.log('File: ', fileHolder);
    // const audioBlob = new Blob(fileHolder, { type: fileHolder[0].type || null});
    let audioBlob = null;
    if (fileHolder !== null) {
      audioBlob = new Blob(fileHolder, { type: fileHolder[0].type || null });

    }
    const formData = new FormData();

    formData.append('userId', userr);
    formData.append('_id', documentId);
    formData.append('name', title);
    formData.append('artistId', artist._id);
    formData.append('artistName', artist.name);
    formData.append('artistBio', artist.bio);
    formData.append('artistArtwork', artist.artwork);
    formData.append('album', album);
    formData.append('bpm', bpm);
    formData.append('key', key);
    formData.append('scale', scale);
    formData.append('lyrics', showLyrics ? lyrics : '');
    formData.append('duration', duration);
    formData.append('genreTags', tags.genre);
    formData.append('moodTags', tags.mood);
    formData.append('instrumentTags', tags.instrument);
    formData.append('themeTags', tags.theme);
    // formData.append('price');
    formData.append('pitchStatus', pitchStatus);
    if (fileHolder !== null) {
      formData.append('mimetype', fileHolder[0].type);
      formData.append('file', audioBlob, fileHolder[0].name);

    }

    console.log('FORMDATA: ', formData)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/editsong`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent => {
          setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) /
            progressEvent.total), 10))
        }
      }).then(response => {
        successCallback();

      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }

  useEffect(() => {
    getSong(documentId)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (songInfo !== null){
      console.log('SONG INFO: ', songInfo)
    }

  }, [songInfo])

  useEffect(() => {
    if (songInfo !== null) {

      // console.log('DOC DATA: ', docData.getDocumentById)
      if (songInfo?.userId !== user._id &&
        user?.role !== 'admin' &&
        user?.organization !== songInfo?.claimed
      ) {
        navigate('/403')
      }

      setTitle(songInfo?.title || '');
      setArtistSelector(songInfo?.artist?.name || '');
      setArtist(
        {
          _id: songInfo?.artist?._id || '',
          name: songInfo?.artist?.name || '',
          bio: songInfo?.artist?.bio || '',
          artwork: songInfo?.artist?.artwork || '',
        }
      )
      // songInfo?.artist?._id || ''
      setBpm(songInfo?.bpm || '');
      setKey(songInfo?.key || '');
      setScale(songInfo?.scale || '');
      setTags(songInfo?.tags || []);
      setLyrics(songInfo?.lyrics || null);
      setShowLyrics(songInfo?.lyrics)
    };

    // if (userData) {
    //   setArtistChoices(userData.getUserById.alias)

    //   const names = [];

    //   userData?.getUserById?.alias?.map(item => names.push(item.name))
    //   names.push(<Divider sx={{ width: '100%' }} />)
    //   names.push('Add new artist')
    //   setArtistList(names)
    // }

    // console.log('PITCHES LENGTH: ', songInfo?.pitches.length === 0)
    function checkPitches() {
      if (songInfo?.pitches !== undefined) {

        const pitchIndex = songInfo?.pitches?.length;
        const lastPitch = songInfo?.pitches[pitchIndex === 0 ? pitchIndex : pitchIndex - 1]?.status || null
        // console.log('LAST PITCH: ', lastPitch)
        return setPitchStatus(lastPitch)


      }
      return null
    }
    checkPitches()

  }
    , [songInfo]) // eslint-disable-line

  useEffect(() => {
    let tempTagsLength = 0;

    if (tags.genre !== undefined) {
      tags.genre.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    }
    if (tags.mood !== undefined) {
      tags.mood.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    }
    if (tags.instrument !== undefined) {
      tags.instrument.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    }
    if (tags.theme !== undefined) {
      tags.theme.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    }

    setTagsLength(tempTagsLength)

  }, [tags]); // eslint-disable-line

  useEffect(() => {

    let correctArtist = {};

    if (artistSelector === 'Add new artist') {
      setArtistDialogOpen(true);
    }

    if (artistSelector !== '') {
      artistChoices.map(item => { // eslint-disable-line
        if (item.name === artistSelector) {
          correctArtist = item
        }
      })
      setArtist(correctArtist)
    }
    if (correctArtist === {}) {
      console.log("Couldn't find it")
    }


  }, [artistSelector]); // eslint-disable-line




  if (songLoading) {
    return <LoadingScreen />;
  }
  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Edit </title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dashboard',
                },
                { name: 'Edit Song' },
              ]
            }
            activeLast
          // moreLink={['https://www.framer.com/api/motion']}
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          // backgroundColor: 'red',
        }}
      >

        <Typography variant="h4" sx={{ mb: 5 }}>
          Edit Song
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 7, width: '100%', }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3, }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '400px', }}>
                <Box
                  component='form'
                  sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}
                  autoComplete='off'
                  noValidate
                >
                  <Typography
                    variant='subtitle1'
                  >
                    Song Title
                  </Typography>
                  <TextField
                    variant='outlined'
                    size='small'
                    sx={{}}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    error={errorState === 'title' && title === ''}
                    helperText={(errorState === 'title' && title === '') && errorMessage}
                  // sx={{border: 'red solid 1px', borderRadius: 1}}
                  />
                </Box>
                <Box
                  component='form'
                  sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                  autoComplete='off'
                >
                  <Typography variant='subtitle1'>Artist</Typography>
                  {artistSelector === '' ?
                    <Autocomplete
                      disablePortal
                      id="artist-select"
                      options={artistList}
                      value={artistSelector}
                      // onAbort={()=>{setArtist({name: '', bio: '', artwork: ''})}}
                      onInputChange={(event, value) => {
                        setArtistSelector(value)
                      }}
                      sx={{
                        width: 400,
                        '& input': {
                          py: '0px !important',
                        }
                        // height: '40px',
                      }}
                      // onSelectCapture={()=> alert('SELECT')}
                      // renderInput={(params) => <TextField {...params} size='medium' />}
                      renderInput={(params) => <TextField {...params} size='medium'
                        sx={{
                          borderRadius: 1,
                          border: (errorState === 'artist' && artist?.name === '') ? 'red solid 1px !important' : 'inherit',
                        }}
                      />}
                    /> :
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 3,
                        borderRadius: 1,
                        // border: errorState === 'artist' ? 'red solid 1px !important' : 'inherit',
                        border: (errorState === 'artist' && artist?.name === '') ? 'red solid 1px !important' : 'inherit',
                      }}
                    >
                      <TextfieldDisplay
                        width='100%'
                        message={artistSelector}
                        deleteButton={isAA()}
                        onDelete={() => { setArtistSelector('') }}
                        locked={!isAA()}
                        sx={{
                          // border: 'red solid 1px !important',
                        }}
                      />
                    </Box>
                  }
                  {(errorState === 'artist' && artist?.name === '') &&
                    <Box sx={{ height: '10px' }}>
                      <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                    </Box>
                  }
                </Box>

              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateRows: fileHolder === null ? (errorState === 'file' ? 'auto 10px' : '100%') : 'auto 40px', // eslint-disable-line
                  gap: 1,
                  pt: 4,

                }}
              >
                <Box // main holder
                  sx={{
                    border: `${theme.palette.primary.main} solid 1px`,
                    borderRadius: 2,
                    height: 'min-content',
                    p: 2,
                    boxShadow: theme.shadows[4],
                  }}
                >
                  <Box // file name and audio
                    sx={{
                      // border: 'red solid 1px',
                      // height: '120px',
                      py: '0px',
                    }}
                  >
                    <Typography variant='caption' sx={{ p: 0 }}>
                      {fileHolder !== null ? fileHolder[0]?.name : songInfo?.originalFileName || 'unknown'}
                    </Typography>

                    <Stack direction='row' gap={1} width='100%' height='100%' justifyContent='center'>

                      <Button variant='text'
                        onClick={() => {
                          if (fileHolder === null) {
                            if (src?.audioUrl !== songInfo?.audioUrl) {
                              setSrc({
                                artist: {
                                  name: artist?.name,
                                  artwork: artist?.smallArtwork || artist?.artwork,
                                  // bio: artist.bio,
                                },

                                title: songInfo?.title,
                                audioUrl: songInfo?.audioUrl,
                              });
                            } else {
                              setControlMessage('play-pause')
                              // setIsPlaying(true)
                            }
                          }
                          if (fileHolder !== null) {
                            const audioBlob = new Blob(fileHolder, { type: fileHolder[0].type });
                            const blobUrl = URL.createObjectURL(audioBlob);
                            if (src?.audioUrl !== blobUrl && isPlaying===false) {
                              setSrc({
                                artist: {
                                  name: artist?.name,
                                  artwork: artist?.smallArtwork || artist?.artwork,
                                  // bio: artist.bio,
                                },
                                title: songInfo?.title,
                                audioUrl: blobUrl
                              });
                            } else {
                              setControlMessage('play-pause')
                              // console.log('THIS!!!')
                              // setIsPlaying(true)
                            }

                          }

                          // console.log('')
                        }

                        }
                        sx={{ minWidth: '60px', mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}>
                        {isPlaying ?
                          <PauseCircleIcon sx={{
                            width: '66px',
                            height: '66px',
                            m: 0,
                            opacity: .8,
                            color: isAA() ? 'inherit' : theme.palette.primary.main,
                          }} /> :
                          <PlayCircleFilledRoundedIcon sx={{
                            width: '66px',
                            height: '66px',
                            opacity: .8,
                            m: 0,
                            color: isAA() ? 'inherit' : theme.palette.common.white,
                          }} />
                        }
                      </Button>
                      <Stack direction='column' spacing={1} width='100%'>
                        <Button
                          variant={revisePitch === 'revisepitch' ? 'contained' : 'outlined'}
                          color={revisePitch === 'revisepitch' ? 'error' : 'primary'}
                          fullWidth
                          onClick={() => { setFileState('new') }}
                          sx={{
                            minWidth: '100px',
                          }}
                        >
                          {revisePitch === 'revisepitch' ? 'Replace Audio to Revise Pitch' : 'Replace audio file'}
                        </Button>
                        <Button
                          variant={revisePitch === 'revisepitch' ? 'contained' : 'outlined'}
                          color={(revisePitch === 'revisepitch' || songInfo?.multitrackUrl === '') ? 'error' : 'primary'}
                          fullWidth
                          sx={{
                            mt: 1,
                            minWidth: '100px',
                          }}
                          onClick={() => {
                            if (multisUploadType === 'server'){
                              setOpenMultisUploadDrawer(true) 
                            } else {
                              window.open(isAA() ? `https://audioarsenal.wetransfer.com/?title=${songInfo?._id}&msg=${songInfo?.title}` : `https://trackbase.wetransfer.com/?title=${songInfo?._id}&msg=${songInfo?.title}`, '_blank')
                            }
                          }}
                        >
                          {songInfo?.multitrackUrl ? 'Replace Multis' : 'Upload Multis'}
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>

                </Box>
                {fileHolder !== null ?
                  <Stack direction='row' spacing={1} width='100%'>
                    <Button
                      variant='outlined'
                      color='error'
                      fullWidth
                      onClick={() => {
                        setFileHolder(null)
                        // setTitle(songInfo?.title || '')
                      }}
                      sx={{ mt: 0, mb: 0, height: '100%' }}
                    >
                      Cancel replacement audio
                    </Button>
                    {revisePitch === 'revisepitch' &&
                      <Button
                        variant='contained'
                        color='success'
                        fullWidth
                        onClick={() => {
                          handleUpload(fileHolder)
                        }}
                        sx={{ mt: 0, mb: 0, height: '100%' }}
                      >
                        {!songLoading ? 'Save Changes' : '...'}
                      </Button>
                    }

                  </Stack> :
                  <Box sx={{ height: '36px' }} />
                }
                {(fileHolder === null && errorState === 'file') &&
                  <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                }
              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 'fit-content' }}>
                <Typography variant='subtitle1'>Bpm</Typography>
                <TextField
                  variant='outlined'
                  type='text'
                  size='small'
                  sx={{ width: 'fit-content' }}
                  value={bpm}
                  onChange={(e) => {
                    setBpm(e.target.value)
                  }}
                  error={errorState === 'bpm' && bpm === ''}
                  helperText={(errorState === 'bpm' && bpm === '') && errorMessage}

                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                <Typography variant='subtitle1'>Key</Typography>
                <ToggleButtonGroup
                  exclusive
                  color='standard'
                  value={key}
                  fullWidth
                  size='small'
                  sx={{
                    height: '40px',
                    overflow: 'hidden',
                    transition: 'all 0.2s ease-in-out',
                    border: errorState === 'key' && key === '' ? 'red solid 1px' : 'inherit',
                    // background: `${theme.palette.grey[700]}10`,
                    // marginTop: '32px',

                  }}
                  onChange={(e) => {
                    const newKey = e.target.value;
                    setKey(newKey)
                  }}
                  error={errorState === 'key' && key === ''}
                  helperText={(errorState === 'key' && key === '') && errorMessage}
                >
                  {scaleList.map((note) => { // eslint-disable-line
                    return (
                      <ToggleButton
                        key={`scale-${note}`}
                        value={note}
                        size='small'
                        sx={{
                          backgroundColor: key === note ? `${theme.palette.primary.main} !important` : 'inherit',
                          color: key === note ? `white !important` : 'inherit',
                        }}>
                        {note}
                      </ToggleButton>
                    )
                  }
                  )}
                </ToggleButtonGroup>
                {(errorState === 'key' && key === '') &&
                  <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                }

              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '200px' }}>
                <Typography variant='subtitle1'>Scale </Typography>
                <ToggleButtonGroup
                  exclusive
                  color='standard'
                  value={scale}
                  sx={{
                    overflow: 'hidden',
                    transition: 'all 0.2s ease-in-out',
                    height: '40px',
                    border: errorState === 'scale' && scale === '' ? 'red solid 1px' : 'inherit',

                    // width: '200px',
                  }}
                  onChange={(e) => {
                    const newScale = e.target.value;
                    setScale(newScale)
                  }}
                >
                  <ToggleButton
                    value='Major'
                    // size='small'
                    sx={{
                      backgroundColor: scale === 'Major' ? `${theme.palette.primary.main} !important` : 'inherit',
                      color: scale === 'Major' ? `white !important` : 'inherit'
                    }}>
                    Major
                  </ToggleButton>
                  <ToggleButton
                    value='Minor'
                    // size='small'
                    sx={{
                      backgroundColor: scale === 'Minor' ? `${theme.palette.primary.main} !important` : 'inherit',
                      color: scale === 'Minor' ? `white !important` : 'inherit'
                    }}>
                    Minor
                  </ToggleButton>
                </ToggleButtonGroup>
                {(errorState === 'scale' && scale === '') &&
                  <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                }

              </Box>
            </Box>
            <Stack direction='row'>
              <ToggleButtonGroup
                exclusive
                color='standard'
                value={showLyrics}
                fullWidth
                sx={{
                  overflow: 'hidden',
                  transition: 'all 0.2s ease-in-out',
                  height: '40px',
                }}
                onChange={(e) => {
                  setShowLyrics(e.target.value === 'Lyrics')
                }}
              >
                <ToggleButton
                  value='Instrumental'
                  // size='small'
                  sx={{
                    backgroundColor: !showLyrics ? `${theme.palette.primary.main} !important` : 'inherit',
                    color: !showLyrics ? `white !important` : 'inherit'
                  }}>
                  Instrumental
                </ToggleButton>
                <ToggleButton
                  value='Lyrics'
                  // size='small'
                  sx={{
                    backgroundColor: showLyrics ? `${theme.palette.primary.main} !important` : 'inherit',
                    color: showLyrics ? `white !important` : 'inherit'
                  }}>
                  Lyrics
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>
            <Box sx={{ display: showLyrics ? 'flex' : 'none', flexDirection: 'column', gap: 1, width: '100%' }}>
              <Typography variant='subtitle1' >
                Lyrics
              </Typography>
              <TextField multiline rows={10}
                value={lyrics}
                onChange={(e) => { setLyrics(e.target.value) }}
              />
            </Box>

            <UploadTagsSection
              tags={tags}
              setTags={setTags}
              errorState={errorState}
              setErrorState={setErrorState}
              errorMessage={errorMessage}
              tagsLength={tagsLength}
            />

          </Grid>
          <Grid item xs={12} sm={12} md={4} >
            {(fileState !== 'original') &&
              <UploadBox
                onDrop={(e) => {
                  if (
                    e[0].type === 'audio/mpeg' ||
                    e[0].type === 'audio/m4a' ||
                    e[0].type === 'audio/aac'
                  ) {
                    setUploadFileType('ok')
                    setFileHolder(e);
                    const audioBlob = new Blob(e, { type: e[0].type });
                    const audio = new Audio(URL.createObjectURL(audioBlob)); // Create an HTMLAudioElement
                    audio.addEventListener('loadedmetadata', () => {
                      const tempDuration = audio.duration; // eslint-disable-line
                      setDuration(tempDuration);
                      console.log('Duration:', tempDuration);
                    });
                    console.log(e);
                  } else {
                    setUploadFileType('error')
                  }
                }}
                accept=".wav, .mp3, .aiff, .aac"
                sx={{
                  mt: 4,
                  mb: 0,
                  py: 1,
                  ml: 0,
                  width: '100%',
                  height: '280px',
                  boxSizing: 'border-box',
                  borderRadius: 1,
                  backgroundColor: uploadFileType !== 'error' ? `${theme.palette.text.primary}80` : theme.palette.error.dark, // eslint-disable-line 
                  border: (errorState === 'file' && !fileHolder) ? 'red solid 2px' : 'inherit',
                }}

                placeholder=
                {
                  fileHolder === null && uploadFileType !== 'error' ? // eslint-disable-line
                    <Box sx={{ color: 'text.disabled', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" color='common.white'>Add/drop mp3</Typography>
                      <Iconify icon="eva:cloud-upload-fill" width={40} sx={{ color: theme.palette.common.white, }} />
                    </Box> :
                    (uploadFileType !== 'error' ? <Box sx={{ color: theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" color='common.white'>{fileHolder[0].name || 'File added'}</Typography>
                      <Iconify icon="eva:checkmark-circle-2-fill" width={40} sx={{ color: theme.palette.common.white, }} />
                    </Box> : <Box sx={{ color: theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                      <Typography variant="subtitle1" color='common.white'>File must be MP3, M4A, or AAC</Typography>
                      <Iconify icon="eva:slash-fill" width={20} sx={{ color: theme.palette.common.white, }} />
                    </Box>
                    )
                }
              />
            }
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ mb: 2, paddingTop: '0px', }}>
            <Box sx={{ display: 'flex', gap: 2, width: '100%', justifyContent: 'space-between', }}>
              <Stack direction='row' spacing={2} >
                <Button
                  variant='outlined'
                  disabled={songLoading || deleteWarning}
                  onClick={() => {
                    navigate(-1)
                  }}
                  sx={{ mt: 0, mb: 3, height: '36px', width: 'fit-content' }}
                >
                  Cancel
                </Button>
                <Button
                  variant='contained'
                  disabled={sendLoading || deleteWarning}
                  onClick={() => {
                    // console.log(title)
                    handleUpload(fileHolder)
                  }}
                  sx={{
                    mt: 0,
                    mb: 3,
                    height: '36px',
                    width: 'fit-content'
                  }}
                >
                  {!songLoading ? 'Save Changes' : '...'}
                </Button>
              </Stack>
              {!deleteWarning ?
                <Button
                  variant='outlined'
                  color='error'
                  // disabled={sendLoading}
                  onClick={() => {
                    // DeleteSong();
                    setDeleteWarning(true)
                  }}
                  sx={{
                    mt: 0,
                    mb: 3,
                    height: '36px',
                    width: 'fit-content'
                  }}
                >
                  Delete Song
                </Button> :
                <Stack direction='row' spacing={2}
                >
                  <Typography variant='subtitle1' color='error' sx={{ height: '36px', display: 'flex', alignItems: 'center', }}>
                    Are you sure?
                  </Typography>
                  <Button
                    variant='outlined'
                    color='error'
                    onClick={() => {
                      setDeleteWarning(false)
                    }}
                    sx={{
                      mt: 0,
                      mb: 3,
                      height: '36px',
                      width: 'fit-content',
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant='contained'
                    color='error'
                    onClick={() => {
                      DeleteSong();
                    }}
                    sx={{
                      mt: 0,
                      mb: 3,
                      height: '36px',
                      width: 'fit-content',
                    }}
                  >
                    Delete It
                  </Button>

                </Stack>
              }

              <Box
                sx={{ display: 'flex', alignItems: 'center', height: '34px' }}
              >

                <Typography variant='caption' sx={{ color: theme.palette.error.main, }}>
                  {errorMessage !== '' ? 'Check form for missing fields' : null}
                </Typography>
              </Box>
            </Box>
            {(user?.role === 'admin' && songInfo?.pitches?.length) > 0 ?
              <>
                <Divider direction='horizontal' sx={{ width: '65%', my: 3 }} />
                <Stack direction='row' width='65%'
                  justifyContent='flex-start' alignItems='center'
                  borderRadius={1} border={`${theme.palette.primary.main} solid 1px`}
                  px={3} spacing={2}
                >
                  <Typography variant='subtitle1'>
                    Pitch Status:
                  </Typography>
                  <RadioGroup row
                    value={pitchStatus}
                    onChange={(e) => { setPitchStatus(e.target.value) }}
                  >
                    <FormControlLabel value="submitted" control={<Radio color='warning' />} label="Submitted" />
                    <FormControlLabel value="revise" control={<Radio color='warning' />} label="Revise" />
                    <FormControlLabel value="resubmitted" control={<Radio color='warning' />} label="Resubmitted" />
                    <FormControlLabel value="pitched" control={<Radio color='warning' />} label="Pitched" />
                    <FormControlLabel value="pass" control={<Radio color='error' />} label="Pass" />
                    <FormControlLabel value="sold" control={<Radio color='success' />} label="Sold" />
                  </RadioGroup>
                </Stack>
              </> :
              <Typography variant='subtitle2' color='info.lighter'
                sx={{
                  display: user?.role === 'admin' ? 'inherit' : 'none',
                }}
              >
                NO PITCHES YET
              </Typography>
            }
          </Grid>
        </Grid>

      </Container>
      <MultisUploadDrawer
        openMultisUploadDrawer={openMultisUploadDrawer}
        setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
        multisInfo={
          {
            title: songInfo?.title || '',
            _id: songInfo?._id || '',
            multitrackUrl: songInfo?.multitrackUrl || '',
          }
        }
        // setMultisInfo={setMultisInfo}
        refetch={()=>{getSong(documentId)}}
        loadedSong={src}
      // persistent
      />

      <Footer />

      <CustomToast toast={toast} setToast={setToast} />

    </>
  );
}
