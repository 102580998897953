import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Container, Typography, Select, MenuItem, Stack, IconButton } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';

// components
import UserTransactionsAdminDialog from '../components-custom/UserTransactionsAdminDialog';
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import UserInfoDrawer from '../components-custom/UserInfoDrawer';
import EditArtistDialogAdmin from '../components-custom/EditArtistDialogAdmin';
import UserInfoCard from '../components-custom/UserInfoCard';


// ----------------------------------------------------------------------

export default function AdminUsersPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;

  // console.log('User: ', user)
  const [openUserDrawer, setOpenUserDrawer] = useState(false)
  const [openArtistDrawer, setOpenArtistDrawer] = useState(false)

  const [userDrawerInfo, setUserDrawerInfo] = useState({})
  const [transactionDialogOpen, setTransactionDialogOpen] = useState(false)
  const [targetUserId, setTargetUserId] = useState('')

  const [artistDetails, setArtistDetails] = useState({})


  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [hideNoBalance, setHideNoBalance] = useState(false);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]); // eslint-disable-line
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showSearchTerm, setShowSearchTerm] = useState('');
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [userStatus, setUserStatus] = useState('all');
  const [notedStatus, setNotedStatus] = useState(false);
  const [sortMethod, setSortMethod] = useState('date-descending')

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [inventoryCount, setInventoryCount] = useState('')
  const [SelectedCount, setSelectedCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || 1)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(30)
  const [pageLinks, setPageLinks] = useState('')
  const [pageLinksAbbrev, setPageLinksAbbrev] = useState('')

  const [loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [selectedId, setSelectedId] = useState(null); // eslint-disable-line

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  function noResultsMessage(){
    if (userStatus === 'all'){
      return 'No songs currently available'
    }
    if (userStatus === 'all'){
      return 'No songs currently available'
    }
    if (userStatus === 'pitched'){
      return 'No songs currently pitched'
    }
    if (userStatus === 'pass'){
      return 'No song pitches currently passed on'
    }
    if (userStatus === 'revise'){
      return 'No song pitches currently requested to revise'
    }
    if (userStatus === 'unpitched'){
      return 'No songs currently unpitched'
    }
    if (userStatus === 'sold'){
      return 'No songs sold yet'
    }
    return null
  }

  async function getInventory(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getusers`, {  // eslint-disable-line
        type: 'users',
        perPage,
        modifier: userStatus,
        sortMethod,
        noted: notedStatus,
        // userId: '64d3c9649d24018f494a997c',
      }).then(response => {
        console.log(response)
        setAxiosResults(response.data)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  async function updateInventory(){
    try {
      window.scrollTo({
        top: 0,
        behavior: 'auto', // Use smooth scrolling behavior
      });
      

      const normalPull = async () => {
        try {
          setAxiosLoading(true)
          const res = await axios.post(`${process.env.REACT_APP_SERVER}/getusers`, {  // eslint-disable-line
            type: 'users',
            perPage,
            modifier: userStatus,
            sortMethod,
            searchTerm,
            // userId: '64d3c9649d24018f494a997c',
          }).then(response => {
            console.log(response)
            setAxiosResults(response.data)
          });
            } catch (error) {
              console.error('Error fetching search results:', error);
            }
          }
          // if (searchTerm === ''){
          //   normalPull()
          // } else {
          //   debouncedSearch()
          // }
          normalPull()
          setUpdateTrigger(false)
      
    } catch (err) {
      console.log(err)
    }              
  }

  async function getInventoryCount(){
    // setLoading(true)
    try {
      // const res = await axios.post(`${process.env.REACT_APP_SERVER}/getusers`, {  // eslint-disable-line
      //   type: 'inventoryCount',
      // }).then(response => setInventoryCount(
      //   <Typography variant="subtitle1" fontWeight={500} sx={{color: theme.palette.info.light }}>
      //   {`(${response.data} Total)`}
      //   </Typography>
      //   )
      // );
    } catch (err) {
      console.log(err)
    }              
  }

  useEffect(() => {
    getInventoryCount()
    getInventory()

  },[]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.users)
      setSelectedCount(axiosResults?.totalUsers)

      const tempFilterList = [];
      setFilterList(tempFilterList)


      const totalPages = axiosResults.totalDocs/perPage
      setPageCount(Math.ceil(totalPages))

      const buttons = [];

      for (let i = 1; i <= Math.ceil(totalPages);) {
        // buttons.push(<button type='button' key={i}>Page {i}</button>);
        buttons.push(
          <Button key={`pageButton${i}`} variant={(page === i) ? 'outlined' : 'text'} size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled={page === i}
            onClick={()=>{
              setPage(i-1)
              navigate(`${window.location.pathname}?page=${i-1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
          <Typography variant='subtitle1' color={page === i ? 'primary' : 'primary'}>
            {i}
          </Typography>
        </Button>

        );
        i += 1
      }

      setPageLinks(buttons)
      const buttonsAbbrev = buttons.splice(0,3)
      if (page !== 4 && page !== 5){
        buttonsAbbrev.push(
          <Typography key='ellipsis' variant='button' color='primary'
            sx={{pt: .5, cursor: 'default'}}
          >
            ...
          </Typography>
        )
      }
      if (page > 3 && page < pageCount-2){
        buttonsAbbrev.push(
          <Button key={`pageButtonPrev${page}`} variant='text' size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
              display: page === 4 ? 'none' : 'inherit'
            }}
            onClick={()=>{
              setPage(page-1)
              navigate(`${window.location.pathname}?page=${page-1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {page-1}
            </Typography>
          </Button>
        )        
        buttonsAbbrev.push(
          <Button key={`pageButtonHere${page}`} variant='outlined' size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
            }}
            disabled
            onClick={()=>{
              setPage(page)
              navigate(`${window.location.pathname}?page=${page}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {page}
            </Typography>
          </Button>
        )
        if (page <= pageCount - 4){
          buttonsAbbrev.push(
            <Button key={`pageButtonNext${page}`} variant='text' size='small' width='fit-content'
              sx={{
                px: '10px',
                minWidth: 0,
              }}
              onClick={()=>{
                setPage(page+1)
                navigate(`${window.location.pathname}?page=${page+1}`)
                // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
              }}
            >
              <Typography variant='subtitle1' color='primary'>
                {page+1}
              </Typography>
            </Button>
          )
        }   
        buttonsAbbrev.push(
          <Button key={`pageButtonNextNext${page}`} variant='text' size='small' width='fit-content'
            sx={{
              px: '10px',
              minWidth: 0,
              display: page === 4 ? 'inherit' : 'none', 
            }}
            onClick={()=>{
              setPage(page+1)
              navigate(`${window.location.pathname}?page=${page+1}`)
              // window.location.href = `${window.location.origin}${window.location.pathname}?page=${i-1}`
            }}
          >
            <Typography variant='subtitle1' color='primary'>
              {page+2}
            </Typography>
          </Button>
        )        
       if (page < pageCount-4){
         buttonsAbbrev.push(
           <Typography key='post-ellipses' variant='button' color='primary'
             sx={{pt: .5, cursor: 'default'}}
           >
             ...
           </Typography>
         )
       }
      }
      buttonsAbbrev.push(buttons[buttons.length -3])
      buttonsAbbrev.push(buttons[buttons.length -2])
      buttonsAbbrev.push(buttons[buttons.length -1])

      setPageLinksAbbrev(buttonsAbbrev)
      setAxiosLoading(false)

    }
  },[axiosResults]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== ''){
      updateInventory()
    }
  },[page, sortMethod, perPage]) // eslint-disable-line

  // useEffect(() => {
  //   if (axiosResults !== '' && pageQuery !== null){
  //     setPage(pageQuery)
  //   }
  // },[pageQuery]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      updateInventory()
    }
  },[userStatus]) // eslint-disable-line




  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };


  useEffect(() => { // eslint-disable-line

    // console.log('Filter: ', filter)

    if (axiosResults === '') {
      return
    }

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('pause')
        setIsPlaying(false)
      } 
      updateInventory()

    } else {      
      updateInventory()
    }

  }, [filter]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }
    
    if (updateTrigger === false) {
      return
    }

    updateInventory()

  }, [updateTrigger]); // eslint-disable-line 


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                // name: 'Dashboard',
                // href: null,
              },
              // { name: 'Dashboard' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
            All Users
            </Typography>
            {inventoryCount}
          </Stack>
          <Stack direction='row' gap={2}>
          <Select  
            // defaultValue={sortMethod}
            value={sortMethod}
            sx={{
              display: 'flex',
              height: '36px',
              width: '200px',
            }}
            onChange={(e)=>{setSortMethod(e.target.value)}}
          >
            <MenuItem value='date-descending'>
              Sort by: Date <Iconify icon='bx:down-arrow' sx={{height: '12px'}}/>
            </MenuItem>
            <MenuItem value='date-ascending'>
              Sort by: Date <Iconify icon='bx:up-arrow' sx={{height: '12px'}}/>
            </MenuItem>
          </Select>
          <Select  
              // defaultValue={sortMethod}
              value={perPage}
              sx={{
                display: 'flex',
                height: '36px',
                width: '140px',
              }}
              onChange={(e)=>{
                setPage(0)
                setPerPage(e.target.value)
                navigate('/users')
              }}
            >
              <MenuItem value={10}>
                10 per page
              </MenuItem>
              <MenuItem value={20}>
                20 per page
              </MenuItem>
              <MenuItem value={30}>
                30 per page
              </MenuItem>
            </Select>
        </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid  item xs={12} sm={12} md={12} lg={12} xl={12} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
            >
            <Stack direction='row' gap={2} display='grid'
              sx={{
                width: '100%',
                gridTemplateColumns: {
                  xs: '1fr 1fr 1fr',
                  sm: '1fr 1fr 1fr',
                  md: '1fr 1fr 1fr 1fr 1fr',
                },
                // mt: 2,
                mb: 2,
              }}
            >
              <Button 
                variant={userStatus === 'all' ? 'contained': 'outlined'}
                color={userStatus === 'all' ? 'info' : 'info'}
                sx={{
                  opacity: userStatus === 'all' ? 1 : .5,
                  width: '100%',
                }}
                onClick={()=>{
                  setUserStatus('all')
                  setPage(1)
                  navigate(`${window.location.pathname}?page=1`)
                }}
              >
                All
              </Button>
              <Button 
                variant={userStatus === 'producer' ? 'contained': 'outlined'} 
                color={userStatus === 'producer' ? 'primary' : 'info'}
                sx={{
                  opacity: userStatus === 'producer' ? 1 : .5,
                  width: '100%',
                  px: 0,
                }}
                onClick={()=>{
                  setUserStatus('producer')
                  setPage(1)
                  navigate(`${window.location.pathname}?page=1`)
                }}
              >
                {`Producers ${(userStatus === 'producer' && !axiosLoading) ? SelectedCount : ''}`}
              </Button>
              <Button 
                variant={userStatus === 'client' ? 'contained': 'outlined'} 
                color={userStatus === 'submitted' ? 'warning' : 'info'}
                sx={{
                  opacity: userStatus === 'client' ? 1 : .5,
                  color: userStatus === 'client' ? theme.palette.common.white : 'inherit',
                  width: '100%',
                  px: 0,
                }}
                onClick={()=>{
                  setUserStatus('client')
                  setPage(1)
                  navigate(`${window.location.pathname}?page=1`)
                }}
              >
                {`Clients ${(userStatus === 'submitted'  && !axiosLoading) ? SelectedCount : ''}`}
              </Button>
              <Button 
                variant={userStatus === 'pass' ? 'contained': 'outlined'} 
                color={userStatus === 'pass' ? 'error' : 'info'}
                sx={{
                  opacity: userStatus === 'pass' ? 1 : .5,
                  width: '100%',
                  backgroundColor: userStatus === 'pass' ? theme.palette.error.dark : 'none',
                  px: 0,
                }}
                onClick={()=>{
                  setUserStatus('nocuts')
                  setPage(1)
                  navigate(`${window.location.pathname}?page=1`)
                }}
              >
                {`No Cuts ${(userStatus === 'nocuts'  && !axiosLoading) ? SelectedCount : ''}`}
              </Button>
              <Button 
                variant={userStatus === 'noted' ? 'contained': 'outlined'} 
                color={userStatus === 'noted' ? 'success' : 'info'}
                sx={{
                  opacity: userStatus === 'noted' ? 1 : .5,
                  width: '100%',
                  backgroundColor: userStatus === 'noted' ? theme.palette.success.dark : 'none',
                  px: 0,
                }}
                onClick={()=>{
                  setUserStatus('noted')
                  setPage(1)
                  navigate(`${window.location.pathname}?page=1`)
                  // setUpdateTrigger(true)

                }}
              >
                {`Noted ${(userStatus === 'noted' && !axiosLoading) ? `(${SelectedCount})` : ''}`}
              </Button>
              <Button 
                variant={hideNoBalance ? 'contained': 'outlined'} 
                color='primary'
                sx={{
                  // opacity: hideNoBalance ? 1 : .5,
                  width: '100%',
                  // backgroundColor: theme.palette.primary.main,
                  px: 0,
                }}
                onClick={()=>{
                  setHideNoBalance(!hideNoBalance)
                }}
              >
                Positive Balance
              </Button>
            </Stack>
            {showSearchTerm &&
              <Box
                sx={{
                  // height: '100px',
                  width: '100%',
                  borderRadius: 1,
                  backgroundColor: theme.palette.divider,
                  border: `${theme.palette.info.main} solid 1px`,
                  p: 3,
                  mb: 2,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Typography variant='subtitle1'>
                  Showing results for &apos;{showSearchTerm}&apos;
                </Typography>
                <IconButton onClick={()=>{
                  setSearchTerm('')
                  setShowSearchTerm('')
                  setUpdateTrigger(true)
                  }}>
                  <CancelIcon />

                </IconButton>
              </Box>
            }
            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%'/> : 
            <Box onKeyDown={handleKeyDown}>
            {displayFiles?.length > 0 ? 
                displayFiles.map((item, index) => (
                  <UserInfoCard 
                    key={item?._id}
                    inputUser={item} 
                    hideItemOption={hideNoBalance}
                    userDrawerInfo={userDrawerInfo}
                    setUserDrawerInfo={setUserDrawerInfo}
                    openUserDrawer={openUserDrawer}
                    setOpenUserDrawer={setOpenUserDrawer}
                    openArtistDrawer={openArtistDrawer}
                    setOpenArtistDrawer={setOpenArtistDrawer}
                    artistDetails={artistDetails}
                    setArtistDetails={setArtistDetails}
                    toast={toast} 
                    setToast={setToast}
                    transactionDialogOpen={transactionDialogOpen}
                    setTransactionDialogOpen={setTransactionDialogOpen}
                    targetUserId={targetUserId}
                    setTargetUserId={setTargetUserId}
                  />
                  ))  :
                <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                 {noResultsMessage()}
                </Typography>
            }
            </Box>
           }
           {pageCount > 1 &&
           <Stack direction='row' height='50px' width='100%' gap={1}
            sx={{
              justifyContent: 'flex-end'
            }}
           >
            {page > 1 &&
            <Button variant='contained' size='small'
            onClick={()=>{
              setPage(page -1)
              navigate(`${window.location.pathname}?page=${page-1}`)
            }}
            >
              Prev
            </Button>
            }
            {pageLinks.length <= 5 ? pageLinks : pageLinksAbbrev}
            <Button variant='contained' size='small'
              onClick={()=>{
                setPage(page +1)
                navigate(`${window.location.pathname}?page=${page+1}`)
              }}
              disabled={page === pageCount}
            >
              Next
            </Button>
           </Stack>
           }
          </Grid>

        </Grid>
      </Container>
      <AudioPlayerBar
        src={loadedSong}
        controlMessage={controlMessage}
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        // audioBarFavorite={audioBarFavorite}
        // setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
      // refetch={refetch}          
      />

      <UserInfoDrawer 
        openUserDrawer={openUserDrawer} 
        setOpenUserDrawer={setOpenUserDrawer}
        userDrawerInfo={userDrawerInfo}
        setUserDrawerInfo={setUserDrawerInfo}
        adminUsersPage
        file={loadedSong}
        setLoadedSong={setLoadedSong}
      />
      <EditArtistDialogAdmin 
        editArtistDialogOpen={openArtistDrawer}
        setEditArtistDialogOpen={setOpenArtistDrawer}
        artistDetails={artistDetails}
        setArtistDetails={setArtistDetails}
      />
      <UserTransactionsAdminDialog 
        transactionDialogOpen={transactionDialogOpen} 
        setTransactionDialogOpen={setTransactionDialogOpen} 
        targetUserId={targetUserId}
        setToast={setToast}
      />
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        setIsPlaying={setIsPlaying}
        userId={userr}
      />
      <CustomToast toast={toast} setToast={setToast}/>

      {/* <CartWidgetCustom totalItems={cartItems} /> */}
    </>
  );
}
