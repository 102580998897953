import { Navigate, useRoutes } from 'react-router-dom';

import BriefsOrgChoicePage from '../pages/BriefsOrgChoicePage';
import LogoutPage from '../pages-minimal/auth/LogoutPage'; 

// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
import { useAuthContext } from '../auth/useAuthContext';

// layouts
import MainLayoutNoFooter from '../layouts/main/MainLayoutNoFooter';
import MainLayoutNoHeaderNoFooter from '../layouts/main/MainLayoutNoHeaderNoFooter';

import SimpleLayout from '../layouts/simple';
import CompactLayout from '../layouts/compact';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  // Auth
  // // Dashboard: General
  // GeneralAppPage,
  // GeneralFilePage,
  // GeneralBankingPage,
  // GeneralBookingPage,
  // GeneralEcommercePage,
  // GeneralAnalyticsPage,
  // // Dashboard: User
  // UserListPage,
  // UserEditPage,
  // UserCardsPage,
  // UserCreatePage,
  // UserProfilePage,
  // UserAccountPage,
  // // Dashboard: Ecommerce
  // EcommerceShopPage,
  // EcommerceCheckoutPage,
  // EcommerceProductListPage,
  // EcommerceProductEditPage,
  // EcommerceProductCreatePage,
  // EcommerceProductDetailsPage,
  // // Dashboard: Invoice
  // // InvoiceListPage,
  // InvoiceDetailsPage,
  // InvoiceCreatePage,
  // InvoiceEditPage,
  // // Dashboard: Blog
  // BlogPostsPage,
  // BlogPostPage,
  // BlogNewPostPage,
  // // Dashboard: FileManager
  // FileManagerPage,
  // // Dashboard: App
  // ChatPage,
  // MailPage,
  // CalendarPage,
  // KanbanPage,
  // //
  // BlankPage,
  // PermissionDeniedPage,
  // //
  // FaqsPage,
  // AboutPage,
  // Contact,
  // PricingPage,
  // PaymentPage,
  // ComingSoonPage,
  // MaintenancePage,
  // //
  // ComponentsOverviewPage,
  // FoundationColorsPage,
  // FoundationTypographyPage,
  // FoundationShadowsPage,
  // FoundationGridPage,
  // FoundationIconsPage,
  // //
  // MUIAccordionPage,
  // MUIAlertPage,
  // MUIAutocompletePage,
  // MUIAvatarPage,
  // MUIBadgePage,
  // MUIBreadcrumbsPage,
  // MUIButtonsPage,
  // MUICheckboxPage,
  // MUIChipPage,
  // MUIDataGridPage,
  // MUIDialogPage,
  // MUIListPage,
  // MUIMenuPage,
  // MUIPaginationPage,
  // MUIPickersPage,
  // MUIPopoverPage,
  // MUIProgressPage,
  // MUIRadioButtonsPage,
  // MUIRatingPage,
  // MUISliderPage,
  // MUIStepperPage,
  // MUISwitchPage,
  // MUITablePage,
  // MUITabsPage,
  // MUITextFieldPage,
  // MUITimelinePage,
  // MUITooltipPage,
  // MUITransferListPage,
  // MUITreesViewPage,
  // //
  // DemoAnimatePage,
  // DemoCarouselsPage,
  // DemoChartsPage,
  // DemoCopyToClipboardPage,
  // DemoEditorPage,
  // DemoFormValidationPage,
  // DemoImagePage,
  // DemoLabelPage,
  // DemoLightboxPage,
  // DemoMapPage,
  // DemoMegaMenuPage,
  // DemoMultiLanguagePage,
  // DemoNavigationBarPage,
  // DemoOrganizationalChartPage,
  // DemoScrollbarPage,
  // DemoSnackbarPage,
  // DemoTextMaxLinePage,
  // DemoUploadPage,
  // DemoMarkdownPage,
  LoginPage,
  RegisterPage,
  VerifyCodePage,
  NewPasswordPage,
  ForgotPasswordPage,
  PasswordResetPage,
  Page500,
  Page403,
  Page404,
  ProducerInventoryPage,
  ProducerContactPage,
  TermsPage,
  PrivacyPage,
  UploadPage,
  OnboardingPage,
  OnboardingPage2,
} from './elements';

import EditDoc from '../pages/EditDoc';
import AdminInventoryPage from '../pages/AdminInventoryPage';
import AdminUsersPage from '../pages/AdminUsersPage';
import AdminOrgsPage from '../pages/AdminOrgsPage';
import AllPitchesPage from '../pages/AllPitchesPage';
// import OnboardingPage from '../pages/OnboardingPage';
// import OnboardingPage2 from '../pages/OnboardingPage2';
import ClientInventoryPageV2 from '../pages/ClientInventoryPageV2';
import ClientInventoryPageShare from '../pages/ClientInventoryPageShare';
import ClientInventoryPagePassed from '../pages/ClientInventoryPagePassed';
import ProducerInventoryPageAvailable from '../pages/ProducerInventoryPageAvailable';
import ProducerInventoryPageSold from '../pages/ProducerInventoryPageSold';
import UploadSongPage from '../pages/UploadSongPage';
import TestPdfPage from '../pages/TestPdfPage';
import UploadMultitracksPage from '../pages/UploadMultitracksPage';
import UploadSuccess from '../pages/UploadSuccess';
import HomePageAA from '../pages-minimal/HomePageAA';
import CustomCart from '../pages/CustomCart';
import InvoiceDetailsPageWM from '../pages/InvoiceDetailsPageWM';
import InvoiceDetailsPageAdmin from '../pages/InvoiceDetailsPageAdmin';
import InvoiceListPage from '../pages/InvoiceListPage'
import InvoiceListPageAdmin from '../pages/InvoiceListPageAdmin';
import BriefsProducerPageV2 from '../pages/BriefsProducerPageV2';
import BriefsActiveV2 from '../pages/BriefsActiveV2';
import BriefsInactiveV2 from '../pages/BriefsInactiveV2';
import BriefsAdmin from '../pages/BriefsAdmin';
import CreateBriefPage from '../pages/CreateBriefPage';
import EditBriefPage from '../pages/EditBriefPage';
import BriefDetailUserPage from '../pages/BriefDetailUserPage';
import BriefDetailClientPageV2 from '../pages/BriefDetailClientPageV2';
import ProducerProfilePage from '../pages/ProducerProfilePage';
import ClientProfilePage from '../pages/ClientProfilePage';
import AdminProfilePage from '../pages/AdminProfilePage';
import AdminUtilityPage from '../pages/AdminUtilityPage';
import ContractTodoListPage from '../pages/ContractTodoListPage';
import ClientAdminPage from '../pages/ClientAdminPage';
import PlaylistsPage from '../pages/PlaylistsPage';
import AdminSubmissionsPage from '../pages/AdminSubmissionsPage';
import AAGeneralContactPage from '../pages/AAGeneralContactPage';
import AAFaqsPage from '../pages/AAFaqsPage';
import OrdersPageClient from '../pages/OrdersPageClient';
import OrdersPageAdmin from '../pages/OrdersPageAdmin';
import OrderDetailPageClientV2 from '../pages/OrderDetailPageClientV2';
import OrderDetailPageAdminV2 from '../pages/OrderDetailPageAdminV2';
import OrderDetailPageAdminV2TB from '../pages/OrderDetailPageAdminV2TB';
import BriefsDemo from '../pages/BriefsDemo';
import BriefDetailDemoPage from '../pages/BriefDetailDemoPage';
import TransactionsPage from '../pages/TransactionsPage';
import TransactionsAdminPage from '../pages/TransactionsAdminPage';
import ClientCategoriesPage from '../pages/ClientCategoriesPage';
import AATerms from '../pages/AATerms';
import BriefsActiveV2ADMIN from '../pages/BriefsActiveV2ADMIN';
import BriefDetailAdminPageV2 from '../pages/BriefDetailAdminPageV2';
import ClientInventoryPageDemoTracks from '../pages/ClientInventoryPageDemoTracks';
import ClientPricesPage from '../pages/ClientPricesPage';
import BriefsInactiveV2ADMIN from '../pages/BriefsInactiveV2ADMIN';
import MultisUploadPage from '../pages/MultisUploadPage';
import isAA from '../components-custom/isAA';


// ----------------------------------------------------------------------

export default function Router() {


  const { user } = useAuthContext();

  return useRoutes([
    // Auth
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              {/* <RegisterPage /> */}
              <Navigate to='/signuplist' replace />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <LoginPage /> },
        // { path: 'register-unprotected', element: <RegisterPage /> },
        { path: 'register-unprotected', element: <Navigate to='/signuplist' replace /> },
        {
          element: <CompactLayout />,
          children: [
            { path: 'forgot-password', element: <ForgotPasswordPage /> },
            { path: 'password-reset', element: <PasswordResetPage /> },
            { path: 'new-password', element: <NewPasswordPage /> },
            { path: 'verify', element: <VerifyCodePage /> },
          ],
        },
      ],
    },

    // Dashboard
    // {
    //   path: 'dashboard',
    //   element: (
    //     <AuthGuard>
    //       <DashboardLayout />
    //     </AuthGuard>
    //   ),
    //   children: [
    //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
    //     { path: 'app', element: <GeneralAppPage /> },
    //     { path: 'ecommerce', element: <GeneralEcommercePage /> },
    //     { path: 'analytics', element: <GeneralAnalyticsPage /> },
    //     { path: 'banking', element: <GeneralBankingPage /> },
    //     { path: 'booking', element: <GeneralBookingPage /> },
    //     { path: 'file', element: <GeneralFilePage /> },
    //     {
    //       path: 'e-commerce',
    //       children: [
    //         { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
    //         { path: 'shop', element: <EcommerceShopPage /> },
    //         { path: 'product/:name', element: <EcommerceProductDetailsPage /> },
    //         { path: 'list', element: <EcommerceProductListPage /> },
    //         { path: 'product/new', element: <EcommerceProductCreatePage /> },
    //         { path: 'product/:name/edit', element: <EcommerceProductEditPage /> },
    //         { path: 'checkout', element: <EcommerceCheckoutPage /> },
    //       ],
    //     },
    //     {
    //       path: 'user',
    //       children: [
    //         { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
    //         { path: 'profile', element: <UserProfilePage /> },
    //         { path: 'cards', element: <UserCardsPage /> },
    //         { path: 'list', element: <UserListPage /> },
    //         { path: 'new', element: <UserCreatePage /> },
    //         { path: ':name/edit', element: <UserEditPage /> },
    //         { path: 'account', element: <UserAccountPage /> },
    //       ],
    //     },
    //     {
    //       path: 'invoice',
    //       children: [
    //         // { element: <Navigate to="/dashboard/invoice/list" replace />, index: true },
    //         { path: 'list', element: <InvoiceListPage /> },
    //         { path: ':id', element: <InvoiceDetailsPage /> },
    //         { path: ':id/edit', element: <InvoiceEditPage /> },
    //         { path: 'new', element: <InvoiceCreatePage /> },
    //       ],
    //     },
    //     {
    //       path: 'blog',
    //       children: [
    //         { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
    //         { path: 'posts', element: <BlogPostsPage /> },
    //         { path: 'post/:title', element: <BlogPostPage /> },
    //         { path: 'new', element: <BlogNewPostPage /> },
    //       ],
    //     },
    //     { path: 'files-manager', element: <FileManagerPage /> },
    //     {
    //       path: 'mail',
    //       children: [
    //       children: [
    //         { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
    //         { path: 'label/:customLabel', element: <MailPage /> },
    //         { path: 'label/:customLabel/:mailId', element: <MailPage /> },
    //         { path: ':systemLabel', element: <MailPage /> },
    //         { path: ':systemLabel/:mailId', element: <MailPage /> },
    //       ],
    //     },
    //     {
    //       path: 'chat',
    //       children: [
    //         { element: <ChatPage />, index: true },
    //         { path: 'new', element: <ChatPage /> },
    //         { path: ':conversationKey', element: <ChatPage /> },
    //       ],
    //     },
    //     { path: 'calendar', element: <CalendarPage /> },
    //     { path: 'kanban', element: <KanbanPage /> },
    //     { path: 'permission-denied', element: <PermissionDeniedPage /> },
    //     { path: 'blank', element: <BlankPage /> },
    //   ],
    // },

    // Main Routes
    {
      element: <MainLayoutNoFooter />,
      children: [
        { element: user ? <Navigate to={PATH_AFTER_LOGIN} replace /> : <HomePageAA/>, index: true },
        { path: 'signuplist', element: <ProducerContactPage /> },
        { path: 'contact', element: <AAGeneralContactPage /> },

        // { path: 'about-us', element: <AboutPage /> },
        // { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <AAFaqsPage /> },
        { path: 'terms', element: <TermsPage />},
        { path: 'privacypolicy', element: <PrivacyPage />},
        { path: 'share', element: <ClientInventoryPageShare />},
        { path: 'demo', 
        children : [
          {path: '', element: <ClientInventoryPageDemoTracks />}, // eslint-disable-line
          // {path: '', element: <BriefsDemo />}, // eslint-disable-line
          {path: ':briefId', element: <BriefDetailDemoPage /> },
          ]
        },

        // Demo Components
        // {
        //   path: 'components',
        //   children: [
        //     { element: <ComponentsOverviewPage />, index: true },
        //     {
        //       path: 'foundation',
        //       children: [
        //         { element: <Navigate to="/components/foundation/colors" replace />, index: true },
        //         { path: 'colors', element: <FoundationColorsPage /> },
        //         { path: 'typography', element: <FoundationTypographyPage /> },
        //         { path: 'shadows', element: <FoundationShadowsPage /> },
        //         { path: 'grid', element: <FoundationGridPage /> },
        //         { path: 'icons', element: <FoundationIconsPage /> },
        //       ],
        //     },
        //     {
        //       path: 'mui',
        //       children: [
        //         { element: <Navigate to="/components/mui/accordion" replace />, index: true },
        //         { path: 'accordion', element: <MUIAccordionPage /> },
        //         { path: 'alert', element: <MUIAlertPage /> },
        //         { path: 'autocomplete', element: <MUIAutocompletePage /> },
        //         { path: 'avatar', element: <MUIAvatarPage /> },
        //         { path: 'badge', element: <MUIBadgePage /> },
        //         { path: 'breadcrumbs', element: <MUIBreadcrumbsPage /> },
        //         { path: 'buttons', element: <MUIButtonsPage /> },
        //         { path: 'checkbox', element: <MUICheckboxPage /> },
        //         { path: 'chip', element: <MUIChipPage /> },
        //         { path: 'data-grid', element: <MUIDataGridPage /> },
        //         { path: 'dialog', element: <MUIDialogPage /> },
        //         { path: 'list', element: <MUIListPage /> },
        //         { path: 'menu', element: <MUIMenuPage /> },
        //         { path: 'pagination', element: <MUIPaginationPage /> },
        //         { path: 'pickers', element: <MUIPickersPage /> },
        //         { path: 'popover', element: <MUIPopoverPage /> },
        //         { path: 'progress', element: <MUIProgressPage /> },
        //         { path: 'radio-button', element: <MUIRadioButtonsPage /> },
        //         { path: 'rating', element: <MUIRatingPage /> },
        //         { path: 'slider', element: <MUISliderPage /> },
        //         { path: 'stepper', element: <MUIStepperPage /> },
        //         { path: 'switch', element: <MUISwitchPage /> },
        //         { path: 'table', element: <MUITablePage /> },
        //         { path: 'tabs', element: <MUITabsPage /> },
        //         { path: 'textfield', element: <MUITextFieldPage /> },
        //         { path: 'timeline', element: <MUITimelinePage /> },
        //         { path: 'tooltip', element: <MUITooltipPage /> },
        //         { path: 'transfer-list', element: <MUITransferListPage /> },
        //         { path: 'tree-view', element: <MUITreesViewPage /> },
        //       ],
        //     },
        //     {
        //       path: 'extra',
        //       children: [
        //         { element: <Navigate to="/components/extra/animate" replace />, index: true },
        //         { path: 'animate', element: <DemoAnimatePage /> },
        //         { path: 'carousel', element: <DemoCarouselsPage /> },
        //         { path: 'chart', element: <DemoChartsPage /> },
        //         { path: 'copy-to-clipboard', element: <DemoCopyToClipboardPage /> },
        //         { path: 'editor', element: <DemoEditorPage /> },
        //         { path: 'form-validation', element: <DemoFormValidationPage /> },
        //         { path: 'image', element: <DemoImagePage /> },
        //         { path: 'label', element: <DemoLabelPage /> },
        //         { path: 'lightbox', element: <DemoLightboxPage /> },
        //         { path: 'map', element: <DemoMapPage /> },
        //         { path: 'mega-menu', element: <DemoMegaMenuPage /> },
        //         { path: 'multi-language', element: <DemoMultiLanguagePage /> },
        //         { path: 'navigation-bar', element: <DemoNavigationBarPage /> },
        //         { path: 'organization-chart', element: <DemoOrganizationalChartPage /> },
        //         { path: 'scroll', element: <DemoScrollbarPage /> },
        //         { path: 'snackbar', element: <DemoSnackbarPage /> },
        //         { path: 'text-max-line', element: <DemoTextMaxLinePage /> },
        //         { path: 'upload', element: <DemoUploadPage /> },
        //         { path: 'markdown', element: <DemoMarkdownPage /> },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
    
    // User Protected Routes
    {
      element: 
      <AuthGuard>
        <MainLayoutNoFooter />
      </AuthGuard>,
      
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'admindashboard', 
            children : [
              {path: '', element: <AdminInventoryPage /> },
            ]
        },
        { path: 'producerdashboard', 
            children : [
              {path: '', element: <ProducerInventoryPageAvailable /> },
            ]
        },
        { path: 'dash',  
            children : [
              {path: '', element: user?.role === 'client' ? <ClientProfilePage/> : (user?.role === 'producer' ? <ProducerProfilePage /> : <AdminProfilePage/>) }, // eslint-disable-line
            ]
        },
        { path: 'dashboard',  
            children : [
              // {path: '', element: <ProducerProfilePage/> },
              {path: '', element: user?.role === 'client' ? <ClientProfilePage/> : (user?.role === 'producer' ? <ProducerProfilePage /> : <AdminProfilePage/>) }, // eslint-disable-line
            ]
        },
        { path: 'inventory',  
            children : [
              {path: '', element: user?.role === 'admin' ? <AdminInventoryPage/> : (user?.role === 'producer' ? <ProducerInventoryPage /> : <ClientInventoryPageV2 />) }, // eslint-disable-line
              {path: 'rejects', element: user?.role === 'admin' ? <AdminInventoryPage/> : (user?.role === 'producer' ? <ProducerInventoryPageAvailable /> : <ClientInventoryPagePassed />) }, // eslint-disable-line
              {path: 'sold', element: <ProducerInventoryPageSold /> }, // eslint-disable-line
            ]
        },
        { path: 'users',  
            children : [
              {path: '', element: user?.role === 'admin' ? <AdminUsersPage/> : <Page404 /> }, // eslint-disable-line
              // {path: 'rejects', element: user?.role === 'admin' ? <AdminInventoryPage/> : (user?.role === 'producer' ? <ProducerInventoryPageAvailable /> : <ClientInventoryPagePassed />) }, // eslint-disable-line
              // {path: 'sold', element: <ProducerInventoryPageSold /> }, // eslint-disable-line
            ]
        },
        { path: 'submissions', element: <AdminSubmissionsPage /> },
        { path: 'organizations', element: user?.role === 'admin' ? <AdminOrgsPage /> : <Page403 />},
        { path: 'pitches', element: <AllPitchesPage /> },
        { path: 'playlists', element: <PlaylistsPage /> },
        { path: 'logout', element: <LogoutPage /> },
        { path: 'cart', element: <CustomCart /> },
        { path: 'upload', element: <UploadPage /> },
        { path: 'testpdf', element: <TestPdfPage /> },
        { path: 'uploadsuccess', element: <UploadSuccess /> },
        { path: 'adminutility', element: <AdminUtilityPage /> },
        { path: 'contractlist', element: <ContractTodoListPage /> },
        { path: 'client-admin', element: <ClientAdminPage /> },
        { path: 'categories', element: <ClientCategoriesPage /> },
        { path: 'prices', element: <ClientPricesPage /> },
        { path: 'transactions',
          children: [
            {path: '', element: <TransactionsPage /> },
            {path: ':userId', element: user?.role === 'admin' ?<TransactionsAdminPage /> : <Page403 /> },
          ]
        },
        { path: 'uploadmultis',
          children: [
            {path: '', element: <Page404 /> },
            {path: ':documentId', element: <MultisUploadPage /> },
          ]
        },
        { path: 'editsong',
          children: [
            {path: '', element: <EditDoc /> },
            {path: ':documentId', element: <EditDoc /> },
          ]
        },
        {
          path: 'invoice',
          children: [
            { path: '', element: user?.role === 'admin'? <InvoiceListPageAdmin /> : <InvoiceListPage /> },
            { path: ':id', element: user?.role === 'admin'? <InvoiceDetailsPageAdmin /> : <InvoiceDetailsPageWM /> },
          ],
        },
        {
          path: 'orders',
          children: [
            { path: '', element: user?.role === 'client' ? <OrdersPageClient /> : (user?.role === 'admin' ? <OrdersPageAdmin /> : <Page403 />)}, // eslint-disable-line
            { path: ':id', element: user?.role === 'client' ? <OrderDetailPageClientV2 /> : (user?.role === 'admin' ? (isAA() ? <OrderDetailPageAdminV2 /> : <OrderDetailPageAdminV2TB />) : <Page403 />)}, // eslint-disable-line
          ],
        },
        {
          path: 'multitracks',
          children: [
            { path: ':docId', element: <UploadMultitracksPage /> },
          ],
        },
        { path: 'briefs', 
        children : [
          {path: '', element: user?.role === 'producer' ? <BriefsProducerPageV2/> : (user?.role === 'admin' ? <BriefsActiveV2ADMIN/> : <BriefsActiveV2 /> )}, // eslint-disable-line
          {path: 'inactive', element: user?.role !== 'producer' ? <BriefsInactiveV2ADMIN /> : <Page403 /> },
          {path: 'admin', element: <BriefsAdmin /> },
          {path: 'create', element: <CreateBriefPage /> },
          {path: 'edit', 
            children: [
              {path: ':briefId', element: user?.role !== 'producer' ? <EditBriefPage/> : <Page403 />},
            ]
          },
          {path: ':briefId', element: <BriefDetailUserPage />},
          {path: 'client', 
            children: [
              {path: ':briefId', element: user?.role !== 'producer' ? <BriefDetailClientPageV2/> : <Page403 />},
            ]
          },
          {path: 'admin', 
            children: [
              // {path: 'inactive', element: user?.role === 'admin' ? <BriefsInactiveV2ADMIN /> : <Page403 />},
              {path: ':briefId', element: user?.role === 'admin' ? <BriefDetailAdminPageV2 /> : <Page403 />},
            ]
          },
          ]
        },
      ],
    },
    {
      element: 
      <AuthGuard>
        <MainLayoutNoHeaderNoFooter />
      </AuthGuard>,
      
      children: [
        { path: 'onboarding', element: <OnboardingPage /> },
        { path: 'theprocess', element: <OnboardingPage2 /> },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        // { path: 'pricing', element: <PricingPage /> },
        // { path: 'payment', element: <PaymentPage /> },
      ],
    },
    {
      element: <CompactLayout />,
      children: [
        // { path: 'coming-soon', element: <ComingSoonPage /> },
        // { path: 'maintenance', element: <MaintenancePage /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
