import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// @mui
import { alpha, styled, useTheme} from '@mui/material/styles';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
// utils
import { bgBlur } from '../../../../../utils/cssStyles';
// auth
import { useAuthContext } from '../../../../../auth/useAuthContext';
// components
import Image from '../../../../../components-default/image';
import { CustomAvatar } from '../../../../../components-default/custom-avatar';
import Iconify from '../../../../../components-default/iconify/Iconify';
import isAA from '../../../../isAA';
import AASubscriptionLink from '../../../../AASubscriptionLink';
import TBSubscriptionLink from '../../../../TBSubscriptionLink';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  '&:before': {
    ...bgBlur({
      color: isAA() ? theme.palette.text.primary : theme.palette.info.dark,
      opacity: isAA() ? .7 : .8,
      blur: isAA()? 16 : 4,
    }),
    top: 0,
    zIndex: 6,
    content: "''",
    width: '100%',
    height: '100%',
    opacity: .8,
    position: 'absolute',
  },
}));

const StyledInfo = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  zIndex: 99,
  position: 'absolute',
  marginTop: theme.spacing(5),
  [theme.breakpoints.up('md')]: {
    right: 'auto',
    display: 'flex',
    alignItems: 'center',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
  },
}));


// ----------------------------------------------------------------------

ProfileCover.propTypes = {
  user: PropTypes.object,
  setAccountDialogOpen: PropTypes.func,
  org: PropTypes.object
};

export default function ProfileCover({ user, setAccountDialogOpen, org }) {

  const theme = useTheme();

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [iconisHovered, setIconIsHovered] = useState(false);

  const handleMouseEnterIcon = () => {
    setIconIsHovered(true);
  };

  const handleMouseLeaveIcon = () => {
    setIconIsHovered(false);
  };

  const [avatar, setAvatar] = useState(user?.avatar)

  useEffect(()=>{
    setAvatar(user?.avatar)
  }, [user])
  
  
  return (
    <StyledRoot>
      <StyledInfo>
        <Box
          onMouseEnter={handleMouseEnterIcon}
          onMouseLeave={handleMouseLeaveIcon}
          sx={{
            // transition: 'all .3s ease-in-out',
          }}
        >
          <Typography
            onClick={()=>{
              setAccountDialogOpen(true)
            }}
            sx={{
              // display: iconisHovered ? 'inherit' : 'none',
              opacity: iconisHovered ? 1 : 0,
              transition: 'all .2s ease-in-out',
              position: 'absolute',
              bottom: '15%',
              left: '11.5%',
              border: `${theme.palette.common.white} solid 1px`,
              color: theme.palette.common.white,
              backgroundColor: theme.palette.grey[900],
              px: 1,
              borderRadius: 2,
              lineHeight: '16px',
              cursor: 'pointer',
              zIndex: 10,
            }}
          >
            Edit
          </Typography>
          <CustomAvatar
            src={avatar}
            alt={user?.name}
            name={user?.name}
            sx={{
              mx: 'auto',
              borderWidth: 2,
              borderStyle: 'solid',
              borderColor: theme.palette.background.default,
              backgroundColor: isAA() ? theme.palette.text.primary : theme.palette.background.paper,
              width: { xs: 80, md: 128 },
              height: { xs: 80, md: 128 },
            }}
            iconisHovered={iconisHovered}
            setIconIsHovered={setIconIsHovered}
          />

        </Box>

        <Box
          sx={{
            ml: { md: 3 },
            mt: { xs: 1, md: 0 },
            color: 'common.white',
            textAlign: { xs: 'center', md: 'left' },
            cursor: 'default',
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Stack direction='column' spacing={0}>
            <Stack direction='row' spacing={1}
              sx={{
                // border: 'red solid 1px',
                paddingLeft: {
                  xs: '36px',
                  sm: '36px',
                  md: 'inherit',
                },
                justifyContent: {
                  xs: 'center',
                  md: 'flex-start'
                }
              }}
            >
              <Typography variant="h4">{user?.name}</Typography>
              <IconButton onClick={()=>{setAccountDialogOpen(true)}} sx={{opacity: isHovered ? 1 : 0, transition: 'all .2s ease-in-out',}}>
                <Iconify icon="eva:edit-fill" sx={{color: 'white'}}/>
              </IconButton>
            </Stack>

            <Typography variant='body' sx={{ opacity: 0.72 }}>
              {user?.role.charAt(0).toUpperCase().concat(user?.role.slice(1))}
            </Typography>
            <Typography variant='body2' sx={{ mt: 2, opacity: 0.72 }}>
              {user?.email}
            </Typography>
            {user?.role === 'client' && org ?
              <Typography variant='body' sx={{ opacity: 1 }}>{org?.name}</Typography> :
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography variant='body2' sx={{ opacity: 0.72, py: .5 }}>{user?.plan} Plan</Typography>
                {(user?.plan === 'Trial' || user?.plan === 'Free') &&
                  <Button variant='contained'color='primary' size='small'
                    href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink(user?._id, user?.email)}
                    sx={{
                      py: 0,
                      height: '24px',
                    }}
                  >
                    Subscribe to Premium
                  </Button> 
                }
              </Stack>  
            }

          </Stack>
        </Box>

      </StyledInfo>
      <Box sx={{
          position: 'absolute',
          top: 12,
          right: 12,
          zIndex: 100,
          display: 'flex',
          width: '100%',
          justifyContent: 'flex-end',
          mb: 1,
        }}
        >
          <Button
            onClick={()=>{setAccountDialogOpen(true)}}
            variant="outlined"
            color={process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 'primary' : 'inherit'}
            size='small'
            startIcon={<Iconify icon="eva:edit-fill" />}
            sx={{color: 'white'}}
          >
            Edit Profile
          </Button>
        </Box>


      <Image
        alt="cover"
        // src={isAA() ? avatar : "/logo/TB_Record_Small.png"}
        src={avatar}
        // backgroundSize='fit-content'
        sx={{
          zIndex: 0,
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          position: 'absolute',
        }}
      />

    </StyledRoot>
  );
}
