import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Button, Tab, Card, Tabs, Container, Box, Grid } from '@mui/material';

import { useMutation, useQuery } from "@apollo/client";

// routes
import { GET_ORG_INFO, GET_USER_BY_ID } from '../graphql/queries'
import { GET_USER_PITCHES_BY_ID_M } from '../graphql/mutations';

import { PATH_DASHBOARD } from '../routes/paths';
// auth
import { useAuthContext } from '../auth/useAuthContext';
// _mock_
import {
  _userAbout,
  _userFeeds,
  _userFriends,
  _userGallery,
  _userFollowers,
} from '../_mock/arrays';
// components
import Iconify from '../components-default/iconify';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
import { useSettingsContext } from '../components-default/settings';
// sections
import {
  ClientProfile,
  ProfileCover,
} from '../components-custom/sections/@dashboard/user/profile';
import ProfileAbout from '../components-custom/sections/@dashboard/user/profile/home/ProfileAbout';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import AccountDialog from '../components-custom/AccountDialog';
import Footer from '../layouts/main/Footer';

// ----------------------------------------------------------------------

export default function ClientProfilePageAA() {
  const { themeStretch } = useSettingsContext();

  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const [searchFriends, setSearchFriends] = useState('');

  const [currentTab, setCurrentTab] = useState('profile');

  const [artistList, setArtistList] = useState([])

  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [controlMessage, setControlMessage] = useState('');

  const [isPlaying, setIsPlaying] = useState(false);

  const [accountDialogOpen, setAccountDialogOpen] = useState(false)

  const { loading, error, data = {getUserById: []}, refetch } = useQuery(GET_USER_BY_ID, {
    variables: { id: userr },
    fetchPolicy: 'network-only'
  });

  const { loading: orgLoading, error: orgError, data: orgData = {getOrgInfo: {}}, refetch: orgRefetch } = useQuery(GET_ORG_INFO, {
    variables: { orgId: user.organization },
    fetchPolicy: 'network-only'
  });




  useEffect(()=>{
    if (data){
      setArtistList(data.getUserById.alias)
    }
  },[data])


  const TABS = [
    {
      value: 'profile',
      label: 'Profile',
      icon: <Iconify icon="ic:round-account-box" />,
      component: <ClientProfile info={_userAbout} posts={_userFeeds} />,
    },
  ];

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Profile</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'} >
        <CustomBreadcrumbs
          heading="Client Dashboard"
          links={['']}
        />
        <Grid container>
          <Grid item 
            xs={12} 
            sm={12} 
            // md={12} 
            // lg={12}
          >
            <Card
              sx={{
                mb: 3,
                height: 280,
                position: 'relative',
              }}
              >
              <ProfileCover user={user} setAccountDialogOpen={setAccountDialogOpen} org={orgData.getOrgInfo}/>
            </Card>
          
          </Grid> 

        </Grid>
        <ClientProfile 
          info={_userAbout} 
          user={user}
          posts={_userFeeds} 
          data={artistList} 
          refetch={refetch}
          loadedSong={loadedSong}
          setLoadedSong={setLoadedSong}
          controlMessage={controlMessage}
          setControlMessage={setControlMessage}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          accountDialogOpen={accountDialogOpen}
          setAccountDialogOpen={setAccountDialogOpen}
        />
      </Container>
      <Footer />
      <AudioPlayerBar
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        // audioBarFavorite={audioBarFavorite}
        // setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        // refetch={refetch}          
    />
    </>
  );
}
