import { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from "react-router-dom"

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Divider, Stack, Select, MenuItem } from '@mui/material';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';
import CheckIcon from '@mui/icons-material/Check';


// graphql
import axios from 'axios';
import { useMutation, useQuery } from "@apollo/client";
import queryString from 'query-string';
import { GET_USER_DOCS_BY_ID, GET_USER_BY_ID, GET_USER_AVAILABLE_DOCS_BY_ID } from '../graphql/queries'
import { GET_USER_DOCS_BY_ID_M, GET_ARTIST_SONGS_BY_USER_ID } from '../graphql/mutations'

// _mock_

import { useDispatch, useSelector } from '../redux/store';
import { addToCart } from '../redux/slices/product';


// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardProducer from '../sections/@dashboard/general/file/AudioFileCardProducer'


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import CartWidget from '../sections/@dashboard/e-commerce/CartWidget';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import CustomToast from '../components-custom/CustomToast';
import Footer from '../layouts/main/Footer';
import { ArtistProfileCard } from '../components-custom/sections/@dashboard/blog';

// ----------------------------------------------------------------------

export default function ProducerInventoryPageAvailable() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const dispatch = useDispatch();

  const navigate = useNavigate();
  
  const queryParams = new URLSearchParams(location.search); // eslint-disable-line

  const queryArtistName = queryParams.get('artist');  
  const queryDeletedItem= queryParams.get('deleted');  


  // console.log('User: ', user)

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [allUserSongs, setAllUserSongs] = useState([])
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [selectedId, setSelectedId] = useState(null);

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [artistState, setArtistState] = useState([{alias: {name: '', bio: '', artwork: '', _id: '123'}}])
  const [artistList, setArtistList] = useState([])
  const [selectedArtistFilter, setSelectedArtistFilter] = useState(null)

  const [toast, setToast] = useState({})

  const { loading, error, data: songsData = {getAvailableDocumentsByOwnerId: []}, refetch } = useQuery(GET_USER_AVAILABLE_DOCS_BY_ID, {
    variables: { userId: userr },
    fetchPolicy: 'network-only'
  });
  

  const { loading: userLoading, error: userError, data: userData = {getUserById: []}, refetch: userRefetch } = useQuery(GET_USER_BY_ID, {
    variables: { id: userr },
    fetchPolicy: 'network-only'
  });
  
  const [getSongsByArtist] = useMutation(GET_ARTIST_SONGS_BY_USER_ID);
  
  const [getAllSongs] = useMutation(GET_USER_DOCS_BY_ID_M);
  
  
  async function getArtistSongs(){
    const artistSongs = await getSongsByArtist({ variables: { userId: userr, artistId: artistId } }); // eslint-disable-line
    // console.log('ARTIST SONGS: ', artistSongs.data)
  }
  
  async function getAllUserSongs(){
    const allSongs = await getAllSongs({ variables: { getDocumentsByOwnerIdId: userr } }); // eslint-disable-line

  }


  useEffect(()=>{
    if (queryDeletedItem) {
      setTimeout(()=>{
        setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${queryDeletedItem} has been deleted`, duration: 4000})
      }, 700)
    }

  }, []) // eslint-disable-line

  function doRefetch() {
    refetch();
    console.log('refetched')
  }

  function scrollToFile() {
    if (selectedId !== null) {
      const pane = document.getElementById(selectedId)
      setTimeout(()=> {pane.scrollIntoView({ behavior: 'smooth', block: 'center' })}, 20)
    }
  }

  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };

  useEffect(()=>{
    if (songsData){
      const sortedFiles = songsData.getAvailableDocumentsByOwnerId.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))

      setAllUserSongs(sortedFiles)
    }
  },[songsData]) 

  useEffect(()=>{
    if (allUserSongs.length > 0){
      SetAllSongsData()
      // console.log('ALL USER SONGS: ', allUserSongs)
    }
  },[allUserSongs]) // eslint-disable-line

  useEffect(()=>{
    if (userData){

      setArtistState(userData?.getUserById?.alias)
    }
  },[userData])
  
  useEffect(() => {
    if (selectedId !== null && isPlaying) {
      scrollToFile()
    }
  }, [controlMessage]); // eslint-disable-line
  
  useEffect(() => {
    if (queryArtistName) {
      onCardClick(queryArtistName)
    }
  }, []); // eslint-disable-line
  

  
  async function SetAllSongsData(){
    
    // const allSongs = await getAllSongs({ variables: { getDocumentsByOwnerIdId: userr } }); // eslint-disable-line
    if (allUserSongs) {
      setDisplayFiles(allUserSongs || []);

      const tempFilterList = [];
      allUserSongs.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
              doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.mood.length > 0) {
              doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.instrument.length > 0) {
              doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
      })
      setFilterList(tempFilterList)
    }
  }  

  function onCardClick(artist){

    if (selectedArtistFilter === artist.name) {
      setSelectedArtistFilter('')
    } else {
      setSelectedArtistFilter(artist.name)
    }
  }

  useEffect(() => {


    // console.log('Filter: ', filter)

    const filteredFiles = [];

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('play-pause')
      }
      setDisplayFiles(allUserSongs)


    } else {
      allUserSongs.map(doc => { // eslint-disable-line
        if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
          return filteredFiles.push(doc)
        }
      })
      setDisplayFiles(filteredFiles)
    }

  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    // console.log('Display Files: ', displayFiles)

  }, [displayFiles]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedArtistFilter !== ''){

      // console.log('Selected Artist: ', selectedArtistFilter)
      
      const filteredFiles = [];

      allUserSongs.map(doc => { // eslint-disable-line

        if (doc.artist.name === selectedArtistFilter) {
          return filteredFiles.push(doc)
        }
      })
      setDisplayFiles(filteredFiles)
    } else {
      setDisplayFiles(allUserSongs)
    }

  }, [selectedArtistFilter]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Helmet>
        {queryArtistName ?
        <title> {process.env.REACT_APP_SITE_NAME} | {queryArtistName} </title> :

        <title> {process.env.REACT_APP_SITE_NAME} | Inventory </title>}
      </Helmet>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
        sx={{pt: 0}}
      >
        <CustomBreadcrumbs
          heading={songsData?.getAvailableDocumentsByOwnerId ? 
            `Inventory (${songsData?.getAvailableDocumentsByOwnerId?.length || 0} Total)` :
            'Inventory'
          }
          action={
            <Stack direction='row' spacing={3}>
              <Select  
                defaultValue='available'
                sx={{
                  display: 'flex',
                  height: '36px',
                  width: {
                    sm: '140px',
                    md: '200px',
                },
                }}
                onChange={(e)=>{
                  if (e.target.value === 'sold'){
                    navigate('/inventory/sold')
                  }
                }}
              >
                <MenuItem value='available'>Available</MenuItem>
                <MenuItem value='sold'>Sold</MenuItem>
              </Select>
            </Stack>
          }
          links={[
            {
              name: 'Dashboard',
              href: '/dash',
            },
            {
              name: 'Inventory',
            },
          ]}
          />
        
        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid  item xs={12} sm={12} md={9} lg={9} xl={9} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
            >
            {loading ? <LoadingScreen/> : 
            <Box onKeyDown={handleKeyDown}>
            {displayFiles.length > 0 ? 
                displayFiles.map((doc, index) => (
                  (!doc.pass.includes(userr) || user.role === 'admin') &&
                  <AudioFileCardProducer
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    data={songsData}
                    dispatch={dispatch}
                    addToCart={addToCart}
                    refetch={()=> doRefetch()}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 2, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    favorites
                    passable
                    editable={doc.userId === userr}
                    inventoryPage

                  />
                ))  :
                <Typography variant="h5" sx={{ mb: 0 }}>
                  No songs currently available
                </Typography>
            }
            </Box>
              }
          </Grid>
          
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} 
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: {
                sm: 1,
                md: 7,
              },
              // mt: 0,
              mt: 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',               
              height: {
                xs: showFilters ? 'fit-content' : '78px',
                sm: showFilters ? 'fit-content' : '78px',
                md: 'fit-content',
              },
              transition: 'all .2s ease-in-out',
              overflowY: 'hidden',
              display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
          {loading ? <LoadingScreen/> : 
            <Stack
              direction='row' 
              justifyContent='space-between'
              // sx={{
              //   display: 'flex', 
              //   alignItems: 'center', 
              //   justifyContent: 'space-between', 
              //   mr: 4, 
              //   mt: 0, 
              //   // gap: 3,
              // }}
            >

              <Typography variant="h5" sx={{ mb: 2 }}
                onClick={()=>{setShowFilters(!showFilters)}}
              >
                Filters
              </Typography>
              <Button 
                variant="text" 
                onClick={() => {
                  setFilter([])
                  setFilteredDisplayFiles([])
                  if (queryArtistName){
                    navigate('/inventory')
                  }
                }}
                  sx={{ 
                    display: {
                      xs: !showFilters ? 'none' : 'inherit',
                      sm: !showFilters ? 'none' : 'inherit',
                      md: 'inherit',
                    }, 
                    m: 0, 
                    p: 0, 
                    pr: 3,
                    height: 'fit-content',
                    textTransform: 'none', 
                    fontSize: '12px', 
                    fontWeight: '200', 
                    color: theme.palette.primary.darker
                  }}
                >
                clear filters
              </Button>
            </Stack>
            }
            <Grid container spacing={1} 
              sx={{
                pt: 2
              }}
            >
              {artistState?.map((artist, index) => <Grid 
                    key={`artist${index}`} 
                    item 
                    xs={2} 
                    sm={1} 
                    md={4} 
                    lg={3} 
                    sx={{
                      mr: {
                        xs: 2,
                      },
                      display: 'flex', 
                      justifyContent: 'center', 
                      // gap:4,
                    }}
                  >
                  <Box 
                    sx={{
                      display: 'flex', 
                      flexDirection: 'column', 
                      // pr: 2,
                      // gap: 2,
                      // display: 'grid',
                      // gridTemplateRows: '38px 12px',
                      alignItems: 'center', 
                      justifyContent: 'center', 
                      width:{ 
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                      }, 
                      height:{ 
                          xs: '100%',
                          sm: '100%',
                          md: '100%',
                      },
                      // border: 'green solid 1px',
                    }}
                  >
                    <ArtistProfileCard 
                      artist={artist} 
                      refetch={refetch}
                      sx={{aspectRatio: '1/1'}}
                      onCardClick= {()=>{onCardClick(artist)}}
                      selectedArtistFilter={selectedArtistFilter}
                      noTitle
                      // disabled = {checkIfArtist(artist?.name)}
                    />
                    <Typography 
                      variant='caption' 
                      sx={{
                        opacity: (selectedArtistFilter === artist.name) ? 1 : (selectedArtistFilter !== '' && selectedArtistFilter !== artist.name ? .4 : 1), // eslint-disable-line
                      }}
                      noWrap
                    >
                      {artist.name}
                    </Typography>
                    </Box>
                </Grid>)}
            </Grid>
            <Divider width='90%' sx={{pt: 2}}/>
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'space-between',
                lg: 'space-between',
                xl: 'space-between',
              },
              m: 0,
              p: 0,
              pt: 2,
              pr: 1,
              pb: 3,
            }}>
              {filterList.map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                    />)}

            </Box>
          </Grid>

        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        audioBarFavorite={audioBarFavorite}
        setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast} />
    </>
  );
}
