import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


import { Button, Box, Chip, Typography, Stack } from '@mui/material';
import { alpha, useTheme, styled } from '@mui/material/styles';
import { useQuery, useMutation } from "@apollo/client";


import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import PauseCircleOutlinedIcon from '@mui/icons-material/PauseCircleOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { fToNow } from '../utils/formatTime';
import { fPillStatus } from '../utils/formatPitchPill';

import { DELETE_PITCH } from '../graphql/mutations';
import { useSettingsContext } from '../components-default/settings';
import Label from '../components-default/label';
import Image from '../components-default/image';
import Iconify from '../components-default/iconify/Iconify';
import isAA from './isAA';


SongPitchedSquare.propTypes = {
  item: PropTypes.object,
  artistName: PropTypes.string,
  artwork: PropTypes.string,
  briefId: PropTypes.string,
  pitch: PropTypes.object,
  includeBriefTitle: PropTypes.bool,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  controlMessage: PropTypes.string,
  setControlMessage: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  refetch: PropTypes.func,
  userId: PropTypes.string,
  setToast: PropTypes.func,
  multisUploadType: PropTypes.string,
  openMultisUploadDrawer: PropTypes.bool,
  setOpenMultisUploadDrawer: PropTypes.func,
  multisInfo: PropTypes.object,
  setMultisInfo: PropTypes.func,
};

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  mb: 3,
  position: 'absolute',
  borderRadius: 1.5,
  backgroundColor: alpha(theme.palette.grey[900], 0.44),
}));

export default function SongPitchedSquare(props) {

  const { 
    item, 
    artistName,
    // briefTitle,
    artwork,
    pitch,
    briefId, 
    includeBriefTitle, 
    loadedSong, 
    setLoadedSong,
    controlMessage, 
    setControlMessage, 
    isPlaying, 
    setIsPlaying, 
    refetch,
    setToast,
    userId,
    multisUploadType,
    openMultisUploadDrawer,
    setOpenMultisUploadDrawer,
    multisInfo,
    setMultisInfo,
  } = props;

  const navigate = useNavigate()

  const [itemStatus, setItemStatus] = useState('')
  const [briefTitle, setBriefTitle] = useState('')
  const [correctIndex, setCorrectIndex] = useState(0)
  const [feedback, setFeedback] = useState('')

  const [pitchStatus, setPitchStatus] = useState('')

  const [deleteWarning, setDeleteWarning] = useState(false)
  const [showFeedback, setShowFeedback] = useState(false)

  const [loading, setLoading] = useState(false)

  const theme = useTheme();

  const [deleteSongPitch] = useMutation(DELETE_PITCH);

  async function handleDeletePitch(){
    setLoading(true)
    await deleteSongPitch({ variables: { docId: item._id, briefId: briefId} }); // eslint-disable-line

    refetch();

    setLoading(false)
    setToast({
      open: true, 
      message: `Successfully removed "${item.title}" pitch from ${briefTitle}`, 
      icon: <CheckRoundedIcon sx={{color: 'white'}}/>, 
      duration: 4000,
      toastColor: theme.palette.success.dark,
      closeButton: false,
      horizontal: 'right',
    })
  }

  function convertDate(rawDate) {
    const timestamp = rawDate;

    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // Adding 1 since getMonth() returns zero-based index
    const day = date.getDate();
    const year = date.getFullYear();

    const formattedDate = `${month}/${day}/${year}`;

    return formattedDate

  }

  useEffect(()=>{
    if (item.pitches !== undefined){
      // console.log('tried it')
      item.pitches.map((x, indexx) => { // eslint-disable-line
        if (x.briefId === briefId){
          // console.log('X: ', x)
          setItemStatus(x?.status)
          setBriefTitle(x?.briefTitle)
          setFeedback(x?.feedback)
          if (x?.feedback !== null) {
            setCorrectIndex(x?.feedback.length === 0 ? 0 : x.feedback.length-1)
          }
          setPitchStatus(fPillStatus(x?.status))
        }
      })


    }
  }, [item]) // eslint-disable-line

  
  useEffect(()=>{

  }, []) // eslint-disable-line

  // console.log('PITCH STATUS COLOR: ', pitchStatus.color)
  const pillBorderColor = pitchStatus?.color?.toString().slice(9)
  // console.log('PITCH STATUS COLOR: ', pillBorderColor)

  return (
    <Box 
      sx={{ 
        position: 'relative',
        height: '100%', 
        width: '100%', 
        borderRadius: 1.5,
        overflow: 'hidden',
        boxShadow: theme.shadows[8]
    }}
    >
      <StyledOverlay />
      {deleteWarning &&
      <Box
        sx={{
          zIndex: 12,
          position: 'absolute',
          top: 0,
          right: 0,
          height: '100%',
          width: '100%',
          backgroundColor: alpha(theme.palette.error.darker, .9),

        }}
      >
        <Box 
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: 3,
            gap: 1,
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: '100%',
            width: '100%',
          }}
        >
          <Typography variant='body2' sx={{color: theme.palette.common.white}}>
            Remove this song from
          </Typography>

          <Typography variant='body' fontWeight='bold' sx={{color: theme.palette.common.white}}>
            {briefTitle}
          </Typography>

          <Typography variant='body2' sx={{color: theme.palette.common.white}}>
            pitches?
          </Typography>
          <Box 
            sx={{
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              gap: 2,
              pt: 3,
            }}
            >
            <Button variant='outlined' color='error'
              sx={{
                color: theme.palette.common.white,
                backgroundColor: 'transparent',
                border: `${theme.palette.common.white} solid 1px`,
                '&:hover': {
                  border: `${theme.palette.common.white} solid 1px`,
                }
              }}
              onClick={()=>setDeleteWarning(false)}
              >
              Cancel
            </Button>
            <Button variant='contained' color='error' disabled={loading}
              sx={{
                color: theme.palette.common.white,
                backgroundColor: theme.palette.error.main,
                border: `${theme.palette.common.white} solid 1px`,
                '&:hover': {
                  border: `${theme.palette.common.white} solid 1px`,
                  backgroundColor: theme.palette.error.dark,
                }
              }}
              onClick={()=>handleDeletePitch()}
            >
              Remove
            </Button>
          </Box>

        </Box>
      </Box>
    }
    {(item?.multitrackUrl === '' && pitchStatus.status !== 'Pass' )&&
    <Button variant='contained' color='error' size='small'
      sx={{
        position: 'absolute',
        top: 46,
        left: 16,
        zIndex: 10,
        height: '24px',
        px: 1,
        fontSize: '12px',
      }}
      onClick={()=>{
        if (multisUploadType === 'server'){
          setMultisInfo({
            title: item?.title,
            _id: item?._id,
            multitrackUrl: item?.multitrackUrl,
          })
          setOpenMultisUploadDrawer(true)
        } else {
          window.open(isAA() ? `https://audioarsenal.wetransfer.com/?title=${item?._id}&msg=${item?.title}` : `https://trackbase.wetransfer.com/?title=${item?._id}&msg=${item?.title}`, '_blank')
        }

      }}
    >
     {pitchStatus.status !== 'Sold' ? 'Upload Multis' : 'Upload Multis ASAP'}
    </Button>
    }
    {feedback?.length === 0 ?
      <Iconify
          icon="eva:close-fill"
          onClick={()=>{setDeleteWarning(true)}}
          sx={{
            position: 'absolute',
            top: 12,
            right: 12,
            zIndex: 10,
            display: (deleteWarning || pitchStatus.status === 'Sold') ? 'none' : 'flex',
            width: 24,
            height: 24,
            borderRadius: '50%',
            transition: 'all .2s ease-in-out',
            cursor: 'pointer',
            color: `${theme.palette.common.white}80`,
            '&:hover': {
              color: theme.palette.common.white,

            }
          }}
        /> :
        <Button variant='text'
          onClick={()=>{
            setShowFeedback(true)
          }}
          color='info'
          size='small'
          sx={{
            position: 'absolute',
            top: 16,
            right: 12,
            zIndex: 10,
            height: '26px',
            display: (showFeedback || pitchStatus.status === 'Pitched') ? 'none' : 'flex',
            alignItems: 'center',
            width: 'fit-content',
            p: 0,
            pl: 1.5,
            pr: 2,
            m: 0,
            // backgroundColor: theme.palette.background.default, 
            border: `${theme.palette.background.default} solid 1px`, 
            borderRadius: .5, 
            '&:hover': {
              backgroundColor: alpha(theme.palette.grey[900],.5), 

            }
          }}
        >
          <Stack direction='row' spacing={.2}>

            <Iconify icon='bx:show-alt'
              sx={{
                color: theme.palette.common.white,
                textShadow: theme.shadows[1],
              }} 
            />
            <Typography variant='body' 
              // color='info'
              sx={{
                color: theme.palette.common.white,
                textShadow: theme.shadows[1],
              }} 
            >
              Feedback
            </Typography>
          </Stack>
        </Button>
        
    }

      {(includeBriefTitle && briefTitle !== '') &&
        <Label
          variant="filled"
          sx={{
            right: 16,
            zIndex: 2,
            top: 16,
            height: '26px',
            position: 'absolute',
            backgroundColor: `${theme.palette.background.paper}`,
            // border: 'white solid 1px',
            color: theme.palette.text.primary,
          }}
        >
          {briefTitle}
        </Label>
      }
      <Label // pitch pill
        variant="filled"
        onClick={()=>{
          if (pitchStatus.status === 'Revise'){
            navigate(`/editsong/${item?._id}?type=revisepitch`)
          }
        }}
        sx={{
          left: 16,
          zIndex: 3,
          top: includeBriefTitle ? 16 : 16,
          position: 'absolute',
          backgroundColor: pitchStatus.status !== 'Pass' ? `${theme.palette.grey[800]}80` : 'black',
          textShadow: theme.shadows[8],
          borderRadius: 2,
          '--border-color': pitchStatus.color,
          border: `var(--border-color) solid 1px`,
          color: theme.palette.common.white,
          cursor: pitchStatus.status === 'Revise' ? 'pointer' : 'default',
        }}
      >
        <Typography variant='subtitle2' sx={{color: pitchStatus.color}}>
          {pitchStatus.status}
        </Typography>
      </Label>
      <Box 
        sx={{
          position: 'absolute',
          bottom: 16,
          left: 16,
          zIndex: 2,
          width: '100%',
        }}
      >
        <Typography 
          variant='subtitle2'
          noWrap
          sx={{
            color: theme.palette.common.white,
            width: '90%',
            // lineHeight: '8px',
            textOverflow: 'ellipsis',
          }}
        >
          {item?.title}
        </Typography>
        <Typography 
          variant='caption'
          sx={{
            color: theme.palette.common.white,
          }}
        >
          {item?.artist.name}
        </Typography>
      </Box>
      <Box 
        sx={{
          position: 'absolute',
          bottom: 18,
          right: 16,
          zIndex: 2,
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
          // border: 'red solid 1px',
        }}
      >
        <Typography 
          variant='caption'
          sx={{
            color: theme.palette.common.white,
          }}
        >
          {fToNow(item?.created_at)}
        </Typography>
      </Box>

      <Box
        sx={{
          position: 'absolute',
          zIndex: 1,
          height: '100%',
          width: '100%',
          display: 'flex',
        }}
      >
      {!showFeedback &&
        <Button 
          variant='text'
          sx={{
            height: '50%',
            width: '50%',
            ml: '25%',
            mt: '23%',
            borderRadius: '50%',

          }}
          onClick={() => {
            if (loadedSong !== item) {
              setLoadedSong(item);
              // setIsPlaying(true);
            } else {
              setControlMessage('play-pause')
              // setIsPlaying(true)
            }
          
          }}
        >
        {isPlaying && item._id === loadedSong._id ?
          <PauseCircleOutlinedIcon 
            sx={{
              height: '100%',
              width: '100%',
              color: theme.palette.common.white,
            }}
          /> :
          <PlayCircleOutlinedIcon 
            sx={{
              height: '100%',
              width: '100%',
              color: theme.palette.common.white,
            }}
          /> 
      }
        </Button>
      }

      </Box>
        <Image 
          alt="cover" 
          src={item?.artist.artwork} 
          ratio="1/1" 
          sx={{ 
            borderRadius: 1.5,
            backgroundColor: 'black',
            opacity: .8,
          }} 
        />
      {(feedback.length > 0 && showFeedback) &&
      <Box 
        onClick={()=>{setShowFeedback(false)}}
        sx={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: alpha(theme.palette.text.primary, .95),
          borderRadius: 2,
          zIndex: 3,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          p: 3,
          cursor: 'pointer',
        }}
      >
            <Stack spacing={2} >
              <Typography variant='caption2' sx={{color: 'white'}}>{feedback[correctIndex]?.message}</Typography>
              <Button variant='contained' color='error'
                sx={{display: itemStatus !== 'pass' ? 'inherit' : 'none'}}
                onClick={()=>{
                  navigate(`/editsong/${item?._id}?type=revisepitch`)
                }}
              >Upload Revision</Button>
            </Stack>
            <Stack direction='row' justifyContent='flex-end'>
              <Typography variant='caption' sx={{color: 'white'}}>{convertDate(feedback[correctIndex]?.date)}</Typography>
            </Stack>
      </Box>
      }
    </Box>
  )

}
