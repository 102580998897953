import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { useTheme } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
import { Box, Grid, Card, Stack, Button, Switch, Typography, TextField, MenuItem, Select, FormControl, InputLabel, Divider } from '@mui/material';
import { CheckRounded } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { DELETE_BRIEF_BY_ID } from '../../../../graphql/mutations';
import axios from '../../../../utils/axios';

// routes
// components
import { useAuthContext } from '../../../../auth/useAuthContext';
import FormProvider, {
  RHFUpload,

} from '../../../../components-default/hook-form';
//
import Image from '../../../../components-default/image';
import Editor from '../../../../components-default/editor';


import BriefPreview from './BriefPreview';
import isAA from '../../../isAA';
import Iconify from '../../../../components-default/iconify';



// ----------------------------------------------------------------------


// ----------------------------------------------------------------------


EditBrief.propTypes = {
  post: PropTypes.object,
  briefId: PropTypes.string,
};

export default function EditBrief(props) {

  const navigate = useNavigate();
  const { user } = useAuthContext();
  const theme = useTheme();

  const { post, briefId } = props;
  const [spotifyPlaylistId, setSpotifyPlaylistId] = useState('')
  const [playlistBox, setPlaylistBox] = useState(null)

  const [deleteWarning, setDeleteWarning] = useState(false);


  const [newCover, setNewCover] = useState('')
  const [categories, setCategories] = useState([])
  const [multiGenres, setMultiGenres] = useState([])
  const [subGenres, setSubGenres] = useState([])
  const [stripePrices, setStripePrices] = useState([])

  const [briefValues, setBriefValues] = useState({
    active: false,
    title: '',
    description: '',
    content: '',
    cover: '',
    category: '',
    tags: [],
    publish: true,
    comments: true,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: [],
    spotifyLink: '',
    appleMusicLink: '',
    priceId: {
      _id: '',
      description: '',
      amount: 0,
    }
  })

  const [rateDisplay, setRateDisplay] = useState('');

  // console.log('Post prop: ', post)

  const [openPreview, setOpenPreview] = useState(false);

  const NewBlogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    tags: Yup.array().min(1, 'Must have at least 1 tag'),
    metaKeywords: Yup.array().min(0, 'Meta keywords is required'),
    cover: Yup.mixed().required('Cover is required'),
    content: Yup.string().required('Content is required'),
    category: Yup.string().required('Category is required'),
  });

  const defaultValues = {
    active: false,
    title: '',
    description: '',
    content: '',
    cover: null,
    category: '',
    multiGenres: [],
    subGenres: [],
    tags: [],
    publish: true,
    comments: true,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: [],
    spotifyLink: '',
    appleMusicLink: '',
    youtubeLink: '',
    priceId: {
      _id: '',
      description: '',
      amount: 0,
    }
  };

  const methods = useForm({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const [deleteBrief] = useMutation(DELETE_BRIEF_BY_ID);


  const submitBrief = async () => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/createbrief`, { values });
  }

  const onSubmit = async (data) => {
    try {

      submitBrief();

    } catch (error) {
      console.error(error);
    }
  };

  function successCallback() {
    console.log('Upload Success!')
    // setLoading(false);
    if (user?.role === 'client') {
      // navigate(`/briefs/client/${briefId}?updatesuccess=true`)
      navigate(-1)
    } else {
      // navigate(`/briefs/${briefId}?updatesuccess=true`)
      navigate(-1)

    }
  }

  const handleUpload = async () => {
    const coverBlob = briefValues.cover !== '' ? new Blob([briefValues.cover, { type: briefValues.cover.type }]) : '';
    const formData = new FormData();

    const author = { _id: user._id, name: user.name, avatarUrl: '' }

    formData.append('id', briefId);
    formData.append('active', briefValues.active);
    formData.append('title', briefValues.title);
    formData.append('description', briefValues.description);
    formData.append('content', briefValues.content);
    formData.append('author', author);
    formData.append('tags', briefValues.tags);
    formData.append('category', briefValues.category);
    formData.append('multiGenres', multiGenres);
    formData.append('subGenres', subGenres);
    formData.append('spotifyLink', briefValues.spotifyLink);
    formData.append('appleMusicLink', briefValues.appleMusicLink);
    formData.append('youtubeLink', briefValues.youtubeLink);
    formData.append('rate', rateDisplay * 100);
    formData.append('quantity', briefValues.quantity || 0);
    formData.append('fulfilled', briefValues.fulfilled || 0);
    formData.append('priceIdId', briefValues.priceId._id);
    formData.append('priceIdDescription', briefValues.priceId.description);
    formData.append('priceIdAmount', briefValues.priceId.amount);
    if (typeof briefValues.cover !== 'string') {
      formData.append('fileType', briefValues.cover !== null ? briefValues.cover.type : '');
      formData.append('file', coverBlob, briefValues.cover !== null ? briefValues.cover.name : '');
    }

    console.log('FORMDATA: ', formData)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/editbrief`, formData, {  // eslint-disable-line
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        successCallback();
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        // setValue('cover', file, { shouldValidate: false });
        setBriefValues(prevState => ({
          ...prevState,
          cover: file
        }))
        setNewCover(newFile.preview)
      }
    },
    [setBriefValues]
  );

  const handleRemoveFile = () => {
    setValue('cover', null);
    setBriefValues(prevState => ({
      ...prevState,
      cover: ''
    }))
  };


  async function getCategories(orgId) {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/orgcategories`, {  // eslint-disable-line
        orgId,
        type: 'get',
        platform: isAA() ? 'AA' : 'TB',
      }).then(response => {
        // console.log(response)
        setCategories(response.data.categories)
      });
    } catch (err) {
      console.log(err)
    }
  }


  async function getPrices(orgId) {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getstripeprices`, {  // eslint-disable-line
        orgId,
      }).then(response => {
        console.log(response)
        // setStripePrices(response.data)
      });
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (post !== null || post !== undefined) {
      getCategories(post.organization)
      getPrices(user?.organization)
      setBriefValues(post)
      setSubGenres(post?.subGenres)
      setMultiGenres(post?.multiGenres)
      const rateConvert = post.rate * .01
      setRateDisplay(rateConvert)
      // console.log(post)

      const subString = 'playlist';

      if (post?.spotifyLink.includes(subString)) {
        try {
          const spotifyTemp1 = post?.spotifyLink.split('/playlist/')
          const spotifyTemp2 = spotifyTemp1[1].split('?')
          setSpotifyPlaylistId(spotifyTemp2[0])
          // console.log('Spotify: ', spotifyTemp2)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }, [post]) // eslint-disable-line

  useEffect(() => {

    const subString = 'playlist';

    if (post?.spotifyLink.includes(subString)) {
      try {
        const spotifyTemp1 = briefValues?.spotifyLink.split('/playlist/')
        const spotifyTemp2 = spotifyTemp1[1].split('?')
        setSpotifyPlaylistId(spotifyTemp2[0])
        // console.log('Spotify: ', spotifyTemp2)
      } catch (err) {
        console.log(err)
      }
    }

  }, [briefValues]) // eslint-disable-line

  useEffect(() => {

    if (spotifyPlaylistId !== '') {
      try {
        setPlaylistBox(
          <iframe style={{ borderRadius: "12px" }} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator&theme=0`} width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist' />
        )
      } catch (err) {
        console.log(err)
      }
    }

  }, [spotifyPlaylistId]) // eslint-disable-line

  return (
    <FormProvider
      methods={methods}
    // onSubmit={handleSubmit(onSubmit)}
    // onSubmit={handleUpload}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <TextField
                variant='outlined'
                autoComplete='off'
                placeholder='New Title'
                label='Title'
                value={briefValues?.title}
                onChange={(e) => {
                  setBriefValues(prevState => ({
                    ...prevState,
                    title: e.target.value
                  }))
                }}
              />
              <TextField
                variant='outlined'
                autoComplete='off'
                placeholder='New Description'
                label='Description'
                multiline
                rows={3}
                value={briefValues?.description}
                onChange={(e) => {
                  setBriefValues(prevState => ({
                    ...prevState,
                    description: e.target.value
                  }))
                }}
              />

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Content
                </Typography>

                <Editor
                  // id={name}
                  simple
                  value={briefValues?.content}
                  onChange={(e) => {
                    // console.log(e)
                    setBriefValues(prevState => ({
                      ...prevState,
                      content: e
                    }))
                  }}
                // error={!!error}
                />
              </Stack>

              <Stack spacing={2}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Cover
                </Typography>
                {briefValues?.cover === '' ?
                  <RHFUpload
                    name="cover"
                    maxSize={5145728}
                    onDrop={handleDrop}
                    onDelete={handleRemoveFile}
                  /> :
                  <>
                    <Button
                      variant='text'
                      sx={{ color: 'error.main' }}
                      onClick={() => {
                        setBriefValues(prevState => ({
                          ...prevState,
                          cover: ''
                        }))
                      }}
                    >
                      Remove Picture
                    </Button>
                    <Image
                      alt="cover"
                      src={
                        typeof briefValues?.cover === 'string' ? briefValues?.cover : newCover
                      }
                      sx={{
                        borderRadius: 2,
                        // mb: 6,
                      }}
                      ratio="16/9" />
                  </>
                }
                <Box
                  sx={{
                    // border: 'red solid 1px',
                    width: '100%',
                    p: 4,
                    pt: 0,
                    borderRadius: 2,
                    // zIndex: 100,
                    // backgroundColor: alpha(theme.palette.grey[900], 0.01),

                    backgroundColor: theme.palette.grey[800],
                    display: post?.spotifyLink || post?.appleMusicLink ? 'block' : 'none',
                  }}
                >
                  {/* <StyledOverlay/> */}
                  <Typography
                    variant="h3"
                    sx={{
                      pb: 3,
                      pt: 2,
                      color: theme.palette.common.white,
                      // py: 5,
                      // px: { md: 5 },
                    }}
                  >
                    Reference
                  </Typography>

                  {playlistBox !== null &&
                    <Box sx={{
                      // zIndex: 1,
                    }}>
                      {playlistBox}
                    </Box>
                    // <iframe style={{borderRadius: "12px"}} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator`} width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist'/>
                  }
                </Box>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                  <Typography
                    autoComplete='off'
                    variant='body'
                    sx={{
                      opacity: briefValues?.active ? 1 : .3,
                    }}
                  >
                    Active
                  </Typography>
                  <Switch
                    // value={briefValues.active}
                    checked={briefValues?.active}
                    onChange={(e) => {
                      setBriefValues(prevState => ({
                        ...prevState,
                        active: !briefValues.active
                      }))
                    }}
                  // defaultChecked 
                  />
                </Box>

              </div>

              <TextField
                sx={{ display: 'none' }}
                variant='outlined'
                autoComplete='off'
                placeholder='Genre'
                label='Genre'
                value={briefValues?.category}
                onChange={(e) => {
                  setBriefValues(prevState => ({
                    ...prevState,
                    category: e.target.value
                  }))
                }}
              />
              <Stack spacing={1}>

                <Typography variant='caption'>Genre</Typography>
                <Select
                  // label='Genre'
                  placeholder='Genre'
                  value={briefValues?.category}
                  onChange={(e) => {
                    setBriefValues(prevState => ({
                      ...prevState,
                      category: e.target.value
                    }))
                    if (e.target.value === 'Various') {
                      const blankArray = []
                      setSubGenres(blankArray)
                    }
                  }}
                >
                  {categories.map((item, itemIndex) => { // eslint-disable-line
                    return <MenuItem key={`category-${itemIndex}`}
                      value={item.genre}
                    // style={{backgroundColor: theme.palette.primary.main}}
                    >
                      {item.genre.split('/')[0]}
                    </MenuItem>
                  })}
                  <MenuItem value='Various'>Various</MenuItem>
                </Select>
                {briefValues?.category === 'Various' &&
                  <FormControl>
                    <InputLabel
                      id="colored-label-select-label"
                      style={{ color: theme.palette.info.main }} // Apply the style to the label
                    >
                      Genres
                    </InputLabel>
                    <Select
                      label='Genre'
                      // placeholder='Genre'
                      value={multiGenres}
                      multiple
                      onChange={(e) => {
                        const newMultiGenres = e.target.value
                        setMultiGenres(newMultiGenres)
                      }}
                      renderValue={(selection) => (
                        <div>
                          {selection.map((value, index) => (
                            <span key={value}>{index !== selection.length - 1 ? value : value.trim()},
                            </span>
                          ))}
                        </div>
                      )}
                    >
                      {categories.map(item => { // eslint-disable-line
                        return <MenuItem value={item.genre}>
                          {item.genre.split('/')[0]}
                        </MenuItem>
                      })}
                    </Select>
                  </FormControl>
                }
                {(briefValues?.category !== 'Various' && briefValues?.category !== '') &&
                  <FormControl>
                    <InputLabel
                      id="colored-label-select-label"
                      style={{ color: theme.palette.info.main }} // Apply the style to the label
                    >
                      Sub Genres
                    </InputLabel>
                    <Select
                      label='SubGenres'
                      // placeholder='Genre'
                      value={subGenres}
                      multiple
                      onChange={(e) => {
                        const newSubGenres = e.target.value
                        setSubGenres(newSubGenres)
                      }}
                      renderValue={(selection) => (
                        <div>
                          {selection.map((value, index) => (
                            <span key={value}>{index !== selection.length - 1 ? value : value.trim()},
                            </span>
                          ))}
                        </div>
                      )}
                    >
                      {categories.map(item => { // eslint-disable-line
                        if (item.genre === briefValues?.category) {
                          return item.subCategories.map((subItem, subIndex) => { // eslint-disable-line
                            // console.log(`SubItem ${subIndex}`, subItem.subType)
                            return <MenuItem value={subItem.subType}>
                              {subItem.subType}
                            </MenuItem>
                          })
                        }
                      })}
                    </Select>
                  </FormControl>
                }
              </Stack>

              <TextField
                variant='outlined'
                autoComplete='off'
                placeholder='Spotify Playlist'
                label='Spotify Link'
                value={briefValues?.spotifyLink}
                onChange={(e) => {
                  setBriefValues(prevState => ({
                    ...prevState,
                    spotifyLink: e.target.value
                  }))
                }}
              />
              <TextField
                variant='outlined'
                autoComplete='off'
                placeholder='Youtube Playlist'
                label='Youtube Link'
                value={briefValues?.youtubeLink}
                onChange={(e) => {
                  setBriefValues(prevState => ({
                    ...prevState,
                    youtubeLink: e.target.value
                  }))
                }}
              />

              <Stack direction='column' spacing={2}>
                <Divider />
                <Typography variant='caption'>Stripe Price</Typography>
                <Stack direction='row' gap={2} alignItems='center'>
                  <TextField
                    size='small'
                    fullWidth
                    variant='outlined'
                    autoComplete='off'
                    // placeholder='Price Id'
                    label='Price Id'
                    value={briefValues?.priceId._id}
                    onChange={(e) => {
                      setBriefValues(prevState => ({
                        ...prevState,
                        priceId: {
                          ...prevState.priceId,
                          _id: e.target.value
                        }
                      }))
                    }}
                  />
                  <Iconify icon='akar-icons:copy' width={18}
                    sx={{
                      color: theme.palette.info.main,
                      cursor: 'pointer',
                      transition: 'all .2s ease-in-out',
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                      '&:active': {
                        transform: 'scale(.9)'
                      },
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(briefValues?.priceId._id)
                    }}
                  />
                </Stack>
                <Stack direction='row' gap={2} alignItems='center'>

                  <TextField
                    size='small'
                    fullWidth
                    variant='outlined'
                    autoComplete='off'
                    // placeholder='Description'
                    label='Description'
                    value={briefValues?.priceId.description}
                    onChange={(e) => {
                      setBriefValues(prevState => ({
                        ...prevState,
                        priceId: {
                          ...prevState.priceId,
                          description: e.target.value
                        }
                      }))
                    }}
                  />
                  <Iconify icon='akar-icons:copy' width={18}
                    sx={{
                      color: theme.palette.info.main,
                      cursor: 'pointer',
                      transition: 'all .2s ease-in-out',
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                      '&:active': {
                        transform: 'scale(.9)'
                      },
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(briefValues?.priceId.description)
                    }}
                  />

                </Stack>
                <Stack direction='row' gap={2} alignItems='center'>

                  <TextField
                    size='small'
                    fullWidth
                    variant='outlined'
                    autoComplete='off'
                    // placeholder='Amount'
                    label='Amount (in cents)'
                    value={briefValues?.priceId.amount}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[^0-9,\s]/g, '');
                      setBriefValues(prevState => ({
                        ...prevState,
                        priceId: {
                          ...prevState.priceId,
                          amount: newValue
                        }
                      }))
                    }}
                  />
                  <Iconify icon='akar-icons:copy' width={18}
                    sx={{
                      color: theme.palette.info.main,
                      cursor: 'pointer',
                      transition: 'all .2s ease-in-out',
                      '&:hover': {
                        color: theme.palette.primary.main,
                      },
                      '&:active': {
                        transform: 'scale(.9)'
                      },
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(briefValues?.priceId.amount)
                    }}
                  />

                </Stack>
                <Divider />

              </Stack>

              <Stack direction='row' spacing={2} display='flex'>
                <TextField
                  variant='outlined'
                  autoComplete='off'
                  placeholder='Quantity'
                  label='Quantity'
                  value={briefValues?.quantity}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^0-9,\s]/g, '');
                    setBriefValues(prevState => ({
                      ...prevState,
                      quantity: newValue
                    }))
                  }}
                />
                <TextField
                  variant='outlined'
                  autoComplete='off'
                  placeholder='Fulfilled'
                  label='Fulfilled'
                  value={briefValues?.fulfilled}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^0-9,\s]/g, '');
                    setBriefValues(prevState => ({
                      ...prevState,
                      fulfilled: newValue
                    }))
                  }}
                />
              </Stack>


              <TextField
                variant='outlined'
                sx={{
                  display: 'none'
                }}
                placeholder='Apple Music'
                label='Apple Music'
                value={briefValues?.appleMusicLink}
                onChange={(e) => {
                  setBriefValues(prevState => ({
                    ...prevState,
                    appleMusicLink: e.target.value
                  }))
                }}
              />
              {user?.role === 'admin' &&
                <TextField
                  variant='outlined'
                  autoComplete='off'
                  placeholder='Rate'
                  label='Rate'
                  value={rateDisplay}
                  onChange={(e) => {
                    setRateDisplay(e.target.value)
                  }}
                />
              }
            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3, mb: 3 }}>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              size="large"
              onClick={() => { navigate(-1) }}
            >
              Cancel
            </Button>
            <Button
              fullWidth
              color="inherit"
              variant="outlined"
              size="large"
              onClick={handleOpenPreview}
            >
              Preview
            </Button>
          </Stack>
          <LoadingButton
            fullWidth
            type="button"
            variant="contained"
            size="large"
            loading={isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              handleUpload();
            }}
          >
            Update Brief
          </LoadingButton>
          {!deleteWarning &&
            <Button variant='outlined' color='error' fullWidth size='large'
              onClick={() => { setDeleteWarning(true) }}
              sx={{ mt: 2 }}
            >
              Delete Brief
            </Button>
          }
          {deleteWarning &&
            <Box sx={{
              height: 'fit-content',
              width: '100%',
              mt: 2,
              borderRadius: 2,
              backgroundColor: theme.palette.error.dark
            }}
            >
              <Stack direction='column' spacing={3} alignItems='center' padding={3}>
                <Typography variant='h4' sx={{ mt: 1, color: 'white' }}>
                  Are you sure?
                </Typography>
                <Typography variant='body2' sx={{ mt: 3, color: 'white' }}>
                  If you&apos;re done with this brief, it&apos;s better to set it
                  as inactive, so you can come back and reference it if needed.
                </Typography>
                <Typography variant='body2' sx={{ mt: 3, color: 'white' }}>
                  Only delete this brief if you haven&apos;t approved any submissions yet,
                  and no longer need it.
                </Typography>
                <Stack direction='row' spacing={2} >
                  <Button variant='outlined' color='error' size='large'
                    onClick={() => { setDeleteWarning(false) }}
                    sx={{ color: 'white', width: '145px', }}
                  >
                    Cancel
                  </Button>
                  <Button variant='contained' size='large'
                    disabled={briefValues.active === false}
                    onClick={() => {
                      setBriefValues(prevState => ({
                        ...prevState,
                        active: !briefValues.active
                      }))
                      setTimeout(() => { setDeleteWarning(false) }, 1000)
                    }
                    }
                    sx={{
                      color: theme.palette.primary.main,
                      backgroundColor: 'white',
                      width: '145px',
                      '&:hover': {
                        color: 'white'
                      }
                    }}
                  >
                    {briefValues.active !== false ?
                      'Mark Inactive' :
                      <>
                        Marked Inactive <CheckRounded />
                      </>
                    }
                  </Button>


                </Stack>
                <Button variant='outlined' color='error' size='large' fullWidth
                  onClick={async () => {
                    await deleteBrief({ variables: { id: briefId } });
                    navigate('/briefs')
                  }}
                  sx={{ color: theme.palette.error, backgroundColor: 'white', }}
                >
                  Delete Brief
                </Button>

              </Stack>
            </Box>
          }
        </Grid>
      </Grid>

      <BriefPreview
        values={briefValues}
        open={openPreview}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClose={handleClosePreview}
        onSubmit={handleUpload}
      />
    </FormProvider>
  );
}
