import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Container, Typography, Stack, Link } from '@mui/material';
import LoadingIcons from 'react-loading-icons'

// graphql
import axios from 'axios';

// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import { fDate2 } from '../utils/formatTime';
import { fCurrencyCents } from '../utils/formatNumber';
import Image from '../components-default/image';



// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';


// ----------------------------------------------------------------------

export default function OrdersPageAdmin() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();

  const [displayFiles, setDisplayFiles] = useState([]);
  const [orderLoading, setOrderLoading] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

 async function getAllInvoices(){
  try {
    setOrderLoading(true)
    const res = await axios.post(`${process.env.REACT_APP_SERVER}/getallinvoices`, {  // eslint-disable-line
    }).then(response => {
      // console.log(response)
      setOrderLoading(false)
      const sortedFiles = response?.data?.invoices.sort((a, b) => new Date (b.created_at) - new Date(a.created_at))
      return setDisplayFiles(sortedFiles)
    });
  } catch (err) {
    console.log(err)
  }   
 }

  useEffect(()=>{
    getAllInvoices()

  },[]) // eslint-disable-line


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Orders </title>
      </Helmet>
      <Box
        sx={{ 
          pt: 6, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dash',
                },
                { name: 'Orders' },
              ]
              }
            heading='Orders (Admin)'
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        {orderLoading ?
        <Box
          sx={{
            py: 5,
          }}
        >
          <LoadingIcons.TailSpin height='100px' stroke={theme.palette.primary.main} width='100%'/>
        </Box> :
  
        <Grid container spacing={2} className='test-opaque'
          sx={{
            borderRadius: 2,
            overflow: 'hidden',
            mb: 6,
          }}
        >
          {displayFiles.map(invoice => {return <Grid item key={invoice._id} // eslint-disable-line
          xs={12} sm={6} md={4} sx={{
            // m:2, 
          }}>
            <Link href={`/orders/${invoice?._id}`} 
              sx={{
                '&:hover': {
                  textDecoration: 'none'
                },
              }}
            >
          <Box 
            sx={{
              height: {
                xs: '150px',
                sm: '250px',
              },
              width: '100%',
              borderRadius: 2,
              backgroundColor: theme.palette.divider,
              border: `${theme.palette.info.light} solid 1px`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              overflow: 'hidden',
            }}
            >
            <Stack direction='row' alignItems='center' justifyContent='center' overflow='hidden'>
              <Image src='/logo/pikes.svg' sx={{
                height: {
                  xs: '150px',
                  sm: '250px',
                },
                width: {
                  xs: '150px',
                  sm: '250px',
                },
                right: {
                  xs: '25px',
                  sm: 'unset',
                },
                opacity: {
                  xs: .1,
                  sm: .035,
                },
                ml: 10,
                position: 'absolute',
                zIndex: 0,
                overflow: 'hidden',
                // borderRadius: "50%",
                // border: `${theme.palette.text.primary} solid 2px`,
              }}/>  
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  p: 2,
                  cursor: 'pointer',
                }}
              >
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Order Date:</Typography>
                  <Typography variant='subtitle1'>{fDate2(invoice?.created_at)}</Typography>
                </Stack>
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Invoice Id:</Typography>
                  <Typography variant='subtitle1'>{invoice?._id}</Typography>

                </Stack>
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Amount:</Typography>
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <Typography variant='subtitle1'>${fCurrencyCents(invoice?.totalPrice)}</Typography>
                    <Typography variant='caption' 
                      sx={{
                        color: invoice?.status === 'paid' ? theme.palette.success.main : theme.palette.info.main,
                        border: invoice?.status === 'paid' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.info.main} solid 1px`,
                        px: 1,
                        borderRadius: 2,
                      }}
                    >{invoice?.status === 'paid' ? 'PAID' : 'OPEN'}</Typography>
                  </Stack>
                </Stack>
                <Stack direction='row' spacing={3} 
                  alignItems='center' 
                  padding={0}
                  sx={{
                    color: theme.palette.text.primary,
                  }}
                  // border='red solid 1px'
                >
                  <Typography variant='h6' sx={{width: '100px', p: 0, textAlign: 'right'}}>Songs:</Typography>
                    <Typography variant='subtitle1'>{invoice?.items?.length}</Typography>
                </Stack>
              </Box>
            </Stack>
          </Box>
          </Link>
          </Grid>
}
          )}
        </Grid>
      }

      </Container>
      <Footer />

      <CustomToast toast={toast} setToast={setToast}/>

    </>
  );
}
