import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';

import {
  Box,
  Card,
  Grid,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Typography,
  TableContainer,
} from '@mui/material';

// utils
import { fDate } from '../../../../../utils/formatTime';
import { fCurrencyCents } from '../../../../../utils/formatNumber';
// components
import Label from '../../../../../components-default/label';
import Image from '../../../../../components-default/image';
import Scrollbar from '../../../../../components-default/scrollbar';
//
import { useAuthContext } from '../../../../../auth/useAuthContext';

import InvoiceToolbar from './InvoiceToolbar';

// ----------------------------------------------------------------------

const StyledRowResult = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

// ----------------------------------------------------------------------

InvoiceDetails.propTypes = {
  invoice: PropTypes.object,
  paymentDrawerOpen: PropTypes.bool,
  setPaymentDrawerOpen: PropTypes.func,
};

export default function InvoiceDetails({ invoice, paymentDrawerOpen, setPaymentDrawerOpen }) {

  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const theme = useTheme()


  if (!invoice) {
    return null;
  }

  const {
    items,
    taxes,
    status,
    dueDate,
    discount,
    invoiceTo,
    organization,
    created_at,
    subTotal,
    salesTax,
    totalPrice,
    invoiceFrom,
    _id,
  } = invoice;

  // console.log('INVOICE: ', invoice)

  const fullAddress = `${organization.address}, ${organization.city} ${organization.state} ${organization.zip}`

  return (
    <>
      <InvoiceToolbar invoice={invoice} paymentDrawerOpen={paymentDrawerOpen} setPaymentDrawerOpen={setPaymentDrawerOpen}/>

      <Card sx={{ pt: 5, px: 5, mb: 10, }}>
        <Grid container>
        <Grid item xs={12} sm={6} sx={{ mb: 5, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Image disabledEffect alt="logo" src="/logo/pikes.svg" sx={{ width: 40 }} />
            <span
              style={{fontFamily: "peckham-press, sans-serif", fontSize: 20, color: "#51504a", fontWeight: 100, letterSpacing: 2 }}
            >
              {process.env.REACT_APP_SITE_NAME}
          </span>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Box sx={{ textAlign: { sm: 'right' } }}>
              <Label
                variant="soft"
                color={
                  (status === 'paid' && 'success') ||
                  (status === 'unpaid' && 'warning') ||
                  (status === 'overdue' && 'error') ||
                  'default'
                }
                sx={{ textTransform: 'uppercase', mb: 1 }}
              >
                {status}
              </Label>

              <Typography variant="h6">{_id}</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice from
            </Typography>

            <Typography variant="body2">{process.env.REACT_APP_SITE_NAME}</Typography>

            <Typography variant="body2">3033 Oxford Dr, Mount Juliet TN 37211</Typography>

            <Typography variant="body2">Phone: 615-477-3695</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice to
            </Typography>

            <Typography variant="body2">{invoice?.organization?.name || invoice.invoiceTo}</Typography>

            <Typography variant="body2">{fullAddress || ''}</Typography>

            <Typography variant="body2">{invoice?.organization?.phone || ''}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Invoice Date
            </Typography>

            <Typography variant="body2">{fDate(created_at)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} sx={{ mb: 5 }}>
            <Typography paragraph variant="overline" sx={{ color: 'text.disabled' }}>
              Due date
            </Typography>

            <Typography variant="body2">{fDate(dueDate)}</Typography>
          </Grid>
        </Grid>

        <TableContainer sx={{ overflow: 'unset' }}>
          <Scrollbar>
            <Table sx={{ minWidth: 960 }}>
              <TableHead
                sx={{
                  borderBottom: `solid 1px ${theme.palette.divider}`,
                  '& th': { backgroundColor: 'transparent' },
                }}
              >
                <TableRow>
                  <TableCell align="left">Description</TableCell>

                  <TableCell align='left'>Item #</TableCell>

                  {/* <TableCell align="left">Documents</TableCell> */}
                  <TableCell align="left"/>

                  <TableCell align="left"/>

                  <TableCell align="right">Total</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {invoice.items.map((item, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      borderBottom: `solid 1px ${theme.palette.divider}`,
                    }}
                  >
                    <TableCell align="left">
                      <Box sx={{ maxWidth: 560 }}>
                        <Typography variant="subtitle2">{item.title}</Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                          {item.artist.name}
                        </Typography>
                      </Box>
                    </TableCell>

                    <TableCell>{item._id}</TableCell>

                    {/* <TableCell align="left"><a href=''style={{color: 'inherit'}} >Download Docs</a></TableCell> */}
                    <TableCell align="left"/>
                    
                    
                    <TableCell align="left"/>

                    {/* <TableCell align="left" sx={{cursor: 'default', color: item.multitrackUrl !== '' ? 'inherit' : theme.palette.text.disabled}}>
                      {item.multitrackUrl !== '' ?
                      <a href={item.multitrackUrl} target='_blank' rel='noreferrer' style={{color: 'inherit'}} >Multitracks</a> :
                        'Multitracks'
                      }
                      </TableCell> */}

                    <TableCell align="right">${fCurrencyCents(item.price)}</TableCell>
                  </TableRow>
                ))}

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'body1' }}>
                    <Box sx={{ mt: 2 }} />
                    Subtotal
                  </TableCell>

                  <TableCell align="right" width={120} sx={{ typography: 'body1' }}>
                    <Box sx={{ mt: 2 }} />
                    ${fCurrencyCents(subTotal)}
                  </TableCell>
                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />

                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />
                  <TableCell align="right" sx={{ typography: 'body1' }}/>

                  <TableCell align="right" width={140} sx={{ typography: 'body1' }}/>

                </StyledRowResult>

                <StyledRowResult>
                  <TableCell colSpan={3} />

                  <TableCell align="right" sx={{ typography: 'h6' }}>
                    Total Due
                  </TableCell>

                  <TableCell align="right" width={140} sx={{ typography: 'h6' }}>
                    ${fCurrencyCents(totalPrice)}
                  </TableCell>
                </StyledRowResult>
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <Divider sx={{ mt: 5 }} />

        <Grid container>
          <Grid item xs={12} md={9} sx={{ py: 3 }}>
            <Typography variant="subtitle2">NOTES</Typography>

            <Typography variant="body2">
              We appreciate your business. 
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sx={{ py: 3, textAlign: 'right' }}>
            <Typography variant="subtitle2">Have a Question?</Typography>

            <Typography variant="body2">support@audioarsenal.io</Typography>
          </Grid>
        </Grid>
      </Card>
    </>
  );
}
