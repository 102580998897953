import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { Box, Grid, Stack, Slider, Checkbox } from '@mui/material';

import { useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import { TOGGLE_DOC_FAVORITE, } from '../graphql/mutations'

import Iconify from '../components-default/iconify';
import 'react-h5-audio-player/lib/styles.css'; // eslint-disable-line import/no-extraneous-dependencies
import WaveSurferPlayer from './WavesurferPlayer';

export default function AudioPlayerBar(props) {

    AudioPlayerBar.propTypes = {
        src: PropTypes.object,
        userId: PropTypes.string,
        refetch: PropTypes.func,
        controlMessage: PropTypes.string,
        setControlMessage: PropTypes.func,
        isPlaying: PropTypes.bool,
        audioBarFavorite: PropTypes.bool,
        setAudioBarFavorite: PropTypes.func,
        setIsPlaying: PropTypes.func,
    }

    const theme = useTheme();
    const { src, refetch, controlMessage, setControlMessage, isPlaying, setIsPlaying, audioBarFavorite, setAudioBarFavorite, userId } = props;
    
    const [volumeValue, setVolumeValue] = useState(.7);
    
    const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);
  
    const handleKeyDown = (event) => {
        if (event.key === 'Spacebar') {
            setControlMessage('play-pause')
        }
      };

    const handleFavorite = async () => {
        setAudioBarFavorite(!audioBarFavorite);
        await toggleFavorite({ variables: { docId: src._id, userId} });
        refetch()
    };

    const handleChange = (event, newValue) => {
      setVolumeValue(newValue);
    };
  
    useEffect(() => {
        if (setAudioBarFavorite){
            setAudioBarFavorite(src?.favorited?.includes(userId))
        }
    },[src]) // eslint-disable-line react-hooks/exhaustive-deps

    return (

    <Box sx={{ 
        onKeyDown: {handleKeyDown},
        position: 'fixed',
        bottom: src?.audioUrl ? 0 : -100,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        height: '80px',
        width: '100%',
        // backgroundColor: theme.palette.text.primary,
        backgroundColor: "#51504a",
        borderTop: `1px solid ${theme.palette.divider}`,
        transition: 'all 0.2s ease-in-out',
        cursor: 'default',
        zIndex: 101,
    }}>        
        <Box 
            sx={{
                // blank box spacer
                display: 'flex', 
                alignItems: 'center', 
                // justifyContent:'flex-end',
                // border: 'green solid 1px',
            }}
        />

        <WaveSurferPlayer 
            src={src} 
            controlMessage={controlMessage} 
            setControlMessage={setControlMessage}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            volumeValue={volumeValue}
            setVolumeValue={setVolumeValue}
            audioBarFavorite={audioBarFavorite}
            setAudioBarFavorite={setAudioBarFavorite}
            refetch={refetch}
            userId={userId}
        />
       
    </Box>

  )
}
