import { gql} from 'graphql-tag';

export const REGISTER_USER = gql`
    mutation Mutation(
        $registerInput: RegisterInput
        ) {
            registerUser(
                registerInput: $registerInput
            ) {
            _id
            name
            email
            theme
            role
            publisher
            pro
            plan
            tier
            token
            collaborators{
                _id
                name
                email
                lastDate
            }
            }
        }
`

export const LOGIN_USER = gql`
    mutation Mutation(
        $loginInput: LoginInput
        ) {
            loginUser(
                loginInput: $loginInput
            ) {
            _id
            name
            email
            theme
            role
            publisher
            pro
            plan
            tier
            token
            collaborators{
                _id
                name
                email
                lastDate
            }
            }
        }
`
export const UPDATE_USER = gql`
    mutation UpdateUser(
        $updateUserId: String, 
        $updateInput: UserInput
        ) {
        updateUser(
            updateUserId: $updateUserId, 
            updateInput: $updateInput
        ) {
            _id
        }
        }

`

export const UPDATE_USER_AI_USAGE = gql`
    mutation UpdateUserAiUsage(
        $userId: String, 
        $tokens: Int
    ) {
        updateUserAiUsage(
            userId: $userId, 
            tokens: $tokens
        ) {
            _id
        }
    }
`

export const DELETE_DOCUMENT = gql`
    mutation DeleteDocumentById(
        $id: String
        ) {
            deleteDocumentById(
                id: $id
        ) {
            title
            _id
        }
}`


export const TOGGLE_DOC_FAVORITE = gql`
    mutation Mutation(
        $docId: String, 
        $userId: String
        ) {
        toggleDocFavorite(
            docId: $docId, 
            userId: $userId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
            }     
            favorited
            pass
        }
}`



export const GET_ARTIST_SONGS_BY_USER_ID = gql`
    mutation Mutation(
        $userId: String
        $artistId: String
        ) {
            getArtistSongsByUserId(
                id: $userId
                artist: $artistId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
            }
            inCart {
                _id
                orgName
                price
            }
            price
        }
    }
`

export const TOGGLE_DOC_PASS = gql`
    mutation Mutation(
        $docId: String, 
        $userId: String
        ) {
        toggleDocPass(
            docId: $docId, 
            userId: $userId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
            }     
            favorited
            pass
            inCart {
                _id
                orgName
                price
            }
        }
}`

export const PITCH_PASS = gql`
    mutation Mutation(
        $docId: String, 
        $briefId: String, 
        $userId: String
        ) {
        pitchPass(
            docId: $docId, 
            briefId: $briefId,
            userId: $userId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
                theme
            }     
            pitches {
                briefId
                briefTitle
                status
                created_at
                updated_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
            }
            favorited
            pass
            inCart {
                _id
                orgName
                price
            }
        }
}`

export const PITCH_APPROVE = gql`
    mutation Mutation(
        $docId: String, 
        $briefId: String, 
        $userId: String
        ) {
        pitchApprove(
            docId: $docId, 
            briefId: $briefId,
            userId: $userId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
                theme
            }     
            pitches {
                briefId
                briefTitle
                status
                created_at
                updated_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
            }
            favorited
            pass
            inCart {
                _id
                orgName
                price
            }
        }
}`

export const ADD_DOC_TO_CART = gql`
    mutation Mutation(
        $docId: String, 
        $orgId: String
        ) {
        addDocToCart(
            docId: $docId, 
            orgId: $orgId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
            }                favorited
            pass
            inCart {
                _id
                orgName
                price
            }
        }
}`

export const PITCH_SONG_TO_BRIEF = gql`
    mutation Mutation(
        $docId: String, 
        $briefId: String
        $briefTitle: String
        ) {
        pitchSongToBrief(
            docId: $docId, 
            briefId: $briefId
            briefTitle: $briefTitle
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
            }                
            favorited
            pass
            inCart {
                _id
                orgName
                price
            }
            pitches {
                briefId
                briefTitle
                status
                created_at
                updated_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
            }
        }
}`

export const DELETE_PITCH = gql`
    mutation DeletePitch(
        $docId: String, 
        $briefId: String
        ) {
        deleteSongPitch(
            docId: $docId, 
            briefId: $briefId
        ) {
            _id
            title
        }
    }
`
export const DELETE_ARTIST_BY_ID = gql`
    mutation DeleteArtistById(
        $userId: String, 
        $artistId: String
        ) {
        deleteArtistById(
            userId: $userId, 
            artistId: $artistId
        ) {
            _id
            name
        }
    }
`

export const GET_BRIEF_BY_ID_M = gql`
mutation Mutation(
    $briefId: ID
    ) {
        getBriefById(
            id: $briefId
            ) {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            youtubeLink
            organization
            quantity
            fulfilled
        }

    }
}   
`
export const GET_ACTIVE_BRIEFS_M = gql`
    mutation GetActiveBriefsM (
        $userTier: Int
    ){
    getActiveBriefsM (
        userTier: $userTier
    ){
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            customRate
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }
    }
}
`

export const GET_ACTIVE_BRIEFS_BY_ORG_M = gql`
mutation Mutation(
    $orgId: String
    ) {
        getActiveBriefsByOrgM(
            orgId: $orgId
            ) {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }

    }
}   
`

export const GET_INACTIVE_BRIEFS_M = gql`
    mutation GetInactiveBriefsM {
    getInactiveBriefsM {
        _id
        post {
            active
            title
            category
            description
            content
            multiGenres
            subGenres
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }
    }
}
`

export const GET_INACTIVE_BRIEFS_BY_ORG_M = gql`
mutation Mutation(
    $orgId: String
    ) {
        getInactiveBriefsByOrgM(
            orgId: $orgId
            ) {
        _id
        post {
            active
            title
            category
            multiGenres
            subGenres
            description
            content
            cover
            tags
            rate
            terms
            customTerms
            createdAt
            author {
                _id
                name
                avatarUrl
            }
            spotifyLink
            appleMusicLink
            organization
        }

    }
}   
`


export const ADD_OWNER_TO_DOCUMENT_BY_EMAIL = gql`
    mutation Mutation(
        $sender: String, 
        $senderId: String, 
        $docId: String, 
        $email: String
        ) {
        addOwnerToDocumentByEmail(
            sender: $sender,
            senderId: $senderId,
            docId: $docId, 
            email: $email
            ) {
                owner {
                    name
                    _id
                    email
                    added
                    percentage
                    publisher
                    pro
                    status
                }
                title
                _id
            }
        }
`
export const REMOVE_OWNER_FROM_DOCUMENT_BY_ID = gql`
    mutation Mutation(
        $docId: String, 
        $userId: String
        ) {
        removeOwnerFromDocumentById(
            docId: $docId, 
            userId: $userId
        ) {
            owner {
                name
                status
            }
            title
        }
}
`
export const REMOVE_OWNER_FROM_DOCUMENT_BY_EMAIL = gql`
    mutation Mutation(
        $docId: String, 
        $userEmail: String
        ) {
        removeOwnerFromDocumentByEmail(
            docId: $docId, 
            userEmail: $userEmail
        ) {
            owner {
                name
                status
            }
            title
        }
}
`

export const UPDATE_DOCUMENT_BY_ID = gql`
    mutation UpdateDocumentById(
        $documentId: ID, 
        $updateInput: DocumentInput, 
        $userId: String
    ) {
        updateDocumentById(
        documentId: $documentId, 
        updateInput: $updateInput, 
        userId: $userId
    ) {
        _id

    }
    }
`

export const DELETE_AUDIO_FROM_DOCUMENT_BY_ID = gql`
    mutation DeleteAudioFromDocumentById(
        $docId: String, 
        $audioName: String
    ) {
    deleteAudioFromDocumentById(
        docId: $docId, 
        audioName: $audioName
    ) {
        audio {
        name
        }
    }
    }
`

export const ADD_COLLABARATORS_TO_USER = gql`
    mutation Mutation(
        $docId: ID
    ) {
        addCollabs(
        docId: $docId
    )   {
            title
        }
        }

`

export const UPDATE_SCRATCHPAD_BY_ID = gql`
    mutation UpdateScratchpadById(
        $documentId: ID, 
        $updateInput: ScratchPadInput, 
        $userId: String
    ) {
        updateScratchpadById(
        documentId: $documentId, 
        updateInput: $updateInput, 
        userId: $userId
    ) {
        _id

    }
    }
`

export const CONFIRM_DOCUMENT_INVITE = gql`
    mutation confirmDocumentInvite(
        $documentId: ID, 
        $updateInput: DocumentInput, 
        $userEmail: String
    ) {
        confirmDocumentInvite(
        documentId: $documentId, 
        updateInput: $updateInput, 
        userEmail: $userEmail
    ) {
        _id

    }
    }
`

export const UPLOAD_AUDIO_TO_SESSION = gql`
  mutation UploadAudio(
        $documentId: String, 
        $audio: AudioInput
    ) {
    uploadAudio(
        documentId: $documentId, 
        audio: $audio
    ) {
      _id
      title
    }
  }
`;

export const DELETE_BRIEF_BY_ID = gql`
    mutation DeleteBriefById(
        $id: String
        ) {
            deleteBriefById(
                id: $id
        ) {
            _id
            post {
                title

            }
        }
}`

export const GET_USER_DOCS_BY_ID_M = gql`
    mutation Mutation(
        $getDocumentsByOwnerIdId: String
    ) {
        getDocumentsByOwnerId(
            id: $getDocumentsByOwnerIdId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
            }
            inCart {
                _id
                orgName
                price
            }
            price
        }
    }
`
export const GET_DOCS_BY_ORG_PITCH_M = gql`
    mutation getDocumentsByPitchedToOrgM(
        $orgId: String
    ) {
        getDocumentsByPitchedToOrgM(
            orgId: $orgId
        ) {
            _id
            title
            bpm
            key
            scale
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                organization
                briefId
                briefTitle
                briefCategory
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }        
            }
            inCart {
                _id
                orgName
                price
            }
            price
            cleared
            legalDocs
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }        
        }
    }
`


export const GET_USER_BY_ID_M = gql`
    mutation Mutation(
        $userId: String
    ) {
        getUserByIdM(
            userId: $userId
        ) {
            _id
            name
            email
            theme
            role
            tier
            publisher
            pro
            collaborators {
                _id
                name
                email
                lastDate
            }
            ai_usage {
                month
                total
            }
            avatar
            alias {
                _id
                name
                bio
                artwork
                smallArtwork
                created_at
            } 
            receipts {
                announcements
                briefs
            }  
            transactions {
                amount
                status
                createdAt
            }
            noted
        }
    }
`


export const GET_USER_PITCHES_BY_ID_M = gql`
    mutation Mutation(
        $userId: String
    ) {
        getUserPitchesById(
            id: $userId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            artist {
                name
                artwork
                smallArtwork
            }
            pitches {
                briefId
                briefTitle
                briefCategory
                briefArtwork
                status
                created_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
                rate
            }
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }   
        }
    }
`
export const GET_RECENT_USER_PITCHES_BY_ID_M = gql`
    mutation Mutation(
        $userId: String
    ) {
        getRecentUserPitchesById(
            id: $userId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            artist {
                name
                artwork
                smallArtwork
            }
            pitches {
                briefId
                briefTitle
                briefCategory
                briefArtwork
                status
                created_at
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }  
                rate 
            }
            feedback {
                date
                message
                sender {
                    _id
                    name
                    avatarUrl
                }
            }   
        }
    }
`

export const UPDATE_CLIENT_ORGANIZATION = gql`
    mutation updateClientOrg(
        $clientId: String, 
        $orgId: String, 
        ) {
        updateClientOrg(
            clientId: $clientId,
            orgId: $orgId, 
        ) {
            _id
        }
        }

`

export const GET_ORG_INFO_M = gql`
    mutation getOrgInfo(
        $orgId: String
    ) {
        getOrgInfo(
            orgId: $orgId
        ) {
            _id
            name
            address
            city
            state
            zip
            phone
            paymentType
            lastFour
            rate
            terms
            tier
            categories {
                genre
                subCategories {
                    subType
                    quantity
                    fulfilled
                }
            }
        }
    }
`

export const CREATE_PLAYLIST = gql`
    mutation createPlaylist(
        $playlistName: String,
        $orgId: String
    ) {
        createPlaylist(
            playlistName: $playlistName,
            orgId: $orgId
        ) {
            _id
            name
            organization
        }
    }
`

export const TOGGLE_SONG_TO_PLAYLIST = gql`
    mutation toggleSongToPlaylist(
        $playlistId: String,
        $docId: String
    ) {
        toggleSongToPlaylist(
            playlistId: $playlistId,
            docId: $docId
        ) {
            _id
            name
            organization
        }
    }
`
export const GET_PLAYLIST_M = gql`
    mutation getPlaylistM(
        $playlistId: String
    ) {
        getPlaylistM(
            playlistId: $playlistId
        ) {
            _id
            title
            audioUrl
            multitrackUrl
            created_at
            updated_at
            userId
            claimed
            playlist
            artist {
                name
                artwork
                smallArtwork
                bio
            }
            album
            tags {
                genre
                mood
                instrument
                theme
            }
            favorited
            pass
            pitches {
                briefId
                briefTitle
                status
                feedback {
                    date
                    message
                    sender {
                        _id
                        name
                        avatarUrl
                    }
                }   
            }
            inCart {
                _id
                orgName
                price
            }
            price
        }
    }
`

