import { Helmet } from 'react-helmet-async';

// @mui
import { Box, Container, Grid, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { useAuthContext } from '../auth/useAuthContext';


// sections
import Iconify from '../components-default/iconify';
import Footer from '../layouts/main/Footer';
import { fCurrencyCents } from '../utils/formatNumber';

// ----------------------------------------------------------------------

export default function TransactionsPage() {

  const theme = useTheme();
  const { user } = useAuthContext();


  const transactions = user?.transactions

  function convertDate(timestamp){

    // Create a new Date object with the timestamp
    const date = new Date(timestamp);

    // Extract the various components of the date
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Months are 0-indexed, so add 1
    const day = date.getDate();

    // Create a formatted date string
    const formattedDate = `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
    return formattedDate
  }

  return (
    <>
      <Helmet>
        <title> Transactions | {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>

      <Container sx={{ pt: 8, pb: 10, position: 'relative' }}>

      <Box>
      <Grid container>
        <Grid item xs={12} sx={{display: 'flex', justifyContent: 'flex-start'}}>

          <Timeline position="right" >
              {transactions.map((item) => (
                
                <TimelineItem key={item?._id} >
                  {item?.status === 'credit' ? 
                    <TimelineOppositeContent sx={{mb: 4, px: 3}} >
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {convertDate(item?.createdAt)}
                      </Typography>
                      <Typography variant="subtitle2">
                        {item?.songTitle}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {item?.songArtist}
                      </Typography>
                    </TimelineOppositeContent > :
                    <TimelineOppositeContent sx={{mb: 4, px: 3}} >
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}/>
                    </TimelineOppositeContent>
                  }
                  <TimelineSeparator>
                    <TimelineDot color={item?.status === 'credit' ? 'primary' : 'success'}>
                      <Iconify icon={item?.status === 'credit' ? 'circum:receipt' : 'tdesign:money'}/>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  {item?.status === 'paid' ?
                  <TimelineContent sx={{mb: 4, px: 3}} >
                    <Paper
                      sx={{
                        p: 3,
                        bgcolor: theme.palette.success.dark,
                      }}
                    >
                      <Typography variant="body2" 
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 2.5,
                        }}
                      >
                        {convertDate(item?.createdAt)}
                      </Typography>
                      <Typography variant="h5" 
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 1.5,
                        }}
                      >
                        PAYOUT - ${fCurrencyCents(item.amount)}
                      </Typography>
                      <Typography variant="caption" fontWeight='bold'
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 2.5,
                        }}
                      >
                        Songs:
                      </Typography>
                      <br/>
                      <Typography variant="caption" 
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 2.5,
                        }}
                      >
                        {item?.songTitle}
                      </Typography>
                    </Paper>
                  </TimelineContent> :
                  <TimelineContent sx={{mb: 4, px: 3}} >
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Sale
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        ${fCurrencyCents(item.amount)}
                      </Typography>
                  </TimelineContent>
                  }
                  {item?.status === 'subscription-charge' ?
                  <TimelineContent sx={{mb: 4, px: 3}} >
                    <Paper
                      sx={{
                        p: 3,
                        bgcolor: theme.palette.success.dark,
                      }}
                    >
                      <Typography variant="body2" 
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 2.5,
                        }}
                      >
                        {convertDate(item?.createdAt)}
                      </Typography>
                      <Typography variant="h3" 
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 1.5,
                        }}
                      >
                        Monthly Subscription Charge
                      </Typography>
                      <Typography variant="h3" 
                        sx={{ 
                          color: theme.palette.common.white,
                          mb: 1.5,
                        }}
                      >
                        ${fCurrencyCents(item.amount)}
                      </Typography>
                    </Paper>
                  </TimelineContent> :
                  <TimelineContent sx={{mb: 4, px: 3}} >
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Sale
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        ${fCurrencyCents(item.amount)}
                      </Typography>
                  </TimelineContent>
                  }
                </TimelineItem>
              ))}
            </Timeline>
          </Grid>
        </Grid>
      </Box>

      </Container>
      <Footer />
    </>
  );
}
