import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Stack,
  Divider,
  Checkbox,
  MenuItem,
  Typography,
  Grid,
  Button,
  Chip,
  TextField,
  Link,
  Switch,
} from '@mui/material';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CheckIcon from '@mui/icons-material/Check';

import { useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import axios from 'axios';
import { saveAs } from 'file-saver';


import { ReactComponent as LogoIcon } from '../../../../assets/icons/pikes.svg'
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import { TOGGLE_DOC_FAVORITE, ADD_DOC_TO_CART, PITCH_PASS, CREATE_PLAYLIST, TOGGLE_SONG_TO_PLAYLIST } from '../../../../graphql/mutations'

// components
import Iconify from '../../../../components-default/iconify';
import MenuPopover from '../../../../components-default/menu-popover';
import Markdown from '../../../../components-default/markdown/Markdown';
import { plusSideList } from '../../../../components-custom/plusSideList';
//

// ----------------------------------------------------------------------

AudioFileCardClient.propTypes = {
  sx: PropTypes.object,
  userId: PropTypes.string,
  user: PropTypes.object,
  file: PropTypes.object,
  data: PropTypes.object,
  filter: PropTypes.array,
  refetch: PropTypes.func,
  cartRefetch: PropTypes.func,
  dispatch: PropTypes.func,
  addToCart: PropTypes.func,
  getCartCount: PropTypes.func,
  onDelete: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setControlMessage: PropTypes.func,
  audioBarFavorite: PropTypes.bool,
  setAudioBarFavorite: PropTypes.func,
  favorites: PropTypes.bool,
  passable: PropTypes.bool,
  editable: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  setSelectedId: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
  briefId: PropTypes.string,
  toast: PropTypes.object,
  setToast: PropTypes.func,
  inventoryPage: PropTypes.bool,
  playlists: PropTypes.array,
  playlistRefetch: PropTypes.func,
  hideCartButton: PropTypes.bool,
  hidePlaylistButton: PropTypes.bool,
  hideTotalBuys: PropTypes.bool,
  getInventory: PropTypes.func,
};


export default function AudioFileCardClient({ userId, user, file, data, filter, refetch, cartRefetch, dispatch, addToCart, getCartCount, onDelete, sx, favorites, passable, editable, briefId, loadedSong, setLoadedSong, isPlaying, setIsPlaying, setControlMessage, audioBarFavorite, setAudioBarFavorite, index, setSelectedIndex, selectedIndex, selected, setSelectedId, toast, setToast, inventoryPage, hideCartButton, hideTotalBuys, getInventory, playlists, hidePlaylistButton, playlistRefetch, ...other }) {


  const isDesktop = useResponsive('up', 'sm');

  const [passWarning, setPassWarning] = useState(false);

  const [favorited, setFavorited] = useState(Boolean(file?.favorited ? file?.favorited?.includes(user?.organization) : false));
  const [inCart, setInCart] = useState(file.inCart?._id !== '' && file.inCart?._id === user?.organization);
  const [playButton, setPlayButton] = useState('play');
  const [pitchIndex, setPitchIndex] = useState(null);

  const [openShare, setOpenShare] = useState(false); // eslint-disable-line
  const [passRefetch, setPassRefetch] = useState(false); // eslint-disable-line
  const [openPopover, setOpenPopover] = useState(null); // eslint-disable-line
  const [playlistMenuState, setPlaylistMenuState] = useState('read'); // eslint-disable-line
  const [newPlaylistName, setNewPlaylistName] = useState(''); // eslint-disable-line
  const [showLyrics, setShowLyrics] = useState(false); // eslint-disable-line

  const [openDetails, setOpenDetails] = useState(false); // eslint-disable-line
  const [feedback, setFeedback] = useState('');
  const [feedbackType, setFeedbackType] = useState('pass');

  const theme = useTheme();

  const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);
  const [addDocToCart] = useMutation(ADD_DOC_TO_CART);
  const [createPlaylist] = useMutation(CREATE_PLAYLIST);
  const [toggleSongToPlaylist] = useMutation(TOGGLE_SONG_TO_PLAYLIST);

  const [artistBoughtCount, setArtistBoughtCount] = useState(0)

  // console.log(['USER: ', user])

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setNewPlaylistName('')
    setPlaylistMenuState('read')
    setOpenPopover(null);
  };

  async function createNewPlaylist() {
    await createPlaylist({ variables: { playlistName: newPlaylistName, orgId: user?.organization } });
    setNewPlaylistName('')
    setPlaylistMenuState('read')
    playlistRefetch();
  }

  async function toggleToPlaylist(playlistId, docId) {
    await toggleSongToPlaylist({ variables: { playlistId, docId } });
    playlistRefetch()
  }

  const handleFavorite = async () => {
    setAudioBarFavorite(!audioBarFavorite);
    await toggleFavorite({ variables: { docId: file._id, userId: user?.organization } });
    setFavorited(!favorited)
    refetch()
  };

  const handleAddDocToCart = async () => {
    await addDocToCart({
      variables: {
        docId: file._id,
        orgId: user?.organization
      }
    });
    if (setInCart) {
      setInCart(!inCart)
    }
    if (getCartCount) {
      getCartCount()
    }
    if (refetch) {
      refetch()
    }
    if (cartRefetch) {
      cartRefetch()
    }
  };

  async function sendFeedback() {
    console.log('Feedback')
    try {
      const randomNumber = Math.floor(Math.random() * plusSideList.length) + 1;

      const res = await axios.post(`${process.env.REACT_APP_SERVER}/addfeedback`, {  // eslint-disable-line
        docId: file._id,
        briefId: briefId ? briefId : file?.pitches[pitchIndex]?.briefId, // eslint-disable-line
        briefTitle: file?.pitches[pitchIndex]?.briefTitle, // eslint-disable-line
        plusSide: plusSideList[randomNumber],
        message: feedback,
        feedbackType,
        sender: {
          _id: userId,
          name: user.name,
          avatarUrl: user?.avatar
        }
      }).then(response => {
        console.log(response.data)
        setTimeout(() => {
          setFeedback('')
          if (refetch) {
            refetch()
          }
          if (getInventory) {
            getInventory();
          }
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${file.title} - Status: DENIED ☠️`, duration: 4000 })
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }

  async function queuePass() {
    // console.log('Feedback')
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/addtopassqueue`, {  // eslint-disable-line
        docId: file?._id,
        userId: file?.userId,
        briefId: briefId ? briefId : file?.pitches[pitchIndex]?.briefId, // eslint-disable-line
        briefTitle: file?.pitches[pitchIndex]?.briefTitle, // eslint-disable-line
        message: feedback,
        orgId: user?.organization,
        sender: {
          _id: userId,
          name: user.name,
          avatarUrl: user?.avatar
        },
        sendStatus: 'ready',
      }).then(response => {
        console.log(response.data)
        setTimeout(() => {
          setFeedback('')
          if (refetch) {
            refetch();
          }
          if (getInventory) {
            getInventory()
          }
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${file.title} passed`, duration: 4000 })
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }

  }


  async function unPassSong() {
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/unpasssong`, {  // eslint-disable-line
        docId: file?._id,
        userId: file?.userId,
        briefId: briefId ? briefId : file?.pitches[pitchIndex]?.briefId, // eslint-disable-line
        briefTitle: file?.pitches[pitchIndex]?.briefTitle, // eslint-disable-line
        message: feedback,
        orgId: user?.organization,
        sender: {
          _id: userId,
          name: user.name,
          avatarUrl: user?.avatar
        },
        sendStatus: 'ready',
      }).then(response => {
        console.log(response.data)
        setTimeout(() => {
          setFeedback('')
          if (refetch) {
            refetch();
          }
          if (getInventory) {
            getInventory()
          }
          setToast({ open: true, icon: <CheckIcon sx={{ color: 'white' }} />, horizontal: 'right', toastColor: theme.palette.success.dark, message: `${file.title} passed`, duration: 4000 })
        }, 1000)
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }

  }



  async function getArtistBoughtCount() {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'clientinventory',
        perPage: 10,
        modifier: 'artistboughtcount',
        artistName: file?.artist?.name,
        orgId: user?.organization,
        // sortMethod,
        // userId: '64d3c9649d24018f494a997c',
      }).then(response => {
        // console.log(response)
        setArtistBoughtCount(response?.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }
  }

  async function handleDownloadSave() {
    try {
      // Fetch the MP3 file from the URL
      const response = await fetch(file?.audioUrl);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Failed to fetch the MP3 file.');
      }

      // Get the response data as a Blob
      const mp3Blob = await response.blob();

      // Use file-saver to trigger the download with a specified filename
      saveAs(mp3Blob, `${file?.title} ROUGHMIX.mp3`);
    } catch (error) {
      console.error('Error downloading the MP3:', error);
    }
  }



  useEffect(() => {
    if (passRefetch === true) {
      setTimeout(() => { refetch() }, 1500)
    }
  }, [passRefetch]) // eslint-disable-line


  useEffect(() => {
    setFavorited(Boolean(file.favorited.includes(user?.organization)));
    setInCart(file.inCart?._id !== '' && file.inCart?._id === user?.organization)
  }, [data]) // eslint-disable-line

  useEffect(() => {
    file.pitches.map((pitch, indexx) => { // eslint-disable-line
      // console.log('PITCH: ', pitch)
      // console.log('BRIEF ID: ', pitch.briefId)
      if (pitch.briefId === briefId || inventoryPage) {
        // console.log('INDEX IS: ', indexx)
        return setPitchIndex(indexx)
      }
    })
    getArtistBoughtCount()
  }, [file]) // eslint-disable-line

  useEffect(() => {
    setPlayButton(isPlaying && file.audioUrl === loadedSong.audioUrl ? 'pause' : 'play');
  }, [isPlaying, loadedSong, favorited]) // eslint-disable-line  

  // console.log("FILE: ", file)

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          borderRadius: 2,
          position: 'relative',
          transition: 'opacity 0.5s ease-in-out, all 0.2s ease-in-out',
          opacity: passRefetch ? .1 : 1,
          ml: loadedSong?.audioUrl === file?.audioUrl ? 1 : 0,
          mr: loadedSong?.audioUrl === file?.audioUrl ? -1 : 0,
          minHeight: file?.pitches[pitchIndex]?.passQueue ? `85px` : '96px',
          height: file?.pitches[pitchIndex]?.passQueue ? `85px` : `inherit`,
          overflowY: 'hidden',
          backgroundColor: file?.pitches[pitchIndex]?.passQueue ? `${theme.palette.error.main}80` : `${theme.palette.background.paper}0`,
          boxShadow: loadedSong?.audioUrl === file?.audioUrl ? `-8px 8px 2px rgba(0, 0, 0, 0.1)` : theme.customShadows.z8,
          border: loadedSong?.audioUrl === file?.audioUrl ? // if playing

            `${!file?.pitches[pitchIndex]?.passQueue ?  // eslint-disable-line
              // if not passqueue
              (file?.pitches[pitchIndex]?.status === 'sold' ?
                // if sold
                theme.palette.success.main

                // if not sold
                : theme.palette.primary.main)

              // if passqueue
              : theme.palette.error.main} solid 2px` // eslint-disable-line

            // if not playing
            : `solid 1px ${file?.pitches[pitchIndex]?.passQueue ? theme.palette.error.main : theme.palette.info.lighter}40`, // eslint-disable-line
          '&:hover': {
            // bgcolor: passWarning ? `${theme.palette.text.primary}` : `inherit` ,
            boxShadow: loadedSong?.audioUrl === file?.audioUrl ? '-8px 8px 2px rgba(0, 0, 0, 0.1)' : theme.customShadows.z8,
          },
          ...(isDesktop && {
            // p: 1.5,
            borderRadius: 1.5,
          }),
          ...sx,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            height: '34px',
            width: '34px',
            // zIndex: 10,
            transition: 'all .5s ease-in-out',
            ml: (loadedSong?.audioUrl === file?.audioUrl && isPlaying) ? -9.5 : -20,
            opacity: (loadedSong?.audioUrl === file?.audioUrl && isPlaying) ? 1 : 0,
            mt: '45px',
            transform: 'rotateZ(43deg)',
            borderRadius: '50%',

          }}
        >
          <LogoIcon height='100%' />
        </Box>
        {((user?.role === 'admin') && file?.inCart?._id) &&
          <Box
            sx={{
              display: 'flex',
              position: 'absolute',
              height: '100%',
              width: '100%',
              justifyContent: 'flex-end',
              zIndex: 0,
              borderRadius: 1,
              border: `${theme.palette.success.dark} solid 6px`,
              // backgroundColor: theme.palette.success.dark,
              opacity: .5,


            }}
          >
            <Iconify icon='grommet-icons:cart' width='200px' sx={{ color: theme.palette.success.main, opacity: .5 }} />
          </Box>
        }
        <Stack
          spacing={isDesktop ? 1.5 : 2}
          direction={isDesktop ? 'row' : 'column'}
          alignItems={isDesktop ? 'center' : 'flex-start'}
          sx={{
            p: 2.5,
            borderRadius: 2,
            position: 'relative',
            marginBottom: '0px !important',
            // border: 'red solid 1px',
            width: '100%',
            ...(isDesktop && {
              marginBottom: '0px!important',
              p: 1.5,
              pb: 0,
              borderRadius: 1.5,
            }),
            ...sx,
          }}
          {...other}
        >
          <Grid container>

            <Grid item xs={8} sm={8} md={7} lg={7}
              order={{
                xs: 1,
                sm: 1,
              }}
              sx={{
                // PLAY BUTTON / ARTIST INFO
                width: '100%',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                pr: 3,
                // border: 'green solid 1px',
              }}
            >
              {!passWarning &&
                <Button
                  variant="text"
                  color={((file?.pitches[pitchIndex]?.status !== 'pass' && file?.pitches[pitchIndex]?.status !== 'sold') && !file?.pitches[pitchIndex]?.passQueue) ? "primary" : (file?.pitches[pitchIndex]?.status === 'sold' ? 'success' : 'error')} // eslint-disable-line
                  onClick={() => {
                    // setSelectedIndex(index)
                    // setSelectedId(file._id)
                    if (loadedSong !== file) {
                      setLoadedSong(file);
                      setIsPlaying(true);
                    } else {
                      setControlMessage('play-pause')
                      setIsPlaying(true)
                    }

                  }}
                  sx={{
                    flexGrow: 1,
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                    borderRadius: '25%',
                    p: 0,
                    m: 0,
                  }}
                >
                  {playButton === 'pause' ?
                    <PauseCircleIcon sx={{
                      width: '52px',
                      height: '52px',
                      m: 0,
                      opacity: .8,
                    }} /> :
                    <PlayCircleFilledRoundedIcon sx={{
                      width: '52px',
                      height: '52px',
                      opacity: .8,
                      m: 0,
                    }} />
                  }
                </Button>
              }

              <Box sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                width: '100%',
              }}>
                <Box
                  // song/artist name box
                  sx={{
                    width: '100%',
                    maxWidth: {
                      xs: '95%',
                      sm: '265px',
                      md: '265px',
                    },
                    pr: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    opacity: passWarning ? 0.2 : 1,
                    marginBottom: '0px !important',
                    justifyContent: 'center',
                  }}
                >
                  <Typography noWrap variant="subtitle2"
                    sx={{
                      textOverflow: 'ellipsis',
                      cursor: 'default',
                    }}
                  >
                    {file.title}
                  </Typography>
                  <Typography variant="caption" noWrap sx={{ cursor: 'default' }}
                  >
                    {file.artist.name}
                  </Typography>
                  {file.multitrackUrl === 'producer' &&
                    <Typography variant="caption" noWrap sx={{ cursor: 'default' }}
                      onClick={async () => {
                        try {
                          const response = await axios.get(file?.multitrackUrl, { responseType: 'stream' });

                        } catch (error) {
                          console.error(error);
                        }
                      }}
                    >
                      Download Multis
                    </Typography>
                  }

                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4} md={4} lg={4}
              order={{
                xs: 3,
                sm: 2,
              }}
              sx={{
                // ADD TO CART BUTTON SECTION
                width: '100%',
                height: '60px',
                display: !file?.pitches[pitchIndex]?.passQueue ? 'flex' : 'none',
                alignItems: 'center',
                justifyContent: {
                  sm: 'flex-end',
                  md: 'center',
                  lg: 'flex-end',
                },
                paddingTop: {
                  xs: 2,
                  sm: 'inherit',
                },
                // pr: 3,
                // border: 'green solid 1px',
              }}
            >
              {inCart && !hideCartButton &&
                <Iconify icon='grommet-icons:cart' width='35px' sx={{ color: theme.palette.primary.main, opacity: .5, mr: 3 }} />
              }

              <Stack direction='column' gap={1} width='100%'>
                <Divider
                  sx={{
                    width: '100%',
                    my: 3,
                    display: {
                      xs: 'inherit',
                      sm: 'none',
                    }
                  }}
                />
                {file?.pitches[pitchIndex]?.status !== 'pass' ?

                  <Button
                    variant={inCart ? "outlined" : "contained"}
                    // fullWidth
                    color={inCart ? "info" : "primary"}
                    onClick={handleAddDocToCart}
                    sx={{
                      p: 1,
                      mr: 2,
                      width: {
                        xs: '100%',
                        sm: '170px',
                      },
                      display: (!passWarning && !hideCartButton) ? 'inline-flex' : 'none',
                      '&:hover': {
                        backgroundColor: inCart ? theme.palette.error.main : theme.palette.text.primary,
                        color: theme.palette.common.white,
                      },
                    }}
                  >
                    <Typography variant="subtitle2" noWrap>
                      {inCart ? 'Remove from Cart' : 'Add to Cart'}
                    </Typography>
                  </Button> :
                  <Button
                    variant="contained"
                    // fullWidth
                    color="error"
                    disabled
                    onClick={() => {
                      console.log('restore')
                    }}
                    sx={{
                      // opacity: .2,
                      p: 1,
                      width: {
                        xs: '100%',
                        sm: '170px',
                      },
                      backgroundColor: theme.palette.error.dark,
                      display: !hideCartButton ? 'inline-flex' : 'none',
                      '&:hover': {
                        backgroundColor: theme.palette.error.dark,
                        color: theme.palette.common.white,
                      },
                    }}
                  >
                    <Typography variant="subtitle2" noWrap>
                      Passed
                    </Typography>
                  </Button>
                }
              </Stack>


            </Grid>


            <Grid item xs={4} sm={12} md={1} lg={1}
              order={{
                xs: 2,
                sm: 3,
              }}
              sx={{
                display: file?.pitches[pitchIndex]?.status !== 'pass' ? 'inherit' : 'none',
              }}
            >
              <Box
                sx={{
                  // LIKE PASS EDIT SECTION
                  display: !file?.pitches[pitchIndex]?.passQueue ? 'flex' : 'none',
                  justifyContent: {
                    xs: 'flex-end',
                    sm: 'flex-end',
                    md: 'flex-end',
                  },
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  mt: {
                    // xs: 2,
                    sm: 'inherit',
                  },
                  pr: 1,
                  // ml: 2,
                  // border: 'pink solid 1px',

                }}
              >
                <Box
                  sx={{
                    display: !hidePlaylistButton ? 'flex' : 'none',
                    alignContent: 'center',
                    justifyContent: 'center',
                    // border: 'red solid 1px',
                  }}
                >
                  <Iconify
                    // icon='bi:plus' 
                    icon='tabler:playlist-add'
                    width='28px'
                    sx={{
                      // border: 'blue solid 1px', 
                      height: '100%',
                      transition: 'all .2s ease-in-out',
                      pt: .3,
                      // ml: 2, 
                      color: theme.palette.info.main,
                      cursor: 'pointer',
                      '&:active': {
                        scale: '.9'
                      }
                    }}
                    onClick={(e) => {
                      handleOpenPopover(e)
                    }}
                  />
                  {favorites &&
                    <Checkbox
                      color="error"
                      icon={<Iconify icon="eva:heart-outline" />}
                      checkedIcon={<Iconify icon="eva:heart-fill" />}
                      checked={favorited}
                      onChange={handleFavorite}
                      sx={{
                        p: 0.75,
                        display: !passWarning ? 'inline-flex' : 'none',
                      }}
                    />
                  }
                </Box>

              </Box>

            </Grid>
          </Grid>
          <Box
                sx={{
                  // UNPASS
                  display: file?.pitches[pitchIndex]?.passQueue ? 'flex' : 'none',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  height: '100%',
                  width: '100%',
                  pr: 1,
                }}
              >
                <Button
                  variant='contained'
                  sx={{
                    px: 6,
                  }}
                  onClick={() => {
                    unPassSong()
                  }}
                >
                  UNPASS
                </Button>
              </Box>

        </Stack>
        <Box
          sx={{
            pl: 2.5,
            py: 0,
            mb: 1,
            display: !passWarning ? 'flex' : 'none',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...(isDesktop && {
              position: 'unset',
            }),
            overflow: 'hidden',
          }}
        >
          {(inventoryPage) &&
            <Stack direction='row' gap={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                // border: `${theme.palette.text.disabled} solid 1px`,
                borderRadius: 5,
                height: '20px',
                // mb: 1,
                // mr: 1,
              }}
            >
              <Link
                href={`/briefs/client/${file?.pitches[pitchIndex]?.briefId}`}
              >
                <Typography variant="subtitle2" noWrap sx={{ color: theme.palette.info.dark, cursor: 'pointer', py: 0, px: 1 }}>
                  Brief: {file?.pitches[pitchIndex]?.briefTitle}
                </Typography>
              </Link>
            </Stack>
          }
        </Box>
        <Box
          sx={{
            pl: 3,
            py: 0,
            my: 0,
            display: !passWarning ? 'flex' : 'none',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...(isDesktop && {
              position: 'unset',
            }),
            overflowX: 'hidden',
          }}
        >
          <Stack direction='row' gap={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `${theme.palette.text.disabled} solid 1px`,
              borderRadius: 5,
              height: '20px',
              mr: 1,
              my: 1,
            }}
          >
            <Typography variant="caption" noWrap sx={{ color: theme.palette.info.dark, cursor: 'default', py: 0, pl: 1 }}>
              {file?.bpm} bpm
            </Typography>
            <Typography variant="caption" noWrap sx={{ color: theme.palette.info.dark, cursor: 'default', py: 0, }}>
              |
            </Typography>
            <Typography variant="caption" noWrap sx={{ color: theme.palette.info.dark, cursor: 'default', py: 0, pr: 1 }}>
              {file?.key} {file?.scale}
            </Typography>
          </Stack>


          <Stack direction='row' gap={1}
            sx={{
              display: !hideTotalBuys ? 'flex' : 'none',
              alignItems: 'center',
              border: `${theme.palette.info.main} solid 1px`,
              borderRadius: 5,
              height: '20px',
              mr: 1,
            }}
          >
            <Typography variant="caption" noWrap sx={{ color: theme.palette.info.main, cursor: 'default', py: 0, px: 1 }}>
              Total Buys from this Artist: <b>{artistBoughtCount}</b>
            </Typography>
          </Stack>

          <Stack direction='row' gap={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: file?.multitrackUrl !== '' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
              borderRadius: 5,
              height: '20px',
              mr: 1,
            }}
          >
            <Typography variant="caption" noWrap sx={{ color: file?.multitrackUrl !== '' ? theme.palette.success.dark : theme.palette.text.disabled, cursor: 'default', py: 0, px: 1 }}>
              Multitracks
            </Typography>
          </Stack>
          <Stack direction='row' gap={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `${theme.palette.success.main} solid 1px`,
              borderRadius: 5,
              height: '20px',
              mr: 1,
            }}
          >
            <Typography variant="caption" noWrap
              sx={{
                color: theme.palette.success.dark,
                py: 0,
                px: 1,
                cursor: 'pointer',
              }}
              onClick={(e) => { handleDownloadSave() }}
            >
              Download Roughmix
            </Typography>
          </Stack>

        </Box>
        <Box
          sx={{
            pl: 3,
            py: 0,
            my: 0,
            mb: passable ? 0 : 1,
            display: !passWarning ? 'flex' : 'none',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...(isDesktop && {
              position: 'unset',
            }),
            overflowX: 'hidden',
          }}
        >
          {file?.tags?.genre !== undefined && file?.tags?.genre.map((tag) => tag !== '' && <Chip
            key={tag}
            label={tag}
            variant="filled"
            sx={{
              border: 'none',
              mb: 1,
              mt: 1,
              mr: 1,
              backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
              color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
            }}
            size="small"
          />
          )}
          {file?.tags?.mood !== undefined && file?.tags?.mood.map((tag) => tag !== '' && <Chip
            key={tag}
            label={tag}
            variant="filled"
            sx={{
              border: 'none',
              mb: 1,
              mt: 1,
              mr: 1,
              backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
              color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
            }}
            size="small"
          />
          )}
          {file?.tags?.instrument !== undefined && file?.tags?.instrument.map((tag) => tag !== '' && <Chip
            key={tag}
            label={tag}
            variant="filled"
            sx={{
              border: 'none',
              mb: 1,
              mt: 1,
              mr: 1,
              backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper,
              color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
            }}
            size="small"
          />
          )}
        </Box>
        <Stack
          direction='row'
          padding={3}
          sx={{
            display: (passWarning || file?.pitches[pitchIndex]?.status === 'pass' || !passable) ? 'none' : 'flex',
          }}
        >
          <TextField
            variant='outlined'
            placeholder={feedbackType === 'pass' ? 'Pass feedback' : 'Revise feedback'}
            sx={{ width: '100%' }}
            size='small'
            autoComplete='off'
            value={feedback}
            color='info'
            onChange={(e) => { setFeedback(e.target.value) }}
          />
          {feedbackType === 'pass' ?
            <Button
              variant='outlined'
              sx={{ width: '100px', ml: 2, }}
              onClick={() => { queuePass() }}
            >
              Pass
            </Button> :
            <Button
              variant='contained'
              color='primary'
              sx={{ width: '100px', ml: 2, }}
              onClick={() => { sendFeedback() }}
            >
              Revise
            </Button>
          }
          <Switch
            checked={feedbackType === 'revise'}
            onChange={() => {
              if (feedbackType === 'pass') {
                setFeedbackType('revise')
              } else {
                setFeedbackType('pass')
              }
            }}
            sx={{
              display: 'flex',
              // width: '20px',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          />
        </Stack>
        <Stack direction='row' width='100%' alignItems='center'
          display={file?.lyrics ? 'flex' : 'none'} marginBottom={2} marginLeft={2}
        >
          <Iconify icon={!showLyrics ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'}
            sx={{
              // mx:2,
              width: '34px',
              cursor: 'pointer',
              color: theme.palette.info.light,
            }}
            onClick={() => { setShowLyrics(!showLyrics) }}
          />
          <Typography variant='subtitle1'
            sx={{ width: '195px', minWidth: '195px', cursor: 'pointer', color: theme.palette.info.light }}
            onClick={() => {
              setShowLyrics(!showLyrics)
            }}
          >
            Lyrics
          </Typography>
        </Stack>
        {(file?.lyrics && showLyrics) &&
          <>
            <Divider sx={{ width: '100%', pt: 1, }} />
            <Stack
              direction='row'
              padding={3}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                ml: 2,
                transition: 'height .2s ease-in-out',
              }}
            >
              <Typography variant='caption'
                sx={{
                  color: theme.palette.info.main,
                  // opacity: .5,
                }}
              >
                <Markdown>
                  {file?.lyrics.replace(/\r\n/g, '<br>')}
                </Markdown>
              </Typography>
            </Stack>
          </>
        }
      </Box>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      // onClose={()=>{
      //   setOpenPopover(null)
      //   setPlaylistMenuState('read')
      // }}
      >
        {playlists && playlists.map(item => { // eslint-disable-line
          return <MenuItem
            key={item._id}
            value={item._id}
            onClick={() => {
              toggleToPlaylist(item?._id, file?._id)
            }}
            sx={{
              color: item?.songs.includes(file?._id) ? theme.palette.success.dark : theme.palette.text.primary,
              display: 'flex',
              justifyContent: 'space-between',
              // gap: 3,
            }}
          >
            {item.name}
            {item?.songs.includes(file?._id) &&
              <Iconify icon='ci:check' sx={{ color: theme.palette.success.dark }} />
            }
          </MenuItem>
        })}
        <Divider sx={{ width: '100%', color: theme.palette.divider }} />
        {playlistMenuState === 'read' ? <MenuItem
          value=''
          sx={{
            color: theme.palette.info.main,
          }}
          onClick={() => setPlaylistMenuState('new')}
        >
          New Playlist
        </MenuItem> :
          <>
            <TextField fullWidth autoComplete='off'
              size='small'
              sx={{
                pb: .5,
              }}
              placeholder='New Playlist'
              onChange={(e) => { setNewPlaylistName(e.target.value) }}
            />
            <Button variant='contained' fullWidth
              onClick={() => { createNewPlaylist() }}
            >
              Create
            </Button>
          </>
        }
      </MenuPopover>
    </>
  );
}
