import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Button, Container, Typography, DialogActions, Dialog, Divider, Stack } from '@mui/material';
// components
import Image from '../../../../components-default/image';
import Markdown from '../../../../components-default/markdown';
import Scrollbar from '../../../../components-default/scrollbar';
import EmptyContent from '../../../../components-default/empty-content';

import aaIcon from '../../../../assets/icons/pikes-white.svg'
import { fCurrencyCents } from '../../../../utils/formatNumber';
import { parseReferenceLink } from '../../../../utils/parse-ref-link';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

const StyledTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.h3,
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h2,
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const StyledFooter = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
  },
}));

BriefPreview.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  stripePrice: PropTypes.object,
  userOrg: PropTypes.string,
  isSubmitting: PropTypes.bool,
};

export default function BriefPreview({
  values,
  stripePrice,
  userOrg,
  isValid,
  isSubmitting,
  open,
  onClose,
  onSubmit,
}) {
  // const { title = '', content = '', description = '' } = values;

  const title = values.title || '';
  const content = values.content || '';
  const description = values.description || '';
  const category = values.category || '';

  const cover = typeof values.cover === 'string' ? values.cover : values.cover?.preview;

  const hasContent = title || description || content || cover;

  const hasHero = title || cover;

  function spotifyPlaylistId() {
    if (values?.spotifyLink.includes('/playlist')) {
      const spotifyTemp1 = values?.spotifyLink?.split('/playlist/')
      const spotifyTemp2 = spotifyTemp1[1].split('?')
      return spotifyTemp2[0]
    }

    return null
  }

  return (
    <Dialog fullScreen open={open} onClose={onClose}>
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Preview Post
        </Typography>

        <Button variant="outlined" color="inherit" onClick={onClose}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          // disabled={!isValid}
          loading={isSubmitting}
          onClick={onSubmit}
        >
          Post
        </LoadingButton>
      </DialogActions>

      <Divider />

      {hasContent ? (
        <Scrollbar>
          <Container sx={{ mt: 5, mb: 10, ml: 10, mr: 20 }}>
            {hasHero &&
              <PreviewHero
                title={title}
                cover={cover}
                category={category}
              />}

            <Typography variant="h6" sx={{ mt: 3, mb: 3 }}>
              {description}
            </Typography>

            <Divider sx={{ mb: 5 }} />
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                // gridTemplateColumns: 'repeat(1, minMax())',

                gap: 2,
              }}
            >
              <Stack gap={2}>
                <Markdown children={content} />
                <Box
                  sx={{
                    display: values?.youtubeLink ? 'inherit' : 'none',
                  }}
                >
                  <iframe
                    width="100%"
                    height={parseReferenceLink(values?.youtubeLink)?.height}
                    src={parseReferenceLink(values?.youtubeLink)?.link}
                    // src={`https://www.youtube.com/embed/videoseries?si=Gld-8t4gFgvkVg1U&amp;list=${youtubePlaylistId}`}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                  />
                </Box>

              </Stack>
              <Stack gap={2}>
                <Typography variant='body'>
                  {`Rate: $${fCurrencyCents(stripePrice?.amount)}`}
                </Typography>
                <Typography variant='body'>
                  {`Terms: ${userOrg?.terms}`}
                </Typography>
              </Stack>

              <Box
                sx={{
                  // border: 'red solid 1px',
                  width: '100%'
                }}
              >
                {values?.spotifyLink.includes('/playlist') &&
                  <iframe style={{ borderRadius: "12px" }} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId()}?utm_source=generator&theme=0`} width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='AudioArsenal' />
                }
              </Box>
            </Box>
          </Container>
        </Scrollbar>
      ) : (
        <EmptyContent title="Empty content" />
      )}
    </Dialog>
  );
}

// ----------------------------------------------------------------------

PreviewHero.propTypes = {
  cover: PropTypes.string,
  title: PropTypes.string,
  category: PropTypes.string,
};

function PreviewHero({ title, cover, category }) {

  const theme = useTheme();

  return (
    <Box
      sx={{
        overflow: 'hidden',
        position: 'relative',
        borderRadius: {
          xs: `16px 16px 16px 16px`,
          md: `16px 16px 0 0`,
        },
        height: '300px',
      }}
    >
      <StyledTitle>{title}</StyledTitle>

      <StyledFooter>
        <Box sx={{ display: 'flex', alignItems: 'flex-end', width: '100%', }}>
          <Image
            alt="icon"
            src={aaIcon}
            sx={{
              width: 48,
              height: 48,
              p: '5px',
              borderRadius: '50%',
              border: 'white solid 1px',
              backgroundColor: `${theme.palette.grey[900]}80`,
            }}
          />
          <Box sx={{ ml: 2, cursor: 'default', width: '100%', display: 'flex', justifyContent: 'space-between', }}>
            <Box sx={{ width: '100%', }}>

              <Typography
                variant='subtitle2'
                sx={{ color: 'common.white', pb: 0 }}
              >
                {process.env.REACT_APP_SITE_NAME}
              </Typography>

              <Typography
                variant='body2'
                sx={{
                  // py: 1,
                  // px: { md: 5 },
                  cursor: 'default',
                  color: 'common.white',
                }}
              >
                <b>Genre:</b> <i>{category}</i>
              </Typography>
            </Box>
            <Typography
              variant='caption'
              sx={{ color: 'common.white', width: '100px', display: 'flex', alignItems: 'flex-end' }}
            >
              Now
            </Typography>
          </Box>
        </Box>

      </StyledFooter>


      <StyledOverlay />

      <Image
        alt="cover"
        src={cover}
        // src='/assets/images/test/camouflage.jpg' 
        ratio="16/9" />
    </Box>
  );
}
