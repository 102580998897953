import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

// @mui
import { useTheme } from '@mui/material/styles';

import { Button, Container, Typography, DialogActions, Dialog, Divider, Stack, Grid, Switch } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LoadingIcons from 'react-loading-icons'
import axios from '../utils/axios';

// components
import Scrollbar from '../components-default/scrollbar';

import isAA from './isAA';
import Iconify from '../components-default/iconify';
import Image from './image';

// ----------------------------------------------------------------------

ChangePitchDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  setChangePitchDialogOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  songInfo: PropTypes.object,
  isSubmitting: PropTypes.bool,
  transferDoc: PropTypes.object,
  setTransferDoc: PropTypes.func,
  setToast: PropTypes.func,
  setUpdateTrigger: PropTypes.func,
};

export default function ChangePitchDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  setChangePitchDialogOpen,
  songInfo,
  onSubmit,
  transferDoc,
  setTransferDoc,
  setToast,
  setUpdateTrigger
}) {

  const theme = useTheme()

  const [displayBriefs, setDisplayBriefs]=useState([])
  const [briefsLoading, setBriefsLoading]=useState(false)
  const [pitchBrief, setPitchBrief]=useState(null)
  const [pitchStatus, setPitchStatus]=useState('submitted')

  async function getAABriefs(){
    try {
      setBriefsLoading(true)
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getAAbriefs`, {  // eslint-disable-line
      }).then(response => {
        console.log(response?.data)
        setDisplayBriefs(response?.data?.briefData?.briefs)
        setBriefsLoading(false)
      });
    } catch (err) {
      console.log(err)
    }   
  }

  async function changePitch(){
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/changedocpitch`, {  // eslint-disable-line
        originalDoc: transferDoc,
        brief: pitchBrief,
        pitchStatus
      }).then(response => {
        if (response.status === 200){
          setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Brief successfully changed to ${pitchBrief?.post?.title}`, duration: 4000})
          setPitchBrief(null)
          onClose()
          setUpdateTrigger(true)
      }
      });
    } catch (err) {
      console.log(err)
    }   
  }

  useEffect(()=>{
    if (open){
      getAABriefs()
    }

  }, [open])

  return (
    <Dialog fullScreen open={open} onClose={onClose} 
      sx={{
        overflowY: 'scroll',
      }}
      >
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Audio Arsenal Briefs
        </Typography>

        <Button variant="outlined" color="inherit" 
          onClick={()=>{
            setPitchBrief(null)
            onClose()
          }}
        >
          Close
        </Button>
      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 5, 
              mb: 10, 
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Stack direction='row' spacing={2} sx={{
                width: '100%',
                justifyContent: 'space-between',
                alignItems: 'center',
                p: 2,
                borderRadius: 2,
                border: 'white solid 1px',
                mb: 2,
              }}>
                <Stack direction='row' spacing={2}>
                  <Image src={transferDoc?.artist?.artwork} 
                    sx={{
                      width: '70px',
                      borderRadius: 2,
                    }}
                  />
                    <Stack spacing={1}>
                      <Typography variant='subtitle1'>
                        {transferDoc?.title}
                      </Typography>
                      <Typography variant='caption'>
                        {transferDoc?.artist?.name}
                      </Typography>
                    </Stack>

                </Stack>
                {pitchBrief !== null ?
                <Stack direction='row' spacing={1} 
                  sx={{
                    height: '100px',
                    width: '300px',
                    p: 2,
                    justifyContent: 'space-between',
                    borderRadius: 2,
                    border: 'white solid 1px',
                    backgroundColor: theme.palette.primary.dark,
                    cursor: 'default',
                  }}
                >
                  <Stack spacing={1}
                    sx={{
                      color: theme.palette.common.white,
                    }}
                  >

                    <Typography variant='subtitle1' width='100px'>
                      {pitchBrief?.post?.title}
                    </Typography>
                    <Typography variant='caption'>
                      {pitchBrief?.post?.category}
                    </Typography>
                  </Stack>
                  <Image src={pitchBrief?.post?.cover} 
                      sx={{
                        borderRadius: 1,
                        height: '100%',
                      }}
                  />
                  <Iconify icon='zondicons:close-solid' 
                    sx={{
                      color: theme.palette.common.white,
                      // width: '50px',
                      cursor: 'pointer',
                    }}
                    onClick={()=>{
                      setPitchBrief(null)
                    }}
                  />

                </Stack> :
                <Stack direction='row' spacing={1}a
                  sx={{
                    height: '100px',
                    width: '300px',
                    px: 2,
                    borderRadius: 2,
                    border: 'white solid 1px',
                    backgroundColor: theme.palette.info.light,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                <Typography variant='h5'
                  sx={{
                    color: theme.palette.common.white,
                  }}
                >
                  Select Brief
                </Typography>
                </Stack>
                }
                <Stack direction='row' spacing={1} alignItems='center'>
                  <Typography variant='caption'
                    sx={{
                      px: 2,
                      color: pitchStatus ==='submitted' ? theme.palette.info.main : theme.palette.text.disabled,
                      borderRadius: 2,
                      border: pitchStatus ==='submitted' ? `${theme.palette.info.main} solid 1px` : 'none',

                    }}
                  >
                    Submitted
                  </Typography>
                  <Switch
                    checked={pitchStatus === 'pitched'}
                    onChange={()=>{
                      if (pitchStatus === 'pitched'){
                        setPitchStatus('submitted')
                      } else {
                        setPitchStatus('pitched')
                      }
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  />

                  <Typography variant='caption'
                    sx={{
                      px: 2,
                      color: pitchStatus ==='pitched' ? theme.palette.primary.dark : theme.palette.text.disabled,
                      borderRadius: 2,
                      border: pitchStatus ==='pitched' ? `${theme.palette.primary.dark} solid 1px` : 'none',
                    }}
                  >
                    Pitched
                  </Typography>
                </Stack>
                <Button variant='contained' size='large'
                  disabled={pitchBrief === null}
                  onClick={()=>{
                    changePitch()
                  }}
                >
                    Set Pitch
                </Button>

              </Stack>
              <Grid container spacing={3} 
                sx={{
                  pb: '50px',
                  
                }}
              >

              {!briefsLoading ? displayBriefs.map((item, index) => { // eslint-disable-line
                return <Grid item key={item._id}
                xs={4}
                >
                  <Stack sx={{
                      height: '100px',
                      borderRadius: 2,
                      border: pitchBrief?._id === item?._id ? `${theme.palette.primary.dark} solid 3px` : 'white solid 1px',
                      // backgroundColor: pitchBrief?._id === item?._id ? theme.palette.primary.dark : 'inherit',
                      p: 1,
                      cursor: 'pointer',
                    }}
                    onClick={()=>{
                      setPitchBrief(item)
                    }}
                  >
                    <Image src={item.post.cover} 
                      sx={{
                        borderRadius: 1,
                      }}
                    />
                    <Stack direction='row'
                      sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '100%',
                        pt: 1,
                      }}
                    >
                      <Typography variant='subtitle1'>
                        {item?.post?.title}
                      </Typography>
                      <Typography variant='body'>
                        {item?.post?.category}
                      </Typography>
                      <Typography variant='caption'>
                        {item?.post?.subGenres || item?.post?.multiGenres[0]}
                      </Typography>

                    </Stack>
                  </Stack>
                </Grid>
              }) :
              <LoadingIcons.TailSpin stroke='white' width='100%'/>
            }

              </Grid>
          </Container>
        </Scrollbar>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

