import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Stack,
  Divider,
  Checkbox,
  MenuItem,
  Typography,
  Grid,
  Button,
  Chip,
  TextField,
  Link,
} from '@mui/material';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import EditIcon from '@mui/icons-material/Edit';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CheckIcon from '@mui/icons-material/Check';

import { alpha, useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import axios from 'axios';

import { ReactComponent as LogoIcon} from '../../../../assets/icons/pikes.svg'
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import { TOGGLE_DOC_FAVORITE, TOGGLE_DOC_PASS, ADD_DOC_TO_CART, PITCH_PASS } from '../../../../graphql/mutations'

// components
import Iconify from '../../../../components-default/iconify';
import MenuPopover from '../../../../components-default/menu-popover';
//

// ----------------------------------------------------------------------

AudioFileCardCart.propTypes = {
  sx: PropTypes.object,
  userId: PropTypes.string,
  user: PropTypes.object,
  file: PropTypes.object,
  data: PropTypes.object,
  refetch: PropTypes.func,
  dispatch: PropTypes.func,
  addToCart: PropTypes.func,
  onDelete: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setControlMessage: PropTypes.func,
  audioBarFavorite: PropTypes.bool,
  setAudioBarFavorite: PropTypes.func,
  favorites: PropTypes.bool,
  passable: PropTypes.bool,
  editable: PropTypes.bool,
  setSelectedIndex: PropTypes.func,
  selectedIndex: PropTypes.number,
  setSelectedId: PropTypes.func,
  selected: PropTypes.bool,
  index: PropTypes.number,
  briefId: PropTypes.string,
  toast: PropTypes.object,
  setToast: PropTypes.func,
  inventoryPage: PropTypes.bool,
};


export default function AudioFileCardCart({ userId, user, file, data, refetch, dispatch, addToCart, onDelete, sx, favorites, passable, editable, briefId, loadedSong, setLoadedSong, isPlaying, setIsPlaying, setControlMessage, audioBarFavorite, setAudioBarFavorite, index, setSelectedIndex, selectedIndex, selected, setSelectedId, toast, setToast, inventoryPage, ...other }) {


  const isDesktop = useResponsive('up', 'sm');


  const [openPassPopover, setOpenPassPopover] = useState(null);
  const [passWarning, setPassWarning] = useState(false);

  const [favorited, setFavorited] = useState(Boolean(file.favorited.includes(user?.organization)));
  const [playButton, setPlayButton] = useState('play');
  const [pitchIndex, setPitchIndex] = useState(null);

  const [openShare, setOpenShare] = useState(false); // eslint-disable-line
  const [passRefetch, setPassRefetch] = useState(false); // eslint-disable-line

  const [openDetails, setOpenDetails] = useState(false); // eslint-disable-line
  const [feedback, setFeedback] = useState(''); 

  const theme = useTheme();



  const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);
  const [toggleDocPass] = useMutation(TOGGLE_DOC_PASS);
  const [pitchPass] = useMutation(PITCH_PASS);
  const [addDocToCart] = useMutation(ADD_DOC_TO_CART);

  const handleFavorite = async () => {
    setAudioBarFavorite(!audioBarFavorite);
    await toggleFavorite({ variables: { docId: file._id, userId: user?.organization} });
    refetch()
  };

  const handlePass = async () => {
    // await toggleDocPass({ variables: { docId: file._id, userId} });
    await pitchPass({ variables: { docId: file._id, briefId, userId} });
    setPassRefetch(true)
    setLoadedSong({album: '', artist: {name: '', artwork: '', bio: ''}, title: '', favorited: [], tags: []});
    setPassWarning(false);
    // setTimeout(()=>{refetch()}, 1500)
    // refetch();
    setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `You passed on ${file.name}`, duration: 4000})
  };

  const handleAddDocToCart = async () => {
    const res = await addDocToCart({ 
      variables: { 
        docId: file._id, 
        orgId: user?.organization
      } 
    });
    refetch()
  };

  const handleOpenShare = () => {
    setOpenShare(true);
  };


  const handleClosePassPopover = () => {
    setOpenPassPopover(null);
  };



  useEffect(() => {
    file.pitches.map((pitch, indexx )=> { // eslint-disable-line
      // console.log('PITCH: ', pitch)
      // console.log('BRIEF ID: ', pitch.briefId)
      if (pitch.briefId === briefId || inventoryPage) {
        // console.log('INDEX IS: ', indexx)
        return setPitchIndex(indexx)
      }
    })
  },[file]) // eslint-disable-line
  
  useEffect(() => {
    setPlayButton(isPlaying && file.audioUrl === loadedSong.audioUrl ? 'pause' : 'play');
  },[isPlaying, loadedSong, favorited]) // eslint-disable-line  

  useEffect(() => {
    if (selectedIndex === index) {
      setLoadedSong(file);
      // setIsPlaying(true);
      setSelectedId(file._id)
    }
  },[selectedIndex, isPlaying]) // eslint-disable-line
  
  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        position: 'relative',
        transition: 'opacity 0.5s ease-in-out, all 0.2s ease-in-out',
        opacity: passRefetch ? .1 : 1,
        ml: selectedIndex === index ? 1 : 0,
        mr: selectedIndex === index ? -1 : 0,
        pb: 2,
        minHeight: '96px',
        backgroundColor: passWarning ? `${theme.palette.error.main}` : `${theme.palette.background.paper}0`,
        boxShadow: selectedIndex === index ? `-8px 8px 2px rgba(0, 0, 0, 0.1)` : theme.customShadows.z8,
        border: selectedIndex === index ? `${theme.palette.primary.main} solid 2px` : (file?.pitches[pitchIndex]?.status === 'pass' ? `${theme.palette.error.dark} solid 2px`: `solid 1px ${theme.palette.info.lighter}40`), // eslint-disable-line
        '&:hover': {
          bgcolor: passWarning ? `${theme.palette.text.primary}` : `inherit` ,
          boxShadow: selectedIndex === index ? '-8px 8px 2px rgba(0, 0, 0, 0.1)' : theme.customShadows.z8,
        },
        ...(isDesktop && {
          // p: 1.5,
          borderRadius: 1.5,
        }),
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: '34px',
          width: '34px',
          // zIndex: 10,
          transition: 'all .5s ease-in-out',
          ml: (selectedIndex === index && isPlaying) ? -9.5 : -20,
          opacity: (selectedIndex === index && isPlaying) ? 1 : 0,
          mt: '45px',
          transform: 'rotateZ(43deg)',
          borderRadius: '50%',
          
        }}
        >
        <LogoIcon height='100%'/>
      </Box>
      <Stack
        spacing={isDesktop ? 1.5 : 2}
        direction={isDesktop ? 'row' : 'column'}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        sx={{
          p: 2.5,
          borderRadius: 2,
          position: 'relative',
          marginBottom: '0px !important',
          // border: 'red solid 1px',
          width: '100%',
          ...(isDesktop && {
            marginBottom: '0px!important',
            p: 1.5,
            pb: 0,
            borderRadius: 1.5,
          }),
          ...sx,
        }}
        {...other}
      >
        <Grid container>

          <Grid item xs={8} sm={8} md={7} lg={7} 
            order={{
              xs: 1,
              sm: 1,
            }}
            sx={{
              // PLAY BUTTON / ARTIST INFO
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              pr: 3,
              // border: 'green solid 1px',
            }}
          >
            <Button 
              variant="text" 
              color={file?.pitches[pitchIndex]?.status !== 'pass' ? "primary" : 'info' }
              onClick={() => {
                setSelectedIndex(index)
                setSelectedId(file._id)
                if (loadedSong !== file) {
                  setLoadedSong(file);
                  setIsPlaying(true);
                } else {
                  setControlMessage('play-pause')
                  setIsPlaying(true)
                }
              
              }}
              sx={{
                flexGrow: 1,
                alignItems: 'flex-start',
                justifyContent:'center',
                borderRadius: '25%',
                p: 0,
                m: 0,
              }}
            >
            {playButton === 'pause' ? 
              <PauseCircleIcon sx={{
                width: '52px',
                height: '52px',
                m: 0,
                opacity: .8,
              }}/> :
              <PlayCircleFilledRoundedIcon sx={{
                width: '52px',
                height: '52px',
                opacity: .8,
                m: 0,
              }}/> 
              }
            </Button>

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              width: '100%',
            }}>
            <Box
            // song/artist name box
              sx={{
                width: '100%',
                maxWidth: {
                  xs: '95%',
                  sm: '265px',
                  md: '265px',
                },
                pr:3,
                display: 'flex',
                flexDirection: 'column',
                opacity: passWarning ? 0.2 : 1,
                marginBottom: '0px !important',
                justifyContent: 'center',
              }}
            >
              <Typography noWrap variant="subtitle2" noWrap 
                sx={{
                  textOverflow: 'ellipsis',
                  cursor: 'default',
                }}
                >
                {file.title}
              </Typography>
              <Typography variant="caption" noWrap sx={{cursor: 'default'}}
              >
                {file.artist.name}
              </Typography>
              {file.multitrackUrl === 'producer' &&
                <Typography variant="caption" noWrap sx={{cursor: 'default'}}
                  onClick={async ()=>{
                    try {
                      const response = await axios.get(file.multitrackUrl, { responseType: 'stream' });
                      
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Download Multis
                </Typography>
              }
            </Box>
              </Box>
          </Grid>

          <Grid item xs={12} sm={5} md={5} lg={5} 
            order={{
              xs: 3,
              sm: 2,
            }}
            sx={{
              // ADD TO CART BUTTON SECTION
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: {
                sm: 'flex-end',
                md: 'center',
                lg: 'flex-end',
              },
              // pr: 3,
              // border: 'green solid 1px',
            }}
          >
            {file?.inCart?._id &&
              <Iconify icon='grommet-icons:cart' width='35px' sx={{color: theme.palette.primary.main, opacity: .5, mr: 3}}/>
            }
            <Button 
              variant={file.inCart?._id === user?.organization ? "outlined" : "contained"}
              // fullWidth
              color={file.inCart?._id === user?.organization ? "info" : "primary"} 
              onClick={handleAddDocToCart} 
              sx={{
                // mr: 4,
                p: 1,
                width: {
                  xs: '100%',
                  sm: '170px',
              },
                display: !passWarning ? 'inline-flex' : 'none',
                '&:hover': {
                    backgroundColor: file.inCart?._id === user?.organization ? theme.palette.error.main : theme.palette.text.primary, 
                    color: theme.palette.common.white,
                  },
              }}
            >
              <Typography variant="subtitle2" noWrap>
                {file.inCart?._id !== '' && file.inCart?._id === user?.organization ? 'Remove from Cart' : 'Add to Cart'}
              </Typography>
            </Button> 
            
          </Grid>
        </Grid>
      </Stack>
      <Box
        sx={{
          pl: 2.5,
          py: 0,
          mb: 1,
          display: !passWarning ? 'flex' : 'none',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isDesktop && {
            position: 'unset',
          }),
          overflow: 'hidden',
        }}
      >
          {(inventoryPage) &&
            <Stack direction='row' gap={0}
              sx={{
                display: 'flex',
                alignItems: 'center',
                // border: `${theme.palette.text.disabled} solid 1px`,
                borderRadius: 5,
                height: '20px',
                // mb: 1,
                // mr: 1,
              }}
            >
              <Link
                href={`/briefs/client/${file?.pitches[pitchIndex]?.briefId}`}
              >
                <Typography variant="subtitle2" noWrap sx={{color: theme.palette.info.dark, cursor: 'pointer', py: 0, px: 1}}>
                  Brief: {file?.pitches[pitchIndex]?.briefTitle}
                </Typography>
              </Link>
            </Stack>
          }
      </Box>
      <Box
        sx={{
          pl: 3,
          py: 0,
          my: 0,
          display: !passWarning ? 'flex' : 'none',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isDesktop && {
            position: 'unset',
          }),
          overflowX: 'hidden',
        }}
      >
        <Stack direction='row' gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `${theme.palette.text.disabled} solid 1px`,
            borderRadius: 5,
            height: '20px',
            mr: 1,
          }}
        >
          <Typography variant="caption" noWrap sx={{color: theme.palette.info.dark, cursor: 'default', py: 0, pl: 1}}>
            {file?.bpm} bpm
          </Typography>
          <Typography variant="caption" noWrap sx={{color: theme.palette.info.dark, cursor: 'default', py: 0, }}>
            |
          </Typography>
          <Typography variant="caption" noWrap sx={{color: theme.palette.info.dark, cursor: 'default', py: 0, pr: 1}}>
            {file?.key} {file?.scale}
          </Typography>
        </Stack>


        <Stack direction='row' gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: file?.cleared === 'cleared' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
            borderRadius: 5,
            height: '20px',
            mr: 1,
          }}
        >
          <Typography variant="caption" noWrap sx={{color: file?.cleared === 'cleared' ? theme.palette.success.dark : theme.palette.text.disabled, cursor: 'default', py: 0, px: 1}}>
            Cleared
          </Typography>
        </Stack>
        <Stack direction='row' gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: file?.legalDocs === 'signed' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
            borderRadius: 5,
            height: '20px',
            mr: 1,
          }}
        >
          <Typography variant="caption" noWrap sx={{color: file?.legalDocs === 'signed' ? theme.palette.success.dark : theme.palette.text.disabled, cursor: 'default', py: 0, px: 1}}>
            Legal
          </Typography>
        </Stack>
        <Stack direction='row' gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: file?.multitrackUrl !== '' ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
            borderRadius: 5,
            height: '20px',
            mr: 1,
          }}
        >
          <Typography variant="caption" noWrap sx={{color: file?.multitrackUrl !== '' ? theme.palette.success.dark : theme.palette.text.disabled, cursor: 'default', py: 0, px: 1}}>
            Multitracks
          </Typography>
        </Stack>
      </Box>
      <Box
          sx={{
            pl: 3,
            py: 0,
            my: 0,
            display: !passWarning ? 'flex' : 'none',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...(isDesktop && {
              position: 'unset',
            }),
            overflowX: 'hidden',
          }}
        >
          {file?.tags?.genre !== undefined && file?.tags?.genre.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: theme.palette.background.paper, 
                  color: theme.palette.info.dark,
                }}
                size="small"
                />
          )}
          {file?.tags?.mood !== undefined && file?.tags?.mood.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: theme.palette.background.paper, 
                  color: theme.palette.info.dark,
                }}
                size="small"
                />
          )}
          {file?.tags?.instrument !== undefined && file?.tags?.instrument.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: theme.palette.background.paper, 
                  color: theme.palette.info.dark,
                }}
                size="small"
                />
          )}
          {file?.tags?.theme !== undefined && file?.tags?.theme.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: theme.palette.background.paper, 
                  color: theme.palette.info.dark,
                }}
                size="small"
                />
          )}
        </Box>
    </Box>
    </>
  );
}
