import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, TextField, InputAdornment, IconButton, Divider } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';

// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';

// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import isAA from '../components-custom/isAA';

// ----------------------------------------------------------------------

export default function ClientCategoriesPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id


  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);


  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]); // eslint-disable-line

  const [axiosResults, setAxiosResults] = useState('')
  const [showAddSubGenre, setShowAddSubGenre] = useState(false)
  const [showAddGenre, setShowAddGenre] = useState(false)
  const [editFieldTarget, setEditFieldTarget] = useState(null)
  const [editFieldValue, setEditFieldValue] = useState('')
  const [deleteWarning, setDeleteWarning] = useState(null)

  const [categories, setCategories] = useState(
    [
      {
        genre: 'Ambient',
        subCategories: [
          {
            subType: 'Focus',
            quantity: 50,
            fulfilled: 7
          },
          {
            subType: 'Spa',
            quantity: 20,
            fulfilled: 3
          },
        ]
      },
      {
        genre: 'Cinematic',
        subCategories: [
          {
            subType: 'Epic Score',
            quantity: 35,
            fulfilled: 11
          },
          {
            subType: 'Emotional Score',
            quantity: 10,
            fulfilled: 6
          },
        ]
      },
    ]
  )

  const addGenre =  {
    genre: 'New Genre',
    subCategories: [
      {
        subType: 'Main',
        quantity: 0,
        fulfilled: 0,
      },
    ]
  }

  const addSubGenre =  {
        subType: 'New Subgenre',
        quantity: 0,
        fulfilled: 0
  }


  const [selectedId, setSelectedId] = useState(null); // eslint-disable-line
  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});



  async function getCategories(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/orgcategories`, {  // eslint-disable-line
        orgId: user?.organization,
        type: 'get',
        platform: isAA() ? 'AA' : 'TB',
      }).then(response => {
        console.log(response)
        setCategories(response.data.categories)
      });
    } catch (err) {
      console.log(err)
    }              
  }

  async function saveCategories(){
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/orgcategories`, {  // eslint-disable-line
        orgId: user?.organization,
        type: 'save',
        categories,
        platform: isAA() ? 'AA' : 'TB',
      }).then(response => {
        // console.log(response)
        if (response.status===200){
          setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Categories saved`, duration: 4000})
        }
      });
    } catch (err) {
      console.log(err)
    }              
  }


  useEffect(() => {
    getCategories()
  },[]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== ''){
      // yes
    }
  },[axiosResults]) // eslint-disable-line


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Categories</title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dashboard',
              },
              { name: 'Categories' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              // justifyContent: 'flex-start',
              alignItems: 'center',
              width: '100%',
              pb: 8,
              // border: 'red solid 1px',
              
            }}
          >
          {categories.map((item, itemIndex) => { // eslint-disable-line
            return <Stack direction='column' justifyContent='flex-start' key={`item-${itemIndex}`}
              sx={{
                // border: 'blue solid 1px',
                // width: '600px',
                pb: 6,
                borderRadius: 1,
                backgroundColor: deleteWarning === item.genre ? theme.palette.error.light : 'inherit',
              }}
              >
                <Stack direction='row' spacing={2} alignItems='center' justifyContent='space-between' width='100%'
                  sx={{
                    pb: 2,
                  }}
                >
                  <Stack direction='row' spacing={1} alignItems='center' >
                    <Typography variant='h5' 
                      sx={{
                        display: editFieldTarget !== item.genre ? 'inherit' : 'none',
                      }}
                    >
                      {item.genre}
                    </Typography>
                    <TextField value={editFieldValue} size='large' variant='standard' autoComplete='off'
                        sx={{
                          display: editFieldTarget === item.genre ? 'inherit' : 'none',
                          width: '150px',
                        }}
                        onChange={(e)=>{
                          setEditFieldValue(e.target.value)
                        }}
                        onKeyDown={(e)=>{
                          if (e.key === 'Enter'){
                            if (categories[itemIndex].genre === editFieldValue){
                              setEditFieldTarget(null)
                              setEditFieldValue('')
                            } else {
                            const newCategories = [...categories]
                            newCategories[itemIndex].genre = editFieldValue
                            setCategories(newCategories)
                            }
                          }
                          if (e.key === 'Escape'){
                            setEditFieldTarget(null)
                            setEditFieldValue('')
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <>
                              <InputAdornment position="end" 
                                onClick={()=>{
                                  if (categories[itemIndex].genre === editFieldValue){
                                    setEditFieldTarget(null)
                                    setEditFieldValue('')
                                  } else {
                                    const newCategories = [...categories]
                                    newCategories[itemIndex].genre = editFieldValue
                                    setCategories(newCategories)
                                  }
                                }}
                                sx={{cursor: 'pointer'}}
                              >
                                <Iconify icon='iconamoon:check-duotone'/>
                              </InputAdornment>

                              <InputAdornment position="end" 
                                onClick={()=>{
                                  setEditFieldTarget(null)
                                  setEditFieldValue('')
                              }}
                                sx={{cursor: 'pointer'}}
                              >
                                <Iconify icon='iconamoon:close'/>
                              </InputAdornment>
                            </>
                          ),
                        }}
                    />

                    <Iconify icon='material-symbols:edit-outline' 
                      sx={{
                        display: (editFieldTarget === item.genre || deleteWarning === `item.genre`) ? 'none' : 'inherit',
                        cursor: 'pointer',
                        transition: 'all .2s ease-in-out',
                        opacity: .1,
                        '&:hover':{
                          opacity: 1,
                        }
                      }}
                      onClick={()=>{
                        setEditFieldTarget(item.genre)
                        setEditFieldValue(item.genre)
                      }}
                    />
                  </Stack>
                  <Iconify icon='ic:round-delete' 
                    sx={{
                      display: deleteWarning === `item.genre` ? 'none' : 'inherit',
                      cursor: 'pointer',
                      transition: 'all .2s ease-in-out',
                      opacity: .2,
                      '&:hover':{
                        opacity: 1,
                      }
                    }}
                    onClick={()=>{
                      setDeleteWarning(item.genre)
                    }}
                  />
                </Stack>
                {item.subCategories.map((sub, subIndex) =>{ //eslint-disable-line
                  return <Stack direction='row' spacing={4} key={`sub-${subIndex}`}
                  sx={{
                    alignItems: 'center',
                    pb: 2,
                    pl: 4,
                    pt: deleteWarning === `${item.genre}-${sub.subType}-${subIndex}` ? 1 : 'inherit',
                    borderRadius: 1,
                    backgroundColor: deleteWarning === `${item.genre}-${sub.subType}-${subIndex}` ? theme.palette.error.main : 'inherit',
                  }}>
                    <Stack direction='row' justifyContent='space-between' alignItems='center'>
                      <Typography variant='subtitle1' 
                        sx={{
                          display: editFieldTarget === `${item.genre}-${sub.subType}-${subIndex}` ? 'none' : 'inherit',
                          width: '150px',
                          color: deleteWarning === `${item.genre}-${sub.subType}-${subIndex}` ? theme.palette.common.white : 'inherit',
                          cursor: 'default',
                        }}
                        >
                        {sub.subType}
                      </Typography>
                      <TextField value={editFieldValue} size='small' variant='standard' autoComplete='off'
                          sx={{
                            display: editFieldTarget === `${item.genre}-${sub.subType}-${subIndex}` ? 'inherit' : 'none',
                            width: '170px',
                          }}
                          onChange={(e)=>{
                            setEditFieldValue(e.target.value)
                          }}
                          onKeyDown={(e)=>{
                            if (e.key === 'Enter'){
                              if (categories[itemIndex].subCategories[subIndex].subType === editFieldValue){
                                setEditFieldTarget(null)
                                setEditFieldValue('')
                              } else {
                              const newCategories = [...categories]
                              newCategories[itemIndex].subCategories[subIndex].subType = editFieldValue
                              setCategories(newCategories)
                              }
                            }
                            if (e.key === 'Escape'){
                              setEditFieldTarget(null)
                              setEditFieldValue('')
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <>
                                <InputAdornment position="end" 
                                  onClick={()=>{
                                    if (categories[itemIndex].subCategories[subIndex].subType === editFieldValue){
                                      setEditFieldTarget(null)
                                      setEditFieldValue('')
                                    } else {
                                      const newCategories = [...categories]
                                      newCategories[itemIndex].subCategories[subIndex].subType = editFieldValue
                                      setCategories(newCategories)
                                    }
                                  }}
                                  sx={{cursor: 'pointer'}}
                                >
                                  <Iconify icon='iconamoon:check-duotone'/>
                                </InputAdornment>

                                <InputAdornment position="end" 
                                  onClick={()=>{
                                    setEditFieldTarget(null)
                                    setEditFieldValue('')
                                }}
                                  sx={{cursor: 'pointer'}}
                                >
                                  <Iconify icon='iconamoon:close'/>
                                </InputAdornment>
                              </>
                            ),
                          }}
                      />
                      <Iconify icon='material-symbols:edit-outline' 
                        sx={{
                          display: (editFieldTarget === `${item.genre}-${sub.subType}-${subIndex}` || deleteWarning === `${item.genre}-${sub.subType}`) ? 'none' : 'inherit',
                          cursor: 'pointer',
                          transition: 'all .2s ease-in-out',
                          opacity: .1,
                          '&:hover':{
                            opacity: 1,
                          }
                        }}
                        onClick={()=>{
                          setEditFieldTarget(`${item.genre}-${sub.subType}-${subIndex}`)
                          setEditFieldValue(sub.subType)
                        }}
                      />
                    </Stack>
                    <Stack direction='row' justifyContent='flex-end' width='100%' alignItems='center'
                    >
                      <Stack direction='row' spacing={2} 
                        sx={{
                          pr: 2, 
                          alignItems: 'center',
                          display: deleteWarning !== `${item.genre}-${sub.subType}-${subIndex}` ? 'inherit' : 'none',
                        }}
                        >
                        <Typography variant='subtitle2' sx={{width: '50px',}}>
                          Quantity:
                        </Typography>
                        <TextField value={sub.quantity} size='small' autoComplete='off'
                          sx={{
                            width: '50px',
                          }}
                          onChange={(e)=>{
                            const newCategories = [...categories]
                            newCategories[itemIndex].subCategories[subIndex].quantity = e.target.value
                            setCategories(newCategories)
                          }}
                          />
                      </Stack>
                      <Stack direction='row' spacing={2} 
                        sx={{
                          pr: 6, 
                          alignItems: 'center',
                          display: deleteWarning !== `${item.genre}-${sub.subType}-${subIndex}` ? 'inherit' : 'none',
                        }}
                      >
                        <Typography variant='subtitle2' sx={{width: '50px',}}>
                          Fulfilled:
                        </Typography>
                        <TextField value={sub.fulfilled} size='small' autoComplete='off'
                          sx={{
                            width: '50px',
                          }}
                          onChange={(e)=>{
                            const newCategories = [...categories]
                            newCategories[itemIndex].subCategories[subIndex].fulfilled = e.target.value
                            setCategories(newCategories)
                          }}
                        />
                      </Stack>
                      <Stack direction='row' spacing={2} 
                        sx={{
                          pr: 6, 
                          alignItems: 'center',
                          display: deleteWarning !== `${item.genre}-${sub.subType}-${subIndex}` ? 'inherit' : 'none',
                          cursor: 'default',
                        }}
                      >
                        <Typography variant='subtitle2' sx={{width: '70px',}}>
                          Remaining:
                        </Typography>
                        <Typography variant='subtitle2' 
                          sx={{
                            width: '50px',
                            height: '40px',
                            borderRadius: 1,
                            border: sub.quantity-sub.fulfilled === 0 ? `${theme.palette.success.main} solid 1px` : `${theme.palette.divider} solid 1px`,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: sub.quantity-sub.fulfilled === 0 ? theme.palette.success.main : theme.palette.text.disabled,
                          }}
                        >
                          {sub.quantity-sub.fulfilled === 0 ? '0' :sub.quantity-sub.fulfilled || '' }
                        </Typography>
                      </Stack>

                      <Iconify icon='ic:round-delete' 
                        sx={{
                          display: deleteWarning !== `${item.genre}-${sub.subType}-${subIndex}` ? 'inherit' : 'none',
                          cursor: 'pointer',
                          transition: 'all .2s ease-in-out',
                          opacity: .2,
                          '&:hover':{
                            opacity: 1,
                          }
                        }}
                        onClick={()=>{
                          setDeleteWarning(`${item.genre}-${sub.subType}-${subIndex}`)
                        }}
                      />
                      <Box 
                        sx={{
                          display: deleteWarning === `${item.genre}-${sub.subType}-${subIndex}` ? 'flex' : 'none',
                          borderRadius: 1,
                          backgroundColor: theme.palette.error.main,
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                          // width: '350px',
                          height: '100%',
                        }}
                      >
                        <Typography variant='body2' sx={{color: 'white', py: 1, pr: 4, cursor: 'default'}}>
                          Delete this subgenre?
                        </Typography>
                        <Button variant='contained' color='error' size='small'
                          sx={{
                            //
                          }}
                          onClick={()=>{
                            const newCategories = [...categories]
                            newCategories[itemIndex].subCategories.splice(subIndex, 1)
                            setCategories(newCategories)

                          }}
                        >
                          Delete
                        </Button>
                        <Button variant='contained' color='error' size='small'
                          sx={{
                            mr: 2,
                          }}
                          onClick={()=>{
                            setDeleteWarning(null)
                          }}
                        >
                          Cancel
                        </Button>
                      </Box>
                    </Stack>
                  </Stack>
                })}
                <Button fullWidth variant='text'
                  sx={{
                    my: 2,
                    display: deleteWarning === item.genre ? 'none' : 'inherit',
                  }}
                  onClick={()=>{
                    // setShowAddSubGenre(true)
                    const newCategories = [...categories]
                    newCategories[itemIndex].subCategories.push(addSubGenre)
                    setCategories(newCategories)
                    setEditFieldTarget(`${item.genre}-New Subgenre`)
                    setEditFieldValue('New Subgenre')
                  }}
                >
                  Add New SubGenre
                </Button>
                <Button fullWidth variant='contained' color='error'
                  sx={{
                    mb: 1,
                    display: deleteWarning !== item.genre ? 'none' : 'inherit',
                  }}
                  onClick={()=>{
                    // setShowAddSubGenre(true)
                    const newCategories = [...categories]
                    newCategories.splice(itemIndex, 1)
                    setCategories(newCategories)
                  }}
                >
                  DELETE CATEGORY
                </Button>
                <Button fullWidth variant='outlined' color='error'
                  sx={{
                    // my: 2,
                    display: deleteWarning !== item.genre ? 'none' : 'inherit',
                    color: 'white',
                  }}
                  onClick={()=>{
                    setDeleteWarning(null)
                  }}
                >
                  CANCEL
                </Button>
                <Divider sx={{width: '100%'}}/>
            </Stack>
          }
          )}
          <Stack direction='row' width='600px' justifyContent='flex-start'>
            <Button variant='text'
              sx={{
                //
              }}
              onClick={()=>{
                const newCategories = [...categories]
                newCategories.push(addGenre)
                setCategories(newCategories)
              }}
            >
              Add New Category
            </Button>
          </Stack>
          <Stack direction='column' width='600px' justifyContent='center' alignItems='center'
            sx={{
              pt: 6,
            }}
          >
            <Divider width='100%' />
            <Button variant='contained' size='large' fullWidth
              sx={{
                mt: 3,
              }}
              onClick={()=>{
                saveCategories()
              }}
            >
              SAVE
            </Button>
          </Stack>
          </Box>

        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        // audioBarFavorite={audioBarFavorite}
        // setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        // refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast}/>

      {/* <CartWidgetCustom totalItems={cartItems} /> */}
    </>
  );
}
