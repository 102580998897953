import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Iconify from '../components-default/iconify';


export default function ScrollButton(props){

    const theme = useTheme();

    const {playerLoaded} = props;
    
    return (
        <Stack justifyContent='center' alignItems='center' 
          sx={{
            display: 'flex',
            position: 'fixed',
            transition: 'all 0.2s ease-in-out',
            bottom: playerLoaded ? '100px' : '20px',
            right: '16px',
            zIndex: 1000,
            // gap: 4,
          }}
        >

          <Box 
            sx={{
              height: '50px', 
              width: '50px', 
              borderRadius: '50%', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              p: 1,
              // cursor: 'pointer',
              backgroundColor: theme.palette.primary.main,
              boxShadow: 3,
            }}
            >
            <Iconify icon='raphael:arrowup'  
              sx={{
                color: theme.palette.common.white,
                // border: 'red solid 1px',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.2)',
                },
                '&:active': {
                  transform: 'scale(0.8)',
                },
              }}
              onClick={()=>{
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth'
                });          
              }}
              
              />
            <Iconify icon='raphael:arrowdown'  
              sx={{
                color: theme.palette.common.white,
                // border: 'red solid 1px',
                cursor: 'pointer',
                transition: 'all 0.2s ease-in-out',
                '&:hover': {
                  transform: 'scale(1.2)',
                },
                '&:active': {
                  transform: 'scale(0.8)',
                },
              }}
              onClick={()=>{
                window.scrollTo({
                  top: document.body.scrollHeight,
                  behavior: 'smooth'
                });          
              }}

            />
          </Box>
        </Stack>

    );
};

ScrollButton.propTypes = {
    playerLoaded: PropTypes.string,
};
