import PropTypes from 'prop-types';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Link, Card, Typography, CardHeader, Stack } from '@mui/material';
// components
import TBSubscriptionLink from '../../../../../TBSubscriptionLink';
import AASubscriptionLink from '../../../../../AASubscriptionLink';
import isAA from '../../../../../isAA';
import Iconify from '../../../../../../components-default/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------

ProfileAbout.propTypes = {
  user: PropTypes.object,
  setPaymentDialogOpen: PropTypes.func,
  handleCancelSubscription: PropTypes.func,
  accountMessage: PropTypes.string,
  setAccountMessage: PropTypes.func,
  cancelWarning: PropTypes.bool,
  setCancelWarning: PropTypes.func,
  accountDialogOpen: PropTypes.bool,
  setAccountDialogOpen: PropTypes.func,
};

export default function ProfileAbout({ user, setPaymentDialogOpen, handleCancelSubscription, accountMessage, setAccountMessage, cancelWarning, setCancelWarning, accountDialogOpen, setAccountDialogOpen }) {

  // console.log('ABOUT USER: ', user)
  const theme = useTheme();

  return (
    <Card>
      <CardHeader title="Account" />
      <Stack spacing={2} sx={{ p: 3, cursor: 'default', }}>


        <Stack direction="row">
          <StyledIcon icon="eva:email-fill" />
          <Typography variant="body2">{user?.email}</Typography>
        </Stack>

        <Stack direction="row" alignItems='center' >
          <StyledIcon icon="ic:round-electric-bolt" />

          <Link href={null}
            sx={{
              pr: 3,
              cursor: 'default',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'none',

              }
            }}
          >
            <Stack direction='row' alignItems='center'>
              <Typography variant="body2" fontWeight='bold'
              >
                {user?.plan}
              </Typography>
              <Button href={isAA() ? AASubscriptionLink(user?._id, user?.email) : TBSubscriptionLink(user?._id, user?.email)}
                variant='contained'
                sx={{
                  ml: 3,
                  display: (user?.plan !== 'Premium' && user?.role !== 'client')? 'inherit' : 'none',
                }}
              >
                Subscribe
              </Button>

            </Stack>
          </Link>
          {user?.plan === 'Premium' &&
            <Button variant='text' color='info'
              onClick={()=>{
                setCancelWarning(true);
                setAccountDialogOpen(true)
                // handleCancelSubscription()
              }}
              sx={{
                '&:hover':{
                  color: theme.palette.error.main,
                }
              }}
            >
              Cancel Subscription
            </Button>
          }
        </Stack>

        <Stack direction="row">
          <StyledIcon icon="ic:round-business-center" />

          <Typography variant="body2">
              {user?.role.charAt(0).toUpperCase().concat(user?.role.slice(1))}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
