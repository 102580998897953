import { forwardRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Slide,
  Box,
  Typography,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';


import MailRoundedIcon from '@mui/icons-material/MailRounded';
import CheckIcon from '@mui/icons-material/Check';
import axios from '../utils/axios';
import { UploadAvatar, Upload, UploadBox } from './upload';
import Image from './image';
import TextfieldDisplay from './TextfieldDisplay';

// ----------------------------------------------------------------------

const Transition = forwardRef((props, ref) => <Slide direction="left" ref={ref} {...props} />);


AddArtistDialog.propTypes = {
    artistDialogOpen: PropTypes.bool,
    setArtistDialogOpen: PropTypes.func,
    userr: PropTypes.string,
    refetch: PropTypes.func,
    setArtistSelector: PropTypes.func,
    setToast: PropTypes.func,
    uploadPage: PropTypes.bool,
  };



export default function AddArtistDialog({artistDialogOpen, setArtistDialogOpen, userr, refetch, setArtistSelector, setToast, uploadPage}) {

  const theme = useTheme();
  
  const [newArtistInfo, setNewArtistInfo] = useState({name: '', artwork: '', bio: ''})
  const [fileHolder, setFileHolder] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  


  const handleClose = () => {
    setArtistDialogOpen(false);
    setNewArtistInfo({name: '', artwork: '', bio: ''})
    setFileHolder('');
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true)
    if (newArtistInfo.name.length > 0 && fileHolder !== '') {
      setLoading(true)
      submitNewArtist();
    } else {
      setLoading(false)
      setErrorMessage('invalid')
    }
  }
  
  
  function successCallback() {
    console.log('Upload Success!')
    // setCardState('add')
    setLoading(false)
    if (uploadPage) {
      setArtistSelector('')
    }
    setTimeout(()=>{
      handleClose();
      refetch()
      setToast({open: true, icon: <CheckIcon sx={{color: 'white'}}/>, horizontal: 'right', toastColor: theme.palette.success.dark, message: `Artist ${newArtistInfo.name} successfully added to your profile`, duration: 4000})
      console.log('refetching')
    }, 500)
    refetch()
      // navigate('/uploadsuccess');
  }


  async function submitNewArtist(){

    const coverBlob = fileHolder !== '' ? new Blob([fileHolder, { type: fileHolder?.type }]) : '';
    const formData = new FormData();
    
    formData.append('name', newArtistInfo.name);
    formData.append('bio', newArtistInfo.bio);
    formData.append('userId', userr);
    formData.append('fileType', fileHolder?.type);
    formData.append('file', coverBlob, fileHolder.name);

    try {

      const res = await axios.post(`${process.env.REACT_APP_SERVER}/addartist`, formData, {  // eslint-disable-line
          headers: {
              'Content-Type':'multipart/form-data',
          },
      }).then(response => {
        if (response.status === 200){
          successCallback();
        } else {
          setToast({open: true, horizontal: 'right', toastColor: theme.palette.error.main, message: `Something went wrong`, duration: 4000})
        }
      });
    } catch (err) {
      console.log(err)
    }              
    }

  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setFileHolder(file);
        console.log(file)
      }
    },
    [setFileHolder]
  );



  return (
    <div>
      <Dialog
        open={artistDialogOpen}
        TransitionComponent={Transition}
        keepMounted
        scroll='body'
        onClose={()=>{setArtistDialogOpen(false)}}
        // aria-labelledby="alert-dialog-slide-title"
        // aria-describedby="alert-dialog-slide-description"
        sx={{
            height: '100%',
            // minWidth: '100px',
            paper: {
              // width: '500px',
            }
        }}
      >
        <Box 
          sx={{
            width: {
              sm: '300px',
              md: '500px',
            }, 
          }}
        >

        <DialogTitle 
          id="add-artist-title"
          sx={{
            pb: 0,
          }}
        >
          Add Artist
        </DialogTitle>

        <DialogContent scroll='body' >
        <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              minWidth: '100px',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Artist Name</Typography>
            <Typography 
              variant='caption'
              // color='error'
            >
              *Artist name must not match with any artist on Artlist, Soundstripe or Epidemic Sound
            </Typography>
            <TextField 
              variant='outlined' 
              size='small'
              fullWidth
              autoComplete='off'
              sx={{ }} 
              value={newArtistInfo.name} 
              onChange={(e)=>{
                setNewArtistInfo({...newArtistInfo, name: e.target.value})
              }}
            />
  
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              px: 3,
              // border: 'red solid 1px',
            }}
            >
            <Typography variant='subtitle1'>Artist Bio</Typography>
            <TextField 
              variant='outlined' 
              size='small'
              autoComplete='off'
              fullWidth
              placeholder='Optional'
              value={newArtistInfo.bio} 
              onChange={(e)=>{
                setNewArtistInfo({...newArtistInfo, bio: e.target.value})
              }}
            />
          </Box>
          <Box 
            sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 1,
              height: '100%',
              width: '100%',
              pt: 3,
              px: 3,
              pb: 3,
              // border: 'red solid 1px',
            }}
            >
              <Box sx={{display: 'flex', gap: 3}}>
                <Typography variant='subtitle1' sx={{display:'flex', alignItems: 'center'}}>Artwork</Typography>

                <Button 
                  variant='text' 
                  size='small'
                  onClick={()=>{setFileHolder('')}}
                  sx={{
                    height: '50%'
                  }}
                  >
                  clear selection
                </Button>
              </Box>
              <Typography 
                variant='caption'
                // color='error'
              >
                *1600 x 1600 square, photo must not have any words or text
              </Typography>
            {fileHolder === '' ?
              <Upload
                multiple
                accept={{ 'image/*': [] }}
                simple
                files={newArtistInfo.cover}
                onDrop={handleDrop}
                sx={{
                  borderRadius: 1,
                  border: errorMessage === 'invalid' ? 'red solid 1px' : 'inherit',
                  // height: '50px',
                }}
                // onDelete={handleRemoveFile}
            /> :
            <Image 
              alt="cover" 
              src={fileHolder?.preview} 
              sx={{ 
                // height: 360, 
                borderRadius: 2, 
              }} 
              ratio='16/9'
            />
            
          }
          {fileHolder === '' && errorMessage === 'invalid' && 
          <Typography variant='caption' color='error' >
            Artwork required
          </Typography>
          }
          </Box>


        </DialogContent>

        <DialogActions>
          <Button 
            size='medium' 
            color='inherit' 
            variant="outlined" 
            onClick={handleClose} 
            sx={{px: "16px", py: "7px"}}
          >
            Cancel
          </Button>

          <Button 
            size='medium' 
            variant="contained" 
            onClick={handleSubmit} 
            sx={{px: "16px", py: "7px"}}
            disabled={loading}
          >
            Add
          </Button>
        </DialogActions>
        </Box>

      </Dialog>
    </div>
  );
}
