import { Helmet } from 'react-helmet-async';
// @mui
import { Box } from '@mui/material';
// components
import ScrollProgress from '../components-default/scroll-progress';
// sections
import {
  HomeHeroAA,
  HomeHeroTB
} from '../sections/home';

import Footer from '../layouts/main/Footer';
import isAA from '../components-custom/isAA';

// ----------------------------------------------------------------------

export default function HomePageAA() {
  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_SITE_NAME} || Home</title>
      </Helmet>

      <ScrollProgress />

      <Box
        sx={{
          overflow: 'hidden',
          position: 'relative',
          bgcolor: 'background.default',
        }}
      >
        {/* <HomeMinimal /> */}
        {isAA() ? <HomeHeroAA /> : <HomeHeroTB/>}
        {/* <HomeLookingFor /> */}

        {/* <HomeForDesigner /> */}

        {/* <HomeDarkMode /> */}

        {/* <HomeColorPresets /> */}

        {/* <HomeCleanInterfaces /> */}

        {/* <HomePricingPlansAA /> */}


        {/* <HomeAdvertisement /> */}
      </Box>
      <Footer />
    </>
  );
}
