import PropTypes from 'prop-types';
// @mui
import { LoadingButton } from '@mui/lab';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { Button, Container, Typography, DialogActions, Dialog, Divider, Stack } from '@mui/material';
// components
import Scrollbar from '../components-default/scrollbar';

import isAA from './isAA';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 9,
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.64),
}));

const StyledTitle = styled('h1')(({ theme }) => ({
  ...theme.typography.h3,
  top: 0,
  zIndex: 10,
  width: '100%',
  position: 'absolute',
  padding: theme.spacing(3),
  color: theme.palette.common.white,
  [theme.breakpoints.up('md')]: {
    ...theme.typography.h2,
    padding: theme.spacing(5),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(5),
  },
}));

const StyledFooter = styled('div')(({ theme }) => ({
  bottom: 0,
  zIndex: 10,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'flex-end',
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('sm')]: {
    alignItems: 'center',
    paddingRight: theme.spacing(3),
  },
  [theme.breakpoints.up('lg')]: {
    padding: theme.spacing(2),
  },
}));

LegalDialog.propTypes = {
  open: PropTypes.bool,
  isValid: PropTypes.bool,
  onClose: PropTypes.func,
  setLegalDialogOpen: PropTypes.func,
  onSubmit: PropTypes.func,
  values: PropTypes.object,
  user: PropTypes.object,
  songInfo: PropTypes.object,
  isSubmitting: PropTypes.bool,
};

export default function LegalDialog({
  values,
  user,
  isValid,
  isSubmitting,
  open,
  onClose,
  setLegalDialogOpen,
  songInfo,
  onSubmit,
}) {
  // const { title = '', content = '', description = '' } = values;

  // console.log('SONG INFO: ', songInfo)

  return (
    <Dialog fullScreen open={open} onClose={onClose} >
      <DialogActions sx={{ py: 2, px: 3 }}>
        <Stack direction='row' spacing={2} width='100%' justifyContent='flex-start' alignItems='center'>
          <Typography variant="h6" 
            // sx={{ flexGrow: 1 }}
          >
            Transfer of Ownership 
          </Typography>
          <Typography variant="caption" 
            // sx={{ flexGrow: 1 }}
          >
            (scroll to the bottom of page to start the e-sign process)
          </Typography>

        </Stack>

        <Button variant="outlined" color="inherit" onClick={()=>{setLegalDialogOpen(false)}}>
          Cancel
        </Button>

        <LoadingButton
          type="submit"
          variant="contained"
          // disabled={!isValid}
          loading={isSubmitting}
          onClick={onClose}
        >
          Done
        </LoadingButton>
      </DialogActions>

      <Divider />

        <Scrollbar>
          <Container 
            sx={{ 
              mt: 5, 
              mb: 10, 
              // ml: 10, 
              // mr: 20, 
              height: '800px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
          <Stack 
            justifyContent='center' 
            alignItems='center' 
            width='80%' 
            textAlign='center'
            gap={6}
            marginBottom={6}
          >
            <Typography variant='h4'>
              This contract for Transfer of Ownership must be signed before pitching.
            </Typography>
          </Stack>
          <iframe title='AudioArsenal Terms of Service and Transfer of Ownerhips' 
            src={isAA() ? 
              `https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhDKBfpp7l9WSyf3AZUm7xEzZVHyNromFaULKSvgWrwEirjTYmNBY4322zxgnK4RmJc*&hosted=false#&name=${user?.name}` : 
              `https://na4.documents.adobe.com/public/esignWidget?wid=CBFCIBAA3AAABLblqZhCzPs7gvqpoxjmkDJMz1usenexr2zqBPaUyHfTYKifPYgOgFFzYcF96Ka5QPR-FLew*&hosted=false#&name=${user?.name}`
            }
            width="100%" height="100%" frameBorder="0" 
            style={{border: 0, overflow: 'hidden', minHeight: '500px', minWidth:' 600px'}}/>
          </Container>
        </Scrollbar>
    </Dialog>
  );
}

// ----------------------------------------------------------------------

