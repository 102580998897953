import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { useNavigate } from 'react-router-dom';

import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

// @mui
import {
  Box,
  Stack,
  Button,
  Dialog,
  Tooltip,
  IconButton,
  DialogActions,
  CircularProgress,
  Typography,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// components
import Iconify from '../../../../../components-default/iconify';
//
import InvoicePDFAAWM from './InvoicePDFAA_WM';
import InvoicePDFWM from './InvoicePDF_WM';
import InvoicePDFAAWMV2 from './InvoicePDFAA_WM-V2';

// ----------------------------------------------------------------------

InvoiceToolbar.propTypes = {
  invoice: PropTypes.object,
  openPaymentsDrawer: PropTypes.bool,
  setOpenPaymentsDrawer: PropTypes.func

};

export default function InvoiceToolbar({ invoice, openPaymentsDrawer, setOpenPaymentsDrawer }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  
  const [metadata, setMetadata] = useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEdit = () => {
    navigate(PATH_DASHBOARD.invoice.edit(invoice.id));
  };


  const generateMetadataXLSX = () => {
    // Sample data (array of objects)
    const data = [
      { name: 'John', age: 25, city: 'New York' },
      { name: 'Jane', age: 30, city: 'San Francisco' },
      // ...more data
    ];

  
    // Create a new worksheet
    const ws = XLSX.utils.json_to_sheet(metadata);
    
    // Create a custom cell style for the first row (bold)
    const boldCellStyle = {
      font: { bold: true },
    };

    // Set column widths (default width for column A and B)
    const colWidths = [
      { wpx: 150 }, // name
      { wpx: 175 }, // song title
      { wpx: 175 }, // album title
      { wpx: 200 }, // album art
      { wpx: 200 }, // profile art
      { wpx: 100 }, // bpm
      { wpx: 100 }, // key
      { wpx: 100 }, // lyrics
      { wpx: 150 }, // email
    ];
    ws['!cols'] = colWidths;
  
    // Create a new workbook and add the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
    // Convert the workbook to an array buffer
    const wbArrayBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  
    // Convert the array buffer to a Blob
    const blob = new Blob([wbArrayBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  
    // Save the Blob using FileSaver.js
    saveAs(blob, `WavMaker Metadata - ${invoice._id}.xlsx`);
  };  

  useEffect(()=>{
    const linesArray = [];

    invoice?.items?.map(item => { // eslint-disable-line
      // console.log('ITEM ARTIST: ', item?.artist)
      const line = {
        ArtistName: item?.artist?.deliveryName || item?.artist?.name,
        SongTitle: item?.title,
        AlbumTitle: item?.title,
        AlbumArtLink: (item?.artist?.largeArtwork !== null && item?.artist?.largeArtwork !== 'null') ? item?.artist?.largeArtwork : (item?.artist?.artwork),
        ProfileArtwork: (item?.artist?.largeArtwork !== null && item?.artist?.largeArtwork !== 'null') ? item?.artist?.largeArtwork : (item?.artist?.artwork),
        Bpm: item?.bpm,
        Key: `${item?.key} ${item?.scale}`,
        Lyrics: 'N/A',
        ArtistEmail: 'artists@audioarsenal.io',
      }
      linesArray.push(line)
    })

    setMetadata(linesArray)

  }, []) // eslint-disable-line

  return (
    <>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        alignItems={{ sm: 'center' }}
        sx={{ mb: 5 }}
      >
        <Stack direction="row" spacing={1} alignItems='center'>
          <Typography variant='subtitle1'>
            Statement of Work:
          </Typography>
          <Tooltip title="Print">
            <IconButton onClick={handleOpen}>
              <Iconify icon="eva:printer-fill" />
            </IconButton>
          </Tooltip>

          <PDFDownloadLink
            document={<InvoicePDFAAWMV2 invoice={invoice} />}
            fileName={`Audio Arsenal Invoice - ${invoice._id}`}
            style={{ textDecoration: 'none' }}
          >
            {({ loading }) => (
              <Tooltip title="Download">
                <IconButton>
                  {loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <Iconify icon="eva:download-fill" />
                  )}
                </IconButton>
              </Tooltip>
            )}
          </PDFDownloadLink >

          <Typography variant='subtitle1'>
            Metadata:
          </Typography>

          <Tooltip title="Metadata spreadsheet">
            <IconButton onClick={generateMetadataXLSX}>
              <Iconify icon="teenyicons:spreadsheet-outline" />
            </IconButton>
          </Tooltip>
        </Stack>

      </Stack>

      <Dialog fullScreen open={open}>
        <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <DialogActions
            sx={{
              zIndex: 9,
              padding: '12px !important',
              boxShadow: (theme) => theme.customShadows.z8,
            }}
          >
            <Tooltip title="Close">
              <IconButton color="inherit" onClick={handleClose}>
                <Iconify icon="eva:close-fill" />
              </IconButton>
            </Tooltip>
          </DialogActions>

          <Box sx={{ flexGrow: 1, height: '100%', overflow: 'hidden' }}>
            <PDFViewer width="100%" height="100%" style={{ border: 'none' }}>
              <InvoicePDFAAWMV2 invoice={invoice} />
            </PDFViewer>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
