export function parseReferenceLink(linkInput) {

    if (linkInput?.includes('youtube.com/watch?v=')) {
        const splitLink = linkInput?.split('watch?v=');
        return {
            link: `https://www.youtube.com/embed/${splitLink[1]}`,
            type: 'youtube-video',
            height: 200,

        }
    }

    if (linkInput?.includes('youtu.be/')) {
        const splitLink = linkInput?.split('youtu.be/');
        const splitLink2 = splitLink[1].split('?');
        return {
            link: `https://www.youtube.com/embed/${splitLink2[0]}`,
            type: 'youtube-video',
            height: 315,
            width: 560,

        }
    }

    if (linkInput?.includes('youtube') && linkInput?.includes('playlist')) {
        const splitLink = linkInput?.split('youtube.com/playlist?list=');
        const splitLink2 = splitLink[1].split('&');
        return {
            link: `https://www.youtube.com/embed/videoseries?si=xJi6qEnS8t-5chKG&amp;list=${splitLink2[0]}`,
            type: 'youtube-playlist',
            height: 200,

        }
    }

    if (linkInput?.includes('spotify') && linkInput?.includes('track')) {
        const splitLink = linkInput?.split('spotify.com/track/');
        const splitLink2 = splitLink[1].split('?');
        return {
            link: `https://open.spotify.com/embed/track/${splitLink2[0]}?utm_source=generator&theme=0`,
            type: 'spotify-track',
            height: 352,

        }
    }

    if (linkInput?.includes('spotify') && linkInput?.includes('playlist')) {
        const splitLink = linkInput?.split('spotify.com/playlist/');
        const splitLink2 = splitLink[1].split('?');
        return {
            link: `https://open.spotify.com/embed/playlist/${splitLink2[0]}?utm_source=generator&theme=0`,
            type: 'spotify-playlist',
            height: 452,

        }
    }

    return undefined
}
