import PropTypes from 'prop-types';
// @mui
import { Typography, Stack } from '@mui/material';
// components
import Logo from '../../components-default/logo';
import Image from '../../components-default/image';
//
import { StyledRoot, StyledSectionBg, StyledSection, StyledContent } from './styles';
import isAA from '../../components-custom/isAA';
import Iconify from '../../components-default/iconify';

// ----------------------------------------------------------------------

LoginLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string,
};

export default function LoginLayout({ children, illustration, title }) {
  return (
    <StyledRoot
      sx={{
        // mb: 10,
      }}
    >
      {isAA() &&
        <Logo
          sx={{
            zIndex: 9,
            position: 'absolute',
            mt: { xs: 1.5, md: 5 },
            ml: { xs: 2, md: 5 },
          }}
        />
      }
      {isAA() ?
      <StyledSection sx={{mt: 6, }}>
        <Typography variant="h2" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          Whoa whoa. You want in here?
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          Let&apos;s see some credentials first.
        </Typography>
        {/* <Typography variant="h2" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          Doing a little work on the site.
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          Check back in a bit.
        </Typography> */}

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/assets/shield-weapons.svg'}
          sx={{ maxWidth: 720, height: '400px', }}
        />
        <StyledSectionBg />
      </StyledSection> :
      <StyledSection >
        <Stack direction='row' spacing={2} justifyContent='center' alignItems='center'
          sx={{
            // border: 'red solid 1px',
            height: 'fit-content',
            mb: 10,
          }}
        >
          <Typography variant="h2" sx={{ maxWidth: 480, textAlign: 'center' }}>
            Login
          </Typography>
          <Iconify icon='solar:arrow-right-bold' width='42px'/>
        </Stack>
        {/* <Typography variant="h2" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          Doing a little work on the site.
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 10, maxWidth: 480, textAlign: 'center' }}>
          Check back in a bit.
        </Typography> */}

        <Image
          disabledEffect
          visibleByDefault
          alt="auth"
          src={illustration || '/logo/TB_Record_Small.png'}
          sx={{ 
            maxWidth: 720, 
            height: '400px',
            width: '400px',
         }}
        />
        <StyledSectionBg />
      </StyledSection>
      }

      <StyledContent
      sx={{
        pt: '64px !important',
      }}
      // TEMPORARILY DISABLED
      >
        <Stack 
        sx={{ 
          // backgroundColor: 'red !important',
          width: 1, 
        }}
        > {children} </Stack>
      </StyledContent>
    </StyledRoot>
  );
}
