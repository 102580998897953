import PropTypes from 'prop-types';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
// utils
import { bgGradient } from '../utils/cssStyles';
import { fShortenNumber, fCurrency } from '../utils/formatNumber';
// components
import Iconify from '../components-default/iconify';
import Image from './image/Image';

// ----------------------------------------------------------------------

AAWidgetCard.propTypes = {
  sx: PropTypes.object,
  icon: PropTypes.string,
  statsLoading: PropTypes.bool,
  color: PropTypes.string,
  textColor: PropTypes.string,
  cardColor: PropTypes.string,
  darkColor: PropTypes.string,
  title: PropTypes.string,
  total: PropTypes.any,
  money: PropTypes.bool,
  noTotal: PropTypes.bool,
  totalPrefix: PropTypes.string,
  onClick: PropTypes.func,
};

export default function AAWidgetCard({
  title,
  total,
  money,
  noTotal,
  statsLoading,
  totalPrefix,
  icon,
  color = 'primary',
  textColor,
  cardColor,
  darkColor,
  onClick,
  sx,
  ...other
}) {
  const theme = useTheme();

  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: onClick ? 'pointer' : 'default',
        pt: {
          xs: 3,
          sm: 4,
        },
        pb: 3,
        boxShadow: 0,
        height: {
          xs: '150px',
          sm: '200px',
        },
        textAlign: 'center',
        color: textColor || '#bada55',
        bgcolor: cardColor || '#nnn',
        ...bgGradient({
          direction: '135deg',
          startColor: `${alpha(cardColor || '#nnn', 0.9)} 0%`,
          endColor: `${alpha(cardColor || '#nnn', .8)} 50%`,
        }),
        ...sx,
      }}
      {...other}
    >
      {!total ?
        <Typography variant="h5" sx={{opacity: 0.84 }}>{title}</Typography> :

        <Typography variant="h5" sx={{ opacity: 0.84 }}>
          {title}
        </Typography>
      }
      {total ?
        <Typography variant="h3" 
          sx={{
            mt: {
              xs: 2.5,
              sm: 3,
            },
            ml: {
              xs: totalPrefix ? '-.5rem' : 'inherit',
              sm: totalPrefix ? '-.5rem' : 'inherit',
            }
          }}
        > 
          {/* {totalPrefix}{fShortenNumber(total)} */}
          {totalPrefix}{money ? fCurrency(total) : total}
          </Typography> :
          <Typography variant="h3" 
            sx={{
              mt: {
                xs: 2.5,
                sm: 3,
                display: noTotal ? 'none' : 'inherit',
              },
              ml: {
                xs: totalPrefix ? '-.5rem' : 'inherit',
                sm: totalPrefix ? '-.5rem' : 'inherit',
              }
            }}
          > 
            {totalPrefix} 0
            </Typography>
        
        // <Typography variant="h3">&nbsp;</Typography>

      }

      {icon &&
        <Iconify
          icon={icon}
          sx={{
            mt: {
              xs: 1,
              sm: 2,
            },
            p: 2.5,
            width: title === 'Upload Song' ? 64 : 64,
            height: title === 'Upload Song' ? 64 : 64,
            borderRadius: '50%',
            border: `${darkColor}80 solid 1px`,
            color: `${textColor}`,
            ...bgGradient({
              direction: '135deg',
              startColor: `${alpha(theme.palette.divider, 0)} 0%`,
              endColor: `${alpha(darkColor, 0.24)} 100%`,
            }),
          }}
        />
      
      }

    </Card>
  );
}
