import { useState, } from 'react';
import PropTypes from 'prop-types';

// @mui
import { alpha, styled, useTheme } from '@mui/material/styles';

import {
  Box,
  IconButton,
  Typography,

} from '@mui/material';
import Iconify from '../components-default/iconify';


TextfieldDisplay.propTypes = {
    message: PropTypes.any,
    size: PropTypes.string,
    color: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    onDelete: PropTypes.func,
    deleteButton: PropTypes.bool,
    locked: PropTypes.bool,
  };

export default function TextfieldDisplay(props) {

    const { message, size, locked, width, height, deleteButton, onDelete, color } = props;
    const theme = useTheme();

    return (
        <Box 
            sx={{
                border: `${theme.palette.divider} solid 1px`,
                borderRadius: 1,
                height: height || '40px',
                maxHeight: height || 'inherit',
                width: `${width}`,
                display: 'flex',
                justifyContent: 'space-between',
                alignContent: 'center',
                pl: 1.5,
            }}
        >
            <Typography 
                variant='body' 
                sx={{
                    py: .75,
                    width: '100%',
                    height: height || '100%',
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'default',
                    color: color || locked ? theme.palette.text.disabled : 'inherit',
                }}
            >
                {message}
            </Typography>
            {deleteButton &&
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <IconButton 
                        color={theme.palette.text.primary} 
                        onClick={onDelete} 
                        sx={{
                            p: 0,
                            mr: 1,
                        }}
                        >
                        <Iconify icon="eva:close-fill" color={theme.palette.text.primary}/>
                    </IconButton>
                </Box>
            }
      </Box>
    )
}