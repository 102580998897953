import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import { Box, AppBar, Toolbar, Container, Switch } from '@mui/material';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';

// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useResponsive from '../../hooks/useResponsive';
import { useSettingsContext } from '../../components-default/settings';
import { useAuthContext } from '../../auth/useAuthContext';


// utils
import { bgBlur } from '../../utils/cssStyles';
// config
import { HEADER } from '../../config-global';
// routes
// components
import Logo from '../../components-default/logo';
//
import {navConfig, navConfigProducer, navConfigClient, navConfigAdmin, navConfigNoUser, navConfigAdminTB} from './nav/config-navigation';
import navConfigLocal from './nav/config-navigation-local';
import NavMobile from './nav/mobile';
import NavDesktop from './nav/desktop';
import AccountPopover from '../dashboard/header/AccountPopover';
import isAA from '../../components-custom/isAA';

// ----------------------------------------------------------------------

export default function Header() {
  const carouselRef = useRef(null);
  const { user, logout } = useAuthContext();
  const navigate = useNavigate();


  const theme = useTheme();

  const { themeMode, onToggleMode } = useSettingsContext();

  const isDesktop = useResponsive('up', 'md');

  const isOffset = useOffSetTop(HEADER.H_MAIN_DESKTOP);

  if (user === null && window.location.href !== `${process.env.REACT_APP_FRONTEND_URL}/demo`) {
    logout();
  }

  // if someone types in 'audioarsenal.io', the server won't respond correctly
  // since CORS expects 'www.audioarsenal.io'
  // nginix conf isnt forwarding to www, so this is a temporary workaround
  if (window.location.host !== 'localhost:3000' && window.location.href.slice(0, 11) !== 'https://www'){
    window.open(process.env.REACT_APP_FRONTEND_URL, '_parent')
  }
  // console.log('WINDOW: ', window.location)

  return (
    <AppBar 
      ref={carouselRef} 
      color="transparent" 
      position='static' 
      sx={{ boxShadow: 'none' }}
    >
      <Toolbar
        disableGutters
        sx={{
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_MAIN_DESKTOP,
          },
          transition: theme.transitions.create(['height', 'background-color'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(isOffset && {
            ...bgBlur({ color: theme.palette.background.default }),
            height: {
              md: HEADER.H_MAIN_DESKTOP - 16,
            },
          }),
        }}
      >
        <Container sx={{ height: 1, display: 'flex', alignItems: 'center', gap: 3 }}>
          <Logo />
          <Box 
          onClick={()=>{
            if (user){
              navigate('/dash')
            } else {
              navigate('/')
            }
          }}
          sx={{ 
            display: {
              xs: 'flex',
              sm: 'flex',
              md: 'flex',
              lg: 'flex',
              xl: 'flex',
            },
          }}
          >
            {theme.palette.mode === 'light'? 
            <Box
              sx={{
                fontFamily: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? "peckham-press, sans-serif" : 'gravesend-sans, sans-serif',
                fontWeight: 400,
                fontStyle: 'normal',
                fontSize: {
                  xs: 18,
                  sm: 18,
                  md: 22,
                  lg: 22,
                  xl: 22
                }, 
                color: theme.palette.primary.contrast, 
                cursor: 'pointer',
                textTransform: 'uppercase',
                whitespace: 'nowrap',
                wrap: 'nowrap',
              }}
            >
              {process.env.REACT_APP_SITE_NAME}
            </Box>
            : <Box
              sx={{
                fontFamily: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? "peckham-press, sans-serif" : 'gravesend-sans, sans-serif',
                fontWeight: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 400 : 700,
                fontStyle: 'normal',
                fontSize: {
                  xs: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 18 : 22,
                  sm: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 18 : 22,
                  md: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 22 : 30,
                  lg: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 22 : 30,
                  xl: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? 22 : 30,
                }, 
                color: theme.palette.primary.contrast, 
                cursor: 'pointer',
                textTransform: 'uppercase',
                whitespace: 'nowrap'
              }}
            >
              {process.env.REACT_APP_SITE_NAME}
            </Box>
            }

          </Box>
          
          <Box sx={{ flexGrow: 1 }} />

          {isDesktop && user &&
            <NavDesktop isOffset={isOffset} data={user?.role === 'admin' ? (isAA() ? navConfigAdmin : navConfigAdminTB) : (user?.role === 'producer' ? navConfigProducer : navConfigClient)} /> // eslint-disable-line
          }

          {!user &&
            <NavDesktop isOffset={isOffset} data={navConfigNoUser} />
          }

          {isDesktop && 
            <Container
              sx={{ 
                // display: 'flex', 
                display: 'none', 
                alignItems: 'center', 
                width: '156px',
                // border: 'red solid 1px'
              }}
            >
                <DarkModeOutlinedIcon 
                  sx={{
                    height: 15,
                    cursor: 'pointer',
                  }}
                  onClick={()=>{
                    if (themeMode === 'light') {
                      onToggleMode('dark')
                    }
                  }}
                />
                <Switch 
                  onChange={()=>{
                    onToggleMode()
                  }}
                  checked={themeMode === 'light'}
                />
                <LightModeOutlinedIcon 
                  sx={{
                    height: 15,
                    cursor: 'pointer',
                  }}
                  onClick={()=>{
                    if (themeMode === 'dark') {
                      onToggleMode('light')
                    }
                  }}
                />
            </Container>
          }
          {!isDesktop && 
              <NavMobile isOffset={isOffset} data={user?.role === 'producer' ? navConfigProducer : (user?.role === 'client' ? navConfigClient : (isAA() ? navConfigAdmin : navConfigAdminTB))} /> // eslint-disable-line
          }
          {isDesktop && user &&
              <AccountPopover/>
          }
        </Container>
      </Toolbar>
      {/* disabled for static header */}
      {/* {isOffset && <Shadow />} */}
    </AppBar>
  );
}

// ----------------------------------------------------------------------

Shadow.propTypes = {
  sx: PropTypes.object,
};

function Shadow({ sx, ...other }) {
  return (
    <Box
      sx={{
        left: 0,
        right: 0,
        bottom: 0,
        height: 24,
        zIndex: -1,
        m: 'auto',
        borderRadius: '50%',
        position: 'absolute',
        width: `calc(100% - 48px)`,
        boxShadow: (theme) => theme.customShadows.z8,
        ...sx,
      }}
      {...other}
    />
  );
}
