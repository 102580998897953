import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { paramCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui

import { alpha, styled, useTheme } from '@mui/material/styles';
import { Box, Card, Typography, CardContent, Stack, Link } from '@mui/material';
import { useAuthContext } from '../../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
// import useResponsive from '../../../../hooks/useResponsive';
// utils
import { fDate } from '../../../../utils/formatTime';
import { fShortenNumber } from '../../../../utils/formatNumber';
// components
import Image from '../../../../components-default/image';
import Iconify from '../../../../components-default/iconify';
import TextMaxLine from '../../../../components-default/text-max-line';
// import SvgColor from '../../../../components-default/svg-color';
// import cardIcon from '../../../../assets/icons/instruments/electric-guitar.svg';

// ----------------------------------------------------------------------

const StyledOverlay = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha('#85795a', .3),
  transition: 'all .15s ease-in-out',
  '&:hover': {
    backgroundColor: alpha('#85795a', 0.14),
    // backgroundColor: alpha(theme.palette.grey[700], 0.11),


  }
}));

// ----------------------------------------------------------------------

BriefPostCard.propTypes = {
  id: PropTypes.string,
  post: PropTypes.object,
  cover: PropTypes.string,
};

export default function BriefPostCard({ post, cover, id }) {

  const theme = useTheme();
  const navigate = useNavigate();

  const { user } = useAuthContext();
  const userr = user?._id || user?._id

    return (
      <Link href={user?.role === 'client' ? `/briefs/client/${id}` : `/briefs/${id}`}>
        
      <Card
        sx={{
          cursor: 'pointer',
          transition: 'all .2s ease-in-out',
          overflow: 'hidden',
          opacity: post?.active ? 1 : .4,
          '&:hover': {
            // boxShadow: '-8px 8px 2px rgba(0, 0, 0, 0.1)',
          },
        }}

      >
        <Box // wrapper label
        >
          <Typography variant='subtitle' color='common.white' fontWeight='bold'
            sx= {{ // wrapper text
              display: 'none',
              position: 'absolute',
              top: 15,
              right: 10,
              zIndex: 20,
              border: `${theme.palette.common.white}40 solid 1px`,
              borderRadius: 5,
              px: 2,
            }}>
              New
          </Typography>
          <Box // wrapper background
            sx={{
              display: 'none',
              position: 'absolute',
              top: -20,
              right: -70,
              zIndex: 10,
              height: '100px',
              width: '200px',
              boxShadow: theme.shadows[12],
              backgroundColor: alpha(theme.palette.primary.main, .9),
              transform: 'rotate(45deg)'
            }}
            />

        </Box>
        <Typography 
            sx={{
              cursor: 'pointer',
              top: 24,
              left: 24,
              zIndex: 9,
              position: 'absolute',
              color: theme.palette.common.white,
              fontFamily: "peckham-press, sans-serif",
              fontSize: '18px',
              lineHeight: '26px',
              border: `${theme.palette.common.white} 2px solid`,
              borderRadius: '32px',
              padding: 2,
              paddingTop: 2.5,
              backgroundColor: `${theme.palette.grey[900]}80`,
            }}
            onClick={(user.role === 'client') ? navigate(`/briefs/client/${id}`) : navigate(`/briefs/${id}`)
            }
        >
          {post?.category || ''}
        </Typography>
        <Typography 
            variant='h5'
            sx={{
              display: post?.rate ? 'flex' : 'none',
              cursor: 'pointer',
              bottom: 38,
              right: 18,
              zIndex: 9,
              position: 'absolute',
              color: theme.palette.common.white,
              // fontFamily: "peckham-press, sans-serif",
              // fontSize: '18px',
              // lineHeight: '16px',
              // border: `${theme.palette.common.white} 2px solid`,
              borderRadius: '32px',
              padding: 2,
              paddingTop: 2.5,
              textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
              // backgroundColor: `${theme.palette.grey[900]}80`,
            }}
        >
          ${post?.rate}
        </Typography>
        <PostContent
          title={post?.title || ''}
          createdAt={post?.createdAt || null}
        />

        <StyledOverlay />

        <Image 
          alt="cover" 
          src={post?.cover} 
          sx={{ height: 360 }} 
            
        />
      </Card>
      </Link>

    );

}

// ----------------------------------------------------------------------

export function PostContent(post) {
  // const isDesktop = useResponsive('up', 'md');

  const theme = useTheme();

  return (
    <CardContent
      sx={{
        // pt: 4.5,
        width: 1,
        pt: 0,
        zIndex: 3,
        bottom: 0,
        position: 'absolute',
        color: 'common.white',
        '&:hover': {
          background: 'none',
        }
      }}
      >

      <TextMaxLine
        variant='h5'
        line={1}
        persistent
        sx={{
          textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',

        }}
      >
        {post?.title || ''}
      </TextMaxLine>
      <Typography
        gutterBottom
        variant="body2"
        component="div"
        sx={{
          opacity: 0.8,
          color: 'common.white',
          textShadow: '2px 3px 4px rgba(0, 0, 0, 0.5)',
        }}
      >
        {fDate(post?.createdAt || null)}
      </Typography>

    </CardContent>
  );
}
