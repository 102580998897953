import { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';  // eslint-disable-line
import { m } from 'framer-motion';
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg'

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Autocomplete, Grid, Box, Button, Container, ToggleButtonGroup, ToggleButton, Typography, TextField, Chip, Stack, Divider } from '@mui/material';
import { useQuery } from "@apollo/client";


// import { Dropbox } from 'dropbox';
// graphql
import axios from 'axios';
import UploadTagsSection from '../components-custom/UploadTagsSection';
import { GET_USER_BY_ID } from '../graphql/queries'

// components
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import { varFade, MotionViewport } from '../components-default/animate';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import { UploadBox } from '../components-default/upload';
import { genreList, subGenreList, moodList, instrumentList, themeList } from '../components-custom/TagList';
import AddArtistDialog from '../components-custom/AddArtistDialog';


import Footer from '../layouts/main/Footer';
import LoadingScreen from '../components-custom/loading-screen/LoadingScreen';
import TextfieldDisplay from '../components-custom/TextfieldDisplay';
import CustomToast from '../components-custom/CustomToast';
import isAA from '../components-custom/isAA';
import { AudioContext } from '../components-custom/audio/audio-context';

// ----------------------------------------------------------------------



export default function UploadSongPage() {


  const theme = useTheme();
  const navigate = useNavigate();
  const windowParams = new URLSearchParams(window.location.search);
  const briefId = windowParams.get('brief');

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const pillsGap = 1.5;


  // console.log('User: ', user)
  const [artistDialogOpen, setArtistDialogOpen] = useState(false)


  const [uploadPercentage, setUploadPercentage] = useState(0);  // eslint-disable-line
  const [loading, setLoading] = useState(false);
  const [loadedSong, setLoadedSong] = useState({ album: '', artist: '', title: '', favorited: [], tags: [] }); // eslint-disable-line
  const [fileHolder, setFileHolder] = useState(null);
  const [uploadFileType, setUploadFileType] = useState('ok');
  const [title, setTitle] = useState('');
  const [artist, setArtist] = useState({ name: '', artwork: '', bio: '' });
  const [artistChoices, setArtistChoices] = useState([]);
  const [artistList, setArtistList] = useState([]);
  const [album, setAlbum] = useState('');
  const [bpm, setBpm] = useState('');
  const [key, setKey] = useState('');
  const [scale, setScale] = useState('');
  const [tags, setTags] = useState({ genre: [], mood: [], instrument: [], theme: [] });
  const [lyrics, setLyrics] = useState('')
  const [duration, setDuration] = useState(null);
  const [menuOpen, setMenuOpen] = useState(null); // eslint-disable-line
  const [errorState, setErrorState] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')
  const [tagsLength, setTagsLength] = useState(0)
  const [validated, setValidated] = useState(false)
  const [showLyrics, setShowLyrics] = useState(false)
  const [showTags, setShowTags] = useState(false)

  const {
    // src,
    setSrc,
    // isPlaying,
    // setIsPlaying,
    // controlMessage,
    // setControlMessage
  } = useContext(AudioContext);

  const scaleList = ['A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'G#']

  const [artistQuery, setArtistQuery] = useState(''); // eslint-disable-line
  const [soundsLike, setSoundsLike] = useState(''); // eslint-disable-line

  const [artistSelector, setArtistSelector] = useState(''); // eslint-disable-line

  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });

  const { loading: dataLoading, error, data, refetch } = useQuery(GET_USER_BY_ID, {
    variables: { id: userr },
    fetchPolicy: 'network-only'
  });

  // ////////////////////////
  // ////////////////////////
  // //////////////////////
  // //////////////////////

  function ValidateEntry() {

    if (title === '') {
      setErrorState('title')
      setErrorMessage('Title required')
      return false
    }
    if (artist?._id === '' || artist?._id === undefined) {
      setErrorState('artist')
      setErrorMessage('Select artist from dropdown menu')
      return false
    }
    if (bpm === '') {
      setErrorState('bpm')
      setErrorMessage('Please enter bpm')
      return false
    }
    if (key === '') {
      setErrorState('key')
      setErrorMessage('Please enter key')
      return false
    }
    if (scale === '') {
      setErrorState('scale')
      setErrorMessage('Please enter scale')
      return false
    }
    if (fileHolder === null) {
      setErrorState('file')
      setErrorMessage('Add an audio file')
      return false
    }
    if (tagsLength < 3) {
      setErrorState('tags')
      setErrorMessage('Please choose at least 3 tags')
      return false
    }
    return true
  }

  function successCallback(songId) {
    console.log('Upload Success!')
    if (briefId) {
      setTimeout(() => { navigate(`/briefs/${briefId}`) }, 500)
      setLoading(false);
    } else {
      setTimeout(() => {
        navigate(`/uploadsuccess?songId=${songId}`);
      }, 1000)
    }
  }

  const handleUpload = async () => {
    if (ValidateEntry()) {
      setLoading(true)
      console.log('File: ', fileHolder);
      const audioBlob = new Blob(fileHolder, { type: fileHolder[0].type });
      const formData = new FormData();

      formData.append('userId', userr);
      formData.append('userName', user.name);
      formData.append('name', title);
      formData.append('artistId', artist._id);
      formData.append('artistName', artist.name);
      formData.append('artistBio', artist.bio);
      formData.append('artistArtwork', artist?.artwork);
      formData.append('artistSmallArtwork', artist?.smallArtwork);
      formData.append('artistLargeArtwork', artist?.largeArtwork);
      formData.append('album', album);
      formData.append('bpm', bpm);
      formData.append('key', key);
      formData.append('lyrics', lyrics);
      formData.append('scale', scale);
      formData.append('duration', duration);
      formData.append('genreTags', tags.genre);
      formData.append('moodTags', tags.mood);
      formData.append('instrumentTags', tags.instrument);
      formData.append('themeTags', tags.theme);
      formData.append('price', 300);
      formData.append('mimetype', fileHolder[0].type);
      formData.append('platform', 'Audio Arsenal');
      formData.append('file', audioBlob, fileHolder[0].name);

      console.log('FORMDATA: ', formData)
      try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER}/storageupload`, formData, {  // eslint-disable-line
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: progressEvent => {
            setUploadPercentage(parseInt(Math.round((progressEvent.loaded * 100) /
              progressEvent.total), 10))
          }
        }).then(response => {
          console.log(response.data)
          successCallback(response.data.id);

        });
      } catch (err) {
        if (err.response.status) {
          console.log('No directory baby!')
        } else {
          console.log(err.response.data)
        }
      }
    } else {
      // 
    }
  }



  useEffect(() => {
    if (data) {
      // console.log('DATA: ', data)
      setArtistChoices(data.getUserById.alias)

      const names = [];

      // console.log('ALIAS: ', data.getUserById.alias)
      data?.getUserById?.alias?.map(item => names.push(item.name))
      names.push(<Divider sx={{ width: '100%' }} />)
      names.push('Add new artist')
      setArtistList(names)
    }
  }, [data]);

  useEffect(() => {
    let tempTagsLength = 0;

    tags.genre.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    tags.mood.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    tags.instrument.map(item => { tempTagsLength += 1 }) // eslint-disable-line
    tags.theme.map(item => { tempTagsLength += 1 }) // eslint-disable-line

    setTagsLength(tempTagsLength)

  }, [tags]); // eslint-disable-line


  useEffect(() => {

    let correctArtist = {};

    if (artistSelector === 'Add new artist') {
      setArtistDialogOpen(true);
    }

    if (artistSelector !== '') {
      artistChoices.map(item => { // eslint-disable-line
        if (item.name === artistSelector) {
          correctArtist = item
        }
      })
      setArtist(correctArtist)
    }
    if (correctArtist === {}) {
      console.log("Couldn't find it")
    }


  }, [artistSelector]); // eslint-disable-line

  useEffect(() => {
    if (
      title !== '' &&
      artist.name !== '' &&
      bpm !== '' &&
      key !== '' &&
      scale !== ''
    ) {
      setShowTags(true)
    }

  }, [title, artist, bpm, key, scale])

  if (dataLoading) {
    return <LoadingScreen />
  }
  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Upload </title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dashboard',
                },
                { name: 'Upload' },
              ]
            }
            activeLast
          // moreLink={['https://www.framer.com/api/motion']}
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
        sx={{
          // backgroundColor: 'red',
        }}
      >
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            Upload Song
          </Typography>
          <Typography variant="caption" sx={{ pt: 1.2 }}>
            (All fields required)
          </Typography>
          <Box  // DEV BUTTONS
            sx={{ display: 'none' }}
          >

            <Button
              variant='contained'
              disabled={loading}
              onClick={() => {
                handleUpload(fileHolder)
                // ValidateEntry();
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              {!loading ? 'Upload' : '...'}
            </Button>
            <Button
              variant='contained'
              // disabled={loading}
              onClick={async () => {
                try {
                  const res = await axios.post(`${process.env.REACT_APP_SERVER}/artistsearch`, {  // eslint-disable-line
                    // headers: {
                    //     'Content-Type':'multipart/form-data',
                    // },
                    artistName: artistQuery

                  }).then(response => {
                    console.log(response.data.artists)

                  });
                } catch (err) {
                  console.log(err.response.data)
                }
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              API
            </Button>
            <Button
              variant='contained'
              // disabled={loading}
              onClick={async () => {
                console.log(artistSelector)
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              ARTIST SELECTOR
            </Button>
            <Button
              variant='contained'
              // disabled={loading}
              onClick={async () => {
                console.log(artist)
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              ARTIST STATE
            </Button>
            <Button
              variant='contained'
              // disabled={loading}
              onClick={async () => {
                console.log(artistList)
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              ARTIST LIST
            </Button>
            <Button
              variant='contained'
              // disabled={loading}
              onClick={async () => {
                console.log(artistChoices)
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              ARTIST CHOICES
            </Button>
            <Button
              variant='contained'
              // disabled={loading}
              onClick={async () => {
                console.log(errorState)
              }}
              sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
            >
              ERROR STATE
            </Button>

          </Box>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={8} sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 7, width: '100%', }}>
            <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3, }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '400px', }}>
                <Box
                  component='form'
                  sx={{ display: 'flex', flexDirection: 'column', gap: 1, }}
                  autoComplete='off'
                  noValidate
                >
                  <Typography
                    variant='subtitle1'
                  >
                    Song Title
                  </Typography>
                  <TextField
                    autoComplete='off'
                    variant='outlined'
                    placeholder='Title'
                    size='small'
                    sx={{
                      '& input::placeholder': {
                        opacity: isAA() ? .4 : 1,
                      }
                    }}
                    value={title}
                    onChange={(e) => {
                      setTitle(e.target.value)
                    }}
                    error={errorState === 'title' && title === ''}
                    helperText={(errorState === 'title' && title === '') && errorMessage}
                  // sx={{border: 'red solid 1px', borderRadius: 1}}
                  />
                </Box>
                <Box
                  component='form'
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                    cursor: 'pointer !important',
                  }}
                  autoComplete='off'
                >
                  <Typography variant='subtitle1'>Artist</Typography>
                  {artistSelector === '' ?
                    <Autocomplete
                      disablePortal
                      id="artist-select"
                      options={artistList}
                      value={artistSelector}
                      disableClearable
                      // onAbort={()=>{setArtist({name: '', bio: '', artwork: ''})}}
                      onInputChange={(event, value) => {
                        setArtistSelector(value)
                      }}
                      sx={{
                        width: 400,
                        '& input': {
                          py: '0px !important',
                          cursor: 'pointer !important',
                        },
                        '& input::placeholder': {
                          opacity: isAA() ? .4 : 1,
                        }

                        // height: '40px',
                      }}
                      // onSelectCapture={()=> alert('SELECT')}
                      // renderInput={(params) => <TextField {...params} size='medium' />}
                      renderInput={(params) => <TextField {...params} size='medium'
                        placeholder='Select Artist'
                        sx={{
                          borderRadius: 1,
                          border: (errorState === 'artist' && artist?.name === '') ? 'red solid 1px !important' : 'inherit',
                          cursor: 'pointer !important',
                        }}
                      />}
                    /> :
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 3,
                        borderRadius: 1,
                        cursor: 'default !important',
                        // border: errorState === 'artist' ? 'red solid 1px !important' : 'inherit',
                        border: (errorState === 'artist' && artist?.name === '') ? 'red solid 1px !important' : 'inherit',
                      }}
                    >
                      <TextfieldDisplay
                        width='100%'
                        message={artistSelector}
                        deleteButton
                        onDelete={() => { setArtistSelector('') }}
                        sx={{
                          // border: 'red solid 1px !important',
                        }}
                      />
                    </Box>
                  }
                  {(errorState === 'artist' && artist?.name === '') &&
                    <Box sx={{ height: '10px' }}>
                      <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                    </Box>
                  }
                </Box>

              </Box>
              <Box
                sx={{
                  display: 'grid',
                  gridTemplateRows: fileHolder === null ? (errorState === 'file' ? 'auto 10px' : '100%') : 'auto 40px', // eslint-disable-line
                  gap: 1,
                  pt: 4
                }}
              >
                <UploadBox
                  onDrop={(e) => {
                    if (
                      // e[0].type === 'audio/wav' || 
                      e[0].type === 'audio/mpeg' ||
                      e[0].type === 'audio/m4a' ||
                      e[0].type === 'audio/aac'
                      // e[0].type === 'audio/aiff' 
                    ) {
                      setUploadFileType('ok')
                      setFileHolder(e);
                      if (title === '') {
                        setTitle(e[0].name.split('.')[0]);
                      }
                      const audioBlob = new Blob(e, { type: e[0].type });
                      const audio = new Audio(URL.createObjectURL(audioBlob)); // Create an HTMLAudioElement
                      audio.addEventListener('loadedmetadata', () => {
                        const tempDuration = audio.duration; // eslint-disable-line
                        setDuration(tempDuration);
                        console.log('Duration:', tempDuration);
                      });
                      console.log(e);
                    } else {
                      setUploadFileType('error')
                    }
                  }}
                  accept=".wav, .mp3, .aiff, .aac, .m4a"
                  sx={{
                    mt: 0,
                    mb: 0,
                    py: 1,
                    ml: 0,
                    width: '100%',
                    height: '100%',
                    boxSizing: 'border-box',
                    borderRadius: 1,
                    backgroundColor: uploadFileType !== 'error' ? `#e6e8e4` : theme.palette.error.dark, // eslint-disable-line 
                    border: (errorState === 'file' && !fileHolder) ? 'red solid 2px' : 'inherit',
                  }}

                  placeholder=
                  {
                    fileHolder === null && uploadFileType !== 'error' ? // eslint-disable-line
                      <Box sx={{ color: isAA() ? 'text.disabled' : 'primary.light', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: isAA() ? theme.palette.text.main : theme.palette.background.default }}>Add/drop file</Typography>
                        <Iconify icon="eva:cloud-upload-fill" width={40} sx={{ color: isAA() ? `${theme.palette.text.primary}80` : `${theme.palette.background.default}`, }} />
                      </Box> :
                      (uploadFileType !== 'error' ? <Box sx={{ color: isAA() ? theme.palette.text.primary : theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" sx={{ color: isAA() ? theme.palette.common.white : theme.palette.background.default }}>{fileHolder[0].name || 'File added'}</Typography>
                        <Iconify icon="eva:checkmark-circle-2-fill" width={40} sx={{ color: isAA() ? theme.palette.common.white : theme.palette.background.default, }} />
                      </Box> : <Box sx={{ color: theme.palette.text.primary, height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                        <Typography variant="subtitle1" color='common.white'>File must be MP3, M4A or AAC</Typography>
                        <Iconify icon="eva:slash-fill" width={20} sx={{ color: theme.palette.common.white, }} />
                      </Box>
                      )
                  }
                />
                {fileHolder !== null ?
                  <Stack direction='row' justifyContent='space-between' alignItems='center' sx={{ height: '40px', width: '100%', gap: 1, }}>
                    <Button variant='text'
                      onClick={() => {
                        const audioBlob = new Blob(fileHolder, { type: fileHolder[0].type });
                        setSrc({
                          artist: {
                            name: artist.name,
                            artwork: artist.artwork,
                            bio: artist.bio,
                          },

                          audioUrl: URL.createObjectURL(audioBlob),
                        });
                        console.log('')
                      }
                      } sx={{ mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}>
                      <Iconify icon="solar:play-bold" width={20} sx={{ color: theme.palette.primary.main, }} />
                    </Button>
                    <Button fullWidth
                      variant='text'
                      onClick={() => {
                        setFileHolder(null)
                        setTitle('')
                      }}
                      sx={{ mt: 0, mb: 0, height: '100%', border: `${theme.palette.primary.main} 1px solid` }}
                    >
                      clear selection
                    </Button>

                  </Stack> :
                  <Box sx={{ height: '36px' }} />
                }
                {(fileHolder === null && errorState === 'file') &&
                  <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                }

              </Box>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1, width: '100%' }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: 'fit-content' }}>
                <Typography variant='subtitle1'>Bpm</Typography>
                <TextField
                  placeholder='120'
                  autoComplete='off'
                  variant='outlined'
                  type='text'
                  size='small'
                  sx={{
                    width: 'fit-content',
                    '& input::placeholder': {
                      opacity: isAA() ? .4 : 1,
                    }
                  }}
                  value={bpm}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^0-9,\s]/g, '');
                    // if (e.target.value.){
                    setBpm(newValue)
                    // console.log(e)
                    // }
                  }}
                  error={errorState === 'bpm' && bpm === ''}
                  helperText={(errorState === 'bpm' && bpm === '') && errorMessage}

                />
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '100%' }}>
                <Typography variant='subtitle1'>Key</Typography>
                <ToggleButtonGroup
                  exclusive
                  color='standard'
                  value={key}
                  fullWidth
                  size='small'
                  sx={{
                    height: '40px',
                    overflow: 'hidden',
                    transition: 'all 0.2s ease-in-out',
                    border: errorState === 'key' && key === '' ? 'red solid 1px' : 'inherit',
                    // background: `${theme.palette.grey[700]}10`,
                    // marginTop: '32px',

                  }}
                  onChange={(e) => {
                    const newKey = e.target.value;
                    setKey(newKey)
                  }}
                  error={errorState === 'key' && key === ''}
                  helperText={(errorState === 'key' && key === '') && errorMessage}
                >
                  {scaleList.map((note) => { // eslint-disable-line
                    return (
                      <ToggleButton
                        key={`scale-${note}`}
                        value={note}
                        size='small'
                        sx={{
                          backgroundColor: key === note ? `${theme.palette.primary.main} !important` : 'inherit',
                          color: key === note ? `white !important` : 'inherit',
                        }}>
                        {note}
                      </ToggleButton>
                    )
                  }
                  )}
                </ToggleButtonGroup>
                {(errorState === 'key' && key === '') &&
                  <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                }

              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, width: '200px' }}>
                <Typography variant='subtitle1'>Scale </Typography>
                <ToggleButtonGroup
                  exclusive
                  color='standard'
                  value={scale}
                  sx={{
                    overflow: 'hidden',
                    transition: 'all 0.2s ease-in-out',
                    height: '40px',
                    border: errorState === 'scale' && scale === '' ? 'red solid 1px' : 'inherit',

                    // width: '200px',
                  }}
                  onChange={(e) => {
                    const newScale = e.target.value;
                    setScale(newScale)
                  }}
                >
                  <ToggleButton
                    value='Major'
                    // size='small'
                    sx={{
                      backgroundColor: scale === 'Major' ? `${theme.palette.primary.main} !important` : 'inherit',
                      color: scale === 'Major' ? `white !important` : 'inherit'
                    }}>
                    Major
                  </ToggleButton>
                  <ToggleButton
                    value='Minor'
                    // size='small'
                    sx={{
                      backgroundColor: scale === 'Minor' ? `${theme.palette.primary.main} !important` : 'inherit',
                      color: scale === 'Minor' ? `white !important` : 'inherit'
                    }}>
                    Minor
                  </ToggleButton>
                </ToggleButtonGroup>
                {(errorState === 'scale' && scale === '') &&
                  <Typography variant='caption' sx={{ color: 'red' }}>{errorMessage}</Typography>
                }
              </Box>
            </Box>

            <Stack direction='row' sx={{ mt: 1, mb: 3 }}
            // LYRICS SECTION
            >

              <ToggleButtonGroup
                exclusive
                color='standard'
                value={showLyrics}
                fullWidth
                sx={{
                  overflow: 'hidden',
                  transition: 'all 0.2s ease-in-out',
                  height: '40px',
                }}
                onChange={(e) => {
                  setShowLyrics(Boolean(e.target.value === 'Lyrics'))
                }}
              >
                <ToggleButton
                  value='Instrumental'
                  // size='small'
                  sx={{
                    backgroundColor: !showLyrics ? `${theme.palette.primary.main} !important` : 'inherit',
                    color: !showLyrics ? `white !important` : 'inherit'
                  }}>
                  Instrumental
                </ToggleButton>
                <ToggleButton
                  value='Lyrics'
                  // size='small'
                  sx={{
                    backgroundColor: showLyrics ? `${theme.palette.primary.main} !important` : 'inherit',
                    color: showLyrics ? `white !important` : 'inherit'
                  }}>
                  Lyrics
                </ToggleButton>
              </ToggleButtonGroup>
            </Stack>

            <Box sx={{ display: showLyrics ? 'flex' : 'none', flexDirection: 'column', gap: 1, width: '100%', mb: 3 }}>
              <Typography variant='subtitle1' >
                Lyrics
              </Typography>
              <TextField multiline rows={6}
                value={lyrics}
                onChange={(e) => { setLyrics(e.target.value) }}
              />
            </Box>

            {showTags &&
              <UploadTagsSection
                tags={tags}
                setTags={setTags}
                errorState={errorState}
                errorMessage={errorMessage}
                tagsLength={tagsLength}
                setTagsLength={setTagsLength}
              />
            }

          </Grid>
          <Grid item xs={12} sm={12} md={4} >
            &nbsp;
          </Grid>
          <Grid item xs={12} sm={12} md={12} sx={{ mb: 2, paddingTop: '0px', }}>
            <Box sx={{ display: 'flex', gap: 2, alignContent: 'center' }}>
              <Button
                variant='contained'
                disabled={loading}
                onClick={() => {
                  // console.log(title)
                  handleUpload(fileHolder)
                }}
                sx={{ mt: 0, mb: 3, height: '36px', width: '80px' }}
              >
                {!loading ? 'Upload' : '...'}
              </Button>
              <Box
                sx={{ display: 'flex', alignItems: 'center', height: '34px' }}
              >

                <Typography variant='caption' sx={{ color: theme.palette.error.main, }}>
                  {errorMessage !== '' ? 'Check form for missing fields' : null}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <CustomToast toast={toast} setToast={setToast} />
        <AddArtistDialog
          artistDialogOpen={artistDialogOpen}
          setArtistDialogOpen={setArtistDialogOpen}
          userr={userr}
          refetch={refetch}
          setArtistSelector={setArtistSelector}
          setToast={setToast}
          uploadPage
        />
      </Container>
      <Footer />

    </>
  );
}
