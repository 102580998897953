import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import {
  Box,
  Stack,
  Divider,
  MenuItem,
  Typography,
  Grid,
  Button,
  Chip,
  TextField,
} from '@mui/material';
import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';


import { useTheme } from '@mui/material/styles';

import { useMutation } from '@apollo/client';
import axios from 'axios';
import { saveAs } from 'file-saver';


import { ReactComponent as LogoIcon} from '../../../../assets/icons/pikes.svg'
import Image from '../../../../components-default/image';
// hooks
import useResponsive from '../../../../hooks/useResponsive';

import { TOGGLE_DOC_FAVORITE, ADD_DOC_TO_CART, PITCH_PASS, CREATE_PLAYLIST, TOGGLE_SONG_TO_PLAYLIST } from '../../../../graphql/mutations'

// components
import Iconify from '../../../../components-default/iconify';
import MenuPopover from '../../../../components-default/menu-popover';
import Markdown from '../../../../components-default/markdown';
//

// ----------------------------------------------------------------------

AudioFileCardOrderDetail.propTypes = {
  sx: PropTypes.object,
  userId: PropTypes.string,
  user: PropTypes.object,
  file: PropTypes.object,
  data: PropTypes.object,
  filter: PropTypes.array,
  refetch: PropTypes.func,
  dispatch: PropTypes.func,
  addToCart: PropTypes.func,
  onDelete: PropTypes.func,
  loadedSong: PropTypes.object,
  setLoadedSong: PropTypes.func,
  isPlaying: PropTypes.bool,
  setIsPlaying: PropTypes.func,
  setControlMessage: PropTypes.func,
  audioBarFavorite: PropTypes.bool,
  setAudioBarFavorite: PropTypes.func,
  favorites: PropTypes.bool,
  passable: PropTypes.bool,
  editable: PropTypes.bool,
  selected: PropTypes.bool,
  index: PropTypes.number,
  briefId: PropTypes.string,
  toast: PropTypes.object,
  setToast: PropTypes.func,
  inventoryPage: PropTypes.bool,
  playlists: PropTypes.array,
  playlistRefetch: PropTypes.func,
};


export default function AudioFileCardOrderDetail({ userId, user, file, data, filter, refetch, dispatch, addToCart, onDelete, sx, favorites, passable, editable, briefId, loadedSong, setLoadedSong, isPlaying, setIsPlaying, setControlMessage, audioBarFavorite, setAudioBarFavorite, index, selected, toast, setToast, inventoryPage, playlists, playlistRefetch, ...other }) {


  const isDesktop = useResponsive('up', 'sm');

  const [passWarning, setPassWarning] = useState(false);

  const [favorited, setFavorited] = useState(Boolean(file?.favorited ? file?.favorited?.includes(user?.organization) : false));
  const [playButton, setPlayButton] = useState('play');

  const [openShare, setOpenShare] = useState(false); // eslint-disable-line
  const [passRefetch, setPassRefetch] = useState(false); // eslint-disable-line
  const [openPopover, setOpenPopover] = useState(null); // eslint-disable-line
  const [playlistMenuState, setPlaylistMenuState] = useState('read'); // eslint-disable-line
  const [newPlaylistName, setNewPlaylistName] = useState(''); // eslint-disable-line

  const [openDetails, setOpenDetails] = useState(false); // eslint-disable-line
  const [showLyrics, setShowLyrics] = useState(false); 

  const theme = useTheme();

  const [createPlaylist] = useMutation(CREATE_PLAYLIST);
  const [toggleSongToPlaylist] = useMutation(TOGGLE_SONG_TO_PLAYLIST);

  const handleOpenPopover = (event) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setNewPlaylistName('')
    setPlaylistMenuState('read')
    setOpenPopover(null);
  };

  async function createNewPlaylist(){
    await createPlaylist({ variables: { playlistName: newPlaylistName, orgId: user?.organization} });
    setNewPlaylistName('')
    setPlaylistMenuState('read')
    playlistRefetch();
  }

  async function toggleToPlaylist(playlistId, docId){
    await toggleSongToPlaylist({ variables: { playlistId, docId} });
    playlistRefetch()
  }


  async function handleDownloadSave(){
    try {
      // Fetch the MP3 file from the URL
      const response = await fetch(file?.audioUrl);

      // Check if the request was successful
      if (!response.ok) {
        throw new Error('Failed to fetch the MP3 file.');
      }

      // Get the response data as a Blob
      const mp3Blob = await response.blob();

      // Use file-saver to trigger the download with a specified filename
      saveAs(mp3Blob, `${file?.title} ROUGHMIX.mp3`);
    } catch (error) {
      console.error('Error downloading the MP3:', error);
    }
  }




  useEffect(() => {
    if (passRefetch === true){
      setTimeout(()=>{refetch()}, 1500)
    }
  },[passRefetch]) // eslint-disable-line
  

  useEffect(() => {
    setFavorited(Boolean(file?.favorited?.includes(user?.organization)));
  },[data]) // eslint-disable-line

  
  useEffect(() => {
    setPlayButton(isPlaying && file.audioUrl === loadedSong.audioUrl ? 'pause' : 'play');
  },[isPlaying, loadedSong, favorited]) // eslint-disable-line  

  
  return (
    <>
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 2,
        position: 'relative',
        transition: 'opacity 0.5s ease-in-out, all 0.2s ease-in-out',
        opacity: passRefetch ? .1 : 1,
        ml: (loadedSong?.audioUrl === file?.audioUrl) ? 1 : 0,
        mr: (loadedSong?.audioUrl === file?.audioUrl) ? -1 : 0,
        minHeight: '96px',
        height: 'fit-content',
        backgroundColor: passWarning ? `${theme.palette.error.main}` : `${theme.palette.background.paper}0`,
        boxShadow: (loadedSong?.audioUrl === file?.audioUrl) ? `-8px 8px 2px rgba(0, 0, 0, 0.1)` : theme.customShadows.z8,
        border: (loadedSong?.audioUrl === file?.audioUrl) ? `${theme.palette.primary.main} solid 2px` : 
        (`solid 1px ${theme.palette.info.lighter}40`), // eslint-disable-line
        '&:hover': {
          bgcolor: passWarning ? `${theme.palette.text.primary}` : `inherit` ,
          boxShadow: loadedSong?.audioUrl === file?.audioUrl ? '-8px 8px 2px rgba(0, 0, 0, 0.1)' : theme.customShadows.z8,
        },
        ...(isDesktop && {
          // p: 1.5,
          borderRadius: 1.5,
        }),
        ...sx,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          height: '34px',
          width: '34px',
          // zIndex: 10,
          transition: 'all .5s ease-in-out',
          ml: (loadedSong?.audioUrl === file?.audioUrl && isPlaying) ? -9.5 : -20,
          opacity: (loadedSong?.audioUrl === file?.audioUrl && isPlaying) ? 1 : 0,
          mt: '45px',
          transform: 'rotateZ(43deg)',
          borderRadius: '50%',
          
        }}
        >
        <LogoIcon height='100%'/>
      </Box>
      {((user?.role === 'admin') && file?.inCart?._id) &&
        <Box 
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            zIndex: 0,
            borderRadius: 1,
            border: `${theme.palette.success.dark} solid 6px`,
            // backgroundColor: theme.palette.success.dark,
            opacity: .5,


          }}
        >
          <Iconify icon='grommet-icons:cart' width='200px' sx={{color: theme.palette.success.main, opacity: .5}}/>
        </Box>
      }
      <Stack
        spacing={isDesktop ? 1.5 : 2}
        direction={isDesktop ? 'row' : 'column'}
        alignItems={isDesktop ? 'center' : 'flex-start'}
        sx={{
          p: 2.5,
          borderRadius: 2,
          position: 'relative',
          marginBottom: '0px !important',
          // border: 'red solid 1px',
          width: '100%',
          ...(isDesktop && {
            marginBottom: '0px!important',
            p: 1.5,
            pb: 0,
            borderRadius: 1.5,
          }),
          ...sx,
        }}
        {...other}
      >
        <Grid container>

          <Grid item xs={8} sm={8} md={7} lg={7} 
            order={{
              xs: 1,
              sm: 1,
            }}
            sx={{
              // PLAY BUTTON / ARTIST INFO
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'center',
              pr: 3,
              // border: 'green solid 1px',
            }}
          >
          {!passWarning && 
            <Button 
              variant="text" 
              color="primary"
              onClick={() => {
                // setSelectedIndex(index)
                // setSelectedId(file._id)
                if (loadedSong !== file) {
                  setLoadedSong(file);
                  // setIsPlaying(true);
                } else {
                  setControlMessage('play-pause')
                  // setIsPlaying(true)
                }
              
              }}
              sx={{
                flexGrow: 1,
                alignItems: 'flex-start',
                justifyContent:'center',
                borderRadius: '25%',
                p: 0,
                m: 0,
              }}
            >
            {playButton === 'pause' ? 
              <PauseCircleIcon sx={{
                width: '52px',
                height: '52px',
                m: 0,
                opacity: .8,
              }}/> :
              <PlayCircleFilledRoundedIcon sx={{
                width: '52px',
                height: '52px',
                opacity: .8,
                m: 0,
              }}/> 
              }
            </Button>
          }

            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center',
              width: '100%',
            }}>
            <Box
            // song/artist name box
              sx={{
                width: '100%',
                maxWidth: {
                  xs: '95%',
                  sm: '265px',
                  md: '265px',
                },
                pr:3,
                display: 'flex',
                flexDirection: 'column',
                opacity: passWarning ? 0.2 : 1,
                marginBottom: '0px !important',
                justifyContent: 'center',
              }}
            >
              <Typography noWrap variant="subtitle2" 
                sx={{
                  textOverflow: 'ellipsis',
                  cursor: 'default',
                }}
                >
                {file.title}
              </Typography>
              <Typography variant="caption" noWrap sx={{cursor: 'default'}}
              >
                {file.artist.name}
              </Typography>
              {file.multitrackUrl === 'producer' &&
                <Typography variant="caption" noWrap sx={{cursor: 'default'}}
                  onClick={async ()=>{
                    try {
                      const response = await axios.get(file.multitrackUrl, { responseType: 'stream' });
                      
                    } catch (error) {
                      console.error(error);
                    }
                  }}
                >
                  Download Multis
                </Typography>
              }
            </Box>
              </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={5} lg={5} 
            order={{
              xs: 3,
              sm: 2,
            }}
            sx={{
              // ARTWORK
              width: '100%',
              height: '60px',
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: {
                sm: 'flex-end',
                md: 'flex-end',
                lg: 'flex-end',
              },
              mb: {
                xs: 2,
                sm: 0,
              },
              // pr: 3,
              // border: 'green solid 1px',
            }}
          >
            <Image src={file?.artist?.artwork} 
            sx={{
              height: '100px',
              width: '100px',
              borderRadius: 2,
              // border: `${theme.palette.primary.main} solid 2px`,
              boxShadow: theme.shadows[4]
            }}
            />
            
          </Grid>

 
        </Grid>
      </Stack>
      <Box
        sx={{
          pl: 3,
          py: 0,
          my: 0,
          display: !passWarning ? 'flex' : 'none',
          alignItems: 'center',
          flexWrap: 'wrap',
          ...(isDesktop && {
            position: 'unset',
          }),
          overflowX: 'hidden',
        }}
      >
        <Stack direction='row' gap={1}
          sx={{
            display: 'flex',
            alignItems: 'center',
            border: `${theme.palette.text.disabled} solid 1px`,
            borderRadius: 5,
            height: '20px',
            mr: 1,
          }}
        >
          <Typography variant="caption" noWrap sx={{color: theme.palette.info.dark, cursor: 'default', py: 0, pl: 1}}>
            {file?.bpm} bpm
          </Typography>
          <Typography variant="caption" noWrap sx={{color: theme.palette.info.dark, cursor: 'default', py: 0, }}>
            |
          </Typography>
          <Typography variant="caption" noWrap sx={{color: theme.palette.info.dark, cursor: 'default', py: 0, pr: 1}}>
            {file?.key} {file?.scale}
          </Typography>
        </Stack>
        <Stack direction='row' gap={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              border: `${theme.palette.success.main} solid 1px`,
              borderRadius: 5,
              height: '20px',
              mr: 1,
            }}
          >
            <Typography variant="caption" noWrap 
              sx={{
                color: theme.palette.success.dark, 
                py: 0, 
                px: 1,
                cursor: 'pointer'
              }}
              onClick={(e)=>{handleDownloadSave()}}
            >
              Download Roughmix
            </Typography>
          </Stack>

      </Box>
      <Box
          sx={{
            pl: 3,
            pt: 2,
            pb: 0,
            my: 0,
            display: !passWarning ? 'flex' : 'none',
            alignItems: 'center',
            flexWrap: 'wrap',
            ...(isDesktop && {
              position: 'unset',
            }),
            overflowX: 'hidden',
          }}
        >
          {file?.tags?.genre !== undefined && file?.tags?.genre.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper, 
                  color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
                }}
                size="small"
                />
          )}
          {file?.tags?.mood !== undefined && file?.tags?.mood.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper, 
                  color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
                }}
                size="small"
                />
          )}
          {file?.tags?.instrument !== undefined && file?.tags?.instrument.map((tag) =>  tag !== '' && <Chip
                key={tag}
                label={tag}
                variant="filled"
                sx={{
                  border: 'none', 
                  mb: 1, 
                  mt: 1, 
                  mr: 1, 
                  backgroundColor: filter?.includes(tag) ? theme.palette.primary.main : theme.palette.background.paper, 
                  color: filter?.includes(tag) ? theme.palette.common.white : theme.palette.info.dark,
                }}
                size="small"
                />
          )}
        </Box>
        <Stack direction='row' width='100%' alignItems='center'
        display={file?.lyrics ? 'flex' : 'none'} marginBottom={2} marginLeft={2}
      >
        <Iconify icon={!showLyrics ? 'akar-icons:triangle-right-fill' : 'akar-icons:triangle-down-fill'} 
          sx={{
            // mx:2,
            width: '34px',
            cursor: 'pointer',
            color: theme.palette.info.light,
          }}
          onClick={()=>{setShowLyrics(!showLyrics)}}
        />
        <Typography variant='subtitle1'
          sx={{width: '195px', minWidth: '195px', cursor: 'pointer', color: theme.palette.info.light }}
          onClick={()=>{
            setShowLyrics(!showLyrics)
          }}
        >
          Lyrics
        </Typography>
      </Stack>
      {(file?.lyrics && showLyrics) &&
      <>
        <Divider sx={{width:'100%', pt: 1,}}/>
        <Stack 
          direction='row'
          padding={3} 
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            ml: 2,
            transition: 'height .2s ease-in-out',
          }}
          >
          <Typography variant='caption'
            sx={{
              color: theme.palette.info.main,
              // opacity: .5,
            }}
          >
            <Markdown>
              {file?.lyrics.replace(/\r\n/g, '<br>')}
            </Markdown>
          </Typography>
        </Stack>
      </>
      }
      

    </Box>
    <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
        // onClose={()=>{
        //   setOpenPopover(null)
        //   setPlaylistMenuState('read')
        // }}
      >
        {playlists && playlists.map(item => { // eslint-disable-line
          return <MenuItem
              key={item._id}
              value={item._id}
              onClick={()=>{
                toggleToPlaylist(item?._id, file?._id)
              }}
              sx={{
                color: item?.songs.includes(file?._id) ? theme.palette.success.dark : theme.palette.text.primary,
                display: 'flex',
                justifyContent: 'space-between',
                // gap: 3,
              }}
            >
              {item.name}
              {item?.songs.includes(file?._id) &&
                <Iconify icon='ci:check' sx={{color: theme.palette.success.dark}}/>
              }
            </MenuItem>
        })}
        <Divider sx={{width: '100%', color: theme.palette.divider}}/>
        {playlistMenuState === 'read' ? <MenuItem
          value=''
          sx={{
            color: theme.palette.info.main,
          }}
          onClick={()=>setPlaylistMenuState('new')}
        >
          New Playlist
        </MenuItem> :
        <>
        <TextField fullWidth autoComplete='off'
          size='small'
          sx={{
            pb: .5,
        }}
          placeholder='New Playlist'
          onChange={(e)=>{setNewPlaylistName(e.target.value)}}
          />
          <Button variant='contained' fullWidth
            onClick={()=>{createNewPlaylist()}}
          >
            Create
          </Button>
          </>
        }
      </MenuPopover>
    </>
  );
}
