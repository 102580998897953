import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Stack, Select, MenuItem, Divider, Link, TextField } from '@mui/material';
import AudiotrackRoundedIcon from '@mui/icons-material/AudiotrackRounded';


// graphql
import axios from 'axios';
import { useMutation, useQuery } from "@apollo/client";
import queryString from 'query-string';
import { GET_USER_DOCS_BY_ID, GET_ALL_DOCS, GET_DOCS_BY_ORG_PITCH, GET_USER_CART, GET_ORG_PLAYLISTS } from '../graphql/queries'
import { GET_PLAYLIST_M } from '../graphql/mutations';

// _mock_

import { useDispatch, useSelector } from '../redux/store';
import { addToCart } from '../redux/slices/product';


// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient'
import AudioFileCardPlaylist from '../sections/@dashboard/general/file/AudioFileCardPlaylist'


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import CartWidget from '../sections/@dashboard/e-commerce/CartWidget';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import {genreList, instrumentList, moodList, themeList} from '../components-custom/TagList';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';


// ----------------------------------------------------------------------

export default function PlaylistsPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { checkout } = useSelector((state) => state.product);

  // console.log('User: ', user)

  const [pitchStatus, setPitchStatus] = useState('Pitched');

  const [selectedIndex, setSelectedIndex] = useState(null);
  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});
  const [ cartItems, setCartItems] = useState();

  const [selectedId, setSelectedId] = useState(null);
  const [selectedPlaylist, setSelectedPlaylist] = useState(null);

  const [keyGate, setKeyGate] = useState(true)

  const [controlMessage, setControlMessage] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const fakePlaylists = [
    {name: 'Killer Songs', _id: '123'}, 
    {name: 'Rock Potentials', _id: '456'},
    {name: 'Ambient Potentials', _id: '789'},
  ]

  const { loading, error, data = {getDocumentsByPitchedToOrg: []}, refetch } = useQuery(GET_DOCS_BY_ORG_PITCH, {
    variables: { orgId: user?.organization },
    fetchPolicy: 'network-only'
  });


  const { loading: cartLoading, error: cartError, data: cartData = {getUserCart: []}, refetch: cartRefetch } = useQuery(GET_USER_CART, {
    variables: { orgId: user.organization },
    fetchPolicy: 'network-only'
  });


  const { loading: playlistLoading, error: playistError, data: playlistData = {getOrgPlaylists: []}, refetch: playlistRefetch } = useQuery(GET_ORG_PLAYLISTS, {
    variables: { orgId: user?.organization },
    fetchPolicy: 'network-only'
  });

  const [getPlaylistM] = useMutation(GET_PLAYLIST_M);

  async function getCurrentPlaylist() {
    // const currentPlaylist = await getPlaylistM({ variables: { playlistId: selectedPlaylist._id } }); // eslint-disable-line

    const res = await axios.post(`${process.env.REACT_APP_SERVER}/getplaylist`, {  // eslint-disable-line
      playlistId: selectedPlaylist._id
  }).then(response => {
    console.log(response.data)
    // successCallback(response.data.id);

  });

  }

  function doRefetch() {
    refetch();
    console.log('refetched')
  }

  function scrollToFile() {
    if (selectedId !== null) {
      const pane = document.getElementById(selectedId)
      setTimeout(()=> {pane.scrollIntoView({ behavior: 'smooth', block: 'center' })}, 20)
    }
  }

  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null
  function KeyTimer(){
    setTimeout(()=> setKeyGate(true), 500)
  }

  const handleKeyDown = (event) => {
    
    if (event.key === 'ArrowUp' && keyGate === true) {
      if (selectedIndex !== 0) {
        setSelectedIndex((prevIndex) => Math.max(prevIndex - 1, 0));
        setKeyGate(false)
        KeyTimer()
      }

      } else if (event.key === 'ArrowDown' && keyGate === true) {
        if (selectedIndex !== displayFiles.length - 1) {
          setSelectedIndex((prevIndex) =>
          Math.min(prevIndex + 1, displayFiles.length - 1)
          );
          setKeyGate(false)
          KeyTimer()
        }
      } else if (event.key === 'Spacebar' && keyGate === true) {
        setControlMessage('play-pause')
      }
  };


  useEffect(() => { // eslint-disable-line
    const filteredResults = []

    if (searchTerm === '') {
       return setDisplayFiles(data?.getDocumentsByPitchedToOrg || [])
    } 

    displayFiles.filter(item => { // eslint-disable-line
      if (item.title.includes(searchTerm) || item.artist.name.includes(searchTerm)) {
        return filteredResults.push(item)
      }
    })

    setDisplayFiles(filteredResults)
    // return console.log('FILTER: ', filteredResults)
  }, [searchTerm]); // eslint-disable-line
    
  useEffect(() => {
    if (pitchStatus === 'Rejected') {
      navigate('/inventory/rejects')
    }

  }, [pitchStatus]); // eslint-disable-line


  useEffect(() => {
    if (selectedId !== null && isPlaying) {
      scrollToFile()
    }
  }, [controlMessage]); // eslint-disable-line
  

  useEffect(() => {
    if (data?.getDocumentsByPitchedToOrg.length > 0) {
      setDisplayFiles(data?.getDocumentsByPitchedToOrg || []);

      const cart = data?.getDocumentsByPitchedToOrg.filter((doc) => doc.inCart?._id === user?.organization)
      const tempFilterList = [];
      data?.getDocumentsByPitchedToOrg.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
              doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.mood.length > 0) {
              doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
        if (doc.tags.instrument.length > 0) {
              doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
            )
        } 
      })
      setFilterList(tempFilterList)
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {

    console.log('Filter: ', filter)

    const filteredFiles = [];

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('play-pause')
      }
      setDisplayFiles(data?.getDocumentsByPitchedToOrg)


    } else {
      data?.getDocumentsByPitchedToOrg.map(doc => { // eslint-disable-line
        if (Object.values(doc.tags).some(x => filter.some(f => x.includes(f)))) {
          return filteredFiles.push(doc)
        }
      })
      setDisplayFiles(filteredFiles)
    }

  }, [filter]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(()=>{
  
    if (cartData !== undefined) {
      // console.log('CART DATA: ', cartData)
      setCartItems(cartData?.getUserCart.length || 0)
    }
  },[cartData]) // eslint-disable-line



  useEffect(()=>{

    if (selectedPlaylist) {

        getCurrentPlaylist()


    }
  
    // if (selectedPlaylist) {
    //   setDisplayFiles(getOrgPlaylists[selectedPlaylist.index]?.)
    // }

  },[selectedPlaylist]) // eslint-disable-line




  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Playlists </title>
      </Helmet>
      <Box
        sx={{ 
          pt: 6, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dash',
                },
                { name: 'Playlists' },
              ]
              }
            // heading='Playlists'
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >

        <Grid container spacing={0} className='test-opaque'
          sx={{
            borderTop: `${theme.palette.divider} solid 1px`,
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: theme.shadows[7],
            // display: 'flex',
          }}
        >
          <Grid  item // PLAYLIST MENU
            xs={12} sm={12} md={2} lg={2} xl={2} 
            sx={{
              borderRadius: '16px 0px 0px 16px',
              border: `${theme.palette.divider} solid 1px`,
              // borderRight: `${theme.palette.divider} solid 1px`,
              borderRight: `none`,
              borderTop: 'none',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              // pt: 3,
              // mt: 3, 
              mb: 7, 
              // pl: 2,
              pr: {
                xs: 0,
                sm: 0,
                // md: 3,
                // lg: 3,
                // xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
          >
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: '42px',
                backgroundColor: theme.palette.text.primary,
                borderRight: `${theme.palette.text.primary} solid 1px`,
                p: 1,
                pl: 2,
                zIndex: 2,
              }}
            >
              <Typography variant='subtitle2' fontWeight='bold' sx={{color: 'white'}}>
                Playlist
              </Typography>
            </Box>
            <Box sx={{
              borderRight: `${theme.palette.divider} solid 1px`,
              height: '100%',
              pr: 0,
              }}
            >
            {playlistData?.getOrgPlaylists.map((item, playlistIndex) => { // eslint-disable-line
              return <Box 
                key={item._id}
                sx={{
                  backgroundColor: playlistIndex === selectedPlaylist?.index ? theme.palette.divider : theme.palette.background.default,
                  display: 'flex',
                  width: '98%',
                  height: 'fit-content',
                  pl: 2,
                  py: 1,
                  pr: 0,
                  m: .5,
                  mx: 0,
                }}
              >
                <Link
                  sx={{
                    cursor: 'pointer',
                    // color: theme.palette.text.primary,
                    '&:hover' : {
                      textDecoration: 'none',
                    }
                  }}
                  onClick={()=>{
                    setSelectedPlaylist({_id: item._id, index: playlistIndex})
                  }}
                >
                  <Typography variant='body' 
                    sx={{
                      // color: playlistIndex === selectedPlaylist ? theme.palette.common.white : theme.palette.text.primary,
                      color: theme.palette.text.primary,
                    }} 
                  >
                    {item.name}
                  </Typography>
                </Link>
              </Box>
            })}
            </Box>
          </Grid> 
          <Grid  item // AUDIO CARDS GRID ITEM
            xs={12} sm={12} md={7} lg={7} xl={7} 
            sx={{
              mb: 7, 
              pr: {
                xs: 0,
                sm: 0,
                // md: 3,
                // lg: 3,
                // xl: 3,
              }, 
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 2,
            }}
            >
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              height: '42px',
              backgroundColor: theme.palette.text.primary,
              zIndex: 2,
            }}
            />

            {loading ? <LoadingScreen/> : 
            <Box onKeyDown={handleKeyDown}
            sx={{
                pl: 1,

              }}
            >
            {displayFiles.length > 0 ? 
                displayFiles.map((doc, index) => (
                  <AudioFileCardPlaylist
                    key={doc._id}
                    id={doc._id}
                    userId={userr} 
                    user={user}
                    file={doc}
                    data={data}
                    dispatch={dispatch}
                    addToCart={addToCart}
                    refetch={cartRefetch}
                    loadedSong={loadedSong} 
                    setLoadedSong={setLoadedSong}
                    setSelectedIndex={setSelectedIndex}
                    selectedIndex={selectedIndex}
                    setSelectedId={setSelectedId}
                    index={index}
                    selected={selectedIndex === index}
                    sx={{ mb: 0, mt: 0, display: 'flex'}} // eslint-disable-line
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    audioBarFavorite={audioBarFavorite}
                    setAudioBarFavorite={setAudioBarFavorite}
                    toast={toast} 
                    setToast={setToast}
                    favorites
                    passable
                    inventoryPage
                  />
                ))  :
                <Typography variant="h5" sx={{ mb: 0, }}>
                  Playlist is empty
                </Typography>
            }
            </Box>
              }
          </Grid>
          <Grid item  // FILTERS BOX GRID ITME
            xs={12} sm={12} md={3} lg={3} xl={3} 
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: 7,
              // mt: 3,
              // p: 3,
              borderRadius: '0px 16px 16px 0px',
              ml: {
                // xs: 3,
                // sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              // border: 'none',
              borderTop: 'none',
              borderLeft: 'none',
              width: '100%',
              zIndex:1,
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
            >
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                width: '100%',
                height: '42px',
                backgroundColor: theme.palette.text.primary,
                borderLeft: `${theme.palette.text.primary} solid 1px`,
                zIndex: 4,
              }}
              />
            <Box sx={{
              borderLeft: `${theme.palette.divider} solid 1px`,
              p: 2,
              }}>

            {loading ? <LoadingScreen/> : 

            <Box sx={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'space-between', 
                mr: 4, 
                mt: 0, 
                gap: 3,
              }}
            >
              <Typography variant="h5" sx={{ mb: 0 }}>
                Filters
              </Typography>
              <Button 
                variant="text" 
                onClick={() => {
                  setFilter([])
                  setFilteredDisplayFiles([])
                }}
                sx={{ m: 0, p: 0, textTransform: 'none', fontSize: '12px', fontWeight: '200', color: theme.palette.primary.darker}}
                >
                clear filters
              </Button>
            </Box>
            }
            <TextField fullWidth
              size='small'
              placeholder='Search'
              autoComplete='off'
              color='info'
              sx={{
                py: 1,
              }}
              onChange={(e)=> {
                console.log(e.target.value)
                setSearchTerm(e.target.value)
              }}
            />
            <Box sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: {
                xs: 'flex-start',
                sm: 'flex-start',
                md: 'space-between',
                lg: 'space-between',
                xl: 'space-between',
              },
              m: 0,
              p: 0,
              pt: 2,
              pr: 1,
              pb: 3,
            }}>
              {/* {filterList.map((filterItem) => <Chip */}
              <Typography variant='caption' width='100%' fontWeight='bold'>
                Genre
              </Typography>

              {filterList.filter(value => genreList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }

              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Instrument
              </Typography>

              {filterList.filter(value => instrumentList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
              <Typography variant='caption' width='100%' fontWeight='bold' sx={{mt: 2}}>
                Mood
              </Typography>

              {filterList.filter(value => moodList.includes(value)).map((filterItem) => <Chip
                    key={filterItem}
                    label={filterItem}
                    variant={filter.includes(filterItem) ? 'filled' : 'outlined'}
                    color={filter.includes(filterItem) ? 'primary' : 'primary'}
                    sx={{
                      border: 'none', 
                      m: .5, 
                      color: filter.includes(filterItem) ? 'primary' : theme.palette.primary.darker
                    }}
                    size="small"
                    onClick={() => {
                      const currentFilters = [...filter]
                      if (currentFilters.includes(filterItem)) {
                        // console.log('no')
                        const removedFilter = currentFilters.filter((x) => x !== filterItem)
                        console.log(removedFilter)
                        setFilter(removedFilter)
                      } else {
                        console.log(filterItem)

                        currentFilters.push(filterItem)
                        console.log('Current Filters: ', currentFilters)
                        setFilter(currentFilters)
                      }
                    }}
                />)
              }
              
            </Box>
            </Box>

          </Grid>

        </Grid>
      </Container>
      <Footer />
      <AudioPlayerBar 
        src={loadedSong} 
        controlMessage={controlMessage} 
        setControlMessage={setControlMessage}
        isPlaying={isPlaying}
        audioBarFavorite={audioBarFavorite}
        setAudioBarFavorite={setAudioBarFavorite}
        setIsPlaying={setIsPlaying}
        userId={userr}
        refetch={refetch}          
      />
      <CustomToast toast={toast} setToast={setToast}/>

      <CartWidgetCustom totalItems={cartItems} />
    </>
  );
}
