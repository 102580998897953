import { useState } from 'react';
import PropTypes from 'prop-types';  // eslint-disable-line

import { paramCase } from 'change-case';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import { useNavigate } from 'react-router-dom';
// @mui
import { Box, Button, Link, TextField, Typography, Autocomplete, InputAdornment } from '@mui/material';
// utils
import axios from '../../../../../utils/axios';
// routes
import { PATH_DASHBOARD } from '../../../../../routes/paths';
// components
import Image from '../../../../../components-default/image';
import Iconify from '../../../../../components-default/iconify';
import { CustomTextField } from '../../../../../components-default/custom-input';
import SearchNotFound from '../../../../../components-default/search-not-found';

// ----------------------------------------------------------------------

ArtistSearch.propTypes = {
  soundsLike: PropTypes.string,
  setSoundsLike: PropTypes.func,
}

export default function ArtistSearch(props) {
  const navigate = useNavigate();

  const [searchProducts, setSearchProducts] = useState('');

  const [searchResults, setSearchResults] = useState([]);

  const { soundsLike, setSoundsLike} = props;

  const handleChangeSearch = async (value) => {
    try {
      setSearchProducts(value);
      if (value && value.length >= 2) {

        // const res = await axios.post(`${process.env.REACT_APP_SERVER}/artistsearch`, {  // eslint-disable-line
        //   artistName: value
        // })

        // setSearchResults(res.data.artists);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleGotoProduct = (name) => {
    navigate(PATH_DASHBOARD.eCommerce.view(paramCase(name)));
  };

  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      handleGotoProduct(searchProducts);
    }
  };

  return (

    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={searchResults}
      onInputChange={(event, value) => {
        handleChangeSearch(value)
        setSoundsLike(value)
        }}
      sx={{ width: 400 }}
      // onSelectCapture={()=> alert('SELECT')}
      renderInput={(params) => <TextField {...params} label="Sounds like" />}
      renderGroup={(params) => (
        <li key={params.key}>
          {/* <Button variant='text' onClick={(e)=>{
            console.log(e.target)
          }}> */}
            <Typography variant='h1'>Butts</Typography>
          {/* </Button> */}
        </li>
      )}
    />

    // <Autocomplete
    //   size="small"
    //   autoHighlight
    //   popupIcon={null}
    //   options={searchResults}
    //   onInputChange={(event, value) => handleChangeSearch(value)}
    //   // getOptionLabel={(product) => product.name}
    //   noOptionsText={<SearchNotFound query={searchProducts} />}
    //   // isOptionEqualToValue={(option, value) => option.id === value.id}
    //   componentsProps={{
    //     popper: {
    //       sx: {
    //         width: `480px !important`,
    //       },
    //     },
    //     paper: {
    //       sx: {
    //         '& .MuiAutocomplete-option': {
    //           px: `8px !important`,
    //         },
    //       },
    //     },
    //   }}
    //   renderInput={(params) => (
    //     // <Typography>Hi</Typography>
    //     <CustomTextField
    //       {...params}
    //       width='480px'
    //       placeholder="Search..."
    //       onKeyUp={handleKeyUp}
    //       InputProps={{
    //         ...params.InputProps,
    //         startAdornment: (
    //           <InputAdornment position="start">
    //             <Iconify icon="eva:search-fill" sx={{ ml: 1, color: 'text.disabled' }} />
    //           </InputAdornment>
    //         ),
    //       }}
    //     />
    //   )}
    //   renderOption={(props, { inputValue }) => { // eslint-disable-line
    //     // const { name, cover } = product;
    //     // const matches = match(name, inputValue);
    //     // const parts = parse(name, matches);

    //     console.log(params)
    //     return (
    //       <li {...props} >
    //         {inputValue}
    //       </li>
    //     );
    //   }}
    // />
  );
}
