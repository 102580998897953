import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import WaveSurfer from 'wavesurfer.js';
import {
  Box, Checkbox, Grid, IconButton, LinearProgress, Slider, Stack, Typography,
} from '@mui/material';

import PlayCircleFilledRoundedIcon from '@mui/icons-material/PlayCircleFilledRounded';
import PauseCircleFilledRoundedIcon from '@mui/icons-material/PauseCircleFilledRounded';
import { useTheme } from '@mui/material/styles';
import { useMutation } from '@apollo/client';

import { TOGGLE_DOC_FAVORITE, } from '../graphql/mutations'
import Iconify from '../components-default/iconify';
import Image from '../components-default/image';
import isAA from './isAA';

export default function WaveSurferPlayer(props) {

    WaveSurferPlayer.propTypes = {
        src: PropTypes.object.isRequired,
        controlMessage: PropTypes.string,
        setControlMessage: PropTypes.func,
        audioBarFavorite: PropTypes.bool,
        setAudioBarFavorite: PropTypes.func,
        isPlaying: PropTypes.bool,
        setIsPlaying: PropTypes.func,
        refetch: PropTypes.func,
        volumeValue: PropTypes.number,
        setVolumeValue: PropTypes.func,
        userId: PropTypes.string,
      };

      const { 
          src, 
          controlMessage, 
          setControlMessage, 
          audioBarFavorite, 
          setAudioBarFavorite, 
          refetch, 
          isPlaying, 
          setIsPlaying, 
          volumeValue,
          setVolumeValue,
          userId
        } = props;

      const theme = useTheme();

      const containerRef = useRef();
      
      const waveSurferRef = useRef({
        isPlaying: () => false,
      });

      const [loading, setLoading] = useState(false);

      const [toggleFavorite] = useMutation(TOGGLE_DOC_FAVORITE);


      const handleFavorite = async () => {
          setAudioBarFavorite(!audioBarFavorite);
          await toggleFavorite({ variables: { docId: src._id, userId} });
          refetch()
      };

      const handleChange = (event, newValue) => {
        setVolumeValue(newValue);
      };

      function formatTime(entry) {
        const minutes = Math.floor(entry / 60);
        const seconds = Math.floor(entry % 60);

        if (seconds < 10) {
          return `${minutes}:0${seconds}`
        }
        return `${minutes}:${seconds}`;
      }


      useEffect(() => { // eslint-disable-line  
        if (setAudioBarFavorite){
          // setAudioBarFavorite(src?.favorited.includes(userId))
        }
        if (src?.audioUrl) {
          const waveSurfer = WaveSurfer.create({
            container: containerRef.current,
            barWidth: 2,
            barHeight: .7,
            barRadius: .4,
            cursorWidth: 0,
            hideCursor: false,
            barGap: 2,
            waveColor: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? `${theme.palette.primary.lighter}` : `${theme.palette.text.primary}`,
            progressColor: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? `${theme.palette.common.white}` : `${theme.palette.primary.main}`,
            cursorColor: 'white',
            responsive: true,
            normalize: true,
            // overflow: 'hidden',
            // mobile silent mode fix?
            // backend: 'MediaElementWebAudio',
            // height: '100px',
          });
          setLoading(true);
          waveSurfer.load(src?.audioUrl);
          waveSurfer.on('ready', () => {
            setLoading(false);
            waveSurferRef.current = waveSurfer;
            setControlMessage('play-pause')
            // waveSurferRef.current.playPause();
          });
          waveSurfer.on('audioprocess', () => {
            if (waveSurfer.isPlaying()) {
              const totalTime = waveSurfer.getDuration();
              const currentTime = waveSurfer.getCurrentTime();
              // const remainingTime = totalTime - currentTime;
                  
              document.getElementById('time-total').innerText = formatTime(totalTime);
              document.getElementById('time-current').innerText = formatTime(currentTime);
              // document.getElementById('time-remaining').innerText = formatTime(remainingTime);

            }
          });
          waveSurfer.on('finish', () => {
            setControlMessage('')
            setIsPlaying(false);
            // alert('SONG ENDED')
          });
      
          return () => {
            waveSurfer.destroy();
          };
    
        }
      }, [src]); // eslint-disable-line  


      useEffect(() => {
        if (src?.audioUrl && controlMessage === 'play-pause') {
          
          if (!loading){
              waveSurferRef.current.playPause();
              setIsPlaying(waveSurferRef.current.isPlaying());
              setControlMessage('');
            } else {
              setControlMessage('');
              // setIsPlaying(false);
          }

        }
       }, [controlMessage])// eslint-disable-line  


      useEffect(() => {
        if (src?.audioUrl && volumeValue !== null) {
          waveSurferRef.current.setVolume(volumeValue);
        }
       }, [volumeValue])// eslint-disable-line  

      return (
            <Box sx={{
              display: 'flex', 
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%', 
            }}>
              <Grid container 
              >
                <Grid item 
                  // song info
                  xs={0}
                  sm={0}
                  md={3}
                >
                  <Box sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'flex',
                    },
                    alignItems: 'center', 
                    justifyContent:'flex-end', 
                    width: '100%',
                    height: '100%',
                    borderRadius: 1,
                    boxSizing: 'border-box',  
                  }}>
                    {src?.artist?.artwork !== '' &&
                      <Image 
                      src={
                        (src?.artist?.smallArtwork && 
                        src?.artist?.smallArtwork !== null && 
                        src?.artist?.smallArtwork !== 'null' && 
                        src?.artist?.smallArtwork !== '') ? src?.artist?.smallArtwork : (src?.artist?.artwork)
                      }
                      // src={src?.artist?.artwork}
                      // ratio='16/9'
                      sx={{
                          boxSizing: 'border-box',
                          width: '85px',
                          height: '50px',
                          borderRadius: '16px',
                          backgroundColor: isAA() ? 'white' : theme.palette.background.default,
                          mr: '24px',
                          ml: 3,
                          border: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? `${theme.palette.primary.main} solid 1px` : `${theme.palette.info.light} solid 1px`,
                        }}
                      />
                    
                    }
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '156px',}}>
                      <Typography noWrap variant="button" color='common.white' sx={{textOverflow: 'ellipsis'}}component="div">
                          {src?.title}
                      </Typography>
                      <Typography variant="caption" color='common.white' sx={{}} component="div">
                          {src?.artist?.name}
                      </Typography>

                    </Box>
                  </Box>
                </Grid>
                <Grid item 
                  // waveform window
                  xs={12}
                  sm={12}
                  md={6}
                >
                  <Box 
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      ml: {
                        xs: 1,
                        sm: 1,
                        md: 0,
                      },                     
                      mr: {
                        xs: 3,
                        sm: 3,
                        md: 0,
                      }
                    }}
                  >
                  <IconButton 
                      sx={{height: '55px', width: '55px', color: 'white'}}
                      onClick={() => {
                        setControlMessage('play-pause')

                      }}            
                      >
                      {isPlaying ? 
                          <PauseCircleFilledRoundedIcon sx={{height: '100%', width: '100%'}}/> : 
                          <PlayCircleFilledRoundedIcon sx={{height: '100%', width: '100%'}}/>}
                    </IconButton>
                    <Box sx={{width: '28px'}}>
                      <Typography id='time-current' variant='body2' sx={{color: theme.palette.common.white, width: '28px !important'}}/>

                    </Box>
                    <Box sx={{
                      disply: 'flex', 
                      alignItems: 'center', 
                      borderRadius: '16px',
                      padding: '8px',
                      boxSizing: 'content-box',
                      height: '40px', 
                      width: '100%',
                      overflow: 'hidden',
                    }}>
                      {loading && 
                        <Box 
                          sx={{
                            display: 'flex', 
                            height: '100%', 
                            alignItems: 'center',
                            pt: 1,
                          }}
                        >
                          <LinearProgress 
                            // color='common.white'
                            sx={{ 
                              mb: 2, 
                              width: 1, 
                              color: theme.palette.common.white,  
                              backgroundColor: theme.palette.common.white,
                              opacity: 0.5,
                            }} 
                          />

                        </Box>
                      }
                      <Box
                          id='waveform'
                          sx={{
                            display: 'grid',
                            position: 'relative',
                            height: 1,
                            width: '100%',
                            '& wave': {
                              display: 'flex !important', 
                              height: '38px !important',
                              // new
                              overflow: 'hidden !important',
                              scrollbarWidth: 'none',
                              
                            },
                          }}
                          ref={containerRef}
                      />
                      </Box>
                      <Typography id='time-total' variant='body2' sx={{color: theme.palette.common.white}}/>

                  </Box>
                  </Grid>
                  <Grid item 
                    // favourite and volume window
                    md={3}
                  >
                    <Box sx={{
                        display: {
                          xs: 'none',
                          sm: 'none',
                          md: 'flex',
                        },
                        alignItems: 'center', 
                        justifyContent: 'space-between',
                        height: '100%',
                        width: '100%',
                        pl: 2,
                        pr: 3,
                        // border: 'purple solid 1px',
                    }}>
                      {setAudioBarFavorite ?
                        <Checkbox
                        //   color="white"
                          icon={<Iconify icon="eva:heart-outline" />}
                          checkedIcon={<Iconify icon="eva:heart-fill" sx={{color: theme.palette.error.main}}/>}
                          checked={audioBarFavorite}
                          onChange={handleFavorite}
                          sx={{ 
                            p: 0.75,
                            display: 'inline-flex',
                            color: theme.palette.common.white,
                          }}          
                        /> :
                        <Box >
                          &nbsp;
                        </Box>
                      }
                      <Stack 
                          direction="row" 
                          alignItems="center" 
                          spacing={1} 
                          width={.2}
                          sx={{
                              minWidth: '105px',

                          }}
                      >
                          <Iconify icon="eva:volume-up-fill" width={24} color='common.white'/>
                          <Slider  
                              value={volumeValue}
                              min={0}
                              max={1}
                              step={0.01}
                              onChange={handleChange} 
                              aria-labelledby="continuous-slider" 
                              sx={{color: theme.palette.common.white}}

                          />
                      </Stack>
                  </Box>
                  </Grid>
            </Grid>
            </Box>
      )


    
}