// ----------------------------------------------------------------------

export default function noResultsMessage(input) {
  if (input === 'all') {
    return 'No songs currently available'
  }
  if (input === 'pitched') {
    return 'No songs currently pitched'
  }
  if (input === 'pass') {
    return 'No song pitches currently passed on'
  }
  if (input === 'revise') {
    return 'No song pitches currently requested to revise'
  }
  if (input === 'unpitched') {
    return 'No songs currently unpitched'
  }
  if (input === 'sold') {
    return 'No songs sold yet'
  }
  return null
}

