import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { useParams } from 'react-router-dom';

// @mui
import { Container } from '@mui/material';

import { useQuery } from "@apollo/client";

// routes
import { PATH_DASHBOARD } from '../routes/paths';
import axios from '../utils/axios';
import { GET_BRIEF_BY_ID } from '../graphql/queries'


// components
import { useSettingsContext } from '../components-default/settings';
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';
// sections
import { BlogNewPostForm } from '../sections/@dashboard/blog';
import { EditBrief } from '../components-custom/sections/@dashboard/blog';

// ----------------------------------------------------------------------


export default function EditBriefPage() {

  const { themeStretch } = useSettingsContext();
  const { briefId } = useParams();

  const [post, setPost] = useState(null);


  const { loading, error, data, refetch } = useQuery(GET_BRIEF_BY_ID, {
    variables: { briefId: briefId }, // eslint-disable-line
    fetchPolicy: 'network-only',
  });

  useEffect(()=>{
    if (data?.getBriefById?.post !== undefined){

      console.log(data.getBriefById)

      setPost(data.getBriefById.post)
    }
  }, [data])

  return (
    <>
      <Helmet>
        <title> Edit Brief | Audio Arsenal</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading="Edit Brief"
          links={[
            {
              name: 'Dashboard',
              href: '/dashboard',
            },
            {
              name: 'Briefs',
              href: '/briefs',
            },
            {
              name: 'Edit',
            },
          ]}
        />
        {data?.getBriefById?.post !== undefined &&
          <EditBrief post={data?.getBriefById.post} briefId={briefId}/>
        }
      </Container>
    </>
  );
}
