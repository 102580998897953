import React, { useEffect } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../auth/useAuthContext';


export default function LogoutPage() {

    // const { logout } = useAuthContext();
    // const navigate = useNavigate

    // useEffect(() => {
    //     navigate('/login');

    // }, 
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // [])
        // logout();


  return (
    <div>
      Hey
    </div>
  )
}
