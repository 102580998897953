import { useState, useEffect, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Select, MenuItem, Stack, TextField, InputAdornment, IconButton, Pagination } from '@mui/material';


// graphql
import axios from 'axios';
import queryString from 'query-string';
import LoadingIcons from 'react-loading-icons'

import { addToCart } from '../redux/slices/product';

// components
import useResponsive from '../hooks/useResponsive';
import noResultsMessage from '../utils/no-results-message';
// import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';
import AudioFileCardClient from '../sections/@dashboard/general/file/AudioFileCardClient';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import AudioPlayerBar from '../components-custom/AudioPlayerBar';
import CartWidgetCustom from '../components-custom/CartWidgetCustom';
import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import ScrollButton from '../components-custom/ScrollButton';
import FilterPane from '../components-custom/FilterPane';
import InventoryShowSearchBox from '../components-custom/InventoryShowSearchBox';
import { AudioContext } from '../components-custom/audio/audio-context';


// ----------------------------------------------------------------------

export default function ClientInventoryPageV2() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id
  const isDesktop = useResponsive('up', 'md');

  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location?.search);
  const pageQuery = queryParams.page || null;
  const perPageQuery = queryParams.perpage || 20;
  const searchQuery = queryParams.search || null;
  const modifierQuery = queryParams.modifier || 'pitched';
  const tagsQuery = queryParams.tags || null;

  // console.log('User: ', user)

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  const [displayFiles, setDisplayFiles] = useState([]);
  const [filteredDisplayFiles, setFilteredDisplayFiles] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [filter, setFilter] = useState(tagsQuery?.split(',') || []);
  const [showFilter, setShowFilter] = useState(isDesktop);
  const [searchTerm, setSearchTerm] = useState(searchQuery || '');
  const [showSearchTerm, setShowSearchTerm] = useState(searchTerm);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const [songStatus, setSongStatus] = useState(modifierQuery);
  const [sortMethod, setSortMethod] = useState('date-descending')

  // const [pageLoaded, setPageLoaded] = useState(false)

  const [axiosLoading, setAxiosLoading] = useState(false)
  const [inventoryCount, setInventoryCount] = useState('')
  const [axiosResults, setAxiosResults] = useState('')
  const [page, setPage] = useState(pageQuery || null)
  const [pageCount, setPageCount] = useState(0)
  const [perPage, setPerPage] = useState(perPageQuery)

  const [cartItems, setCartItems] = useState(null);


  const [audioBarFavorite, setAudioBarFavorite] = useState(false);

  const [toast, setToast] = useState({ open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000 });


  const fullPath = useMemo(() => { // eslint-disable-line
    return `${window.location.pathname}?page=${page || 1}&perpage=${perPage}&modifier=${songStatus}&tags=${filter.join(',').replace('&', '%26')}&search=${searchTerm}`
  }, [page, perPage, songStatus, filter, searchTerm]);

  async function getInventory() {
    // setLoading(true)
    console.log('WINDOW: ', window.location.pathname)
    if (!page) {
      // setPage(1)
      navigate(fullPath)
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'clientinventory',
        perPage,
        modifier: songStatus,
        sortMethod,
        filter,
        orgId: user?.organization,
        searchTerm,
      }).then(response => {
        // console.log(response)
        setAxiosResults(response.data)
        setInventoryCount(response.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }
  }

  async function getCartCount() {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getcartcount`, {  // eslint-disable-line
        orgId: user?.organization,
      }).then(response => {
        // console.log(response)
        // setAxiosResults(response.data)
        setCartItems(response.data?.totalDocs)
      });
    } catch (err) {
      console.log(err)
    }
  }

  async function updateInventory() {
    try {
      window.scrollTo({
        top: 0,
        behavior: 'auto', // Use smooth scrolling behavior
      });

      const normalPull = async () => {
        try {
          setAxiosLoading(true)
          const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
            type: 'clientinventory',
            page,
            perPage,
            modifier: songStatus,
            sortMethod,
            filter,
            searchTerm,
            orgId: user?.organization,
          }).then(response => {
            // console.log(response)
            setAxiosResults(response.data)

          });
        } catch (error) {
          console.error('Error fetching search results:', error);
        }
      }

      normalPull()
      setUpdateTrigger(false)

    } catch (err) {
      console.log(err)
    }
  }

  async function getInventoryCount() {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/getdocs`, {  // eslint-disable-line
        type: 'inventoryCount',
        userId: user?._id,
      }).then(response => setInventoryCount(
        <Typography variant="subtitle1" fontWeight={500} sx={{ color: theme.palette.info.light }}>
          {`(${response.data} Total)`}
        </Typography>
      )
      );
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getInventoryCount()
    getCartCount()
    getInventory()
    // console.log('TAGS QUERY: ', tagsQuery)
  }, []) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== '') {
      // console.log('AXIOS RESULTS: ', axiosResults)
      setDisplayFiles(axiosResults?.docs)

      const tempFilterList = [];
      axiosResults?.docs.map(doc => { // eslint-disable-line
        if (doc.tags.genre.length > 0) {
          doc.tags.genre.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.mood.length > 0) {
          doc.tags.mood.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
        if (doc.tags.instrument.length > 0) {
          doc.tags.instrument.map((tag) => tempFilterList.includes(tag) ? null : tempFilterList.push(tag)
          )
        }
      })
      setFilterList(tempFilterList)


      const totalPages = axiosResults.totalDocs / perPage
      setPageCount(Math.ceil(totalPages))

      setAxiosLoading(false)

    }
  }, [axiosResults]) // eslint-disable-line

  useEffect(() => {
    if (axiosResults !== '') {
      updateInventory()
    }
  }, [page, sortMethod, perPage]) // eslint-disable-line


  useEffect(() => {
    if (axiosResults !== '') {
      updateInventory()
    }
  }, [songStatus]) // eslint-disable-line




  // selecting files using the keyboard causes crash if cycled too quickly
  // this forces a delay to prevent wavesurfer.isPlaying from returning null

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }

    if (filter.length === 0) {
      if (isPlaying) {
        setControlMessage('pause')
        setIsPlaying(false)
      }
      // updateInventory()
      setUpdateTrigger(true)

    } else {
      // updateInventory()
      setUpdateTrigger(true)
    }

  }, [filter]); // eslint-disable-line 

  useEffect(() => { // eslint-disable-line

    if (axiosResults === '') {
      return
    }

    if (updateTrigger === false) {
      return
    }

    updateInventory()
    navigate(fullPath)

  }, [updateTrigger]); // eslint-disable-line 


  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | Inventory</title>
      </Helmet>
      <Box
        sx={{
          pt: 1,
          pb: 1,
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
                {
                  name: 'Dashboard',
                  href: '/dashboard',
                },
                { name: 'Inventory' },
              ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Stack direction='row' width='100%' justifyContent='space-between' marginBottom={3}>
          <Stack direction='row' gap={2} alignItems='center'>
            <Typography variant="h4">
              {songStatus === 'pitched' ? 'Current Pitched Songs' : 'Bought Songs'}
            </Typography>
            <Typography variant="h5" sx={{ color: theme.palette.info.light }}>
              {songStatus === 'pitched' &&
                `(${inventoryCount})`
              }
            </Typography>
          </Stack>
          <Stack direction='row' gap={2}>
            <Select
              // defaultValue={sortMethod}
              value={sortMethod}
              sx={{
                display: 'flex',
                height: '36px',
                width: '200px',
              }}
              onChange={(e) => { setSortMethod(e.target.value) }}
            >
              <MenuItem value='date-descending'>
                Sort by: Date <Iconify icon='bx:down-arrow' sx={{ height: '12px' }} />
              </MenuItem>
              <MenuItem value='date-ascending'>
                Sort by: Date <Iconify icon='bx:up-arrow' sx={{ height: '12px' }} />
              </MenuItem>
            </Select>
            <Select
              // defaultValue={sortMethod}
              value={perPage}
              sx={{
                display: 'flex',
                height: '36px',
                width: '140px',
              }}
              onChange={(e) => {
                setPage(1)
                setPerPage(e.target.value)
                setUpdateTrigger(true)
              }}
            >
              <MenuItem value={10}>
                10 per page
              </MenuItem>
              <MenuItem value={20}>
                20 per page
              </MenuItem>
              <MenuItem value={30}>
                30 per page
              </MenuItem>
            </Select>
          </Stack>
        </Stack>

        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}
            sx={{
              mb: 7,
              pr: {
                xs: 0,
                sm: 0,
                md: 3,
                lg: 3,
                xl: 3,
              },
              minHeight: '400px'
            }}
            order={{
              xs: 2,
              sm: 2,
              md: 1,
            }}
          >
            <Stack direction='row' gap={3}
              display='flex'
              sx={{
                width: '100%',
                gridTemplateColumns: {
                  xs: '1fr 1fr 1fr',
                  sm: '1fr 1fr 1fr',
                  md: '1fr 1fr 1fr',
                },
                // mt: 2,
                mb: 2,
              }}
            >
              <Button
                variant={songStatus === 'pitched' ? 'contained' : 'outlined'}
                color={songStatus === 'pitched' ? 'primary' : 'info'}
                sx={{
                  opacity: songStatus === 'pitched' ? 1 : .3,
                  width: '100%',
                }}
                onClick={() => {
                  setSongStatus('pitched')
                  setPage(1)
                  setUpdateTrigger(true)
                }}
              >
                Pitched {songStatus === 'pitched' && `(${axiosResults?.totalDocs})`}
              </Button>
              <Button
                variant={songStatus === 'sold' ? 'contained' : 'outlined'}
                color={songStatus === 'sold' ? 'success' : 'info'}
                sx={{
                  opacity: songStatus === 'sold' ? 1 : .3,
                  width: '100%',
                  backgroundColor: songStatus === 'sold' ? theme.palette.success.main : 'none',
                }}
                onClick={() => {
                  setSongStatus('sold')
                  setPage(1)
                  setUpdateTrigger(true)
                }}
              >
                Bought {songStatus === 'sold' && `(${axiosResults?.totalDocs})`}
              </Button>
              <Button
                variant={songStatus === 'favorited' ? 'contained' : 'outlined'}
                color={songStatus === 'favorited' ? 'warning' : 'info'}
                sx={{
                  opacity: songStatus === 'favorited' ? 1 : .3,
                  width: '100%',
                  backgroundColor: songStatus === 'favorited' ? theme.palette.warning.main : 'none',
                  color: songStatus === 'favorited' ? theme.palette.common.white : 'inherit',
                }}
                onClick={() => {
                  setSongStatus('favorited')
                  setPage(1)
                  setUpdateTrigger(true)
                }}
              >
                Favorite {songStatus === 'favorited' && `(${axiosResults?.totalDocs})`}
              </Button>
            </Stack>
            <InventoryShowSearchBox
              showSearchTerm={showSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setSearchTerm={setSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              filter={filter}
              setFilter={setFilter}
              selectedCount={axiosResults?.totalDocs}
            />
            {axiosLoading ? <LoadingIcons.TailSpin stroke={theme.palette.primary.main} width='100%' /> :
              <Box >
                {displayFiles?.length > 0 ?
                  displayFiles.map((doc, index) => (
                    <AudioFileCardClient
                      key={doc._id}
                      id={doc._id}
                      userId={userr}
                      user={user}
                      file={doc}
                      filter={filter}
                      addToCart={addToCart}
                      getCartCount={() => { getCartCount() }}
                      getInventory={() => { getInventory() }}
                      updateInventory={() => { updateInventory() }}
                      loadedSong={src}
                      setLoadedSong={setSrc}
                      index={index}
                      sx={{ mb: 2, mt: 0, display: 'flex' }} // eslint-disable-line
                      setControlMessage={setControlMessage}
                      isPlaying={isPlaying}
                      setIsPlaying={setIsPlaying}
                      audioBarFavorite={audioBarFavorite}
                      setAudioBarFavorite={setAudioBarFavorite}
                      toast={toast}
                      setToast={setToast}
                      inventoryPage
                      favorites
                      passable={songStatus !== 'sold'}
                      hideCartButton={songStatus === 'sold' || songStatus === 'favorited'}
                    />
                  )) :
                  <Typography variant="h5" sx={{ mt: 3, mb: 0 }}>
                    {noResultsMessage(songStatus)}
                  </Typography>
                }
              </Box>
            }
            <Box
              sx={{
                display: pageCount > 1 ? 'flex' : 'none',
                justifyContent: 'flex-end',
                alignItems: 'center',
                mb: 2,
                // display: 'none',
              }}
            >
              <Pagination count={pageCount} page={parseInt(page, 10)}
                // variant='soft'
                shape='rounded'
                color='primary'
                size='large'
                onChange={(e, value) => {
                  setPage(value)
                  setUpdateTrigger(true)
                }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3}
            order={{
              xs: 1,
              sm: 1,
              md: 2,
            }}
            sx={{
              mb: {
                sm: 3,
                md: 7,
              },
              mt: 3,
              pb: showFilter ? 'inherit' : 3,
              borderRadius: '16px',
              ml: {
                xs: 3,
                sm: 3,
                md: 0,
                lg: 0,
                xl: 0,
              },
              border: `1px solid ${theme.palette.divider}`,
              width: '100%',
              // display: displayFiles.length > 0 ? 'block' : 'none'
            }}
          >
            <FilterPane
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              setControlMessage={setControlMessage}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setFilteredDisplayFiles={setFilteredDisplayFiles}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setShowSearchTerm={setShowSearchTerm}
              setUpdateTrigger={setUpdateTrigger}
              fullPath={fullPath}
              filter={filter}
              setFilter={setFilter}
            />
          </Grid>
          <ScrollButton playerLoaded={src?.audioUrl} />
        </Grid>
      </Container>
      <Footer />

      <CartWidgetCustom totalItems={cartItems} />
      <CustomToast toast={toast} setToast={setToast} />

    </>
  );
}
