import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const ALTGREY = {
  0: '#FFFFFF',
  100: '#d4c898',
  200: '#bfb488',
  300: '#ada37b',
  400: '#99906e',
  500: '#877f61',
  600: '#706951',
  700: '#5e5842',
  800: '#474232',
  900: '#2e2b20',
};

const PRIMARY = {
  lighter: '#ddd0a4',
  light: '#cebf8e',
  main: '#C0B283',
  dark: '#ada075',
  darker: '#938863',
  contrastText: '#FFFFFF',
};

const PRIMARYTB = {
  lighter: '#cff0ff',
  light: '#6bccfa',
  main: '#00A2EF',
  dark: '#0586c5',
  darker: '#034f73',
  contrastText: '#FFFFFF',
};

const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#96A271',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

const SECONDARYTB = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#96A271',
  dark: '#1939B7',
  darker: '#091A7A',
  contrastText: '#FFFFFF',
};

const INFO = {
  lighter: '#9c9c9e',
  light: '#7f7f84',
  main: '#696970',
  dark: '#56565b',
  darker: '#464649',
  contrastText: '#FFFFFF',
};

const INFOTB = {
  lighter: '#9c9c9e',
  light: '#7f7f84',
  main: '#939799',
  dark: '#56565b',
  darker: '#464649',
  contrastText: '#FFFFFF',
};


const SUCCESS = {
  lighter: '#ABEDE0',
  light: '#8DC1B7',
  main: '#709991',
  dark: '#537B73',
  darker: '#3E5F59',
  contrastText: '#FFFFFF',
};

const SUCCESSTB = {
  lighter: '#64d9cc',
  light: '#35a195',
  main: '#2e7d74',
  dark: '#275F59',
  darker: '#1a423e',
  contrastText: '#FFFFFF',
};

const WARNING = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#EFA100',
  darker: '#7A4100',
  contrastText: GREY[800],
};
const WARNINGTB = {
  lighter: '#FFF5CC',
  light: '#FFD666',
  main: '#FFAB00',
  dark: '#EFA100',
  darker: '#7A4100',
  contrastText: GREY[800],
};

const ERROR = {
  lighter: '#FFE9D5',
  light: '#F5655A',
  main: '#f44336',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const ERRORTB = {
  lighter: '#FFE9D5',
  light: '#F5655A',
  main: '#f44336',
  dark: '#B71D18',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

const COMMON = {
  common: { black: '#000000', white: '#FFFFFF', offWhite: '#eaebed' },
  primary: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? PRIMARY : PRIMARYTB,
  secondary: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? SECONDARY : SECONDARYTB,
  info: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? INFO : INFOTB,
  success: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? SUCCESS : SUCCESSTB,
  warning: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? WARNING : WARNINGTB,
  error: process.env.REACT_APP_SITE_NAME === 'Audio Arsenal' ? ERROR : ERRORTB,
  grey: GREY,
  altGrey: ALTGREY,
  divider: alpha(GREY[500], 0.24),

  action: {
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

export default function palette(themeMode) {
  const light = {
    ...COMMON,
    mode: 'light',
    text: {
      primary: '#51504a',
      secondary: GREY[600],
      disabled: GREY[500],
    },
    background: { 
      paper: '#e5e8e3', 
      default: '#FBFCF8',
      neutral: GREY[200],
      toolComponent: "353C43",
      editor: "#0c0f14",
      scratchPad: "#e8ebef",
      tableMain: "#1E242B",
      tableCardHeading: "linear-gradient(45deg, rgba(80,177,252,1) 0%, rgba(30,36,43,1) 0%, rgba(36,44,51,1) 100%)",
      tableCardFooter: "linear-gradient(-45deg, rgba(80,177,252,1) 0%, rgba(30,36,43,1) 0%, rgba(36,44,51,1) 100%)",
      tableHeader: '#282E36',
    },
    componentBorder: alpha(GREY[500], 0.5),
    action: {
      ...COMMON.action,
      active: GREY[600],
    },
  };
  
  const dark = {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: GREY[500],
      disabled: GREY[600],
    },
    background: {
      paper: GREY[800],
      default: '#02131c',
      neutral: alpha(GREY[500], 0.16),
      toolComponent: "#273442",
      componentItem: "#444444",
      editor: "#0c0f14",
      scratchPad: "#e8ebef",
      tableMain: "#1E242B",
      tableCardHeading: "linear-gradient(41deg, rgba(80,177,252,1) 0%, rgba(33,42,54,1) 0%, rgba(40,51,65,1) 100%)",
      tableCardFooter: "linear-gradient(217deg, rgba(80,177,252,1) 0%, rgba(33,42,54,1) 0%, rgba(40,51,65,1) 100%)",
      tableHeader: '#282E36',
    },
    componentBorder: alpha(GREY[500], 0.5),
    action: {
      ...COMMON.action,
      active: GREY[500],
    },
  };

  return themeMode === 'light' ? light : dark;
}
