import { useState, useEffect, useRef, useContext } from 'react';
import { Helmet } from 'react-helmet-async';

// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Box, Button, Chip, Container, Typography, Stack, Divider } from '@mui/material';


// graphql
import axios from 'axios';
import { useMutation } from "@apollo/client";
import queryString from 'query-string';
import { GET_USER_PITCHES_BY_ID_M } from '../graphql/mutations';



// components
import LoadingScreen from '../components-default/loading-screen';
import { useSettingsContext } from '../components-default/settings';
import { useAuthContext } from '../auth/useAuthContext';
import Iconify from '../components-default/iconify';


// sections
import CustomBreadcrumbs from '../components-custom/custom-breadcrumbs';

import Footer from '../layouts/main/Footer';
import CustomToast from '../components-custom/CustomToast';
import SongPitchedCard from '../components-custom/SongPitchedCard';
import { AudioContext } from '../components-custom/audio/audio-context';
import MultisUploadDrawer from '../components-custom/MultisUploadDrawer';


// ----------------------------------------------------------------------

export default function AllPitchesPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();
  const { user } = useAuthContext();
  const userr = user?._id || user?._id

  const multisUploadType = process.env.REACT_APP_MULTIS_UPLOAD_TYPE 

  // console.log('User: ', user)

  const [ allPitchesState, setAllPitchesState] = useState([]);

  const {
    src,
    setSrc,
    isPlaying,
    setIsPlaying,
    controlMessage,
    setControlMessage
  } = useContext(AudioContext);

  // const [ loadedSong, setLoadedSong] = useState({album: '', artist: '', title: '', favorited: [], tags: []});

  // const [controlMessage, setControlMessage] = useState('');
  // const [isPlaying, setIsPlaying] = useState(false);

  const [toast, setToast] = useState({open: false, type: '', message: 'add message', icon: 'checkIcon', duration: 3000});

  const [openMultisUploadDrawer, setOpenMultisUploadDrawer] = useState(false)
  const [multisInfo, setMultisInfo] = useState({
    title: '',
    _id: '',
    multitrackUrl: '',
  })


  const [getUserPitchesById] = useMutation(GET_USER_PITCHES_BY_ID_M);

console.log('MULTIS UPLOAD TYPE: ', multisUploadType)

  async function getAllPitches(){
    const allPitches = await getUserPitchesById({ variables: { userId: userr } }); // eslint-disable-line
    setAllPitchesState(allPitches?.data?.getUserPitchesById)
  }

  useEffect(()=>{
    getAllPitches();
  },[]) // eslint-disable-line

  return (
    <>
      <Helmet>
        <title> {process.env.REACT_APP_SITE_NAME} | All Pitches </title>
      </Helmet>
      <Box
        sx={{ 
          pt: 1, 
          pb: 1, 
        }}
      >
        <Container>
          <CustomBreadcrumbs
            // heading='Dashboard'
            links={
              [
              {
                name: 'Dashboard',
                href: '/dash',
              },
              { name: 'Pitches' },
            ]
            }
            activeLast
          />
        </Container>
      </Box>
      <Container 
        maxWidth={themeStretch ? false : 'lg'}
      >
        <Typography variant="h4" sx={{ mb: 5 }}>
        All Pitches
        </Typography>
        <Grid container spacing={3} className='test-opaque'
          sx={{
            // display: 'flex',
          }}
        >
          {allPitchesState?.map(song => {  // eslint-disable-line

            return song.pitches.map(pitch => { // eslint-disable-line
              // console.log('PITCH: ', pitch)
              return <Grid key={`${pitch.briefId}${song._id}`} item xs= {12} sm={6} md={4} lg={3} sx={{display: 'flex', justifyContent:'center', mb: 3}}>
                  <SongPitchedCard 
                    item={song} 
                    inputBrief={pitch}
                    createdAt={pitch?.created_at || ''}
                    loadedSong={src}
                    setLoadedSong={setSrc}
                    controlMessage={controlMessage} 
                    setControlMessage={setControlMessage}
                    isPlaying={isPlaying}
                    setIsPlaying={setIsPlaying}
                    getAllPitches={()=>{getAllPitches()}}
                    setToast={setToast}
                    multisUploadType={multisUploadType}
                    openMultisUploadDrawer={openMultisUploadDrawer}
                    setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
                    multisInfo={multisInfo}
                    setMultisInfo={setMultisInfo}
                    refetch={()=>{getAllPitches()}}

                  />
                </Grid>
              //
            })
            }
          )}

        </Grid>
      </Container>
      <MultisUploadDrawer
        openMultisUploadDrawer={openMultisUploadDrawer}
        setOpenMultisUploadDrawer={setOpenMultisUploadDrawer}
        multisInfo={multisInfo}
        setMultisInfo={setMultisInfo}
        refetch={()=>{getAllPitches()}}
        loadedSong={src}
      />

      <Footer />
 
      <CustomToast toast={toast} setToast={setToast}/>
    </>
  );
}
