import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useCallback } from 'react';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { Box, Grid, Card, Stack, Button, Typography, Select, MenuItem, FormControl, InputLabel, TextField, Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation } from "@apollo/client";

import { fCurrencyCents } from '../../../../utils/formatNumber'
import { GET_ORG_INFO_M } from '../../../../graphql/mutations';

import axios from '../../../../utils/axios';

// routes
// components
import { useAuthContext } from '../../../../auth/useAuthContext';
import TextfieldDisplay from '../../../TextfieldDisplay';
import FormProvider, {
  RHFSwitch,
  RHFEditor,
  RHFUpload,
  RHFTextField,
  RHFAutocomplete,
} from '../../../../components-default/hook-form';
//
import BriefPreview from './BriefPreview';
import { genreList, } from '../../../TagList';
import isAA from '../../../isAA';


// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function CreateBrief() {

  const navigate = useNavigate();
  const { user } = useAuthContext();

  const theme = useTheme();

  const [spotifyPlaylistId, setSpotifyPlaylistId] = useState('')
  const [playlistBox, setPlaylistBox] = useState(null)
  const [categories, setCategories] = useState([])
  const [genre, setGenre] = useState('')
  const [multiGenres, setMultiGenres] = useState([])
  const [subGenres, setSubGenres] = useState([])
  const [stripePrice, setStripePrice] = useState({
    _id: '',
    description: '',
    amount: 0,
  })


  const [openPreview, setOpenPreview] = useState(false);

  const [userOrg, setUserOrg] = useState('');
  const [organizationId, setOrganizationId] = useState('');

  const [getOrgInfo] = useMutation(GET_ORG_INFO_M);


  const NewBlogSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    tags: Yup.array().min(1, 'Must have at least 1 tag'),
    metaKeywords: Yup.array().min(0, 'Meta keywords is required'),
    cover: Yup.mixed().required('Cover is required'),
    content: Yup.string(),
    category: Yup.string().required('Category is required'),
  });

  const defaultValues = {
    active: true,
    title: '',
    description: '',
    content: '',
    cover: null,
    category: '',
    multiGenres: [],
    tags: [],
    publish: true,
    comments: true,
    metaTitle: '',
    metaDescription: '',
    metaKeywords: [],
    spotifyLink: '',
    appleMusicLink: '',
    youtubeLink: '',
    priceId: {
      _id: '',
      description: '',
      amount: 0,
    }

  };

  const methods = useForm({
    resolver: yupResolver(NewBlogSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  const handleOpenPreview = () => {
    setOpenPreview(true);
  };

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const submitBrief = async () => {
    const response = await axios.post(`${process.env.REACT_APP_SERVER}/createbrief`, { values });


  }




  function successCallback() {
    console.log('Upload Success!')
    // setLoading(false);
    navigate('/briefs');
  }


  async function getCategories(orgId) {
    // setLoading(true)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/orgcategories`, {  // eslint-disable-line
        orgId,
        type: 'get',
        platform: isAA() ? 'AA' : 'TB',
      }).then(response => {
        // console.log(response)
        setCategories(response.data.categories)
      });
    } catch (err) {
      console.log(err)
    }
  }

  const handleUpload = async () => {
    // setLoading(true)
    // console.log('File: ', fileHolder);
    // const picFile = 
    const coverBlob = values.cover !== null ? new Blob([values.cover, { type: values.cover.type }]) : '';
    const formData = new FormData();

    const author = { _id: user._id, name: user.name, avatarUrl: '' }

    formData.append('active', values.active);
    formData.append('title', values.title);
    formData.append('description', values.description);
    formData.append('content', values.content);
    formData.append('author', author);
    formData.append('tags', values.tags);
    formData.append('category', genre);
    formData.append('multiGenres', multiGenres);
    formData.append('subGenres', subGenres);
    formData.append('spotifyLink', values.spotifyLink);
    formData.append('appleMusicLink', values.appleMusicLink);
    formData.append('youtubeLink', values.youtubeLink);
    formData.append('organization', userOrg._id || '');
    formData.append('rate', stripePrice.amount || '');
    formData.append('terms', userOrg?.terms || '');
    formData.append('tier', userOrg.tier || '');
    formData.append('priceIdId', stripePrice._id);
    formData.append('priceIdDescription', stripePrice.description);
    formData.append('priceIdAmount', stripePrice.amount);

    formData.append('fileType', values.cover !== null ? values.cover.type : '');
    if (values.cover !== null) {
      formData.append('file', coverBlob, values.cover !== null ? values.cover.name : '');
    }

    console.log('FORMDATA: ', formData)
    try {
      const res = await axios.post(`${process.env.REACT_APP_SERVER}/createbrief`, formData, {  // eslint-disable-line
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        successCallback();
      });
    } catch (err) {
      if (err.response.status) {
        console.log('No directory baby!')
      } else {
        console.log(err.response.data)
      }
    }
  }


  const handleDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('cover', file, { shouldValidate: false });
        // setValue('cover', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = () => {
    setValue('cover', null);
  };

  async function getUserOrg() {
    const org = await getOrgInfo({ variables: { orgId: organizationId } }); // eslint-disable-line      
    setUserOrg(org.data?.getOrgInfo)
  }

  useEffect(() => {

    if (values?.spotifyLink !== '' && values?.spotifyLink.includes('/playlist')) {
      try {
        const spotifyTemp1 = values?.spotifyLink.split('/playlist/')
        const spotifyTemp2 = spotifyTemp1[1].split('?')
        setSpotifyPlaylistId(spotifyTemp2[0])
        console.log('Spotify: ', spotifyTemp2)
      } catch (err) {
        console.log(err)
      }
    }

  }, [values]) // eslint-disable-line


  useEffect(() => {

    if (spotifyPlaylistId !== '') {
      try {
        setPlaylistBox(
          <iframe style={{ borderRadius: "12px" }} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator&theme=0`} width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist' />
        )
      } catch (err) {
        console.log(err)
      }
    }

  }, [spotifyPlaylistId]) // eslint-disable-line


  useEffect(() => {
    if (organizationId !== '') {
      getCategories(organizationId)
      getUserOrg()

    }
    // if (user?.organization !== '') {
    //   try {
    //     getUserOrg()
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }

  }, [organizationId]) // eslint-disable-line

  return (
    <FormProvider
      methods={methods}
    // onSubmit={handleSubmit(onSubmit)}
    // onSubmit={handleUpload}
    >
      {user?.role === 'admin' &&
        <Button onClick={() => { console.table(userOrg) }}>ORG</Button>
      }
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}
          sx={{
            pb: 20,
          }}
        >
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <RHFTextField name="title" label="Post Title" />

              <RHFTextField name="description" label="Description" multiline rows={3} />

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Content
                </Typography>

                <RHFEditor simple name="content" />
              </Stack>

              <Stack spacing={1}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                  Cover
                </Typography>

                <RHFUpload
                  name="cover"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  onDelete={handleRemoveFile}
                />
                <Box
                  sx={{
                    // border: 'red solid 1px',
                    width: '100%',
                    p: 4,
                    pt: 0,
                    borderRadius: 2,
                    // zIndex: 100,
                    // backgroundColor: alpha(theme.palette.grey[900], 0.01),

                    backgroundColor: theme.palette.grey[800],
                    display: values.spotifyLink !== '' || values.appleMusicLink !== '' ? 'block' : 'none',
                  }}
                >
                  {/* <StyledOverlay/> */}
                  <Typography
                    variant="h3"
                    sx={{
                      pb: 3,
                      pt: 2,
                      color: theme.palette.common.white,
                      // py: 5,
                      // px: { md: 5 },
                    }}
                  >
                    Reference
                  </Typography>

                  {playlistBox !== null &&
                    <Box sx={{
                      // zIndex: 1,
                    }}>
                      {playlistBox}
                    </Box>
                    // <iframe style={{borderRadius: "12px"}} src={`https://open.spotify.com/embed/playlist/${spotifyPlaylistId}?utm_source=generator`} width="100%" height="352" frameBorder="0" allowFullScreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy" title='trial-playlist'/>
                  }
                </Box>
              </Stack>
            </Stack>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ p: 3 }}>
            <Stack spacing={3}>
              <div>
                <RHFSwitch
                  name="active"
                  label="Active"
                  labelPlacement="start"
                  sx={{ mb: 1, mx: 0, width: 1, justifyContent: 'space-between' }}
                />

              </div>
              <Stack spacing={1}>

                <Typography variant='caption'>Genre</Typography>
                {isAA() ?
                  <Select
                    // label='Genre'
                    placeholder='Genre'
                    value={genre}
                    onChange={(e) => { setGenre(e.target.value) }}
                  >
                    {categories?.map((item, itemIndex) => { // eslint-disable-line
                      return <MenuItem key={`category-${itemIndex}`}
                        value={item.genre}
                      // style={{backgroundColor: theme.palette.primary.main}}
                      >
                        {item.genre.split('/')[0]}
                      </MenuItem>
                    })}
                    <MenuItem value='Various'>Various</MenuItem>
                  </Select> :
                  <TextField
                    value={genre}
                    onChange={(e) => { setGenre(e.target.value) }}
                    autoComplete='off'
                  />
                }
                {genre === 'Various' &&
                  <FormControl>
                    <InputLabel
                      id="colored-label-select-label"
                      style={{ color: theme.palette.info.main }} // Apply the style to the label
                    >
                      Genres
                    </InputLabel>
                    <Select
                      label='Genre'
                      // placeholder='Genre'
                      value={multiGenres}
                      multiple
                      onChange={(e) => {
                        const newMultiGenres = e.target.value
                        setMultiGenres(newMultiGenres)
                      }}
                      renderValue={(selection) => (
                        <div>
                          {selection.map((value, index) => (
                            <span key={value}>{index !== selection.length - 1 ? value : value.trim()},
                            </span>
                          ))}
                        </div>
                      )}
                    >
                      {categories?.map(item => { // eslint-disable-line
                        return <MenuItem value={item.genre}>
                          {item.genre.split('/')[0]}
                        </MenuItem>
                      })}
                    </Select>
                  </FormControl>
                }
                {(genre !== 'Various' && genre !== '') &&
                  <FormControl>
                    <InputLabel
                      id="colored-label-select-label"
                      style={{ color: theme.palette.info.main }} // Apply the style to the label
                    >
                      Sub Genres
                    </InputLabel>
                    <Select
                      label='SubGenres'
                      // placeholder='Genre'
                      value={subGenres}
                      multiple
                      onChange={(e) => {
                        const newSubGenres = e.target.value
                        setSubGenres(newSubGenres)
                      }}
                      renderValue={(selection) => (
                        <div>
                          {selection?.map((value, index) => (
                            <span key={value}>{index !== selection.length - 1 ? value : value.trim()},
                            </span>
                          ))}
                        </div>
                      )}
                    >
                      {categories?.map(item => { // eslint-disable-line
                        if (item.genre === genre) {
                          return item.subCategories?.map((subItem, subIndex) => { // eslint-disable-line
                            console.log(`SubItem ${subIndex}`, subItem.subType)
                            return <MenuItem value={subItem.subType}>
                              {subItem.subType}
                            </MenuItem>
                          })
                        }
                      })}
                    </Select>
                  </FormControl>
                }
              </Stack>


              <RHFAutocomplete
                name="tags"
                label="Tags"
                multiple
                freeSolo
                options={genreList.map((option) => option.toUpperCase().slice(0, 1).concat(option.slice(1)))}
                ChipProps={{ size: 'small' }}
              />

              <RHFTextField
                name="spotifyLink"
                label="Spotify Playlist"
              />
              <RHFTextField
                name="youtubeLink"
                label="Youtube Link"
              />

              {user?.role === 'admin' ?
              <>
                <Select
                  // label='Organization'
                  // placeholder='Organization'
                  value={organizationId}
                  onChange={(e) => {
                    setOrganizationId(e.target.value)
                  }}
                >
                  <MenuItem value=''>Select Organization</MenuItem>
                  <MenuItem value='63779527-dc9f-4d96-91bb-52fe8117c3ff'>WavMaker</MenuItem>
                </Select>
                <Stack direction='column' spacing={2}>
                <Divider />
                <Typography variant='caption'>Stripe Price</Typography>
                <TextField
                  size='small'
                  variant='outlined'
                  autoComplete='off'
                  // placeholder='Price Id'
                  label='Price Id'
                  value={stripePrice?._id}
                  onChange={(e) => {
                    setStripePrice({...stripePrice, _id: e.target.value})
                  }}
                />
                <TextField
                  size='small'
                  variant='outlined'
                  autoComplete='off'
                  // placeholder='Description'
                  label='Description'
                  value={stripePrice?.description}
                  onChange={(e) => {
                    setStripePrice({...stripePrice, description: e.target.value})
                  }}
                />
                <TextField
                  size='small'
                  variant='outlined'
                  autoComplete='off'
                  // placeholder='Amount'
                  label='Amount (in cents)'
                  value={stripePrice?.amount}
                  onChange={(e) => {
                    setStripePrice({...stripePrice, amount: e.target.value})
                  }}
                />
                <Divider />

              </Stack>

              </> :
                // <RHFTextField 
                //   name="organization" 
                //   label="Organization" 
                // /> :
                <>
                  <TextfieldDisplay
                    message={
                      <Stack direction='row' justifyContent='flex-start' width='100%' paddingRight={1} gap={3}>
                        <Box>Rate: </Box>
                        <Box>{`$${fCurrencyCents(userOrg?.rate)}`}</Box>
                      </Stack>
                      // `${userOrg?.name} - Rate: $${userOrg?.rate}`
                    }
                    height="fit-content"
                    color={theme.palette.info.lighter}
                  />
                  <Box
                    sx={{
                      p: 2,
                      border: `${theme.palette.divider} solid 1px`,
                      borderRadius: 1,
                    }}
                  >
                    <Stack direction='row' justifyContent='flex-start' width='100%' paddingRight={1} gap={2}
                      sx={{
                        color: theme.palette.text.disabled,
                      }}
                    >
                      <Box>Terms: </Box>
                      <Box>{userOrg?.terms}</Box>
                    </Stack>

                  </Box>
                </>
              }
              {!isAA() &&
                <>
                  <Typography variant='caption'>
                    Tier
                  </Typography>
                  <Select
                    // label='Tier'
                    placeholder='Tier'
                    value={userOrg.tier}
                    onChange={(e) => { setUserOrg({ ...userOrg, tier: e.target.value }) }}
                  >
                    <MenuItem value={1}>Tier 1</MenuItem>
                    <MenuItem value={2}>Tier 2</MenuItem>
                    <MenuItem value={3}>Tier 3</MenuItem>
                  </Select>
                </>
              }

            </Stack>
          </Card>

          <Stack direction="row" spacing={1.5} sx={{ mt: 3 }}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              size="large"
              onClick={handleOpenPreview}
            >
              Preview
            </Button>

            <LoadingButton
              fullWidth
              type="button"
              variant="contained"
              size="large"
              loading={isSubmitting}
              onClick={(e) => {
                e.preventDefault();
                handleUpload();
              }}
              sx={{
                display: 'none'
              }}
            >
              Post
            </LoadingButton>
          </Stack>
        </Grid>
      </Grid>

      <BriefPreview
        values={values}
        stripePrice={stripePrice}
        userOrg={userOrg}
        open={openPreview}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClose={handleClosePreview}
        onSubmit={handleUpload}
      />
    </FormProvider>
  );
}
