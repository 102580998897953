import PropTypes from 'prop-types';
// @mui
import { Box, Switch, TablePagination, FormControlLabel, Link, useTheme } from '@mui/material';

// ----------------------------------------------------------------------

TablePaginationCustom.propTypes = {
  dense: PropTypes.bool,
  onChangeDense: PropTypes.func,
  teaser: PropTypes.bool,
  teaserLink: PropTypes.string,
  rowsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  sx: PropTypes.object,
};

export default function TablePaginationCustom({
  dense,
  onChangeDense,
  rowsPerPageOptions = [5, 10, 25],
  teaser,
  teaserLink,
  sx,
  ...other
}) {

  const theme = useTheme();

  return (
    <Box sx={{ 
      position: 'relative',
      height: '64px',
      ...sx 
    }}>
    {!teaser && 
      <TablePagination 
        rowsPerPageOptions={rowsPerPageOptions} 
        component="div" 
        {...other} 
      />
    }
    {teaser &&
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingRight: 2,
      height: '100%',
    }}>
      <Link 
        href={teaserLink} 
        target="_parent" 
        rel="noopener noreferrer"
        color='inherit'
        sx={{
          textDecoration: 'none',
          transition: 'all 0.2s ease',
          '&:hover': {
            textDecoration: 'none',
            color: theme.palette.primary.main,
          },
        }}
      >
        View all
      </Link>

    </Box>
    }

      {/* {onChangeDense && (
        <FormControlLabel
          label="Dense"
          control={<Switch checked={dense} onChange={onChangeDense} />}
          sx={{
            pl: 2,
            py: 1.5,
            top: 0,
            position: {
              sm: 'absolute',
            },
          }}
        />
      )} */}
    </Box>
  );
}
