import PropTypes from 'prop-types';
import { useState, useEffect, useContext } from 'react';
// @mui
import { Box,} from '@mui/material';

import { useTheme } from '@mui/material/styles';

import 'react-h5-audio-player/lib/styles.css'; // eslint-disable-line import/no-extraneous-dependencies
import WaveSurferPlayer from './WavesurferPlayer';
import { AudioContext } from './audio/audio-context';

export default function AudioPlayerBarGlobal(props) {

    AudioPlayerBarGlobal.propTypes = {
        userId: PropTypes.string,
        refetch: PropTypes.func,
        audioBarFavorite: PropTypes.bool,
        setAudioBarFavorite: PropTypes.func,
        // src: PropTypes.object,
        // setSrc: PropTypes.func,
    }

    const theme = useTheme();

    const { 
        src, 
        setSrc, 
        isPlaying, 
        setIsPlaying, 
        controlMessage, 
        setControlMessage 
    } = useContext(AudioContext);

    const { 
        refetch, 
        audioBarFavorite, 
        setAudioBarFavorite, 
        userId,
        // src,
        // setSrc,
    } = props;
    
    const [volumeValue, setVolumeValue] = useState(.7);
    
    useEffect(() => {
        if (setAudioBarFavorite){
            setAudioBarFavorite(src?.favorited?.includes(userId))
        }
        // console.log('AUDIO BAR GLOBAL: ', src )
    },[src]) // eslint-disable-line react-hooks/exhaustive-deps

    return (

    <Box sx={{ 
        position: 'fixed',
        bottom: src?.audioUrl ? 0 : -100,
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        height: '80px',
        width: '100%',
        // backgroundColor: theme.palette.error.main,
        backgroundColor: "#51504a",
        borderTop: `1px solid ${theme.palette.divider}`,
        transition: 'all 0.2s ease-in-out',
        cursor: 'default',
        zIndex: 101,
    }}>        
        <Box 
            sx={{
                // blank box spacer
                display: 'flex', 
                alignItems: 'center', 
            }}
        />

        <WaveSurferPlayer 
            src={src} 
            controlMessage={controlMessage} 
            setControlMessage={setControlMessage}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            volumeValue={volumeValue}
            setVolumeValue={setVolumeValue}
            audioBarFavorite={audioBarFavorite}
            setAudioBarFavorite={setAudioBarFavorite}
            refetch={refetch}
            userId={userId}
        />
       
    </Box>

  )
}
